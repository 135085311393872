/*
 * Package Import
 */
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { UilMapMarkerQuestion, UilSmileDizzy } from '@iconscout/react-unicons';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.background._2,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PositionContainer = styled.div({
  width: '30%',
});

export const MapMarkerQuestion = styled(UilMapMarkerQuestion)(({ theme }) => ({
  color: theme.text._1,
}));

export const dropAndRoll = keyframes({
  '0%': { transform: 'translateY(0) rotate(180deg)' },
  '100%': { transform: 'translateY(25vh) rotate(360deg)' },
});
export const SmileDizzy = styled(UilSmileDizzy)(({ theme }) => ({
  position: 'absolute',
  color: theme.text._1,
  top: 0,
  animation: `3s ease-in 0s 1 ${dropAndRoll}`,
  animationFillMode: 'forwards',
}));

export const Title = styled.h2(({ theme }) => ({
  color: theme.text._1,
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '2rem',
  position: 'relative',
}));

export const Text = styled.span(({ theme }) => ({
  color: theme.text._1,
}));
