/*
 * Package Import
 */
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';

// Queries
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { useGetCourses } from 'src/serverStateManagers/queries/Courses/useGetCourses';
import { useDeactivateUser } from 'src/serverStateManagers/mutations/deactivate/Users/useDeactivateUser';

// Schemas
import { MongoId } from 'src/schemas/Entities/utils';

// Helpers
import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
type DeactivateProps = {
  isOpen: boolean;
  idToDeactivate: MongoId | null;
};

const Deactivate = ({ isOpen, idToDeactivate }: DeactivateProps): JSX.Element => {
  const history = useHistory();
  const deactivateUserMutation = useDeactivateUser();
  const { data: coursesData } = useGetCourses();

  const { data: usersData } = useGetUsers();
  const userData = usersData?.find((user) => user.id === idToDeactivate);

  useEffect(() => {
    if (userData?.deactivatedAt) {
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [userData?.deactivatedAt, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('usersTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (userData?.deactivatedAt) {
      toast('Utilisateur déjà archivé', { type: 'warning' });
      history.push(getRoute('promotionsTable').getUrl({}));
      return;
    }
    if (idToDeactivate) {
      deactivateUserMutation.mutate(idToDeactivate);
    }
    history.push(getRoute('usersTable').getUrl({}));
  };

  // get courses affected by the deletion of a user related as teacher
  const relatedCoursesForTeacher = coursesData?.filter(
    (course) => course.teacher.id === idToDeactivate,
  );

  // is there any relations ?
  const isRelated = relatedCoursesForTeacher && relatedCoursesForTeacher?.length > 0;

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>Êtes-vous sûr de vouloir archiver cet utilisateur ?</F.DeactivateHeader>
        <F.DeactivateLabel>
          Cet utilisateur ne pourra plus se connecter à Slippers
        </F.DeactivateLabel>
        {isRelated && (
          <>
            <F.DeactivateLabel>
              Les éléments listés ci-dessous seront impactés par l&apos;archivage :
            </F.DeactivateLabel>
            <F.RelatedItemsContainer>
              {relatedCoursesForTeacher?.map((relatedCourse) => (
                <F.RelatedItem key={relatedCourse.id}>
                  <Tooltip content="Un cours doit avoir un professeur actif">
                    <F.Info>
                      <F.InfoIcon />
                    </F.Info>
                  </Tooltip>
                  Cours {relatedCourse.title}
                </F.RelatedItem>
              ))}
            </F.RelatedItemsContainer>
          </>
        )}
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="danger" onClick={handleSubmit} fullWidth>
            Archiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Deactivate;
