/* eslint-disable react/static-property-placement */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { scaleDown } from 'src/utils/image';
import Webcam from 'src/components/Users/Profile/Image/Video/Webcam';

/*
 * Component
 */
export const createMugshot = (VideoComponent) =>
  class Mugshot extends React.Component {
    /*
     * Ref
     */
    video = React.createRef();

    /*
     * Props
     */
    static propTypes = {
      actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
      status: PropTypes.bool.isRequired,
      user: PropTypes.string.isRequired,
      setVideoOn: PropTypes.func.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      interval: PropTypes.number.isRequired,
    };

    /*
     * LifeCycles
     */
    componentDidUpdate() {
      const { status } = this.props;
      if (status) {
        this.scaled = false;
      }
    }

    componentWillUnmount() {
      window.clearInterval(this.timer);
    }

    /*
     * Actions
     */
    create = () => {
      // Vars
      const { width, height } = this.props;
      const { videoWidth, videoHeight } = this.video.current;

      // Create canvas for mugshot
      this.canvas = document.createElement('canvas');
      this.canvas.width = width;
      this.canvas.height = height;

      // 2D Context
      this.ctx = this.canvas.getContext('2d');

      // Scale for each dimension
      const scaleHeight = height / videoHeight;
      const scaleWidth = width / videoWidth;

      // Resize & crop a little: take the smaller scale (= max ratio)
      // draw in center, so adjust x y coord
      let scale;
      const draw = {};
      if (scaleHeight < scaleWidth) {
        scale = scaleWidth;
        /* eslint-disable id-length */
        draw.x = 0;
        draw.y = height - scale * videoHeight;
        /* eslint-enable id-length */
      }
      else {
        scale = scaleHeight;
        /* eslint-disable id-length */
        draw.x = width - scale * videoWidth;
        draw.y = 0;
        /* eslint-enable id-length */
      }

      // Video --> Mugshot settings
      this.settings = {
        source: {
          width: videoWidth,
          height: videoHeight,
        },
        scale,
        draw,
      };

      // Play once
      this.snapshot();

      // Play later, every `interval` ms
      this.timer = window.setInterval(this.snapshot, this.props.interval);
    };

    snapshot = () => {
      if (this.video) {
        // Let's get image from video
        const mugshot = this.drawImage();
        // And send it to server
        this.props.actions.sendMugshot({
          mugshot,
          user: this.props.user,
        });
      }
    };

    /**
     * Get Image
     * @description   drawImage with downscale optimization:
     *                downscale step by step until the image is too small
     */
    drawImage = () => {
      // Get downscaled image from video
      const { image, remainingScale } = scaleDown(this.video.current, this.settings);

      // Is context have been scale yet?
      if (!this.scaled) {
        this.scaled = true;
        this.ctx.scale(remainingScale, remainingScale);
      }

      // Write it in out canvas 2d context
      this.ctx.drawImage(image, this.settings.draw.x, this.settings.draw.y);

      // Convert it in Base64
      const mugshot = this.canvas.toDataURL('image/jpeg');

      // Return
      return mugshot;
    };

    /*
     * Render
     */
    render() {
      return (
        <VideoComponent
          ref={this.video}
          onPlay={this.create}
          setVideoOn={this.props.setVideoOn}
          status={this.props.status}
        />
      );
    }
  };

/*
 * Export
 */
export default createMugshot(Webcam);
