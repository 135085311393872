/*
 * Package Import
 */
import styled from '@emotion/styled';
/*
 * Style
 */
export const Text = styled.span(
  ({ theme, isMe }) => `
  color: ${theme.primary._1};
  border-radius: 2px;
  padding: 1px;
  ${isMe && `background-color: ${theme.success.hover};`}
`,
);
