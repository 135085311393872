/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { reactivatePromotion } from './reactivatePromotion';

/*
 * Code
 */
export const useReactivatePromotion = (): UseMutationResult<void, unknown, string, unknown> => {
  const PromotionMutation = useMutation((promotionId: string) => reactivatePromotion(promotionId), {
    onSettled: () => {
      queryClient.invalidateQueries('promotions');
    },
    onSuccess: () => {
      toast('Promotion désarchivée avec succès !', { type: 'success' });
    },
  });

  return PromotionMutation;
};
