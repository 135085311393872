/**
 * The platform is Mac ?
 */
export const isMac = (): boolean => navigator.platform.toUpperCase().indexOf('MAC') >= 0;

/**
 * The platform is Linux ?
 */
export const isLinux = (): boolean => navigator.platform.toUpperCase().indexOf('LINUX') >= 0;

/**
 * Check if the navigator we're using is allowed
 */
export const navigatorAllowed = (): boolean => {
  const browser = navigator.userAgent.match(
    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
  );

  if (browser) {
    const name = browser[1];
    const version = parseInt(browser[2], 10);

    const browserAllowed = [
      {
        name: 'Chrome',
        minVersion: 87,
      },
      {
        name: 'Firefox',
        minVersion: 78,
      },
      {
        name: 'Safari',
        minVersion: 14,
      },
    ];

    return browserAllowed.some((element) => name === element.name && version >= element.minVersion);
  }

  return false;
};
