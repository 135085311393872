/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SubMenu } from '@szhsin/react-menu';

// Style
import * as P from '../style';
import * as G from '../../style';

/**
 * Buttons selector for layout settings
 */
const ButtonsSelector = ({ icon, onChange, options, title, value, ...props }) => (
  <SubMenu
    label={(
      <P.ContainerCheckBox>
        <P.Checkbox />
        {icon}
        {title}
      </P.ContainerCheckBox>
    )}
    {...props}
  >
    <G.MenuItemContent>
      <P.Title>{title}</P.Title>
      <P.Buttons>
        {options.map((option) => (
          <P.Button
            type="button"
            key={`key-${option.value}`}
            onClick={() => onChange(option.value)}
            isActive={option.value === value}
          >
            {option.icon}
            {option.label}
          </P.Button>
        ))}
      </P.Buttons>
    </G.MenuItemContent>
  </SubMenu>
);

ButtonsSelector.propTypes = {
  icon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

ButtonsSelector.defaultProps = {
  icon: null,
};

/*
 * Export
 */
export default ButtonsSelector;
