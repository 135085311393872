/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilSmile, UilMessage } from '@iconscout/react-unicons';

/*
 * Style
 */
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Form = styled.form`
  flex: 1;
  position: relative;
  display: flex;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 0.25rem;
`;

export const Button = styled.button(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '32px',
  width: '32px',
  borderRadius: '6px',
  ...theme.focusVisible,
}));

export const Smile = styled(UilSmile)(({ theme }) => ({
  color: theme.text._5,
  height: '1.125rem',
  width: '1.125rem',
  ':hover': {
    color: theme.text._4,
  },
}));

export const Send = styled(UilMessage)(({ theme }) => ({
  color: theme.text._5,
  height: '1.125rem',
  width: '1.125rem',
  ':hover': {
    color: theme.text._4,
  },
}));

export const ParagraphNoDisplay = styled.p({
  display: 'none',
});

export const SubTitleSrOnly = styled.h3(({ theme }) => ({
  ...theme.srOnly,
}));
