/*
 * Package Import
 */
import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  ColumnDef,
  Row,
  Column,
  SortingFn,
} from '@tanstack/react-table';

/*
 * Local Import
 */
import UpdatePromotionUsersTable from 'src/components/Admin/Tables/UpdatePromotionUsersTable';
import * as T from 'src/components/Admin/TableElements';
import { roles } from 'src/constants/roles';
import { noMatchCaseSort } from 'src/utils/table';
import { User } from 'src/schemas/Entities/User';
import { MongoId } from 'src/schemas/Entities/utils';
import { AllUsersFilter, getInitialSelectedUsers } from '../utils';

type MetaData = {
  rowIsDisabled: (rowOriginal?: User) => boolean;
  idToAddUsers: string | null;
};

/*
 * Code
 */
const RowCheckbox = ({ row, column }: { row: Row<User>; column: Column<User, unknown> }) => {
  const { meta } = column.columnDef;
  const { rowIsDisabled } = meta as MetaData;
  return <T.Checkbox row={row} rowIsDisabled={rowIsDisabled} />;
};

const UsersFilter = ({
  column: { getFilterValue, setFilterValue },
}: {
  column: Column<User, unknown>;
}) => (
  <T.UpdatePromoUsersFilter
    usersFilterValue={getFilterValue() as string | null | undefined}
    setFilter={setFilterValue}
  />
);

const UsersFullLabelCell = ({ row }: { row: Row<User> }) =>
  (row.original ? (
    <T.UserFullLabel
      avatar={row.original.avatar ?? ''}
      firstname={row.original.firstname}
      lastname={row.original.lastname}
      rowId={row.id}
    />
  ) : null);

const RoleCell = ({ row }: { row: Row<User> }) => (
  <T.Tag>{roles.find((role) => role.name === row.original?.role)?.display}</T.Tag>
);

// Hook receiving datas and returning corresponding Table and tableInstance
const useUpdatePromotionUsersTable = (
  newData: User[],
  parentPromotionId: MongoId | undefined,
  { rowIsDisabled, idToAddUsers }: MetaData,
) => {
  const [data, setData] = useState(newData || []);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState(AllUsersFilter.ACTIVATED);
  // Custom alphanumeric sorting, without matching case
  const insensitiveCaseSort: SortingFn<User> = (rowA, rowB, columnId) =>
    noMatchCaseSort({
      valueA: rowA.getValue(columnId),
      valueB: rowB.getValue(columnId),
    });

  useEffect(() => {
    const filteredData = newData.filter((user) => !user.deactivatedAt);
    setData(filteredData);
  }, [newData]);

  useEffect(() => {
    const initialSelectionState = getInitialSelectedUsers(data, idToAddUsers);
    setRowSelection(initialSelectionState);
  }, [data, idToAddUsers]);

  useEffect(() => {
    if (parentPromotionId) {
      setGlobalFilter(AllUsersFilter.DEACTIVATED);
    }
    else {
      setGlobalFilter(AllUsersFilter.ACTIVATED);
    }
  }, [parentPromotionId]);

  // Columns configuration
  const columns: ColumnDef<User>[] = useMemo(
    () => [
      {
        id: 'selection',
        header: '',
        cell: RowCheckbox,
        meta: {
          rowIsDisabled,
        },
      },
      {
        header: 'Id',
        id: 'id',
        accessorKey: 'id',
      },
      {
        id: 'users',
        header: 'Utilisateurs',
        accessorFn: (row: User) => `${row.firstname} ${row.lastname}`,
        cell: UsersFullLabelCell,
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'role',
        header: 'Rôle',
        accessorKey: 'role',
        cell: RoleCell,
      },
    ],
    [insensitiveCaseSort],
  );

  const initialState = {
    columnVisibility: {
      id: false,
    },
    sorting: useMemo(
      () => [
        {
          id: 'users',
          desc: false,
        },
      ],
      [],
    ),
    filterTypes: {
      users: {
        getFilter: ({ column }: { column: Column<User, unknown> }) => UsersFilter({ column }),
      },
    },
  };

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    state: {
      rowSelection,
      ...(!!parentPromotionId && {
        globalFilter,
      }),
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...(!!parentPromotionId && {
      onGlobalFilterChange: setGlobalFilter,
      globalFilterFn: (row: Row<User>, columnId: string, filterValue: AllUsersFilter) => {
        const isUserInPromotion = row.original?.promotions.some(
          (promo) => promo?.id === parentPromotionId,
        );
        return filterValue === AllUsersFilter.ACTIVATED || isUserInPromotion;
      },
    }),
  });
  return [tableInstance, UpdatePromotionUsersTable] as const;
};

/*
 * Export
 */
export default useUpdatePromotionUsersTable;
