/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { base } from 'src/theme';

/**
 * Style
 */
export const Form = styled.form(({ theme }) => ({
  ...theme.typo.bodyText.smallBody,
  color: theme.text._1,
  padding: '.5rem 1rem',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  userSelect: 'none',
  position: 'relative',

  ':hover': {
    background: theme.background._4,
  },
}));

export const AnswersContainer = styled.div({
  width: '100%',
});

export const InputContainer = styled.div({
  display: 'flex',
  alignContent: 'center',
  padding: '.5rem 0',
  height: '100%',
  width: '100%',
});

export const Input = styled.input(({ type }) => ({
  appearance: type,
  flex: '0 0 1.5rem',
  height: '1.5rem',
  width: '1.5rem',
}));

export const LabelAnswer = styled.label({
  cursor: 'pointer',
  marginLeft: '0.5rem',
  width: '100%',
  wordBreak: 'break-word',
  zIndex: '2',
});

export const Button = styled.button(({ isMain, theme }) => ({
  ...base.button({ isMain, theme }),
  margin: '1rem',
}));
