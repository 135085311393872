/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { CourseDTO } from 'src/schemas/Entities/Course';
import { EdusignStatusEnum } from 'src/schemas/Entities/utils';
import { updateCourse, UpdateCourseProps } from './updateCourse';

/*
 * Code
 */
export const useUpdateCourse = (): UseMutationResult<
CourseDTO,
unknown,
UpdateCourseProps,
unknown
> => {
  const courseMutation = useMutation(
    (updateCourseProps: UpdateCourseProps) => updateCourse(updateCourseProps),
    {
      onSettled: () => {
        queryClient.invalidateQueries('courses');
      },
      onSuccess: (courseData) => {
        toast(`Le cours ${courseData.title} a bien été modifié.`, {
          type: 'success',
        });

        const { edusignStatus } = courseData;

        if (edusignStatus === EdusignStatusEnum.ERROR) {
          toast("Une ou plusieurs séances n'ont pas pu être modifiées sur Edusign.", {
            type: 'error',
          });
        }
      },
    },
  );

  return courseMutation;
};
