import React from 'react';
import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import { ErrorIcon } from './Icon';

const getTinyLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;

export const ErrorContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '.25rem',
}));

export const Message = styled('p')(({ theme }) => ({
  color: theme.danger.main,
  width: 'fit-content',
  maxWidth: '30rem',
  ...getTinyLabelTypoStyle(theme),
}));

type ErrorMessageProps = {
  children: React.ReactChild;
};

export const ErrorMessage = ({ children }: ErrorMessageProps): JSX.Element => (
  <ErrorContainer>
    <ErrorIcon />
    <Message>{children}</Message>
  </ErrorContainer>
);
