/* eslint-disable react/jsx-key */

/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import * as T from 'src/components/Admin/TableElements';

// Style
import * as S from './style';

/*
 * Code
 */
const Table = ({ title, tableInstance, openAddModal, menuOptions, customInterface }) => {
  const {
    getHeaderGroups,
    getVisibleFlatColumns,
    setGlobalFilter,
    getRowModel,
    getFilteredRowModel,
    getState,
    setPageSize,
    previousPage,
    nextPage,
    getCanPreviousPage,
    getCanNextPage,
    setColumnVisibility,
  } = tableInstance;

  const { pagination, hideColumns, filtersTypes, globalFilter } = getState();
  const { pageIndex, pageSize } = pagination;
  const { rows } = getRowModel();
  const { rows: allFilteredRows } = getFilteredRowModel();
  const headerGroups = getHeaderGroups();
  const visibleColumns = getVisibleFlatColumns();

  const paginationProps = {
    rowsCount: allFilteredRows.length,
    pageIndex,
    pageSize,
    setPageSize,
    previousPage,
    nextPage,
    getCanPreviousPage,
    getCanNextPage,
  };

  useEffect(() => {
    if (hideColumns) {
      // Custom function to hide columns when needed
      hideColumns({ rows, setColumnVisibility });
    }
  }, [hideColumns]);

  return (
    <>
      <S.OverheadButtons>
        {customInterface?.topLeft ?? <div />}
        <S.AddButtons>
          {customInterface?.topRight ?? <div />}
          <T.PlusButton title={title} openAddModal={openAddModal} />
        </S.AddButtons>
      </S.OverheadButtons>
      <T.TableRoot>
        <colgroup>
          {visibleColumns.map((column) => (
            <col
              key={column.id}
              // check if column width is not set to default value (150)
              style={{
                width: column.columnDef.meta?.width
                  ? column.columnDef.meta.width
                  : `${98 / visibleColumns.length}%`,
              }}
            />
          ))}
          <col style={{ width: '2%' }} />
        </colgroup>
        <T.TableHead>
          <T.TableRowWithoutHover>
            <T.TableCellTitle colSpan={visibleColumns.length + 1}>
              <S.TitleSearchContainer>
                <S.TitleContainer>
                  <S.Title>{title}</S.Title>
                </S.TitleContainer>
                <Tooltip content="Recherche sur toute la table">
                  <T.Search globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </Tooltip>
              </S.TitleSearchContainer>
            </T.TableCellTitle>
          </T.TableRowWithoutHover>
          {headerGroups.map((headerGroup) => (
            <T.TableRowWithoutHover key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <T.TableHeader
                  key={header.id}
                  header={header}
                  filterType={filtersTypes[header.column.id]}
                />
              ))}
              <T.TableCellMenuHeader />
            </T.TableRowWithoutHover>
          ))}
        </T.TableHead>
        <T.TableBody>
          {rows.map((row) => {
            const id = row.getValue('id') ?? '';
            return (
              <T.TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <T.TableCell
                    key={cell.id}
                    customColumnStyle={cell.column.columnDef.meta?.customColumnStyle}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </T.TableCell>
                ))}
                <T.TableCellMenu>
                  <Tooltip content="Menu d'action">
                    <T.ActionsMenu rowId={id} menuOptions={menuOptions} />
                  </Tooltip>
                </T.TableCellMenu>
              </T.TableRow>
            );
          })}
        </T.TableBody>
        <T.TableFooter>
          <T.TableRowWithoutHover>
            <T.TableCellFooter colSpan={visibleColumns.length + 1}>
              <T.Pagination {...paginationProps} />
            </T.TableCellFooter>
          </T.TableRowWithoutHover>
        </T.TableFooter>
      </T.TableRoot>
    </>
  );
};

Table.propTypes = {
  title: PropTypes.string.isRequired,
  tableInstance: PropTypes.object.isRequired,
  openAddModal: PropTypes.func.isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  customInterface: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Table;
