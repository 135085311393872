/*
 * Local Import
 */
import CHATS from 'src/constants/chats';

// Components
import Chat from './Channel/ChannelHeader/container';
import Survey from './Poll/SurveysList/container';
// import Document from './Document';

/**
 * Chat panel · navigation tabs
 * @type {Object}
 */
export default {
  [CHATS.TAB_CHAT]: {
    id: CHATS.TAB_CHAT,
    label: 'Chat',
    component: Chat,
  },
  [CHATS.TAB_SURVEY]: {
    id: CHATS.TAB_SURVEY,
    label: 'Sondages',
    component: Survey,
  },
  // [CHATS.TAB_DOCUMENT]: {
  //   id: CHATS.TAB_DOCUMENT,
  //   label: 'Documents',
  //   component: Document,
  // },
};
