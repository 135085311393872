/*
 * Package Import
 */
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

/*
 * Local Import
 */
import devicesMediasRTC from 'src/utils/medias';
import * as types from 'src/store/types';
import { isTeacher } from 'src/utils/roles';

/*
 * Init
 */
let speechConfig;
let primaryRecognizer;
let secondaryRecognizer;
let neededSwitch = false;

/*
 * Middleware
 */
export default (store) => (next) => (action) => {
  switch (action.type) {
    case types.TRANSCRIPTION_INIT:
    case types.TRANSCRIPTION_REFRESH_TOKEN: {
      const token = JSON.parse(atob(action.token.split('.')[1]));
      const serviceRegion = token.region;
      const { course, users } = store.getState();

      const authorizationToken = action.token;

      if (!serviceRegion || !authorizationToken) {
        throw new Error('Failed to init transcription');
      }

      // Create speech config from token
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion,
      );

      if (!speechConfig) {
        throw new Error('Failed to init the speech configuration');
      }

      // Get endpoint id from store
      const { organization } = store.getState();
      const { subtitle_endpoint_id: endpointId } = organization;

      // Set endpoint
      if (endpointId) {
        speechConfig.endpointId = endpointId;
      }

      // Set audio language
      speechConfig.speechRecognitionLanguage = 'fr-FR';

      // disable profanity filter
      speechConfig.setProfanity(2);

      // Start recognition on start if user is Teacher
      if (
        isTeacher(users.client.role)
        && course.medias.microphoneEnabled
        && !primaryRecognizer
        && !secondaryRecognizer
      ) {
        store.dispatch({ type: types.CONNECT_TRANSCRIPTION });
      }
      else if (primaryRecognizer || secondaryRecognizer) {
        neededSwitch = true;
      }
      break;
    }

    case types.CONNECT_TRANSCRIPTION: {
      if (speechConfig) {
        // Provisional result
        const recognizing = (sender, event) => {
          const { Id } = JSON.parse(event.result.json);

          store.dispatch({
            type: types.SEND_TRANSCRIPTION,
            previousId: store.getState().transcription.previousId,
            id: Id,
            courseId: store.getState().course.courseId,
            userId: store.getState().users.client.id,
            text: event.result.text,
            isFinal: false,
            ws: true,
          });

          store.dispatch({
            type: types.SET_PREVIOUS_ID,
            id: Id,
          });
        };

        // Final result
        const recognized = (sender, event) => {
          const { Id } = JSON.parse(event.result.json);

          if (!event.result.text) return;

          store.dispatch({
            type: types.SEND_TRANSCRIPTION,
            previousId: store.getState().transcription.previousId,
            id: Id,
            courseId: store.getState().course.courseId,
            userId: store.getState().users.client.id,
            text: event.result.text,
            isFinal: true,
            ws: true,
          });

          store.dispatch({
            type: types.SET_PREVIOUS_ID,
            id: null,
          });

          if (neededSwitch) {
            store.dispatch({ type: types.CONNECT_TRANSCRIPTION });
            neededSwitch = false;
          }
        };

        // fallback, use the defaultMicrophone
        const microphoneInput = devicesMediasRTC.localStream
          ? SpeechSDK.AudioConfig.fromStreamInput(devicesMediasRTC.localStream)
          : SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();

        if (!primaryRecognizer) {
          // Init primaryRecognizer with our config
          primaryRecognizer = new SpeechSDK.SpeechRecognizer(speechConfig, microphoneInput);

          if (!primaryRecognizer) {
            throw new Error('Failed to get the primary recognizer');
          }

          // Enable vocal ponctuation (final result only)
          speechConfig.enableDictation();

          // Bind provisional & final result event
          primaryRecognizer.recognizing = recognizing;
          primaryRecognizer.recognized = recognized;

          if (secondaryRecognizer) {
            secondaryRecognizer.stopContinuousRecognitionAsync();
            secondaryRecognizer = null;
          }

          primaryRecognizer.startContinuousRecognitionAsync();
        }
        else if (!secondaryRecognizer) {
          // Init secondaryRecognizer with our config
          secondaryRecognizer = new SpeechSDK.SpeechRecognizer(speechConfig, microphoneInput);

          if (!secondaryRecognizer) {
            throw new Error('Failed to get the second recognizer');
          }

          // Enable vocal ponctuation (final result only)
          speechConfig.enableDictation();

          // Bind provisional & final result event
          secondaryRecognizer.recognizing = recognizing;
          secondaryRecognizer.recognized = recognized;

          if (primaryRecognizer) {
            primaryRecognizer.stopContinuousRecognitionAsync();
            primaryRecognizer = null;
          }

          secondaryRecognizer.startContinuousRecognitionAsync();
        }
      }
      break;
    }

    case types.DISCONNECT_TRANSCRIPTION: {
      if (primaryRecognizer) {
        primaryRecognizer.stopContinuousRecognitionAsync();
        primaryRecognizer = null;
      }
      if (secondaryRecognizer) {
        secondaryRecognizer.stopContinuousRecognitionAsync();
        secondaryRecognizer = null;
      }
      break;
    }

    default:
      break;
  }

  return next(action);
};
