/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilCheckCircle, UilTimes, UilExclamationCircle } from '@iconscout/react-unicons';
import { Snackbar as SnackbarMaterial } from '@material-ui/core';

/*
 * Local Import
 */

export const Snackbar = styled(SnackbarMaterial)(() => ({}));

export const IconSuccess = styled(UilCheckCircle)(() => ({
  margin: '0.625rem 0.625rem 0.625rem 0.875rem',
  height: '20px',
  width: '20px',
}));

export const IconFail = styled(UilExclamationCircle)(() => ({
  margin: '0.625rem 0.625rem 0.625rem 0.875rem',
  height: '20px',
  width: '20px',
}));

export const SnackMessage = styled.div(() => ({
  margin: '0.5rem 2.75rem 0.5rem 0',
}));

export const Alert = styled.div(({ failed, theme }) => ({
  background: failed ? theme.colors.danger._500 : theme.colors.success._500,
  color: 'white',
  borderRadius: '.5rem',
  display: 'flex',
  alignItems: 'center',
  opacity: '1',
  transform: 'none',
}));

export const Close = styled(UilTimes)(() => ({
  cursor: 'pointer',
  color: 'white',
  marginRight: '0.875rem',
  ':hover': {
    color: 'black',
  },
}));
