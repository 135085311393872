/**
 * Local Import
 */
import { mergeDeep } from 'src/utils';
import { DEFAULT_CONSTRAINTS } from 'src/constants/mediaDevices';

/**
 * Devices medias data.
 */
const devicesMediasRTC = {
  // Getting devices
  hasWebcam: false,
  hasMicrophone: false,
  hasSpeakers: false,

  // Permissions devices
  hasWebcamPermissions: false,
  hasMicrophonePermissions: false,

  // Collection of media tracks, each represented by a `MediaStreamTrack`
  localStream: null,

  // List of available devices
  devices: { audioinput: [], audiooutput: [], videoinput: [] },

  // Constraints, with devices ids stored in localStorage
  constraints: {},
};

/**
 * Enumerate a list of the available media input and output devices, such as
 * cameras 🎥, microphones 🎤, speakers 🔊, and so forth. -> User's devices.
 * @return {Promise}
 */
export const getDevices = async () => {
  // Prevent duplication
  const alreadyUsedDevices = {};

  return navigator.mediaDevices
    .enumerateDevices()
    .then((allDevices) => {
      // Devices
      const devices = { audioinput: [], audiooutput: [], videoinput: [] };

      allDevices.forEach((device) => {
        if (alreadyUsedDevices[device.kind + device.deviceId]) {
          return;
        }

        // Webcam 🎥
        if (device.kind === 'videoinput') {
          devicesMediasRTC.hasWebcam = true;

          // Label is available only if authorization is granted
          if (device.label && !devicesMediasRTC.hasWebcamPermissions) {
            devicesMediasRTC.hasWebcamPermissions = true;
          }

          if (devices.videoinput.indexOf(device) === -1) {
            devices.videoinput.push(device);
          }
        }

        // Microphone 🎤
        if (device.kind === 'audioinput') {
          devicesMediasRTC.hasMicrophone = true;

          // Label is available only if authorization is granted
          if (device.label && !devicesMediasRTC.hasMicrophonePermissions) {
            devicesMediasRTC.hasMicrophonePermissions = true;
          }

          if (devices.audioinput.indexOf(device) === -1) {
            devices.audioinput.push(device);
          }
        }

        // Speaker 🔊
        if (device.kind === 'audiooutput') {
          devicesMediasRTC.hasSpeakers = true;

          if (devices.audiooutput.indexOf(device) === -1) {
            devices.audiooutput.push(device);
          }
        }

        alreadyUsedDevices[device.kind + device.deviceId] = device;
      });

      // Finally, get the devices
      devicesMediasRTC.devices = devices;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Cannot get devices -> error name: ${err.name}: ${err.message}`);
    });
};

/**
 * Track the device change event. Each time the user plugs in a camera,
 * microphone, or other media device, or turns one on or off, we call
 * the `getDevices` function, to redraw the list of connected devices.
 * @return {void}
 */
export const trackDeviceChange = () => {
  navigator.mediaDevices.ondevicechange = () => {
    getDevices();
  };
};

/**
 * Get the MediaStream and store it in memory
 * @param {Object} stream
 * @return {void}
 */
export const getStream = (stream) => {
  devicesMediasRTC.localStream = stream;
};

/**
 *
 */
export const setConstraints = (constraints) => {
  devicesMediasRTC.constraints = constraints;
};

/**
 * Get user medias to use
 * @param {Object} constraints
 * @param {function} callback
 * @return {Promise}
 */
export const getUserMedia = (constraints, callback) =>
  navigator.mediaDevices.getUserMedia(constraints).then(callback);

/**
 * Get constraints to use with the getUserMedia method
 * @param {String} options
 * @return {Object}
 */
export const getConstraints = (options = {}) => {
  const constraints = {};

  // Add default `audio` constraints
  constraints.audio = devicesMediasRTC.hasMicrophone ? DEFAULT_CONSTRAINTS.audio : false;

  // Add default `video` constraints
  constraints.video = devicesMediasRTC.hasWebcam ? DEFAULT_CONSTRAINTS.video : false;

  return mergeDeep({}, constraints, devicesMediasRTC.constraints, options);
};

/**
 * Update the video track for the current localStream
 * @param {MediaStream} stream
 */
export const switchVideoLocalStream = (stream) => {
  const videoTrack = devicesMediasRTC.localStream.getVideoTracks()[0];
  devicesMediasRTC.localStream.removeTrack(videoTrack);
  videoTrack.stop();

  // Add new track
  devicesMediasRTC.localStream.addTrack(stream.getVideoTracks()[0]);
};

/**
 * Update the audio track for the current localStream
 * @param {MediaStream} stream
 */
export const switchAudioLocalStream = (stream) => {
  const audioTrack = devicesMediasRTC.localStream.getAudioTracks()[0];
  devicesMediasRTC.localStream.removeTrack(audioTrack);
  audioTrack.stop();

  // Add new track
  devicesMediasRTC.localStream.addTrack(stream.getAudioTracks()[0]);
};

/**
 * Switch the video device
 * @param {String} deviceId
 * @return {Promise}
 */
export const switchVideoDevice = (deviceId) => {
  const constraints = getConstraints();

  // Add the new device id in the constraints
  if (typeof deviceId !== 'undefined') {
    if (constraints.video !== true) {
      constraints.video.deviceId = { ideal: deviceId };
    }
    else {
      constraints.video = { deviceId: { ideal: deviceId } };
    }
  }

  devicesMediasRTC.constraints = constraints;
  // Apply the new constraints
  return getUserMedia(constraints, switchVideoLocalStream);
};

/**
 * Switch the audio device
 * @param {String} deviceId
 * @return {Promise}
 */
export const switchAudioDevice = (deviceId) => {
  const constraints = getConstraints();

  // Add the new device id in the constraints
  if (typeof deviceId !== 'undefined') {
    if (constraints.audio !== true) {
      constraints.audio.deviceId = { ideal: deviceId };
    }
    else {
      constraints.audio = { deviceId: { ideal: deviceId } };
    }
  }

  devicesMediasRTC.constraints = constraints;
  // Apply the new constraints
  return getUserMedia(constraints, switchAudioLocalStream);
};

/**
 * Stop'n'close all streams.
 * @return {void}
 */
export const closeStream = () => {
  if (devicesMediasRTC.localStream) {
    devicesMediasRTC.localStream.getTracks().forEach((track) => {
      track.stop();
    });

    // Reset
    devicesMediasRTC.localStream = null;
  }
};

/**
 * Export
 */
export default devicesMediasRTC;
