/*
 * Package Import
 */
import * as z from 'zod';

/**
 * Stream types
 */
export const STREAM_TYPES = {
  AUDIO: 'audio',
  VIDEO: 'video',
  SCREEN: 'screen',
};

/**
 * Hand statuses
 */
export const HAND_STATUS = {
  RAISED: 'raised',
  UNRAISED: 'unraised',
  VOICE_GIVEN: 'voiceGiven',
};

// Types
export const HAND_STATUS_SCHEMA = z.nativeEnum(HAND_STATUS);
export type HandStatusEnum = z.infer<typeof HAND_STATUS_SCHEMA>;

/**
 * Conferences statuses
 */
export const CONFERENCE_STATUS = {
  STOPPED: 'stopped',
  RUNNING: 'running',
};

/**
 * Maximum number of students allowed
 * This limit performance problems with conference mode
 */
export const CONFERENCE_MAX_STUDENTS = 5;

// Types
export const CONFERENCE_STATUS_SCHEMA = z.nativeEnum(CONFERENCE_STATUS);
export type ConferenceStatusEnum = z.infer<typeof CONFERENCE_STATUS_SCHEMA>;
