/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilAngleDown, UilEye, UilCommentQuestion } from '@iconscout/react-unicons';

/*
 * Style
 */
export const Container = styled.div`
  display: flex;
  border-radius: 6px;
  padding: 4px 0;

  :hover {
    background-color: ${({ theme }) => theme.background._3};
  }

  .menu-message {
    top: -68px !important;
    left: -194px !important;
    background-color: ${({ theme }) => theme.background._2};
    border: 1px solid ${({ theme }) => theme.background._5};
    border-radius: 12px;
    padding: 4px;

    .menu-message-item {
      color: ${({ theme }) => theme.text._1};
      border-radius: 8px;
      padding: 5.5px 22px 5.5px 0;
      font-size: 14px;

      &.hovered {
        background-color: ${({ theme }) => theme.background._3};
      }
    }
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.background._5};
  padding: 0 4px;
`;

export const IconMenu = styled(UilAngleDown)`
  color: ${({ theme }) => theme.text._4};
  border-radius: 4px;
  height: 100%;

  :hover {
    background-color: ${({ theme }) => theme.background._5};
  }
`;

export const IconEye = styled(UilEye)`
  margin: 0 10px;
`;

export const IconQuestion = styled(UilCommentQuestion)`
  margin: 0 10px;
`;
