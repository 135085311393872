/*
 * Package Import
 */

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { UserDTO, UserFormDataValidated } from 'src/schemas/Entities/User';
import { transformUserFormDataToUserDTO } from 'src/schemas/Adapters/Users/transformUserFormDataToUserDTO';
import { API_UPDATE_USER, API_PUT } from 'src/constants/apiRoutes';
import { toast } from 'react-toastify';
import { MongoId } from 'src/schemas/Entities/utils';

/*
 * Code
 */
export interface UpdateUserProps {
  userId: MongoId;
  userFormData: UserFormDataValidated;
}
export const updateUser = async ({ userId, userFormData }: UpdateUserProps): Promise<UserDTO> => {
  try {
    const userDTO = transformUserFormDataToUserDTO(userFormData);

    const payload: MutationPayload = {
      method: API_PUT,
      url: API_UPDATE_USER,
      data: {
        param: { _id: userId },
        newDatas: userDTO,
      },
    };
    const { data: userData } = await api(payload);

    return userData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
