/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import {
  courseFormDataSchema,
  CourseFormData,
  CourseFormDataValidated,
  courseFormDataValidatedSchema,
} from 'src/schemas/Entities/Course';

import { CustomSelect } from 'src/components/Admin/Modals/FormElements/Selects/CustomSelect';
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { dangerousTransformObjectInObjectAny } from 'src/utils/types';
import { getRoute } from 'src/utils/routes';
import { useStore } from 'src/localStateManagers/store';
import { LIMITATIONS_FEATURES } from 'src/constants/limitations';

// Queries
import { useGetLimitations } from 'src/serverStateManagers/queries/Limitations/useGetLimitations';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { useCreateCourse } from 'src/serverStateManagers/mutations/create/Courses/useCreateCourse';

import { coursesAddFormData } from './config';
import { defaultSlot } from '../../FormElements/Timeslot/utils';

/*
 * Code
 */
type AddCourseProps = {
  isOpen: boolean;
};
const AddCourse = ({ isOpen }: AddCourseProps): JSX.Element | null => {
  const createCourseMutation = useCreateCourse();
  const history = useHistory();
  const client = useStore((state) => state.client);

  // Config
  const { header, inputs } = coursesAddFormData;
  const { title, date, selectTeacher, selectHelpers, selectPromotion } = inputs;

  // Selects Data
  const { data: limitationsData } = useGetLimitations();
  const { data: promotionsData } = useGetPromotions();
  const { data: usersData } = useGetUsers();
  const maxParticipantsData = useMemo(
    () =>
      limitationsData
      && limitationsData.find(
        (limitation) => limitation.feature === LIMITATIONS_FEATURES.MAX_PARTICIPANTS_IN_COURSE,
      ),
    [limitationsData],
  );
  const activePromotionsData = useMemo(
    () => promotionsData?.filter((promoData) => !promoData.deactivatedAt),
    [promotionsData],
  );
  const activeUsersData = useMemo(
    () => usersData?.filter((userData) => !userData.deactivatedAt),
    [usersData],
  );

  // Form
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    reset,
    trigger,
  } = useForm({
    resolver: zodResolver(courseFormDataSchema),
    defaultValues: {
      promotion: selectPromotion.getPromoOptions(activePromotionsData)[0],
      teacher: selectTeacher.getDefaultTeacher(client, activeUsersData),
      timeslots: [defaultSlot],
    },
  });

  // To bind form hook to timeslots field
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timeslots',
    keyName: 'fieldId',
  });

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    reset();
    clearErrors();
    history.push(getRoute('coursesTable').getUrl({}));
  };

  const onSubmit = (data: CourseFormData): void => {
    // eslint-disable-next-line max-len
    const courseFormDataValidatedResult: CourseFormDataValidated = courseFormDataValidatedSchema.parse(data);
    clearErrors();
    createCourseMutation.mutate(courseFormDataValidatedResult);
    history.push(getRoute('coursesTable').getUrl({}));
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('coursesTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  // Trigger validation to get errors on timeslots
  //  b/c mode onChange doesn't trigger validation on linked path
  useEffect(() => {
    trigger('timeslots');
    trigger('limitationSimultaneousCourses');
    trigger('limitationTotalHours');
  }, [watch('date'), fields]);

  /**
   * Render
   */
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>

        {/* Limitations */}
        {maxParticipantsData && maxParticipantsData.active && maxParticipantsData.quantity && (
          <F.Description>
            Votre abonnement actuel vous permet d’accueillir {maxParticipantsData.quantity}{' '}
            participants au total dans la classe virtuelle, formateurs inclus.
          </F.Description>
        )}

        {/* Fields */}
        <F.Field>
          <F.Label htmlFor={title.title}>{title.display}</F.Label>
          <F.Input
            className={errors.title ? 'is-error' : undefined}
            ref={register()}
            name={title.title}
            type={title.type}
          />
          {errors.title?.message && <F.ErrorMessage>{errors.title.message}</F.ErrorMessage>}
        </F.Field>
        <F.Line>
          <F.Field key={selectPromotion.title} isLeft>
            <F.Label htmlFor={selectPromotion.title}>{selectPromotion.display}</F.Label>
            <CustomSelect
              isMulti={selectPromotion.isMulti}
              data={selectPromotion.getPromoOptions(activePromotionsData)}
              selectName={selectPromotion.title}
              control={control}
              isError={!!errors.promotion}
              optionsExtraData={selectPromotion.getPromoOptionsTooltipsInfos(activePromotionsData)}
            />
            {errors.promotion?.message && (
              <F.ErrorMessage>{errors.promotion.message}</F.ErrorMessage>
            )}
          </F.Field>
          <F.Field>
            <F.Label htmlFor={selectTeacher.title}>{selectTeacher.display}</F.Label>
            <CustomSelect
              isMulti={selectTeacher.isMulti}
              data={selectTeacher.getPedaOptions(activeUsersData)}
              selectName={selectTeacher.title}
              control={control}
              optionsToDisable={watch(selectTeacher.selectToWatch)}
              isError={!!errors.teacher}
            />
            {errors.teacher?.message && <F.ErrorMessage>{errors.teacher.message}</F.ErrorMessage>}
          </F.Field>
        </F.Line>
        <F.Line>
          <F.Field isLeft>
            <F.Label htmlFor={date.title}>{date.display}</F.Label>
            <F.Input
              className={errors.date ? 'is-error' : undefined}
              ref={register()}
              name={date.title}
              type={date.type}
              min={date.getMinDate(activePromotionsData, watch(selectPromotion.title))}
              max={date.getMaxDate(activePromotionsData, watch(selectPromotion.title))}
            />
            {errors.date?.message && <F.ErrorMessage>{errors.date.message}</F.ErrorMessage>}
          </F.Field>
          <F.Field>
            <F.Label htmlFor={selectHelpers.title}>{selectHelpers.display}</F.Label>
            <CustomSelect
              isMulti={selectHelpers.isMulti}
              data={selectHelpers.getPedaOptions(activeUsersData)}
              selectName={selectHelpers.title}
              control={control}
              optionsToDisable={watch(selectHelpers.selectToWatch)}
              isError={!!errors.helpers}
            />
            {!!errors.helpers
              && errors.helpers?.map(
                (error, index) =>
                  error?.message && <F.ErrorMessage key={index}>{error.message}</F.ErrorMessage>,
              )}
          </F.Field>
        </F.Line>
        <F.Field>
          <F.TimeslotsList
            fields={fields}
            append={append}
            remove={remove}
            register={register}
            watch={watch}
            errors={errors}
          />
        </F.Field>

        {/* Errors messages • Limitations */}
        {dangerousTransformObjectInObjectAny(errors)?.limitationSimultaneousCourses?.message && (
          <F.ErrorMessage>
            {dangerousTransformObjectInObjectAny(errors)?.limitationSimultaneousCourses?.message}
          </F.ErrorMessage>
        )}
        {dangerousTransformObjectInObjectAny(errors).limitationTotalHours?.message && (
          <F.ErrorMessage>
            {dangerousTransformObjectInObjectAny(errors).limitationTotalHours.message}
          </F.ErrorMessage>
        )}

        {/* CTAs */}
        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default AddCourse;
