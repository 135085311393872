/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Notifications from 'src/components/TopBar/Notifications';
import { setMenuNotifications, deleteNotifications } from 'src/store/actions';

/*
 * Code
 */

const mapStateToProps = (state) => ({
  menuNotificationsIsOpen: state.notifications.isOpen,
  allNotifications: state.notifications.allNotifications,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setMenuNotifications, deleteNotifications }, dispatch),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
