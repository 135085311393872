/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.background._2,
  height: '7rem',
  width: '100%',
  borderBottom: `1px solid ${theme.text._7}`,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Title = styled.h2(({ theme }) => ({
  color: theme.text._1,
  fontSize: '2rem',
  fontWeight: 'bold',
  alignSelf: 'center',
  marginLeft: '2rem',
}));

export const LoaderContainer = styled.div(() => ({
  marginRight: '3rem',
  alignSelf: 'center',
}));
