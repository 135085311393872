/*
 * Package Import
 */

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { CourseDTO, CourseFormDataValidated } from 'src/schemas/Entities/Course';
import { transformCourseFormDataToCourseDTO } from 'src/schemas/Adapters/Courses/transformCourseFormDataToCourseDTO';
import { API_UPDATE_COURSE, API_PUT } from 'src/constants/apiRoutes';
import { toast } from 'react-toastify';
import { MongoId } from 'src/schemas/Entities/utils';

/*
 * Code
 */
export interface UpdateCourseProps {
  courseId: MongoId;
  courseFormData: CourseFormDataValidated;
}
export const updateCourse = async ({
  courseId,
  courseFormData,
}: UpdateCourseProps): Promise<CourseDTO> => {
  try {
    const courseDTO = transformCourseFormDataToCourseDTO(courseFormData);

    const payload: MutationPayload = {
      method: API_PUT,
      url: API_UPDATE_COURSE,
      data: {
        param: { _id: courseId },
        newDatas: courseDTO,
      },
    };
    const { data: courseData } = await api(payload);

    return courseData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
