/*
 * Local Import
 */
import ROLES from 'src/constants/roles';

/**
 * User is ‘Helper’ ?
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isHelper = (role) => role === ROLES.ROLE_HELPER;

/**
 * User is ‘Teacher’ ?
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isTeacher = (role) => role === ROLES.ROLE_TEACHER;

/**
 * User is ‘Ghost’ ?
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isGhost = (role) => role === ROLES.ROLE_GHOST;

/**
 * User is ‘Educator’ ? This can be ‘Teacher’ or ‘Helper’.
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isEducator = (role) => isTeacher(role) || isHelper(role);

/**
 * User is ‘Student’ ?
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isStudent = (role) => role === ROLES.ROLE_STUDENT;

/**
 * User has Admin role
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isAdmin = (role) => role === ROLES.ROLE_ADMIN;

/**
 * User has superAdmin role
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isSuperAdmin = (role) => role === ROLES.ROLE_SUPER_ADMIN;

/**
 * User has supervisor role
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isSupervisor = (role) => role === ROLES.ROLE_SUPERVISOR;
/**
 * User has basic role
 * @param  {String}  role - User role
 * @return {Boolean}
 */
export const isBasic = (role) => role === ROLES.ROLE_BASIC;
