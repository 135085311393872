/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';

/*
 * Style
 */

export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter !important',
  '.MuiDialog-paper': {
    position: 'relative',
    minWidth: '24rem',
    minHeight: 'unset',
    maxWidth: 'unset',
    background: theme.background._2,
    border: `2px solid ${theme.text._7}`,
    borderRadius: '16px',
    ...theme.shadows.plus3,
  },
}));

export const Close = styled.button(({ theme }) => ({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2rem',
  height: '2rem',
  background: 'none',
  border: 'none',
  borderRadius: '2px',
  cursor: 'pointer',
  color: theme.text._4,
  ...theme.focusVisible,
}));

export const ScrollContainer = styled.button(() => ({
  overflowX: 'hidden',
  overflowY: 'auto',
}));
