/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  PromotionDTOWithoutId,
  promotionDTOSchemaWithoutId,
  PromotionFormDataValidated,
  promotionFormDataValidatedSchema,
} from 'src/schemas/Entities/Promotion';
import { purify } from '../utils';

/*
 * Code
 */
export const transformPromotionFormDataToPromotionDTO = (
  promotionFormData: PromotionFormDataValidated,
): PromotionDTOWithoutId => {
  const promotionFormDataParseResult = promotionFormDataValidatedSchema.parse(promotionFormData);

  const { name, start, end, parentId } = promotionFormDataParseResult;

  const promotionDTO: PromotionDTOWithoutId = {
    name: purify(name),
    start,
    end,
    parentId,
  };

  const promotionDTOParseResult = promotionDTOSchemaWithoutId.parse(promotionDTO);

  return promotionDTOParseResult;
};
