/*
 * Package Import
 */
import dompurify from 'dompurify';
import { ZodError } from 'zod';

/*
 * Local Import
 */
import type { User } from 'src/schemas/Entities/User';

/*
 * Types
 */
export interface ZodValidationFailure {
  success: false;
  error: ZodError;
  data: User;
}

export interface ZodValidationSuccess {
  success: true;
  data: User;
}

/*
 * Code
 */
export const purify = (string: string): string => dompurify.sanitize(string.trim());

export const purifyIfExist = (string: string | undefined | null): string | null => {
  if (string && string.length > 0) {
    return purify(string);
  }
  return null;
};
