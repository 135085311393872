/*
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useActor } from '@xstate/react';
import { Dialog } from '@oclock/crumble';

/*
 * Local Import
 */
import Action from 'src/components/Course/Controls/Action';
import Modal from 'src/components/Course/Controls/Modal/container';

// Context
import { GlobalStateContext } from 'src/context/GlobalStateMachine';

// Constants
import { CONFERENCE_STATUS, HAND_STATUS } from 'src/constants/conference';
import { CONTROLS_ID } from 'src/constants/controls';
import ROLES from 'src/constants/roles';

// Helpers
import devicesMediasRTC from 'src/utils/medias';
import { isEducator, isTeacher } from 'src/utils/roles';
import {
  getConferenceButtonDescription,
  getHandButtonDescription,
  getMicrophoneButtonDescription,
  getScreenButtonDescription,
  getSoundButtonDescription,
  getWebcamButtonDescription,
  isHandRaisingButtonDisabled,
  isMicrophoneButtonDisabled,
  isWebcamButtonDisabled,
} from './utils';

// Style
import * as S from './style';

/*
 * Component
 */
const Controls = ({
  actions,
  handStatus,
  webcamEnabled,
  microphoneEnabled,
  screenEnabled,
  soundEnabled,
  conferenceStatus,
  clientRole,
  isTeacherConnected,
  isMenuDisplay,
  isAudioConferenceButtonDisabled,
  isMaxTracksReached,
  isLocalStreamReady,
  connectedStudents,
}) => {
  /*
   * Context
   */
  const globalServices = useContext(GlobalStateContext);

  /*
   * State
   */
  const [state, send] = useActor(globalServices.classroomService);
  const [handModalActive, setHandModalActive] = useState(false);
  const [leaveModalActive, setLeaveModalActive] = useState(false);

  /**
   * Toggle Microphone 🔊
   */
  const onToggleMicrophone = () => {
    if (microphoneEnabled) {
      actions.disableMicrophone();
    }
    else {
      actions.enableMicrophone();
    }
  };

  /**
   * Toggle Webcam 📽
   */
  const onToggleWebcam = () => {
    if (webcamEnabled) {
      actions.disableWebcam();
    }
    else {
      actions.enableWebcam();
    }
  };

  /**
   * Toggle Sound 🔊
   */
  const onToggleSound = () => {
    if (soundEnabled) {
      actions.disableSound();
    }
    else {
      actions.enableSound();
    }
  };

  /**
   * Toggle Screen 🖥
   */
  const onToggleScreen = async () => {
    if (screenEnabled) {
      actions.disableScreen();
    }
    else {
      actions.enableScreen();
    }
  };

  /**
   * Toggle the leave course
   */
  const onDisplayLeaveCourse = () => {
    // Update the state machine (classroom)
    send('LEAVING');
    // Display Modal
    setLeaveModalActive(true);
  };

  const onCloseLeavingCourse = () => {
    // Updte the state machine (classroom), to return the initial `joined` state
    send('JOINED');
    // Close the modal
    setLeaveModalActive(false);
  };

  /**
   * Toggle Hand status ✋ (Student only)
   */
  const onToggleHand = () => {
    if (!isTeacher(clientRole)) {
      if (handStatus === HAND_STATUS.UNRAISED) {
        actions.sendRaisedHand();
        setHandModalActive(true);
      }
      else {
        setHandModalActive(!handModalActive);
      }
    }
  };

  /**
   * Toggle the conference status
   */
  const onToggleConference = () => {
    if (conferenceStatus === CONFERENCE_STATUS.STOPPED) {
      actions.setConferenceStatus(CONFERENCE_STATUS.RUNNING);
    }
    else if (conferenceStatus === CONFERENCE_STATUS.RUNNING) {
      actions.setConferenceStatus(CONFERENCE_STATUS.STOPPED);
    }
  };

  /**
   * Close modal
   */
  const onCloseHand = () => {
    setHandModalActive(false);
  };

  /*
   * LifeCycles
   */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isTeacher(clientRole)) {
      // Listener used to detect if screen is shared
      // and trigger the button color change
      const localVideo = document.getElementById('screenView');

      if (localVideo) {
        localVideo.addEventListener('play', () => {
          actions.setScreenStatus(true);
        });

        return () => {
          localVideo.removeEventListener('play', () => {
            actions.setScreenStatus(false);
          });
        };
      }
    }
  }, [clientRole]);

  /** Buttons disabled states  */
  const microphoneButtonDisabled = isMicrophoneButtonDisabled({
    hasMicrophonePermissions: devicesMediasRTC.hasMicrophonePermissions,
    handStatus,
    conferenceStatus,
    clientRole,
    isLocalStreamReady,
  });
  const handRaisingButtonDisabled = isHandRaisingButtonDisabled({
    hasMicrophonePermissions: devicesMediasRTC.hasMicrophonePermissions,
    isMaxTracksReached,
    isTeacherConnected,
    conferenceStatus,
    clientRole,
    handStatus,
    isLocalStreamReady,
  });
  const webcamButtonDisabled = isWebcamButtonDisabled({
    hasWebcamPermissions: devicesMediasRTC.hasWebcamPermissions,
    handStatus,
    clientRole,
    isMaxTracksReached,
    webcamEnabled,
    isLocalStreamReady,
  });

  /** Buttons descriptions  */
  const microphoneButtonDescription = getMicrophoneButtonDescription({
    hasMicrophonePermissions: devicesMediasRTC.hasMicrophonePermissions,
    handStatus,
    conferenceStatus,
    clientRole,
    microphoneEnabled,
    isLocalStreamReady,
  });
  const handButtonDescription = getHandButtonDescription({
    hasMicrophonePermissions: devicesMediasRTC.hasMicrophonePermissions,
    isMaxTracksReached,
    isTeacherConnected,
    conferenceStatus,
    clientRole,
    handStatus,
    isLocalStreamReady,
  });
  const webcamButtonDescription = getWebcamButtonDescription({
    hasWebcamPermissions: devicesMediasRTC.hasWebcamPermissions,
    handStatus,
    clientRole,
    webcamEnabled,
    isMaxTracksReached,
    isLocalStreamReady,
  });

  /*
   * Render
   */
  return (
    <S.ActionsContainer role="list" isMenuDisplay={isMenuDisplay}>
      {isEducator(clientRole) ? (
        // Special handlers for team educator (`teachers`, `helpers`)
        <Action
          id={CONTROLS_ID.MICROPHONE}
          onClick={onToggleMicrophone}
          isActive={microphoneEnabled && devicesMediasRTC.hasMicrophonePermissions}
          disabled={microphoneButtonDisabled}
          label="Micro"
          title="Micro"
          description={microphoneButtonDescription}
        />
      ) : (
        // Special handlers for all others people (`students`)
        <>
          {/* Hand ✋ */}
          <Action
            id={CONTROLS_ID.HAND}
            onClick={onToggleHand}
            isActive={
              (isTeacherConnected && handStatus)
              || (!isTeacherConnected && handStatus !== HAND_STATUS.UNRAISED)
            }
            disabled={handRaisingButtonDisabled}
            label="Parole"
            title="Demande de parole"
            description={handButtonDescription}
          />

          {/* Microphone 🎤 */}
          <Action
            id={CONTROLS_ID.MICROPHONE}
            onClick={onToggleMicrophone}
            isActive={microphoneEnabled && devicesMediasRTC.hasMicrophonePermissions}
            disabled={microphoneButtonDisabled}
            label="Micro"
            title="Micro"
            description={microphoneButtonDescription}
          />
        </>
      )}

      {/* Sound 🔊 */}
      <Action
        id={CONTROLS_ID.SOUND}
        onClick={onToggleSound}
        isActive={soundEnabled}
        label="Son"
        title="Son du cours"
        description={getSoundButtonDescription({ soundEnabled })}
      />

      {/* Webcam 📽 */}
      <Action
        id={CONTROLS_ID.WEBCAM}
        label="Webcam"
        onClick={onToggleWebcam}
        isActive={webcamEnabled && devicesMediasRTC.hasWebcamPermissions}
        disabled={webcamButtonDisabled}
        title="Webcams"
        description={webcamButtonDescription}
      />

      {/* Screen 🖥 */}
      {isTeacher(clientRole) && (
        <Action
          id={CONTROLS_ID.SCREEN}
          onClick={onToggleScreen}
          isActive={screenEnabled}
          label="Présenter"
          title="Écran du cours"
          description={getScreenButtonDescription({ screenEnabled })}
        />
      )}

      {/* 🎭 Conference 🎭 */}
      {isTeacher(clientRole) && (
        <Action
          id={CONTROLS_ID.CONFERENCE}
          onClick={onToggleConference}
          isActive={conferenceStatus === CONFERENCE_STATUS.RUNNING}
          // Button is disabled if any student has voice given or CONFERENCE_MAX_STUDENTS is reached
          disabled={isAudioConferenceButtonDisabled}
          label="Discuter"
          title="Discuter"
          description={getConferenceButtonDescription({
            isDisabled: isAudioConferenceButtonDisabled,
            conferenceStatus,
            connectedStudents,
          })}
        />
      )}

      {/* Leave 🚪 */}
      <Action
        id={CONTROLS_ID.LEAVE}
        onClick={onDisplayLeaveCourse}
        isActive={false}
        label="Quitter"
        title="Quitter le cours"
        description="Retourner à la liste des cours"
      />

      {/* Modals */}
      {handModalActive && handStatus !== HAND_STATUS.UNRAISED && (
        <Modal onClose={onCloseHand} handStatus={handStatus} ariaLabel="Lever la main" />
      )}

      {leaveModalActive && state.value === 'leaving' && (
        <Dialog
          title="Êtes-vous sûr de vouloir quitter le cours ?"
          desc="Vous allez revenir sur la liste des cours."
          variant="danger"
          cancelButtonProps={{
            label: 'Annuler',
            onClick: onCloseLeavingCourse,
          }}
          successButtonProps={{
            label: 'Quitter le cours',
            onClick: () => {
              window.location = '/';
            },
          }}
        />
      )}
    </S.ActionsContainer>
  );
};

/*
 * PropTypes
 */
Controls.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Medias devices informations, from current user */
  webcamEnabled: PropTypes.bool.isRequired,
  microphoneEnabled: PropTypes.bool.isRequired,
  soundEnabled: PropTypes.bool.isRequired,
  screenEnabled: PropTypes.bool.isRequired,
  /** Current status */
  handStatus: PropTypes.string.isRequired,
  conferenceStatus: PropTypes.string,
  /** Educator props */
  clientRole: PropTypes.oneOf([
    ROLES.ROLE_GHOST,
    ROLES.ROLE_TEACHER,
    ROLES.ROLE_HELPER,
    ROLES.ROLE_STUDENT,
  ]).isRequired,
  isTeacherConnected: PropTypes.bool.isRequired,
  isMenuDisplay: PropTypes.bool.isRequired,
  isAudioConferenceButtonDisabled: PropTypes.bool.isRequired,
  isMaxTracksReached: PropTypes.bool.isRequired,
  isLocalStreamReady: PropTypes.bool.isRequired,
  connectedStudents: PropTypes.array.isRequired,
};

Controls.defaultProps = {
  conferenceStatus: CONFERENCE_STATUS.STOPPED,
};

/*
 * Export
 */
export default Controls;
