/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';

// Queries
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { useDeleteUser } from 'src/serverStateManagers/mutations/delete/Users/useDeleteUser';

// Schemas
import { MongoId } from 'src/schemas/Entities/utils';

// Helpers
import { getRoute } from 'src/utils/routes';
import useWrapClient from 'src/components/Authorization/hooks/useWrapClient';

/*
 * Code
 */
type DeleteProps = {
  isOpen: boolean;
  idToDelete: MongoId | null;
};

const Delete = ({ isOpen, idToDelete }: DeleteProps): JSX.Element => {
  const history = useHistory();
  const { data: usersData } = useGetUsers();
  const client = useWrapClient();
  const userToDelete = usersData?.find((user) => user.id === idToDelete);

  const deleteUserMutation = useDeleteUser();

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('usersTable').getUrl({}));
  };

  const handleSubmit = (): void => {
    if (idToDelete) {
      deleteUserMutation.mutate(idToDelete);
    }
    history.push(getRoute('usersTable').getUrl({}));
  };

  useEffect(() => {
    if (!client || !client.isMinRole('admin') || userToDelete?.isRegistered) {
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [history, userToDelete]);

  const bodyTextFirstLine = `Êtes-vous sûr de vouloir supprimer ${
    userToDelete ? userToDelete.name : "l'utilisateur"
  } ?`;
  const bodyTextSecondLine = 'Il ne pourra pas compléter son inscription sur Slippers.';

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <>
        <F.ModalTitle>Supprimer l&apos;utilisateur</F.ModalTitle>
        <F.SmallBody>{bodyTextFirstLine}</F.SmallBody>
        <F.SmallBody>{bodyTextSecondLine}</F.SmallBody>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button
            type="submit"
            variant="danger"
            // disabled={isLoading}
            onClick={handleSubmit}
            fullWidth
          >
            Supprimer
          </Button>
        </F.Buttons>
      </>
    </Modal>
  );
};

/*
 * Export
 */
export default Delete;
