/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Event = styled.li`
  color: ${(props) => props.theme.info.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.7em;
`;

export const Container = styled.button(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '0.5em 1em',
  color: theme.info.main,
  ...theme.focusVisible,
}));

export const Label = styled.span`
  margin-left: 0.5rem;
`;
