import * as z from 'zod';
import { roleEnumSchema } from 'src/constants/roles';
import { mongoIdSchema } from './utils';
import * as refines from '../Refines/users';

const integrationsSchema = z
  .object({
    edusign: z.object({
      id: z.string().nullable().optional(),
      mustSign: z.boolean().optional(),
    }),
  })
  .optional();

export const userDTOSchema = z.object({
  id: mongoIdSchema,
  avatar: z.string().nullable(),
  isRegistered: z.boolean().optional(),
  invitationDate: z.string().optional(),
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  role: roleEnumSchema,
  email: z.string().email({ message: "L'email doit être rempli et valide" }),
  promosIds: z.array(mongoIdSchema.nullable()),
  name: z.string().optional(),
  town: z.string().nullable(),
  country: z.string().nullable(),
  githubProfile: z.string().nullable(),
  discordProfile: z.string().nullable(),
  deactivatedAt: z.string().optional(),
  integrations: integrationsSchema,
  canCreateVM: z.boolean().optional(),
});

export const userDTOSchemaWithoutId = userDTOSchema.omit({ id: true });

export const userSchema = z.object({
  id: mongoIdSchema,
  avatar: z.string().nullable(),
  isRegistered: z.boolean().optional(),
  invitationDate: z.date().optional(),
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  name: z.string().optional(),
  role: roleEnumSchema,
  email: z.string().email({ message: "L'email doit être rempli et valide" }),
  promotions: z.array(
    z
      .object({
        id: mongoIdSchema,
        name: z.string(),
        displayName: z.string().optional(),
        deactivatedAt: z.date().optional(),
      })
      .nullable(),
  ),
  town: z.string().nullable(),
  country: z.string().nullable(),
  githubProfile: z.string().nullable(),
  discordProfile: z.string().nullable(),
  deactivatedAt: z.date().optional(),
  integrations: integrationsSchema,
  canCreateVM: z.boolean().optional(),
});

export const userDisplayBeforeCreateSchema = z
  .object({
    id: z.string().uuid(),
    avatar: z.null(),
    firstname: z
      .string()
      .nonempty('Le prénom doit être rempli')
      .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
    lastname: z
      .string()
      .nonempty('Le nom doit être rempli')
      .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
    role: z.literal('basic'),
    email: z.string().email({ message: "L'email doit être rempli et valide" }),
    promotions: z.array(
      z
        .object({
          id: mongoIdSchema,
          name: z.string(),
          deactivatedAt: z.date().optional(),
        })
        .nullable(),
    ),
    town: z.string(),
    country: z.string(),
    githubProfile: z.string(),
    discordProfile: z.string(),
    integrations: integrationsSchema,
    canCreateVM: z.boolean(),
  })
  .refine(refines.userCsvEmailMustBeUniqueRelativeToExistingUsers, {
    message: 'Cet email est déjà utilisé par un autre utilisateur enregistré',
    path: ['email'],
  })
  .refine(refines.userCsvEmailMustBeUniqueInCsvWhenEdit, {
    message: 'Vous ne pouvez pas créer deux utilisateurs avec le même email',
    path: ['email'],
  });

export const userFormDataSchema = z
  .object({
    id: mongoIdSchema.optional(),
    firstname: z
      .string()
      .nonempty('Le prénom doit être rempli')
      .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
    lastname: z
      .string()
      .nonempty('Le nom doit être rempli')
      .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
    role: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .nullable() // we use refine to overload error message
      .refine((data) => typeof data !== null && roleEnumSchema.safeParse(data?.value).success, {
        message: "Ce rôle n'est pas reconnu",
      }),
    email: z.string().nonempty('Le mail doit être rempli').email("Le mail n'est pas valide"),
    promotions: z.array(
      z
        .object({
          value: mongoIdSchema,
          label: z.string(),
        })
        .nullable(),
    ),
    avatar: z
      .string()
      .optional()
      .refine((data) => !data || z.string().safeParse(data).success, {
        message: "L'url n'est pas valide",
      }),
    town: z.string().optional(),
    country: z.string().optional(),
    githubProfile: z.string().optional(),
    discordProfile: z.string().optional(),
    integrations: integrationsSchema,
    canCreateVM: z.boolean().optional(),
  })
  .refine(refines.userFormMustBePeda, {
    message:
      'Déjà sélectionné en tant que professeur/helper sur des cours, doit être un rôle pédagogique : Superviseur, Admin ou SuperAdmin',
    path: ['role'],
  })
  .refine(refines.userFormMustGivenLowerOrEqualRole, {
    message: 'Vous ne pouvez pas attribuer de rôles supérieurs à vos propres droits',
    path: ['role'],
  })
  .refine(refines.userFormMustChangeLowerRole, {
    message:
      'Vous ne pouvez pas modifier un utilisateur dont le rôle est égal ou supérieur au vôtre',
    path: ['role'],
  })
  .refine(refines.userFormEmailMustBeUnique, {
    message: 'Cet email est déjà utilisé par un autre utilisateur',
    path: ['email'],
  });

export const userFormDataValidatedSchema = z.object({
  id: mongoIdSchema.optional(),
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  role: z.object({
    value: roleEnumSchema,
    label: z.string(),
  }),
  email: z.string().nonempty('Le mail doit être rempli').email("Le mail n'est pas valide"),
  promotions: z.array(
    z
      .object({
        value: mongoIdSchema,
        label: z.string(),
      })
      .nullable(),
  ),
  avatar: z.string().optional(),
  town: z.string().optional(),
  country: z.string().optional(),
  githubProfile: z.string().optional(),
  discordProfile: z.string().optional(),
  integrations: integrationsSchema,
  canCreateVM: z.boolean().optional(),
});

export const clientSchema = z.object({
  id: mongoIdSchema,
  avatar: z.string().optional(),
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  email: z.string().email(),
  role: roleEnumSchema,
  name: z.string().optional(),
  canCreateVM: z.boolean().optional(),
});

export const userRelationSchema = z.object({
  id: mongoIdSchema,
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  deactivatedAt: z.date().optional(),
});

export type UserDTO = z.infer<typeof userDTOSchema>;
export type UserDTOWithoutId = z.infer<typeof userDTOSchemaWithoutId>;
export type User = z.infer<typeof userSchema>;
export type UserDisplayBeforeCreate = z.infer<typeof userDisplayBeforeCreateSchema>;
export type UserFormData = z.infer<typeof userFormDataSchema>;
export type UserFormDataValidated = z.infer<typeof userFormDataValidatedSchema>;
export type Client = z.infer<typeof clientSchema>;
