/*
 * Local Import
 */
import { isMac } from './navigator';

/**
 * Is the key pressed : `Escape` ?
 */
export const isEscape = (event: KeyboardEvent): boolean => {
  const key = event.key || event.keyCode;
  const escape = key === 'Escape' || key === 'Esc' || key === 27;
  return escape;
};

/**
 * Is the key pressed : `Enter` (↩︎) ?
 */
export const isEnter = (event: KeyboardEvent, acceptShift = true): boolean => {
  const key = event.key || event.keyCode;
  const enter = key === 'Enter' || key === 13;
  const shift = acceptShift || !event.shiftKey;
  return enter && shift;
};

/**
 * Is the key pressed : `Space` ( ) ?
 */
export const isSpace = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;
  const enter = key === ' ' || key === 32;
  return enter;
};

/**
 * Is the key pressed : arrow right ?
 */
export const isRight = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;
  const right = key === 'ArrowRight' || key === 39;
  return right;
};

/**
 * Is the key pressed : arrow left ?
 */
export const isLeft = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;
  const left = key === 'ArrowLeft' || key === 37;
  return left;
};

/**
 * Is the key pressed : `Ctrl` or `CMD` (⌘) ?
 */
export const isCtrl = (event: KeyboardEvent, acceptAlt = false): boolean => {
  if (acceptAlt) {
    return (isMac() && event.metaKey) || (!isMac() && event.ctrlKey);
  }

  return (isMac() && event.metaKey) || (!isMac() && event.ctrlKey && !event.altKey);
};

/**
 * Is the key pressed : `Alt` (⌥) ?
 */
export const isAlt = (event: KeyboardEvent): boolean => event.altKey;

/**
 * Is the key pressed : `Shift` (⇧) ?
 */
export const isShift = (event: KeyboardEvent): boolean => event.shiftKey;
