/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Mugshot from 'src/components/Chats/Channel/Mugshot';

// Styles
import * as S from './style';

/*
 * No participant Component
 */
export const NoParticipants = () => (
  <S.Container data-testid="no-participant-survey-answer">
    <S.Name>Aucune réponse</S.Name>
  </S.Container>
);

/*
 * Participant Component
 */
export const Participants = ({ user }) => (
  <S.Container data-testid="participant-survey-answer">
    <Mugshot user={user} size={24} statusSize={0.5} />
    <S.Name>{user?.name || user?.id || 'Utilisateur inconnu'}</S.Name>
  </S.Container>
);

Participants.propTypes = {
  /** Voter data user */
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

Participants.defaultProps = {
  user: null,
};
