import { socketInstance } from 'src/context/SocketProvider';

/**
 * Middleware that sends every action that has `ws: true` through the websocket
 * Importing the websocket instance like this isn't the prettiest solution but its good enough.
 */
export default () => (next) => (action) => {
  if (socketInstance && socketInstance.connected && action.ws) {
    const { ws, type, ...payload } = action;
    socketInstance.emit(type, payload);
  }

  return next(action);
};
