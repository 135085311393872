/*
 * Package Import
 */
import produce from 'immer';
import { AnyAction } from 'redux';

/*
 * Local import
 */
import CHATS, { ChannelPanelEnum, ChatTabsEnum, ChatTypesEnum } from 'src/constants/chats';
import * as types from 'src/store/types';

/**
 * Types
 */
interface ChatProps {
  id: string;
  kind: ChatTypesEnum;
  open: boolean;
  unread: number;
  usersIds: string[];
}

interface ChatsState {
  active: string | null;
  general: string | null;
  clientId: string | null;
  currentView: ChannelPanelEnum;
  currentMainChatView: ChatTabsEnum;
  ids: string[];
  byId: Record<string, ChatProps>;
  unreadPolls: number;
  popout: boolean;
}

/*
 * Initial State
 */
const initialState: ChatsState = {
  active: null,
  general: null,
  clientId: null,
  ids: [],
  byId: {},
  currentView: CHATS.CHAT_GENERAL,
  currentMainChatView: CHATS.TAB_CHAT,
  unreadPolls: 0,
  popout: false,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      /*
       * Data User
       */
      case types.LOG_USER_IN: {
        draft.clientId = action.user.id;
        break;
      }

      /**
       * Initialize the application w/ data
       */
      case types.INITIALIZE_DATA: {
        const { chats } = action.data;
        const { ids, byId, general } = chats;

        /** By default, set the current `ID` to general channel */
        draft.active = general;

        /** By default, set the current `view` to the `general` tab */
        draft.currentView = CHATS.CHAT_GENERAL;

        /** General ID */
        draft.general = general;

        /** Channels data */
        draft.ids = ids;
        draft.byId = byId;
        break;
      }

      /**
       * Update the channel affect by the received message
       */
      case types.RECEIVED_MESSAGE: {
        // Channel exist ?
        if (!state.byId[action.chatId]) {
          return state;
        }

        // Update the `open` property
        if (!state.byId[action.chatId].open) {
          draft.byId[action.chatId].open = true;
        }

        // Update the `unread` property
        if (state.active !== action.chatId && action.userId !== state.clientId) {
          draft.byId[action.chatId].unread += 1;
        }
        break;
      }

      case types.RECEIVED_SURVEY: {
        // Update the `unreadPolls` property
        if (state.currentMainChatView !== CHATS.TAB_SURVEY && action.userId !== state.clientId) {
          draft.unreadPolls += 1;
        }
        break;
      }

      /**
       * Create a new channel
       */
      case types.CHAT_CREATED: {
        const { chatId, kind, startedBy, userId, withUser } = action;

        // Channel exist ?
        if (!state.byId[chatId] || state.ids.indexOf(chatId) === -1) {
          // ids
          draft.ids.push(chatId);

          // byId
          draft.byId[chatId] = {
            id: chatId,
            kind,
            open: startedBy === userId, // Started by me?
            unread: 0,
            usersIds: [startedBy, withUser],
          };
        }

        // Only for myself
        if (startedBy === userId && action.type === types.CHAT_CREATED) {
          // Update current channel
          draft.active = chatId;
          // Update current view.
          // By default, we open a private chat
          draft.currentView = state.byId[chatId]?.kind || CHATS.CHAT_PRIVATE;
          // Send to notification's reducer
          action.view = draft.currentView;
        }

        if (window.location.href.includes('chat')) {
          window.focus();
        }

        break;
      }

      /**
       * Open a channel
       */
      case types.OPENED_CHAT: {
        const { chatId } = action;

        // Update current channel
        draft.active = chatId;
        draft.byId[chatId].open = true;

        // Update current view.
        // By default, we open a private chat
        draft.currentView = state.byId[chatId]?.kind || CHATS.CHAT_PRIVATE;
        // Send to notification's reducer
        action.view = draft.currentView;

        if (window.location.href.includes('chat')) {
          window.focus();
        }

        break;
      }

      /**
       * Close a channel
       */
      case types.CLOSED_CHAT: {
        // Close the current channel
        draft.byId[action.chatId].open = false;
        if (state.active === action.chatId) {
          // Reset the active chat
          draft.active = '';
          // Set the current view to the default view
          draft.currentView = CHATS.CHAT_HOME_PRIVATE;
        }
        break;
      }

      /**
       * Set the next active channel
       */
      case types.CHANGE_CHAT: {
        draft.active = action.chatId;

        if (!action.chatId) {
          draft.currentView = CHATS.CHAT_HOME_PRIVATE;
        }
        else {
          draft.currentView = state.byId[action.chatId]?.kind;
        }

        // Send to notification's reducer
        action.view = draft.currentView;

        if (window.location.href.includes('chat')) {
          window.focus();
        }

        break;
      }

      /**
       * Change Main chat view (chat - survey - document)
       */
      case types.CHANGE_MAIN_CHAT_VIEW: {
        draft.currentMainChatView = action.view;

        // Reset the 'unreadPolls' property
        if (action.view === CHATS.TAB_SURVEY) {
          draft.unreadPolls = 0;
        }
        break;
      }

      /**
       * Update current view.
       */
      case types.CHANGE_ACTIVE_VIEW:
        draft.currentView = action.view;
        break;

      /**
       * Set the number of unread messages, for a channel
       */
      case types.SET_CHAT_UNREAD: {
        if (state.byId[action.chatId]) {
          draft.byId[action.chatId].unread = action.unread || 0;
        }
        break;
      }

      case types.SET_POPOUT: {
        draft.popout = action.isOpen;
        break;
      }

      default:
        return state;
    }
  });
