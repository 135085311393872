import { v4 as uuid } from 'uuid';

/**
 * Local Import
 */
import { cleanCommand } from './utils';
import type { CommandProps } from './types';

/**
 * Pin command
 */
export const commandPin = (props: CommandProps) => {
  const { addMessage, actions, canPin, chatId, context, value } = props;

  // Clean message without the `/commands`
  const cleanedCommand = cleanCommand({ command: 'pin', value });

  if (cleanedCommand === '' || !canPin) return false;

  const content = {
    ops: [
      {
        insert: cleanedCommand,
      },
    ],
  };

  const payload = {
    chatId,
    content,
    context,
    isPinned: canPin,
    isCommand: true,
    messageUuid: uuid(),
  };

  // Send with `pin` option
  actions.sendMessage(payload);
  addMessage?.(payload);

  return canPin;
};
