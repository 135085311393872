/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from 'react-menu-list';

/*
 * Local Import
 */
import { Picker } from 'src/components/Format/Formatizer';

// Style
import * as S from './style';
import 'styles/picker.css';

/*
 * Component
 */
const EmojiPicker = React.forwardRef(({ onEmoji, icon }, ref) => {
  /*
   * State
   */
  const [displayPicker, setDisplayPicker] = useState(
    /** @type {Boolean} · Initial State */
    false,
  );

  /*
   * Variables
   */
  const Icon = S[icon];
  const popout = window.location.href.includes('chat');

  /*
   * Handlers
   */
  const setHandlePopin = (display) => () => {
    setDisplayPicker(display);
  };

  const handleEmoji = (...args) => {
    onEmoji(...args);
    setDisplayPicker(false);
  };

  return (
    <MenuButton
      type="button"
      // a11y
      buttonProps={{ 'aria-label': 'Sélectionnez un emoji' }}
      aria-haspopup="true"
      aria-expanded={displayPicker}
      // Modal
      onWillOpen={setHandlePopin(true)}
      onWillClose={setHandlePopin(false)}
      menuZIndex={15}
      menu={(
        <S.Menu popout={popout}>
          <S.ContainerPicker>
            <Picker ref={ref} onChange={handleEmoji} />
          </S.ContainerPicker>
        </S.Menu>
      )}
    >
      <Icon aria-hidden />
    </MenuButton>
  );
});

/*
 * PropTypes
 */
EmojiPicker.propTypes = {
  /**
   * Handles the addition of an emoji to the current message input.
   * @param {object} emoji
   * @return {void}
   */
  onEmoji: PropTypes.func,
  /** Name of icon for button */
  icon: PropTypes.string.isRequired,
};

EmojiPicker.defaultProps = {
  onEmoji: null,
};

/*
 * Export
 */
export default EmojiPicker;
