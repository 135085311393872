export const i18n = {
  search: 'Rechercher',
  clear: 'Effacer', // Accessible label on "clear" button
  notfound: 'Aucun Emoji trouvé',
  skintext: 'Sélectionnez une couleur de peau par défaut',
  categories: {
    search: 'Résultats de la recherche',
    recent: 'Fréquemment utilisés',
    smileys: 'Smileys & Émotions', // N'apparaît pas dans le picker
    people: 'Émoticônes & Personnages',
    nature: 'Animaux & Nature',
    foods: 'Nourriture & Boisson',
    activity: 'Activités',
    places: 'Voyages & Lieux',
    objects: 'Objets',
    symbols: 'Symboles',
    flags: 'Drapeaux',
    custom: 'Personnalisés',
  },
  categorieslabel: 'Catégories des Emojis', // Accessible title for the list of categories
  skintones: {
    1: 'Couleur de peau par défaut',
    2: 'Couleur de peau claire',
    3: 'Couleur de peau légèrement claire',
    4: 'Couleur de peau moyenne',
    5: 'Couleur de peau légèrement foncée',
    6: 'Couleur de peau foncée',
  },
};
