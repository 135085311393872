/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const InnerSurvey = styled.div`
  position: relative;
  height: calc(100% - 3rem);
  ${'' /* as long as Filters are note implemented height: calc(100% - 6rem); */}
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2rem;

  scrollbar-color: ${({ theme }) => theme.background._7} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background._7};
    border-radius: 5px;
  }
`;

export const Element = styled.div`
  width: 100%;
`;

export const NewPoll = styled.button(({ theme }) => ({
  background: theme.primary._1,
  color: theme.text._11,
  display: 'flex',
  borderRadius: '100%',
  position: 'fixed',
  bottom: '1rem',
  right: '1rem',
  padding: '.5rem',
  zIndex: 5,
}));
