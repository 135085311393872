/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import {
  setInitializeData,
  wsConnect,
  toggleChat,
  toggleUsers,
  logUserIn,
  courseLeft,
} from 'src/store/actions';
import Classroom from 'src/components/Classroom';

/*
 * Code
 */

// State
const mapStateToProps = (state) => ({
  menuNotificationsIsOpen: state.notifications.isOpen,
  menuSignatureIsOpen: state.signature.isOpen,
  courseTitle: state.course.courseTitle,
  readerIsOpen: state.notifications.readerIsOpen,
  chatOpened: state.settings.layout.chatOpened,
  usersOpened: state.settings.layout.usersOpened,
  resetSignaturesIsOpen: state.signature.resetIsOpen,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setInitializeData,
      wsConnect,
      toggleChat,
      toggleUsers,
      logUserIn,
      courseLeft,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Classroom);
