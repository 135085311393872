/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MenuLayout from 'src/components/TopBar/Menu/SubMenus/MenuLayout';
import { toggleLayout, resetLayout, changeTheme } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { displayChat, displayUsers, webcams, tooltips } = state.settings.layout;

  return {
    displayChat,
    displayUsers,
    webcams,
    tooltips,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ toggleLayout, resetLayout, changeTheme }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(MenuLayout);
