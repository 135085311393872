// ICON
export const icons = {
  ICON_INFO: 'IconInfoCircle',
  ICON_MESSAGE: 'IconCommentsAlt',
  ICON_DOCUMENT: 'IconFileAlt',
  ICON_SURVEY: 'IconChart',
  ICON_HAND: 'IconHand',
  ICON_EDUSIGN: 'IconEdusign',
};

// TYPE
export const titles = {
  PRIVATE_MESSAGE: 'Nouveau message privé',
  REQUEST_TALK: 'Demande de parole',
  REPORT_SOUND: 'Problème de son',
  REPORT_VIDEO: 'Problème de vidéo',
  NEW_DOCUMENT: 'Nouveau document',
  NEW_MENTION: 'Nouvelle mention',
  NEW_QUESTION: 'Nouvelle question',
  NEW_PIN: 'Message épinglé',
  NEW_SURVEY: 'Nouveau sondage',
  AUDIO_CONFERENCE_ACTIVE: 'Le mode discussion est disponible',
  AUDIO_CONFERENCE_INACTIVE: 'Le mode discussion est désactivé',
  MAX_TRACKS_REACHED: "Votre webcam n'est pas affichée",
  COURSE_END_SOON: 'Fin du cours',
  EDUSIGN_SIGNATURE_NEEDED: 'Émargement',
};

export const descriptions = {
  PRIVATE_MESSAGE: 'vous a envoyé un message',
  REQUEST_TALK: 'demande la parole',
  REPORT: "vous alerte d'un problème",
  NEW_DOCUMENT: 'Un nouveau document est disponible',
  NEW_MENTION: 'vous a mentionné',
  NEW_QUESTION: 'a posé une question',
  NEW_PIN: 'message a été épinglé',
  NEW_SURVEY: 'Un nouveau sondage est disponible',
  AUDIO_CONFERENCE_ACTIVE: 'Le formateur vous a autorisé à activer votre microphone',
  AUDIO_CONFERENCE_INACTIVE: "Le formateur vous a retiré le droit d'activer votre microphone",
  MAX_TRACKS_REACHED:
    'Vous pourrez activer votre webcam quand il y aura de la place au dessus du partage d’écran',
  COURSE_END_SOON: "C'est la fin du cours ! La classe virtuelle va être déconnectée dans XXmin.",
  EDUSIGN_STUDENT_SIGNATURE_NEEDED:
    "Vous êtes invité à signer la feuille d'émargement grâce au menu Émargement. Émarger vous permet de prouver votre présence, notamment à votre organisme financeur.",
  EDUSIGN_TEACHER_SIGNATURE_NEEDED:
    "N’oubliez pas d’envoyer la feuille d'émargement à vos apprenants pour qu’ils puisse prouver leur présence.",
};
