/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: 'auto',
  '.MuiDialog-paper': {
    display: 'flex',
    minWidth: '24rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    background: theme.background._2,
    border: `2px solid ${theme.text._7}`,
    borderRadius: '16px',
    ...theme.shadows.plus3,
  },
}));

export const Away = styled.div(({ theme }) => ({
  textAlign: 'center',
  background: theme.background._2,
  padding: '1rem',
  border: `1px solid ${theme.text._7}`,
  borderRadius: '16px',
  ...theme.shadows.plus2,
}));

export const Header = styled.h1(({ theme }) => ({
  color: theme.text._1,
  marginBottom: '.25rem',
  ...theme.typo.headlines.H5,
}));

export const Label = styled.div(({ theme }) => ({
  width: '100%',
  color: theme.text._1,
  marginBottom: '2rem',
  ...theme.typo.bodyText.body,
}));

export const Choose = styled.div({
  width: '100%',
});

export const SelectLabel = styled.span(({ theme }) => ({
  position: 'relative',
  color: theme.text._1,
  ...theme.typo.bodyText.smallBody,
}));

export const StyledSelect = styled.select(({ theme }) => ({
  border: `1px solid ${theme.text._5}`,
  boxSizing: 'border-box',
  borderRadius: '8px',
  marginTop: '.5rem',
  marginLeft: '50%',
  marginBottom: '2rem',
  transform: 'translate(-50%)',
  background: theme.background._2,
  color: theme.text._5,
  width: '10.75rem',
  height: '2.5rem',
  padding: '.5rem .75rem',
  ...theme.typo.labels.smallLabel,

  ...theme.focusVisible,
}));

export const Option = styled.option();

export const Buttons = styled.div({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
});

export const StyledButton = styled.button({}, ({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  flex: 1,
  ':first-of-type': {
    marginRight: '1rem',
  },
}));
