/**
 * Class to store keycloak instance
 */
export default class SlippersApi {
  static keycloakInstance;

  static apiUrl;

  static setKeycloakInstance(instance) {
    this.keycloakInstance = instance;
  }

  static setApiUrl(apiUrl) {
    this.apiUrl = apiUrl;
  }
}
