/*
 * Package Import
 */
import axios, { AxiosResponse, Method } from 'axios';

/*
 * Local Import
 */
import VM, { NOT_RESOLVED_VM, TRANSLATED_STATUS, StatusVMEnum } from 'src/constants/virtualDesktop';
import ROLES, { RoleEnum } from 'src/constants/roles';

// Helpers
import { isStudent, isBasic } from 'src/utils/roles';
import { getOrganization } from 'src/utils/organization';

// Schemas
import { MongoId } from 'src/schemas/Entities/utils';
import { User } from 'src/schemas/Entities/User';

/**
 * Types
 */
export type VMPayload = {
  url: string;
  method?: Method;
  params?: Record<string, unknown>;
  user: User;
};

/**
 * Get the translated status
 */
export const getTranslatedStatus = (item?: keyof typeof TRANSLATED_STATUS): string | undefined => {
  if (item && TRANSLATED_STATUS[item]) {
    return TRANSLATED_STATUS[item];
  }

  return item;
};

/**
 * Get the full VM address
 * @param  {String} user
 * @return {String}
 */
export const getFullVmAddress = (userId: MongoId) => `${userId}.${VM.PUBLIC_SUFFIX}.${VM.HOSTNAME}`;

/**
 * getVmAddress
 * @param  {String} user
 * @return {String}
 */
export const getVmAddress = (userId: MongoId): string => `${userId}.${VM.HOSTNAME}`;

/**
 * Get user role
 * @param  {String} user
 * @return {String}
 */
export const getUserRole = (userRole: RoleEnum) =>
  (isStudent(userRole) || isBasic(userRole) ? ROLES.ROLE_STUDENT : ROLES.ROLE_TEACHER);

/**
 *getUnresolvedStatus
 * @param status
 * @returns
 */
export const getUnresolvedStatus = (status: StatusVMEnum) =>
  NOT_RESOLVED_VM.some((element) => element === status);

/**
 * Request wrapper
 * @param  {String} method [default='POST']
 * @param  {String} url
 * @param  {Object} params [default={}]
 * @return {Promise}
 */
export const makeRequest = async ({
  user,
  method = 'POST',
  url,
  params = {},
}: VMPayload): Promise<AxiosResponse> => {
  // Constants
  const { vm_api: URL_VM_API, orga: organization } = await getOrganization();

  // Request
  return axios(`${URL_VM_API}${url}`, {
    method,
    headers: { 'x-organisation-name': organization },
    data: {
      name: getVmAddress(user.id),
      role: getUserRole(user.role),
      ...params,
    },
  });
};
