/*
 * Package Import
 */
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Redirect, Switch } from 'react-router-dom';

/**
 * Local Import
 */
import Init from 'src/components/Admin/Init';
import GiveAccess from 'src/components/Authorization/GiveAccess';
import ToastContainer from 'src/components/Admin/ToastContainer';
import NotFound from 'src/components/NotFound';

// Pages
import UserPage from 'src/components/Admin/Pages/User';
import PromotionPage from 'src/components/Admin/Pages/Promotion';
import CoursePage from 'src/components/Admin/Pages/Course';

// Provider
import QueryProvider from 'src/components/Admin/QueryProvider';

// Constants
import {
  ADMIN_URL,
  ADMIN_COURSES_URL,
  ADMINS_COURSES_URLS,
  ADMINS_USERS_URLS,
  ADMINS_PROMOTIONS_URLS,
  PAGE_NOT_FOUND_URL,
} from 'src/constants/routes';

/*
 * Code
 */
const Admin = (): JSX.Element => (
  <>
    <QueryProvider>
      <Init>
        <>
          <main style={{ width: '100%', overflow: 'auto' }}>
            <Switch>
              <Route exact path={ADMIN_URL}>
                <Redirect to={ADMIN_COURSES_URL} />
              </Route>
              <Route path={ADMINS_COURSES_URLS}>
                <GiveAccess when={(client) => client.isMinRole('supervisor')}>
                  <CoursePage />
                </GiveAccess>
              </Route>
              <Route path={ADMINS_USERS_URLS}>
                <GiveAccess when={(client) => client.isMinRole('admin')}>
                  <UserPage />
                </GiveAccess>
              </Route>
              <Route path={ADMINS_PROMOTIONS_URLS}>
                <GiveAccess when={(client) => client.isMinRole('admin')}>
                  <PromotionPage />
                </GiveAccess>
              </Route>
              <Route path={PAGE_NOT_FOUND_URL}>
                <NotFound />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </main>

          {/* ReactQuery DevTools */}
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </>
      </Init>
    </QueryProvider>

    {/* Toast */}
    <ToastContainer position="top-center" />
  </>
);

/**
 * Export
 */
export default Admin;
