/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import UserCard from 'src/components/Users/UsersPanel/List/UserCard/container';

// Helpers
import { VOICE_REQUEST_LABELS } from 'src/constants/controls';
import { HAND_STATUS } from 'src/constants/conference';

// Constants
import { StreamListStatus } from 'src/constants/mediaDevices';

// Style
import * as S from './style';

/*
 * Component
 */

const VoiceRequest = ({
  peerById,
  students,
  isClientTeacher,
  notifications,
  actions,
  isMaxTracksReached,
  streamListStatus,
}) => {
  /**
   * State
   */
  const [loading, setLoading] = useState(null);

  /*
   * Handlers
   */
  const removeNotification = (id) => {
    const currentNotif = notifications.find((notif) => notif.userIdRequest === id);
    if (currentNotif) {
      actions.deleteNotifications(currentNotif.id);
    }
  };

  const handleGiveVoice = (id) => {
    // When a stream is loading, catch it
    setLoading(id);

    actions.sendGiveVoice(id);
    removeNotification(id);
  };

  const handleRefuseGiveVoice = (id) => {
    if (loading === id) setLoading(null);

    actions.refuseGiveVoice(id);
    removeNotification(id);
  };

  const handleRefuseGiveVoiceAll = () => {
    students.forEach((user) => {
      if (peerById[user.id]?.hand === HAND_STATUS.RAISED) {
        actions.refuseGiveVoice(user.id);
        removeNotification(user.id);
      }
    });
  };

  /**
   * Unraise hand of current user
   * @return {void}
   */
  const stopSpeaking = (id, handStatus) => {
    if (handStatus === HAND_STATUS.VOICE_GIVEN) {
      if (loading === id) setLoading(null);

      actions.simpleSendTakeVoice(id);
    }
  };

  // Props
  const handRaised = students.filter((user) => peerById[user.id]?.hand === HAND_STATUS.RAISED);
  const voiceRequestDisabled = !!loading || isMaxTracksReached;
  let voiceRequestDisabledContent = '';

  if (isMaxTracksReached) {
    voiceRequestDisabledContent = VOICE_REQUEST_LABELS.FEATURE_DISABLED.MAX_VIDEO_TRACKS;
  }
  else if (loading) {
    voiceRequestDisabledContent = VOICE_REQUEST_LABELS.FEATURE_DISABLED.LOADING;
  }

  useEffect(() => {
    // When a stream is loading, catch it
    if (streamListStatus === StreamListStatus.READY) {
      setLoading(null);
    }
  }, [streamListStatus]);

  /**
   * Render
   */
  if (!students.length) {
    return null;
  }

  if (isClientTeacher) {
    return (
      <S.Container>
        <S.Header>
          <S.Title>Demande de parole</S.Title>
          <S.Button isCancel disabled={!handRaised.length} onClick={handleRefuseGiveVoiceAll}>
            {VOICE_REQUEST_LABELS.FEATURE_ALL_DENIED}
          </S.Button>
        </S.Header>
        <S.Content>
          {students.map((user) => (peerById[user.id]?.hand === HAND_STATUS.RAISED ? (
            <S.UserLine key={user.id} isLarge>
              <UserCard user={user} />
              <S.Buttons>
                <Tooltip content={voiceRequestDisabledContent} isEnabled={voiceRequestDisabled}>
                  <S.Button
                    isGreen
                    isIcon
                    disabled={voiceRequestDisabled}
                    onClick={() => handleGiveVoice(user.id)}
                  >
                    <S.IconCheck />
                  </S.Button>
                </Tooltip>
                <S.Button isRed isIcon onClick={() => handleRefuseGiveVoice(user.id)}>
                  <S.IconClose />
                </S.Button>
              </S.Buttons>
            </S.UserLine>
          ) : (
            <S.UserLine key={user.id}>
              <UserCard user={user} />
              <S.Button onClick={() => stopSpeaking(user.id, peerById[user.id]?.hand)} isCancel>
                {VOICE_REQUEST_LABELS.FEATURE_OFF}
              </S.Button>
            </S.UserLine>
          )),
          )}
        </S.Content>
      </S.Container>
    );
  }

  /** Default case */
  return (
    <S.Container>
      <S.Header>
        <S.Title>Demande de parole</S.Title>
      </S.Header>
      <S.Content>
        {students.map((user) => (
          <S.UserLine key={user.id} isLarge>
            <UserCard user={user} />
            {peerById[user.id]?.hand === HAND_STATUS.RAISED ? (
              <S.Label status={peerById[user.id]?.hand}>
                {VOICE_REQUEST_LABELS.FEATURE_AWAIT}
              </S.Label>
            ) : (
              <S.Label status={peerById[user.id]?.hand}>
                {VOICE_REQUEST_LABELS.FEATURE_ACCEPTED}
              </S.Label>
            )}
          </S.UserLine>
        ))}
      </S.Content>
    </S.Container>
  );
};

/*
 * PropTypes
 */
VoiceRequest.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  peerById: PropTypes.object.isRequired,
  students: PropTypes.array.isRequired,
  isClientTeacher: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  isMaxTracksReached: PropTypes.bool.isRequired,
  streamListStatus: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default VoiceRequest;
