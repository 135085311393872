/**
 * Types
 */
interface MarksProps {
  label: string;
  value: number;
}

/**
 * Marks text sizes
 */
export const marks: MarksProps[] = [
  {
    label: 'Petit',
    value: 0.75,
  },
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Grand',
    value: 1.5,
  },
];
