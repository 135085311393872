/**
 * Package Import
 */
import * as z from 'zod';

/**
 * Local Import
 */

export const limitationDTOSchema = z.array(
  z.object({
    featureTitle: z.string(),
    feature: z.string(),
    type: z.enum(['Limitation', 'OnOff', 'Consumption']),
    active: z.boolean(),
    quantity: z.number().optional(),
  }),
);

export type LimitationDTO = z.infer<typeof limitationDTOSchema>;
