/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

/*
 * Local Import
 */

// Types
import * as types from 'src/store/types';

// Style
import * as S from './style';

/*
 * Component
 */
const Feedback = ({
  isOpen,
  select,
  comment,
  error,
  loader,
  errorSend,
  helpersIds,
  teacherId,
  name,
  actions,
}) => {
  /*
   * Handlers
   */
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      event.preventDefault();

      // Clean Select & error
      actions.setInputFeedback(types.SET_SELECT_FEEDBACK);
      if (error) {
        actions.setErrorFeedback(false);
      }
      // ? keep message ?

      actions.setModalFeedback({ isOpen: false });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check value
    if (!select.length) {
      return actions.setErrorFeedback(true);
    }
    const data = {
      select,
      comment,
      helpersIds,
      teacherId,
      name,
    };
    // Send value
    return actions.sendFeedback(data);
  };

  const handleChange = (type) => (event) => {
    // Check error
    if (type === types.SET_SELECT_FEEDBACK && event.currentTarget.value.length) {
      actions.setErrorFeedback(false);
    }

    actions.setInputFeedback(types[type], event.currentTarget.value);
  };

  /*
   * Render
   */
  return (
    <S.Modal open={isOpen} onClose={handleClose} aria-labelledby="Feedback-modal">
      <S.Form className="form-group" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <S.Title id="report">Signaler un problème</S.Title>

        <S.ContainerFields>
          <S.Label>Votre problème concerne* :</S.Label>
          <S.Select value={select} onChange={handleChange(types.SET_SELECT_FEEDBACK)} error={error}>
            <option disabled value="">
              Choisir
            </option>
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </S.Select>
          {error && <S.ErrorHelper>Ce champs est obligatoire.</S.ErrorHelper>}
        </S.ContainerFields>
        <S.ContainerFields>
          <S.Label>Précisez votre problème :</S.Label>

          <S.Textarea
            aria-label="empty textarea"
            placeholder="Commentaire"
            value={comment}
            onChange={handleChange(types.SET_COMMENT_FEEDBACK)}
          />
        </S.ContainerFields>

        <S.FormAction>
          <S.Button isCancel onClick={handleClose} color="primary">
            Annuler
          </S.Button>
          <S.Button isMain onClick={handleSubmit} color="primary" disabled={error || loader}>
            {
              // eslint-disable-next-line no-nested-ternary
              loader ? (
                <CircularProgress color="inherit" size={16} />
              ) : errorSend ? (
                'Réessayer'
              ) : (
                'Envoyer'
              )
            }
          </S.Button>
        </S.FormAction>
      </S.Form>
    </S.Modal>
  );
};

/*
 * PropTypes
 */
Feedback.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State modal feedback */
  isOpen: PropTypes.bool.isRequired,
  /** Value of select */
  select: PropTypes.string.isRequired,
  /** Value of commment */
  comment: PropTypes.string.isRequired,
  /** Error if try send and Value of select is null */
  error: PropTypes.bool.isRequired,
  /** Loader indicator message send */
  loader: PropTypes.bool.isRequired,
  /** If back send an error */
  errorSend: PropTypes.bool.isRequired,
  /** name to send back for notif */
  name: PropTypes.string.isRequired,
  /** List ids teacher for send notif */
  teacherId: PropTypes.string.isRequired,
  /** List ids helpers for send notif */
  helpersIds: PropTypes.array.isRequired,
};

Feedback.defaultProps = {};

/*
 * Export
 */
export default Feedback;
