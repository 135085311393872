/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Quill } from 'react-quill';

/*
 * Local Import
 */
import Picker from 'src/components/Chats/Channel/Chat/Input/Picker';
import CreatePoll from 'src/components/Chats/Poll/Create/container';
import Tooltip from 'src/components/Tooltip/container';

// Helpers
import options from './data';

// Style
import * as S from './style';

/**
 * Init
 */
const icons = Quill.import('ui/icons');
icons['code-block'] = <S.CodeIcon />;
icons.bold = <S.BoldIcon />;
icons.italic = <S.ItalicIcon />;

/*
 * Component
 */
const Icon = ({ name }) => {
  const GoodIcon = S[name];
  return <GoodIcon aria-hidden />;
};

Icon.propTypes = {
  /** Name for react-icon */
  name: PropTypes.string.isRequired,
};

const Composer = ({
  actions,
  canAskSurvey,
  // canShare,
  onEmojiSelected,
  id,
}) => (
  <S.Composer role="list" id={id}>
    {/* Picker Emoji */}
    <li>
      <Tooltip content="Emoji">
        <Picker onEmoji={onEmojiSelected} icon="Smile" />
      </Tooltip>
    </li>

    {/* Send new poll */}
    {canAskSurvey && (
      <li>
        <Tooltip content="Envoyer un sondage">
          <button
            type="button"
            onClick={actions.openModalSurvey}
            // a11y
            aria-label="Créer un sondage"
            aria-haspopup="true"
          >
            <S.PollIcon aria-hidden />
          </button>
        </Tooltip>
        <CreatePoll />
      </li>
    )}

    {/* Send new documents */}
    {/* {canShare && (
      <Tooltip content="Joindre un document">
        <button
          type="button"
          // a11y
          aria-label="Joindre un document"
          aria-haspopup="true"
        >
          <S.DocumentIcon />
        </button>
      </Tooltip>
    )} */}

    {/* Format buttons */}
    {options.map((option) => (
      <li key={option.id}>
        <Tooltip content={option.name}>
          <button type="button" aria-label={option.name} className={`ql-${option.id}`}>
            <Icon name={option.icon} />
          </button>
        </Tooltip>
      </li>
    ))}
  </S.Composer>
);

/*
 * PropTypes
 */
Composer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** The user can write a poll */
  canAskSurvey: PropTypes.bool,
  /** The user can share a document with others */
  canShare: PropTypes.bool,
  /** Select an emoji */
  onEmojiSelected: PropTypes.func.isRequired,
  /** Id of container to connect to QuillJS */
  id: PropTypes.string.isRequired,
};

Composer.defaultProps = {
  canAskSurvey: false,
  canShare: false,
};

/*
 * Export
 */
export default React.memo(Composer);
