/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ChannelPreview from 'src/components/Chats/Channel/ChannelPreview';
import { triggerLocations } from 'src/constants/tracking';

// Actions
import { changeChannelId } from 'src/store/actions';

// Helpers
import { isHelper, isTeacher, isGhost } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  // Props
  const userProps = {};

  // Chats props
  const channel = state.chats.byId[ownProps.chatId];

  // Users props
  const client = state.users.client;
  const participant = channel.usersIds.find((userId) => userId !== client?.id);

  const user = participant
    ? state.users.userById?.[participant]
    : state.users.userById?.[client?.id];

  if (user) {
    userProps.user = user;
    userProps.label = user.name;

    // Role
    userProps.isMe = user.id === client?.id;
    userProps.isHelper = isHelper(user?.role);
    userProps.isTeacher = isTeacher(user?.role);
    userProps.isGhost = isGhost(user?.role);
  }

  return {
    ...userProps,
    channelId: channel.id,
    unread: channel.unread,
  };
};

// Actions
const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators(
    {
      changeChannelId: changeChannelId(ownProps.chatId, triggerLocations.PRIVATE_CHAT),
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(ChannelPreview);
