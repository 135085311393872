/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import TextSize from 'src/components/TopBar/Menu/SubMenus/MenuLayout/TextSize';
import { changeTextSize } from 'src/store/actions';

/*
 * Code
 */

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ changeTextSize }, dispatch),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(TextSize);
