/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Code
 */
export const notFoundError = {
  status: 404,
  message: "Cet utilisateur n'a pas de VM attribuée",
};

interface VmApiError extends Error {
  response: {
    data: string;
    status: number;
  };
}

export const isVmApiError = (error: unknown): error is VmApiError => {
  const { response } = error as VmApiError;
  return (
    response !== undefined
    && ['data', 'status'].every((property) => Object.prototype.hasOwnProperty.call(response, property))
  );
};
