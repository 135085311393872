/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Screen from 'src/components/Course/Screen';
import { enableScreen, setPopoutScreen, sendHasScreen } from 'src/store/actions';
import { getTeacher } from 'src/store/selectors/users';
import { isTeacher } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  antServer: state.course.antServer,
  teacherId: getTeacher(state),
  isClientTeacher: isTeacher(state.users.client.role),
  transcriptionEnabled: state.settings.layout.transcription,
  transcriptionText: state.transcription.data,
  userById: state.users.userById,
  courseId: state.course.courseId,
  popoutScreen: state.settings.popout.popoutScreen,
  hasScreen: state.course.hasScreen,
  screenEnabled: state.course.medias.screenEnabled,
  streamList: state.webRTC.streamList.screen,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ enableScreen, setPopoutScreen, sendHasScreen }, dispatch),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Screen);
