/* eslint-disable max-len */
/*
 * Package Import
 */
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';

/*
 * Local Import
 */

/*
 * Style
 */

export const CounterContainer = styled('div')((props) => ({
  backgroundColor: props.isActive ? props.theme.primary._5 : props.theme.background._4,
  borderRadius: '0.5rem',
  marginLeft: '1rem',
  padding: '0.35rem 0.35rem 0.35rem 0.35rem',
  width: '4rem',
  height: '2rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'space-evenly',
  '@media (max-width: 1440px)': {
    width: '2rem',
  },
}));

export const StyledUilUsersAlt = styled(UilUsersAlt, {
  shouldForwardProp: (prop) => isPropValid(prop),
})((props) => ({
  height: '1.4rem',
  color: props.isActive ? props.theme.primary._1 : props.theme.text._1,
  '@media (max-width: 1440px)': {
    display: 'none',
  },
}));

export const Number = styled('span')((props) => ({
  ...props.theme.typo.labels.label,
  color: props.isActive ? props.theme.primary._1 : props.theme.text._1,
}));
