/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { Infos, Name } from 'src/components/Chats/Channel/Chat/Message/style';

/*
 * Style
 */
export const Text = styled.div(({ chatAppearance, hasError, isPinned, theme }) => ({
  background: isPinned ? theme.primary._5 : theme.background._4,
  color: theme.text._2,
  borderRadius: '12px',
  padding: '.75rem 1rem',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
  ...theme.typo.bodyText.smallBody,
  fontSize: `${theme.textSize}rem `,
  opacity: hasError && 0.56,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',

  ...(chatAppearance === 'compact' && {
    background: isPinned ? theme.primary._5 : 'none',
    borderRadius: 0,
    padding: 0,
    display: 'inline-block',
    width: '100%',

    span: {
      verticalAlign: 'text-bottom',
    },

    [Infos]: {
      verticalAlign: 'text-bottom',
      [Name]: {
        marginLeft: 0,
      },
    },
  }),

  // eslint-disable-next-line id-length
  'a:not([role])': {
    color: `${theme.primary._2} !important`,
  },

  strong: {
    fontWeight: '700',
  },

  // eslint-disable-next-line id-length
  i: {
    fontStyle: 'italic',
  },

  '.formatizer-mention': {
    color: `${theme.primary._2} !important`,
  },

  '.formatizer-mention-me': {
    color: `${theme.info.main} !important`,
  },

  // To align emoji
  '.formatizer-emoji-container': {
    verticalAlign: 'text-top !important',
  },
  '.formatizer-emoji': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
