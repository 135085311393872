/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query/types';
import { useHistory, useRouteMatch } from 'react-router-dom';

/*
 * Local Import
 */
import Add from 'src/components/Admin/Modals/Add/Promotion';
import Update from 'src/components/Admin/Modals/Update/Promotion';
import Deactivate from 'src/components/Admin/Modals/Deactivate/Promotion';
import Reactivate from 'src/components/Admin/Modals/Reactivate/Promotion';
import ActivityExport from 'src/components/Admin/Modals/Activity/Promotion';
import UpdatePromotionUsers from 'src/components/Admin/Modals/PromotionUsers/Promotion';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { menuOptions } from 'src/components/Admin/TableElements/ActionsMenuOptions';
import { MongoId } from 'src/schemas/Entities/utils';
import { ParamsProps, actions, IdToAction } from 'src/constants/routes';
import { isBefore } from 'src/utils/time';
import { isProperUrl, getRoute } from 'src/utils/routes';
import * as T from 'src/components/Admin/TableElements';
import * as S from './style';
import useTable from './hooks/usePromotionTable';

/*
 * Code
 */
const PromotionTable = (): JSX.Element => {
  const { isError, data }: UseQueryResult<Promotion[] | Error> = useGetPromotions();

  const [idToAction, setIdToAction] = useState({
    update: null,
    deactivate: null,
    reactivate: null,
    activity: null,
    promotionUsers: null,
    add: null,
  } as IdToAction);

  const match = useRouteMatch();
  const params = match.params as ParamsProps;
  const history = useHistory();

  const handleAddModal = (): void => {
    setIdToAction({
      ...idToAction,
      add: null,
    });
    history.push(getRoute('promotionsAdd').getUrl({}));
  };
  const handleAddChildModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsAdd').getUrl({ id }));
    }
  };
  const handleDeactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsDeactivate').getUrl({ id }));
    }
  };
  const handleReactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsReactivate').getUrl({ id }));
    }
  };
  const handleUpdateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsUpdate').getUrl({ id }));
    }
  };
  const handleActivityModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsActivity').getUrl({ id }));
    }
  };
  const handlePromotionUsersModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('promotionsUsers').getUrl({ id }));
    }
  };

  useEffect(() => {
    if (!isProperUrl('promotions', data, params)) {
      history.push('/404');
    }

    if (params.action && params.id) {
      const { action, id } = params;
      setIdToAction((prevState) => ({
        ...prevState,
        [action]: id,
      }));
    }
  }, [params, data, history]);

  // eslint-disable-next-line max-len
  const {
    updateOption,
    deactivateOption,
    reactivateOption,
    activityOption,
    promoUsersOption,
    createChildPromo,
  } = menuOptions;

  const isChild = (rowId: MongoId | null) => {
    const currentPromotion = data?.find((promotion) => promotion.id === rowId);
    return currentPromotion?.isChild || false;
  };

  const updateHandle = (id: MongoId | null): void => {
    handleUpdateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      update: id,
    }));
  };
  const configuredUpdateOption = updateOption(updateHandle);
  const deactivateHandle = (id: MongoId | null): void => {
    handleDeactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      deactivate: id,
    }));
  };
  const isDeactive = (rowId: MongoId | null): boolean => {
    const currentPromotion = data?.find((promotion) => promotion.id === rowId);
    if (!currentPromotion) {
      return false;
    }
    return !!currentPromotion.deactivatedAt;
  };

  const isDeactivateDisplayed = (rowId: MongoId | null) => !isDeactive(rowId) && !isChild(rowId);
  const configuredDeactivateOption = deactivateOption(deactivateHandle, isDeactivateDisplayed);

  const reactivateHandle = (id: MongoId | null): void => {
    handleReactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      reactivate: id,
    }));
  };
  const isReactivateDisplayed = (rowId: MongoId | null) => isDeactive(rowId) && !isChild(rowId);
  const configuredReactivateOption = reactivateOption(reactivateHandle, isReactivateDisplayed);

  const activityHandle = (id: MongoId | null): void => {
    handleActivityModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      activity: id,
    }));
  };
  const hasStarted = (rowId: MongoId | null): boolean => {
    const currentPromotion = data?.find((promotion) => promotion.id === rowId);
    const today = new Date();

    if (!currentPromotion) {
      return false;
    }

    return isBefore(currentPromotion.start, today);
  };
  const configuredActivityOption = activityOption(activityHandle, hasStarted);

  const promotionUsersHandle = (id: MongoId | null): void => {
    handlePromotionUsersModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      promotionUsers: id,
    }));
  };
  const isPromoUsersDisplayed = (rowId: MongoId | null) => !isDeactive(rowId);
  const configuredPromotionUsersOption = promoUsersOption(
    promotionUsersHandle,
    isPromoUsersDisplayed,
  );

  const createChildPromoHandle = (id: MongoId | null): void => {
    handleAddChildModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      add: id,
    }));
  };
  const isCreateChildPromoDisplayed = (rowId: MongoId | null): boolean => !isChild(rowId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const configuredCreateChildPromoOption = createChildPromo(
    createChildPromoHandle,
    isCreateChildPromoDisplayed,
  );

  const [tableInstance, Table] = useTable(data);

  return (
    <S.Container>
      {!isError && (
        <>
          <Table
            title="Promotions"
            tableInstance={tableInstance}
            openAddModal={handleAddModal}
            menuOptions={[
              configuredUpdateOption,
              configuredPromotionUsersOption,
              configuredCreateChildPromoOption,
              configuredActivityOption,
              configuredReactivateOption,
              configuredDeactivateOption,
            ]}
            customInterface={{
              topLeft: <T.ArchiveFilter tableInterface={tableInstance} isFeminine />,
            }}
          />
          <Add
            isOpen={!!params.action && params.action === actions.add}
            parentId={idToAction.add}
          />
          <Update
            isOpen={!!params.action && !!params.id && params.action === actions.update}
            idToUpdate={idToAction.update}
          />
          <Deactivate
            isOpen={!!params.action && !!params.id && params.action === actions.deactivate}
            idToDeactivate={idToAction.deactivate}
          />
          <Reactivate
            isOpen={!!params.action && !!params.id && params.action === actions.reactivate}
            idToReactivate={idToAction.reactivate}
          />
          <ActivityExport
            isOpen={!!params.action && !!params.id && params.action === actions.activity}
            idToExport={idToAction.activity}
          />
          <UpdatePromotionUsers
            isOpen={!!params.action && !!params.id && params.action === actions.promotionUsers}
            idToAddUsers={idToAction.promotionUsers}
          />
        </>
      )}
    </S.Container>
  );
};

/*
 * Export
 */

export default PromotionTable;
