/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Conference from 'src/components/Course/Conference';
import { getConferencePeers } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  audioPeers: state.course.audioPeers,
  conferenceStatus: state.course.conferenceStatus,
  websocketStatus: state.socket.status,
  webRTCConnected: state.webRTC.connected,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getConferencePeers,
    },
    dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Conference);
