/**
 * Recursive function to return a string with all content of block
 * @param {array} data Data
 * @param {number} currentIndex Starting index
 * @param {string} attribute Block format you want check
 * @param {string} [result] Result used for recursivity
 * @returns string
 */
export const checkBlock = (data, currentIndex, attribute, result = '') => {
  if (
    !data[currentIndex]
    // eslint-disable-next-line max-len
    || (data[currentIndex].attributes && !data[currentIndex].attributes[attribute])
  ) {
    return result;
  }

  return checkBlock(
    data,
    currentIndex + 2,
    attribute,
    result
      + (data[currentIndex - 1]?.insert || '')
      // If no code-block after, don't add insert (to fix span at the end)
      + (data[currentIndex + 2]?.attributes?.[attribute] ? data[currentIndex].insert : ''),
  );
};
