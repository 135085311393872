/*
 * Package Import
 */
import styled from '@emotion/styled';
import { MenuButton as MenuButtonImported, Menu as MenuInner } from '@szhsin/react-menu';
import {
  UilVolume,
  UilVolumeMute,
  UilVideo,
  UilVideoSlash,
  UilPlay,
  UilPause,
  UilEllipsisV,
  UilExternalLinkAlt,
} from '@iconscout/react-unicons';

import { ReactComponent as Hand } from 'assets/images/icons/hand.svg';
import { menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';

/*
 * Local Import
 */

/*
 * Icons
 */
const icons = {
  audio: {
    on: UilVolumeMute,
    off: UilVolume,
  },
  video: {
    on: UilVideoSlash,
    off: UilVideo,
  },
  videoPlay: {
    on: UilPlay,
    off: UilPause,
  },
};

const iconStyle = (theme) => ({
  color: theme.text._1,
  height: '1.25rem',
  width: '1.25rem',
  marginRight: '.5rem',
});

export const IconHand = styled(Hand)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Button = ({ id, isMute }) => {
  const status = isMute ? 'off' : 'on';
  return styled(icons[id][status])(({ theme }) => ({
    ...iconStyle(theme),
  }));
};

export const IconExternalWindow = styled(UilExternalLinkAlt)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const StyledUilMenu = styled(UilEllipsisV)(({ theme }) => ({
  height: '1.25rem',
  width: '1.25rem',
  color: theme.colors.monochrome.white,
}));

/*
 * Code
 */
// Button opening the main menu
export const MenuButton = styled(MenuButtonImported)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  background: theme.colors.coolGrey._900,
  opacity: '0.7',
  margin: '.25rem',
  borderRadius: '4px',
  height: '1.75rem',
  width: '1.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1',
  ':hover': {
    opacity: '1',
  },
  ':active': {
    background: theme.background._7,
    border: 'none',
  },
  ':focus-visible': {
    background: 'none',
    border: `2px solid ${theme.primary._1}`,
  },
  ':-moz-focusring': {
    background: 'none',
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const Menu = styled(MenuInner)(({ theme }) => ({
  [menuSelector.name]: {
    position: 'absolute',
    background: theme.background._2,
    color: theme.text._1,
    borderRadius: '0.5rem',
    border: 'none',
    minWidth: '15rem',
    marginLeft: '1.8rem',
    ...theme.shadows.plus2,
  },

  [menuItemSelector.name]: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  [menuItemSelector.hover]: {
    background: theme.background._3,
  },
}));

// Container of label
export const ContainerLabel = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const Name = styled.span(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  bottom: '.15rem',
  left: '.25rem',
  color: theme.colors.monochrome.white,
  background: 'rgba(26, 32, 46, 0.56)',
  borderRadius: '4px',
  padding: '.25rem',
  ...theme.typo.labels.tinyLabel,
}));

export const Webcam = styled.li(({ theme, isDisplayed, isOpenPopout }) => ({
  maxWidth: '160px',
  height: '120px',
  background: theme.colors.coolGrey._400,
  borderRadius: '8px',
  position: 'relative',
  display: !isOpenPopout && isDisplayed ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '0',
  margin: '0 0.25rem',
  ':hover': {
    background: theme.colors.monochrome.black,
    [MenuButton]: {
      opacity: 1,
    },
  },
  ':first-of-type': { marginLeft: 0 },
  ':last-of-type': { marginRight: 0 },
}));

export const Audio = styled.audio`
  width: 100%;
  overflow: auto;
`;

export const Video = styled.video`
  height: 100%;
  overflow: auto;
  border-radius: 8px;
`;
export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarContainer = styled.div(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Avatar = styled.img((props) => ({
  position: 'relative',
  borderRadius: '8px',
  minWidth: '3.5rem',
  width: '3.5rem',
  height: '3.5rem',
  backgroundImage: `url(${props.picture})`,
  backgroundColor: props.theme.background._7,
  backgroundSize: 'cover',
}));
