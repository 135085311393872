/*
 * Package Import
 */
import { useEffect, useRef } from 'react';

/**
 * Hooks ~ useInterval
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param  {Function} callback
 * @param  {Number}   [delay=1000]
 * @return {IntervalFunction}
 */
export const useInterval = (callback, delay = 1000) => {
  const intervalRef = useRef(null);
  const callbackRef = useRef(callback);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setInterval ticks again, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // interval will be reset.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof delay === 'number') {
      intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

      // Clear interval if the components is unmounted or the delay changes:
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  // In case you want to manually clear
  // the interval from the consuming component...
  return intervalRef;
};
