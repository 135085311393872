/*
 * Package Import
 */
import { combineReducers } from 'redux';

/*
 * Local Import
 */
import layout from './layout';
import notifications from './notifications';
import popout from './popout';

/*
 * Export
 */
export default combineReducers({
  layout,
  notifications,
  popout,
});
