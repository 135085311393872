/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ManagementVM from 'src/components/VM';
import { setVirtualDesktopStatus } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  status: state.virtualDesktop.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setVirtualDesktopStatus }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(ManagementVM);
