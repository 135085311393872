/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';
import { getSave } from 'src/store/middlewares/localStorage';

/**
 * Types
 */
export interface SettingsNotificationPartsState {
  enabled: boolean;
  soundEnabled: boolean;
  generalEnabled: boolean;
  privateEnabled: boolean;
  surveyEnabled: boolean;
  mentionEnabled: boolean;
  questionEnabled: boolean;
  pinEnabled: boolean;
  documentEnabled: boolean;
}

export interface SettingsNotificationState extends SettingsNotificationPartsState {
  disableActiveWindow: boolean;
  sound: 'default';
  chatId: string | null;
  contentId: string | null;
}

/*
 * Code
 */
const localState: Partial<SettingsNotificationState> = getSave('settings', 'notifications') || {};

/*
 * Initial State
 */
const initialState: SettingsNotificationState = {
  enabled: true,
  generalEnabled: false,
  privateEnabled: true,
  mentionEnabled: true,
  pinEnabled: true,
  questionEnabled: true,
  surveyEnabled: true,
  documentEnabled: true,
  disableActiveWindow: false,
  soundEnabled: true,
  sound: 'default',
  ...localState,
  chatId: null,
  contentId: null,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.TOGGLE_NOTIFICATION:
        if (typeof action.name === 'string') {
          const notificationName = action.name as keyof SettingsNotificationPartsState;
          draft[notificationName] = !state[notificationName];
        }
        break;

      case types.CHANGE_NOTIFICATIONS_SOUND:
        draft.sound = action.sound;
        break;

      case types.RECEIVED_MESSAGE: {
        if (
          state.enabled
          && (state.generalEnabled
            || state.privateEnabled
            || state.surveyEnabled
            || state.mentionEnabled)
        ) {
          draft.contentId = action.id;
          draft.chatId = action.chatId;
        }
        break;
      }

      default:
        return state;
    }
  });
