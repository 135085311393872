/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilAngleDoubleUp } from '@iconscout/react-unicons';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: ${(props) => (props.isMenuDisplay ? 0 : '-6.5rem')};
  transition: all 0.3s ease-out;
`;

export const IconArrow = styled(UilAngleDoubleUp)(({ theme }) => ({
  color: theme.text._5,
}));

export const Button = styled.button(({ isMenuDisplay }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '2rem',
  margin: 'auto',

  [IconArrow]: {
    transform: isMenuDisplay && 'rotate(180deg)',
  },
}));
