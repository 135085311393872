import { useEffect, useState, useRef, useCallback } from 'react';

export const useEvent = (event, initial) => {
  // state to check if event was detected
  const [eventFlag, setEventFlag] = useState(initial);

  // if event detected for this first time, flag at true
  const handleWindowEventCallback = () => {
    if (!eventFlag) {
      setEventFlag(true);
    }
  };

  // We keep ref of callback always updated
  const handleWindowEventRef = useRef(handleWindowEventCallback);
  useEffect(() => {
    handleWindowEventRef.current = handleWindowEventCallback;
  }, [handleWindowEventCallback]);
  const handleWindowEvent = useCallback((evt) => handleWindowEventRef.current(evt), []);

  // We watch event received and remove listener if unmount
  useEffect(() => {
    window.addEventListener(event, handleWindowEvent);
    return () => window.removeEventListener(event, handleWindowEvent);
  }, []);

  // reset event flag
  const resetEventFlag = () => {
    setEventFlag(initial);
  };

  return [eventFlag, resetEventFlag];
};
