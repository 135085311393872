/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@szhsin/react-menu';

/*
 * Local Import
 */
import { useAction } from 'src/components/Classroom/hooks';
import { isEnter, isSpace } from 'src/utils/events';
import * as S from './style';

/**
 * Component
 */
const MoreOptions = ({
  actions,
  canAsk,
  canCopy,
  canDelete,
  canEdit,
  isAnswered,
  isAsked,
  handleCopy,
  handleDelete,
  handleEdit,
  messageId,
}) => {
  const { setMessageAsQuestion, setQuestionAnswered } = useAction();

  /**
   * Flag the message as a question
   * @return {void}
   */
  const onAsk = () => {
    if (isAsked && isAnswered) {
      actions.setQuestionMessageAnsweredStatus({ answered: false });
      setQuestionAnswered({ messageId, answered: false });
    }
    else {
      actions.askMessage();
      setMessageAsQuestion({ messageId });
    }
  };

  /**
   *  Execute function with keyboard event (space + enter)
   * @param  {Function} action
   * @param  {Event} evt
   */
  const onKeyDown = (action) => (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      action();
    }
  };

  /**
   * Props
   */
  const canAskMessage = canAsk && !isAsked;
  const canReflagAskMessage = canAsk && isAsked && isAnswered;

  /*
   * Render
   */
  if (!canAsk && !canCopy && !canDelete && !canEdit) {
    return null;
  }

  return (
    <>
      <S.Menu
        align="end"
        direction="top"
        menuButton={({ open }) => (
          <S.Button
            direction="top"
            role="button"
            aria-label="Plus d‘options"
            aria-expanded={open}
            aria-haspopup="true"
          >
            <S.More />
          </S.Button>
        )}
      >
        {/* Copy */}
        {canCopy && (
          <MenuItem onClick={handleCopy} onKeyDown={onKeyDown(handleCopy)}>
            <S.Icon>
              <S.Copy />
            </S.Icon>
            <S.Label>Copier le message</S.Label>
          </MenuItem>
        )}

        {/* Ask */}
        {(canAskMessage || canReflagAskMessage) && (
          <MenuItem onClick={onAsk} onKeyDown={onKeyDown(onAsk)}>
            <S.Icon>
              <S.Question />
            </S.Icon>
            <S.Label>Question</S.Label>
          </MenuItem>
        )}

        {/* Edit */}
        {canEdit && (
          <MenuItem onClick={handleEdit} onKeyDown={onKeyDown(handleEdit)}>
            <S.Icon>
              <S.Edit />
            </S.Icon>
            <S.Label>Modifier</S.Label>
          </MenuItem>
        )}

        {/* Delete */}
        {canDelete && (
          <MenuItem onClick={handleDelete} onKeyDown={onKeyDown(handleDelete)}>
            <S.Icon>
              <S.Delete />
            </S.Icon>
            <S.Label>Supprimer</S.Label>
          </MenuItem>
        )}
      </S.Menu>
    </>
  );
};

/*
 * PropTypes
 */
MoreOptions.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** The user has permission to flag the current message as a question */
  canAsk: PropTypes.bool,
  /** The user can copy message (navigator permission/support) */
  canCopy: PropTypes.bool.isRequired,
  /** The user has permission {Myself|Educator} to delete the current message */
  canDelete: PropTypes.bool,
  /** The user has permission {Myself|Educator} to edit the current message */
  canEdit: PropTypes.bool,
  /** The current question is flagged as answered. */
  isAnswered: PropTypes.bool,
  /** The current message is flagged as a question */
  isAsked: PropTypes.bool,
  /**
   * Handler for copying the current message
   * @param {MouseEvent} event
   * @return {void}
   */
  handleCopy: PropTypes.func.isRequired,
  /**
   * Handler for deleting the current message
   * @param {MouseEvent} event
   * @return {void}
   */
  handleDelete: PropTypes.func.isRequired,
  /**
   * Handler for editing the current message
   * @param {MouseEvent} event
   * @return {void}
   */
  handleEdit: PropTypes.func.isRequired,
  /** Message ID */
  messageId: PropTypes.string.isRequired,
};

MoreOptions.defaultProps = {
  canEdit: false,
  canDelete: false,
  canAsk: false,
  isAnswered: false,
  isAsked: false,
};

/*
 * Export
 */
export default MoreOptions;
