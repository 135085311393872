/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { UserDTO, UserDisplayBeforeCreate } from 'src/schemas/Entities/User';
import { transformUsersDisplayToUsersDTOWithoutId } from 'src/schemas/Adapters/Users/transformUserDisplayToUserDTOWithoutId';
import { API_CREATE_USERS, API_POST } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const createUsers = async (users: UserDisplayBeforeCreate[]): Promise<UserDTO[]> => {
  try {
    const usersDTOWithoutId = transformUsersDisplayToUsersDTOWithoutId(users);

    const payload: MutationPayload = {
      method: API_POST,
      url: API_CREATE_USERS,
      data: usersDTOWithoutId,
    };
    const { data: usersData } = await api(payload);

    return usersData as UserDTO[];
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
