/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import Notification from './Notification/container';

// Styles
import * as S from './style';

/*
 * Component
 */
const Notifications = ({ actions, allNotifications, chatPanelOpened }) => {
  /*
   * State
   */
  const [cls, setCls] = useState('');
  const [openDelete, setOpenDelete] = useState(false);

  /*
   * Handlers
   */
  const handleConfimDelete = () => {
    setOpenDelete(false);
    setCls('removed');
    setTimeout(() => {
      actions.deleteNotifications();
    }, 500);
  };

  const handleCancel = () => {
    setOpenDelete(false);
  };

  const handleDeleteNotification = () => {
    setOpenDelete(!openDelete);
  };

  const handleCloseMenu = () => {
    actions.setMenuNotifications({ isOpen: false });
  };

  /**
   * Get the position (top, right) of `notifications` panel
   * @return {Object}
   */
  const getNotificationsPanelPosition = () => {
    const defaultMargin = 16;
    const elementRight = document.getElementById('workspace-drawer-right');
    const elementNavigation = document.getElementById('workspace-navigation');

    return {
      top: elementNavigation.offsetHeight + defaultMargin,
      right: chatPanelOpened ? elementRight.offsetWidth + defaultMargin : defaultMargin,
    };
  };

  /*
   * Component
   */
  const allNotif = allNotifications.map((notif) => (
    <Notification
      newClass={cls}
      key={notif.id}
      id={notif.id}
      title={notif.title}
      description={notif.description}
      // @TODO trouver un nom générique
      userIdMessage={notif.userIdMessage}
    />
  ));

  /*
   * Render
   */
  return (
    <S.NotificationModal
      isOpen
      onRequestClose={handleCloseMenu}
      {...getNotificationsPanelPosition()}
    >
      <S.NotificationsList>
        <S.Header>
          <S.Title>Centre de notification</S.Title>
          <S.Actions>
            <li>
              <Tooltip content="Tout supprimer">
                <S.Action
                  disabled={allNotifications.length === 0}
                  onClick={handleDeleteNotification}
                >
                  <S.DeleteIcon
                    role="img"
                    toRemove={allNotifications.length === 0}
                    aria-label="Appuyez ici pour supprimer toutes les notifications"
                  />
                </S.Action>
              </Tooltip>
            </li>
          </S.Actions>
        </S.Header>
        <S.List blur={openDelete}>
          {allNotif}
          <S.Confim open={openDelete}>
            <S.Text>Supprimer toutes les notifications ?</S.Text>
            <S.Buttons>
              <S.Button isMain onClick={handleConfimDelete}>
                Oui
              </S.Button>
              <S.Button onClick={handleCancel} isCancel>
                Non
              </S.Button>
            </S.Buttons>
          </S.Confim>
        </S.List>
        {!allNotifications.length && (
          <S.Empty>
            <S.CheckContainer>
              <S.CheckIcon />
            </S.CheckContainer>
            Aucune notification
          </S.Empty>
        )}
      </S.NotificationsList>
    </S.NotificationModal>
  );
};

/*
 * Proptypes
 */
Notifications.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Array contain alls notifications */
  allNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      userIdMessage: PropTypes.string,
    }),
  ).isRequired,
  /** The right panel (chat) is opened ? */
  chatPanelOpened: PropTypes.bool.isRequired,
};

Notifications.defaultProps = {};

/*
 * Export
 */
export default Notifications;
