/*
 * Package import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local import
 */
import * as S from './style';

/*
 * Component
 */
const Mention = ({ name, prefix, isMe }) => (
  <S.Text isMe={isMe}>
    {prefix}
    {name}
  </S.Text>
);

/*
 * PropTypes
 */
Mention.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  isMe: PropTypes.bool.isRequired,
};

Mention.defaultProps = {
  name: 'Utilisateur inconnu',
  prefix: '@',
};

/*
 * Export
 */
export default React.memo(Mention);
