import { getCoursesChildrenOfUser } from 'src/schemas/Repositories/courses';
import type { MongoId } from 'src/schemas/Entities/utils';
import type { Course } from 'src/schemas/Entities/Course';
import type { Option } from 'src/components/Admin/Modals/FormElements/type';
import { roles } from 'src/constants/roles';
import type { Client, User, UserDisplayBeforeCreate } from 'src/schemas/Entities/User';

// Can't use directly schema FormData type b/c cyclic dependency
export interface UserFormDataPartialStatic {
  id?: MongoId;
  role: Option | null;
  email: string;
}
export interface UserCsvImportTablePartialStatic {
  id: string;
  email: string;
}
export const findCoursesAndCheckIfUserMustBePeda = (
  data: UserFormDataPartialStatic,
  courses: Course[] | undefined,
): boolean => {
  if (data.id) {
    if (courses) {
      const userCourses = getCoursesChildrenOfUser({
        courses,
        id: data.id,
      });

      if (!userCourses || userCourses.length === 0) {
        return true;
      }
      if (userCourses.length > 0 && !roles.find((role) => role.name === data.role?.value)?.isPeda) {
        return false;
      }
    }
  }
  return true;
};

export const findClientRoleAndCheckIfRoleGivenIsLowerOrEqual = (
  data: UserFormDataPartialStatic,
  client: Client | null,
  users: User[] | undefined,
): boolean => {
  if (!client) return false;
  // We don't use client directly bc he has maybe been updated since init
  const clientInCache = users?.find((user) => user.id === client.id);
  if (!clientInCache) return false;
  const oldUserData = users?.find((user) => user.id === data.id);
  // If you don't find role score, take extreme score to guard problems
  const clientRoleScore = roles.find((role) => role.name === clientInCache.role)?.score ?? 0;
  const newRoleScore = roles.find((role) => role.name === data.role?.value)?.score ?? Infinity;
  if (clientRoleScore < newRoleScore) {
    // if Update form and role is not changed, don't block
    if (data.id && data.role?.value === oldUserData?.role) return true;
    return false;
  }
  return true;
};

export const findClientRoleAndCheckIfUserUpdatedIsRoleLower = (
  id: MongoId,
  client: Client | null,
  users: User[] | undefined,
): boolean => {
  if (!client) return false;
  // We don't use client directly bc he has maybe been updated since init
  const clientInCache = users?.find((user) => user.id === client.id);
  if (!clientInCache) return false;
  // If the client is the user modified, he can update itself
  if (clientInCache.id === id) {
    return true;
  }
  const clientRoleScore = roles.find((role) => role.name === clientInCache.role)?.score ?? 0;
  // SuperAdmin can modify even other superadmins
  if (clientRoleScore >= 1000) return true;
  const oldUserData = users?.find((user) => user.id === id);
  if (!oldUserData) return false;
  // If you don't find role score, take extreme score to guard problems
  // eslint-disable-next-line max-len
  const oldUserDataRoleScore = roles.find((role) => role.name === oldUserData.role)?.score ?? Infinity;
  if (clientRoleScore <= oldUserDataRoleScore) {
    return false;
  }
  return true;
};

export const isMailNotAlreadyUsedInUser = (
  data: UserFormDataPartialStatic | UserCsvImportTablePartialStatic,
  users: User[],
): boolean => {
  const otherUsers = users?.filter((user) => user.id !== data.id);
  const mails = otherUsers?.map((user) => user.email);
  const isMailNotUsed = !mails?.includes(data.email);
  return isMailNotUsed;
};

export const isMailNotAlreadyUsedInUserDisplayBeforeCreate = (
  data: UserFormDataPartialStatic | UserCsvImportTablePartialStatic,
  users: UserDisplayBeforeCreate[],
): boolean => {
  const otherUsers = users?.filter((user) => user.id !== data.id);
  const mails = otherUsers?.map((user) => user.email);
  const isMailNotUsed = !mails?.includes(data.email);
  return isMailNotUsed;
};
