/*
 * Package Import
 */
import { format, formatRelative } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

/*
 * Local Import
 */
import { DateString, TimeString } from 'src/schemas/Entities/utils';
import { pad } from 'src/utils';

/*
 * Code
 */
const defaultDisplayTime = "HH'\u00A0h\u00A0'mm"; // e.g. 01 h 23

/**
 * Is this a past day?
 * @param  {Number} time - Timestamps [1584380429415]
 * @return {Boolean}
 */
export const isPastDay = (time: number): boolean => {
  // Time
  const dateTime = new Date(time);
  const dateTimeYear = dateTime.getFullYear();

  // Now
  const now = new Date();
  const nowYear = now.getFullYear();

  return (
    // A year before
    dateTimeYear < nowYear
    // Or a month before
    || (dateTimeYear === nowYear && dateTime.getMonth() < now.getMonth())
    // Or a day before
    || dateTime.getDate() < now.getDate()
  );
};

/**
 * Format the time
 * @param  {Number} time  · Timestamps · e.g : 1584380429415
 * @param  {String} style · [default=defaultDisplayTime]
 * @return {String}       · e.g : 15 h 16
 */
export const getFormatTime = (time: number, style = defaultDisplayTime) =>
  format(time, style, {
    locale: frLocale,
  });

/**
 * getFormatRelative
 * @param  {Number} time • Timestamps · e.g : 1584380429415
 * @return {String}
 */
export const getFormatRelative = (time: number) => {
  const formatRelativeLocale = {
    lastWeek: 'eeee',
    yesterday: "'hier'",
    today: defaultDisplayTime,
    tomorrow: 'P',
    nextWeek: 'P',
    other: 'P',
  };

  const locale = {
    ...frLocale,
    formatRelative: (token: keyof typeof formatRelativeLocale): string =>
      formatRelativeLocale[token],
  };

  return formatRelative(time, new Date(), { locale });
};

/**
 * getFormatRelativeFull
 * @param  {Number} time • Timestamps · e.g : 1584380429415
 * @return {String}
 */
export const getFormatRelativeFull = (time: number): string => {
  const formatRelativeLocale = {
    lastWeek: "eeee 'dernier à' p",
    yesterday: "'hier à' p",
    today: "'aujourd’hui à' p",
    tomorrow: "'demain à' p'",
    nextWeek: "eeee 'prochain à' p",
    other: 'P',
  };

  const locale = {
    ...frLocale,
    formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
  };

  return formatRelative(time, new Date(), { locale });
};

/**
 * Get the Day from time
 * @param  {Number} time - Timestamps [1584380429415]
 * @return {String}      - Monday, Tuesday, ...
 */
export const getDay = (time: number): string => format(time, 'EEEE');

/**
 * Format the full time
 * @param  {Number} time - Timestamps [1584380429415]
 * @return {String}      - "Tuesday, October 29, 2019 2:47 PM"
 */
export const getFormatFullTime = (time: number): string =>
  format(time, 'EEEE, dd LLLL yyyy, kk:mm', {
    locale: frLocale,
  });

/**
 * Elapsed time
 * @param  {Number} seconds
 * @return {String}         * Ex: 08:06, 01:18:60
 */
export const getElapsed = (seconds: number): string => {
  const hours = Math.floor((seconds / 3600) % 24);
  const minutes = Math.floor((seconds / 60) % 60);
  const secondes = Math.floor(seconds % 60);

  if (seconds > 0) {
    if (hours) {
      return `${pad(hours)}:${pad(minutes)}:${pad(secondes)}`;
    }

    return `${pad(minutes)}:${pad(secondes)}`;
  }
  return `${pad(0)}:${pad(0)}`;
};

/**
 * Get time duration
 * @param  {Number} time - Timestamps [1584380429415]
 */
export const getTimeDuration = (time: number) => {
  const hours = Math.floor(time / 1000 / 60 / 60);
  const minutes = Math.floor(time / 1000 / 60) % 60;
  const seconds = Math.floor(time / 1000) % 60;
  return { hours, minutes, seconds };
};

/**
 * Date to number
 * @param {Date} date
 * @returns {Number} * Ex : "20211206"
 */
export const dateToNumberValue = (date: Date): number => {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return parseInt([year.toString(), month, day].join(''), 10);
};

/**
 * dateStringToNumberValue
 */
export const dateStringToNumberValue = (dateString: DateString): number =>
  parseInt(dateString.split('-').join(''), 10);

/**
 * isBefore
 */
export const isBefore = (firstDate: Date, secondDate: Date): boolean =>
  dateToNumberValue(firstDate) < dateToNumberValue(secondDate);

/**
 * isAfter
 */
export const isAfter = (firstDate: Date, secondDate: Date): boolean =>
  dateToNumberValue(firstDate) > dateToNumberValue(secondDate);

/**
 * isEqual
 */
export const isEqual = (firstDate: Date, secondDate: Date): boolean =>
  dateToNumberValue(firstDate) === dateToNumberValue(secondDate);

/**
 * isBeforeOrEqual
 */
export const isBeforeOrEqual = (firstDate: Date, secondDate: Date): boolean =>
  isBefore(firstDate, secondDate) || isEqual(firstDate, secondDate);

/**
 * isAfterOrEqual
 */
export const isAfterOrEqual = (firstDate: Date, secondDate: Date): boolean =>
  isAfter(firstDate, secondDate) || isEqual(firstDate, secondDate);

/**
 * Time string to number value
 */
export const timeStringToNumberValue = (timeString: TimeString): number =>
  parseInt(timeString.split(':').join(''), 10);
