/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Item from 'src/components/Chats/Poll/Item';

// Actions
import { voteSurvey } from 'src/store/actions';

/**
 * Code
 */
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      voteSurvey,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(Item);
