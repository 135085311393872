/* eslint-disable @typescript-eslint/no-unused-vars */
import produce from 'immer';
import { AnyAction } from 'redux';

import * as types from 'src/store/types';

const initialState = null;

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.INITIALIZE_KC_TOKEN:
        return action.token;

      default:
        return state;
    }
  });
