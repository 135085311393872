/*
 * Package import
 */
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

/*
 * Local import
 */
import CHATS from 'src/constants/chats';
import type { RootState } from 'src/store';

/**
 * Get currently opened chat
 */
export const getOpenedChat = createSelector(
  (state: RootState) => state.chats.active,
  (state: RootState) => state.chats.byId,
  (activeId, byId) => byId[activeId || ''],
);

/**
 * Get private channels that are open
 * @return {Array}
 */
export const getOpenChannelsIds = createSelector(
  (state: RootState) => state.chats.ids,
  (state: RootState) => state.chats.byId,
  (ids, byId) =>
    ids.filter((id) => byId[id]?.kind === CHATS.CHAT_PRIVATE && byId[id]?.open).filter(Boolean),
);

/**
 * Get unread
 * @return {Number}
 */
export const getUnread = createCachedSelector(
  (state: RootState) => state.chats.ids,
  (state: RootState) => state.chats.byId,
  (state: RootState, userId: string) => userId,
  (ids, byId, userId) => {
    // Get chats which contains the `userId`
    const chats = ids.filter((id) => {
      if (byId[id].kind === CHATS.CHAT_PRIVATE) {
        return byId[id].usersIds.includes(userId);
      }
      return false;
    });

    // If we have result, return the unread value
    if (chats.length !== 0) {
      return byId[chats[0]].unread;
    }

    // By default, return 0
    return 0;
  },
)(
  // re-reselect keySelector (receives selectors' arguments)
  // Use "userId" as cacheKey
  (_state_, userId) => userId,
);

/**
 * Get the number of unread private messages
 * @return {Number}
 */
export const getUnreadPrivates = createSelector(
  (state: RootState) => state.chats.ids,
  (state: RootState) => state.chats.byId,
  (channelIds, channelById) =>
    channelIds.reduce((acc, channel) => {
      if (channelById[channel]?.kind === CHATS.CHAT_PRIVATE) {
        acc += Number(channelById[channel]?.unread) ?? 0;
      }

      return acc;
    }, 0),
);
