import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getH2TypoStyle = (theme: ThemeInterface): Record<symbol, string> => theme.typo.headlines.H2;
const getH5TypoStyle = (theme: ThemeInterface): Record<symbol, string> => theme.typo.headlines.H5;
const getLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.label;
const getTinyLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;
const getSmallBodyText = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.bodyText.smallBody;

export const Header = styled('h1')(({ theme }) => ({
  color: theme.text._1,
  marginBottom: '2rem',
  textAlign: 'center',
  padding: '.25rem 0',
  ...getH2TypoStyle(theme),
}));

export const DeactivateHeader = styled('h5')(({ theme }) => ({
  marginBottom: '.25rem',
  textAlign: 'center',
  ...getH5TypoStyle(theme),
}));

export const ModalTitle = styled('h5')(({ theme }) => ({
  margin: '.25rem 1rem',
  textAlign: 'center',
  color: theme.text._1,
  ...getH5TypoStyle(theme),
}));

export const ImportUsersHeader = styled('h1')(({ theme }) => ({
  color: theme.text._1,
  textAlign: 'center',
  marginBottom: '.25rem',
  ...getH2TypoStyle(theme),
}));

export const Subheading = styled('div')(({ theme }) => ({
  color: theme.text._4,
  marginBottom: '2.5rem',
  textAlign: 'center',
  ...getLabelTypoStyle(theme),
}));

export const CsvCaptionContainer = styled('div')(() => ({
  marginBottom: '1rem',
  marginTop: '2rem',
}));

export const CsvCaption = styled('div')(({ theme }) => ({
  color: theme.text._4,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  ...getTinyLabelTypoStyle(theme),
}));

export const Description = styled('div')(({ theme }) => ({
  color: theme.text._4,
  maxWidth: '475px',
  margin: '0 auto 1rem',
  textAlign: 'center',
  ...getSmallBodyText(theme),
}));
