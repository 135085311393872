/*
 * Package Import
 */
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

/*
 * Style
 */
export const Center = styled.main(() => ({
  overflow: 'auto',
  height: '100%',
  width: '100%',
}));

export const TitleContainer = styled.div`
  border-bottom: 1px solid var(--border-default);
  padding: 2rem;
`;

export const PageTitle = styled.h1(({ theme }) => ({
  ...theme.typo.headlines.H2,
  userSelect: 'none',
}));

export const Main = styled.section({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const NavigationContainer = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.text._7}`,
  display: 'inline-flex',
  margin: '1.5rem 1.5rem 0 1.5rem',
  paddingBottom: '0.25rem',

  '> a:not(:last-of-type)': {
    marginRight: '2rem',
  },
}));

export const Link = styled(NavLink)(({ theme }) => ({
  /* Labels / Normal */
  ...theme.typo.labels.label,
  /* identical to box height, or 24px */
  textAlign: 'center',
  color: theme.text._1,
  /* */
  justifyContent: 'center',
  position: 'relative',
  transition: '0.2s background',

  /** Active */
  '::before': {
    content: '""',
    background: theme.primary._1,
    height: '0.2em',
    position: 'absolute',
    left: '2em',
    right: '2em',
    bottom: '-5px',
    opacity: 0,
  },

  '&.active': {
    fontWeight: 500,

    '&::before': {
      opacity: 1,
      left: 0,
      right: 0,
    },
  },
}));
