/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Center = styled.main({
  background: 'var(--background-1)',
  overflow: 'auto',
  height: '100%',
  width: '100%',
});

export const TitleContainer = styled.div`
  border-bottom: 1px solid var(--border-default);
  background: var(--background-1);
  padding: 2rem;
  height: 7em;
  width: 100%;
`;

export const Title = styled.h1(({ theme }) => ({
  ...theme.typo.headlines.H2,
  userSelect: 'none',
}));

export const Loading = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem 0',
});

export const CoursesList = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const CardsContainer = styled.div({
  width: '60rem',
  marginTop: '2.5rem',
});

export const Category = styled.div({
  marginBottom: '2rem',

  ':last-of-type': {
    marginBottom: 0,
  },

  '& .crumble-card': {
    marginBottom: '1.5rem',
  },
});

export const CategoryTitle = styled.h2(({ theme }) => ({
  ...theme.typo.headlines.H3,
  marginBottom: '1.5rem',
}));
