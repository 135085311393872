/*
 * Package Import
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Input from 'src/components/Chats/Channel/Chat/Input';
import MessagesList from 'src/components/Chats/Channel/Chat/MessagesList/container';

// Style
import * as S from './style';

/*
 * Component
 */
const Chat = ({ chatId, isPrivate }) => {
  if (!chatId) {
    return null;
  }

  useEffect(() => {
    if (isPrivate) {
      setTimeout(() => {
        document.getElementsByClassName('ql-editor')[0].focus();
      }, 0);
    }
  }, [isPrivate]);

  return (
    <S.Chat role="tabpanel" aria-labelledby="tabChat" isPrivate={isPrivate}>
      <S.Content>
        <MessagesList chatId={chatId} isPrivate={isPrivate} />
        <Input chatId={chatId} />
      </S.Content>
    </S.Chat>
  );
};

/*
 * PropTypes
 */
Chat.propTypes = {
  chatId: PropTypes.string,
  isPrivate: PropTypes.bool,
};

Chat.defaultProps = {
  chatId: null,
  isPrivate: false,
};

/*
 * Export
 */
export default React.memo(Chat);
