/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { validateIntegrationDTO } from 'src/schemas/Adapters/Integrations/validateIntegrationDTO';

// Schemas
import { IntegrationDTO } from 'src/schemas/Entities/Integration';

// Constants
import { API_FIND_INTEGRATIONS, API_GET } from 'src/constants/apiRoutes';
import { IntegrationApps } from 'src/constants/integrations';

/*
 * Code
 */
// @TODO : to rework ! We want all integrations and not just edusign
// --> maybe the same way as for activities
export const fetchIntegration = (integrationName: IntegrationApps) => async (): Promise<IntegrationDTO | null> => {
  try {
    const payload: Payload = {
      method: API_GET,
      url: `${API_FIND_INTEGRATIONS}${integrationName}`,
    };
    const { data: integrationData } = await api(payload);

    if (!integrationData) {
      return null;
    }

    const { integration, error } = validateIntegrationDTO(integrationData);

    if (error) {
      const errorMessage = `Integration ${integrationName} validation problem : ${error.message}.`;
      // eslint-disable-next-line no-console
      console.error(`${errorMessage}\n Please contact support`);
      toast(
        'Nous avons détecté des erreurs de validation sur les données reçues, le détail se trouve dans la console',
        { type: 'warning', toastId: 'query' },
      );
    }
    return integration;
  }
  catch (error) {
    toast(`Erreur pendant le chargement des données : ${error}`, {
      type: 'error',
      toastId: 'fetch',
    });
    throw error;
  }
};
