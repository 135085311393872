/**
 * Init the HubSpot conversation widget
 * @doc https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
export const initHubSpotConversations = () => {
  if (window?.HubSpotConversations?.widget) {
    const status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    }
    else {
      window.HubSpotConversations.widget.load();
    }
  }
};
