/**
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Quill
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Quill Modules
import QuillMention from 'quill-mention';
import data from 'emoji-mart/data/apple.json';
import QuillAutoDetectUrl from './auto-detect-url';
// import MarkdownShortcuts from 'quill-markdown-shortcuts';

// Emoji

/**
 * Local Import
 */
import './blots';

// Style
import * as S from './style';

/**
 * Init
 */
// Todo: Gestion des shortcuts
// Quill.register('modules/markdownShortcuts', MarkdownShortcuts, true);
Quill.register('modules/mentions', QuillMention, true);
Quill.register('modules/autoDetectUrl', QuillAutoDetectUrl);

/*
 * Component
 */
const QuillInput = ({
  handleChange,
  handleSubmit,
  handleKeyboard,
  handleKeyDown,
  handleKeyUp,
  mentions,
  myRef,
  toolbarSelector,
}) => {
  const [, setStatedMentions] = useState(mentions);

  useEffect(() => {
    setStatedMentions(mentions);
  }, [mentions]);

  /**
   * Quill key binding
   * See https://quilljs.com/docs/modules/keyboard/#key-bindings
   */
  const bindings = useMemo(
    () => ({
      // Disable the `Tab` key (text-indent on Quill),
      // Allows you to leave text input (keyboard navigation)
      tab: false,

      // Submit on enter
      handleEnter: {
        key: 'enter',
        handler: () => handleSubmit(),
      },

      // Add code block binding
      codeblock: {
        key: 'i',
        altKey: true,
        handler: () => {
          handleKeyboard('code-block');
        },
      },

      // Send spoiler
      spoiler: {
        key: 'enter',
        altKey: true,
        shiftKey: true,
        handler: () => {
          handleSubmit({ options: { isSpoiler: true } });
        },
      },
    }),
    [],
  );

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   * L'ordre ne doit pas changer car ça influe sur certaines features sans raison connue.
   * (Par ex: autoDetectUrl s'est retrouvé au dessus de 'emoji-module', et il ne marchait plus)
   */
  const modules = useMemo(
    () => ({
      'emoji-module': {
        emojiData: data,
        customEmojiData: [],
        preventDrag: true,
        showTitle: true,
        convertEmoticons: true,
        convertShortNames: true,
      },
      autoDetectUrl: {
        urlRegularExpression: /(?:(?:https?|ftp):\/\/|www\.)\S+/i,
      },
      keyboard: {
        bindings,
      },
      mentions: {
        allowedChars: /^[A-Za-z\sÅÄÖåäöéè]*$/,
        mentionDenotationChars: ['@'],
        source: (searchTerm, renderList) => {
          setStatedMentions((currentMentions) => {
            if (searchTerm.length === 0) {
              renderList(currentMentions, searchTerm);
            }
            else {
              const result = currentMentions.filter((mention) =>
                mention.value.toLowerCase().includes(searchTerm.toLowerCase()),
              );
              renderList(result, searchTerm);
            }
            return currentMentions;
          });
        },
        renderItem: (item) => item.value,
      },
      toolbar: {
        container: toolbarSelector,
      },
    }),
    [bindings, data, toolbarSelector],
  );

  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = useMemo(
    () => [
      'bold',
      'italic',
      // 'underline',
      // 'strike',
      'blockquote',
      // 'list',
      'code-block',
      'code',
      'link',
      'emoji',
      'mention',
    ],
    [],
  );

  /**
   * OnKeyDown handler
   */
  const onKeyDown = useCallback(
    (evt) => {
      if (handleKeyDown) {
        handleKeyDown(evt);
      }
    },
    [handleKeyDown],
  );

  /**
   * OnKeyUp handler
   */
  const onKeyUp = useCallback(
    (evt) => {
      if (handleKeyUp) {
        handleKeyUp(evt);
      }
    },
    [handleKeyUp],
  );

  /**
   * OnChange handler
   */
  const onChange = useCallback(
    (content, delta, source, editor) => {
      handleChange?.(editor.getContents(), source);
    },
    [handleChange],
  );

  /**
   * Render
   */
  return (
    <S.Container>
      <ReactQuill
        // React
        ref={myRef}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        // Quilljs
        modules={modules}
        formats={formats}
        theme="snow"
        // HTML
        placeholder="Message"
      />
    </S.Container>
  );
};

QuillInput.propTypes = {
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleKeyUp: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleKeyboard: PropTypes.func.isRequired,
  mentions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  myRef: PropTypes.object.isRequired,
  toolbarSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

QuillInput.defaultProps = {
  handleBlur: () => null,
  handleChange: () => null,
  handleFocus: () => null,
  handleKeyDown: () => null,
  handleKeyUp: () => null,
  toolbarSelector: false,
};

/*
 * Export
 */
export default React.memo(QuillInput);
