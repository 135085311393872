/*
 * Package Import
 */

/*
 * Local Import
 */
import { startVm } from 'src/serverStateManagers/queries/VM/startVM';
import { getEndpoint } from 'src/serverStateManagers/queries/VM/getEndpoint';
import { User } from 'src/schemas/Entities/User';
import { notFoundError } from './utils';

/*
 * Code
 */
export const fetchURL = async (user: User | undefined): Promise<string | null> => {
  if (user && user.role === 'basic') {
    const status = await startVm(user);

    if (status && status === notFoundError.status) {
      return notFoundError.status.toString();
    }

    if (status && status === 200) {
      const url = await getEndpoint(user);

      if (!url) {
        throw new Error('url not found');
      }

      return url;
    }
  }
  return null;
};
