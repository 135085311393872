/*
 * Package Import
 */
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as FilePondLib from 'filepond';
import FilePondPluginFileValideType from 'filepond-plugin-file-validate-type';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import * as F from 'src/components/Admin/Modals/FormElements';
import { CustomSelect } from 'src/components/Admin/Modals/FormElements/Selects/CustomSelect';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { transformUsersCsvToUsers } from 'src/schemas/Adapters/Users/transformUserCsvToUser';
import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { useStore } from 'src/localStateManagers/store';
import { getRoute } from 'src/utils/routes';
import { usersCSVExample } from '../utils';
import { multipleUsersAddFormData } from '../config';

/*
 * Data
 */
FilePondLib.registerPlugin(FilePondPluginFileValideType);

/*
 * Code
 */
type CsvUploadProps = {
  setPromotionSelected: React.Dispatch<React.SetStateAction<Option | null>>;
};
const CsvUpload = ({ setPromotionSelected }: CsvUploadProps): JSX.Element => {
  // Select Data
  const { data: promotionsData } = useGetPromotions();
  const history = useHistory();

  const [file, setFile] = useState([] as Blob[]);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors /* , isSubmitSuccessful */ },
    setError,
    clearErrors,
    control,
    reset,
  } = useForm();

  // Config
  const { header, subheading, inputs } = multipleUsersAddFormData.upload;
  const { selectPromotions, filePond } = inputs;

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    setFile([]);
    clearErrors();
    useStore.setState({ newUsers: [] });
    history.push(getRoute('usersTable').getUrl({}));
  };

  const onUpdateFiles = (fileItems: FilePondLib.FilePondFile[]): void => {
    clearErrors('csvFile');
    const filesList = fileItems.map((fileItem) => fileItem.file);
    setFile(filesList);

    if (fileItems.length > 1) {
      setError('csvFile', {
        type: 'max',
        message: "Vous ne pouvez charger qu'un seul fichier à la fois",
      });
    }
    else if (fileItems.length === 0) {
      setError('csvFile', {
        type: 'required',
        message: 'Vous devez sélectionner un fichier',
      });
    }
    else {
      const fileType = filesList[0].type;
      const fileFormat = fileType.split('/')[1];
      const isValidFormat = fileFormat === 'csv';

      if (!isValidFormat) {
        setError('csvFile', {
          type: 'format',
          message: 'Le fichier doit être au format csv',
        });
      }
    }
  };

  const onSubmit = ({ promotion }: { promotion: Option | null }): void => {
    if (file.length) {
      const fileReader = new FileReader();
      fileReader.onload = (evt) => {
        const promotionId = promotion ? promotion.value : null;
        const fileData = evt.target?.result;
        if (typeof fileData === 'string') {
          const parsedUsers = transformUsersCsvToUsers(fileData, promotionId);
          if (parsedUsers) {
            useStore.setState({ newUsers: parsedUsers });
            setPromotionSelected(promotion);
            clearErrors();
            reset();
          }
        }
      };
      fileReader.readAsText(file[0]);
    }
  };

  // Selects Data
  const activePromotionsData = useMemo(
    () => promotionsData?.filter((promoData) => !promoData.deactivatedAt),
    [promotionsData],
  );

  return (
    <F.Form onSubmit={handleSubmit(onSubmit)}>
      <F.ImportUsersHeader>{header}</F.ImportUsersHeader>
      <F.Subheading>
        {`${subheading.label} `}
        <F.Link
          href={window.URL.createObjectURL(new Blob([usersCSVExample], { type: 'text/csv' }))}
          download="Import-modele.csv"
        >
          {subheading.linkText}
        </F.Link>
      </F.Subheading>
      <F.Field key={selectPromotions.title}>
        <F.Label htmlFor={selectPromotions.title}>
          {selectPromotions.display}
          <F.LabelOptionnal>(optionnel)</F.LabelOptionnal>
        </F.Label>
        <CustomSelect
          isMulti={selectPromotions.isMulti}
          data={selectPromotions.getPromoOptions(activePromotionsData)}
          selectName={selectPromotions.title}
          control={control}
          isError={!!errors.promotions}
          optionsExtraData={selectPromotions.getPromoOptionsTooltipsInfos(activePromotionsData)}
        />
      </F.Field>
      <F.Field key={filePond.title}>
        <F.Label>{filePond.display}</F.Label>
        <F.CustomFilePond
          ref={register}
          allowFileTypeValidation
          acceptedFileTypes={['text/csv']}
          labelFileTypeNotAllowed="Type de fichier incorrect"
          fileValidateTypeLabelExpectedTypes="Le fichier doit être au format csv"
          name="csvFile"
          files={file}
          allowMultiple={false}
          onupdatefiles={onUpdateFiles}
          labelIdle='Déposez votre fichier ici ou <span class="filepond--label-action">Sélectionnez</span>'
          required
        />
      </F.Field>
      <F.Buttons>
        <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
          Annuler
        </Button>
        <Button type="submit" variant="primary" onClick={handleSubmit(onSubmit)} fullWidth>
          Importer
        </Button>
      </F.Buttons>
      <F.CloseButton onClick={handleClose}>
        <F.CloseIcon />
      </F.CloseButton>
    </F.Form>
  );
};

/*
 * Export
 */
export default CsvUpload;
