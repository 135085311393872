/**
 * Package Import
 */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Local Import
 */
import ButtonsSelector from 'src/components/TopBar/Menu/SubMenus/ButtonsSelector';
import { chatAppearanceOptions } from 'src/data/settings';
import { setChatAppearance } from 'src/store/actions';
import { getChatAppearance } from 'src/store/selectors/settings';

// Style
import * as G from '../../../style';

/**
 * Component
 */
const ChatAppearance = (props) => {
  const dispatch = useDispatch();
  const chatAppearance = useSelector(getChatAppearance);

  /**
   * Update the user list style
   * @param {string} value
   * @return {void}
   */
  const handleChange = useCallback((value) => {
    dispatch(setChatAppearance(value));
  }, []);

  /**
   * Render
   */
  return (
    <ButtonsSelector
      icon={<G.IconChatAppearance aria-hidden="true" />}
      onChange={handleChange}
      options={chatAppearanceOptions}
      title="Apparence du chat"
      value={chatAppearance}
      {...props}
    />
  );
};

/**
 * Export
 */
export default ChatAppearance;
