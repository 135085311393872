/*
 * Package Import
 */
import { useQuery, UseQueryResult } from 'react-query';

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { fetchPromotions } from './fetchPromotions';

/*
 * Code
 */
export const useGetPromotions = (): UseQueryResult<Promotion[]> => {
  const promotionsQuery = useQuery('promotions', fetchPromotions, {
    initialData: [],
    staleTime: 5000,
  });

  return promotionsQuery;
};
