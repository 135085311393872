/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import HotkeysListener from './index';

/*
 * Code
 */

// State
const mapStateToProps = (state) => ({
  state,
});

/*
 * Export
 */
export default connect(mapStateToProps)(HotkeysListener);
