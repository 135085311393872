/*
 * Package Import
 */
import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@oclock/crumble';

/*
 * Local Import
 */
import { UserContext } from 'src/context/User';

// Helpers
import { ACTION_VM, STATUS_VM } from 'src/constants/virtualDesktop';
import { makeRequest } from 'src/utils/virtualMachine';

// Style
import * as S from './index.style';

/*
 * Reducer
 */
const newReducer = (state, action) => {
  switch (action.type) {
    case 'request':
      return { status: 'fetching', error: null, payload: null };
    case 'success':
      return { ...state, status: 'fetched', payload: action.payload };
    case 'failure':
      return { ...state, status: 'error', error: action.payload };
    case 'set_catalog':
      return { ...state, catalog: action.catalog };
    case 'set_selected_vm_type':
      return { ...state, selected_vm_type: action.selected_vm_type };
    default:
      return state;
  }
};

/*
 * Component
 */
const ModalVM = ({
  // Status
  getStatus,
  setStatus,

  // Modals
  label,
  onCloseModal,

  // CTAs
  rebootMachine,
  stopMachine,
}) => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  /*
   * State
   */
  const [state, dispatch] = useReducer(newReducer, {
    /** @type {String} */
    status: 'idle',
    /** @type {Mixed<Object|String>} */
    payload: null,
    /** @type {String} */
    error: null,
    /** @type {Array} */
    catalog: [],
    /** @type {String} */
    selected_vm_type: null,
  });

  /**
   * Select a type of virtual machine to install
   * @param {MouseEvent} event
   * @return {void}
   */
  const onSelectVmType = (event) => {
    dispatch({
      type: 'set_selected_vm_type',
      selected_vm_type: event.target.value,
    });
  };

  /**
   * Get the catalog of availables VMs
   * @return {Promise}
   */
  const getCatalog = () =>
    makeRequest({ user, url: 'catalog/' })
      .then(({ data }) => {
        if (data?.catalog?.length) {
          dispatch({ type: 'set_catalog', catalog: data.catalog });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'failure',
          payload: error?.response?.message || error?.response?.data,
        });
      });

  /**
   * Create and re/install a new virtual machine
   * @param {String} • 'create'|'reinstall'
   * @return {Promise}
   */
  const handleCreateMachine = (type) => async () => {
    // Avoid multi request
    dispatch({ type: 'request' });
    onCloseModal();

    // More react for the user
    setStatus(STATUS_VM.PENDING);

    // Params
    const params = {
      branch: 'master',
      vm_type: state.selected_vm_type,
      email: user.email,
    };

    if (user.githubProfile) {
      params.github = user.githubProfile;
    }

    // Request 💯
    try {
      try {
        const { data } = await makeRequest({ user, url: `${type}/`, params });
        // ✅
        dispatch({ type: 'success', payload: data });
        dispatch({ type: 'set_selected_vm_type', selected_vm_type: '' });
      }
      catch (error) {
        dispatch({
          type: 'failure',
          payload: error?.response?.message || error?.response?.data,
        });
      }
    }
    finally {
      getStatus();
    }
  };

  /**
   * Delete the virtual machine
   * @return {Promise}
   */
  const handleDeleteMachine = async () => {
    // Avoid multi request
    dispatch({ type: 'request' });
    onCloseModal();

    // More react for the user
    setStatus(STATUS_VM.PENDING);

    // Request
    try {
      await makeRequest({ user, url: 'delete/' });
    }
    finally {
      getStatus();
    }
  };

  /**
   * Reboot the current virtual machine
   * @return {void}
   */
  const handleRebootMachine = () => {
    // Avoid multi request
    dispatch({ type: 'request' });
    onCloseModal();

    // Request
    return rebootMachine();
  };

  /**
   * Stop the current virtual machine
   * @return {void}
   */
  const handleStopMachine = () => {
    // Avoid multi request
    dispatch({ type: 'request' });
    onCloseModal();

    // Request
    return stopMachine();
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    // Get list of differents VMs available
    if (label === ACTION_VM.CREATE || label === ACTION_VM.REINSTALL) {
      getCatalog();
    }
  }, []);

  /**
   * VM • Create
   */
  if (label === ACTION_VM.CREATE) {
    return (
      <Dialog
        icon="Cloud"
        title="Création d’un bureau virtuel"
        variant="primary"
        cancelButtonProps={{
          type: 'button',
          label: 'Annuler',
          onClick: onCloseModal,
        }}
        successButtonProps={{
          type: 'button',
          label: 'Valider',
          onClick: handleCreateMachine('create'),
          disabled: !state?.selected_vm_type || state.status === 'fetching',
        }}
      >
        <S.Select value={state.selected_vm_type || ''} onChange={onSelectVmType}>
          <option disabled value="">
            Sélectionner un type
          </option>
          {state?.catalog?.length
            ? state.catalog.map((items) => (
              <option key={items['vm-type']} value={items['vm-type']}>
                {items['desc-fr'] || items['vm-type']}
              </option>
            ))
            : null}
        </S.Select>

        {/* Error */}
        {state.status === 'error' && state.error && <S.MessageError>{state.error}</S.MessageError>}
      </Dialog>
    );
  }

  /**
   * VM • Reinstall
   */
  if (label === ACTION_VM.REINSTALL) {
    return (
      <Dialog
        icon="ExclamationTriangle"
        title="Êtes-vous sûr de vouloir réinstaller le bureau virtuel ?"
        desc="Toutes les données seront perdues"
        variant="danger"
        cancelButtonProps={{
          type: 'button',
          label: 'Annuler',
          onClick: onCloseModal,
        }}
        successButtonProps={{
          type: 'button',
          label: 'Confirmer',
          onClick: handleCreateMachine('reinstall'),
          disabled: !state?.selected_vm_type || state.status === 'fetching',
        }}
      >
        <S.Select value={state.selected_vm_type || ''} onChange={onSelectVmType}>
          <option disabled value="">
            Sélectionner un type
          </option>
          {state?.catalog?.length
            ? state.catalog.map((items) => (
              <option key={items['vm-type']} value={items['vm-type']}>
                {items['desc-fr'] || items['vm-type']}
              </option>
            ))
            : null}
        </S.Select>

        {/* Error */}
        {state.status === 'error' && state.error && <S.MessageError>{state.error}</S.MessageError>}
      </Dialog>
    );
  }

  /**
   * VM • Delete
   */
  if (label === ACTION_VM.DELETE) {
    return (
      <Dialog
        icon="ExclamationTriangle"
        title="Êtes-vous sûr de vouloir supprimer le bureau virtuel ?"
        desc="Cette opération est irréversible"
        variant="danger"
        cancelButtonProps={{
          type: 'button',
          label: 'Annuler',
          onClick: onCloseModal,
        }}
        successButtonProps={{
          type: 'button',
          label: 'Confirmer',
          onClick: handleDeleteMachine,
          disabled: state.status === 'fetching',
        }}
      />
    );
  }

  /**
   * VM • REBOOT
   */
  if (label === ACTION_VM.REBOOT) {
    return (
      <Dialog
        icon="ExclamationTriangle"
        title="Êtes-vous sûr de vouloir redémarrer le bureau virtuel ?"
        variant="danger"
        cancelButtonProps={{
          type: 'button',
          label: 'Annuler',
          onClick: onCloseModal,
        }}
        successButtonProps={{
          type: 'button',
          label: 'Redémarrer le bureau',
          onClick: handleRebootMachine,
        }}
      />
    );
  }

  /**
   * VM • Stop
   */
  if (label === ACTION_VM.STOP) {
    return (
      <Dialog
        icon="ExclamationTriangle"
        title="Êtes-vous sûr de vouloir arrêter le bureau virtuel ?"
        variant="danger"
        cancelButtonProps={{
          type: 'button',
          label: 'Annuler',
          onClick: onCloseModal,
        }}
        successButtonProps={{
          type: 'button',
          label: 'Arrêter le bureau',
          onClick: handleStopMachine,
        }}
      />
    );
  }

  /**
   * Render
   */
  return null;
};

/*
 * PropTypes
 */
ModalVM.propTypes = {
  /** Status props */
  getStatus: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  /** Modal props */
  label: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  /** CTAs props */
  rebootMachine: PropTypes.func.isRequired,
  stopMachine: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default React.memo(ModalVM);
