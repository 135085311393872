import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getBodyText = (theme: ThemeInterface): Record<symbol, string> => theme.typo.bodyText.body;
const TableBody = styled.tbody(({ theme }) => ({
  ...getBodyText(theme),
  width: '100%',
}));

export default TableBody;
