import styled from '@emotion/styled';

import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getLabelStyle = (theme: ThemeInterface): Record<symbol, string> => theme.typo.labels.label;
const TableFooter = styled.tfoot(({ theme }) => ({
  backgroundColor: theme.background._3,
  justifyContent: 'center',
  '& > tr': {
    ...getLabelStyle(theme),
    color: theme.text._4,
  },
}));

export default TableFooter;
