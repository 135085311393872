/*
 * Package Import
 */
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/*
 * Component
 */
const Portal = ({ id, children }) => {
  /*
   * Refs
   */
  const el = useRef(
    /** Get the element {id} */
    document.getElementById(id)
      /** Create a new div that wraps the component */
      || document.createElement('div'),
  );

  /*
   * Lifecycles
   */
  useEffect(() => {
    el.current.className = `ReactPortal.${Date.now()}`;

    // Append the element to the DOM when it mounts
    document.body.appendChild(el.current);

    return () => {
      // Remove the element when it unmounts, for prevents memory leak
      document.body.removeChild(el.current);
    };
  }, []);

  /*
   * Render the element's children in a Portal
   */
  return ReactDOM.createPortal(children, el.current);
};

/*
 * PropTypes
 */
Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

/*
 * Export
 */
export default Portal;
