/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { colorsRGB, weights } from 'src/theme';

/*
 * Style
 */
export const Messages = styled.div(({ textSize }) => ({
  minHeight: 0,
  minWidth: 0,
  fontSize: `${textSize}rem !important`,
  height: '100%',
  position: 'relative',
}));

export const MessagesScroller = styled.div(({ theme }) => ({
  scrollbarColor: `${theme.background._7} transparent`,
  scrollbarWidth: '8px',
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: `${theme.background._7}`,
    height: '32px',
    // Les border left & right permettent de simuler un padding sur la scrollbar
    // Actuellement pas d'autre moyen d'utiliser un "vrai" padding.
    borderLeft: '1px solid transparent',
    borderRight: '3px solid transparent',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '4px',
    backgroundClip: 'padding-box',
  },
}));

export const Thread = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-color: ${({ theme }) => theme.background._7} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background._7};
    border-radius: 5px;
  }
`;

export const History = styled.p`
  color: rgba(${colorsRGB.cream}, 1);
  font-style: italic;
  font-weight: ${weights.semiBold};
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
`;

export const MessagesPending = styled.div`
  ::before {
    background: ${({ theme }) =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0), ${theme.background._2})`};
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 75px;
    width: 100%;
    z-index: 2;
  }
`;

export const Pending = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 3;
`;
