/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { reactivateCourse } from './reactivateCourse';

/*
 * Code
 */
export const useReactivateCourse = (): UseMutationResult<void, unknown, string, unknown> => {
  const CourseMutation = useMutation((courseId: string) => reactivateCourse(courseId), {
    onSettled: () => {
      queryClient.invalidateQueries('courses');
    },
    onSuccess: () => {
      toast('Cours désarchivé avec succès !', { type: 'success' });
    },
  });

  return CourseMutation;
};
