/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Transcription from 'src/components/TopBar/Menu/SubMenus/MenuLayout/Transcription';
import { setTranscritionEnabled } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  enabled: state.settings.layout.transcription,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setTranscritionEnabled }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Transcription);
