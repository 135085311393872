/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import {
  userFormDataSchema,
  UserFormData,
  userFormDataValidatedSchema,
  UserFormDataValidated,
} from 'src/schemas/Entities/User';
import { CustomSelect } from 'src/components/Admin/Modals/FormElements/Selects/CustomSelect';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { useUpdateUser } from 'src/serverStateManagers/mutations/update/Users/useUpdateUser';
import Tooltip from 'src/components/Tooltip';
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { MongoId } from 'src/schemas/Entities/utils';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { getRoute } from 'src/utils/routes';
import { useStore } from 'src/localStateManagers/store';
import { useGetIntegration } from 'src/serverStateManagers/queries/Integrations/useGetIntegration';
import { IntegrationApps } from 'src/constants/integrations';
import { usersUpdateFormData } from './config';

/*
 * Code
 */
type UpdateUserProps = {
  isOpen: boolean;
  idToUpdate: MongoId | null;
};
const UpdateUser = ({ isOpen, idToUpdate }: UpdateUserProps): JSX.Element | null => {
  const updateUserMutation = useUpdateUser();
  const history = useHistory();
  const client = useStore((state) => state.client);

  // Config
  const { header, inputs } = usersUpdateFormData;
  const {
    avatar,
    id,
    firstname,
    lastname,
    email,
    selectPromotions,
    selectRole,
    mustSign,
    canCreateVM,
  } = inputs;

  // Entity Data
  const { data: usersData } = useGetUsers();
  const userData = usersData?.find((user) => user.id === idToUpdate);

  const { data: edusignData } = useGetIntegration(IntegrationApps.EDUSIGN);

  // Selects Data
  const { data: promotionsData } = useGetPromotions();
  const activePromotionsData = useMemo(
    () => promotionsData?.filter((promoData) => !promoData.deactivatedAt),
    [promotionsData],
  );

  // Selects Options
  const promotionsOptions = selectPromotions.getPromoOptions(promotionsData);
  const activePromotionsOptions = selectPromotions.getPromoOptions(activePromotionsData);

  // Selects Current Options
  const currentRoleOption = selectRole.rolesOptions.find(
    (roleOption) => roleOption.value === userData?.role,
  );

  const currentPromotionsOptions = promotionsOptions.filter((option) =>
    userData?.promotions
      .map((promotion) => (promotion ? promotion.id : null))
      ?.includes(option.value),
  );

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    control,
    reset,
    trigger,
  } = useForm({
    resolver: zodResolver(userFormDataSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  const onSubmit = (data: UserFormData): void => {
    const userFormDataValidatedResult: UserFormDataValidated = userFormDataValidatedSchema.parse(data);
    clearErrors();
    if (idToUpdate) {
      updateUserMutation.mutate({
        userId: idToUpdate,
        userFormData: userFormDataValidatedResult,
      });
    }
  };

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    clearErrors();
    history.push(getRoute('usersTable').getUrl({}));
  };

  // Force to have id and course found before you can display modal
  // Import for default value in Selects
  if (!idToUpdate || !userData) return null;
  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>
        <F.Field>
          <F.Input ref={register()} name={id.title} type={id.type} defaultValue={userData?.id} />
        </F.Field>
        <F.Field>
          <F.Input
            ref={register()}
            name={avatar.title}
            type="hidden"
            {...(userData?.avatar && { defaultValue: userData.avatar })}
          />
        </F.Field>
        <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={firstname.title}>{firstname.display}</F.Label>
              <F.Input
                className={errors.firstname ? 'is-error' : undefined}
                ref={register()}
                name={firstname.title}
                type={firstname.type}
                defaultValue={userData?.firstname}
              />
              {errors.firstname?.message && (
                <F.ErrorMessage>{errors.firstname.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field>
              <F.Label htmlFor={lastname.title}>{lastname.display}</F.Label>
              <F.Input
                className={errors.lastname ? 'is-error' : undefined}
                ref={register()}
                name={lastname.title}
                type={lastname.type}
                defaultValue={userData?.lastname}
              />
              {errors.lastname?.message && (
                <F.ErrorMessage>{errors.lastname.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
        </F.Line>
        <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={email.title}>{email.display}</F.Label>
              <F.Input
                className={errors.email ? 'is-error' : undefined}
                ref={register()}
                name={email.title}
                type={email.type}
                defaultValue={userData?.email}
              />
              {errors.email?.message && <F.ErrorMessage>{errors.email.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field>
              <F.Label htmlFor={selectRole.title}>{selectRole.display}</F.Label>
              <CustomSelect
                isMulti={selectRole.isMulti}
                data={selectRole.rolesOptions}
                selectName={selectRole.title}
                control={control}
                isError={!!errors.role}
                defaultValue={currentRoleOption}
                optionsToDisable={selectRole.getRolesOptionsToDisable(client)}
              />
              {errors.role?.message && <F.ErrorMessage>{errors.role.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box>
        </F.Line>
        <F.Line>
          <F.Box isLeft>
            <F.Field key={selectPromotions.title}>
              <F.Label htmlFor={selectPromotions.title}>{selectPromotions.display}</F.Label>
              <CustomSelect
                isMulti={selectPromotions.isMulti}
                data={activePromotionsOptions}
                selectName={selectPromotions.title}
                control={control}
                isError={!!errors.promotions}
                defaultValue={currentPromotionsOptions}
                optionsExtraData={selectPromotions.getPromoOptionsTooltipsInfos(promotionsData)}
                mustValidateAtDisplay={trigger}
              />
              {!!errors.promotions
                && errors.promotions?.map(
                  (error, index) =>
                    error?.message && <F.ErrorMessage key={index}>{error.message}</F.ErrorMessage>,
                )}
            </F.Field>
          </F.Box>
          {/* The following fields have been hidden following this ticket. This is temporary.
            https://www.notion.so/slippers/Masquer-des-infos-dans-le-profil-713ee9e4a6094d14a02ebb95c6dd88b5
          */}
          {/* <F.Box>
            <F.Field>
              <F.Label htmlFor={avatar.title}>{avatar.display}</F.Label>
              <F.Input
                className={errors.avatar ? 'is-error' : undefined}
                ref={register()}
                name={avatar.title}
                type={avatar.type}
                {...(userData?.avatar && { defaultValue: userData.avatar })}
              />
              {errors.avatar?.message && <F.ErrorMessage>{errors.avatar.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box> */}
        </F.Line>
        {/* <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={town.title}>{town.display}</F.Label>
              <F.Input
                className={errors.town ? 'is-error' : undefined}
                ref={register()}
                name={town.title}
                type={town.type}
                {...(userData?.town && { defaultValue: userData.town })}
              />
              {errors.town?.message && <F.ErrorMessage>{errors.town.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field>
              <F.Label htmlFor={country.title}>{country.display}</F.Label>
              <F.Input
                className={errors.country ? 'is-error' : undefined}
                ref={register()}
                name={country.title}
                type={country.type}
                {...(userData?.country && { defaultValue: userData.country })}
              />
              {errors.country?.message && <F.ErrorMessage>{errors.country.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box>
        </F.Line>
        <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={githubProfile.title}>{githubProfile.display}</F.Label>
              <F.Input
                className={errors.githubProfile ? 'is-error' : undefined}
                ref={register()}
                name={githubProfile.title}
                type={githubProfile.type}
                {...(userData?.githubProfile && {
                  defaultValue: userData.githubProfile,
                })}
              />
              {errors.githubProfile?.message && (
                <F.ErrorMessage>{errors.githubProfile.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field>
              <F.Label htmlFor={discordProfile.title}>{discordProfile.display}</F.Label>
              <F.Input
                className={errors.discordProfile ? 'is-error' : undefined}
                ref={register()}
                name={discordProfile.title}
                type={discordProfile.type}
                {...(userData?.discordProfile && {
                  defaultValue: userData.discordProfile,
                })}
              />
              {errors.discordProfile?.message && (
                <F.ErrorMessage>{errors.discordProfile.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
        </F.Line> */}
        {!!edusignData?.isAccountLinked && (
          <F.Field style={{ display: 'flex' }}>
            <Tooltip content={mustSign.tooltip}>
              <F.CheckboxField>
                <F.CheckboxInput
                  type={mustSign.type}
                  ref={register()}
                  name={mustSign.title}
                  id={mustSign.title}
                  defaultChecked={
                    typeof userData?.integrations?.edusign?.mustSign === 'boolean'
                      ? userData?.integrations?.edusign?.mustSign
                      : edusignData?.customProperties?.signature_by_default
                  }
                />
                <F.Label htmlFor={mustSign.title}>{mustSign.display}</F.Label>
              </F.CheckboxField>
            </Tooltip>
            {errors.integrations?.edusign?.mustSign?.message && (
              <F.ErrorMessage>{errors.integrations?.edusign?.mustSign.message}</F.ErrorMessage>
            )}
          </F.Field>
        )}
        <F.Field style={{ display: 'flex' }}>
          <F.CheckboxField>
            <F.CheckboxInput
              type={canCreateVM.type}
              ref={register()}
              name={canCreateVM.title}
              id={canCreateVM.title}
              defaultChecked={userData?.canCreateVM}
            />
            <F.Label htmlFor={canCreateVM.title}>{canCreateVM.display}</F.Label>
          </F.CheckboxField>
          {errors.canCreateVM?.message && (
            <F.ErrorMessage>{errors.canCreateVM.message}</F.ErrorMessage>
          )}
        </F.Field>
        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default UpdateUser;
