/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import { NoParticipants } from 'src/components/Chats/Poll/Item/Participants';
import Participants from 'src/components/Chats/Poll/Item/Participants/containers';

// Helpers
import { getPercent } from 'src/utils';
import { isEnter, isSpace } from 'src/utils/events';

// Style
import * as P from '../style';
import * as S from './style';

/*
 * Code
 */
const UNANSWERED_ID = 999;

/**
 * Component
 */
const Result = ({ answers, isEducator, nonVoters, shareResult, userVotes }) => {
  /*
   * Hooks
   */
  const totalVotes = React.useMemo(
    () => answers.reduce((acc, item) => acc + item.votes.length, 0),
    [answers],
  );

  /**
   * State
   */
  const [statsDisplayed, setStatsDisplayed] = React.useState(
    /** @type {Array<Number>} · Initial stats display */
    [],
  );

  /**
   * Display the details of 1 answer
   * @param {Number} value
   * @return {void}
   */
  const oneStatsDisplayed = (value) => () => {
    if (isEducator || shareResult) {
      const newStatsDisplayed = statsDisplayed.includes(value)
        ? statsDisplayed.filter((val) => val !== value)
        : [...statsDisplayed, value].sort();

      setStatsDisplayed(newStatsDisplayed);
    }
  };

  /**
   * Display details of all answers,
   * Including people who did not vote
   * @return {void}
   */
  const allStatsDisplayed = () => {
    const allAnswers = [...answers.map((answer) => answer.id), UNANSWERED_ID];
    const everyAnswersChecked = allAnswers.every(
      (answerId) => statsDisplayed.indexOf(answerId) !== -1,
    );

    if (everyAnswersChecked) {
      setStatsDisplayed([]);
    }
    else {
      setStatsDisplayed(allAnswers);
    }
  };

  /**
   * Display the statistic panel w/ keyboard
   * @param {KeyboardEvent} evt
   * @return {void}
   */
  const keyboardHandleOpen = (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      evt.preventDefault();
      allStatsDisplayed();
    }
  };
  return (
    <S.Result>
      {/* Total votes */}
      <S.Total>{totalVotes}</S.Total> réponse{totalVotes > 1 ? 's' : ''}
      {/* Show details */}
      {(isEducator || shareResult) && (
        <S.StatsAction
          tabIndex="0"
          data-testid="result-show-stats"
          onClick={allStatsDisplayed}
          onKeyDown={keyboardHandleOpen}
        >
          {statsDisplayed?.length > answers?.length ? (
            <>
              <UilEyeSlash />
              <span>Masquer les détails</span>
            </>
          ) : (
            <>
              <UilEye />
              <span>Afficher les détails</span>
            </>
          )}
        </S.StatsAction>
      )}
      {/* Answers */}
      <P.Answers>
        {answers?.length
          ? answers.map((answer, index) => {
            const percent = getPercent(answer.votes.length, totalVotes);
            const percentFormatted = parseFloat(percent.toFixed(1));

            return (
              <S.ContainerAnswer key={`key-result-survey-answer-${answer.id}`}>
                {/* Body */}
                <S.Answer
                  percent={percentFormatted}
                  hasVotedFor={userVotes[index]}
                  data-testid={`result-survey-answer-${answer.id}`}
                  onClick={oneStatsDisplayed(answer.id)}
                  hasCursor={isEducator || shareResult}
                >
                  <S.Content>{answer.content}</S.Content>
                  <S.Analytics>
                    <S.Percent>{percentFormatted}%</S.Percent>
                    <S.InNumber>
                      / {answer.votes.length} {answer.votes.length <= 1 ? 'Réponse' : 'Réponses'}
                    </S.InNumber>
                  </S.Analytics>
                </S.Answer>

                {/* Stats */}
                {statsDisplayed.includes(answer.id) && (
                <S.Stats data-testid={`result-stats-survey-answer-${answer.id}`}>
                  {answer?.votes?.length ? (
                        answer.votes.map((voteId) => <Participants key={voteId} userId={voteId} />)
                      ) : (
                        <NoParticipants />
                      )}
                </S.Stats>
                )}
              </S.ContainerAnswer>
            );
          })
          : null}

        {(isEducator || shareResult) && nonVoters?.length ? (
          <S.ContainerAnswer>
            <S.Answer
              hasCursor={isEducator || shareResult}
              data-testid="result-survey-answer-non-voters"
              onClick={oneStatsDisplayed(UNANSWERED_ID)}
            >
              <S.ParticipantsContent>
                <S.Participants>{nonVoters.length}</S.Participants> participant
                {nonVoters.length > 1 ? 's' : ''} n&apos;
                {nonVoters.length > 1 ? 'ont' : 'a'} pas répondu
              </S.ParticipantsContent>
            </S.Answer>

            {statsDisplayed.includes(UNANSWERED_ID) && (
              <S.Stats data-testid="result-stats-survey-answer-all">
                {nonVoters.map((userId) => (
                  <Participants key={userId} userId={userId} />
                ))}
              </S.Stats>
            )}
          </S.ContainerAnswer>
        ) : null}
      </P.Answers>
    </S.Result>
  );
};

/*
 * PropTypes
 */
Result.propTypes = {
  /** Answers of the survey */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      votes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  ).isRequired,
  /** Diffrent display within role */
  isEducator: PropTypes.bool,
  /** Users who did not vote at the poll */
  nonVoters: PropTypes.arrayOf(PropTypes.string),
  /** Can we share the result detail with other users ? */
  shareResult: PropTypes.bool,
  /** Users who voted at the poll */
  userVotes: PropTypes.arrayOf(PropTypes.bool),
};

Result.defaultProps = {
  userVotes: [],
  nonVoters: [],
  shareResult: false,
  isEducator: false,
};

/**
 * Export
 */
export default Result;
