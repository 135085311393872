/*
 * Package Import
 */
import { v4 as uuid } from 'uuid';
import Papa from 'papaparse';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { UserDisplayBeforeCreate, userDisplayBeforeCreateSchema } from 'src/schemas/Entities/User';
import { RoleEnum, roleEnum } from 'src/constants/roles';
import { MongoId } from 'src/schemas/Entities/utils';
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { getPromotionFromId } from 'src/schemas/Repositories/promotions';
import { ZodValidationFailure, ZodValidationSuccess } from './utils';
import { purify } from '../utils';
import { userCsvEmailMustBeUniqueInCsvWhenUploading } from '../../Refines/users';

/*
 * Code
 */
const transformUserInArrayToUserObject = (
  userData: string[],
  promoId: MongoId | null,
): UserDisplayBeforeCreate => {
  const basic: RoleEnum = roleEnum.basic;

  const promotions: Promotion[] | undefined = queryClient.getQueryData('promotions');
  const promotion = promoId ? getPromotionFromId({ promotions, id: promoId }) : null;
  const promotionInUser = promotion
    ? { id: promotion.id, name: promotion.name, deactivatedAt: promotion.deactivatedAt }
    : null;

  const transformedUser = {
    firstname: purify(userData[0] || ''), // to not have an error from purify if empty field (zod will deal with validation)
    lastname: purify(userData[1] || ''), // to not have an error from purify if empty field (zod will deal with validation)
    email: purify(userData[2] || ''), // to not have an error from purify if empty field (zod will deal with validation)
    town: purify(userData[3] || ''),
    country: purify(userData[4] || ''),
    githubProfile: purify(userData[5] || ''),
    discordProfile: purify(userData[6] || ''),
    role: basic,
    avatar: null,
    promotions: [promotionInUser],
    canCreateVM: false,
    id: uuid(), // Temporary id limited at front only, don't send it at back !
  };

  return transformedUser;
};

const validateTransformedUser = (
  newUser: UserDisplayBeforeCreate,
  allNewUsers: UserDisplayBeforeCreate[],
): ZodValidationSuccess | ZodValidationFailure => {
  const userParseResult = userDisplayBeforeCreateSchema
    .refine(userCsvEmailMustBeUniqueInCsvWhenUploading(allNewUsers), {
      message: 'Vous ne pouvez pas créer deux utilisateurs avec le même email',
      path: ['email'],
    })
    .safeParse(newUser);

  if (!userParseResult.success) {
    return {
      success: userParseResult.success,
      error: userParseResult.error,
      data: newUser,
    };
  }

  return userParseResult;
};

export const transformUsersCsvToUsers = (
  usersCsv: string,
  promoId: MongoId | null,
): (ZodValidationSuccess | ZodValidationFailure)[] | null => {
  const parseConfig = {
    header: false,
    skipEmptyLines: true,
    comments: '#', // To skip parsing of headers line
  };
  const { data, errors } = Papa.parse<string[]>(usersCsv, parseConfig);

  if (errors.length > 0) {
    if (data.length <= 0) {
      toast(
        'Erreur pendant le chargement du csv : votre fichier doit contenir au moins une ligne',
        { type: 'error' },
      );
    }
    else {
      toast('Erreur pendant le chargement du csv', { type: 'error' });
    }
    // eslint-disable-next-line no-console
    console.error(errors);
    return null;
  }

  const newUsers = data.map((line) => transformUserInArrayToUserObject(line, promoId));
  const parsedUsers = newUsers.map((newUser) => validateTransformedUser(newUser, newUsers));

  return parsedUsers;
};
