/*
 * Package Import
 */
import { ZodError } from 'zod';
import { Column, Row } from '@tanstack/react-table';

/*
 * Local Import
 */
import { ZodValidationUser, ZodValidationFailure } from 'src/schemas/Adapters/Users/utils';
import { UserDisplayBeforeCreate, userDisplayBeforeCreateSchema } from 'src/schemas/Entities/User';

/*
 * Code
 */
export type AnomaliesDetails = {
  id: string;
  display: string;
  message: string;
};

export type FormError = {
  type: string;
  message: string;
};

export const isValueAnomaly = (
  zodResult: ZodValidationUser,
  column: Column<ZodValidationUser, unknown>,
): zodResult is ZodValidationFailure =>
  !!(
    !zodResult.success
    && !!zodResult.error?.errors?.find((zodError) =>
      zodError.path.find((errorPath) => errorPath === column.id),
    )
  );

export const getAnomaliesDetails = (
  zodError: ZodError,
  allColumns: Column<ZodValidationUser, unknown>[],
): AnomaliesDetails[] =>
  zodError.errors?.reduce((anomaliesDetails, error) => {
    // find the column matching the zod error
    const matchingColumn = allColumns?.find((column) => column.id === error.path[0]);
    if (matchingColumn) {
      // check if we have already get the current anomaly details
      // because some zod errors are catched more than once
      const fieldAlreadyInDetails = anomaliesDetails.find(
        (anomalyDetails) => anomalyDetails?.id === matchingColumn.id,
      );

      if (!fieldAlreadyInDetails) {
        // for each zod error not already catch, make an object with useful infos
        // for tooltip content
        anomaliesDetails.push({
          id: matchingColumn.id,
          display:
            typeof matchingColumn.columnDef.header === 'string'
              ? matchingColumn.columnDef.header
              : '',
          message: error.message,
        });
      }
    }
    return anomaliesDetails;
  }, [] as AnomaliesDetails[]);

export const getSelectedUsersInCsv = (
  selectedRows: Row<ZodValidationUser>[],
): UserDisplayBeforeCreate[] => {
  const selectedUsers = selectedRows.reduce((selectedRowsData, selectedRow) => {
    if (selectedRow.original?.data) {
      selectedRowsData.push(selectedRow.original.data);
    }
    return selectedRowsData;
  }, [] as UserDisplayBeforeCreate[]);
  return selectedUsers;
};

export const safeParseUser = (user: UserDisplayBeforeCreate): ZodValidationUser => {
  const parsedUser = userDisplayBeforeCreateSchema.safeParse(user);

  if (!parsedUser.success) {
    return {
      success: parsedUser.success,
      error: parsedUser.error,
      data: user,
    };
  }

  return parsedUser;
};
