/**
 * Package imports
 */
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Local imports
 */
import { MESSAGE_FILTER, MESSAGE_TYPE, MESSAGE_STATUS } from 'src/constants/messages';
import { UserContext } from 'src/context/User';
import { useWebsocket } from 'src/hooks/useWebsocket';
import { addDates } from './utils';

const MessageContext = createContext({});

const MessageProvider = ({ children }) => {
  // Contexts
  const { user } = useContext(UserContext);

  const socket = useWebsocket();

  // Messages states
  const [idsByChat, setIdsByChat] = useState({});
  const [messagesById, setMessagesById] = useState({});
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [visibilityFilter, setVisibilityFilter] = useState(MESSAGE_FILTER.SHOW_ALL);

  /**
   * Add new message locally
   */
  const addMessage = useCallback(
    ({ content, context, chatId, kind, messageUuid, isPinned = false }) => {
      const clientMessageId = messageUuid;

      const status = !socket?.connected
        ? MESSAGE_STATUS.STATUS_FAILED
        : MESSAGE_STATUS.STATUS_SENDING;

      // Local message that will be stored first (optimistic rendering)
      const localMessage = {
        id: clientMessageId,
        status,
        chatId,
        content,
        kind: kind || MESSAGE_TYPE.TYPE_TEXT,
        pinned: isPinned,
        time: Date.now(),
        type: context,
        userId: user.id,
        reactions: [],
      };

      setIdsByChat((prevIds) => {
        const prevIdsByMessageChat = prevIds[chatId];

        return {
          ...prevIds,
          [chatId]: [...prevIdsByMessageChat, clientMessageId],
        };
      });

      setMessagesById((prevMessagesById) => ({
        ...prevMessagesById,
        [clientMessageId]: localMessage,
      }));
    },
    [setMessagesById, setIdsByChat, socket, user],
  );

  /**
   * Load more messages then prepend them to lists
   */
  const addMoreMessages = useCallback(
    (data) => {
      const { chatId, ids, byId } = data;

      setIdsByChat((prevIdsByChat) => {
        const prevIdsByPayloadChat = prevIdsByChat[chatId];

        return {
          ...prevIdsByChat,
          [chatId]: [...ids, ...prevIdsByPayloadChat],
        };
      });

      setMessagesById((prevMessagesById) => ({
        ...prevMessagesById,
        ...byId,
      }));
    },
    [setIdsByChat, setMessagesById],
  );

  /**
   * Update single message
   */
  const updateMessage = useCallback(
    (messageId, propsToUpdate) => {
      setMessagesById((prevMessagesById) => ({
        ...prevMessagesById,
        [messageId]: {
          ...prevMessagesById[messageId],
          ...propsToUpdate,
        },
      }));
    },
    [messagesById],
  );

  /**
   * Get last message from chat
   * Excluding any potential "clientMessageId" that remains
   */
  const getLastMessage = useCallback(
    (chatId) => {
      const messagesIds = idsByChat[chatId].filter((id) => !id.includes('-'));

      const lastMessageId = messagesIds.length && messagesIds[messagesIds.length - 1];

      return messagesById[lastMessageId];
    },
    [idsByChat, messagesById],
  );

  /**
   * Get messages
   * Filters on visibilityFilter
   * Return object with one list of messages and another with messages + date separators
   */
  const getMessages = useCallback(
    (chatId, chatAppearance = 'spaced') => {
      const messages = idsByChat[chatId]
        .map((id) => messagesById[id])
        .filter((message) => {
          if (!message) return false;

          switch (visibilityFilter) {
            case MESSAGE_FILTER.SHOW_PINNED:
              return message.pinned;
            case MESSAGE_FILTER.SHOW_QUESTION:
              return message.isAsked && !message.isAnswered;
            default:
              return true;
          }
        });

      const messagesWithDates = addDates(messages, chatAppearance);

      return { messages, messagesWithDates };
    },
    [idsByChat, messagesById, visibilityFilter],
  );

  /**
   * Get only survey type messages
   */
  const getSurveys = useCallback(() => {
    const surveys = Object.keys(messagesById)
      .map((id) => messagesById[id])
      .filter((message) => message.kind === MESSAGE_TYPE.TYPE_SURVEY)
      .sort((surveyA, surveyB) => surveyB.time - surveyA.time);

    const surveysWithDates = addDates(surveys);

    return { surveys, surveysWithDates };
  }, [messagesById]);

  /**
   * Initialize MessageContext data on classroom launch
   */
  const initializeData = useCallback((messageData) => {
    setIdsByChat(messageData.idsByChat);
    setMessagesById(messageData.messageById);
  }, []);

  /**
   * Adds new message to chat.
   * Triggered by CREATED_MESSAGE, RECEIVED_MESSAGE, RECEIVED_SURVEY
   * Replaces locally saved message if needed (for optimistic rendering)
   */
  const onMessageReceived = useCallback(
    (data) => {
      const { chatId, clientMessageId, id } = data;

      setIdsByChat((prevIdsByChat) => {
        const prevIdsByPayloadChat = prevIdsByChat[chatId];

        // If message is already saved in idsByChat, we remove it
        if (clientMessageId) {
          const storedIndex = prevIdsByPayloadChat.indexOf(clientMessageId);

          if (storedIndex !== -1) {
            prevIdsByPayloadChat.splice(storedIndex, 1);
          }
        }

        return {
          ...prevIdsByChat,
          [chatId]: [...prevIdsByPayloadChat, id],
        };
      });

      setMessagesById((prevMessagesById) => {
        const newMessagesById = { ...prevMessagesById };

        // Remove clientMessageId from messagesById object
        // 'delete' will return true no matter if clientMessageId is defined or not
        delete newMessagesById[clientMessageId];

        newMessagesById[id] = {
          ...data,
          kind: data.kind || MESSAGE_TYPE.TYPE_TEXT,
          status: MESSAGE_STATUS.STATUS_RECEIVED,
        };

        return newMessagesById;
      });
    },
    [idsByChat, messagesById],
  );

  const value = useMemo(
    () => ({
      addMessage,
      addMoreMessages,
      getLastMessage,
      getMessages,
      getSurveys,
      idsByChat,
      initializeData,
      messagesById,
      noMoreMessages,
      onMessageReceived,
      setIdsByChat,
      setMessagesById,
      setNoMoreMessages,
      setVisibilityFilter,
      updateMessage,
      visibilityFilter,
    }),
    [
      addMessage,
      addMoreMessages,
      getLastMessage,
      getMessages,
      getSurveys,
      idsByChat,
      initializeData,
      messagesById,
      noMoreMessages,
      onMessageReceived,
      setIdsByChat,
      setMessagesById,
      setNoMoreMessages,
      setVisibilityFilter,
      updateMessage,
      visibilityFilter,
    ],
  );

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MessageContext, MessageProvider };
