/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Poll = styled.div`
  height: 100vh;
`;
