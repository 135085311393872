/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Style
 */
const style = {
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
};

/*
 * Component
 */
const Link = ({ href, children }) => (
  <a style={style} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

/*
 * PropTypes
 */
Link.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Link;
