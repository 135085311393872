/* eslint-disable max-len */

/**
 * Default `MediaStreamConstraints` object,
 * used with the `navigator.mediaDevices.getUserMedia` method
 *
 * @doc https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#parameters
 */
export const DEFAULT_CONSTRAINTS = Object.freeze({
  audio: {},
  video: {
    height: { min: 120, ideal: 160 },
    width: { min: 160, ideal: 213 },
    frameRate: { min: 12, ideal: 12 },
  },
});

/**
 * Default `MediaStreamConstraints` object
 * used with the `navigator.mediaDevices.getDisplayMedia` method
 *
 * @doc https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#parameters
 */
export const DEFAULT_DISPLAY_MEDIA_CONSTRAINTS = Object.freeze({
  audio: false,
  video: { frameRate: 12 },
});

/**
 * Common `navigator.mediaDevices.getUserMedia` errors
 */
export const MEDIA_ERRORS = Object.freeze({
  // Webcam and mic are not detect by the browser
  NO_DEVICES: 'nodevices',

  // Webcam or mic are already in use, or disabled on system
  ABORT: 'AbortError',
  NOT_FOUND: 'NotFoundError',
  NOT_READABLE: 'NotReadableError',

  // Permission denied in browser
  NOT_ALLOWED: 'NotAllowedError',
  PERMISSION_DENIED: 'PermissionDeniedError',
});

/**
 * Medias streams max limit
 */
export const MEDIAS_STREAMS_MAX_LIMIT = 5;

/**
 * Medias streams list status
 */
export enum StreamListStatus {
  INIT = 'initialized',
  PENDING = 'pending',
  READY = 'ready',
}
