/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useTheme } from '@emotion/react';

/*
 * Local Import
 */
import { DARK_THEME_NAME } from 'src/constants/theme';
import * as S from './style';

/*
 * Pattern
 */
export const patternCode = /`(.+?)\n?`/g;

/*
 * Component
 */
const Code = ({ children }) => {
  const theme = useTheme();

  return (
    <S.Container style={theme.themeName === DARK_THEME_NAME ? atomOneDark : atomOneLight}>
      {children}
    </S.Container>
  );
};

/*
 * PropTypes
 */
Code.propTypes = {
  children: PropTypes.any.isRequired,
};

/*
 * Export
 */
export default Code;
