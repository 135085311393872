/*
 * Package import
 */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, InputField } from '@oclock/crumble';

/*
 * Local import
 */
import Snackbar from 'src/components/Dashboard/Settings/Account/ProfileSnackbar';
import ResetPassword from 'src/components/Dashboard/Settings/Account/ResetPassword';

// Context
import { UserContext } from 'src/context/User';

// Helpers
import { isEmpty } from 'src/utils';
import { api } from 'src/utils/api';
import { getAvatar } from 'src/utils/avatar';
import * as errorMessages from 'src/utils/errorMessages';
import { purify } from 'src/components/Dashboard/Settings/Account/utils';

// Constants
import { avatarMaxSize, allowedFormats } from 'src/constants/avatar';

// Style
import * as S from './style';

/**
 * Code
 */
const initialState = {
  id: null,
  avatar: null,
  firstname: null,
  lastname: null,
  email: null,
  town: null,
  country: null,
  githubProfile: null,
  discordProfile: null,
};

/*
 * Component
 */
const Account = () => {
  /*
   * Hooks
   */
  const avatarRef = useRef();
  const { updateUserData } = useContext(UserContext);

  /*
   * State
   */

  // Status
  const [status, setStatus] = useState('idle');
  const [snackStatus, setSnackStatus] = useState('idle');

  // Users
  const [initialProfile, setInitialProfile] = useState({});
  const [profile, setProfile] = useState(initialState);

  // Avatar
  const [preview, setPreview] = useState(null);
  const [updateAvatarStatus, setUpdateAvatarStatus] = useState(null);

  /**
   * Compare the oldest fields with the new ones,
   * and check if they are updated
   */
  const getChangedFields = () =>
    Object.keys(initialProfile).reduce((acc, key) => {
      if (initialProfile[key] !== profile[key]) {
        acc.push(key);
      }
      return acc;
    }, []);

  /**
   * Post update actions
   * @param {*} data
   */
  const postUpdateActions = (data) => {
    // Replace the current data with the final data (trimmed, avatar url, etc…)
    setProfile(data);

    // Set as initiales values
    setInitialProfile(data);

    // Status
    setSnackStatus('updated');

    // Dispatch in whole application
    if (data.avatar || data.firstname || data.lastname) {
      updateUserData(data);
    }
  };

  /**
   * Submit all data of the profile (avatar, firstName, lastName, town, etc…)
   * @param {*} event
   */
  const onSubmit = async (event) => {
    event.preventDefault();

    // Props
    const changedKeys = getChangedFields();

    // Is there data to update ?
    if (changedKeys.length) {
      let profileShallow = { ...initialProfile };

      // Convert data
      const newData = changedKeys.reduce((acc, key) => {
        acc[key] = purify(profile[key]);
        return acc;
      }, {});

      // 🖼 Avatar
      if (newData.avatar) {
        const formData = new FormData();
        const extension = newData.avatar.type.substring('image/'.length);
        formData.append('userId', profile.id);
        formData.append('image', newData.avatar, `${profile.id}.${extension}`);

        const payload = {
          method: 'POST',
          url: '/users/setAvatar',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        api(payload)
          .then(({ data }) => {
            profileShallow = { ...profileShallow, avatar: data.avatar };
            postUpdateActions(profileShallow);
            setUpdateAvatarStatus(null);
          })
          .catch((err) => {
            setSnackStatus('failed');
            setUpdateAvatarStatus(
              errorMessages[err.response.data.code] || err.response.data.message,
            );
          });
      }

      // 🙋‍♂️ User data
      if ((newData.avatar && changedKeys.length > 1) || !newData.avatar) {
        if (newData.avatar) {
          delete newData.avatar;
        }

        const payload = {
          method: 'PUT',
          url: '/users/updateProfile',
          data: { param: { _id: profile.id }, newData },
        };

        api(payload)
          .then(() => {
            profileShallow = { ...profileShallow, ...newData };
            postUpdateActions(profileShallow);
          })
          .catch((error) => {
            setSnackStatus('failed');
            throw new Error(error);
          });
      }
    }
  };

  /**
   * Reset all profile data
   */
  const onResetProfile = () => {
    setProfile(initialProfile);
    setUpdateAvatarStatus(null);
    setPreview(null);
  };

  /**
   * Open the file select popup
   */
  const triggerFileSelectPopup = () => avatarRef.current.click();

  /**
   * Update profile fields
   * @param {*} event
   */
  const onChange = (event) => {
    // Use-case : 💌 email
    if (event.target.id === 'email') return;

    // All others fields…
    setProfile({
      ...profile,
      [event.target.id]: event.target.value || null,
    });
  };

  /**
   * Update Avatar
   * @param {*} event
   */
  const onAvatarChange = (event) => {
    if (event.target.files?.length) {
      setProfile({ ...profile, avatar: event.target.files[0] });

      // Display preview
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        setPreview(reader.result);
      });

      // Case 1 : Avatar Max Size
      if (event.target.files[0].size > avatarMaxSize) {
        setUpdateAvatarStatus(errorMessages.LIMIT_FILE_SIZE);
      }

      // Case 2 : Bad file extension
      else if (allowedFormats.every((format) => event.target.files[0].type !== `image/${format}`)) {
        setUpdateAvatarStatus(errorMessages.BAD_FILE_EXT);
      }

      // OK
      else {
        setUpdateAvatarStatus(null);
      }
    }
  };

  /**
   * Lifecycles
   */
  useEffect(() => {
    // Data request
    let isMounted = true;
    api({ method: 'GET', url: 'users/findProfile' })
      .then(({ data }) => {
        const {
          avatar,
          firstname,
          lastname,
          email,
          id,
          town,
          country,
          githubProfile,
          discordProfile,
        } = data;
        const fetchedUserInfo = {
          avatar,
          firstname,
          lastname,
          email,
          id,
          town,
          country,
          githubProfile,
          discordProfile,
        };

        if (isMounted) {
          setProfile(fetchedUserInfo);
          setInitialProfile(fetchedUserInfo);
          setStatus('fetched');
        }
      })
      .catch(() => {
        setStatus('failed');
      });

    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * Props
   */
  const firstNameEmpty = status !== 'idle' && isEmpty(profile.firstname);
  const lastNameEmpty = status !== 'idle' && isEmpty(profile.lastname);

  const disabled = status === 'failed'
    || lastNameEmpty
    || firstNameEmpty
    || !getChangedFields().length
    || updateAvatarStatus;

  /*
   * Render
   */
  return (
    <S.Account>
      {/* Snackbar */}
      <Snackbar status={status} snackStatus={snackStatus} setSnackStatus={setSnackStatus} />

      <S.FormHeader>
        <S.Title>Informations personnelles</S.Title>
        <S.Caption>*Champs oblilgatoires</S.Caption>
      </S.FormHeader>

      {/* Form */}
      <S.Form onSubmit={onSubmit}>
        <S.Fieldset>
          {/* Avatar */}
          <S.Line>
            <S.Box>
              <S.Label htmlFor="avatar">Avatar</S.Label>
              <S.AvatarContainer>
                <S.Preview
                  error={updateAvatarStatus}
                  aria-label="votre avatar"
                  src={
                    preview
                    || getAvatar({
                      name: `${profile.firstname} ${profile.lastname}`,
                      avatar: profile.avatar,
                    })
                  }
                />
                <S.UploadContainer>
                  <S.Upload
                    onClick={triggerFileSelectPopup}
                    aria-label="Cliquez ici pour changer votre avatar"
                  >
                    Charger une image
                  </S.Upload>
                  <ul>
                    <S.Format>Format accepté : JPG, JPEG, PNG</S.Format>
                    <S.Format>Taille maximale : 2 Mo</S.Format>
                  </ul>
                  {updateAvatarStatus && (
                    <S.AvatarError>
                      <S.ErrorIcon />
                      <p>{updateAvatarStatus}</p>
                    </S.AvatarError>
                  )}
                </S.UploadContainer>
              </S.AvatarContainer>
              <S.Input
                id="avatar"
                ref={avatarRef}
                type="file"
                value=""
                onChange={onAvatarChange}
                isAvatar
                accept="image/png, image/jpeg, image/jpg"
              />
            </S.Box>
          </S.Line>
          {/* Account */}
          <S.Line>
            <S.Box withMargin>
              <InputField
                id="lastname"
                type="text"
                label="Nom"
                required
                onChange={onChange}
                value={profile.lastname || ''}
                helperType={lastNameEmpty && 'error'}
                helperMessage="Veuillez renseigner ce champ."
              />
            </S.Box>
            <S.Box>
              <InputField
                id="firstname"
                type="text"
                label="Prénom"
                required
                onChange={onChange}
                value={profile.firstname || ''}
                helperType={firstNameEmpty && 'error'}
                helperMessage="Veuillez renseigner ce champ."
              />
            </S.Box>
          </S.Line>
          <S.Line>
            <S.Box>
              <InputField
                id="email"
                type="email"
                label="Adresse mail"
                disabled
                onChange={onChange}
                value={profile.email || ''}
              />
            </S.Box>
          </S.Line>
          {/* The following fields have been hidden following this ticket. This is temporary.
            https://www.notion.so/slippers/Masquer-des-infos-dans-le-profil-713ee9e4a6094d14a02ebb95c6dd88b5
          */}
          {/* <S.Line>
            <S.Box withMargin>
              <InputField
                id="town"
                type="text"
                label="Ville"
                onChange={onChange}
                value={profile.town || ''}
              />
            </S.Box>
            <S.Box>
              <InputField
                id="country"
                type="text"
                label="Pays"
                onChange={onChange}
                value={profile.country || ''}
              />
            </S.Box>
          </S.Line>
          <S.Line>
            <S.Box withMargin>
              <InputField
                id="githubProfile"
                type="text"
                label="Pseudo Github"
                onChange={onChange}
                value={profile.githubProfile || ''}
              />
            </S.Box>
            <S.Box>
              <InputField
                id="discordProfile"
                type="text"
                label="Pseudo Discord"
                onChange={onChange}
                value={profile.discordProfile || ''}
              />
            </S.Box>
          </S.Line> */}
          {/* CTAs */}
          <S.ButtonContainer>
            <Button
              type="button"
              onClick={onResetProfile}
              variant="secondary"
              disabled={!getChangedFields().length}
              aria-disabled={disabled}
            >
              Annuler
            </Button>
            <Button type="submit" variant="primary" disabled={disabled} aria-disabled={disabled}>
              Enregistrer
            </Button>
          </S.ButtonContainer>
        </S.Fieldset>
      </S.Form>

      {/* Reset Password */}
      <ResetPassword email={profile.email} />
    </S.Account>
  );
};

/*
 * Export
 */
export default Account;
