/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '2.5rem auto 0',
});

export const PasswordSent = styled.p(({ theme }) => ({
  width: '376px',
  margin: '1rem auto auto',
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '150%',
  letterSpacing: '-0.18px',
  color: theme.colors.coolGrey._600,
}));

export const PasswordErr = styled.p(({ theme }) => ({
  width: '376px',
  margin: '1rem auto auto',
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '150%',
  letterSpacing: '-0.18px',
  color: theme.colors.danger._600,
}));

export const SendPassword = styled.button(({ theme }) => ({
  width: '296px',
  height: '48px',
  marginTop: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  background: theme.colors.danger._100,
  color: theme.colors.danger._500,
  borderRadius: '8px',
  fontWeight: '500',
  fontSize: '1rem',
  lineHeight: '150%',
}));
