/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { UserFormDataValidated, UserDTO } from 'src/schemas/Entities/User';
import { createUser } from './createUser';

/*
 * Code
 */
export const useCreateUser = (): UseMutationResult<
UserDTO,
unknown,
UserFormDataValidated,
unknown
> => {
  const userMutation = useMutation(
    (userFormData: UserFormDataValidated) => createUser(userFormData),
    {
      onSettled: () => {
        queryClient.invalidateQueries('users');
      },
      onSuccess: () => {
        toast('Utilisateur créé avec succès, une invitation par mail lui a été envoyée !', {
          type: 'success',
        });
      },
    },
  );

  return userMutation;
};
