/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const BadgeContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  /** Medias */
  '@media (max-width: 1440px)': {
    display: 'none',
  },
}));
