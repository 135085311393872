/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Webcam = styled.div(
  {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  ({ isLoading }) =>
    isLoading && {
      '::before': {
        ...base.loader,
      },
    },
);

export const Video = styled.video`
  /* Adjust size */
  height: 100%;

  /* Mirror & Center */
  transform: translateX(-50%) scale(-1, 1);
  margin-left: 50%;
`;
