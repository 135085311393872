/**
 * Package Import
 */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Local Import
 */
import ButtonsSelector from 'src/components/TopBar/Menu/SubMenus/ButtonsSelector';
import { chatSizeOptions } from 'src/data/settings';
import { setChatSize } from 'src/store/actions';
import { getChatSize } from 'src/store/selectors/settings';

// Style
import * as G from '../../../style';

/**
 * Component
 */
const ChatSize = (props) => {
  const dispatch = useDispatch();
  const chatSize = useSelector(getChatSize);

  /**
   * Update the user list style
   * @param {string} value
   * @return {void}
   */
  const handleChange = useCallback((value) => {
    dispatch(setChatSize(value));
  }, []);

  /**
   * Render
   */
  return (
    <ButtonsSelector
      icon={<G.IconChatSize aria-hidden="true" />}
      onChange={handleChange}
      options={chatSizeOptions}
      title="Largeur du chat"
      value={chatSize}
      {...props}
    />
  );
};

/**
 * Export
 */
export default ChatSize;
