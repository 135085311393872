/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local import
 */
import Away from 'src/components/Away';
import {
  changeAway,
  changeAwayTime,
  enableMicrophone,
  enableWebcam,
  enableScreen,
  enableSound,
} from 'src/store/actions';
import { isEducator, isTeacher } from 'src/utils/roles';

/*
 * Code
 */
// State
const mapStateToProps = (state) => {
  const { client } = state.users;
  const isAway = state.users.userById?.[client.id].away ?? false;

  return {
    display: state.users.userById[client.id].away,
    displayAwayModal: isAway,
    user: client.id,
    timeValue: state.breaktime.timeValue,
    isTeacher: isTeacher(client.role),
    isEducator: isEducator(client.role),
    screenEnabled: state.course.medias.screenEnabled,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeAway,
      changeAwayTime,
      enableMicrophone,
      enableWebcam,
      enableScreen,
      enableSound,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Away);
