/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Styles
 */
export const Container = styled.div(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.background._2,
  paddingLeft: '5%',
}));

export const Number = styled.div(({ theme }) => ({
  fontSize: '160px',
  fontWeight: '700',
  color: theme.text._1,
}));

export const Text = styled.div(({ theme }) => ({
  color: theme.text._1,
  ...theme.typo.display._2,
  marginBottom: '40px',
}));

export const Link = styled.a(({ theme }) => ({
  background: theme.background._6,
  color: theme.text._10,
  padding: '.5rem 1rem',
  width: 'fit-content',
  borderRadius: '8px',
}));
