/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*
 * Local Import
 */
import Header from 'src/components/Admin/Header';
import CourseTable from 'src/components/Admin/Pages/Course/CourseTable';

// Helpers
import { trackEvent } from 'src/utils/tracking';
import { ADMIN_COURSES_TITLE } from 'src/constants/titles';

// Style
import * as S from './style';

/*
 * Code
 */
const CoursePage = (): JSX.Element => {
  /*
   * Hooks
   */
  const { pathname } = useLocation();

  /**
   * LifeCycles
   */
  useEffect(() => {
    document.title = ADMIN_COURSES_TITLE;

    // Mixpanel tracking event
    trackEvent('Page Viewed', {
      title: ADMIN_COURSES_TITLE,
      url: pathname,
    });
  }, []);

  /**
   * Render
   */
  return (
    <S.Container>
      <Header pageTitle="Gestion des cours" />
      <CourseTable />
    </S.Container>
  );
};

/*
 * Export
 */
export default CoursePage;
