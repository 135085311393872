/*
 * Package Import
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Webcam from 'src/components/Course/Webcams/Webcam/container';

// Helpers
import { isEducator, isHelper } from 'src/utils/roles';

// Constants
import { StreamListStatus } from 'src/constants/mediaDevices';

// Style
import * as S from './style';

/*
 * Component
 */
const Webcams = ({
  actions,
  isMaxTracksReached,
  myRole,
  peers,
  settingsWebcamsEnabled,
  webRTCConnected,
  streamListStatus,
  streamList,
  antmediaLocalStreamReady,
}) => {
  /*
   * LifeCycles
   */
  useEffect(() => {
    // Initialize stream, when the whole WebRTC service is ready,
    // start (once only) the stream, and mute the microphone device, for helpers.
    if (webRTCConnected && antmediaLocalStreamReady && isEducator(myRole)) {
      actions.startMyWebcamStream({ audioOnly: false });

      if (isHelper(myRole)) {
        actions.disableMicrophone();
      }
    }
  }, [webRTCConnected, antmediaLocalStreamReady]);

  useEffect(() => {
    if (
      webRTCConnected
      && antmediaLocalStreamReady
      && isEducator(myRole)
      && streamListStatus !== StreamListStatus.PENDING
    ) {
      // For teachers and helpers, when entering
      // (so local stream has not been launched yet)
      // if webcams limit is reached, disable webcam
      if (isMaxTracksReached && !streamList.includes('localView')) {
        actions.disableWebcam(true);
      }
    }
  }, [webRTCConnected, streamListStatus, antmediaLocalStreamReady]);

  /*
   * Render
   */
  return (
    <S.Webcams settingsWebcamsDisabled={!settingsWebcamsEnabled}>
      {peers.map((id) => (
        <Webcam key={id} userId={id} />
      ))}
    </S.Webcams>
  );
};

/*
 * PropTypes
 */
Webcams.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Webcams limits */
  isMaxTracksReached: PropTypes.bool.isRequired,
  /** Client role  */
  myRole: PropTypes.string.isRequired,
  /** Peers array */
  peers: PropTypes.array.isRequired,
  /** Show webcams ? */
  settingsWebcamsEnabled: PropTypes.bool.isRequired,
  /** WebRTC infos */
  webRTCConnected: PropTypes.bool.isRequired,
  streamListStatus: PropTypes.string.isRequired,
  streamList: PropTypes.array.isRequired,
  antmediaLocalStreamReady: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default Webcams;
