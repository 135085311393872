/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface SettingsPopoutState {
  popoutChat: boolean;
  popoutCamById: Record<string, boolean>;
  popoutScreen: boolean;
}

/*
 * Initial State
 */
const initialState: SettingsPopoutState = {
  popoutChat: false,
  popoutCamById: {},
  popoutScreen: false,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.RESET_LAYOUT:
        return initialState;

      case types.SET_POPOUT_CHAT:
        draft.popoutChat = action.isOpen;
        break;

      case types.SET_POPOUT_CAM:
        draft.popoutCamById[action.userId] = action.isOpen;
        break;

      case types.SET_POPOUT_SCREEN:
        draft.popoutScreen = action.isOpen;
        break;

      default:
        return state;
    }
  });
