/**
 * Package Import
 */

/**
 * Local Import
 */
import { intervalToDuration } from 'date-fns';
import { DurationString, TimeString, MongoId } from 'src/schemas/Entities/utils';
import { timeStringToNumberValue } from 'src/utils/time';

/**
 * Types
 */
export type Slot = {
  _id?: MongoId;
  start: TimeString;
  end: TimeString;
};

/**
 * Constants
 */
export const defaultSlot: Slot = {
  start: '09:00',
  end: '12:00',
};

export const emptySlot: Slot = {
  start: '',
  end: '',
};

/**
 * Utils
 */
const isTimeslotStartBeforeEnd = (start: TimeString, end: TimeString): boolean => {
  const startNumber = timeStringToNumberValue(start);
  const endNumber = timeStringToNumberValue(end);

  return startNumber <= endNumber;
};

export const getHoursOrMinutes = (value: TimeString, type: 'hours' | 'minutes') =>
  parseInt(value.split(':')[type === 'hours' ? 0 : 1], 10);

export const getDuration = (
  startValue: TimeString | undefined,
  endValue: TimeString | undefined,
): DurationString => {
  if (!startValue || !endValue) {
    return '--h--';
  }

  const start = new Date();
  const end = new Date();

  start.setHours(getHoursOrMinutes(startValue, 'hours'), getHoursOrMinutes(startValue, 'minutes'));
  end.setHours(getHoursOrMinutes(endValue, 'hours'), getHoursOrMinutes(endValue, 'minutes'));

  try {
    const { hours, minutes } = intervalToDuration({ start, end });

    if (hours === undefined || minutes === undefined) {
      return '--h--';
    }

    if (!isTimeslotStartBeforeEnd(startValue, endValue)) {
      return '--h--';
    }

    return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
  }
  catch (err) {
    return '--h--';
  }
};
