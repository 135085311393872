/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import SurveysList from 'src/components/Chats/Poll/SurveysList';

// Helpers
import { isEducator } from 'src/utils/roles';

// Redux
import { openModalSurvey } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  isEducator: isEducator(state.users.client.role),
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ openModalSurvey }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(SurveysList);
