/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Time = styled.time(({ theme }) => ({
  color: theme.text._6,
  ...theme.typo.labels.tinyLabel,
}));
