/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import {
  userFormDataSchema,
  UserFormData,
  userFormDataValidatedSchema,
  UserFormDataValidated,
} from 'src/schemas/Entities/User';
import { CustomSelect } from 'src/components/Admin/Modals/FormElements/Selects/CustomSelect';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { useCreateUser } from 'src/serverStateManagers/mutations/create/Users/useCreateUser';
import Tooltip from 'src/components/Tooltip';

import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { useHistory } from 'react-router-dom';
import { getRoute } from 'src/utils/routes';
import { useStore } from 'src/localStateManagers/store';
import { useGetIntegration } from 'src/serverStateManagers/queries/Integrations/useGetIntegration';
import { IntegrationApps } from 'src/constants/integrations';
import { isBasic } from 'src/utils/roles';
import { usersAddFormData } from './config';

/*
 * Code
 */
type UseWatchResultProps = {
  label: string;
  value: string;
};

type AddUserProps = {
  isOpen: boolean;
};
const AddUser = ({ isOpen }: AddUserProps): JSX.Element | null => {
  const createUserMutation = useCreateUser();
  const history = useHistory();
  const client = useStore((state) => state.client);

  // Config
  const { header, inputs } = usersAddFormData;
  const { firstname, lastname, email, selectPromotions, selectRole, mustSign, canCreateVM } = inputs;

  // Selects Data
  const { data: promotionsData } = useGetPromotions();
  const activePromotionsData = useMemo(
    () => promotionsData?.filter((promoData) => !promoData.deactivatedAt),
    [promotionsData],
  );

  const { data: edusignData } = useGetIntegration(IntegrationApps.EDUSIGN);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    control,
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(userFormDataSchema),
  });

  const selectedRole: UseWatchResultProps | undefined = useWatch({
    control,
    name: selectRole.title,
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  useEffect(() => {
    if (selectedRole) {
      setValue(canCreateVM.title, !isBasic(selectedRole.value));
    }
  }, [selectedRole]);

  const onSubmit = (data: UserFormData): void => {
    const userFormDataValidatedResult: UserFormDataValidated = userFormDataValidatedSchema.parse(data);
    clearErrors();
    createUserMutation.mutate(userFormDataValidatedResult);
    history.push(getRoute('usersTable').getUrl({}));
  };

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    reset();
    history.push(getRoute('usersTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>
        <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={firstname.title}>{firstname.display}</F.Label>
              <F.Input
                className={errors.firstname ? 'is-error' : undefined}
                ref={register()}
                name={firstname.title}
                type={firstname.type}
              />
              {errors.firstname?.message && (
                <F.ErrorMessage>{errors.firstname.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field>
              <F.Label htmlFor={lastname.title}>{lastname.display}</F.Label>
              <F.Input
                className={errors.lastname ? 'is-error' : undefined}
                ref={register()}
                name={lastname.title}
                type={lastname.type}
              />
              {errors.lastname?.message && (
                <F.ErrorMessage>{errors.lastname.message}</F.ErrorMessage>
              )}
            </F.Field>
          </F.Box>
        </F.Line>
        <F.Field>
          <F.Label htmlFor={email.title}>{email.display}</F.Label>
          <F.Input
            className={errors.email ? 'is-error' : undefined}
            ref={register()}
            name={email.title}
            type={email.type}
          />
          {errors.email?.message && <F.ErrorMessage>{errors.email.message}</F.ErrorMessage>}
        </F.Field>
        <F.Line>
          <F.Box isLeft>
            <F.Field>
              <F.Label htmlFor={selectRole.title}>{selectRole.display}</F.Label>
              <CustomSelect
                isMulti={selectRole.isMulti}
                data={selectRole.rolesOptions}
                defaultValue={selectRole.rolesOptions[0]}
                selectName={selectRole.title}
                control={control}
                isError={!!errors.role}
                optionsToDisable={selectRole.getRolesOptionsToDisable(client)}
              />
              {errors.role?.message && <F.ErrorMessage>{errors.role.message}</F.ErrorMessage>}
            </F.Field>
          </F.Box>
          <F.Box>
            <F.Field key={selectPromotions.title}>
              <F.Label htmlFor={selectPromotions.title}>{selectPromotions.display}</F.Label>
              <CustomSelect
                isMulti={selectPromotions.isMulti}
                data={selectPromotions.getPromoOptions(activePromotionsData)}
                selectName={selectPromotions.title}
                control={control}
                isError={!!errors.promotions}
                optionsExtraData={selectPromotions.getPromoOptionsTooltipsInfos(
                  activePromotionsData,
                )}
              />
              {!!errors.promotions
                && errors.promotions?.map(
                  (error, index) =>
                    error?.message && <F.ErrorMessage key={index}>{error.message}</F.ErrorMessage>,
                )}
            </F.Field>
          </F.Box>
        </F.Line>
        {!!edusignData?.isAccountLinked && (
          <F.Field style={{ display: 'flex' }}>
            <Tooltip content={mustSign.tooltip}>
              <F.CheckboxField>
                <F.CheckboxInput
                  type={mustSign.type}
                  ref={register()}
                  name={mustSign.title}
                  defaultChecked={edusignData?.customProperties?.signature_by_default}
                />
                <F.Label htmlFor={mustSign.title}>{mustSign.display}</F.Label>
              </F.CheckboxField>
            </Tooltip>
          </F.Field>
        )}
        <F.Field style={{ display: 'flex' }}>
          <F.CheckboxField>
            <F.CheckboxInput type={canCreateVM.type} ref={register()} name={canCreateVM.title} />
            <F.Label htmlFor={canCreateVM.title}>{canCreateVM.display}</F.Label>
          </F.CheckboxField>
        </F.Field>

        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default AddUser;
