/*
 * Package Import
 */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Dialog } from '@material-ui/core';

/*
 * Local Import
 */
import { animations } from 'src/theme';

/*
 * Keyframes
 */
const fadeScaleIn = keyframes(animations.fadeScaleIn);

/*
 * Style
 */
export const Away = styled.div`
  margin: auto;
  text-align: center;
  animation: 0.2s 0.2s ${fadeScaleIn} both;
`;

export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter',
  width: '100%',
  height: 'auto',
  background: theme.colors.coolGrey._800,
  display: 'flex',
  justifyContent: 'center',
  '.MuiDialog-paper': {
    background: theme.background._2,
    borderRadius: '16px',
  },
}));
