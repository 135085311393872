/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ChatEvent from 'src/components/Chats/Channel/Chat/Event';
import { changeMainChatView } from 'src/store/actions';

/*
 * Code
 */
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeMainChatView,
    },
    dispatch,
  ),
});
/*
 * Export
 */
export default connect(null, mapDispatchToProps)(ChatEvent);
