/* eslint-disable max-len */
/*
 * Package Import
 */
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';

// Queries
import { useGetCourses } from 'src/serverStateManagers/queries/Courses/useGetCourses';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { useDeactivatePromotion } from 'src/serverStateManagers/mutations/deactivate/Promotions/useDeactivatePromotion';

// Schemas
import { MongoId } from 'src/schemas/Entities/utils';

// Helpers
import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
type DeactivateProps = {
  isOpen: boolean;
  idToDeactivate: MongoId | null;
};

const Deactivate = ({ isOpen, idToDeactivate }: DeactivateProps): JSX.Element => {
  const history = useHistory();
  const { data: coursesData } = useGetCourses();
  const deactivatePromotionMutation = useDeactivatePromotion();

  const { data: promotionsData } = useGetPromotions();
  const promotionData = promotionsData?.find((promotion) => promotion.id === idToDeactivate);

  useEffect(() => {
    if (promotionData?.deactivatedAt) {
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [promotionData?.deactivatedAt, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (promotionData?.deactivatedAt) {
      toast('Promotion déjà archivée', { type: 'warning' });
      history.push(getRoute('promotionsTable').getUrl({}));
      return;
    }
    if (idToDeactivate) {
      deactivatePromotionMutation.mutate(idToDeactivate);
    }
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  // get courses affected by the deletion of a promo related
  const relatedCoursesForPromotion = coursesData?.filter(
    (course) => course.promotion.id === idToDeactivate,
  );

  // is there any relations ?
  const isRelated = relatedCoursesForPromotion && relatedCoursesForPromotion?.length > 0;

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>Archiver</F.DeactivateHeader>
        <F.DeactivateLabel>Êtes-vous sûr de vouloir archiver cette promotion ?</F.DeactivateLabel>
        {isRelated && (
          <>
            <F.DeactivateLabel>
              Les éléments listés ci-dessous seront impactés par l&apos;archivage :
            </F.DeactivateLabel>
            <F.RelatedItemsContainer>
              {relatedCoursesForPromotion?.map((relatedCourse) => (
                <F.RelatedItem key={relatedCourse.id}>
                  <Tooltip content="Un cours doit avoir une promotion active">
                    <F.Info>
                      <F.InfoIcon />
                    </F.Info>
                  </Tooltip>
                  Cours {relatedCourse.title}
                </F.RelatedItem>
              ))}
            </F.RelatedItemsContainer>
          </>
        )}
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="danger" onClick={handleSubmit} fullWidth>
            Archiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Deactivate;
