/**
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import ScrollShadowContainer from 'src/components/ScrollShadowContainer';
import ListDisplay from 'src/components/Users/UsersPanel/List/ListDisplay';
import SearchResult from 'src/components/Users/UsersPanel/List/SearchResult';
import UserSearch from 'src/components/Users/UsersPanel/List/UserSearch';
import VoiceRequest from 'src/components/Users/UsersPanel/VoiceRequest/container';

// Hooks
import useSearch from 'src/components/Users/UsersPanel/List/useSearch';
import { useScroll } from 'src/hooks';

/**
 * Component
 */
const UsersPanel = ({ users }) => {
  /**
   * State
   */
  const [searchView, setSearchView] = useState(false);

  /**
   * Hooks
   */
  const { handleScroll, isTop } = useScroll();
  const [usersFiltered, searchTerm, setSearchTerm] = useSearch({
    array: users,
    options: { keys: ['name'] },
  });

  /**
   * LifeCycles
   */
  useEffect(() => {
    setSearchView(!!searchTerm);
  }, [searchTerm]);

  /**
   * Render
   */
  return (
    <>
      <ScrollShadowContainer isTop={isTop}>
        <UserSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <VoiceRequest />
      </ScrollShadowContainer>
      {searchView ? (
        <SearchResult handleScroll={handleScroll} usersFiltered={usersFiltered} />
      ) : (
        <ListDisplay handleScroll={handleScroll} users={users} />
      )}
    </>
  );
};

/**
 * PropTypes
 */
UsersPanel.propTypes = {
  users: PropTypes.array.isRequired,
};

/**
 * Export
 */
export default UsersPanel;
