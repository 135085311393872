import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.label;
export const RelatedItemsContainer = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.danger.background}`,
  borderRadius: '8px',
  padding: '.5rem',
  marginBottom: '.5rem',
  color: theme.text._1,
  ...getLabelTypoStyle(theme),
}));

export const RelatedItem = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
