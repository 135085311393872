/*
 * Package Import
 */
import produce from 'immer';
import { AnyAction } from 'redux';

/*
 * Local Import
 */
import { WebsocketStatusEnum, WEBSOCKET_STATUS } from 'src/constants/websocket';
import * as types from 'src/store/types';

/**
 *  Types
 */
export interface SocketState {
  status: WebsocketStatusEnum;
}

/*
 * Initial State
 */
const initialState: SocketState = {
  status: WEBSOCKET_STATUS.INITIAL_STATUS,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.WEBSOCKET_CONNECT:
        draft.status = WEBSOCKET_STATUS.CONNECTION_PENDING;
        break;

      case types.WEBSOCKET_CONNECT_SUCCESS:
        draft.status = WEBSOCKET_STATUS.CONNECTION_SUCCESS;
        break;

      case types.WEBSOCKET_CONNECT_ERROR:
        draft.status = WEBSOCKET_STATUS.CONNECTION_ERROR;
        break;

      default:
        return state;
    }
  });
