/*
 * Package Import
 */
import { format } from 'date-fns';
import isAfter from 'date-fns/isAfter';

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { RoleEnum } from 'src/constants/roles';
import { Client } from 'src/schemas/Entities/User';
import { MongoId, TimeString } from 'src/schemas/Entities/utils';

/*
 * Code
 */
export const getMinDate = (
  promotionsData: Promotion[] | undefined,
  currentPromotionOption: Option | undefined,
  watched: Option | null,
): string | undefined => {
  if (!promotionsData) {
    return undefined;
  }

  if (watched) {
    const watchedPromotion = promotionsData.find((promotion) => promotion.id === watched.value);

    if (watchedPromotion) {
      return format(watchedPromotion.start, 'yyyy-MM-dd');
    }
  }

  if (currentPromotionOption) {
    const currentPromotion = promotionsData.find(
      (promotion) => promotion.id === currentPromotionOption.value,
    );
    if (currentPromotion) {
      return format(currentPromotion.start, 'yyyy-MM-dd');
    }
  }

  return undefined;
};

export const getMaxDate = (
  promotionsData: Promotion[] | undefined,
  currentPromotionOption: Option | undefined,
  watched: Option | null,
): string | undefined => {
  if (!promotionsData) {
    return undefined;
  }

  if (watched) {
    const watchedPromotion = promotionsData.find((promotion) => promotion.id === watched.value);

    if (watchedPromotion) {
      return format(watchedPromotion.end, 'yyyy-MM-dd');
    }
  }

  if (currentPromotionOption) {
    const currentPromotion = promotionsData.find(
      (promotion) => promotion.id === currentPromotionOption.value,
    );
    if (currentPromotion) {
      return format(currentPromotion.end, 'yyyy-MM-dd');
    }
  }

  return undefined;
};

// Date readOnly ?
// We want to avoid to let the users change the date of a course already given to not break replays
// We let this option at superAdmin as escape hatche if needed
export const isDateReadOnly = (date: Date, client: Client | null): boolean => {
  if (isAfter(new Date(), date)) {
    if (client?.role !== ('superAdmin' as RoleEnum)) {
      return true;
    }
  }
  return false;
};

export const getFormattedTimeslots = (
  timeslots: { start: Date; end: Date; _id?: MongoId }[] | undefined,
): { start: TimeString; end: TimeString; _id?: MongoId }[] => {
  if (!timeslots) return [];
  return timeslots.map((timeslot) => ({
    start: format(timeslot.start, 'HH:mm'),
    end: format(timeslot.end, 'HH:mm'),
    ...(timeslot._id && { _id: timeslot._id }),
  }));
};
