import { useCallback, useContext, useEffect } from 'react';

import { MessageContext } from 'src/context/Message';
import { CHAT_CREATED } from 'src/store/types';

/**
 * Hook that handles creating a new chat locally when receiving CHAT_CREATED.
 * If given a socket, it will add listener for this action.
 */
export const useChat = (socket) => {
  const { idsByChat, setIdsByChat } = useContext(MessageContext);

  /**
   * Creates new chat in MessageContext from event's chatId
   */
  const onChatCreated = useCallback(
    ({ chatId }) => {
      if (!idsByChat[chatId]) {
        setIdsByChat((prevIdsByChat) => ({
          ...prevIdsByChat,
          [chatId]: [],
        }));
      }
    },
    [idsByChat],
  );

  useEffect(() => {
    socket?.on(CHAT_CREATED, onChatCreated);

    return () => {
      socket?.off(CHAT_CREATED, onChatCreated);
    };
  }, [onChatCreated, socket]);
};
