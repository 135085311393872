/*
 * Package Import
 */

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { Client, User } from 'src/schemas/Entities/User';
import { roles, RoleEnum, Role } from 'src/constants/roles';
import { Option, OptionTooltip } from 'src/components/Admin/Modals/FormElements/type';
import { PromotionOptionTooltip } from 'src/components/Admin/Modals/FormElements/Selects/CustomSelect/optionTooltip/Promotion';

/*
 * Code
 */
const getRoleByName = (name: RoleEnum): Role | undefined =>
  roles.find((role: Role) => role.name === name);

export const getRolesOptionsToDisable = (client: Client | null): Option[] => {
  const clientRoleScore = roles.find((role) => role.name === client?.role)?.score ?? 0;

  return roles
    .filter((role: Role): boolean => role.score > clientRoleScore)
    .sort((roleA, roleB) => roleB.score - roleA.score)
    .map(
      (role: Role): Option => ({
        label: role.display,
        value: role.name,
      }),
    );
};

export const getPedaOptions = (users: User[] | undefined): Option[] => {
  if (!users || users.length === 0) return [];
  const pedaUsers = users.filter((user) => {
    const role = getRoleByName(user.role);
    if (!role) return false;
    return role.isPeda;
  });
  const pedaOptions = pedaUsers
    .map(
      (user: User): Option => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.id,
      }),
    )
    .sort((userA, userB) => (userB.label.toLowerCase() > userA.label.toLowerCase() ? -1 : 1));
  return pedaOptions;
};

export const getPromoOptions = (data: Promotion[] | undefined): Option[] => {
  if (!data || data.length === 0) return [];
  // Sort all promotions by date
  const sortedPromos = data.sort((promoA, promoB) => (promoB.start > promoA.start ? -1 : 1));

  // First, keep only sorted parents promotions
  const orderedPromos = sortedPromos.filter((promo) => !promo.isChild);

  // Keep only children promotions
  const childrenPromos = sortedPromos.filter((promo) => promo.isChild);

  // For each children
  childrenPromos.forEach((promo) => {
    // Get parent index in parent promotions
    const parentIndex = orderedPromos.findIndex((parent) => parent.id === promo.parentId);
    // Then, insert child promotion just after its parent in ordered promotions
    orderedPromos.splice(parentIndex + 1, 0, promo);
  });

  // Format all final promotions as options
  const options = orderedPromos.map(
    (promo: Promotion): Option => ({
      label: promo.displayName ?? promo.name,
      value: promo.id,
      ...(promo.isChild && { isNested: true }),
    }),
  );

  return options;
};

export const getPromoOptionsTooltipsInfos = (data: Promotion[] | undefined): OptionTooltip[] => {
  if (!data || data.length === 0) return [];
  return data.map((promotion: Promotion) => ({
    id: promotion.id,
    infos: {
      name: promotion.name,
      dates: `${promotion.start.toLocaleDateString('fr-FR')} - ${promotion.end.toLocaleDateString(
        'fr-FR',
      )}`,
      ...(promotion.displayName && { displayName: promotion.displayName }),
    },
    component: (infos) => PromotionOptionTooltip(infos),
  }));
};
