/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import { TooltipInfos } from 'src/components/Admin/Modals/FormElements/type';

/*
 * Style
 */
const getSmallLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.smallLabel;
const Label = styled.div(({ theme }) => ({
  ...getSmallLabelStyle(theme),
}));

/*
 * Component
 */
export const PromotionOptionTooltip = ({ name, dates, displayName }: TooltipInfos): JSX.Element => (
  <>
    <Label>{displayName || name}</Label>
    <div>{dates}</div>
  </>
);
