export const eventNames = {
  CLASS_JOINED: 'Class Joined',
  CLASS_LEFT: 'Class Left',
  BREAK_TAKEN: 'Break Taken',
  BREAK_ENDED: 'Break Ended',
  SPEECH_ASKED: 'Speech Asked',
  SPEECH_CANCELLED: 'Speech Cancelled',
  SPEECH_GIVEN: 'Speech Given',
  SPEECH_GIVEN_BACK: 'Speech Given Back',
  MESSAGE_SENT: 'Message Sent',
  MESSAGE_MODIFIED: 'Message Modified',
  MESSAGE_DELETED: 'Message Deleted',
  POLL_CREATED: 'Poll Created',
  POLL_ANSWERED: 'Poll Answered',
  MESSAGE_PINNED: 'Message Pinned',
  QUESTION_ASKED: 'Question Asked',
  QUESTION_RESOLVED: 'Question Resolved',
  INTERFACE_UPDATED: 'Interface Updated',
  DEVICE_MANAGED: 'Device Managed',
  SCREEN_SHARED: 'Screen Shared',
  NOTIFICATIONS_MANAGED: 'Notifications Managed',
  SHORTCUTS_DISPLAYED: 'Shortcuts Displayed',
  EMOTION_UPDATED: 'Emotion Updated',
  EMOTION_DELETED: 'Emotion Deleted',
  GLOBAL_CHAT_OPENED: 'Global Chat Opened',
  PRIVATE_CHAT_OPENED: 'Private Chat Opened',
  MESSAGES_FILTERED: 'Messages Filtered',
  DISCUSSION_MODE_ENABLED: 'Discussion Mode Enbaled',
  DISCUSSION_MODE_DISABLED: 'Discussion Mode Disabled',
};

export const triggerLocations = {
  GLOBAL_CHAT: 'Global chat',
  PRIVATE_CHAT: 'Private chat',
  USER_LIST: 'User list',
};
