/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Code
 */
export const promotionsUpdateFormData = {
  header: 'Modifier la promotion',
  inputs: {
    id: {
      title: 'id',
      display: 'Id',
      type: 'hidden',
    },
    name: {
      title: 'name',
      display: 'Nom *',
      type: 'text',
    },
    start: {
      title: 'start',
      display: 'Date de début *',
      type: 'date',
    },
    end: {
      title: 'end',
      display: 'Date de fin *',
      type: 'date',
    },
  },
};
