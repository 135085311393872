/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilRedo,
  UilInfoCircle,
  UilPause,
  UilPlay,
  UilTrashAlt,
  UilSyncExclamation,
  UilSync,
  UilPlus,
} from '@iconscout/react-unicons';

/*
 * Local Import
 */
import { STATUS_VM } from 'src/constants/virtualDesktop';

/*
 * Style
 */
export const Container = styled.main`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--border-default);
  padding: 2rem;
`;

export const Title = styled.h1(({ theme }) => ({
  ...theme.typo.headlines.H2,
}));

export const Subtitle = styled.h2(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  display: 'flex',
  alignItems: 'center',
}));

export const VMStatus = styled.div`
  height: 100%;
  padding: 1rem;
`;

export const VMContainer = styled.div`
  max-width: 610px;
`;

export const NoVMO = styled.div(({ theme }) => ({
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '7rem',
  margin: '2rem 0',
  ...theme.typo.labels.bigLabel,
}));

export const Info = styled(UilInfoCircle)(({ theme }) => ({
  color: theme.colors.info._500,
  marginRight: '0.5rem',
}));

export const Informations = styled.div(({ theme }) => ({
  border: `2px solid ${theme.colors.info._500}`,
  borderRadius: '8px',
  boxShadow: theme.shadows.plus1,
  marginBottom: '2.5rem',
  padding: '1rem',
}));

export const Box = styled.div`
  margin: 1.5rem 0;
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Machine = styled.ul(({ theme }) => ({
  border: `2px solid ${theme.colors.coolGrey._300}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '0.75rem',
  padding: '1rem',

  // Margin on second li only
  'li:nth-of-type(2)': {
    margin: '1.5rem 0',
  },
}));

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-self: start;
`;

const iconStyle = () => ({
  marginRight: '0.5rem',
});

export const Redo = styled(UilRedo)(iconStyle);
export const Plus = styled(UilPlus)(iconStyle);
export const Play = styled(UilPlay)(iconStyle);
export const Pause = styled(UilPause)(iconStyle);
export const TrashAlt = styled(UilTrashAlt)(iconStyle);
export const Sync = styled(UilSync)(iconStyle);
export const SyncExclamation = styled(UilSyncExclamation)(iconStyle);

export const Label = styled.h3(({ theme }) => ({
  color: theme.colors.coolGrey._500,
  ...theme.typo.labels.smallLabel,
}));

export const Value = styled.span(({ theme }) => ({
  ...theme.typo.labels.label,
}));

export const Status = styled.span(({ status, theme }) => {
  let color = theme.colors.danger._500;

  if (
    [STATUS_VM.INSTALLING, STATUS_VM.PENDING, STATUS_VM.SHUTTING_DOWN, STATUS_VM.STOPPING].includes(
      status,
    )
  ) {
    color = theme.colors.warning._500;
  }
  else if (status === STATUS_VM.RUNNING) {
    color = theme.colors.success._500;
  }
  else if (status === STATUS_VM.NOT_FOUND) {
    color = theme.colors.coolGrey._500;
  }

  return {
    color,
    ...theme.typo.labels.label,
  };
});

export const Endpoints = styled.ul`
  display: flex;
  justify-content: space-between;

  // Ugly but it's working 🤷‍♂️
  li:nth-of-type(2) {
    margin-left: 1rem;
  }

  li:nth-of-type(3) {
    margin-left: 1rem;
  }
`;

export const Point = styled.li`
  width: 100%;
`;

export const Link = styled.a`
  width: 100%;
  height: 100%;
  padding: 1rem;
  letter-spacing: -0.18px;
  background: var(--background-1);
  border: 1px solid var(--border-default);
  color: var(--text-3);
  border-radius: 8px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;

  :focus {
    outline: var(--primary-border) auto 1px;
  }

  :disabled {
    background: none;
    border: 1px solid var(--border-disabled);
    color: var(--text-5);
    pointer-events: none;
  }

  :not(:disabled):hover {
    border: 1px solid var(--border-hover);
    color: var(--text-1);
  }
`;

export const ButtonWrapper = styled.li`
  margin: 0.75rem auto;
`;

export const Button = styled.button(
  ({ theme }) => ({
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    margin: '0.75rem auto',
    padding: '1rem',
    ...theme.typo.labels.label,
    width: '100%',
  }),
  ({ disabled, hasDanger, theme }) => {
    if (disabled) {
      return {
        border: `1px solid ${theme.colors.coolGrey._200}`,
        color: theme.colors.coolGrey._400,
        cursor: 'not-allowed',
      };
    }

    return {
      border: `1px solid ${theme.colors.coolGrey._300}`,
      color: hasDanger ? theme.colors.danger._600 : theme.colors.coolGrey._600,
      ':hover': {
        background: hasDanger ? theme.colors.danger._50 : theme.colors.coolGrey._100,
        border: `1px solid ${theme.colors.coolGrey._400}`,
        color: hasDanger ? theme.colors.danger._600 : theme.colors.coolGrey._900,
        cursor: 'pointer',
      },
      ':focus-visible': {
        border: `2px solid ${theme.primary._1}`,
      },
    };
  },
);
