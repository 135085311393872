/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Code
 */
const Unauthorized = (): JSX.Element => (
  <S.Container>
    <S.SmileDizzy size="10rem" />
    <S.PositionContainer>
      <S.MapMarkerQuestion size="4rem" />
      <S.Title>Tu ne devrais pas être ici !</S.Title>
      <S.Text>
        Si tu penses que tu devrais avoir accès à cette partie, n&apos;hésite pas à demander de
        l&apos;aide au support technique ;&#41;
      </S.Text>
    </S.PositionContainer>
  </S.Container>
);

/*
 * Export
 */
export default Unauthorized;
