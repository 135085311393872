/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Avatar = styled.img({
  position: 'relative',
  borderRadius: '8px',
  minWidth: '3rem',
  width: '3rem',
  height: '3rem',
});

export const Image = styled.div((props) => ({
  position: 'relative',
  borderRadius: '8px',
  overflow: 'hidden',
  minWidth: '3rem',
  width: '3rem',
  height: '3rem',
  ':before': props.connected && {
    content: '""',
    height: '0.75rem',
    width: '0.75rem',
    backgroundColor: `${!props.away ? props.theme.success.main : props.theme.danger.main}`,
    borderRadius: '50px',
    right: '0.1rem',
    border: `1px solid ${props.theme.background._2}`,
    position: 'absolute',
    bottom: '0.1rem',
    zIndex: '10',
  },
}));
