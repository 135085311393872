/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import devicesMediasRTC from 'src/utils/medias';
import { MEDIA_ERRORS } from 'src/constants/mediaDevices';

// Style
import * as S from '../index.style';

/*
 * Component
 */
const MediasDenied = ({ setApplicationState, localStreamError }) => {
  let devices = ['webcam', 'micro'];
  let icon = <S.Warning aria-hidden="true" />;

  /**
   * Typos
   */
  if (!devicesMediasRTC.hasWebcamPermissions && !devicesMediasRTC.hasMicrophonePermissions) {
    devices = ['webcam', 'micro'];
    icon = <S.Warning aria-hidden="true" />;
  }
  else if (!devicesMediasRTC.hasMicrophonePermissions) {
    devices = ['micro'];
    icon = <S.Microphone aria-hidden="true" />;
  }
  else if (!devicesMediasRTC.hasWebcamPermissions) {
    devices = ['webcam'];
    icon = <S.Video aria-hidden="true" />;
  }

  /**
   * Join classroom
   * @return {void}
   */
  const joinClassroom = () => {
    setApplicationState('JOINED');
  };

  /**
   * Display the message relative to the error returned by `localStreamError`
   * @return {HTMLElement}
   */
  const getPartial = () => {
    switch (localStreamError.name) {
      /**
       * No devices found
       */
      case MEDIA_ERRORS.NO_DEVICES:
        return (
          <>
            <S.Title>Aucune webcam ni micro détectés</S.Title>
            <S.Description>
              Vous avez besoin d’au moins un périphérique multimédia connecté lorsque vous rejoignez
              une salle de classe, sinon il est possible que certaines fonctionnalitées ne
              fonctionnent pas correctement.
            </S.Description>
          </>
        );

      /**
       * Authorization denied
       */
      case MEDIA_ERRORS.NOT_ALLOWED:
        return (
          <>
            <S.Title>
              Votre {devices.length === 1 ? devices[0] : `${devices[0]} et votre ${devices[1]}`}{' '}
              {devices.length === 1 ? 'est' : 'sont'} {devices.length === 1 ? 'bloqué' : 'bloqués'}
            </S.Title>
            <S.Description>
              Vérifiez les accès de votre navigateur avant de rejoindre la salle de classe
            </S.Description>
          </>
        );

      /**
       * Devices disabled on the system OR used by another application
       */
      case MEDIA_ERRORS.ABORT:
      case MEDIA_ERRORS.NOT_FOUND:
      case MEDIA_ERRORS.NOT_READABLE:
        return (
          <>
            <S.Title>
              Votre {devices.length === 1 ? devices[0] : `${devices[0]} et votre ${devices[1]}`}{' '}
              {devices.length === 1 ? 'est' : 'sont'} {devices.length === 1 ? 'bloqué' : 'bloqués'}
            </S.Title>
            <S.Description>
              Votre navigateur ne parvient pas à accéder à votre webcam et à votre micro.{' '}
              <strong>Une autre application les utilise-t-elle ?</strong>
            </S.Description>
          </>
        );

      /**
       * Other errors we didn't catch
       */
      default:
        return <S.Description>Une erreur inattendue est survenue</S.Description>;
    }
  };

  /**
   * Render
   */
  return (
    <S.Container>
      <S.Alert>
        <S.IconContainer hasError>{icon}</S.IconContainer>

        {/* Partials */}
        {getPartial()}

        {/* Doc Stonly */}
        {/* <S.Help href="#">Trouver de l’aide</S.Help> */}

        {/* CTAs */}
        <S.Button type="button" data-testid="medias-denied-button" onClick={joinClassroom} isDanger>
          Continuer sans {devices.length === 1 ? devices[0] : 'appareils'}
        </S.Button>
      </S.Alert>
    </S.Container>
  );
};

/*
 * PropTypes
 */
MediasDenied.propTypes = {
  setApplicationState: PropTypes.func.isRequired,
  localStreamError: PropTypes.object,
};

MediasDenied.defaultProps = {
  localStreamError: {},
};

/*
 * Export
 */
export default MediasDenied;
