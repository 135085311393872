/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Package Component Import
 */
import { MenuItem, SubMenu } from '@szhsin/react-menu';

/*
 * Local Import
 */

// Style
import * as P from '../../style';

/*
 * Component
 */
const MenuHelp = ({ actions, disabled, index }) => {
  /*
   * Handlers
   */
  const handleOpenShortcuts = () => {
    actions.setModalShortcuts({ isOpen: true });
  };

  /*
   * Render
   */
  return (
    <SubMenu
      label={(
        <P.ContainerLabel>
          <P.IconQuestionCircle aria-hidden />
          &nbsp; Aides
        </P.ContainerLabel>
      )}
      index={index}
      disabled={disabled}
    >
      <MenuItem onClick={handleOpenShortcuts}>
        <P.ContainerLabel>
          <P.IconKeyboard aria-hidden />
          &nbsp; Raccourcis clavier
        </P.ContainerLabel>
      </MenuItem>
    </SubMenu>
  );
};

/*
 * PropTypes
 */
MenuHelp.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

MenuHelp.defaultProps = {
  disabled: false,
};

/*
 * Export
 */
export default MenuHelp;
