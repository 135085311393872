export const usersImportExampleData = [
  ['Albus', 'Dumbledore', 'example@example.com', "Godric's Hollow", 'Angleterre'],
  ['Olivier', 'Dubois', 'example@example.com'],
  ['Harry', 'Potter', 'example@example.com', 'ChosenOne'],
];

export const fileFieldsLabels = [
  '#Prénom (requis)',
  'Nom (requis)',
  'Email (requis)',
  'Ville',
  'Pays',
  'Profil Github',
  'Profil Discord',
];
