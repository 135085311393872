/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  marginLeft: '.5rem',

  ':last-child': {
    marginBottom: '0px',
  },
});

export const Name = styled.span(({ theme }) => ({
  ...theme.typo.labels.smallLabel,
  color: theme.text._1,
  maxWidth: '15rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
