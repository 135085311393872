/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { useReactivateUser } from 'src/serverStateManagers/mutations/reactivate/Users/useReactivateUser';
import { MongoId } from 'src/schemas/Entities/utils';
import { getRoute } from 'src/utils/routes';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';

/*
 * Code
 */
type ReactivateProps = {
  isOpen: boolean;
  idToReactivate: MongoId | null;
};

const Reactivate = ({ isOpen, idToReactivate }: ReactivateProps): JSX.Element => {
  const reactivateUserMutation = useReactivateUser();
  const history = useHistory();

  const { data: usersData } = useGetUsers();
  const userData = usersData?.find((user) => user.id === idToReactivate);

  useEffect(() => {
    if (userData && !userData.deactivatedAt) {
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [userData, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('usersTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (userData && !userData.deactivatedAt) {
      toast('Utilisateur déjà actif', { type: 'warning' });
      history.push(getRoute('usersTable').getUrl({}));
      return;
    }
    if (idToReactivate) {
      reactivateUserMutation.mutate(idToReactivate);
    }
    history.push(getRoute('usersTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>
          Êtes-vous sûr de vouloir désarchiver cet utilisateur ?
        </F.DeactivateHeader>
        <F.DeactivateLabel>
          Cette action lui permettra de se reconnecter à Slippers
        </F.DeactivateLabel>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit} fullWidth>
            Désarchiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Reactivate;
