/**
 * Package Import
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

/**
 * Local Import
 */
import Avatar from 'src/components/Users/UsersPanel/List/UserCard/Avatar';

// Style
import * as S from './style';

/**
 * Component
 */
const Mugshot = ({ size, statusSize, user }) => {
  const theme = useTheme();

  const adjustedSize = useMemo(() => size * (theme.textSize ?? 1), [size, theme.textSize]);

  return (
    <S.Mugshot size={adjustedSize}>
      <Avatar
        size={adjustedSize}
        statusSize={statusSize}
        avatar={user?.avatar}
        name={user?.name}
        alt=""
        // a11y
        aria-hidden="true"
      />
    </S.Mugshot>
  );
};

/**
 * PropTypes
 */
Mugshot.propTypes = {
  /** Size {height, width} of Avatar, default to 48 */
  size: PropTypes.number,
  /** Size {height, width} of Status */
  statusSize: PropTypes.number,
  /** User to display avatar, status, etc ... */
  user: PropTypes.object.isRequired,
};

Mugshot.defaultProps = {
  size: 48,
  statusSize: null,
};

/**
 * Export
 */
export default Mugshot;
