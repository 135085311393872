/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';
import { UilExclamationCircle } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

/*
 * Styles
 */
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.danger.main,
  alignItems: 'center',
}));

const Alert = styled('div')(() => ({
  display: 'flex',
}));

const AlertIcon = styled(UilExclamationCircle)(() => ({
  width: '1.25rem',
  height: '1.25rem',
  marginRight: '.375rem',
}));

const getTinyLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;
const AlertTag = styled.span(({ theme }) => ({
  backgroundColor: theme.danger.background,
  borderRadius: '25px',
  padding: '0.2rem 0.4rem',
  color: theme.danger.main,
  whiteSpace: 'nowrap',
  ...getTinyLabelStyle(theme),
}));

type DeactivatedRelationProps = {
  content: string;
  withTag?: boolean;
  tooltipContent: string;
};

export const DeactivatedRelation = ({
  content,
  withTag,
  tooltipContent,
}: DeactivatedRelationProps): JSX.Element => (
  <Container>
    <Tooltip content={tooltipContent}>
      <Alert>
        <AlertIcon />
      </Alert>
    </Tooltip>
    {withTag ? <AlertTag>{content}</AlertTag> : content}
  </Container>
);
