/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/**
 * Height to subtract for private chat
 *
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * |     CHATS     -     SONDAGES       | : 48px
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|        \
 * | Channel général - Messages privés  | : 50px  -—- 146px / 16px = 9.125rem
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|        /
 * | < XXX YYY ZZZ (vous)           (X) | : 48px
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | (Message)                          |
 * | (Message)                          |
 * |                          (Message) |
 * |                          (Message) |
 * |                          (Message) |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | Écrire un message        [Envoyer] |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | [🙂] [📊] [B] [I]                   |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 *
 */
const PRIVATE_HEIGHT_TO_SUBTRACT = '9.125rem';

/**
 * Height to subtract for public chat
 *
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * |     CHATS     -     SONDAGES       | : 48px
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|        \
 * | Channel général - Messages privés  | : 50px  -—- 146px / 16px = 9.125rem
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|        /
 * | Aucun filtre              [❓] [📌] | : 48px
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | (Message)                          |
 * | (Message)                          |
 * |                          (Message) |
 * |                          (Message) |
 * |                          (Message) |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | Écrire un message        [Envoyer] |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 * | [🐛] [B] [I]                       |
 * |—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-—-|
 *
 */
const PUBLIC_HEIGHT_TO_SUBTRACT = '9.125rem';

/*
 * Style
 */
export const Chat = styled.div(({ isPrivate }) => ({
  display: 'flex ',
  flexDirection: 'column',
  height: `calc(100vh - ${isPrivate ? PRIVATE_HEIGHT_TO_SUBTRACT : PUBLIC_HEIGHT_TO_SUBTRACT})`,
}));

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
