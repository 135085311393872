/**
 * Local Import
 */
import { commandPin } from './pin';
import { commandPoll } from './poll';
import { commandQuestion } from './question';
import { commandSpoiler } from './spoiler';
import { commandSmile } from './smile';

/**
 * Commands
 */
export const commands = {
  /**
   * Asks survey in chat
   */
  poll: commandPoll,
  pollm: (props) => commandPoll({ ...props, isMultiple: true }),

  /**
   * Sends ':)' in the chat
   */
  smile: commandSmile,

  /**
   * Pin the message
   */
  pin: commandPin,

  /**
   * Flag the message as a question
   */
  question: commandQuestion,

  /**
   * Flag the message as a spoiler
   */
  spoiler: commandSpoiler,
};

/**
 * List commands keys
 */
export const commandsKeys = Object.keys(commands);

/**
 * This message is command ? `check if the text starts with a command,
 * returns it without slash or undefined
 * @param {String} text
 * @return {string|undefined} the command name if found
 */
export const isCommand = (text) => {
  if (typeof text !== 'string') return undefined;

  // On regarde si le message match avec le pattern `/<command>`
  // Si la regex match, alors `match[1]` devrait correspondre au nom de la commande
  const commandRegex = /\/([\w]+)/;
  const match = text.match(commandRegex);

  return (
    text.startsWith('/')
    && match
    && commandsKeys.find((command) => match[1] === command)?.replace('/', '')
  );
};
