/*
 * Package import
 */
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

/*
 * Local import
 */
import { sort } from 'src/utils';
import ROLES from 'src/constants/roles';

/**
 * Types import
 */
import type { RootState } from 'src/store';
import type { UserProps } from 'src/store/reducers/users';
import type { CourseRoleEnum } from 'src/constants/roles';

/**
 * Filter users by argument
 */
const filterUsersBy = (filter: CourseRoleEnum) =>
  (usersIds: string[], userById: Record<string, UserProps>): string[] =>
    sort(usersIds.filter((id) => userById[id] && userById[id].role === filter).filter(Boolean));

/**
 * Get connected client
 */
export const getClient = (state: RootState) => state.users.client;

/**
 * Get the teacher ids
 */
export const getTeacherIds = createSelector(
  (state: RootState) => state.users.usersIds,
  (state: RootState) => state.users.userById,
  filterUsersBy(ROLES.ROLE_TEACHER),
);

/**
 * Get the helper ids
 */
export const getHelperIds = createSelector(
  (state: RootState) => state.users.usersIds,
  (state: RootState) => state.users.userById,
  filterUsersBy(ROLES.ROLE_HELPER),
);

/**
 * Get the students ids
 */
export const getStudentsIds = createSelector(
  (state: RootState) => state.users.usersIds,
  (state: RootState) => state.users.userById,
  filterUsersBy(ROLES.ROLE_STUDENT),
);

/**
 * Get all users' ids {students, helpers, teachers} from the users' array
 */
export const getAllUsersIds = createSelector(
  getStudentsIds,
  getHelperIds,
  getTeacherIds,
  (studentsIds, helpersIds, teachersIds) => [...studentsIds, ...helpersIds, ...teachersIds],
);

/**
 * Get all users' ids {students, helpers, teachers} who are online
 */
export const getAllUsersIdsOnline = createSelector(
  getAllUsersIds,
  (state: RootState) => state.users.userById,
  (usersIds, userById) => usersIds.filter((id) => userById[id].connected),
);

/**
 * Get all connected users names
 */
export const getUsersNames = createSelector(
  getAllUsersIds,
  (state: RootState) => state.users.userById,
  (usersIds, userById) =>
    usersIds.map((userId) => ({
      id: userId,
      name: userById[userId].name,
    })),
);

/**
 * Get student ids that are currently connected
 */
export const getStudentsIdsOnline = createSelector(
  getStudentsIds,
  (state: RootState) => state.users.userById,
  (studentIds, userById) => studentIds.filter((id) => userById[id].connected),
);

/**
 * Get the users we can @mentions
 */
export const getMentions = createSelector(
  getAllUsersIds,
  (state: RootState) => state.users.userById,
  (usersIds, userById) =>
    usersIds.map((id) => ({
      id,
      avatar: userById[id].avatar,
      value: userById[id].name,
    })),
);

export const getUsersWithoutGhosts = createSelector(
  (state: RootState) => state.users.usersIds,
  (state: RootState) => state.users.userById,
  (usersIds, userById) =>
    usersIds.filter((id) => userById[id].role !== ROLES.ROLE_GHOST).map((id) => userById[id]),
);

/**
 * Get the current teacher
 */
export const getTeacher = createSelector(
  (state: RootState) => state.users.usersIds,
  (state: RootState) => state.users.userById,
  (usersIds, userById) => usersIds.find((id) => userById[id].role === ROLES.ROLE_TEACHER),
);

/**
 * Get users name for reaction tooltip
 * Replace ids by their name, and specifically your self-name by “you”
 */
export const getUsersNamesForReaction = createCachedSelector(
  (state: RootState) => state.users.client,
  (state: RootState) => state.users.userById,
  (state: RootState, ownProps: { messageId: string; name: string; users: string[] }) => ownProps,
  (client, userById, ownProps) => ({
    namedUsers: ownProps.users
      .map((userId, index) => {
        if (userId === client.id) {
          return index ? 'vous' : 'Vous';
        }

        return userById[userId]?.name;
      })
      .filter(Boolean),
  }),
)(
  // Use "reaction-{messageId}-{name}" as cacheKey
  (state, ownProps) => `reaction-${ownProps.messageId}-${ownProps.name}`,
);

/**
 * Get user props from `userId`
 */
export const getUserProps = createCachedSelector(
  (state: RootState) => state.users.userById,
  (state: RootState, userId: string) => userId,
  (userById, userId) => {
    let userProps: Partial<UserProps> = {};
    const user = userById[userId];

    if (user) {
      userProps = user;
    }

    return userProps;
  },
)(
  // Use "userId" as cacheKey
  (state, userId) => userId,
);
