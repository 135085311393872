/*
 * Package Import
 */
import styled from '@emotion/styled';
import { FilePond } from 'react-filepond';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import 'filepond/dist/filepond.min.css';

/*
 * Styles
 */
const getLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.label;
export const CustomFilePond = styled(FilePond)(({ theme }) => ({
  '.filepond--drop-label': {
    color: theme.text._4,
    ...getLabelTypoStyle(theme),
  },

  '.filepond--panel-root': {
    background: theme.background._8,
    border: `1px solid ${theme.text._7}`,
    borderRadius: '8px',
  },
}));
