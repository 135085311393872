/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Badge from 'src/components/Users/Profile/Badge';
import Emotions from 'src/components/Users/Profile/Emotions/container';
import { adminRolesLabels } from 'src/components/Users/data/roles';
import ROLES from 'src/constants/roles';

// Style
import * as S from './style';

/*
 * Component
 */
const Labels = ({ name, role }) => (
  <S.Container>
    <S.Line>
      <S.Name>{name}</S.Name>
      {role !== ROLES.ROLE_STUDENT && <Badge role={role} />}
      <S.Vous>(vous)</S.Vous>
    </S.Line>
    <S.Line>
      {role === ROLES.ROLE_STUDENT && <Emotions />}
      {role !== ROLES.ROLE_STUDENT && (
        <S.Role roleName={role}>{adminRolesLabels[role].label}</S.Role>
      )}
    </S.Line>
  </S.Container>
);

/*
 * PropTypes
 */
Labels.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Labels;
