import { intervalToDuration, isAfter, isBefore, minutesToHours } from 'date-fns';

import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { ongoing, finished, coming } from 'src/constants/status';
import { SlotWithDates } from 'src/schemas/Adapters/Courses/utils';
import { Course } from 'src/schemas/Entities/Course';

export const getSortedTimeslots = (timeslots: SlotWithDates[]): SlotWithDates[] =>
  timeslots.sort((rowA, rowB) => rowA.start.getTime() - rowB.start.getTime());

/**
 * Get course status by checking current datetime,
 * comparing it against first timeslot start and last timeslot end
 * @param course Course
 * @returns Option
 */
export const getStatus = (course: Course): Option => {
  const now = new Date();
  const sortedTimeslots = getSortedTimeslots(course.timeslots);
  const courseStart = sortedTimeslots[0].start;
  const courseEnd = sortedTimeslots[sortedTimeslots.length - 1].end;

  if (isBefore(now, courseStart)) {
    return coming;
  }

  if (isAfter(now, courseEnd)) {
    return finished;
  }

  return ongoing;
};

/**
 *
 */
export const getDuration = (timeslots: SlotWithDates[]) => {
  let hours = 0;
  let minutes = 0;

  timeslots.forEach(({ start, end }) => {
    const duration = intervalToDuration({ start, end });
    hours += duration.hours ?? 0;
    minutes += duration.minutes ?? 0;
  });

  const addedHours = minutesToHours(minutes);
  hours += addedHours;
  minutes -= 60 * addedHours;

  return { hours, minutes };
};

/**
 *
 */
export const getAllDurationFromTimeslots = (timeslots: SlotWithDates[]): string => {
  const { hours, minutes } = getDuration(timeslots);
  return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
};
