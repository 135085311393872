/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { API_REACTIVATE_USER, API_PATCH } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const reactivateUser = async (userId: string): Promise<void> => {
  try {
    const payload: Payload = {
      method: API_PATCH,
      url: API_REACTIVATE_USER,
      data: {
        _id: userId,
      },
    };

    await api(payload);
  }
  catch (error) {
    toast(`Erreur pendant le désarchivage : ${error}`, { type: 'error' });
    throw error;
  }
};
