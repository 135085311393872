/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Code
 */
export const Webcams = styled.ul(({ theme, settingsWebcamsDisabled }) => ({
  display: settingsWebcamsDisabled ? 'none' : '-webkit-box',
  justifyContent: 'center',
  overflowX: 'scroll',

  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',

  '::-webkit-scrollbar': {
    width: '0.25rem',
    height: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));

export const Button = styled.button``;
