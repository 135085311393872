/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MainMenu from 'src/components/TopBar/Menu';
import {
  setModalFeedback,
  setMenuNotifications,
  setModalNotificationReader,
  setModalAway,
} from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setModalFeedback,
      setMenuNotifications,
      setModalNotificationReader,
      setModalAway,
    },
    dispatch,
  ),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
