/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Pin from 'src/components/Chats/Channel/Chat/Message/Actions/Pin/container';
import More from 'src/components/Chats/Channel/Chat/Message/Actions/More/container';
import Reactions from 'src/components/Chats/Channel/Chat/Message/Actions/Reactions';

// Helpers
import MESSAGES from 'src/constants/messages';

// Style
import * as S from './style';

/*
 * Code
 */
const { STATUS_RECEIVED, STATUS_SENDING, STATUS_FAILED, STATUS_ERROR } = MESSAGES;

/*
 * Component
 */
const Actions = ({ status, ...props }) => {
  // Dont display the actions component if the status is not suitable
  // @TODO : Add status on initialize
  if ([STATUS_FAILED, STATUS_ERROR, STATUS_SENDING].includes(status)) {
    return null;
  }

  /*
   * Render
   */
  return (
    <S.Buttons role="group" aria-label="Raccourcis messages">
      <S.Wrapper>
        <Pin {...props} />
        <Reactions {...props} />
        <More {...props} />
      </S.Wrapper>
    </S.Buttons>
  );
};

/*
 * PropTypes
 */
Actions.propTypes = {
  /** Status of the message */
  // eslint-disable-next-line react/require-default-props
  status: PropTypes.oneOf([STATUS_RECEIVED, STATUS_SENDING, STATUS_FAILED, STATUS_ERROR]),
};

Actions.defaultProps = {
  // @TODO : Add status on initialize
  // status: STATUS_SENDING,
};

/*
 * Export
 */
export default React.memo(Actions);
