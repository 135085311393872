/*
 * Package Import
 */
import * as z from 'zod';

/*
 * Code
 */
export const roleEnum = {
  basic: 'basic',
  supervisor: 'supervisor',
  admin: 'admin',
  superAdmin: 'superAdmin',
} as const;
export const roleEnumSchema = z.nativeEnum(roleEnum);
const role = z.object({
  name: roleEnumSchema,
  display: z.string(),
  isPeda: z.boolean(),
  score: z.number(),
});

/**
 * Types
 */
export type RoleEnum = z.infer<typeof roleEnumSchema>;
export type Role = z.infer<typeof role>;

/**
 * Roles
 */
export const roles: Role[] = [
  {
    name: 'basic',
    display: 'Basique',
    isPeda: false,
    score: 0,
  },
  {
    name: 'supervisor',
    display: 'Superviseur',
    isPeda: true,
    score: 10,
  },
  {
    name: 'admin',
    display: 'Administrateur',
    isPeda: true,
    score: 100,
  },
  {
    name: 'superAdmin',
    display: 'Super Administrateur',
    isPeda: true,
    score: 1000,
  },
];

/**
 * Constants · Rôles • Course
 */
export const COURSE_ROLE = {
  ROLE_GHOST: 'ghost',
  ROLE_TEACHER: 'teacher',
  ROLE_HELPER: 'helper',
  ROLE_STUDENT: 'student',
} as const;

// Types
export const COURSE_ROLE_SCHEMA = z.nativeEnum(COURSE_ROLE);
export type CourseRoleEnum = z.infer<typeof COURSE_ROLE_SCHEMA>;

/**
 * Constants · Rôles
 */
export default {
  ...COURSE_ROLE,

  // Application roles
  ROLE_SUPER_ADMIN: 'superAdmin',
  ROLE_ADMIN: 'admin',
  ROLE_SUPERVISOR: 'supervisor',
  ROLE_BASIC: 'basic',
};
