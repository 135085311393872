/*
 * Package Import
 */
import * as z from 'zod';

/**
 * Connection retry delay after websocket failed in milliseconds
 */
export const CONNECTION_RETRY_DELAY = 5000;

/**
 * Event returned when using socket.disconnect()
 * https://socket.io/docs/v3/client-socket-instance/#disconnect
 */
export const MANUAL_DISCONNECT_EVENT = 'io client disconnect';

/**
 * Constants · WebSocket • Statuses
 */
export const WEBSOCKET_STATUS = {
  INITIAL_STATUS: 'INITIAL_STATUS',
  CONNECTION_SUCCESS: 'CONNECTION_SUCCESS',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  CONNECTION_PENDING: 'CONNECTION_PENDING',
};

// Types
export const WEBSOCKET_STATUS_SCHEMA = z.nativeEnum(WEBSOCKET_STATUS);
export type WebsocketStatusEnum = z.infer<typeof WEBSOCKET_STATUS_SCHEMA>;
