/*
 * Package Import
 */
import { combineReducers } from 'redux';

/*
 * Local Import
 */
import breaktime from './breaktime';
import chats from './chats';
import course from './course';
import feedback from './feedback';
import inputs from './inputs';
import modal from './modal';
import notifications from './notifications';
import organization from './organization';
import kcToken from './kcToken';
import settings from './settings';
import shortcuts from './shortcuts';
import socket from './socket';
import tracking from './tracking';
import users from './users';
import transcription from './transcription';
import webRTC from './webRTC';
import virtualDesktop from './virtualDesktop';
import signature from './signature';

/**
 * Reducers
 */
const reducers = combineReducers({
  breaktime,
  chats,
  course,
  feedback,
  inputs,
  modal,
  notifications,
  organization,
  kcToken,
  settings,
  shortcuts,
  socket,
  tracking,
  users,
  transcription,
  webRTC,
  virtualDesktop,
  signature,
});

/*
 * Export
 */
export default reducers;
