/**
 * Package Import
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import Audio from 'src/components/Course/Conference/Item/container';

// Constants
import { WEBSOCKET_STATUS } from 'src/constants/websocket';
import { CONFERENCE_STATUS } from 'src/constants/conference';

/**
 * Component
 */
const Conference = ({
  actions,
  audioPeers,
  conferenceStatus,
  websocketStatus,
  webRTCConnected,
}) => {
  /**
   * LifeCycles
   */
  useEffect(() => {
    if (
      websocketStatus === WEBSOCKET_STATUS.CONNECTION_SUCCESS
      && webRTCConnected
      && conferenceStatus === CONFERENCE_STATUS.RUNNING
    ) {
      actions.getConferencePeers();
    }
  }, [conferenceStatus, websocketStatus, webRTCConnected]);

  /**
   * Render
   */
  return conferenceStatus === CONFERENCE_STATUS.RUNNING && audioPeers.length
    ? audioPeers.map((peerId) => <Audio key={`key-${peerId}`} peerId={peerId} />)
    : null;
};

/**
 * Types
 */
Conference.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  audioPeers: PropTypes.array.isRequired,
  conferenceStatus: PropTypes.string,
  websocketStatus: PropTypes.string,
  webRTCConnected: PropTypes.bool.isRequired,
};

Conference.defaultProps = {
  conferenceStatus: CONFERENCE_STATUS.STOPPED,
  websocketStatus: WEBSOCKET_STATUS.INITIAL_STATUS,
};

/**
 * Export
 */
export default Conference;
