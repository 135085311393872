/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const LoaderContainer = styled.div(() => ({
  position: 'absolute',
  top: 'calc(50% - 48px)',
  left: 'calc(50% - 48px)',
}));
