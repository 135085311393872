/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { useReactivatePromotion } from 'src/serverStateManagers/mutations/reactivate/Promotions/useReactivatePromotion';
import { MongoId } from 'src/schemas/Entities/utils';
import { getRoute } from 'src/utils/routes';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';

/*
 * Code
 */
type ReactivateProps = {
  isOpen: boolean;
  idToReactivate: MongoId | null;
};

const Reactivate = ({ isOpen, idToReactivate }: ReactivateProps): JSX.Element => {
  const reactivatePromotionMutation = useReactivatePromotion();
  const history = useHistory();

  const { data: promotionsData } = useGetPromotions();
  const promotionData = promotionsData?.find((promotion) => promotion.id === idToReactivate);

  useEffect(() => {
    if (promotionData && !promotionData.deactivatedAt) {
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [promotionData, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (promotionData && !promotionData.deactivatedAt) {
      toast('Promotion déjà active', { type: 'warning' });
      history.push(getRoute('promotionsTable').getUrl({}));
      return;
    }
    if (idToReactivate) {
      reactivatePromotionMutation.mutate(idToReactivate);
    }
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>Désarchiver</F.DeactivateHeader>
        <F.DeactivateLabel>
          Êtes-vous sûr de vouloir désarchiver cette promotion ?
        </F.DeactivateLabel>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit} fullWidth>
            Désarchiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Reactivate;
