/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Topbar from 'src/components/TopBar';
import { setMenuNotifications, setMenuSignature } from 'src/store/actions';
import { isEducator } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { userById, client } = state.users;
  return {
    // Notifications
    allNotifications: state.notifications.allNotifications,
    menuNotificationsIsOpen: state.notifications.isOpen,

    // Settings
    displayChat: state.settings.layout.displayChat,
    displayUsers: state.settings.layout.displayUsers,

    // Signature
    menuSignatureIsOpen: state.signature.isOpen,
    canSign: state.signature.canSign || isEducator(state.users.client.role),
    mustSign: userById[client.id]?.mustSign,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setMenuNotifications, setMenuSignature }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
