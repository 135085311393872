/*
 * Package Import
 */
import styled from '@emotion/styled';

import { UilPlusCircle } from '@iconscout/react-unicons';
/*
 * Local Import
 */
import Modal from 'src/components/Modal';

/*
 * Style
 */
export const Reactions = styled.div`
  margin: 0.5rem 0 0.25rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const IconAdd = styled(UilPlusCircle)(({ theme }) => ({
  height: '1.5rem',
  width: '1.5rem',
  color: theme.text._5,
  ':hover': {
    color: theme.text._4,
  },
}));

export const ButtonAddReaction = styled.button({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: '.25rem',
  marginRight: '.25rem',
  verticalAlign: 'top',
});

export const Button = styled.button(({ reacted, theme }) => ({
  alignItems: 'center',
  justifiyContent: 'center',
  background: reacted ? theme.primary._5 : theme.text._9,
  border: '1px solid',
  borderColor: reacted ? theme.primary._2 : theme.text._9,
  borderRadius: '50px',
  display: 'inline-flex',
  marginBottom: '.25rem',
  marginRight: '.25rem',
  padding: '.125rem .5rem',
  verticalAlign: 'top',

  ':hover': {
    background: reacted ? theme.primary._5 : theme.text._8,
    borderColor: reacted ? theme.primary._2 : theme.text._8,
    [IconAdd]: {
      color: theme.text._4,
    },
  },
  ...theme.typo.bodyText.tinyText,
  fontSize: `${theme.textSize}rem`,
}));

export const Emoji = styled.span(({ theme }) => ({
  '>div': {
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '.25rem',
  },

  /* Target: emoticon */
  '.emoji-mart-emoji': {
    height: `${theme.textSize * 20}px !important`,
    width: `${theme.textSize * 20}px !important`,
  },
}));

export const Count = styled.span(({ reacted, theme }) => ({
  color: reacted ? theme.primary._1 : theme.text._4,
  padding: '0 1px 0 3px',
  ...theme.typo.labels.tinyLabelAdjustable,
  fontWeight: 700,
}));

export const ReactionsModal = styled(Modal)({
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',

  '.ReactModal__Overlay ReactModal__Overlay--after-open': {
    backgroundColor: 'none !important',
  },
});
