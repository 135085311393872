import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Context
import { UserContext } from 'src/context/User';

// Utils
import { isAdmin, isSuperAdmin, isSupervisor } from 'src/utils/roles';
import { identify, setSuperProp } from 'src/utils/tracking';
import { initHubSpotConversations } from 'src/utils/hubspot';

/**
 * Custom hook that initializes Mixpanel & Hubspot widget based on user
 */
const useTracking = () => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (user && user.id && user.role && user.createdAt) {
      /**
       * Mixpanel tracking
       */
      identify(user.id);
      setSuperProp({
        registrationDate: user.createdAt,
        userRole: user.role,
      });

      /**
       * Hubspot
       */
      const isGranted = isAdmin(user.role) || isSuperAdmin(user.role) || isSupervisor(user.role);
      if (isGranted && !pathname.includes('chat')) {
        setTimeout(() => {
          initHubSpotConversations();
        }, 2000);
      }
    }
  }, [pathname, user]);
};

export default useTracking;
