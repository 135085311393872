/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { Formatizer } from './Formatizer';
import Mention from '../Chats/Channel/Chat/Message/Content/Mention/container';
import Spoil from '../Chats/Channel/Chat/Message/Content/Spoil/container';
import Code from './Formatizer/components/Piece/Code';
import Emoji from './Formatizer/components/Piece/Emoji';
import Link from './Formatizer/components/Piece/Link';

// Helpers
import { checkBlock } from './utils';

/*
 * Component
 */
const Format = ({ content, element, index }) => {
  // Check if is message
  if (
    (element.attributes?.spoiler && content.ops[index - 1]?.attributes?.spoiler)
    || !element.insert
  ) {
    return null;
  }

  // Check if next entry is code-block to let checkBlock worked
  if (
    content.ops[index + 1]?.attributes
    && (content.ops[index + 1]?.attributes['code-block'] || content.ops[index + 1]?.attributes.spoiler)
  ) {
    return null;
  }

  // Ignore les éléments qui contiennent une image
  if (element.insert.image) {
    return null;
  }

  if (element.insert.emoji) {
    const fullEmojis = content.ops.every(
      (op) =>
        op.insert.emoji
        || (op.insert instanceof String && (op.insert === '\n' || op.insert.trim().length === 0)),
    );
    return <Emoji size={fullEmojis ? 48 : 20}>{element.insert.emoji}</Emoji>;
  }

  /**
   * Mention
   */
  if (element.insert.mention) {
    return <Mention data={element.insert.mention} />;
  }

  if (element.attributes) {
    /**
     * Emoji
     */
    if (element.attributes.emoji) {
      return <Formatizer>{element.insert}</Formatizer>;
    }

    /**
     * Code-block
     */
    if (element.attributes['code-block']) {
      if (content.ops[index - 2]?.attributes && content.ops[index - 2]?.attributes['code-block']) {
        return null;
      }

      return <Code>{checkBlock(content.ops, index, 'code-block')}</Code>;
    }

    /**
     * Bold
     */
    if (element.attributes.bold) {
      return <strong>{element.insert}</strong>;
    }

    /**
     * Italic
     */
    if (element.attributes.italic) {
      return <i>{element.insert}</i>;
    }

    /**
     * Spoiler
     */
    if (element.attributes.spoiler) {
      if (content.ops[index - 2]?.attributes.spoiler) return null;

      return <Spoil>{checkBlock(content.ops, index, 'spoiler')}</Spoil>;
    }

    /**
     * Link
     */
    if (element.attributes.link) {
      // QuillJS enlève le `www` dans les liens, si on utilise `attributes.link`. Par exemple:
      // https://www.google.fr va devenir https://google.fr
      // On utilise `insert` à la place, qui contient le lien tel qu'il est saisi par l'user.
      // Il faut juste rajouter `https://` si il est manquant, sinon le lien ne sera pas valide.
      const isMissingHttp = !element.insert.startsWith('http');

      return (
        <Link href={`${isMissingHttp ? 'https://' : ''}${element.insert}`}>{element.insert}</Link>
      );
    }
  }

  /**
   * Default
   */
  if (element.insert === '\n' && content.ops.length === index + 1) {
    return null;
  }

  return <span>{element.insert}</span>;
};

/*
 * PropTypes
 */
Format.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  element: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default Format;
