/*
 * Package Import
 */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Row, Updater } from '@tanstack/react-table';
/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';

// Schemas
import { User } from 'src/schemas/Entities/User';
import { ZodValidationUser } from 'src/schemas/Adapters/Users/utils';
import { AllUsersFilter } from '../Modals/PromotionUsers/Promotion/utils';

/*
 * Styles
 */
export const CheckboxInput = styled.input(() => ({
  appearance: 'checkbox',
  width: '1.25rem',
  height: '1.25rem',
  cursor: 'pointer',
}));

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    margin-right: 0.5rem;
    cursor: pointer;
  }
`;

const Label = styled.label(({ theme }) => ({
  ...theme.typo.labels.label,
}));

/*
 * Component
 */
type CheckboxProps = {
  row: Row<User>;
  rowIsDisabled?: (rowOriginal?: User) => boolean;
};
export const Checkbox = ({ row, rowIsDisabled }: CheckboxProps): JSX.Element =>
  (rowIsDisabled && rowIsDisabled(row.original) ? (
    <Tooltip content="Vous ne pouvez pas ajouter ou retirer un utilisateur ayant un rôle supérieur ou égal au vôtre">
      <CheckboxInput
        type="checkbox"
        disabled={rowIsDisabled(row.original)}
        style={{
          cursor: rowIsDisabled(row.original) ? 'not-allowed' : 'pointer',
        }}
      />
    </Tooltip>
  ) : (
    <div>
      <CheckboxInput
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    </div>
  ));

type CsvImportCheckboxProps = {
  row: Row<ZodValidationUser>;
};

export const CsvImportCheckbox = ({ row }: CsvImportCheckboxProps): JSX.Element => {
  const rowIsDisabled = !row.original?.success;

  useEffect(() => {
    row.toggleSelected(!rowIsDisabled);
  }, [rowIsDisabled]);

  return rowIsDisabled ? (
    <Tooltip content="Vous ne pouvez pas créer cet utilisateur car il présente des anomalies">
      <CheckboxInput
        type="checkbox"
        disabled={rowIsDisabled}
        style={{ cursor: rowIsDisabled ? 'not-allowed' : 'pointer' }}
      />
    </Tooltip>
  ) : (
    <div>
      <CheckboxInput
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    </div>
  );
};

type AllUsersCheckboxProps = {
  globalFilter: AllUsersFilter;
  setGlobalFilter: (updater: Updater<AllUsersFilter>) => void;
};
export const AllUsersCheckbox = ({
  globalFilter,
  setGlobalFilter,
}: AllUsersCheckboxProps): JSX.Element => {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.checked ? AllUsersFilter.ACTIVATED : AllUsersFilter.DEACTIVATED;
    setGlobalFilter(value);
  };
  return (
    <Container>
      <CheckboxInput
        id="allUsers"
        type="checkbox"
        checked={globalFilter === AllUsersFilter.ACTIVATED}
        onChange={handleChange}
      />
      <Label htmlFor="allUsers">Afficher tous les utilisateurs</Label>
    </Container>
  );
};
