/**
 * Package Import
 */
import format from 'date-fns/format';

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { isBefore, isAfter, isBeforeOrEqual, isAfterOrEqual } from 'src/utils/time';

/*
 * Code
 */
type LastMonth = {
  start: string;
  end: string;
};
export const getLastMonth = (): LastMonth => {
  const today = new Date();
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date().setDate(0);
  return {
    start: format(lastMonthStart, "yyyy-MM-dd'T'HH:mm"),
    end: format(lastMonthEnd, "yyyy-MM-dd'T'HH:mm"),
  };
};

export const getStartDefaultValue = (promotion: Promotion): string | undefined => {
  const today = new Date();
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  // Is promotion start after last month start and before today
  if (isAfter(promotion.start, lastMonthStart) && isBefore(promotion.start, today)) {
    return format(promotion.start, "yyyy-MM-dd'T'HH:mm");
  }

  // Is promotion end before last month start
  if (isBefore(promotion.end, lastMonthStart)) {
    const year = promotion.end.getFullYear();
    const lastMonth = promotion.end.getMonth();
    const promotionLastMonthStart = new Date(year, lastMonth, 1);

    // Is promotion start after the 1st of promotion last month
    if (isAfter(promotion.start, promotionLastMonthStart)) {
      return format(promotion.start, "yyyy-MM-dd'T'HH:mm");
    }

    return format(promotionLastMonthStart, "yyyy-MM-dd'T'HH:mm");
  }

  // Is promotion start before or equal to last month start
  // And is promotion end after or equal to last month start
  if (
    isBeforeOrEqual(promotion.start, lastMonthStart)
    && isAfterOrEqual(promotion.end, lastMonthStart)
  ) {
    return getLastMonth().start;
  }

  return undefined;
};

export const getEndDefaultValue = (promotion: Promotion): string | undefined => {
  const today = new Date();
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(new Date().setDate(0));

  // Is promotion start after last month end
  // And is promotion start before today
  // And is promotion end after or equal to today
  if (
    isAfter(promotion.start, lastMonthEnd)
    && isBefore(promotion.start, today)
    && isAfterOrEqual(promotion.end, today)
  ) {
    return format(yesterday.setHours(23, 59, 59, 59), "yyyy-MM-dd'T'HH:mm");
  }

  // Is promotion start after last month end
  // And is promotion end before today
  // Or is promotion end before last month end
  if (
    (isAfter(promotion.start, lastMonthEnd) && isBefore(promotion.end, today))
    || isBefore(promotion.end, lastMonthEnd)
  ) {
    return format(promotion.end.setHours(23, 59, 59, 59), "yyyy-MM-dd'T'HH:mm");
  }

  // Is promotion start before or equal to last month end
  // And is promotion end after or equal to last month end
  if (
    isBeforeOrEqual(promotion.start, lastMonthEnd)
    && isAfterOrEqual(promotion.end, lastMonthEnd)
  ) {
    return format(new Date(getLastMonth().end).setHours(23, 59, 59, 59), "yyyy-MM-dd'T'HH:mm");
  }

  // Is promotion end before last month start
  if (isBefore(promotion.end, lastMonthStart)) {
    const year = promotion.end.getFullYear();
    const lastMonth = promotion.end.getMonth();
    const promotionLastMonthEnd = new Date(year, lastMonth + 1, 0);

    return format(promotionLastMonthEnd.setHours(23, 59, 59, 59), "yyyy-MM-dd'T'HH:mm");
  }

  return undefined;
};

export const getMinDate = (promotion: Promotion): string =>
  format(promotion.start, "yyyy-MM-dd'T'HH:mm");

export const getMaxDate = (promotion: Promotion): string => {
  const today = new Date();

  if (isBefore(promotion.end, today)) {
    return format(promotion.end, "yyyy-MM-dd'T'HH:mm");
  }

  return format(today, "yyyy-MM-dd'T'HH:mm");
};
