/**
 * Package Import
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UilPlus } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import { MessageContext } from 'src/context/Message';
import Item from '../../Item/container';
import Create from '../../Create/container';
import Introduction from '../../../Introduction';
import DateSeparator from '../../../Channel/Chat/DateSeparator';

// Style
import * as S from './style';

/**
 * Component
 */
const SurveyListInner = ({ actions, isEducator }) => {
  const { getSurveys } = useContext(MessageContext);
  const { surveys, surveysWithDates } = getSurveys();

  /**
   * Render
   */
  return (
    <S.InnerSurvey>
      {surveys.length === 0 && (
        <Introduction
          key="key-survey-intro"
          icon="Chart"
          title="Aucun sondage"
          paragraph={
            isEducator
              ? 'Les sondages sont un excellent moyen de rendre vos cours interactifs'
              : 'Les sondages s’affichent ici'
          }
          cta={{
            display: isEducator,
            label: 'Créer un sondage',
            onClick: actions.openModalSurvey,
            Component: Create,
          }}
        />
      )}

      {surveysWithDates.length > 0
        && surveysWithDates.map((survey, index) => (
          <S.Element key={`key-element-${survey.date}-${survey.id}`}>
            {/* Date separator */}
            {survey.type === 'message.date' ? (
              <DateSeparator date={survey.date} />
            ) : (
              // null
              <Item
                key={`key-survey-${survey.id}`}
                survey={survey}
                surveyId={survey.id}
                surveyIndex={index + 1}
              />
            )}
          </S.Element>
        ))}

      {/* Button new poll */}
      {Array.isArray(surveys) && surveys.length > 0 && isEducator && (
        <>
          <S.NewPoll
            type="button"
            onClick={actions.openModalSurvey}
            aria-label="Créer un nouveau sondage"
          >
            <UilPlus aria-hidden="true" />
          </S.NewPoll>
          <Create />
        </>
      )}
    </S.InnerSurvey>
  );
};

/**
 * PropTypes
 */
SurveyListInner.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  isEducator: PropTypes.bool.isRequired,
};

/**
 * Export
 */
export default SurveyListInner;
