/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

/*
 * Local Import
 */
// Constants

// Style
import { useStatusString } from 'src/hooks';
import Tooltip from 'src/components/Tooltip/container';

/*
 * Properties
 */
const Name = styled('p')((props) => ({
  ...props.theme.typo.specials.lead,
}));

const Status = styled('p')((props) => ({
  ...props.theme.typo.labels.smallLabel,
  color: props.theme.text._7,
}));

/*
 * Component
 */
const AvatarTooltip = ({ name, connected, away, children, tippyOptions }) => {
  /*
   * State
   */
  const statusString = useStatusString({ connected, away });

  /*
   * Render
   */
  return (
    <Tooltip
      content={(
        <>
          <Name>{name}</Name>
          <Status>{statusString}</Status>
        </>
      )}
      options={tippyOptions}
    >
      {children}
    </Tooltip>
  );
};

/*
 * PropTypes
 */
AvatarTooltip.propTypes = {
  connected: PropTypes.bool.isRequired,
  away: PropTypes.bool.isRequired,
  name: PropTypes.string,
  children: PropTypes.object.isRequired,
  tippyOptions: PropTypes.object,
};

AvatarTooltip.defaultProps = {
  tippyOptions: {},
  name: 'Utilisateur inconnu',
};

/*
 * Export
 */
export default AvatarTooltip;
