/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilShieldCheck, UilShieldPlus, UilAngleRight } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import { ReactComponent as Ghost } from 'assets/images/icons/ghost.svg';

/*
 * Icons
 */
const iconStyle = {
  height: '1rem',
  width: '1rem',
};

export const TeacherIcon = styled(UilShieldCheck)(({ theme }) => ({
  color: theme.primary._1,
  ...iconStyle,
}));

export const HelperIcon = styled(UilShieldPlus)(({ theme }) => ({
  color: theme.success.main,
  ...iconStyle,
}));

export const GhostIcon = styled(Ghost)(({ theme }) => ({
  color: theme.text._4,
  ...iconStyle,
}));

export const Arrow = styled(UilAngleRight)(({ theme }) => ({
  color: theme.text._6,
  height: '1.5rem',
  width: '1.5rem',
}));

/*
 * Style
 */
export const TabButton = styled.button(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: '4.5rem',
  padding: '0 0.75rem 0 0.5rem',
  background: theme.background._2,
  textAlign: 'left',
  width: '100%',
  ...theme.focusVisible,

  ':hover': {
    background: theme.background._4,
  },
}));

export const Container = styled.div`
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
  padding-left: 0.25rem;
  font-size: ${({ textSize }) => `${textSize}rem !important`};
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const Label = styled.span(({ theme }) => ({
  color: theme.text._1,
  marginRight: '.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...theme.typo.headlines.H6,
  maxWidth: '8rem',
}));

export const You = styled.span(({ theme }) => ({
  color: theme.text._5,
  ...theme.typo.labels.tinyLabel,
}));

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.125rem;
  min-height: 1.25rem;
`;

export const Message = styled.span(
  ({ theme }) => ({
    color: theme.text._2,
    overflow: 'hidden',
    flexGrow: '1',
    maxHeight: '1.375rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...theme.typo.bodyText.smallBodyAdjustable,
  }),
  ({ unread, theme }) =>
    unread && {
      color: theme.text._1,
      ...theme.typo.bodyText.smallBodyAdjustable,
      fontWeight: '600',
    },
);

export const TimestampContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Unread = styled.div({
  marginLeft: '.5rem',
  marginRight: '2rem',
  height: '0.875rem',
  width: '0.875rem',
});
