/*
 * Package import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local import
 */
import * as S from './style';
import Timer from './Timer';

/*
 * Component
 */
const Away = ({ actions, displayAwayModal, user, timeValue, ...userProps }) => {
  if (!displayAwayModal) {
    return null;
  }

  /**
   * Props
   */
  const { isTeacher, isEducator } = userProps;

  /**
   * Enable microphone 🎤
   */
  const enableMicrophone = () => {
    if (isTeacher) {
      actions.enableMicrophone();
    }
  };

  /**
   * Enable webcam 📽
   */
  const enableWebcam = async () => {
    if (isEducator) {
      actions.enableWebcam();
    }
  };

  /**
   * Enable sound
   */
  const enableSound = () => {
    actions.enableSound();
  };

  /**
   * Enable devices (Microphone, webcam, and screen)
   */
  const enableDevices = () => {
    enableMicrophone();
    enableWebcam();
    enableSound();
  };

  /*
   * Handlers
   */
  const onClose = () => {
    actions.changeAway({ away: false, user });
    actions.changeAwayTime(0);

    // Enable devices
    enableDevices();
  };

  /*
   * View
   */
  return (
    <S.Modal
      open={displayAwayModal}
      onClose={onClose}
      role="dialog"
      aria-modal="true"
      aria-label="fenêtre de pause"
    >
      <S.Away>
        <Timer onClose={onClose} timeValue={timeValue} />
      </S.Away>
    </S.Modal>
  );
};

Away.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  displayAwayModal: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  timeValue: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default Away;
