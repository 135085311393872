/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MenuNotifications from 'src/components/TopBar/Menu/SubMenus/MenuNotifications';
import { toggleNotification } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const {
    enabled,
    privateEnabled,
    mentionEnabled,
    pinEnabled,
    questionEnabled,
    documentEnabled,
    surveyEnabled,
    soundEnabled,
  } = state.settings.notifications;

  return {
    // Notifications settings state
    enabled,
    privateEnabled,
    mentionEnabled,
    pinEnabled,
    questionEnabled,
    documentEnabled,
    surveyEnabled,
    soundEnabled,
    // Users state
    role: state.users.client.role,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ toggleNotification }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(MenuNotifications);
