/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
 * Local Import
 */
import successUrl from 'assets/images/icons/success.svg';
import dangerUrl from 'assets/images/icons/danger.svg';
import warningUrl from 'assets/images/icons/warning.svg';
import infoUrl from 'assets/images/icons/info.svg';

/**
 * Types
 */
type ColorEnum = 'success' | 'danger' | 'warning' | 'info';

/**
 * Get the SVG's URL from color
 */
const getUrlByColor = (color: ColorEnum) => {
  const urls = { success: successUrl, danger: dangerUrl, warning: warningUrl, info: infoUrl };
  return urls[color];
};

/*
 * Style
 */
const getToastStyle = (
  theme: Theme,
  title: string,
  color: ColorEnum,
): Record<string, string | Record<string, string>> => ({
  display: 'flex',
  border: `2px solid ${theme.colors[color]._500}`,
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderRadius: '10px',
  backgroundColor: theme.background._2,
  color: theme.text._2,
  fontSize: '0.6rem',
  fontWeight: 'lighter',
  paddingLeft: '2.8rem',
  '&::before': {
    marginTop: '0.5rem',
    paddingLeft: '0.4rem',
    fontSize: '0.7rem',
    content: `"${title}"`,
    fontWeight: 'bold',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    bottom: '0',
    margin: 'auto',
    left: '0.2rem',
    width: '40px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: theme.colors[color]._500,
    padding: '1rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '70%',
    display: 'block',
    backgroundImage: `url(${getUrlByColor(color)})`,
  },
});

export const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  '.Toastify__progress-bar': {
    background: `linear-gradient(90deg,
      ${theme.colors.primary._300} -20%,
      ${theme.colors.success._300} 120%)`,
  },
  '.Toastify__close-button': {
    position: 'absolute',
    top: '0',
    bottom: '0',
    margin: 'auto',
    right: '0.2rem',
    color: theme.text._2,
  },
  '.Toastify__toast--success': {
    ...getToastStyle(theme, 'Succès', 'success'),
  },
  '.Toastify__toast--error': {
    ...getToastStyle(theme, 'Erreur', 'danger'),
  },
  '.Toastify__toast--warning': {
    ...getToastStyle(theme, 'Attention', 'warning'),
  },
  '.Toastify__toast--info': {
    ...getToastStyle(theme, 'Info', 'info'),
  },
}));
