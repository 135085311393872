/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@oclock/crumble';

/**
 * Local Import
 */
import BaseVirtualDesktop from 'src/components/VM/container';
import { useGetVirtualDesktopStatus } from 'src/components/VM/hooks';
import { STATUS_VM } from 'src/constants/virtualDesktop';
import Loader from 'src/components/Loader';

// Helpers
import { getUnresolvedStatus, getTranslatedStatus } from 'src/utils/virtualMachine';

// Style
import { Status } from 'src/components/VM/style';
import * as S from '../Menu/style';
import { Button, Label } from '../style';

/**
 * Component
 */
const MenuVirtualDesktop = ({ actions, status }) => {
  /**
   * Hooks
   */
  useGetVirtualDesktopStatus({
    setVirtualDesktopStatus: actions.setVirtualDesktopStatus,
  });

  /**
   * Render
   */
  return (
    <S.Menu
      portal
      menuStyles={{
        cursor: 'auto',
        userSelect: 'auto',
        minHeight: '398px',
        minWidth: '660px',
      }}
      menuButton={(
        <Button>
          <Icon name="Cloud" size="20px" />
          <Label>Bureau virtuel</Label>
          {status === STATUS_VM.PENDING && <Loader />}
          {status && !getUnresolvedStatus(status) && (
            <Status status={status}>&nbsp; - {getTranslatedStatus(status)}</Status>
          )}
        </Button>
      )}
    >
      <BaseVirtualDesktop isMainPage={false} />
    </S.Menu>
  );
};

/**
 * PropTypes
 */
MenuVirtualDesktop.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  status: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

MenuVirtualDesktop.defaultProps = {
  status: null,
};

/**
 * Export
 */
export default MenuVirtualDesktop;
