import { v4 as uuid } from 'uuid';

/**
 * Local Import
 */
import type { CommandProps } from './types';

/**
 * Spoiler command
 */
export const commandSmile = (props: CommandProps) => {
  const { addMessage, actions, chatId, context } = props;

  const content = {
    ops: [{ insert: ':)' }],
  };

  const payload = {
    chatId,
    content,
    context,
    isCommand: true,
    messageUuid: uuid(),
  };

  actions.sendMessage(payload);
  addMessage?.(payload);

  return true;
};
