/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Code
 */
const IsJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  }
  catch (err) {
    return false;
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStorage = (key: string): any | false => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(key)) {
      const result = localStorage.getItem(key);
      if (result && IsJsonString(result)) {
        return JSON.parse(result);
      }
      if (result) {
        return result;
      }
    }
  }
  return false;
};

export const setStorage = (key: string, data: unknown): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(data));
  }
};

/**
 * Delete localStorage
 * @param  {String} key
 */
export const deleteStorage = (key: string): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

/*
 * Clear all datas in localStorage
 */
export const clearStorage = (): void => {
  if (typeof window !== undefined) {
    localStorage.clear();
  }
};
