/**
 * Package import
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local import
 */
import Loader from 'src/components/Loader';

const ButtonLoader = ({ isLoading }) =>
  (!isLoading ? null : (
    <div style={{ marginLeft: '0.5rem' }}>
      <Loader />
    </div>
  ));

ButtonLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default ButtonLoader;
