/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilShieldCheck, UilShieldPlus } from '@iconscout/react-unicons';
import { UisBookmark } from '@iconscout/react-unicons-solid';

/*
 * Local Import
 */
import { ReactComponent as GhostIcon } from 'assets/images/icons/ghost.svg';
import { LIGHT_THEME_NAME } from 'src/constants/theme';
import { isGhost, isHelper, isTeacher } from 'src/utils/roles';
import { Buttons } from './Actions/style';

/*
 * Icons
 */
const iconStyle = {
  height: '1em',
  width: '1em',
};

export const Teacher = styled(UilShieldCheck)(({ theme }) => ({
  color: theme.primary._1,
  ...iconStyle,
}));

export const Helper = styled(UilShieldPlus)(({ theme }) => ({
  color: theme.success.main,
  ...iconStyle,
}));

export const Ghost = styled(GhostIcon)(({ theme }) => ({
  color: theme.text._4,
  ...iconStyle,
}));

export const Pinned = styled(UisBookmark)(({ theme }) => ({
  color: theme.primary._1,
}));

/*
 * Style
 */
export const Message = styled.li(
  {
    color: `${({ theme }) => theme.text._1}`,
    position: 'relative',
    letterSpacing: '0.02em',
    minHeight: '1.375rem',
    padding: '0.05rem',
    paddingLeft: '.5rem',
    overflowWrap: 'break-word',
    userSelect: 'text',
    ':hover': {
      background: `${({ theme }) => theme.background._3}`,
      [Buttons]: {
        opacity: '1',
      },
    },
  },
  ({ next }) =>
    !next && {
      minHeight: '2.75rem',
    },
  // ({ isShown, theme }) =>
  //   isShown && {
  //     background: theme.background._3,
  //   },
  ({ editing, theme, themeName }) =>
    editing && {
      background: themeName === LIGHT_THEME_NAME ? theme.colors.success._100 : theme.background._3,
      paddingTop: '.5rem',
    },
);

export const Infos = styled.div(({ chatAppearance }) => ({
  alignItems: 'center',
  display: chatAppearance === 'compact' ? 'inline-flex' : 'flex',
  minHeight: '1.375em',
  margin: chatAppearance === 'compact' ? 0 : '0.25rem 0',
}));

export const Name = styled.a(({ chatAppearance, isPinned, role, theme }) => ({
  display: 'inline',
  ...theme.typo.headlines.H6,
  fontSize: `${theme.textSize}rem`,
  verticalAlign: 'baseline',
  margin: '0 0.25rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  color: isPinned ? theme.primary._1 : theme.text._1,
  ...theme.focusVisible,
  borderRadius: '8px',

  ...(chatAppearance === 'compact' && {
    ...(isTeacher(role) && { color: `${theme.primary._1} !important` }),
    ...(isHelper(role) && {
      color: `${isPinned ? theme.primary._1 : theme.success.main} !important`,
    }),
    ...(isGhost(role) && { color: `${isPinned ? theme.primary._1 : theme.text._4} !important` }),
  }),
}));

export const Badge = styled.span`
  display: flex;
  margin: 0 0.25rem;
`;

export const Icon = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  ...(theme.textSize > 1 && { top: '2.5rem' }),
}));

export const Content = styled.div`
  margin: 0.25rem 0;
`;

export const MessageContainer = styled.div(({ chatAppearance, next }) => ({
  ...(!next && {
    paddingTop: `${chatAppearance === 'compact' ? '0.5' : '0.7'}rem`,
  }),
  ...(chatAppearance === 'compact' && {
    [Message]: {
      minHeight: 0,
      [Content]: { margin: 0 },
    },
  }),
}));
