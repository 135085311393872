export const API_CREATE_COURSE = '/courses/create';
export const API_CREATE_PROMOTION = '/promos/create';
export const API_CREATE_USER = '/users/create';
export const API_CREATE_USERS = '/users/multiCreate';
export const API_FIND_USERS = '/users/find';
export const API_FIND_COURSES = '/courses/find';
export const API_FIND_PROMOTIONS = '/promos/find';
export const API_FIND_LIMITATIONS = '/limitations/find';
export const API_FIND_INTEGRATIONS = '/integrations-apps/find/';
export const API_ACTIVITY_EXPORT = '/activities/promotion/find/';
export const API_UPDATE_COURSE = '/courses/update';
export const API_UPDATE_PROMOTION = '/promos/update';
export const API_UPDATE_USER = '/users/update';
export const API_UPDATE_USERS = '/users/multiUpdate';
export const API_DEACTIVATE_USER = '/users/deactivate';
export const API_DEACTIVATE_COURSE = '/courses/deactivate';
export const API_DEACTIVATE_PROMOTION = '/promos/deactivate';
export const API_REACTIVATE_USER = '/users/resume';
export const API_REACTIVATE_COURSE = '/courses/resume';
export const API_REACTIVATE_PROMOTION = '/promos/resume';
export const API_DELETE_USER = '/users/delete';
export const API_SEND_INVITATION = '/users/sendInvitation';
export const API_GET = 'GET';
export const API_POST = 'POST';
export const API_PATCH = 'PATCH';
export const API_PUT = 'PUT';
export const API_DELETE = 'DELETE';
