/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

/*
 * Styles
 */
const tableBorderRadius = '10px';

export const TableRoot = styled.table(({ theme }) => ({
  tableLayout: 'auto',
  backgroundColor: theme.background._2,
  minWidth: '100%',
  border: `1px solid ${theme.text._7}`,
  borderCollapse: 'separate',
  borderRadius: tableBorderRadius,

  '& > thead': {
    borderTopLeftRadius: tableBorderRadius,
    borderTopRightRadius: tableBorderRadius,
    '& > tr:first-of-type': {
      borderTopLeftRadius: tableBorderRadius,
      borderTopRightRadius: tableBorderRadius,

      '& > th': {
        borderTopLeftRadius: tableBorderRadius,
        borderTopRightRadius: tableBorderRadius,
      },
    },
  },

  '& > tbody': {
    borderBottomLeftRadius: tableBorderRadius,
    borderBottomRightRadius: tableBorderRadius,
    '& > tr:last-child': {
      borderBottomLeftRadius: tableBorderRadius,
      borderBottomRightRadius: tableBorderRadius,

      '& > td': {
        borderBottom: 'none',
      },

      '& > td:first-of-type': {
        borderBottomLeftRadius: tableBorderRadius,
      },

      '& > td:last-child': {
        borderBottomRightRadius: tableBorderRadius,
      },
    },
  },
}));

const getBodyText = (theme: ThemeInterface): Record<symbol, string> => theme.typo.bodyText.body;
export const TableBody = styled.tbody(({ theme }) => ({
  ...getBodyText(theme),
  width: '100%',
  maxHeight: '30rem',
  overflow: 'auto',
}));

export const TableContainer = styled.div(() => ({
  minWidth: '27rem',
}));

export const TableScrollManager = styled.div(({ theme }) => ({
  height: '32rem',
  width: '100%',
  padding: '0 .35rem 0 0',
  overflow: 'auto',
  marginBottom: '1rem',
  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));

type TableRowProps = {
  theme: ThemeInterface;
  disabled: boolean;
};
export const TableRow = styled.tr(({ theme, disabled }: TableRowProps) => ({
  position: 'relative',
  color: disabled ? theme.text._6 : theme.text._4,
  '&:hover': {
    // applying on tds because of mozilla bug (rows' backgroundColor bleeds on border radius)
    '& > td': {
      backgroundColor: theme.background._8,
    },
  },
  ...(disabled && {
    background: theme.background._8,
    '& > td > span': {
      color: theme.text._6,
    },
  }),
}));

export const TableCell = styled.td(({ theme }) => ({
  fontSize: '1rem',
  display: 'table-cell',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
}));
