import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

export const VmUrlContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '1rem',
  color: theme.text._1,
}));

const getSmallLabelTypo = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.smallLabel;
export const VmUrl = styled('p')(({ theme }) => ({
  color: theme.text._1,
  ...getSmallLabelTypo(theme),
}));
