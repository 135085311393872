/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Removes margins on Divider component and replace them with padding
 * This is because Virtuoso doesn't handle margins well and it messes up scrolling
 * https://virtuoso.dev/#gotchas
 */
export const DividerContainer = styled.div({
  '> *': {
    margin: 0,
    padding: '1rem 0',
  },
});
