/*
 * Package Import
 */
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import { Participants } from 'src/components/Chats/Poll/Item/Participants';
// import { openChat } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  const userProps = {};
  const user = state.users.userById[ownProps.userId];

  // User props
  if (user) {
    userProps.user = user;
  }

  return {
    ...userProps,
  };
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({ openChat }, dispatch),
// });

/*
 * Export
 */
export default connect(mapStateToProps, null)(Participants);
