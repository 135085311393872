/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilPlusCircle, UilCheck } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import Modal from 'src/components/Modal';
import { base } from 'src/theme';

/*
 * Style
 */
export const PollModal = styled(Modal)({
  position: 'fixed',
  right: '1rem',
  bottom: '1rem',
  outline: 'none',

  '.ReactModal__Overlay ReactModal__Overlay--after-open': {
    backgroundColor: 'none !important',
  },
});

export const Form = styled.form(({ theme }) => ({
  color: theme.text._1,
  background: theme.background._2,
  border: `2px solid ${theme.text._7}`,
  borderRadius: '16px',
  height: 'fit-content',
  width: 'fit-content',
  minWidth: '20vw',
  maxHeight: '90vh',
  overflowY: 'scroll',
  padding: '1rem',
  position: 'relative',
  ...theme.shadows.plus2,

  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',

  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));

export const Title = styled.div(({ theme }) => ({
  textAlign: 'center',
  ...theme.typo.headlines.H5,
  marginBottom: '1rem',
  padding: '0.5rem 0',
}));

export const Divider = styled.div`
  border: 1px solid ${({ theme }) => theme.background._4};
  margin: 1rem auto 0;
  width: 17.1875rem;
`;

export const Answer = styled.div`
  min-height: 4.375rem;
  margin: 1rem auto;
`;

export const LabelContainer = styled.label(({ theme }) => ({
  ...theme.typo.labels.label,
}));

export const Label = styled.span(({ theme }) => ({
  display: 'block',
  cursor: 'default',
  height: '1.5rem',
  marginBottom: '.25rem',
  ...theme.typo.labels.label,
}));

export const Input = styled.input(({ theme }) => ({
  padding: '0.5em 0.75em',
  height: '2.5rem',
  background: theme.background._3,
  color: theme.text._1,
  border: `1px solid ${theme.text._7}`,
  borderRadius: '8px',
  width: '100%',

  '::placeholder': {
    color: theme.text._6,
  },

  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const Add = styled.button(({ theme }) => ({
  marginBottom: '1rem',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  color: theme.text._1,
  borderRadius: '8px',

  ':hover': {
    color: theme.primary._2,
  },

  ...theme.focusVisible,
}));

export const IconAdd = styled(UilPlusCircle)(({ theme }) => ({
  marginRight: '.5rem',
  color: theme.text._1,
}));

export const Remove = styled.button(({ theme }) => ({
  cursor: 'pointer',
  padding: '.25rem',
  borderRadius: '4px',
  marginTop: '0.15rem',
  marginRight: '0',
  marginLeft: 'auto',
  color: theme.primary._2,
  ...theme.typo.labels.tinyLabel,
  ':hover': {
    color: theme.primary._1,
  },

  ...theme.focusVisible,
}));

export const OptionContainer = styled.label({
  maxWidth: '13.125rem',
  display: 'block',
  marginBottom: '1rem',
});

export const CheckboxCustom = styled.div(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.5rem',
  width: '1.5rem',
  height: '1.5rem',
  position: 'absolute',
  borderRadius: '8px',
  border: `2px solid ${theme.text._1}`,
  background: theme.background._2,
}));

export const Checked = styled(UilCheck)({
  display: 'none',
  position: 'absolute',
});

export const Checkbox = styled.input(({ theme }) => ({
  appearance: 'checkbox',
  display: 'none',

  ':checked + span': {
    [Checked]: {
      display: 'block',
      width: '1.25rem',
      height: '1.25rem',
      color: theme.text._11,
    },
    [CheckboxCustom]: {
      background: theme.background._6,
    },
  },
}));

export const Text = styled.span(({ theme }) => ({
  marginLeft: '2rem',
  color: theme.text._1,
}));

export const LabelOption = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',

  '&:hover': {
    [Text]: {
      color: theme.primary._2,
    },
  },
}));

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justifiy-comtent: space-around;
  margin-top: 1.5rem;
`;

export const Button = styled.button(({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '5.80rem',
  flex: 1,
  ':first-of-type': {
    marginRight: '1rem',
  },
}));
