/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilSmile, UilPlus } from '@iconscout/react-unicons';
import Modal from 'src/components/Modal';

/*
 * Icons
 */
const iconStyle = (theme) => ({
  color: theme.text._1,
  height: '1.25rem',
  width: '1.25rem',
});

export const Smile = styled(UilSmile)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Plus = styled(UilPlus)(({ theme }) => ({
  ...iconStyle(theme),
  color: theme.primary._2,
}));

/*
 * Style
 */
export const Menu = styled.div(({ theme }) => ({
  fontFamily: 'Inter !important',
  position: 'absolute',
  right: '-3rem',
  bottom: '1.8rem',
  padding: '1rem',
  borderRadius: '16px',
  background: theme.background._2,
  ...theme.shadows.plus2,
}));

export const Header = styled.div(({ theme }) => ({
  paddingBottom: '1rem',
  borderBottom: `1px solid ${theme.text._7}`,
  color: theme.text._1,
  textAlign: 'center',
  ...theme.typo.headlines.H5,
}));

export const Reactions = styled.div({
  paddingTop: '1rem',
  width: '24rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Button = styled.button(({ theme }) => ({
  cursor: 'pointer',
  padding: '0.25rem',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1.75rem',
  height: '1.75rem',

  div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 !important',

    span: {
      width: '1.25rem',
      height: '1.25rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  ':hover': {
    background: theme.background._4,

    [Plus]: {
      color: theme.primary._1,
      background: theme.background._4,
    },
  },
}));

export const ReactionsModal = styled(Modal)({
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  outline: 'none',

  '.ReactModal__Overlay ReactModal__Overlay--after-open': {
    backgroundColor: 'none !important',
  },
});
