/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Edited = styled.span(({ theme }) => ({
  color: theme.text._5,
  cursor: 'default',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  ...theme.typo.bodyText.tinyText,
}));
