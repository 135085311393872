/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { UilTimes } from '@iconscout/react-unicons';

/*
 * Local Import
 */

// Style
import * as S from './style';

/*
 * Component
 */
const SigninSheet = ({ actions, isOpen, signatureLink }) => {
  /*
   * Handlers
   */
  const handleClose = (evt) => {
    evt.preventDefault();
    actions.setSignatureModal({ isOpen: false });
  };

  /*
   * Render
   */
  return (
    <S.Modal open={isOpen} onClose={handleClose} aria-labelledby="Signature-modal">
      <S.ScrollContainer>
        <S.Close onClick={handleClose}>
          <UilTimes />
        </S.Close>
        <iframe
          title="Signature émargement"
          src={signatureLink}
          width="512"
          height="780"
          scrolling="no"
        />
      </S.ScrollContainer>
    </S.Modal>
  );
};

/*
 * PropTypes
 */
SigninSheet.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State modal signature */
  isOpen: PropTypes.bool.isRequired,
  /** Value of signatureLink */
  signatureLink: PropTypes.string.isRequired,
};

SigninSheet.defaultProps = {};

/*
 * Export
 */
export default SigninSheet;
