import { isBefore, isAfter } from 'src/utils/time';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { ongoing, finished, coming } from 'src/constants/status';

export const getStatus = (promotion: Promotion): Option => {
  const today = new Date();

  if (isBefore(promotion.end, today)) {
    return finished;
  }

  if (isAfter(promotion.start, today)) {
    return coming;
  }

  return ongoing;
};
