/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Webcams from 'src/components/Course/Webcams';
import { isMaxVideoTracksReached } from 'src/utils/webRTCManager/utils';
import {
  addNotification,
  startMyWebcamStream,
  disableMicrophone,
  disableWebcam,
  enableWebcam,
} from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { streamList, streamListStatus, connected, antmediaLocalStream } = state.webRTC;

  const { userById, client } = state.users;

  const { peers, usersWebcamMuted } = state.course;

  return {
    peers,
    settingsWebcamsEnabled: state.settings.layout.webcams,
    myRole: client.role,
    webRTCConnected: connected,
    isMaxTracksReached: isMaxVideoTracksReached({
      camsStreamList: streamList.cams,
      streamListStatus: streamListStatus.cams,
      usersWebcamMuted,
      userById,
    }),
    streamList: streamList.cams,
    streamListStatus: streamListStatus.cams,
    antmediaLocalStreamReady: antmediaLocalStream,
  };
};

// Actions
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  actions: bindActionCreators(
    {
      addNotification,
      disableMicrophone,
      disableWebcam,
      startMyWebcamStream,
      enableWebcam,
    },
    dispatchProps.dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, null, mergeProps)(Webcams);
