/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Profile = styled('div')({
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  padding: '0 1rem',
  margin: '2rem 0',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const Title = styled('h6')(({ theme }) => ({
  color: theme.text._5,
  ...theme.typo.specials.capitalized,
  margin: '0 .5rem .5rem',
}));

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '100%',
});
