/**
 * Package Import
 */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import ChannelPreview from 'src/components/Chats/Channel/ChannelPreview/container';
import { MessageContext } from 'src/context/Message';
import { getOpenChannelsIds } from 'src/store/selectors/chats';

// style
import * as S from './style';

/**
 * Component
 */
const ChannelList = ({ handleScroll }) => {
  const { getLastMessage, idsByChat, messagesById } = useContext(MessageContext);
  const openChatIds = useSelector(getOpenChannelsIds);

  /**
   * Sort chats by their last message's time
   */
  const sortedChatIds = useMemo(() => {
    const chats = openChatIds.map((chatId) => {
      const lastMessage = getLastMessage(chatId);

      return { chatId, time: lastMessage?.time || 0 };
    });

    return chats.sort((chatA, chatB) => chatB.time - chatA.time).map((chat) => chat.chatId);
  }, [idsByChat, messagesById, openChatIds]);

  return (
    <div role="tabpanel" aria-labelledby="tabChatPrivate">
      <S.List onScroll={handleScroll}>
        {sortedChatIds
          && sortedChatIds.length !== 0
          && sortedChatIds.map((chatId) => <ChannelPreview key={chatId} chatId={chatId} />)}
      </S.List>
    </div>
  );
};

ChannelList.propTypes = {
  handleScroll: PropTypes.func.isRequired,
};

/**
 * Export
 */
export default ChannelList;
