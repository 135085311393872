/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
// utils
import { setIconName } from 'src//utils/icons';

// Style
import * as S from './style';

/*
 * Component
 */
const NotificationReader = ({ readerIsOpen, data, actions, rightOpened }) => {
  /*
   * Variables
   */
  const { title, comment } = data;

  let description;

  if (data.name) {
    description = (
      <>
        {data.name}
        {data.description}
      </>
    );
  }
  else {
    description = data.description;
  }

  const Icon = title ? S[setIconName(title)] : null;

  /*
   * Handlers
   */
  const handleClose = () => {
    actions.setModalNotificationReader({ readerIsOpen: false });
  };

  /*
   * Component
   */

  return (
    <>
      {data && (
        <S.Modal
          open={readerIsOpen}
          onClose={handleClose}
          aria-labelledby="Feedback-modal"
          rightOpened={rightOpened}
        >
          <S.Close onClick={handleClose}>
            <S.IconClose size={16} />
          </S.Close>

          <Icon />
          <S.Title id="report">{title}</S.Title>
          <S.Description>{description}</S.Description>
          {comment && <S.Comment>{comment}</S.Comment>}
        </S.Modal>
      )}
    </>
  );
};

/*
 * PropTypes
 */
NotificationReader.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State reader notification */
  readerIsOpen: PropTypes.bool.isRequired,
  /** Content of modal notification readear */
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    comment: PropTypes.string,
    name: PropTypes.string,
    userIdMessage: PropTypes.string,
  }),
  /** State panel chat */
  rightOpened: PropTypes.bool.isRequired,
};

NotificationReader.defaultProps = {
  data: null,
};

/*
 * Export
 */
export default NotificationReader;
