/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const PageError = () => (
  <S.PageError>
    <S.Container>
      <S.Title>Houston nous avons un problème !</S.Title>
      <S.Subtitle>Une erreur est survenue.</S.Subtitle>
      <S.Paragraph>
        Notre équipe en a été informée et va résoudre le problème dès que possible. <br />
        N&apos;hésitez pas à réessayer dans quelques instants.
      </S.Paragraph>
      <S.Link to="/">Revenir à la page d&apos;accueil</S.Link>
    </S.Container>
  </S.PageError>
);

/*
 * Export
 */
export default PageError;
