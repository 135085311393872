export enum WebRTCEvent {
  WEBRTC_CONNECTED = 'WEBRTC_CONNECTED',
  WEBRTC_ROOMS_JOINED = 'WEBRTC_ROOMS_JOINED',
  WEBRTC_ROOMS_LEFT = 'WEBRTC_ROOMS_LEFT',

  WEBRTC_CAMS_MICS_ROOM_JOINED = 'WEBRTC_CAMS_MICS_ROOM_JOINED',
  WEBRTC_CAMS_MICS_ROOM_LEFT = 'WEBRTC_CAMS_MICS_ROOM_LEFT',
  WEBRTC_SCREENS_ROOM_JOINED = 'WEBRTC_SCREENS_ROOM_JOINED',
  WEBRTC_SCREENS_ROOM_LEFT = 'WEBRTC_SCREENS_ROOM_LEFT',

  CAM_MIC_STREAM_STARTED = 'CAM_MIC_STREAM_STARTED',
  CAM_MIC_STREAM_STOPPED = 'CAM_MIC_STREAM_STOPPED',
  CAM_MIC_PLAY_STARTED = 'CAM_MIC_PLAY_STARTED',

  SCREEN_STREAM_STARTED = 'SCREEN_STREAM_STARTED',
  SCREEN_STREAM_STOPPED = 'SCREEN_STREAM_STOPPED',

  LOCAL_MIC_ENABLED = 'LOCAL_MIC_ENABLED',
  LOCAL_MIC_DISABLED = 'LOCAL_MIC_DISABLED',
  LOCAL_VIDEO_ENABLED = 'LOCAL_VIDEO_ENABLED',
  LOCAL_VIDEO_DISABLED = 'LOCAL_VIDEO_DISABLED',

  PAUSE_ENABLED = 'PAUSE_ENABLED',
  PAUSE_DISABLED = 'PAUSE_DISABLED',

  NEW_CAM_STREAM_AVAILABLE = 'NEW_CAM_STREAM_AVAILABLE',
  NEW_SCREEN_STREAM_AVAILABLE = 'NEW_SCREEN_STREAM_AVAILABLE',

  ANTMEDIA_LOCALSTREAM_READY = 'ANTMEDIA_LOCALSTREAM_READY',
}
