/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  // Notifications state
  hasDisplayParamEnabled: state.settings.layout.tooltips,
});

/*
 * Export
 */
export default connect(mapStateToProps)(Tooltip);
