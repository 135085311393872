/*
 * Package Import
 */
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { defaultStatusFilterValues } from 'src/constants/status';

/*
 * Styles
 */
const Toggle = styled('div')(() => ({
  display: 'flex',
}));

const getLabelTypoStyle = (theme) => theme.typo.labels.label;

const ToggleButton = styled('button')(({ theme }) => ({
  color: theme.text._5,
  background: theme.background._2,
  border: `1px solid ${theme.text._7}`,
  padding: '.5rem 1rem',
  ...getLabelTypoStyle(theme),

  '&.activated': {
    borderRadius: '8px 0 0 8px',
  },

  '&.archived': {
    borderLeftWidth: '0',
    borderRadius: '0 8px 8px 0',
  },

  '&.enabled': {
    color: theme.text._1,
    background: theme.background._8,
  },

  ':active': {
    color: theme.text._1,
    background: theme.background._8,
  },
}));

const ArchiveToggle = ({
  setArchiveFilter,
  setColumnVisibility,
  filterValue,
  statusColumn,
  isFeminine,
}) => {
  const handleActivatedClick = () => {
    setArchiveFilter(false);
    if (statusColumn) {
      const { setFilterValue } = statusColumn;
      setFilterValue([...defaultStatusFilterValues]);
    }
  };
  const handleArchivedClick = () => {
    setArchiveFilter(true);
  };

  useEffect(() => {
    if (statusColumn && setColumnVisibility) {
      setColumnVisibility((prevState) => ({ ...prevState, status: !filterValue }));
      // If we get archived items, remove status filter value
      if (filterValue) {
        statusColumn.setFilterValue(undefined);
      }
    }
  }, [filterValue]);

  return (
    <Toggle>
      <ToggleButton
        className={`activated ${!filterValue && 'enabled'}`}
        onClick={handleActivatedClick}
      >
        {isFeminine ? 'Actives' : 'Actifs'}
      </ToggleButton>
      <ToggleButton
        className={`archived ${filterValue && 'enabled'}`}
        onClick={handleArchivedClick}
      >
        {isFeminine ? 'Archivées' : 'Archivés'}
      </ToggleButton>
    </Toggle>
  );
};
ArchiveToggle.propTypes = {
  setArchiveFilter: PropTypes.func.isRequired,
  setColumnVisibility: PropTypes.func,
  filterValue: PropTypes.bool,
  statusColumn: PropTypes.object,
  isFeminine: PropTypes.bool.isRequired,
};
ArchiveToggle.defaultProps = {
  setColumnVisibility: undefined,
  statusColumn: undefined,
  filterValue: false,
};

export const ArchiveFilter = ({ tableInterface, isFeminine = false }) => {
  const { getAllColumns, setColumnVisibility } = tableInterface;
  const archiveColumn = getAllColumns().find((column) => column.id === 'archive');
  const statusColumn = getAllColumns().find((column) => column.id === 'status');
  if (!archiveColumn) return <div />;
  const { setFilterValue, getFilterValue } = archiveColumn;

  return (
    <ArchiveToggle
      setArchiveFilter={setFilterValue}
      filterValue={getFilterValue()}
      setColumnVisibility={statusColumn ? setColumnVisibility : undefined}
      statusColumn={statusColumn}
      isFeminine={isFeminine}
    />
  );
};
ArchiveFilter.propTypes = {
  tableInterface: PropTypes.object.isRequired,
  isFeminine: PropTypes.bool,
};
ArchiveFilter.defaultProps = {
  isFeminine: false,
};
