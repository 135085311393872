/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { applyStatics, MenuDivider, MenuItem, SubMenu } from '@szhsin/react-menu';

/*
 * Local Import
 */

// Components
import ChatAppearance from 'src/components/TopBar/Menu/SubMenus/MenuLayout/ChatAppearance';
import ChatSize from 'src/components/TopBar/Menu/SubMenus/MenuLayout/ChatSize';
import TextSize from 'src/components/TopBar/Menu/SubMenus/MenuLayout/TextSize/container';
import Transcription from 'src/components/TopBar/Menu/SubMenus/MenuLayout/Transcription/container';
import UserListStyle from 'src/components/TopBar/Menu/SubMenus/MenuLayout/UserListStyle';

// Constants
import { LIGHT_THEME_NAME, DARK_THEME_NAME } from 'src/constants/theme';

// Style
import * as S from '../style';
import * as P from '../../style';

// HOC to import component and declare them as MenuItem
// https://szhsin.github.io/react-menu/docs#utils-apply-statics
applyStatics(SubMenu)(ChatAppearance);
applyStatics(SubMenu)(ChatSize);
applyStatics(SubMenu)(TextSize);
applyStatics(SubMenu)(Transcription);
applyStatics(SubMenu)(UserListStyle);

/*
 * Component
 */
const MenuLayout = ({ actions, displayChat, displayUsers, disabled, index, tooltips, webcams }) => {
  /*
   * Variables
   */
  const theme = useTheme();

  /*
   * Handlers
   */
  const handleChange = (name) => (evt) => {
    actions.toggleLayout(name);
    // Keep menu opened when clicked
    evt.keepOpen = true;
    evt.stopPropagation = true;
  };

  const handleChangeTheme = (evt) => {
    const newTheme = theme.themeName === LIGHT_THEME_NAME ? DARK_THEME_NAME : LIGHT_THEME_NAME;
    theme.chooseTheme(newTheme);
    actions.changeTheme(newTheme);
    // Keep menu opened when clicked
    evt.keepOpen = true;
    evt.stopPropagation = true;
  };

  const handleResetLayout = (evt) => {
    if (theme.themeName !== LIGHT_THEME_NAME) {
      theme.chooseTheme(LIGHT_THEME_NAME);
    }
    actions.resetLayout();
    // Keep menu opened when clicked
    evt.keepOpen = true;
    evt.stopPropagation = true;
  };

  /*
   * Render
   */
  return (
    <SubMenu
      label={(
        <P.ContainerLabel>
          <P.IconCog aria-hidden="true" />
          &nbsp; Interface
        </P.ContainerLabel>
      )}
      index={index}
      disabled={disabled}
    >
      <ChatAppearance />
      <ChatSize />
      <TextSize />
      <UserListStyle />
      {/* TRANSCRIPTION DISABLED FOR NOW */}
      {/* <Transcription /> */}
      <MenuItem onClick={handleChangeTheme}>
        <S.ContainerCheckBox>
          <S.Checkbox>
            <P.IconCheck isactive={theme.themeName === DARK_THEME_NAME ? 'true' : undefined} />
          </S.Checkbox>
          <P.IconMoon aria-hidden="true" />
          Mode nuit
        </S.ContainerCheckBox>
      </MenuItem>
      <MenuDivider />
      <MenuItem onClick={handleResetLayout}>
        <S.ContainerCheckBox>
          <S.Checkbox />
          <S.Label>Réinitialiser l&apos;Interface </S.Label>
        </S.ContainerCheckBox>
      </MenuItem>
      <MenuItem onClick={handleChange('displayChat')}>
        <S.ContainerCheckBox>
          <S.Checkbox>
            <P.IconCheck isactive={displayChat ? 'true' : undefined} />
          </S.Checkbox>
          <S.Label>Afficher le chat </S.Label>
        </S.ContainerCheckBox>
      </MenuItem>
      <MenuItem onClick={handleChange('displayUsers')}>
        <S.ContainerCheckBox>
          <S.Checkbox>
            <P.IconCheck isactive={displayUsers ? 'true' : undefined} />
          </S.Checkbox>
          <S.Label>Afficher les participants </S.Label>
        </S.ContainerCheckBox>
      </MenuItem>
      <MenuItem onClick={handleChange('webcams')}>
        <S.ContainerCheckBox>
          <S.Checkbox>
            <P.IconCheck isactive={webcams ? 'true' : undefined} />
          </S.Checkbox>
          <S.Label>Afficher les webcams </S.Label>
        </S.ContainerCheckBox>
      </MenuItem>
      <MenuItem onClick={handleChange('tooltips')}>
        <S.ContainerCheckBox>
          <S.Checkbox>
            <P.IconCheck isactive={tooltips ? 'true' : undefined} />
          </S.Checkbox>
          <S.Label>Afficher les tooltips </S.Label>
        </S.ContainerCheckBox>
      </MenuItem>
    </SubMenu>
  );
};

/*
 * PropTypes
 */
MenuLayout.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** If chat is display for checkbox settings */
  displayChat: PropTypes.bool.isRequired,
  /** If users is display for checkbox settings */
  displayUsers: PropTypes.bool.isRequired,
  /** If webcams are display for checkbox settings */
  webcams: PropTypes.bool.isRequired,
  /** If tooltips are display for checkbox settings */
  tooltips: PropTypes.bool.isRequired,
  /** All 2 next props are necessary for the call of applyStatics */
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

MenuLayout.defaultProps = {
  disabled: false,
};

/*
 * Export
 */
export default MenuLayout;
