/*
 * Package Import
 */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import MainMenu from 'src/components/TopBar/Menu/container';
import MenuVirtualDesktop from 'src/components/TopBar/MenuVirtualDesktop/container';
import { UserContext } from 'src/context/User';

// Helpers
import { IntegrationApps } from 'src/constants/integrations';
import { titles } from 'src/constants/notifications';
import { api } from 'src/utils/api';
import { isEducator } from 'src/utils/roles';

// SVGs
import {
  UilArrowToRight,
  UilBell,
  UilCommentAltDots,
  UilLeftArrowToLeft,
  UilUsersAlt,
} from '@iconscout/react-unicons';

// Style
import * as S from './style';

/*
 * Component
 */
const Topbar = ({
  actions,
  courseTitle,
  drawerLeftOpen,
  handleDrawerLeft,
  drawerRightOpen,
  handleDrawerRight,
  displayChat,
  displayUsers,
  menuNotificationsIsOpen,
  menuSignatureIsOpen,
  allNotifications,
  canSign,
  mustSign,
}) => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  /**
   * State
   */
  const [newNotification, setNewNotification] = useState(
    /** @type {Boolean} — Default value for notification badge */
    Boolean(allNotifications.length),
  );
  const [edusignData, setEdusignData] = useState({
    isAccountLinked: false,
    signatureByDefault: false,
  });

  /**
   * L'utilisateur peut émarger sous certaines conditions:
   * - edusign est intégré
   * - l'user est formateur ou helper
   * - signature par défaut activée + l'user n'a pas "activer l'émargement" décoché
   * - signature par défaut désactivée + l'user a "activer l'émargement" coché
   */
  const userMustSign = useMemo(() => {
    const { isAccountLinked, signatureByDefault } = edusignData;

    return (
      isAccountLinked
      && (isEducator(user.role) || (signatureByDefault && mustSign !== false) || mustSign)
    );
  }, [edusignData, mustSign, user]);

  /*
   * Handlers
   */
  const newNotifRequestTalk = !!allNotifications.find(
    (notif) => notif.title === titles.REQUEST_TALK,
  );

  const handleClickMenuNotifications = () => {
    // Menu notification
    actions.setMenuNotifications({ isOpen: !menuNotificationsIsOpen });

    // Badge notification
    setNewNotification(false);
  };

  const handleClickMenuSignature = () => {
    // Menu signature
    actions.setMenuSignature({ isOpen: !menuSignatureIsOpen });
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    // Update notification badge
    setNewNotification(Boolean(allNotifications.length));
  }, [allNotifications]);

  useEffect(() => {
    api({
      method: 'GET',
      url: `/integrations-apps/find/${IntegrationApps.EDUSIGN}`,
    })
      .then(({ data }) => {
        const { isAccountLinked, customProperties } = data;
        const { signature_by_default: signatureByDefault } = customProperties;
        if (isAccountLinked) {
          setEdusignData((prevState) => ({
            ...prevState,
            isAccountLinked,
            signatureByDefault,
          }));
        }
      })
      .catch(() => {
        setEdusignData({
          isAccountLinked: false,
          signatureByDefault: false,
        });
      });
  }, []);

  /*
   * Render
   */
  return (
    <>
      <S.Container id="navigation-drawer-left">
        {/* USERS */}
        {displayUsers && (
          <S.Button
            aria-label={
              drawerLeftOpen
                ? 'Fermer le panneau des participants'
                : 'Ouvrir le panneau des participants'
            }
            onClick={handleDrawerLeft}
          >
            {drawerLeftOpen ? (
              <UilLeftArrowToLeft aria-hidden="true" />
            ) : (
              <>
                <UilUsersAlt aria-hidden="true" />
                <S.Label>Participants</S.Label>
                {newNotifRequestTalk && !drawerLeftOpen && <S.Badge />}
              </>
            )}
          </S.Button>
        )}

        {/* MENU */}
        <MainMenu />

        {/* BUREAU VIRTUEL */}
        {user.canCreateVM && <MenuVirtualDesktop />}
      </S.Container>

      <S.ClassTitleContainer>
        <Tooltip content={courseTitle}>
          <S.ClassTitle>{courseTitle}</S.ClassTitle>
        </Tooltip>
      </S.ClassTitleContainer>

      <S.Container id="navigation-drawer-right">
        {/* EMARGEMENT */}
        {edusignData?.isAccountLinked && userMustSign && (
          <S.Button
            aria-expanded={menuSignatureIsOpen}
            isDisplay={menuSignatureIsOpen}
            disabled={!canSign}
            onClick={handleClickMenuSignature}
          >
            <S.TitleSrOnly>Émargement</S.TitleSrOnly>
            <S.EdusignLogo aria-hidden="true" />
            <S.Label>Émargement</S.Label>
          </S.Button>
        )}

        {/* NOTIFICATIONS */}
        <S.Button
          aria-expanded={menuNotificationsIsOpen}
          isDisplay={menuNotificationsIsOpen}
          onClick={handleClickMenuNotifications}
        >
          <S.TitleSrOnly>Notifications</S.TitleSrOnly>
          <UilBell aria-hidden="true" />
          {newNotification && <S.Badge />}
        </S.Button>

        {/* CHATS */}
        {displayChat && (
          <S.Button
            aria-label={drawerRightOpen ? 'Fermer le panneau du chat' : 'Ouvrir le panneau du chat'}
            onClick={handleDrawerRight}
          >
            {drawerRightOpen ? (
              <UilArrowToRight aria-hidden="true" />
            ) : (
              <>
                <UilCommentAltDots aria-hidden="true" />
                <S.Label>Chat</S.Label>
              </>
            )}
          </S.Button>
        )}
      </S.Container>
    </>
  );
};

/*
 * PropTypes
 */
Topbar.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Name of the current course */
  courseTitle: PropTypes.string.isRequired,
  /** State panel users list for display */
  drawerLeftOpen: PropTypes.bool.isRequired,
  /** Function open/close panel users */
  handleDrawerLeft: PropTypes.func.isRequired,
  /** State display button users for display */
  displayUsers: PropTypes.bool.isRequired,
  /** State panel chat for display */
  drawerRightOpen: PropTypes.bool.isRequired,
  /** State display button chat  for display */
  displayChat: PropTypes.bool.isRequired,
  /** Function open/close panel chat */
  handleDrawerRight: PropTypes.func.isRequired,
  /** State menu notification */
  menuNotificationsIsOpen: PropTypes.bool.isRequired,
  /** State menu signature */
  menuSignatureIsOpen: PropTypes.bool.isRequired,
  /** State new notification for badge */
  allNotifications: PropTypes.array.isRequired,
  /** State can sign */
  canSign: PropTypes.bool.isRequired,
  /** user must sign edusign signin sheet  */
  mustSign: PropTypes.bool,
};

Topbar.defaultProps = {
  mustSign: null,
};

/*
 * Export
 */
export default Topbar;
