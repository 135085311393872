/*
 * Package Import
 */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';
import { ColumnFiltersState } from '@tanstack/react-table';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { getRoute } from 'src/utils/routes';
import { MongoId } from 'src/schemas/Entities/utils';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { useUpdatePromotionUsers } from 'src/serverStateManagers/mutations/update/Promotions/PromotionUsers/useUpdatePromotionUsers';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import useUpdatePromotionUsersTable from './hooks/useUpdatePromotionUsersTable';
import { promotionUsersFormData } from './config';
import {
  PromotionUsersFormData,
  FormError,
  getSelectedUsers,
  getRemovedUsers,
  validatePromotionUsersFormData,
  isDisabledRow,
} from './utils';

/*
 * Code
 */
type UpdatePromotionUsersProps = {
  isOpen: boolean;
  idToAddUsers: MongoId | null;
};
const UpdatePromotionUsers = ({ isOpen, idToAddUsers }: UpdatePromotionUsersProps): JSX.Element => {
  const updatePromotionUsersMutation = useUpdatePromotionUsers();
  const history = useHistory();
  const { isError, data: usersData } = useGetUsers();
  const { data: promotionsData } = useGetPromotions();
  const promotionData = promotionsData?.find((promotion) => promotion.id === idToAddUsers);

  // State
  const [promotionUsersNewData, setPromotionUsersNewData] = useState({
    selectedUsers: [],
    removedUsers: [],
  } as PromotionUsersFormData);
  const [errors, setErrors] = useState([] as FormError[]);

  useEffect(() => {
    if (promotionData?.deactivatedAt) {
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [promotionData?.deactivatedAt, history]);

  // Get active users data (not deactivated)
  const activeUsersData = useMemo(
    () => usersData?.filter((userData) => !userData.deactivatedAt),
    [usersData],
  );

  const parentPromotionId = promotionData?.parentId;

  // Config
  const { header, formErrors } = promotionUsersFormData;
  const rowIsDisabled = isDisabledRow();
  const [tableInstance, UpdatePromotionUsersTable] = useUpdatePromotionUsersTable(
    activeUsersData ?? [],
    parentPromotionId,
    {
      rowIsDisabled,
      idToAddUsers,
    },
  );
  const { getSelectedRowModel, setColumnFilters } = tableInstance;
  const { flatRows: selectedFlatRows } = getSelectedRowModel();

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    // Reset form data
    setPromotionUsersNewData({
      selectedUsers: [],
      removedUsers: [],
    });
    // Reset errors
    setErrors([]);
    // Reset filter
    setColumnFilters((prevState: ColumnFiltersState) => [...prevState, { id: 'users', value: '' }]);
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  // Form data state
  useEffect(() => {
    // Keep only users which have not current promotion before
    const selectedRowValues = getSelectedUsers(selectedFlatRows, idToAddUsers);
    // If users are removed from current promotion
    // let's update them
    const removedRowValues = getRemovedUsers(activeUsersData, selectedFlatRows, idToAddUsers);
    // Update form data state
    setPromotionUsersNewData({
      selectedUsers: selectedRowValues,
      removedUsers: removedRowValues,
    });
  }, [selectedFlatRows, activeUsersData, idToAddUsers]);

  // Submit
  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    setErrors([]); // to not have the same error more than once
    // Errors state
    const { selectedUsers, removedUsers } = promotionUsersNewData;
    if (!selectedUsers.length && !removedUsers.length) {
      setErrors((prevState) => [...prevState, formErrors.noAction]);
    }
    else {
      if (idToAddUsers) {
        // Parse selected and removed users
        // eslint-disable-next-line max-len
        const promotionUsersFormDataValidatedResult = validatePromotionUsersFormData(promotionUsersNewData);

        updatePromotionUsersMutation.mutate({
          promotionId: idToAddUsers,
          updatePromotionUsersFormData: promotionUsersFormDataValidatedResult,
        });
      }
      // Reset Form Data
      setPromotionUsersNewData({
        selectedUsers: [],
        removedUsers: [],
      });
      // Reset errors
      setErrors([]);
      // Reset filter
      setColumnFilters((prevState: ColumnFiltersState) => [
        ...prevState,
        { id: 'users', value: '' },
      ]);
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.Header>{header}</F.Header>
        {!isError && (
          <UpdatePromotionUsersTable
            tableInstance={tableInstance}
            rowIsDisabled={rowIsDisabled}
            isPromotionChild={!!parentPromotionId}
          />
        )}
        {!!errors.length
          && errors.map((error) => <F.ErrorMessage key={error.type}>{error.message}</F.ErrorMessage>)}
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit} fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default UpdatePromotionUsers;
