/**
 * Package import
 */
import React, { memo } from 'react';
import { Divider } from '@oclock/crumble';
import PropTypes from 'prop-types';

/**
 * Local import
 */
import TimestampMessage from 'src/components/Chats/Channel/Chat/Message/Timestamp';

/**
 * Style
 */
import * as S from './style';

/**
 * Used in compact mode
 * @param {number} time
 * @returns React.reactNode
 */
const TimeSeparator = ({ time }) => (
  <S.TimeSeparatorContainer>
    <Divider>
      <TimestampMessage time={time} />
    </Divider>
  </S.TimeSeparatorContainer>
);

TimeSeparator.propTypes = {
  time: PropTypes.number.isRequired,
};

export default memo(TimeSeparator);
