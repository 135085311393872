/*
 * Package Import
 */

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { PromotionDTO, PromotionFormDataValidated } from 'src/schemas/Entities/Promotion';
import { transformPromotionFormDataToPromotionDTO } from 'src/schemas/Adapters/Promotions/transformPromotionFormDataToPromotionDTO';
import { API_CREATE_PROMOTION, API_POST } from 'src/constants/apiRoutes';
import { toast } from 'react-toastify';

/*
 * Code
 */
export const createPromotion = async (
  promotionFormData: PromotionFormDataValidated,
): Promise<PromotionDTO> => {
  try {
    const promotionDTO = transformPromotionFormDataToPromotionDTO(promotionFormData);

    const payload: MutationPayload = {
      method: API_POST,
      url: API_CREATE_PROMOTION,
      data: promotionDTO,
    };
    const { data: promotionData } = await api(payload);

    return promotionData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
