/*
 * Package Import
 */
import { ZodError, ZodIssue } from 'zod';
import createVanilla from 'zustand/vanilla';
import create from 'zustand';
import { persist } from 'zustand/middleware';

/*
 * Local Import
 */
import type { Client } from 'src/schemas/Entities/User';
import type { ZodValidationFailure, ZodValidationSuccess } from 'src/schemas/Adapters/Users/utils';

/**
 * Types
 */
export type State = {
  client: Client | null;
  newUsers: (ZodValidationSuccess | ZodValidationFailure)[];
};

/**
 * Store
 */
export const store = createVanilla(
  persist(
    (): State => ({
      client: null,
      newUsers: [],
    }),
    {
      name: 'local-state',
      deserialize: (str) => {
        const storage = JSON.parse(str);
        return {
          ...storage,
          state: {
            ...storage.state,
            newUsers: storage.state.newUsers.map((user: { error: { issues: ZodIssue[] } }) => ({
              ...user,
              error: new ZodError(user.error.issues),
            })),
          },
        };
      },
    },
  ),
);

export const useStore = create(store);
