import { Row } from '@tanstack/react-table';
import { parse } from 'date-fns';
import { PromotionRow } from 'src/components/Admin/Pages/Promotion/PromotionTable/hooks/usePromotionTable';
import { ZodValidationUser } from 'src/schemas/Adapters/Users/utils';
import { Course } from 'src/schemas/Entities/Course';
import { dateToNumberValue, isAfterOrEqual, isBeforeOrEqual } from 'src/utils/time';

export const sortDateTable = (
  rowA: Row<PromotionRow> | Row<Course>,
  rowB: Row<PromotionRow> | Row<Course>,
  columnId: string,
) => {
  if (!rowA.original || !rowB.original) {
    return 0;
  }
  const dateA = parse(rowA.getValue(columnId), 'dd/MM/yyyy', new Date());
  const dateB = parse(rowB.getValue(columnId), 'dd/MM/yyyy', new Date());
  return dateToNumberValue(dateA) - dateToNumberValue(dateB);
};

export const noMatchCaseSort = ({ valueA, valueB }: { valueA: string; valueB: string }) => {
  const stringA = valueA.toLowerCase();
  const stringB = valueB.toLowerCase();

  const strings = [stringA, stringB];
  strings.sort();
  return strings.indexOf(stringA) - strings.indexOf(stringB);
};

export const sortCsvErrors = (
  rowA: Row<ZodValidationUser>,
  rowB: Row<ZodValidationUser>,
): number => {
  const scoreA = rowA.original?.success ? 1 : 0;
  const scoreB = rowB.original?.success ? 1 : 0;

  return scoreA - scoreB;
};

export const dateFilterMethod = (
  row: Row<PromotionRow> | Row<Course>,
  columnId: string,
  filterValue: {
    start: Date;
    end: Date;
  },
) => {
  const formattedRowDateValue = parse(row.getValue(columnId), 'dd/MM/yyyy', new Date());
  if (filterValue.start) {
    if (filterValue.end) {
      return (
        isAfterOrEqual(formattedRowDateValue, filterValue.start)
        && isBeforeOrEqual(formattedRowDateValue, filterValue.end)
      );
    }
    return isAfterOrEqual(formattedRowDateValue, filterValue.start);
  }
  if (filterValue.end) {
    return isBeforeOrEqual(formattedRowDateValue, filterValue.end);
  }
  return true;
};
