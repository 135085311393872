/*
 * Package Import
 */
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useReducer } from 'react';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import Modal from 'src/components/VM/Modal';

// Context / Hooks
import { UserContext } from 'src/context/User';
import { useGetVirtualDesktopStatus } from 'src/components/VM/hooks';

// Helpers
import * as utils from 'src/utils/roles';
import { trackEvent } from 'src/utils/tracking';
import {
  makeRequest,
  getFullVmAddress,
  getUnresolvedStatus,
  getTranslatedStatus,
} from 'src/utils/virtualMachine';

// Constants
import { ACTION_VM, STATUS_VM } from 'src/constants/virtualDesktop';
import { VM_TITLE } from 'src/constants/titles';

// Style
import * as S from './style';

/*
 * Reducer
 */
const virtualMachineReducer = (state, action) => {
  switch (action.type) {
    case 'set_data': {
      return {
        ...state,
        hostname: action.hostname,
        endpoints: action.endpoints,
        current_vm: {
          type: action.vm_type,
          type_translate: action['vm_type_desc-fr'],
        },
      };
    }

    case 'set_modal': {
      return {
        ...state,
        modal: {
          label: action.label,
          display: action.display,
        },
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

/*
 * Component
 */
const ManagementVM = ({ actions, isMainPage, status }) => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  /**
   * Hooks
   */
  const { pathname } = useLocation();

  /**
   * Context
   */
  const { getStatus } = useGetVirtualDesktopStatus({
    setVirtualDesktopStatus: actions.setVirtualDesktopStatus,
  });

  /*
   * State
   */
  const [state, dispatch] = useReducer(virtualMachineReducer, {
    /** @type {String} */
    hostname: null,
    /** @type {Object} */
    current_vm: {},
    /** @type {Array} */
    endpoints: [],
  });

  /**
   * Get the data of the virtual machine.
   * @return {Promise}
   */
  const getData = () =>
    makeRequest({ user, url: 'status/' }).then(({ data }) => {
      dispatch({ type: 'set_data', ...data });
    });

  /**
   * If we need to refresh the status of the virtual machine, on demand.
   * @return {void}
   */
  const refresh = async () => {
    await getStatus();
    await getData();
  };

  /**
   * Start the current virtual machine
   * @return {Promise}
   */
  const startMachine = async () => {
    actions.setVirtualDesktopStatus(STATUS_VM.INSTALLING);

    // Params
    const params = {};

    if (user.githubProfile) {
      params.github = user.githubProfile;
    }

    await makeRequest({ user, url: 'start/', params });
    await getStatus();
  };

  /**
   * Stop the current virtual machine
   * @return {Promise}
   */
  const stopMachine = async () => {
    actions.setVirtualDesktopStatus(STATUS_VM.STOPPING);
    await makeRequest({ user, url: 'stop/' });
    await getStatus();
  };

  /**
   * Restart the current virtual machine
   * @return {Promise}
   */
  const rebootMachine = async () => {
    actions.setVirtualDesktopStatus(STATUS_VM.INSTALLING);
    await makeRequest({ user, url: 'reboot/' });
    await getStatus();
  };

  /**
   * Display the modal
   * @param {String} label
   * @return {void}
   */
  const onDisplayModal = (label) => () => {
    dispatch({ type: 'set_modal', label, display: true });
  };

  /**
   * Close modal
   * @return {void}
   */
  const onCloseModal = () => {
    dispatch({ type: 'set_modal', label: null, display: null });
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    // Get data of the current virtual desktop
    if (status && !getUnresolvedStatus(status)) {
      getData();
    }
  }, [status]);

  useEffect(() => {
    if (isMainPage) {
      document.title = VM_TITLE;

      // Tracking event
      trackEvent('Page Viewed', { title: VM_TITLE, url: pathname });
    }
  }, []);

  /**
   * Partials render • Status
   * @param {String} status
   * @return
   */
  const statusRenderer = (statusRequest) => {
    // 🌀 Loading
    if (!statusRequest || statusRequest === STATUS_VM.PENDING) {
      return <Loader overlay size={64} />;
    }

    // ❌ Have we an error ?
    if (statusRequest === STATUS_VM.ERROR) {
      return (
        <S.Button type="button" onClick={refresh} aria-label="Recharger la page">
          Une erreur est survenue, lors de l’appel. <br />
          Merci de rafraîchir la page
        </S.Button>
      );
    }

    // 🆕 The virtual machine is not found ?
    if (statusRequest === STATUS_VM.NOT_FOUND) {
      return (
        <div>
          <Button
            variant="primary"
            type="button"
            icon="Plus"
            onClick={onDisplayModal(ACTION_VM.CREATE)}
            aria-label="Lancer la création (environ 10 minutes)"
            aria-haspopup="true"
            aria-expanded={state?.modal?.label === ACTION_VM.CREATE && state?.modal?.display}
            // kinda dirty, might need a solution in crumble
            style={{ display: 'flex', marginLeft: 'auto' }}
          >
            Nouveau bureau virtuel
          </Button>
          <S.NoVMO>Aucun bureau virtuel</S.NoVMO>
        </div>
      );
    }

    // The virtual machine is find
    if (statusRequest && !getUnresolvedStatus(statusRequest)) {
      // Granted to delete virtual machine
      const canDelete = utils.isAdmin(user?.role)
        || utils.isSuperAdmin(user?.role)
        || utils.isSupervisor(user?.role);

      /**
       * Status Props
       */
      const isRunning = statusRequest === STATUS_VM.RUNNING;
      const isStopped = statusRequest === STATUS_VM.STOPPED;
      const isStopping = statusRequest === STATUS_VM.STOPPING;
      const isInstalling = statusRequest === STATUS_VM.INSTALLING;

      /**
       * Render
       */
      return (
        <S.VMContainer>
          {/* Informations */}
          <S.Informations>
            <S.Subtitle>
              <S.Info />
              Informations
            </S.Subtitle>
            <ul>
              <li>Votre bureau virtuel s’éteint automatiquement après 1 heure d’inactivité.</li>
            </ul>
          </S.Informations>

          {/* Administration */}
          <S.Box>
            <S.BoxHeader>
              <S.Subtitle>Votre bureau virtuel</S.Subtitle>
              <Button variant="text" onClick={refresh} aria-label="Actualiser">
                <S.Redo />
                Actualiser
              </Button>
            </S.BoxHeader>
            <S.Machine>
              <S.Item>
                <S.Label>Nom</S.Label>
                <S.Value>{state.hostname || getFullVmAddress(user.id)}</S.Value>
              </S.Item>
              <S.Item>
                <S.Label>Type</S.Label>
                <S.Value>{state.current_vm?.type_translate || state.current_vm?.type}</S.Value>
              </S.Item>
              <S.Item>
                <S.Label>Statut</S.Label>
                <S.Status status={status}>{getTranslatedStatus(status)}</S.Status>
              </S.Item>
            </S.Machine>
          </S.Box>

          {/* Endpoints */}
          {!!state?.endpoints?.length && isRunning && (
            <S.Box>
              <S.Subtitle>Connexion</S.Subtitle>
              <S.Endpoints>
                {state.endpoints.map((endpoint) => (
                  <S.Point key={`key-${endpoint.url}`}>
                    <S.Link href={endpoint.url} target="_blank" rel="noopener noreferrer">
                      {endpoint['name-fr'] || endpoint.name}
                    </S.Link>
                  </S.Point>
                ))}
              </S.Endpoints>
            </S.Box>
          )}

          {/* Actions */}
          <S.Box>
            <S.Subtitle>Actions</S.Subtitle>
            <ul>
              {/* Stop */}
              {Boolean(isRunning || isStopping) && (
                <S.ButtonWrapper>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    onClick={onDisplayModal(ACTION_VM.STOP)}
                    aria-label="Arrêter le bureau virtuel"
                    disabled={isStopping}
                  >
                    {isStopping ? (
                      <>
                        <span style={{ marginRight: '0.5rem' }}>
                          <Loader size={24} />
                        </span>
                        Arrêt en cours…
                      </>
                    ) : (
                      <>
                        <S.Pause aria-hidden="true" />
                        Arrêter
                      </>
                    )}
                  </Button>
                </S.ButtonWrapper>
              )}

              {/* Start */}
              {Boolean(isStopped || isInstalling) && (
                <S.ButtonWrapper>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    onClick={startMachine}
                    aria-label="Démarrer le bureau virtuel"
                    disabled={isInstalling || isStopping}
                  >
                    {isInstalling || isStopping ? (
                      <>
                        <span style={{ marginRight: '0.5rem' }}>
                          <Loader size={24} />
                        </span>
                        Démarrage en cours...
                      </>
                    ) : (
                      <>
                        <S.Play aria-hidden="true" />
                        Démarrer
                      </>
                    )}
                  </Button>
                </S.ButtonWrapper>
              )}

              {/* Restart */}
              {isRunning && (
                <S.ButtonWrapper>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    onClick={onDisplayModal(ACTION_VM.REBOOT)}
                    aria-label="Redémarrer le bureau virtuel"
                    disabled={isInstalling || isStopping}
                  >
                    {isInstalling || isStopping ? (
                      <>
                        <span style={{ marginRight: '0.5rem' }}>
                          <Loader size={24} />
                        </span>
                        Redémarrage en cours…
                      </>
                    ) : (
                      <>
                        <S.Sync aria-hidden="true" />
                        Redémarrer
                      </>
                    )}
                  </Button>
                </S.ButtonWrapper>
              )}

              {/* Reinstall */}
              <S.ButtonWrapper>
                <Button
                  fullWidth
                  size="large"
                  variant="danger"
                  onClick={onDisplayModal(ACTION_VM.REINSTALL)}
                  aria-label="Réinstaller le bureau virtuel"
                  aria-haspopup="true"
                  aria-expanded={
                    state?.modal?.label === ACTION_VM.REINSTALL && state?.modal?.display
                  }
                >
                  <S.SyncExclamation aria-hidden="true" />
                  Réinstaller
                </Button>
              </S.ButtonWrapper>

              {/* Delete */}
              {canDelete && (
                <S.ButtonWrapper>
                  <Button
                    fullWidth
                    size="large"
                    variant="danger"
                    onClick={onDisplayModal(ACTION_VM.DELETE)}
                    aria-label="Supprimer le bureau virtuel"
                    aria-haspopup="true"
                    aria-expanded={
                      state?.modal?.label === ACTION_VM.DELETE && state?.modal?.display
                    }
                  >
                    <S.TrashAlt aria-hidden="true" />
                    Supprimer
                  </Button>
                </S.ButtonWrapper>
              )}
            </ul>
          </S.Box>
        </S.VMContainer>
      );
    }

    return null;
  };

  /*
   * Render
   */
  return (
    <S.Container role="main">
      {isMainPage && (
        <S.Header>
          <S.Title id="virtual-machine-create">Bureau virtuel</S.Title>
        </S.Header>
      )}

      {/* Partials render • Status */}
      <S.VMStatus>{statusRenderer(status)}</S.VMStatus>

      {/* Modal */}
      {state?.modal?.display && (
        <Modal
          label={state.modal.label}
          onCloseModal={onCloseModal}
          // Status
          setStatus={actions.setVirtualDesktopStatus}
          getStatus={getStatus}
          // Actions
          rebootMachine={rebootMachine}
          stopMachine={stopMachine}
        />
      )}
    </S.Container>
  );
};

/**
 * PropTypes
 */
ManagementVM.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Component is a page, or is merged with an another part of the app ? */
  isMainPage: PropTypes.bool,
  /** */
  status: PropTypes.string,
};

ManagementVM.defaultProps = {
  isMainPage: true,
  status: null,
};

/*
 * Export
 */
export default ManagementVM;
