/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useCallback, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTheme } from '@emotion/react';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Content from 'src/components/Chats/Channel/Chat/Message/Content/container';
import Reactions from 'src/components/Chats/Channel/Chat/Message/Reactions';
import Actions from 'src/components/Chats/Channel/Chat/Message/Actions';
import EditMessage from 'src/components/Chats/Channel/Chat/Input/Edit/container';
import DeleteMessage from 'src/components/Chats/Channel/Chat/Message/Delete/container';
import { triggerLocations } from 'src/constants/tracking';

// Helpers
import { useBoundingRectObserver } from 'src/hooks';

// Style
import * as S from './style';
import Infos from './Infos';

/*
 * Component
 */
const Message = ({
  actions,
  chatAppearance,
  clientId,
  user,
  onReactionChanged,
  ...messageProps
}) => {
  const { chatId, messageId, next, pinned, status, time, userId, isAsked, isAnswered, isSpoiler } = messageProps;
  const theme = useTheme();

  const [editing, setEditing] = useState(
    /** @type {Boolean} · Edit state */
    false,
  );

  const [deleting, setDeleting] = useState(
    /** @type {Boolean} · Delete state */
    false,
  );

  /*
   * Refs
   */
  const reactionRef = useRef(messageProps?.reactions?.length);
  const messageRef = useRef();

  /*
   * Hooks
   */
  // Hook returning bounding rectangle of message element
  // We can avoid one useless observer with second param to false
  const [messageBoundingRect, messageLastBoundingRect] = useBoundingRectObserver(
    messageRef,
    isSpoiler,
  );

  /**
   * setDelete
   * @return {void}
   */
  const setDelete = useCallback(() => {
    setDeleting(true);
  }, []);

  /**
   * clearDelete
   * @return {void}
   */
  const clearDelete = useCallback(() => {
    setDeleting(false);
  }, []);

  /**
   * setEdit
   * @return {void}
   */
  const setEdit = useCallback(() => {
    setEditing(true);
  }, []);

  /**
   * clearEdit
   * @return {void}
   */
  const clearEdit = useCallback(() => {
    setEditing(false);
  }, []);

  /**
   * handleUsername
   * @return {void}
   */
  const handleUsername = () => {
    actions.openChat(userId, triggerLocations.GLOBAL_CHAT);
  };

  const handleCopy = useCallback(() => {
    if (messageRef) {
      const content = messageRef.current.querySelector('.message-content');

      // Create range to select text
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNode(content);
      selection.removeAllRanges();
      selection.addRange(range);

      // Execute command to copy selection and remove text selection
      document.execCommand('copy');
      selection.removeAllRanges();

      // Notification snackbar
      actions.confirmCopy(user?.name || messageProps?.userId || 'Utilisateur inconnu');
    }
  }, []);

  const infos = !next ? (
    <Infos
      chatAppearance={chatAppearance}
      handleUsername={handleUsername}
      pinned={pinned}
      time={time}
      user={user}
      userId={messageProps?.userId}
    />
  ) : null;

  /*
   * LifeCycles
   */
  useEffect(() => {
    // eslint-disable-next-line max-len
    const hasChanged = messageProps?.reactions?.length !== reactionRef.current;

    if (hasChanged) {
      reactionRef.current = messageProps.reactions.length;
    }

    if (hasChanged) {
      onReactionChanged?.({ chatId, messageId, list: messageProps.reactions });
    }
  }, [messageProps?.reactions]);

  // Used for spoiler who overflow under scroll
  useLayoutEffect(() => {
    // If not messageBoundingRect : too soon or just second argument of hook is false
    // If not messageAncientBoundingRect : to avoid to trigger if not really a resize
    if (
      messageBoundingRect
      && messageLastBoundingRect
      && messageLastBoundingRect.height !== messageBoundingRect.height
    ) {
      const appHeight = window.innerHeight || document.documentElement.clientHeight;
      // If top position of element + his height > height of app - height of chat input
      const scrolled = messageBoundingRect.top + messageBoundingRect.height > appHeight - 150;
      if (scrolled && messageRef.current) {
        // We let this message position himself in bottom of chat
        messageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [messageBoundingRect]);

  /*
   * Render
   */
  if (!messageProps?.content) {
    return null;
  }

  return (
    <S.MessageContainer chatAppearance={chatAppearance} next={next}>
      {/* Delete modal */}
      {deleting && (
        <DeleteMessage
          deleting={deleting}
          clearDelete={clearDelete}
          chatId={chatId}
          messageId={messageId}
          messageUserId={userId}
        />
      )}

      {/* Message */}
      {messageProps && (
        <S.Message
          className="messages"
          ref={messageRef}
          id={`message-${messageId}`}
          role="listitem"
          next={next}
          editing={editing}
          themeName={theme.themeName}
        >
          {chatAppearance === 'spaced' && infos}

          {/* Edit input */}
          {editing && (
            <EditMessage
              clearEdit={clearEdit}
              currentMessage={messageProps}
              editing={editing}
              messageId={messageId}
              messageUserId={userId}
            />
          )}

          {/* Content */}
          {!editing && (
            <S.Content className="message-content">
              <Content infos={infos} {...messageProps} />
            </S.Content>
          )}

          {/* Button Actions */}
          <Actions
            messageId={messageId}
            messageUserId={userId}
            status={status}
            userId={clientId}
            handleDelete={setDelete}
            handleEdit={setEdit}
            handleCopy={handleCopy}
            isAnswered={isAnswered}
            isAsked={isAsked}
            pinned={pinned}
          />

          {/* Reactions */}
          <Reactions messageId={messageId} userId={clientId} reactions={messageProps.reactions} />

          {/* Flag */}
          {pinned && chatAppearance === 'spaced' && (
            <S.Icon>
              <S.Pinned />
            </S.Icon>
          )}
        </S.Message>
      )}
    </S.MessageContainer>
  );
};

/*
 * PropTypes
 */
Message.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** On which channel the message is displayed */
  chatId: PropTypes.string.isRequired,
  /** Chat appearance ('spaced' | 'compact') */
  chatAppearance: PropTypes.string,
  /** Client ID ··· QUESTION: Keep clientId here or replace in actions ? */
  clientId: PropTypes.string.isRequired,
  /** The next message is from the same author ? */
  next: PropTypes.bool,
  /** The message is pinned ? */
  pinned: PropTypes.bool,
  /** List of reactions */
  reactions: PropTypes.array,
  /** Reaction changed handler */
  onReactionChanged: PropTypes.func,
  /** Message timestamp */
  time: PropTypes.number.isRequired,
  /** Message author */
  user: PropTypes.object.isRequired,
};

Message.defaultProps = {
  chatAppearance: 'spaced',
  next: false,
  onReactionChanged: null,
  pinned: false,
  reactions: [],
};

Message.displayName = 'Message';

/*
 * Export
 */
export default React.memo(Message, isEqual);
