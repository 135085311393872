/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  getPedaOptions,
  getPromoOptions,
  getPromoOptionsTooltipsInfos,
} from 'src/components/Admin/Modals/FormElements/utils';
import { getMinDate, getMaxDate, getDefaultTeacher } from './utils';

/*
 * Code
 */
export const coursesAddFormData = {
  header: 'Nouveau cours',
  inputs: {
    title: {
      title: 'title',
      display: 'Titre',
      type: 'text',
    },
    date: {
      title: 'date',
      display: 'Date',
      type: 'date',
      getMinDate,
      getMaxDate,
    },
    selectPromotion: {
      title: 'promotion',
      display: 'Promotion',
      isMulti: false,
      getPromoOptions,
      getPromoOptionsTooltipsInfos,
    },
    selectTeacher: {
      title: 'teacher',
      display: 'Formateur',
      isMulti: false,
      selectToWatch: 'helpers',
      getPedaOptions,
      getDefaultTeacher,
    },
    selectHelpers: {
      title: 'helpers',
      display: 'Helpers',
      isMulti: true,
      selectToWatch: 'teacher',
      getPedaOptions,
    },
  },
};
