/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const User = styled.div`
  display: contents;
  line-height: 150%;
  font-size: 22px;
  font-weight: bold;
`;
export const Text = styled.div`
  overflow: hidden;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.4px;
  text-align: left;
  line-height: 150%;
  display: contents;
`;
