/*
 * Package Import
 */

/*
 * Local Import
 */
import type { User } from '../Entities/User';
import type { MongoId } from '../Entities/utils';

/*
 * Code
 */
export const getUserFromId = ({
  users,
  id,
}: {
  users: User[] | undefined;
  id: MongoId | null;
}): User | undefined => {
  const user = users?.find((promo) => promo.id === id);
  return user;
};
