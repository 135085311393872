/*
 * Package Import
 */
import { utils as themeUtils } from '@o-clock-dev/theme-oclock';

/*
 * Code
 */

/*
 * Export
 */
export const utils = {
  /*
   * Utils functions from `theme-oclock`
   */
  ...themeUtils,

  /*
   *
   */
};
