/*
 * Package Import
 */
import produce from 'immer';
import { AnyAction } from 'redux';
import { StreamListStatus } from 'src/constants/mediaDevices';

/*
 * Local import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface WebRTCState {
  connected: boolean;
  localWebcamStreamId: string | null;
  localScreenStreamId: string | null;
  antmediaLocalStream: boolean;
  isMyCamAndMicPublishing: boolean;
  streamList: {
    cams: string[];
    screen: string[];
  };
  streamListStatus: {
    cams: StreamListStatus;
    screen: StreamListStatus;
  };
}

/*
 * Initial state
 */
const initialState: WebRTCState = {
  connected: false,
  localWebcamStreamId: null,
  localScreenStreamId: null,
  antmediaLocalStream: false,
  isMyCamAndMicPublishing: false,
  streamListStatus: {
    cams: StreamListStatus.INIT,
    screen: StreamListStatus.INIT,
  },
  streamList: {
    cams: [],
    screen: [],
  },
};

/*
 * Reducer
 */

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.WEBRTC_CONNECT:
        draft.connected = action.status;
        if (action.status) {
          draft.localWebcamStreamId = action.localWebcamStreamId;
          draft.localScreenStreamId = action.localScreenStreamId;
        }
        else {
          draft.localWebcamStreamId = null;
          draft.localScreenStreamId = null;
        }
        break;

      case types.SET_STREAM_LIST:
        if (action.streamType === 'cams' || action.streamType === 'screen') {
          const stream: 'cams' | 'screen' = action.streamType;
          draft.streamList[stream] = action.streamList;
        }
        break;

      case types.SET_MY_CAM_AND_MIC_PUBLISHING:
        draft.isMyCamAndMicPublishing = action.isMyCamAndMicPublishing;
        break;

      case types.SET_STREAM_LIST_STATUS:
        if (action.streamType === 'cams' || action.streamType === 'screen') {
          const stream: 'cams' | 'screen' = action.streamType;
          draft.streamListStatus[stream] = action.status;
        }
        break;

      case types.ANTMEDIA_LOCALSTREAM_READY:
        draft.antmediaLocalStream = action.isLocalStreamReady;
        break;

      default:
        return state;
    }
  });
