/**
 * Package Import
 */
import { useTheme } from '@emotion/react';
import { ControlProps, StylesConfig, GroupTypeBase } from 'react-select';

/**
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import { Option } from 'src/components/Admin/Modals/FormElements/type';

const getSmallLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.smallLabel;

const getControlBorderStyles = (
  state: ControlProps<Option, boolean, GroupTypeBase<Option>>,
  theme: ThemeInterface,
): string => {
  if (state.selectProps?.isError) {
    return `2px solid ${theme.danger.main}`;
  }
  if (state.isFocused) {
    return `2px solid ${theme.primary._1}`;
  }
  return `1px solid ${theme.text._7}`;
};

const useStyles = (activeData?: Option[]): StylesConfig<Option, boolean> => {
  const theme = useTheme();

  const colourStyles: StylesConfig<Option, boolean> = {
    menu: (styles) => ({
      ...styles,
      borderRadius: '8px',
      overflow: 'hidden',
      marginTop: '.25rem',
      border: `1px solid ${theme.text._7}`,
      background: theme.background._2,
      ...theme.shadows.plus2,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: '.25rem',
    }),
    container: (styles) => ({
      ...styles,
      borderRadius: '7px',
    }),
    control: (styles, state) => ({
      ...styles,
      border: getControlBorderStyles(state, theme),
      boxShadow: 'none',
      borderRadius: '8px',
      minHeight: '2.5rem',
      padding: state.isFocused ? 0 : '1px', // to avoid moving content when hover
      color: theme.text._1,
      cursor: 'pointer',
      transition: 'none',
      background: theme.background._2,
      ...getSmallLabelTypoStyle(theme),

      ':hover': {
        color: theme.text._1,
        backgroundColor: theme.background._8,
        border: `2px solid ${theme.text._6}`,
        padding: 0, // to avoid moving content when hover
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxWidth: '13.5rem',
      overflow: 'visible',
      padding: '0 0 0 calc(.75rem - 1px)', // 1px added with control
    }),
    singleValue: (styles) => ({
      ...styles,
      margin: 0,
      color: 'none',
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      maxWidth: '6rem',
      color: theme.colors.coolGrey._900,
      backgroundColor: activeData?.find((element) => element?.value === data.value)
        ? theme.background._5
        : theme.danger.background,
      borderRadius: '5px',
      ':hover': {
        color: theme.text._1,
      },
      ...getSmallLabelTypoStyle(theme),
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: activeData?.find((element) => element?.value === data.value)
        ? theme.text._1
        : theme.danger.main,

      ...getSmallLabelTypoStyle(theme),
    }),
    option: (styles, { isDisabled, data }) => ({
      ...styles,
      ...getSmallLabelTypoStyle(theme),
      background: 'none',
      color: isDisabled ? theme.text._7 : theme.text._2,
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: `.5rem ${data.isNested ? '1.5rem' : '.75rem'}`,
      ':hover': {
        backgroundColor: theme.background._8,
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: theme.text._5,
      paddingRight: 'calc(.8rem - 1px)', // 1px added with control
      ':hover': {
        color: theme.text._5,
      },
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: theme.text._7,
      ':hover': {
        color: theme.text._1,
      },
      svg: {
        width: '1rem',
        height: '1rem',
      },
    }),
    multiValueRemove: (styles, { data }) => {
      const isActive = activeData?.find((element) => element?.value === data.value);
      return {
        ...styles,
        color: isActive ? theme.text._1 : theme.danger.background,
        ':hover': {
          color: isActive ? theme.text._1 : theme.danger.background,
        },
        svg: {
          width: '.75rem',
          height: '.75rem',
          backgroundColor: isActive ? theme.background._7 : theme.danger.main,
          borderRadius: '48px',
        },
      };
    },
    indicatorSeparator: (styles, { isMulti, hasValue }) => ({
      ...styles,
      ...((!isMulti || (isMulti && !hasValue)) && { display: 'none' }),
    }),
  };

  return colourStyles;
};

export default useStyles;
