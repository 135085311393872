/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Code
 */
export const promotionUsersFormData = {
  header: 'Participants',
  formErrors: {
    noAction: {
      type: 'noAction',
      message: 'Vous devez sélectionner ou retirer au moins un utilisateur',
    },
  },
};
