import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const ErrorMessage = () => (
  <S.ErrorMessageContainer>
    <S.ExclamationIcon /> Impossible d&apos;afficher ce message
  </S.ErrorMessageContainer>
);

const ErrorNetworkMessage = ({ onRetry, retry }) => (
  <S.ErrorNetworkMessageContainer retry={retry} onClick={onRetry}>
    <S.ExclamationIcon /> Votre message n&apos;a pas pu être envoyé, <span>réessayer</span>
  </S.ErrorNetworkMessageContainer>
);

ErrorNetworkMessage.propTypes = {
  onRetry: PropTypes.func.isRequired,
  retry: PropTypes.bool.isRequired,
};

export { ErrorMessage, ErrorNetworkMessage };
