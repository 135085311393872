/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import QuillInput from 'src/components/Chats/Channel/Chat/Input/QuillInput';
import { getMentions } from 'src/store/selectors/users';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  mentions: getMentions(state),
});

// Actions
const mapDispatchToProps = {};

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(QuillInput);
