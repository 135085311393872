/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Header = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.coolGrey._200}`};
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
`;

export const HeaderTitle = styled.div`
  align-items: center;
  display: flex;
`;

export const Icon = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.info._200};
  color: ${({ theme }) => theme.colors.info._600};
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.text._1};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.09px;
  margin-left: 0.5rem;
`;

export const Content = styled.div`
  padding: 0.5rem 0.75rem;
`;

export const Success = styled.button(({ isGreen, isIcon, theme }) => ({
  ...base.button({ isGreen, isIcon, theme }),
  ...theme.typo.labels.smallLabel,
  padding: '.25rem',
  minHeight: 'unset',
  borderRadius: '4px',
  height: '32px',
  width: '32px',
}));

export const Container = styled.div(({ chatAppearance, theme }) => ({
  border: `1px solid ${theme.colors.info._200}`,
  borderRadius: '8px',

  ...(chatAppearance === 'compact' && {
    [Header]: {
      [Icon]: {
        height: '24px',
        width: '24px',

        svg: {
          height: '16px',
          width: '16px',
        },
      },
      [Success]: {
        height: '24px',
        width: '24px',
      },
    },

    [Content]: {
      padding: '0.25em 0.5em',
    },
  }),
}));
