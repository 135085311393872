/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Delete from 'src/components/Chats/Channel/Chat/Message/Delete';
import { deleteMessage } from 'src/store/actions';

/*
 * Code
 */
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ deleteMessage }, dispatch),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(Delete);
