/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Menu as MenuInner } from '@szhsin/react-menu';
import {
  menuDividerSelector,
  menuItemSelector,
  menuSelector,
  submenuSelector,
} from '@szhsin/react-menu/style-utils';

import {
  UilApps,
  UilBars,
  UilBell,
  UilCheck,
  UilCoffee,
  UilCog,
  UilCommentNotes,
  UilCommentsAlt,
  UilExclamationTriangle,
  UilKeyboard,
  UilLifeRing,
  UilListUl,
  UilMessage,
  UilMoon,
  UilMouseAlt,
  UilQuestionCircle,
  UilSubject,
  UilTextSize,
  UilUsersAlt,
} from '@iconscout/react-unicons';

/**
 * Variables
 */
// Style main icons menu
const iconStylePrimary = () => ({
  color: 'inherit',
  height: '1.25rem',
  width: '1.25rem',
});

/**
 * Styling for the menu
 * https://codesandbox.io/s/react-menu-emotion-styled-2l35s
 */
export const Menu = styled(MenuInner)(({ theme }) => ({
  // Menus
  [menuSelector.name]: {
    minWidth: '20rem',
    marginLeft: '0.25rem',
    marginTop: '1rem',
    padding: '0.5rem',
    borderRadius: '1rem',
    border: 'none',
    background: theme.background._2,
    color: theme.text._1,
    ...theme.shadows.plus2,
  },

  // MenuDivider
  [menuDividerSelector.name]: {
    border: `1px solid ${theme.background._4}`,
    width: '92%',
    margin: '0.5rem auto',
  },

  // MenuItem
  [menuItemSelector.name]: {
    display: 'flex',
    margin: '0',
    padding: '0 0.5rem',
    borderRadius: '0.5rem',
    ...theme.typo.labels.label,
  },
  [menuItemSelector.hover]: {
    color: theme.text._10,
    background: theme.primary._1,

    svg: {
      color: theme.text._10,
    },
  },

  [submenuSelector.name]: {
    [menuSelector.name]: {
      marginTop: 0,
      marginLeft: '0.5rem',
    },
  },
}));

// Container of label
export const ContainerLabel = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  align-items: center;
  display: flex;
`;

// Used for menu item with custom content (e.g.: <TextSize />...)
export const MenuItemContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '0.5rem',
});

/**
 * ICONES
 */

// Icon Main menu
export const IconMenu = styled(UilBars)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${({ theme }) => theme.text._1};
`;

// Icon checkBox
export const IconCheck = styled(UilCheck)`
  color: ${({ theme }) => theme.primary._2};
  display: ${({ isactive }) => (isactive === 'true' ? 'block' : 'none')};
`;

// Pause
export const IconCoffee = styled(UilCoffee)({
  ...iconStylePrimary(),
});

// Interface
export const IconCog = styled(UilCog)({
  ...iconStylePrimary(),
});

// Notifications
export const IconBell = styled(UilBell)({
  ...iconStylePrimary(),
});

// Aides
export const IconQuestionCircle = styled(UilQuestionCircle)({
  ...iconStylePrimary(),
});

// Signaler un problème
export const IconExclamationTrianglee = styled(UilExclamationTriangle)({
  ...iconStylePrimary(),
});

// Apparence du chat
export const IconChatAppearance = styled(UilCommentNotes)({
  ...iconStylePrimary(),
});

// Largeur du chat
export const IconChatSize = styled(UilCommentsAlt)({
  ...iconStylePrimary(),
});

// Taille du texte
export const IconTextSize = styled(UilTextSize)({
  ...iconStylePrimary(),
});

// Affichage des participants
export const IconUsersAlt = styled(UilUsersAlt)(({ theme }) => ({
  ...iconStylePrimary(theme),
}));

export const IconApps = styled(UilApps)(({ theme }) => ({
  ...iconStylePrimary(theme),
}));

export const IconListUl = styled(UilListUl)(({ theme }) => ({
  ...iconStylePrimary(theme),
}));

// Sous-titres
export const IconTranscription = styled(UilSubject)({
  ...iconStylePrimary(),
});

// Mode nuit
export const IconMoon = styled(UilMoon)({
  ...iconStylePrimary(),
});

// Raccourcis clavier
export const IconKeyboard = styled(UilKeyboard)({
  ...iconStylePrimary(),
});

// tutioriel
export const IconMouseAlt = styled(UilMouseAlt)({
  ...iconStylePrimary(),
});

// Support technique
export const IconLifeRing = styled(UilLifeRing)({
  ...iconStylePrimary(),
});

// Nous contacter
export const IconMessage = styled(UilMessage)({
  ...iconStylePrimary(),
});

export const TitleSrOnly = styled.h2(({ theme }) => ({
  ...theme.srOnly,
}));
