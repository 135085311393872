/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
interface TranscriptionDataProps {
  id: string;
  courseId: string;
  userId: string;
  text: string;
  isFinal: boolean;
  date: Date;
}

interface TranscriptionState {
  initialized: boolean;
  data: TranscriptionDataProps[];
  previousId: string | null;
}

/*
 * Initial State
 */
const initialState: TranscriptionState = {
  initialized: false,
  data: [],
  previousId: null,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.TRANSCRIPTION_INIT: {
        draft.initialized = true;
        break;
      }

      // Event from WS
      case types.RECEIVE_TRANSCRIPTION: {
        const newData = [...draft.data];
        const index = draft.data.findIndex((elmt) => elmt.id === action.data.previousId);
        if (index > -1) {
          newData[index] = action.data;
        }
        else {
          newData.push(action.data);
        }
        draft.data = newData;
        break;
      }

      case types.SET_PREVIOUS_ID:
        draft.previousId = action.id;
        break;

      default:
        return state;
    }
  });
