/**
 * Package Import
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import { getOrganization } from 'src/store/selectors/organization';
import devicesMediasRTC from 'src/utils/medias';
import {
  MediaStreamDictionnary,
  StreamCategory,
} from 'src/utils/webRTCManager/mediaStreamDictionnary';

// Constants
import { HAND_STATUS } from 'src/constants/conference';

// Style
import * as S from './style';

/**
 * Component
 */
const ConferenceItem = ({
  actions,
  clientId,
  courseId,
  handStatus,
  peerId,
  soundEnabled,
  streamList,
  webRTCConnected,
  isLocalStreamReady,
}) => {
  /*
   * Code
   */
  const { orga } = useSelector(getOrganization);

  /**
   * Refs
   */
  const audioRef = useRef();

  /**
   * Vars
   */
  const targetStreamId = `${orga}-${courseId}-${peerId}-cams`;

  /**
   * LifeCycles
   */
  useEffect(() => {
    if (webRTCConnected && isLocalStreamReady && peerId === clientId) {
      // Unraised the hand if student has the voice given
      if (handStatus === HAND_STATUS.VOICE_GIVEN) {
        actions.sendTakeVoice(clientId);
      }

      if (handStatus === HAND_STATUS.RAISED) {
        actions.sendUnraisedHand(clientId);
      }

      if (handStatus === HAND_STATUS.UNRAISED) {
        // Mute your microphone by default
        if (devicesMediasRTC.hasMicrophonePermissions) {
          actions.disableMicrophone();
        }

        // Start stream
        // Transcription connection useless
        // since it will be handled by microphone
        // TRICKS TO PREVENT ANTMEDIA PUBLISH TIMEOUT
        setTimeout(() => {
          actions.startMyWebcamStream({ audioOnly: true });
        }, 0);
      }
    }

    return () => {
      // Clean up
      if (peerId === clientId) {
        actions.stopMyWebcamStream();
        actions.disconnectTranscription();
      }
    };
  }, [webRTCConnected, handStatus, isLocalStreamReady]);

  useEffect(() => {
    // When the available streams list is updated put the stream in the video element
    const mediaStreamObject = MediaStreamDictionnary.getStream(StreamCategory.CAM, targetStreamId);

    if (mediaStreamObject) {
      audioRef.current.srcObject = mediaStreamObject;
    }
  }, [streamList]);

  /**
   * Render
   */
  return (
    <S.Audio
      id={peerId === clientId ? 'localView' : targetStreamId}
      autoPlay
      controls
      ref={audioRef}
      muted={!soundEnabled}
    >
      <track kind="captions" />
    </S.Audio>
  );
};

/**
 * Types
 */
ConferenceItem.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  courseId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  handStatus: PropTypes.string.isRequired,
  streamList: PropTypes.array.isRequired,
  peerId: PropTypes.string.isRequired,
  soundEnabled: PropTypes.bool.isRequired,
  webRTCConnected: PropTypes.bool.isRequired,
  isLocalStreamReady: PropTypes.bool.isRequired,
};

/**
 * Export
 */
export default ConferenceItem;
