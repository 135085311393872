/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import { CONTROLS_ID } from 'src/constants/controls';
import * as S from './style';

/*
 * Component
 */
const Action = ({ id, label, isActive, disabled, onClick, title, description }) => {
  /*
   * Variables
   */
  const Icon = S.Icon({ id, isActive });
  const IconLarge = S.IconLarge({ id, isActive });

  /**
   * Enable/Disable media
   */
  const handleActionClick = (evt) => {
    if (disabled) {
      return;
    }

    const isObject = !!isActive;

    // Check if isActive is a bool (always false as a bool) for id "hand"
    // or if is an Object (should be an object if teacher is connected)
    if (id === CONTROLS_ID.HAND && !isObject) {
      return;
    }

    onClick(evt.currentTarget.id);
  };

  /**
   * Content Tooltip
   */
  const TooltipContent = () => (
    <S.TooltipContentContainer>
      <S.IconContainer>
        <Icon aria-hidden="true" />
      </S.IconContainer>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Content>
    </S.TooltipContentContainer>
  );

  /*
   * Render
   */
  return (
    // If you update the ID below, don't forget to update the `customParent` property of
    // `src/components/Course/Controls/Modal/index`, otherwise the `hand` modal will no longer work
    <S.ActionContainer id={`${id}-container`}>
      <Tooltip size="large" content={<TooltipContent />}>
        <S.Action
          onClick={handleActionClick}
          id={id}
          isActive={isActive}
          isDisabled={disabled}
          aria-label={label}
          aria-pressed={isActive}
          aria-disabled={disabled}
        >
          <IconLarge aria-hidden="true" />
        </S.Action>
      </Tooltip>
      <S.Label aria-hidden isDisabled={disabled}>
        {label}
      </S.Label>
    </S.ActionContainer>
  );
};

/*
 * PropTypes
 */
Action.propTypes = {
  /** Action id */
  id: PropTypes.string.isRequired,
  /** Is action disabled ? */
  disabled: PropTypes.bool,
  /** Text under action button */
  label: PropTypes.string.isRequired,
  /** Click/Unclick on action button */
  onClick: PropTypes.func.isRequired,
  /** Has button been clicked ? */
  isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** Title of tooltip */
  title: PropTypes.string.isRequired,
  /** Descrption of tooltip */
  description: PropTypes.string,
};

Action.defaultProps = {
  disabled: false,
  description: '',
};

/*
 * Export
 */
export default Action;
