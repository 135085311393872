/*
 * Package Import
 */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

/*
 * Local Import
 */
import { animations, zIndex } from 'src/theme';

/*
 * KeyFrames
 */
const overlayBg = keyframes(animations.overlayBg);

/*
 * Style
 */
export const Overlay = styled.div(
  {
    fontFamily: 'Inter !important',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    animation: `${overlayBg} .4s both`,
    display: 'flex',
  },
  ({ type }) => ({
    position: type,
    zIndex: type === 'fixed' ? zIndex.courseOverlay : zIndex.chatOverlay,
  }),
);

export const Close = styled.button(({ theme }) => ({
  animation: 'popIn .2s .8s both',
  color: theme.colors.monochrome.white,
  cursor: 'pointer',
  position: 'absolute',
  top: '1.5em',
  right: '2em',
  transition: '.2s',

  '::before': {
    content: '"✕"',
  },
}));
