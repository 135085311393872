/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Course from 'src/components/Course';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  hasScreenShared: state.course.medias.screenEnabled,
});

// Actions
const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Course);
