/**
 * Formatting button options
 * @type {Array}
 */
export default [
  {
    id: 'code-block',
    icon: 'CodeIcon',
    name: 'Bloc de code',
  },
  {
    id: 'bold',
    icon: 'BoldIcon',
    name: 'Gras',
  },
  {
    id: 'italic',
    icon: 'ItalicIcon',
    name: 'Italique',
  },
];
