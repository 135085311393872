/**
 * Package Import
 */
import Delta from 'quill-delta';
import { Quill } from 'react-quill';

/**
 * Local Import
 */
import { MessageContentProps } from 'src/utils/types';

/**
 * Get if message is empty
 */
export const isMessageEmpty = (value: MessageContentProps): boolean =>
  !value
  || !value.ops?.length
  || (value.ops?.length === 1 && (value.ops[0].insert === '\n' || value.ops[0].insert.trim() === ''));

/**
 * Inserts emoji in react quill editor
 * @param {Quill} editor - QuillInput ref's editor
 * @param {string} emoji - emoji.colons property (e.g.: ':smile:')
 */
export function insertEmoji(editor: Quill, emoji: string) {
  const range = editor.getSelection(true);

  /**
   * Il y a un soucis de typage entre `react-quill` qui attends un DeltaStatic
   * et `quill-delta` qui renvoie un Delta, d'où le 'any'.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const delta: any = new Delta().retain(range.index).delete(range.length).insert(`${emoji}`);

  editor.updateContents(delta, 'user');
  editor.setSelection(range.index + 1, 0, 'user');
}
