/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilChart, UilPaperclip, UilArrow, UilBold, UilItalic } from '@iconscout/react-unicons';

/*
 * Icons
 */
const iconStyle = (theme) => ({
  color: `${theme.text._5} !important`,
  height: '1.25rem',
  width: '1.25rem',
});

export const PollIcon = styled(UilChart)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const DocumentIcon = styled(UilPaperclip)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const CodeIcon = styled(UilArrow)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const BoldIcon = styled(UilBold)(({ theme }) => ({
  ...iconStyle(theme),
  height: '1.875rem',
  width: '1.875rem',
}));

export const ItalicIcon = styled(UilItalic)(({ theme }) => ({
  ...iconStyle(theme),
  height: '1.875rem',
  width: '1.875rem',
}));

/*
 * Style
 */
export const Composer = styled.ul(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  marginTop: '0.5rem',
  overflow: 'hidden',
  border: 'none !important',

  '& button': {
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    flex: '0 0 2.5rem !important',
    position: 'relative',
    height: '2.5rem !important',
    width: '2.5rem !important',
    maxHeight: '2.5rem',
    maxWidth: '2.5rem',
    marginRight: '0.25rem',
    background: `${theme.background._2} !important`,
    borderRadius: '8px',
    padding: '.5rem',

    ':hover': {
      transition: 'background 0.2s',
      background: `${theme.background._4} !important`,
    },

    ...theme.focusVisible,

    '&.ql-active': {
      background: `${theme.primary._3} !important`,
      svg: {
        color: theme.primary._1,
      },
    },
  },
}));
