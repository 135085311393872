/*
 * Package Import
 */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Composer from 'src/components/Chats/Channel/Chat/Input/Composer/container';
import QuillInput from 'src/components/Chats/Channel/Chat/Input/QuillInput/container';

// Helpers
import { useMessage } from 'src/components/Classroom/hooks';
import { isEscape } from 'src/utils/events';
import { insertEmoji, isMessageEmpty } from '../utils';

// Style
import * as S from './style';

/*
 * Component
 */
const EditMessage = ({ actions, clearEdit, currentMessage, messageId, messageUserId }) => {
  const { editMessage } = useMessage();
  const quillRef = useRef(null);

  /**
   * Close edit on cancel button click
   */
  const handleCancel = useCallback(() => {
    clearEdit?.();
  }, [clearEdit]);

  /**
   * Insert selected emoji to message
   */
  const handleEmojiSelected = useCallback((emoji) => {
    if (quillRef.current) {
      quillRef.current.focus();
      insertEmoji(quillRef.current.editor, emoji.colons);
    }
  }, []);

  /**
   * Handle code blocks
   */
  const handleKeyboard = useCallback((shortcut) => {
    if (quillRef.current) {
      const currentFormats = quillRef.current.editor.getFormat();

      switch (shortcut) {
        case 'code-block': {
          const isCodeBlock = currentFormats['code-block'];
          quillRef.current.editor.format('code-block', !isCodeBlock, 'user');
          break;
        }
        default:
          break;
      }
    }
  }, []);

  /**
   * Handle pressing escape key to exit editing mode
   */
  const handleKeyDown = useCallback(
    (event) => {
      if (isEscape(event)) {
        clearEdit?.();
      }
    },
    [clearEdit],
  );

  /**
   * Submit edited message
   */
  const handleSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault();

      const value = quillRef.current.editor.getContents();

      if (isMessageEmpty(value)) return;

      const payload = {
        messageId,
        messageUserId,
        newMessage: value,
      };

      actions.editMessage(payload);
      editMessage(payload);

      clearEdit?.();
    },
    [clearEdit, editMessage],
  );

  /**
   * Set message content as input value & focus input
   */
  useEffect(() => {
    quillRef.current?.editor.setContents(currentMessage.content);
    // This sets cursor at the end of the quill input value
    quillRef.current?.editor.setSelection(quillRef.current?.editor.getLength(), 0);
    quillRef.current?.focus();
  }, []);

  return (
    <S.Form onSubmit={handleSubmit}>
      {/* Input */}
      <QuillInput
        // React
        myRef={quillRef}
        handleSubmit={handleSubmit}
        handleKeyboard={handleKeyboard}
        handleKeyDown={handleKeyDown}
        // HTML
        aria-label="Envoyer un message"
        placeholder="Message"
        // QuillJS
        toolbarSelector="#edit-toolbar"
      />

      {/* Composer */}
      <Composer id="edit-toolbar" onEmojiSelected={handleEmojiSelected} />

      {/* Footer */}
      <S.Footer>
        <S.Button type="button" onClick={handleCancel} isCancel>
          Annuler
        </S.Button>
        <S.Button type="submit" isMain>
          Enregistrer
        </S.Button>
      </S.Footer>
    </S.Form>
  );
};

EditMessage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Function to exit the edit state */
  clearEdit: PropTypes.func,
  /** Function to exit the edit state */
  currentMessage: PropTypes.object.isRequired,
  /** ID of message's creator */
  messageUserId: PropTypes.string.isRequired,
  /** Message ID */
  messageId: PropTypes.string.isRequired,
};

EditMessage.defaultProps = {
  clearEdit: null,
};

export default EditMessage;
