/*
 * Package Import
 */
import { fonts as themeFonts } from '@o-clock-dev/theme-oclock';

/*
 * Weights
 */
export const weights = {
  ...themeFonts,
};
