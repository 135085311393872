/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */

// Style
import { badges } from 'src/components/Users/data/roles';

/*
 * Component
 */
const Badge = ({ role }) => {
  /*
   * State
   */

  const BadgeIcon = badges[role];

  /*
   * Render
   */

  return <BadgeIcon />;
};

/*
 * PropTypes
 */
Badge.propTypes = {
  role: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Badge;
