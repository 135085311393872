import styled from '@emotion/styled';

type AdditionalProps = {
  customColumnStyle?: Record<string, string>;
};
export const TableCell = styled.td<AdditionalProps>(({ theme, customColumnStyle }) => ({
  fontSize: '1rem',
  display: 'table-cell',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
  ...(customColumnStyle || {}),
}));

export const TableCellMenu = styled.td(({ theme }) => ({
  fontSize: '1rem',
  display: 'table-cell',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
}));

export const TableCellHeader = styled.th(({ theme }) => ({
  fontSize: '1rem',
  position: 'relative',
  display: 'table-cell',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
}));

export const TableCellFooter = styled.td(({ theme }) => ({
  fontSize: '1rem',
  display: 'table-cell',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
}));

export const TableCellMenuHeader = styled.th(({ theme }) => ({
  fontSize: '1rem',
  position: 'relative',
  display: 'table-cell',
  borderBottom: `1px solid ${theme.text._7}`,
  textAlign: 'left',
  padding: '1rem',
}));

export const HeaderContainer = styled.div(() => ({
  display: 'flex',
}));

export const Header = styled.span(() => ({
  fontSize: '1rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
}));

export const TableCellTitle = styled.td(({ theme }) => ({
  fontSize: '1rem',
  width: '100%',
  borderBottom: `1px solid ${theme.text._7}`,
  display: 'table-cell',
  padding: '0.5rem',
}));
