/*
 * Package Import
 */
import React, { useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  ColumnDef,
  Row,
  Table,
} from '@tanstack/react-table';

/*
 * Local Import
 */
import CsvImportTable from 'src/components/Admin/Tables/CsvImportTable';
import * as T from 'src/components/Admin/TableElements';
import { sortCsvErrors } from 'src/utils/table';
import { ZodValidationUser } from 'src/schemas/Adapters/Users/utils';
import { Informations, AnomaliesInformations } from '../Informations';
import { EditableCell } from '../EditableCell';

/*
 * Code
 */
const RowCheckbox = ({ row }: { row: Row<ZodValidationUser> }) => <T.CsvImportCheckbox row={row} />;

const RowInformations = ({ row }: { row: Row<ZodValidationUser> }) => <Informations row={row} />;

const RowAnomalies = ({
  row,
  table,
}: {
  row: Row<ZodValidationUser>;
  table: Table<ZodValidationUser>;
}) =>
  !row.original?.success && <AnomaliesInformations row={row} allColumns={table.getAllColumns()} />;

const useCsvImportTable = (newData: ZodValidationUser[] = []) => {
  // anomalies sort
  const sortAnomalies = useMemo(() => sortCsvErrors, []);

  // Columns configuration
  const columns: ColumnDef<ZodValidationUser>[] = useMemo(
    () => [
      {
        id: 'selection',
        header: '',
        cell: RowCheckbox,
      },
      {
        id: 'info',
        header: '',
        cell: RowInformations,
      },
      {
        id: 'anomalies',
        header: '',
        cell: RowAnomalies,
      },
      {
        id: 'id',
        accessorFn: (row) => row.data.id,
        sortingFn: sortAnomalies,
      },
      {
        id: 'firstname',
        header: 'Prénom',
        accessorFn: (row) => row.data.firstname,
        cell: EditableCell,
      },
      {
        id: 'lastname',
        header: 'Nom',
        accessorFn: (row) => row.data.lastname,
        cell: EditableCell,
      },
      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.data.email,
        cell: EditableCell,
      },
    ],
    [sortAnomalies],
  );

  const initialState = {
    columnVisibility: {
      id: false,
    },
    sorting: useMemo(
      () => [
        {
          id: 'id',
          desc: false,
        },
      ],
      [],
    ),
  };

  const tableInstance = useReactTable({
    columns,
    data: newData,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  return [tableInstance, CsvImportTable] as const;
};

/*
 * Export
 */
export default useCsvImportTable;
