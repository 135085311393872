/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilTimes } from '@iconscout/react-unicons';
import Modal from 'src/components/Modal';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Code
 */
export const ModalContainer = styled(Modal)((props) => ({
  position: 'absolute',
  minWidth: '400px',
  borderRadius: '16px',
  left: '-10.2rem',
  top: 'auto !important',
  bottom: '7rem',
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  background: `${props.theme.background._2}`,
  border: `1px solid ${props.theme.text._7}`,
  outline: 'none',
  ...props.theme.shadows.plus3,
}));

export const Title = styled.h1((props) => ({
  textAlign: 'center',
  marginBottom: '.5rem',
  color: `${props.theme.text._1}`,
  ...props.theme.typo.headlines.H5,
}));

export const Text = styled.p(({ theme }) => ({
  color: theme.text._5,
  textAlign: 'center',
  marginBottom: '.5rem',
  ...theme.typo.bodyText.smallBody,
}));

export const Status = styled.p(({ theme, status }) => ({
  ...theme.typo.labels.smallLabel,
  textAlign: 'center',
  margin: '0 auto',
  borderRadius: '25px',
  width: 'fit-content',
  padding: '.5rem .75rem',
  background: status === 'voiceGiven' ? theme.success.background : theme.info.background,
  color: status === 'voiceGiven' ? theme.success.main : theme.info.main,
}));

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5rem;
`;

export const CancelButton = styled.button({}, ({ isCancel, theme }) => ({
  ...base.button({ isCancel, theme }),
  marginRight: '1rem',
  flex: 1,
}));

export const StopButton = styled.button({}, ({ isMain, theme }) => ({
  ...base.button({ isMain, theme }),
  flex: 1,
}));

export const Close = styled.button(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  top: '0.75rem',
  right: '0.75rem',
  borderRadius: '4px',
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ':hover': {
    background: theme.background._8,
  },

  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const StyledUilClose = styled(UilTimes)((props) => ({
  color: props.theme.text._5,
}));
