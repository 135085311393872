/**
 * Local Import
 */
import { Option } from 'src/components/Admin/Modals/FormElements/type';

/**
 * Statuses
 */
export const statusStrings = {
  AWAY: 'En pause',
  PRESENT: 'En ligne',
  DISCONNECTED: 'Hors-ligne',
};

/**
 * Options
 */
export const ongoing: Option = {
  value: 'ongoing',
  label: 'En cours',
};
export const coming: Option = {
  value: 'coming',
  label: 'À venir',
};
export const finished: Option = {
  value: 'finished',
  label: 'Terminé',
};

export const status = [ongoing, coming, finished];
export const defaultStatusFilterValues = status.map((option) => option.label);

export const getIsRegisteredStrings = (isRegistered: boolean) => {
  const isRegisteredStrings = {
    registered: {
      statusString: 'Inscrit',
      tooltipString: 'Cet utilisateur est déjà inscrit.',
    },
    notRegistered: {
      statusString: 'Non inscrit',
      tooltipString: "Cet utilisateur n'est pas encore inscrit.",
    },
  };
  return isRegistered ? isRegisteredStrings.registered : isRegisteredStrings.notRegistered;
};

export const getAccessVMStrings = (canCreateVM: boolean) => {
  const accesVMStrings = [
    {
      statusString: 'Oui',
      tooltipString: 'Cet utilisateur peut créer une machine virtuelle.',
    },
    {
      statusString: 'Non',
      tooltipString: 'Cet utilisateur ne peut pas créer de machine virtuelle.',
    },
  ];
  return canCreateVM ? accesVMStrings[0] : accesVMStrings[1];
};
