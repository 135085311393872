/*
 * Package import
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import Hotkeys from 'react-hot-keys';
import PropTypes from 'prop-types';

import { getGlobalHotKeys } from 'src/utils/shortcuts';
import { isMac } from 'src/utils/navigator';
import * as keys from 'src/constants/shortcuts';

/*
 * Component
 */
const HotkeysListener = ({ state }) => {
  /**
   * Context
   */

  /*
   * Variables
   */
  const dispatch = useDispatch();
  const theme = useTheme();

  const namesKey = Object.entries(keys)
    .map((key) => [key[0], isMac() ? key[1].replace('ctrl', 'command') : key[1]])
    .toString();

  /*
   * Handlers
   */
  // eslint-disable-next-line no-unused-vars
  const handleKeyDown = (keyName, evt) => {
    getGlobalHotKeys(evt, dispatch, state, theme);
  };

  /*
   * View
   */
  return <Hotkeys keyName={namesKey} splitKey="_" onKeyDown={handleKeyDown} />;
};

HotkeysListener.propTypes = {
  state: PropTypes.object.isRequired,
};

HotkeysListener.defaultProps = {};

/*
 * Export
 */
export default HotkeysListener;
