/*
 * Package Import
 */
import React from 'react';
import Loader from 'src/components/Loader';

/*
 * Local Import
 */
import useWrapClient from '../hooks/useWrapClient';
import { WrappedClient } from '../hooks/WrappedClient';
import Unauthorized from '../Unauthorized';
import * as S from './style';

/*
 * Code
 */
type GiveAccessProps = {
  children: JSX.Element | null;
  when: (client: WrappedClient) => boolean;
};
const GiveAccess = ({ when, children }: GiveAccessProps): JSX.Element | null => {
  const wrappedClient = useWrapClient();

  if (!wrappedClient) {
    return (
      <S.LoaderContainer>
        <Loader type="Oval" size={96} />
      </S.LoaderContainer>
    );
  }

  if (wrappedClient && !when(wrappedClient)) {
    return <Unauthorized />;
  }

  return children;
};

/*
 * Export
 */
export default GiveAccess;
