/*
 * Package Import
 */

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { transformPromotionsDTOToPromotions } from 'src/schemas/Adapters/Promotions/transformPromotionDTOToPromotion';
import { api, Payload } from 'src/utils/api';
import { API_FIND_PROMOTIONS, API_GET } from 'src/constants/apiRoutes';
import { toast } from 'react-toastify';

import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
export const fetchPromotions = async (): Promise<Promotion[]> => {
  try {
    const payload: Payload = {
      method: API_GET,
      url: API_FIND_PROMOTIONS,
    };
    const { data: promotionsData } = await api(payload);

    const { promotions, errors } = transformPromotionsDTOToPromotions(promotionsData);

    if (errors.length) {
      const errorsMessages = errors.map(
        (errorWithData) =>
          `Promotion with name ${errorWithData.data.name} validation problem : ${
            errorWithData.error.message
          }.
          Click here to correct : ${window.location.origin}${getRoute('promotionsUpdate').getUrl({
  id: errorWithData.data.id,
})}`,
      );
      // eslint-disable-next-line no-console
      console.error(`${errorsMessages.join('\n')}\n Please contact support`);
      toast(
        'Nous avons détecté des erreurs de validation sur les données reçues, le détail se trouve dans la console',
        { type: 'warning', toastId: 'query' },
      );
    }

    return promotions;
  }
  catch (error) {
    toast(`Erreur pendant le chargement des données : ${error}`, {
      type: 'error',
      toastId: 'fetch',
    });
    throw error;
  }
};
