/**
 * Package Import
 */
import * as z from 'zod';

/**
 * Local Import
 */
import * as refines from 'src/schemas/Refines/courses';
import { dateToNumberValue } from 'src/utils/time';
import { getPluralizeFunc } from 'src/utils/string';
import { mongoIdSchema, dateParams, EdusignStatusSchema, getIntervalMessages } from './utils';

export const promotionRelationSchema = z.object({
  id: mongoIdSchema,
  name: z.string(),
  displayName: z.string().optional(),
  start: z.date(),
  end: z.date(),
  deactivatedAt: z.date().optional(),
});

export const userRelationSchema = z.object({
  id: mongoIdSchema,
  firstname: z
    .string()
    .nonempty('Le prénom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le prénom doit être rempli' }),
  lastname: z
    .string()
    .nonempty('Le nom doit être rempli')
    .refine((data) => data.trim().length !== 0, { message: 'Le nom doit être rempli' }),
  deactivatedAt: z.date().optional(),
});

export const courseDTOSchema = z.object({
  id: mongoIdSchema,
  title: z.string(),
  promoId: mongoIdSchema,
  date: z.string(),
  teacherId: mongoIdSchema,
  helpersIds: z.array(mongoIdSchema.nullable()),
  timeslots: z.array(
    z.object({
      _id: mongoIdSchema.optional(),
      start: z.string(),
      end: z.string(),
      edusignSheetId: z.string().optional(),
    }),
  ),
  deactivatedAt: z.string().optional(),
  edusignStatus: EdusignStatusSchema.optional(),
});

export const courseDTOSchemaWithoutId = courseDTOSchema.omit({ id: true });

export const courseSchema = z
  .object({
    id: mongoIdSchema,
    title: z.string(),
    promotion: promotionRelationSchema,
    date: z.date(),
    teacher: userRelationSchema.refine(refines.courseTeacherMustBePeda, {
      message: 'Le professeur doit avoir un rôle pédagogique : Superviseur, Admin ou SuperAdmin',
    }),
    helpers: z.array(userRelationSchema.nullable()).refine(refines.courseHelpersMustBePeda, {
      message: 'Les helpers doivent avoir un rôle pédagogique : Superviseur, Admin ou SuperAdmin',
    }),
    timeslots: z.array(z.object({ _id: mongoIdSchema.optional(), start: z.date(), end: z.date() })),
    deactivatedAt: z.date().optional(),
  })
  .refine(
    (data) =>
      dateToNumberValue(data.date) >= dateToNumberValue(data.promotion.start)
      && dateToNumberValue(data.date) <= dateToNumberValue(data.promotion.end),
    {
      message: 'La date du cours ne correspond pas aux dates de la promotion',
      path: ['date'],
    },
  );

export const courseFormDataSchema = z
  .object({
    id: mongoIdSchema.optional(),
    title: z
      .string()
      .nonempty('Le titre doit être rempli')
      .refine((data) => data.trim().length !== 0, {
        message: 'Le titre doit être rempli',
      }),
    date: z
      .string()
      .nonempty('La date doit être remplie')
      .regex(...dateParams),
    promotion: z
      .object({
        value: mongoIdSchema,
        label: z.string(),
      })
      .nullable() // we use refine to overload error message
      .refine((data) => data !== null, {
        message: 'Il faut choisir une promotion',
      })
      // Check if promotion is active
      .refine(refines.courseFormPromotionMustBeActive, {
        message: 'La promotion choisie est archivée',
      }),
    teacher: z
      .object({
        value: mongoIdSchema,
        label: z.string(),
      })
      .nullable() // we use refine to overload error message
      .refine((data) => data !== null, {
        message: 'Il faut choisir un professeur',
      })
      .refine(refines.courseFormUserMustBePeda, {
        message: 'Le professeur doit avoir un rôle pédagogique : Superviseur, Admin ou SuperAdmin',
      })
      .refine(refines.courseFormUserMustBeActive, {
        message: "L'utilisateur choisi est archivé",
      }),
    helpers: z.array(
      z
        .object({
          value: mongoIdSchema,
          label: z.string(),
        })
        .nullable()
        .refine(refines.courseFormUserMustBePeda, {
          message:
            'Les helpers doivent avoir un rôle pédagogique : Superviseur, Admin ou SuperAdmin',
        })
        .refine(refines.courseFormUserMustBeActive, {
          message: "L'utilisateur choisi est archivé",
        }),
    ),
    timeslots: z
      .array(
        z
          .object({
            _id: z.union([mongoIdSchema, z.string().length(0)]),
            start: z
              .string()
              .refine(refines.isCourseTimeslotMinZero, {
                message: "L'horaire minimal est 00:00",
              })
              .refine(refines.isCourseTimeslotLessThanTwentyFour, {
                message: "L'horaire maximal est 23:59",
              }),
            end: z
              .string()
              .refine(refines.isCourseTimeslotMinZero, {
                message: "L'horaire minimal est 00:00",
              })
              .refine(refines.isCourseTimeslotLessThanTwentyFour, {
                message: "L'horaire maximal est 23:59",
              }),
          })
          .refine(refines.courseTimeslotMustBeFilled, {
            message: 'Les horaires de début et de fin doivent être remplis',
          })
          .refine(refines.courseTimeslotStartMustBeFilled, {
            message: "L'horaire de début doit être rempli",
          })
          .refine(refines.courseTimeslotEndMustBeFilled, {
            message: "L'horaire de fin doit être rempli",
          })
          .refine(refines.isTimeslotStartBeforeEnd, {
            message: "L'heure de fin doit être postérieure à l'heure de début de la séance",
          }),
      )
      .nonempty()
      .superRefine((data, errors) => {
        const timeslotsWithError = refines.areTimeslotsOverlaping(data);
        timeslotsWithError.forEach((timeslotIndex) => {
          errors.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: 'Les séances ne peuvent pas se chevaucher',
            path: [timeslotIndex],
          });
        });
      }),
  })
  .refine(refines.courseFormDateMustBeInPromotionDates, {
    message: 'La date du cours ne correspond pas aux dates de la promotion',
    path: ['promotion'],
  })
  .superRefine((data, errors) => {
    // Get timeslots error data to display errors messages
    const timeslotsWithError = refines.isCourseFormTimeslotExceedSimultaneousCourseLimitation(data);
    const quantityIsZero = refines.isSimultaneousCourseLimitationIsZero();

    // If at least one error detected
    if (timeslotsWithError.length !== 0 || quantityIsZero) {
      const plural = getPluralizeFunc(timeslotsWithError[0].limitationQuantity);
      // we add one general error to invite to subscribe at one custom path
      errors.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message: `Avec votre abonnement, vous pouvez créer ${
          timeslotsWithError[0].limitationQuantity
        } classe${plural('s')} virtuelle${plural('s')} simultanée${plural(
          's',
        )}. Veuillez modifier votre abonnement ou modifier vos séances.`,
        path: ['limitationSimultaneousCourses'],
      });
    }
    // And for each timeslot in error
    timeslotsWithError.forEach((timeslotWithError) => {
      // We prepare message with details of intervals in conflict
      const intervalMessages = getIntervalMessages(timeslotWithError.intervals);
      const plural = getPluralizeFunc(timeslotWithError.intervals.length);
      errors.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message: `Cette séance est en conflit sur le${plural('s')} créneau${plural(
          'x',
        )} ${intervalMessages}. Veuillez la modifier.`,
        path: [`timeslots[${timeslotWithError.index}]`],
      });
    });
  })
  .superRefine((data, errors) => {
    const limitReached = refines.isMonthlyHoursCoursesLimitReached(data);

    if (limitReached) {
      const { limit, hours, minutes } = limitReached;

      errors.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Vous ne pouvez pas créer ces séances car vous êtes limités à ${limit}h de classe virtuelle par mois. Vous avez consommé ${hours}h et ${minutes}min.`,
        path: ['limitationTotalHours'],
      });
    }
  });

export const courseFormDataValidatedSchema = z.object({
  id: mongoIdSchema.optional(),
  title: z.string().nonempty('Le nom doit être rempli'),
  date: z.string().nonempty('La date doit être remplie'),
  promotion: z.object({
    value: mongoIdSchema,
    label: z.string(),
  }),
  teacher: z.object({
    value: mongoIdSchema,
    label: z.string(),
  }),
  helpers: z.array(
    z
      .object({
        value: mongoIdSchema,
        label: z.string(),
      })
      .nullable(),
  ),
  timeslots: z
    .array(
      z.object({
        _id: z.union([mongoIdSchema, z.string().length(0)]),
        start: z.string().nonempty("L'heure de début doit être remplie"),
        end: z.string().nonempty("L'heure de fin doit être remplie"),
      }),
    )
    .nonempty(),
});

export type CourseDTO = z.infer<typeof courseDTOSchema>;
export type CourseDTOWithoutId = z.infer<typeof courseDTOSchemaWithoutId>;
export type Course = z.infer<typeof courseSchema>;
export type CourseFormData = z.infer<typeof courseFormDataSchema>;
export type CourseFormDataValidated = z.infer<typeof courseFormDataValidatedSchema>;

export type PromotionRelation = z.infer<typeof promotionRelationSchema>;
export type UserRelation = z.infer<typeof userRelationSchema>;
