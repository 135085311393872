/*
 * Package Import
 */
import React from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Typing from 'src/components/Chats/Channel/Chat/Input/Typing';
import Message from 'src/components/Chats/Channel/Chat/Input/Message/container';

// Style
import * as S from './style';

/*
 * Component
 */
const Input = ({ chatId }) => {
  const theme = useTheme();

  return (
    <S.Input textSize={theme.textSize}>
      <S.Inner>
        <Typing chatId={chatId} />
        <Message chatId={chatId} />
      </S.Inner>
    </S.Input>
  );
};

/*
 * PropTypes
 */
Input.propTypes = {
  chatId: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Input;
