/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Mention from 'src/components/Chats/Channel/Chat/Message/Content/Mention';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  const user = state.users.userById[ownProps.data.id];
  const isMe = ownProps.data.id === state.users.client.id;

  return {
    name: user?.name,
    prefix: user?.denotationChar,
    isMe,
  };
};

// Actions
const mapDispatchToProps = null;

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Mention);
