/**
 * Local Import
 */
import { CONFERENCE_MAX_STUDENTS, CONFERENCE_STATUS, HAND_STATUS } from 'src/constants/conference';
import { DESCRIPTIONS, CONTROLS_ID } from 'src/constants/controls';
import ROLES from 'src/constants/roles';

// Helpers
import { isEducator, isGhost, isHelper, isStudent, isTeacher } from 'src/utils/roles';

/**
 * Code
 */
const { UNRAISED, VOICE_GIVEN } = HAND_STATUS;
const { RUNNING } = CONFERENCE_STATUS;

/**
 * Button `Raised Hand` is disabled ?
 * • If we dont have microphone permission.
 * • If the teacher is not connected.
 * • If we're a student and the discussion mode is launched
 */
export const isHandRaisingButtonDisabled = ({
  hasMicrophonePermissions,
  isMaxTracksReached,
  isTeacherConnected,
  conferenceStatus,
  clientRole,
  handStatus,
  isLocalStreamReady,
}) => {
  if (!hasMicrophonePermissions) {
    return true;
  }

  if (!isLocalStreamReady) {
    return true;
  }

  // If limit track reached, and the hand is not raised
  if (isMaxTracksReached && handStatus === UNRAISED) {
    return true;
  }

  // Excluding if we have voice given to allow give voice back
  if (!isTeacherConnected && handStatus === UNRAISED) {
    return true;
  }

  if (!isEducator(clientRole) && conferenceStatus === RUNNING) {
    return true;
  }

  return false;
};

/**
 * Button `Microphone` is disabled ?
 * • If we dont have microphone permission.
 * • If we're a student and we didn't raise our hands, or the discussion mode is not launched.
 * • If we're a ghost and we didn't raise our hands.
 */
export const isMicrophoneButtonDisabled = ({
  hasMicrophonePermissions,
  handStatus,
  conferenceStatus,
  clientRole,
  isLocalStreamReady,
}) => {
  if (!hasMicrophonePermissions) {
    return true;
  }

  if (!isLocalStreamReady) {
    return true;
  }

  if (isStudent(clientRole) && conferenceStatus !== RUNNING && handStatus === UNRAISED) {
    return true;
  }

  if (isGhost(clientRole) && handStatus === UNRAISED) {
    return true;
  }

  return false;
};

/**
 * Button `Webcam` is disabled
 * • If we dont have webcam permission.
 * • If we're an educator and webcam is disabled and webcams limit is reached
 * • If we're not an educator (teacher/helper), and we didn't raise our hands.
 */
export const isWebcamButtonDisabled = ({
  hasWebcamPermissions,
  isMaxTracksReached,
  handStatus,
  clientRole,
  webcamEnabled,
  isLocalStreamReady,
}) => {
  if (!hasWebcamPermissions) {
    return true;
  }

  if (!isLocalStreamReady) {
    return true;
  }

  if (isEducator(clientRole) && !webcamEnabled && isMaxTracksReached) {
    return true;
  }

  if (!isEducator(clientRole) && handStatus !== VOICE_GIVEN) {
    return true;
  }

  return false;
};

/**
 * Button `Conference` is disabled
 * If any student or ghost has voice given
 * (cf. ./container)
 */
export const isAnyoneHasVoiceGiven = ({ peers, peerById }) => {
  // Ghosts have student role in peerById
  const studentsIds = peers?.filter((userId) => peerById[userId]?.role === ROLES.ROLE_STUDENT);
  const studentsWithVoiceGiven = studentsIds.find(
    (studentId) => studentId && peerById[studentId]?.hand === VOICE_GIVEN,
  );
  return !!studentsWithVoiceGiven;
};

/**
 * Get `Hand` button proper tooltips descriptions
 */
export const getHandButtonDescription = ({
  hasMicrophonePermissions,
  isMaxTracksReached,
  isTeacherConnected,
  conferenceStatus,
  clientRole,
  handStatus,
  isLocalStreamReady,
}) => {
  const isDisabled = isHandRaisingButtonDisabled({
    hasMicrophonePermissions,
    isMaxTracksReached,
    isTeacherConnected,
    conferenceStatus,
    clientRole,
    handStatus,
    isLocalStreamReady,
  });
  const { FEATURE_DISABLE, FEATURE_ON, FEATURE_OFF } = DESCRIPTIONS[CONTROLS_ID.HAND];

  if (isDisabled) {
    // If the hand button is disabled because of mic permissions not allowed
    if (!hasMicrophonePermissions) {
      return FEATURE_DISABLE.NO_PERMISSIONS;
    }

    // If limit track reached, and the hand is not raised
    if (isMaxTracksReached && handStatus === UNRAISED) {
      return FEATURE_DISABLE.MAX_VIDEO_TRACKS;
    }

    // If the hand button is disabled because of teacher who isn't connected
    if (!isTeacherConnected) {
      return FEATURE_DISABLE.TEACHER_NOT_CONNECTED;
    }

    // For students and ghosts, if the button is disabled
    // because of audio conference running
    if (!isEducator(clientRole) && conferenceStatus === RUNNING) {
      return FEATURE_DISABLE.AUDIO_CONFERENCE_ON;
    }
  }

  if (handStatus !== UNRAISED) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};

/**
 * Get `Webcam` button proper tooltips descriptions
 */
export const getWebcamButtonDescription = ({
  hasWebcamPermissions,
  isMaxTracksReached,
  handStatus,
  clientRole,
  webcamEnabled,
  isLocalStreamReady,
}) => {
  const isDisabled = isWebcamButtonDisabled({
    hasWebcamPermissions,
    isMaxTracksReached,
    handStatus,
    clientRole,
    webcamEnabled,
    isLocalStreamReady,
  });

  const { FEATURE_DISABLE, FEATURE_ON, FEATURE_OFF } = DESCRIPTIONS[CONTROLS_ID.WEBCAM];

  if (isDisabled) {
    if (!hasWebcamPermissions) {
      // For teacher and helpers, if the webcam button is disabled
      // because of webcam permissions not allowed
      if (isTeacher(clientRole) || isHelper(clientRole)) {
        return FEATURE_DISABLE.NO_PERMISSIONS;
      }

      // For students and ghosts, if the webcam button is disabled
      // because of webcam permissions not allowed
      // but only when they have voice given
      // (else render proper text for voice not given)
      if ((isStudent(clientRole) || isGhost(clientRole)) && handStatus === VOICE_GIVEN) {
        return FEATURE_DISABLE.NO_PERMISSIONS;
      }
    }

    if (isEducator(clientRole) && isMaxTracksReached) {
      return FEATURE_DISABLE.MAX_VIDEO_TRACKS;
    }

    // If the webcam button is disable because of voice which isn't given
    if (handStatus !== VOICE_GIVEN) {
      return FEATURE_DISABLE.VOICE_NOT_GIVEN;
    }
  }

  if (webcamEnabled) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};

/**
 * Get `Microphone` button proper tooltips descriptions
 */
export const getMicrophoneButtonDescription = ({
  hasMicrophonePermissions,
  handStatus,
  conferenceStatus,
  clientRole,
  microphoneEnabled,
  isLocalStreamReady,
}) => {
  const isDisabled = isMicrophoneButtonDisabled({
    hasMicrophonePermissions,
    handStatus,
    conferenceStatus,
    clientRole,
    isLocalStreamReady,
  });
  const { FEATURE_DISABLE, FEATURE_OFF, FEATURE_ON } = DESCRIPTIONS[CONTROLS_ID.MICROPHONE];

  if (isDisabled) {
    if (!hasMicrophonePermissions) {
      // For teacher and helpers, if the mic button is disabled
      // because of mic permissions not allowed
      if (isTeacher(clientRole) || isHelper(clientRole)) {
        return FEATURE_DISABLE.NO_PERMISSIONS;
      }

      // For students, if the mic button is disabled
      // because of mic permissions not allowed
      // but only when conference is running or hand raised at least
      // (else render proper text for voice not given)
      if (isStudent(clientRole) && (conferenceStatus === RUNNING || handStatus !== UNRAISED)) {
        return FEATURE_DISABLE.NO_PERMISSIONS;
      }

      // For ghosts, if the mic button is disabled
      // because of mic permissions not allowed
      // but only when hand is raised or voice given
      // (else render proper text for voice not given)
      if (isGhost(clientRole) && handStatus !== UNRAISED) {
        return FEATURE_DISABLE.NO_PERMISSIONS;
      }
    }

    // For students, if the mic button is disabled
    // because conference isn't running or hand is unraised
    if (isStudent(clientRole) && conferenceStatus !== RUNNING && handStatus === UNRAISED) {
      return FEATURE_DISABLE.VOICE_NOT_GIVEN;
    }

    // For ghosts, if the mic button is disabled
    // because hand is unraised
    if (isGhost(clientRole) && handStatus === UNRAISED) {
      return FEATURE_DISABLE.VOICE_NOT_GIVEN;
    }
  }

  if (microphoneEnabled) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};

/**
 * Get `Sound` button proper tooltips descriptions
 */
export const getSoundButtonDescription = ({ soundEnabled }) => {
  const { FEATURE_OFF, FEATURE_ON } = DESCRIPTIONS[CONTROLS_ID.SOUND];

  if (soundEnabled) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};

/**
 * Get `Screen` button proper tooltips descriptions
 */
export const getScreenButtonDescription = ({ screenEnabled }) => {
  const { FEATURE_OFF, FEATURE_ON } = DESCRIPTIONS[CONTROLS_ID.SCREEN];
  if (screenEnabled) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};

/**
 * Get `Conference` button proper tooltips descriptions
 */
export const getConferenceButtonDescription = ({
  isDisabled,
  conferenceStatus,
  connectedStudents,
}) => {
  const { FEATURE_DISABLE, FEATURE_OFF, FEATURE_ON } = DESCRIPTIONS[CONTROLS_ID.CONFERENCE];

  // If the conference button is disabled
  // because any voice is given
  if (isDisabled) {
    // Feature disabled when CONFERENCE_MAX_STUDENTS is reached
    return connectedStudents.length >= CONFERENCE_MAX_STUDENTS
      ? FEATURE_DISABLE.MAX_STUDENTS
      : FEATURE_DISABLE.VOICE_GIVEN;
  }

  if (conferenceStatus === RUNNING) {
    return FEATURE_OFF;
  }

  return FEATURE_ON;
};
