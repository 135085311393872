/*
 * Package Import
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/**
 * Code
 */
const messageSuccess = {
  false: 'Une erreur s’est produite, veuillez réessayer dans un instant',
  true: 'Modifications enregistrées',
};

/*
 * Component
 */
const Snackbar = ({ snackStatus, status, setSnackStatus }) => {
  /**
   * State
   */
  const [snackOpened, setSnackOpened] = useState(false);

  /**
   * Props
   */
  const failed = snackStatus === 'failed' || status === 'failed';
  const success = snackStatus === 'updated';

  // Handlers
  const handleCloseSnackbar = () => {
    setSnackOpened(false);
    if (status !== 'failed') {
      setSnackStatus('idle');
    }
  };

  // useEffect
  useEffect(() => {
    setSnackOpened(failed || success);
  }, [snackStatus, status]);

  return (
    <S.Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={snackOpened}
      onClose={handleCloseSnackbar}
    >
      <S.Alert failed={failed} onClick={handleCloseSnackbar}>
        {failed ? <S.IconFail /> : <S.IconSuccess />}
        <S.SnackMessage>{messageSuccess[success]}</S.SnackMessage>
        <S.Close onClick={handleCloseSnackbar} />
      </S.Alert>
    </S.Snackbar>
  );
};

/*
 * PropTypes
 */
Snackbar.propTypes = {
  status: PropTypes.string.isRequired,
  snackStatus: PropTypes.string.isRequired,
  setSnackStatus: PropTypes.func.isRequired,
};

export default Snackbar;
