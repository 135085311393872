/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { api } from 'src/utils/api';
import * as S from './style';
import * as P from '../style';

/*
 * Component
 */
const ResetPassword = ({ email }) => {
  // Hooks
  const [passwordSent, setPasswordSent] = useState('idle');

  // Handlers
  const handleClick = () => {
    const request = {
      method: 'POST',
      url: '/users/reset-password',
      data: { email },
    };

    api(request)
      .then(() => {
        setPasswordSent('success');
      })
      .catch(() => {
        setPasswordSent('failed');
      });
  };

  const PasswordStatus = (status) => {
    switch (status) {
      case 'idle':
        return (
          <S.SendPassword type="button" onClick={handleClick}>
            Réinitialiser le mot de passe
          </S.SendPassword>
        );

      case 'success':
        return (
          <S.PasswordSent>
            Nous vous avons envoyé un mail contenant un lien permettant de réinitialiser votre mot
            de passe
          </S.PasswordSent>
        );

      case 'failed':
        return (
          <S.PasswordErr>
            Une erreur s’est produite, veuillez réessayer dans un instant.
          </S.PasswordErr>
        );

      default:
        return null;
    }
  };

  return (
    <S.Container>
      <P.Title>Mot de passe</P.Title>
      {PasswordStatus(passwordSent)}
    </S.Container>
  );
};

/*
 * PropTypes
 */
ResetPassword.propTypes = {
  email: PropTypes.string,
};

ResetPassword.defaultProps = {
  email: '',
};

export default ResetPassword;
