const focusVisible = (primary: Record<string, string>) => ({
  ':focus-visible': {
    border: `2px solid ${primary._1}`,
  },
  ':-moz-focusring': {
    border: `2px solid ${primary._1}`,
  },
});

const focusOutlineVisible = (primary: Record<string, string>) => ({
  ':focus-visible': {
    outline: `2px solid ${primary._1}`,
  },
  ':-moz-focusring': {
    outline: `2px solid ${primary._1}`,
  },
});

export { focusOutlineVisible };
export default focusVisible;
