/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilTimes, UilCommentQuestion, UilBookmark, UilAt } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import MESSAGES from 'src/constants/messages';

/**
 * Icon
 */
const iconStyle = () => ({
  height: '1.5rem',
  width: '1.5rem',
});

export const QuestionIcon = styled(UilCommentQuestion)({
  ...iconStyle(),
});

export const PinIcon = styled(UilBookmark)({
  ...iconStyle(),
});

export const MentionIcon = styled(UilAt)({
  ...iconStyle(),
});

export const CloseIcon = styled(UilTimes)((props) => ({
  color: props.theme.text._5,
}));

/*
 * Style
 */
export const Filter = styled.div(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.text._7}`,
  display: 'flex',
  justifyContent: 'space-between',
  height: '3rem',
  padding: '0.5rem 0.75rem',
  ...theme.shadows.plus1,
}));

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Label = styled.span(
  ({ theme }) => ({
    ...theme.typo.labels.smallLabel,
    marginRight: '0.25rem',
  }),
  ({ theme, visibilityFilter }) => {
    if (visibilityFilter === MESSAGES.SHOW_PINNED || visibilityFilter === MESSAGES.SHOW_MENTIONED) {
      return {
        color: theme.primary._1,
      };
    }

    if (visibilityFilter === MESSAGES.SHOW_QUESTION) {
      return {
        color: theme.info.main,
      };
    }

    return {
      color: theme.text._6,
    };
  },
);

export const CloseButton = styled.button(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  height: '1rem',
  width: '1rem',

  ':hover': {
    background: theme.background._8,
  },

  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const Button = styled.button(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.5rem',
    height: '2rem',
    width: '2rem',

    ':hover': {
      color: theme.primary._1,
    },

    ':focus-visible': {
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },

    ':-moz-focusring': {
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },
  }),
  ({ isActive, theme, visibilityFilter }) => {
    if (isActive) {
      if (
        visibilityFilter === MESSAGES.SHOW_PINNED
        || visibilityFilter === MESSAGES.SHOW_MENTIONED
      ) {
        return {
          color: theme.primary._1,
        };
      }

      if (visibilityFilter === MESSAGES.SHOW_QUESTION) {
        return {
          color: theme.info.main,
        };
      }
    }

    return {
      color: theme.text._5,
    };
  },
);
