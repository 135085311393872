/**
 * Package Import
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import Introduction from 'src/components/Chats/Introduction';
import MESSAGES from 'src/constants/messages';
import { UserContext } from 'src/context/User';

/**
 * Helpers
 */
import { getOpenedChat } from 'src/store/selectors/chats';
import { getUserProps } from 'src/store/selectors/users';

/**
 * Component
 */
const IntroductionWrapper = ({ visibilityFilter }) => {
  const { user } = useContext(UserContext);
  const currentChat = useSelector(getOpenedChat);
  const receiverId = currentChat?.usersIds.find((id) => id !== user.id) || user.id;
  const receiver = useSelector((state) => getUserProps(state, receiverId));

  const name = receiver?.name || '';
  const isMe = receiverId === user.id;
  const isPrivate = currentChat.kind === 'private';

  /**
   * Filtered messages
   */
  if (visibilityFilter === MESSAGES.SHOW_PINNED) {
    return (
      <Introduction
        icon="Bookmark"
        title="Aucun message épinglé"
        paragraph="Le formateur peut épingler des messages et les mettre en avant dans le chat. Ils seront filtrés ici"
      />
    );
  }

  if (visibilityFilter === MESSAGES.SHOW_QUESTION) {
    return (
      <Introduction
        icon="CommentQuestion"
        title="Aucune question en attente"
        paragraph="Vous pouvez poser une question grâce aux options d’envois de messages, ou en utilisant la commande /question avant votre message"
        variant="info"
      />
    );
  }

  if (visibilityFilter === MESSAGES.SHOW_MENTIONED) {
    return (
      <Introduction
        icon="At"
        title="Vous n’avez aucune mention"
        paragraph="Les messages vous mentionnant seront filtrés ici."
      />
    );
  }

  /**
   * Classico messages
   */
  if (isPrivate) {
    return (
      <Introduction
        icon="CommentHeart"
        title={name}
        paragraph={
          isMe
            ? 'Votre espace privé pour vous organiser'
            : 'Vous allez commencer une discussion privée'
        }
      />
    );
  }

  return (
    <Introduction
      icon="CommentHeart"
      title="Soyez le premier à dire bonjour"
      paragraph="Le chat permet de communiquer avec les autres participants"
    />
  );
};

/**
 * PropTypes
 */
IntroductionWrapper.propTypes = {
  visibilityFilter: PropTypes.string.isRequired,
};

/**
 * Export
 */
export default IntroductionWrapper;
