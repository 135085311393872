/**
 * Package Import
 */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Local Import
 */
import ButtonsSelector from 'src/components/TopBar/Menu/SubMenus/ButtonsSelector';
import { userListStyleOptions } from 'src/data/settings';
import { setUserListStyle } from 'src/store/actions';
import { getUserListStyle } from 'src/store/selectors/settings';

// Style
import * as G from '../../../style';

const icons = {
  grid: <G.IconApps aria-hidden="true" />,
  list: <G.IconListUl aria-hidden="true" />,
};

/**
 * Component
 */
const UserListStyle = (props) => {
  const dispatch = useDispatch();
  const userListStyle = useSelector(getUserListStyle);

  const options = userListStyleOptions.map((option) => ({
    ...option,
    icon: icons[option.value],
  }));

  /**
   * Update the user list style
   * @param {string} value
   * @return {void}
   */
  const handleChange = useCallback((value) => {
    dispatch(setUserListStyle(value));
  }, []);

  /**
   * Render
   */
  return (
    <ButtonsSelector
      icon={<G.IconUsersAlt aria-hidden="true" />}
      onChange={handleChange}
      options={options}
      title="Affichage des participants"
      value={userListStyle}
      {...props}
    />
  );
};

/**
 * Export
 */
export default UserListStyle;
