/**
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { Screen } from './Screen/style';

/**
 * Style
 */
export const BottomContainer = styled.div(({ screenSize }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  marginTop: '0.5rem',
  width: '100%',
  transition: 'max-width .1s ease',
  ...(screenSize && { maxWidth: `${screenSize}px` }),
}));

export const Course = styled.div`
  padding: 0.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ScreenContainer = styled.div({
  display: 'flex',
  containerType: 'size',
  containerName: 'screen-container',
  overflow: 'hidden',
  height: '100%',

  '@container screen-container (aspect-ratio > 16/9)': {
    [Screen]: {
      height: '100%',
      width: 'auto',
    },
  },
});

export const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  height: 2em;
`;

export const TitleSrOnly = styled.h2(({ theme }) => ({
  ...theme.srOnly,
}));

export const SubTitleSrOnly = styled.h3(({ theme }) => ({
  ...theme.srOnly,
}));
