/*
 * Package Import
 */
import styled from '@emotion/styled';
import { getRoleColor, getRoleBgColor } from 'src/components/Users/data/roles';

/*
 * Local Import
 */

/*
 * Style
 */

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Role = styled('span')((props) => ({
  display: 'inline-block',
  color: getRoleColor(props, props.roleName),
  background: getRoleBgColor(props, props.roleName),
  margin: '0 0 0 0.5rem',
  padding: '.25rem .5rem',
  borderRadius: '50px',
  ...props.theme.typo.labels.tinyLabel,
}));

export const Line = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  marginRight: '1rem',
});

export const Vous = styled('span')((props) => ({
  display: 'inline-block',
  position: 'relative',
  margin: '0 0 0 0.25rem',
  color: props.theme.text._5,
  ...props.theme.typo.labels.tinyLabel,
}));

export const Name = styled('span')((props) => ({
  display: 'inline-block',
  position: 'relative',
  color: props.theme.text._1,
  margin: '0 0.25rem 0rem 0.5rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '13rem',
  ...props.theme.typo.headlines.H6,
}));
