/*
 * Package Import
 */
import React from 'react';

/*
 * Local import
 */
import chrome from 'assets/images/navigators/chrome.svg';
import edge from 'assets/images/navigators/edge.svg';
import safari from 'assets/images/navigators/safari.svg';
import firefox from 'assets/images/navigators/firefox.svg';

// Style
import * as S from './style';

/*
 * Component
 */
const IncompatibleBrowser = () => (
  <S.Container>
    <S.Title>Votre navigateur n’est pas compatible</S.Title>
    <S.Text>
      Pour pouvoir utiliser Slippers nous vous recommandons d’utiliser l’un des navigateurs suivants
      :
    </S.Text>
    <S.Browsers>
      <S.Browser target="_blank" href="https://www.google.fr/chrome/">
        <S.BrowserLogo src={chrome} />
        <S.BrowserName>Chrome 87+</S.BrowserName>
      </S.Browser>
      <S.Browser target="_blank" href="https://www.microsoft.com/fr-fr/edge">
        <S.BrowserLogo src={edge} />
        <S.BrowserName>Edge 87+</S.BrowserName>
      </S.Browser>
      <S.Browser target="_blank" href="https://www.apple.com/fr/safari/">
        <S.BrowserLogo src={safari} />
        <S.BrowserName>Safari 14+</S.BrowserName>
      </S.Browser>
      <S.Browser target="_blank" href="https://www.mozilla.org/fr/firefox/new/">
        <S.BrowserLogo src={firefox} />
        <S.BrowserName>Firefox 78+</S.BrowserName>
      </S.Browser>
    </S.Browsers>
  </S.Container>
);

/*
 * Export
 */
export default IncompatibleBrowser;
