/**
 * messages
 * @type {Array}
 */
export const messages = [
  'Une p’tite pause, ça fait du bien.',
  'Allez, on souffle un peu.',
  'On va se dégourdir les jambes ?',
  'Quelques minutes de pause bien méritées',
  'Quelques minutes de pause, le temps d’aller se grignoter un truc',
  'Si tu veux aller boire quelque chose ou aller au petit coin, c’est maintenant !',
  'Allez, quelques minutes de pause sans écran, ça peut pas faire de mal !',
  'Break Time!',
  'L’heure du petit café, du pipi, ou autre chose, vous faites ce que vous voulez.',
  'Une p’tite pause pour les yeux, pour pas le regretter ce soir…',
  'Un peu de détente, on n’est pas des chevaux de trait !',
  'Allez, on met le code de coté et on va se reposer quelques minutes',
  'C’est la pause !',
  'Une petite pause le temps de souffler et on repart !',
  'Petite pause pour faire souffler les yeux (ouais, c’est pas possible on sait)',
  'Quand on commence à voir double, c’est le moment de prendre une pause.',
  'La petite pause qui fait du bien !',
  'On se détend, et on s’éloigne des écrans quelques minutes',
  'Quelques minutes de repos : faut savoir décrocher !',
  'Café, thé, petite raclette : on en profite, c’est la pause !',
];

/**
 * lateMessages
 * @type {Array}
 */
export const lateMessages = [
  'A un moment donné, il faut revenir quand même.',
  'C’est plus une pause. A ce niveau là, c’est un congé.',
  'Où es-tu ? La journée n’est pas finie…',
  'Il va falloir songer à revenir.',
  'On n’attend plus que toi.',
  'Elle est bien longue cette pause dis moi.',
  'Euh… t’es encore là ?',
  'En temps normal, la pause est moins longue que le temps de travail.',
  'Quand on dit « prendre une pause » ce n’est pas non plus se barrer 3h !',
  'Dis donc, quand tu prends une pause, tu fais pas semblant.',
  'Tu peux revenir, la formation n’est pas terminée !',
  'Avec des pauses aussi longues, la formation va durer 2 ans.',
  'Bon, tu reviens ? C’est pas pareil sans toi !',
];
