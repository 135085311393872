/*
 * Package Import
 */
import axios, { AxiosResponse } from 'axios';
import sub from 'date-fns/sub';
import { useEffect, useState } from 'react';

/*
 * Local Import
 */
import { getStorage, setStorage } from 'src/utils/storage';

/*
 * Code
 */
export const getOrganization = async (): Promise<Record<string, string>> => {
  try {
    const storedOrganization = getStorage('organization');

    if (storedOrganization.fetchedAt) {
      const peremptionDate = sub(new Date(), { days: 1 });
      const isToRefetch = new Date(storedOrganization.fetchedAt) < peremptionDate;

      if (storedOrganization && !isToRefetch) {
        return storedOrganization;
      }
    }

    const { data }: AxiosResponse = await axios({
      method: 'GET',
      url: 'https://org.slippers.live/prod/orga',
    });

    if (!data) {
      throw new Error('organization not found');
    }

    setStorage('organization', { ...data, fetchedAt: new Date() });

    return data;
  }
  catch (err) {
    // eslint-disable-next-line no-console
    console.trace(err);
    throw err;
  }
};

export const useGetOrganization = () => {
  const [organization, setOrganization] = useState<Record<string, string>>({});

  useEffect(() => {
    getOrganization().then(setOrganization);
  }, []);

  return organization;
};
