/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@oclock/crumble';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Compoent
 */
const Introduction = ({ icon: IconProps, title, paragraph, cta, variant }) => (
  /*
   * Render
   */
  <S.Introduction>
    {/* Illustration */}
    {IconProps && (
      <S.Illustration variant={variant}>
        {typeof IconProps === 'object' && <IconProps aria-hidden="true" />}
        {typeof IconProps === 'string' && <Icon name={IconProps} size="3rem" />}
      </S.Illustration>
    )}

    {/* Text */}
    <S.Title>{title}</S.Title>
    <S.Paragraph>{paragraph}</S.Paragraph>

    {/* CTA */}
    {cta?.display && (
      <>
        {/* Button */}
        <S.Button type="button" onClick={cta.onClick} aria-label={cta.label} isMain>
          {cta.label}
        </S.Button>
        {cta.Component && <cta.Component />}
      </>
    )}
  </S.Introduction>
);

/*
 * PropTypes
 */
Introduction.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    display: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    Component: PropTypes.object,
  }),
  variant: PropTypes.string,
};

Introduction.defaultProps = {
  icon: null,
  cta: null,
  variant: null,
};

/*
 * Export
 */
export default Introduction;
