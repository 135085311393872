/*
 * Package Import
 */
import { keyframes as themeKeyframes } from '@o-clock-dev/theme-oclock';

/*
 * Local Import
 */

/*
 * Custom Keyframes
 */

/*
 * Export
 */
export const animations = {
  /*
   * Keyframes from `theme-oclock`
   */
  ...themeKeyframes,

  /*
   * Custom Keyframes
   */
};
