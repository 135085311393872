/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Notification from 'src/components/TopBar/Notifications/Notification';
import {
  deleteNotifications,
  setCurrentNotifications,
  dispatchActionOnClick,
} from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  let name;
  if (ownProps.userIdMessage) {
    name = state.users.userById[ownProps.userIdMessage].name;
  }
  return {
    // Notifications state
    menuNotificationsIsOpen: state.notifications.isOpen,
    allNotifications: state.notifications.allNotifications,
    // User state
    userId: state.users.client.id,
    name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { deleteNotifications, setCurrentNotifications, dispatchActionOnClick },
    dispatch,
  ),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
