import styled from '@emotion/styled';
import { UilExclamationCircle } from '@iconscout/react-unicons';

export const ExclamationIcon = styled(UilExclamationCircle)(({ theme }) => ({
  color: theme.warning.main,
  marginRight: '4px',
}));

export const ErrorNetworkMessageContainer = styled.div`
  align-items: center;
  color: ${(props) => props.theme.text._4};
  display: flex;
  ${(props) => props.theme.typo.bodyText.tinyText};
  margin-top: 4px;

  > span {
    color: ${(props) => props.theme.primary._1};
    cursor: pointer;
  }
`;

export const ErrorMessageContainer = styled.div`
  ${(props) => props.theme.typo.labels.tinyLabel};
  align-items: center;
  color: ${(props) => props.theme.warning.main};
  display: flex;
  margin-top: 8px;
`;
