/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/*
 * Local Import
 */
import { getClient, getStudentsIds } from 'src/store/selectors/users';
import { isEducator, isGhost, isStudent } from 'src/utils/roles';
import TimestampMessage from '../../Channel/Chat/Message/Timestamp';
import Result from './Result';
import Respond from './Respond';

// Style
import * as S from './style';

/*
 * Component
 */
const Item = ({ actions, surveyId, surveyIndex, survey }) => {
  const { answers, multiple, question, shareResult } = survey.content;
  const studentsIds = useSelector(getStudentsIds);
  const user = useSelector(getClient);

  const usersWhoVoted = answers.reduce((acc, answer) => [...acc, ...answer.votes], []);
  const nonVoters = studentsIds.filter((studentId) => !usersWhoVoted.includes(studentId));
  const userVotes = answers.map((answer) => answer.votes.some((userId) => userId === user.id));

  const hasAlreadyVoted = isStudent(user.role) && userVotes.some(Boolean);
  const canSeeResult = isEducator(user.role) || isGhost(user.role) || hasAlreadyVoted;

  return (
    <>
      {/* Header Content */}
      <S.Container>
        <S.HeaderContainer id={surveyId}>
          <S.Header>
            <S.Choice>{multiple ? 'Choix multiple' : 'Choix unique'}</S.Choice>
            <TimestampMessage time={survey.time} />
          </S.Header>
          <S.MinorTitle aria-label={question} tabIndex="0">
            {question}
          </S.MinorTitle>
        </S.HeaderContainer>

        {/* Partials */}
        {canSeeResult ? (
          <Result
            answers={answers}
            isEducator={isEducator(user.role)}
            nonVoters={nonVoters}
            shareResult={shareResult}
            userVotes={userVotes}
          />
        ) : (
          <Respond
            actions={actions}
            userId={user.id}
            answers={answers}
            multiple={multiple}
            surveyId={surveyId}
            surveyIndex={surveyIndex}
            time={survey.time}
          />
        )}
      </S.Container>
    </>
  );
};

/*
 * PropTypes
 */
Item.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** ID of the survey */
  surveyId: PropTypes.string.isRequired,
  /** Index of the survey */
  surveyIndex: PropTypes.number.isRequired,
  /** Can we vote at mutliple answer ? */
  survey: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Item;
