/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local import
 */
import StudentTracking from 'src/components/Tracking';

/*
 * Code
 */
// State
const mapStateToProps = (state) => {
  const userId = state.users.client.id;

  return {
    userId,
  };
};

// Actions
const mapDispatchToProps = {};

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(StudentTracking);
