/**
 * Types
 */
interface DefaultReactionsProps {
  id: string;
  name: string;
  colons: string;
}

/**
 * Possible reaction for a message
 */
export const defaultReactions: DefaultReactionsProps[] = [
  {
    id: '+1',
    name: 'oui',
    colons: ':+1:',
  },
  {
    id: '-1',
    name: 'non',
    colons: ':-1:',
  },
  {
    id: 'wave',
    name: 'salut main',
    colons: ':wave:',
  },
  {
    id: 'joy',
    name: 'pleurs joie',
    colons: ':joy:',
  },
  {
    id: 'sob',
    name: 'sanglot',
    colons: ':sob:',
  },
  {
    id: 'thinking_face',
    name: 'pensif',
    colons: ':thinking_face:',
  },
  {
    id: 'raised_back_of_hand',
    name: 'dos de la main levé',
    colons: ':raised_back_of_hand:',
  },
  {
    id: 'tada',
    name: 'tada',
    colons: ':tada:',
  },
  {
    id: 'clap',
    name: 'applaudissements',
    colons: ':clap:',
  },
  {
    id: 'heart',
    name: 'cœur',
    colons: ':heart:',
  },
];
