/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { useReactivateCourse } from 'src/serverStateManagers/mutations/reactivate/Courses/useReactivateCourse';
import { MongoId } from 'src/schemas/Entities/utils';
import { getRoute } from 'src/utils/routes';
import { useGetCourses } from 'src/serverStateManagers/queries/Courses/useGetCourses';

/*
 * Code
 */
type ReactivateProps = {
  isOpen: boolean;
  idToReactivate: MongoId | null;
};

const Reactivate = ({ isOpen, idToReactivate }: ReactivateProps): JSX.Element => {
  const reactivateCourseMutation = useReactivateCourse();
  const history = useHistory();

  const { data: coursesData } = useGetCourses();
  const courseData = coursesData?.find((course) => course.id === idToReactivate);

  useEffect(() => {
    if (courseData && !courseData.deactivatedAt) {
      history.push(getRoute('coursesTable').getUrl({}));
    }
  }, [courseData, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('coursesTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (courseData && !courseData.deactivatedAt) {
      toast('Cours déjà actif', { type: 'warning' });
      history.push(getRoute('coursesTable').getUrl({}));
      return;
    }
    if (idToReactivate) {
      reactivateCourseMutation.mutate(idToReactivate);
    }
    history.push(getRoute('coursesTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>Désarchiver</F.DeactivateHeader>
        <F.DeactivateLabel>Êtes-vous sûr de vouloir désarchiver ce cours ?</F.DeactivateLabel>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit} fullWidth>
            Désarchiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Reactivate;
