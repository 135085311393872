import styled from '@emotion/styled';

export const TableRow = styled.tr(({ theme }) => ({
  position: 'relative',
  color: theme.text._4,
  '&:hover': {
    backgroundColor: theme.background._8,
  },
}));

export const TableRowWithoutHover = styled.tr(({ theme }) => ({
  position: 'relative',
  color: theme.text._4,
}));
