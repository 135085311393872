import { useCallback, useContext, useEffect } from 'react';
import cloneDeep from 'lodash.clonedeep';

import { MessageContext } from 'src/context/Message';
import { RECEIVED_SURVEY, VOTE_SURVEY } from 'src/store/types';

/**
 * Hook that handles receiving & voting surveys.
 * If given a socket, it will add listeners for those actions.
 */
export const useSurvey = (socket) => {
  const { messagesById, onMessageReceived, updateMessage } = useContext(MessageContext);

  /**
   * Add answers from user to survey
   */
  const voteSurvey = useCallback(
    ({ answersIds, surveyId, userId }) => {
      const survey = messagesById[surveyId];

      if (survey && survey.content && 'answers' in survey.content) {
        const answers = cloneDeep(survey.content.answers);

        answers.forEach((answer, id) => {
          if (answersIds.includes(id)) {
            answer.votes.push(userId);
          }
        });

        updateMessage(surveyId, {
          content: {
            ...survey.content,
            answers,
          },
        });
      }
    },
    [messagesById, updateMessage],
  );

  useEffect(() => {
    socket?.on(RECEIVED_SURVEY, onMessageReceived);
    socket?.on(VOTE_SURVEY, voteSurvey);

    return () => {
      socket?.off(RECEIVED_SURVEY, onMessageReceived);
      socket?.off(VOTE_SURVEY, voteSurvey);
    };
  }, [onMessageReceived, socket, voteSurvey]);

  return {
    voteSurvey,
  };
};
