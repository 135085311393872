/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Emotions from 'src/components/Users/Profile/Emotions';
import { changeEmotion } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const user = state.users.client.id;

  return {
    emotion: state.users.userById[user].emotion,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeEmotion,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Emotions);
