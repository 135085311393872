/**
 * Package Import
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import { getOrganization } from 'src/store/selectors/organization';
import { MediaStreamDictionnary } from 'src/utils/webRTCManager/mediaStreamDictionnary';
import { AudioLevelSample } from './style';

/**
 * Component
 */
const AudioLevel = ({ clientId, courseId, userId }) => {
  /**
   * Vars
   */
  const { orga } = useSelector(getOrganization);

  /**
   * Refs
   */
  const intervalRef = useRef(null);

  /**
   * State
   */
  const [volume, setVolume] = useState(0);

  /**
   * LifeCycles
   */
  useEffect(() => {
    let targetStreamId = `${orga}-${courseId}-${userId}-cams`;

    // Special case : Local stream
    if (userId === clientId) {
      targetStreamId = 'localView';
    }

    // Get volume
    intervalRef.current = window.setInterval(() => {
      const volumeInByte = MediaStreamDictionnary.streamVolumes[targetStreamId];

      if (Number.isSafeInteger(volumeInByte)) {
        setVolume(volumeInByte);
      }
    }, 500);

    return () => window.clearInterval(intervalRef.current);
  }, []);

  /**
   * Render
   */
  if (volume) {
    return <AudioLevelSample volume={volume} />;
  }

  return null;
};

AudioLevel.propTypes = {
  clientId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

/**
 * Export
 */
export default AudioLevel;
