/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';
import { getSave } from 'src/store/middlewares/localStorage';
import {
  chatAppearanceOptions,
  chatSizeOptions,
  textSizeOptions,
  userListStyleOptions,
} from 'src/data/settings';

/**
 * Types
 */
export interface LayoutState {
  chatOpened: boolean;
  usersOpened: boolean;
  displayChat: boolean;
  displayUsers: boolean;
  tooltips: boolean;
  transcription: boolean;
  webcams: boolean;
}

export interface SettingsLayoutState extends LayoutState {
  chatAppearance: string;
  chatSize: string;
  textSize: number;
  userListStyle: string;
}

/*
 * Code
 */
const localStateLayout: Partial<SettingsLayoutState> = getSave('settings', 'layout') || {};

/*
 * Initial State
 */
export const initialLayout = {
  chatOpened: true,
  usersOpened: true,
  displayChat: true,
  displayUsers: true,
  textSize: textSizeOptions[1].value as number,
  tooltips: true,
  transcription: false,
  chatAppearance: chatAppearanceOptions[0].value as string,
  chatSize: chatSizeOptions[0].value as string,
  userListStyle: userListStyleOptions[0].value as string,
  webcams: true,
};

const initialState: SettingsLayoutState = {
  ...initialLayout,
  ...localStateLayout,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.RESET_LAYOUT:
        return initialLayout;

      case types.TOGGLE_LAYOUT:
        if (typeof action.name === 'string') {
          const layoutName = action.name as keyof LayoutState;
          draft[layoutName] = !state[layoutName];
        }

        if (action.name === 'displayChat' && state.chatOpened) {
          draft.chatOpened = !state.displayChat;
        }

        if (action.name === 'displayUsers' && state.usersOpened) {
          draft.usersOpened = !state.displayUsers;
        }
        break;

      case types.TEXTSIZE_CHANGE:
        draft.textSize = action.value;
        break;

      case types.TOGGLE_CHAT_DRAWER:
        draft.chatOpened = !state.chatOpened;
        break;

      case types.TOGGLE_USERS_DRAWER:
        draft.usersOpened = !state.usersOpened;
        break;

      case types.SET_CHAT_APPEARANCE:
        draft.chatAppearance = action.value;
        break;

      case types.SET_CHAT_SIZE:
        draft.chatSize = action.value;
        break;

      case types.SET_TRANSCRIPTION_ENABLED:
        draft.transcription = action.value;
        break;

      case types.SET_USERLIST_STYLE:
        draft.userListStyle = action.value;
        break;

      default:
        return state;
    }
  });
