import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

// Components
import Loader from 'src/components/Loader';

// Hooks
import { useFetch } from 'src/hooks/useFetch';

const UserContext = createContext();

/**
 * UserProvider
 * Handle user info / signOut after keycloak authentication
 */
const UserProvider = ({ children }) => {
  const { keycloak } = useKeycloak();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);

  const { data, error } = useFetch('users/findProfile');

  /**
   * Sign out user
   */
  const signOut = useCallback(async () => {
    setIsLoading(true);

    localStorage.removeItem('kcTokens');
    await keycloak.logout();
  }, [keycloak]);

  /**
   * Update the user data
   * @param {Object} newUserData
   */
  const updateUserData = useCallback((newUserData) => {
    setUser((prevUser) => ({ ...prevUser, ...newUserData }));
  }, []);

  useEffect(() => {
    /**
     * If we receive an error when requesting user data,
     * make Keycloak logout
     */
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast('Erreur lors de la récupération du profil utilisateur', { type: 'error' });

      localStorage.removeItem('kcTokens');
      keycloak.logout();

      return;
    }

    // Check if data is really a user object
    if (data && data.id && data.role && data.email) {
      setUser({ ...data });
    }
  }, [data, error]);

  const value = useMemo(
    () => ({
      user,
      signOut,
      updateUserData,
    }),
    [user, signOut, updateUserData],
  );

  if (isLoading) {
    return <Loader overlay type="Oval" size={48} />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
