import PropTypes from 'prop-types';
import React from 'react';
import { UilSearch } from '@iconscout/react-unicons';
import styled from '@emotion/styled';

const SearchContainer = styled.div(({ theme, isValue }) => ({
  display: 'flex',
  width: '15rem',
  flexDirection: 'row',
  position: 'relative',
  padding: '0.3em',
  borderRadius: '8px',
  border: `1px solid ${theme.text._7}`,
  boxShadow: isValue ? `0 0 0 2px ${theme.primary._1}` : '',
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${theme.primary._1}`,
  },
  '&:hover': {
    boxShadow: `0 0 0 2px ${theme.text._6}`,
  },
}));

const ModalSearchContainer = styled.div(({ theme, isValue }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  padding: '.5rem 1rem',
  marginBottom: '2rem',
  borderRadius: '8px',
  border: `1px solid ${theme.text._7}`,
  boxShadow: isValue ? `0 0 0 2px ${theme.primary._1}` : '',
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${theme.primary._1}`,
  },
}));

const Input = styled.input(({ theme }) => ({
  marginLeft: '2rem',
  background: 'transparent',
  color: theme.text._4,
  width: '100%',
  ...theme.typo.bodyText.body,
}));

const StyledSearchIcon = styled(UilSearch)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  height: '1rem',
  color: theme.text._4,
}));

export const Search = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (inputValue) => {
    setGlobalFilter(inputValue || undefined);
  };
  return (
    <SearchContainer isValue={!!value}>
      <StyledSearchIcon />
      <Input
        value={value || ''}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event.target.value);
        }}
        placeholder="Recherche"
      />
    </SearchContainer>
  );
};
Search.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
};
Search.defaultProps = {
  globalFilter: '',
};

export const ModalSearch = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (inputValue) => {
    setGlobalFilter(inputValue || undefined);
  };
  return (
    <ModalSearchContainer isValue={!!value}>
      <StyledSearchIcon />
      <Input
        value={value || ''}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event.target.value);
        }}
        placeholder="Recherche"
      />
    </ModalSearchContainer>
  );
};
ModalSearch.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
};
ModalSearch.defaultProps = {
  globalFilter: '',
};
