/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Feedback from 'src/components/TopBar/Menu/Feedback';
import {
  setModalFeedback,
  setInputFeedback,
  setErrorFeedback,
  sendFeedback,
} from 'src/store/actions';
import { getUserProps, getHelperIds, getTeacher } from 'src/store/selectors/users';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { isOpen, select, comment, error, loader, errorSend } = state.feedback;
  const { name } = getUserProps(state, state.users.client.id);
  const teacherId = getTeacher(state);
  const helpersIds = getHelperIds(state);

  return {
    // State feedback
    isOpen,
    select,
    comment,
    error,
    loader,
    errorSend,
    // State user
    helpersIds,
    teacherId,
    name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setModalFeedback,
      setInputFeedback,
      setErrorFeedback,
      sendFeedback,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
