/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilCheck } from '@iconscout/react-unicons';

/*
 * Style
 */

// * All SubMenu */

// Label subMenu
export const Label = styled.span`
  display: flex;
  flex-wrap: wrap;
  width: ${({ isShort }) => (isShort ? '80%' : '100%')};
  margin: 0;
`;

// * Notifications Menu */

// Container switch + label
export const Form = styled.form(({ theme }) => ({
  width: '100%',
  padding: '0.5rem 0',
  display: 'flex',
  alignItems: 'center',
  color: theme.text._1,
  ...theme.typo.labels.label,
}));

// Container switch toogle notif
export const ContainerSwitch = styled.div`
  float: left;
  clear: left;
  overflow: hidden;
  cursor: pointer;
  margin-right: 0.5rem;
`;

// Switch toogle notif
export const Switch = styled.div`
  float: left;
  width: 2.5rem;
  height: 1.5rem;
  padding: 0.13rem;
  border-radius: 24px;
  background: ${({ theme, isActive, disabled }) =>
    // eslint-disable-next-line no-nested-ternary
    (disabled
      ? theme.switch.background.disabled
      : isActive
        ? theme.success.main
        : theme.switch.background.inactive)};

  ::before {
    content: '';
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    background: ${({ theme, disabled }) =>
    (disabled ? theme.switch.toogle.disabled : theme.colors.monochrome.white)};
    transform: translateX(${({ isActive }) => (isActive ? '.95rem' : 0)});
    transition: 0.2s;
    box-shadow: ${({ theme }) => theme.shadows.plus1.boxShadow};
  }
`;

/*  Layout Menu */

// Container checkBox + label
export const ContainerCheckBox = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

// Container Icon checkBox
export const Checkbox = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
`;

// Icons for check checkboxes
export const IconCheck = styled(UilCheck)`
  color: ${({ theme }) => theme.primary._2};
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

// Buttons container
export const Buttons = styled.div({
  display: 'flex',
  width: '100%',
});

// Buttons (with optional icon)
export const Button = styled.button(({ isActive, theme }) => ({
  alignItems: 'center',
  background: isActive ? theme.primary._5 : theme.background._8,
  border: isActive && `1px solid ${theme.primary._2}`,
  borderRadius: '4px',
  color: `${isActive ? theme.text._3 : theme.text._5} !important`,
  display: 'flex',
  justifyContent: 'center',
  margin: '0.2rem 0.3rem',
  padding: '0.5rem',
  width: '100%',

  ':first-of-type': { marginLeft: 0 },
  ':last-of-type': { marginRight: 0 },

  '& > svg': {
    color: 'inherit !important',
    marginRight: '.5rem',
  },

  '&:hover': {
    background: isActive ? theme.primary._4 : theme.background._5,
  },

  ':focus': {
    outline: 'unset',
  },
  ':focus-visible': {
    borderRadius: '8px',
    border: `2px solid ${theme.primary._1}`,
  },
  ':-moz-focusring': {
    borderRadius: '8px',
    border: `2px solid ${theme.primary._1}`,
  },
}));

// Submenu item title
export const Title = styled.h3(({ theme }) => ({
  color: theme.text._5,
  margin: '0.25rem 0 0.5rem',
  ...theme.typo.specials.capitalized,
}));

/* Help Menu */

// Link : "Nous contacter"
export const Link = styled.a(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.text._1,
  ...theme.typo.labels.label,
}));
