/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { UilFilter } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import {
  FilterModalButton,
  FilterModal,
  Close,
} from 'src/components/Admin/TableElements/Filters/Modal';

/*
 * Styles
 */
const ButtonFilterIcon = styled(UilFilter)(() => ({
  width: '1.25rem',
}));

const FilterCheckboxContainer = styled.div(() => ({
  padding: '.5rem',
}));

const CheckBoxHeadline = styled.span(({ theme }) => ({
  ...theme.typo.specials.capitalized,
  color: theme.text._4,
}));

const Option = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.text._1,
  paddingLeft: '.5rem',
  cursor: 'pointer',
  borderRadius: '8px',
  minWidth: '8rem',
  ...theme.typo.labels.smallLabel,

  ':hover': {
    background: theme.background._8,
  },
}));

const Checkbox = styled.input(() => ({
  appearance: 'checkbox',
  cursor: 'pointer',
  width: '1.25rem',
  height: '1.25rem',
}));

const CheckboxLabel = styled.label(() => ({
  padding: '.5rem .5rem .5rem .5rem',
  width: '100%',
}));

/*
 * Component
 */
export const FilterCheckbox = ({ filterValue, setFilter, options, header }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleCheck = (evt) => {
    const isChecked = evt.currentTarget.checked;
    const currentValue = evt.currentTarget.value;
    const newFilterValues = [...filterValue, currentValue].filter(
      (value) => value !== currentValue || (isChecked && value === currentValue),
    );
    setFilter(newFilterValues);
  };

  return (
    <Tooltip content={`Filtres sur la colonne ${header}`}>
      <>
        <FilterModalButton
          onClick={isOpen ? handleClose : handleOpen}
          isOpen={isOpen}
          isFilterValue={filterValue?.length}
        >
          <ButtonFilterIcon />
        </FilterModalButton>
        {isOpen && (
          <FilterModal isOpen={isOpen}>
            <FilterCheckboxContainer>
              <CheckBoxHeadline>Filtres</CheckBoxHeadline>
              {options.map((option) => (
                <Option key={option.value}>
                  <Checkbox
                    type="checkbox"
                    id={option.value}
                    value={option.label}
                    onClick={handleCheck}
                    defaultChecked={filterValue?.includes(option.label)}
                  />
                  <CheckboxLabel htmlFor={option.value}>{option.label}</CheckboxLabel>
                </Option>
              ))}
            </FilterCheckboxContainer>
          </FilterModal>
        )}
        {isOpen && <Close onClick={handleClose} />}
      </>
    </Tooltip>
  );
};

FilterCheckbox.propTypes = {
  filterValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilter: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string.isRequired,
};
