/**
 * Package Import
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import { useWebsocket } from 'src/hooks';
import { getUsersNames } from 'src/store/selectors/users';
import useTyping from './useTyping';

/**
 * Style
 */
import * as S from './style';

/**
 * Affiche le message `<nom> est en train d'écrire` dans le chat
 */
const Typing = ({ chatId }) => {
  const classroomUsers = useSelector(getUsersNames);
  const socket = useWebsocket();
  const { usersTyping } = useTyping(chatId, socket);

  /**
   * Si une seule personne est en train d'écrire, on affiche son nom
   * Si plusieurs sont en train d'écrire, on affiche un message générique
   */
  const format = useMemo(() => {
    const usersTypingIds = Object.keys(usersTyping);
    let text;

    if (usersTypingIds.length === 1) {
      // On récupère l'utilisateur en train d'écrire
      // Les clés de l'objet `usersTyping` sont sous la forme `chatId-userId`
      const userTyping = classroomUsers.find((user) => user.id === usersTypingIds[0].split('-')[1]);

      text = `<strong>${userTyping?.name || "Quelqu'un"}</strong> est en train d'écrire…`;
    }
    else if (usersTypingIds.length) {
      text = "Plusieurs personnes sont en train d'écrire…";
    }

    return text;
  }, [classroomUsers, usersTyping]);

  return <S.Typing aria-hidden="true" dangerouslySetInnerHTML={{ __html: format }} />;
};

/*
 * PropTypes
 */
Typing.propTypes = {
  chatId: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default React.memo(Typing);
