/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Styles
 */
export const Container = styled.div`
  display: flex;
  margin: 1rem 2.5rem;
  height: 100%;
  justify-content: center;
`;

export const Card = styled.div(({ theme }) => ({
  alignItems: 'center',
  background: theme.background._2,
  border: `1px solid ${theme.text._8}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  width: '500px',
}));

export const Header = styled.header`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.text._8}`};
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  width: 100%;
`;
export const Footer = styled.footer`
  display: flex;
  margin: 1.5rem auto;
`;

export const Content = styled.div`
  padding: 0 1.5rem;
`;

export const Title = styled.h2(({ theme }) => ({
  ...theme.typo.headlines.H3,
  marginBottom: '.5rem',
}));

export const Description = styled.p(({ theme }) => ({
  ...theme.typo.labels.label,
  color: theme.text._5,
  marginBottom: '1.5rem',
}));

export const CardTitle = styled.h3(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
}));

export const CardDescription = styled.p(({ theme }) => ({
  ...theme.typo.bodyText.body,
}));

export const ErrorMessage = styled.span(({ theme }) => ({
  ...theme.typo.bodyText.tinyText,
  color: theme.danger.main,
  display: 'block',
  width: '500px',
}));
