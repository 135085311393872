/**
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * Local Import
 */
import Question from 'src/components/Chats/Channel/Chat/Message/Content/Question';

// Helpers
import { setQuestionMessageAnsweredStatus } from 'src/store/actions';
import { isTeacher, isHelper } from 'src/utils/roles';

/**
 * Redux
 */
const mapStateToProps = (state, ownProps) => {
  // Props
  const { id, role } = state.users.client;
  const { messageUserId } = ownProps;
  const isMyMessage = messageUserId === id;

  // Does the user has permission to access at these actions ?
  const canAnswer = isTeacher(role) || isHelper(role) || isMyMessage;

  return {
    canAnswer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators(
    {
      setQuestionMessageAnsweredStatus: setQuestionMessageAnsweredStatus(ownProps),
    },
    dispatch,
  ),
});

/**
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Question);
