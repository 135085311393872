/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { Emoji } from 'src/components/Format/Formatizer';
import { NO_EMOTION } from 'src/constants/emotions';

// Style
import * as S from './style';

/*
 * Component
 */
const ClassEmotions = ({ emotion, connected }) => {
  /*
   * State
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (emotion !== NO_EMOTION && connected) {
    return (
      <S.ClassEmotions>
        <Emoji size={14}>{emotion}</Emoji>
      </S.ClassEmotions>
    );
  }
  return null;
};

/*
 * PropTypes
 */
ClassEmotions.propTypes = {
  emotion: PropTypes.string,
  connected: PropTypes.bool,
};

ClassEmotions.defaultProps = {
  emotion: NO_EMOTION,
  connected: true,
};

/*
 * Export
 */
export default ClassEmotions;
