/*
 * Package Import
 */
import styled from '@emotion/styled';
import UilShieldCheck from '@iconscout/react-unicons/icons/uil-shield-check';
import UilShieldPlus from '@iconscout/react-unicons/icons/uil-shield-plus';

/*
 * Local Import
 */
import ROLES from 'src/constants/roles';
import { ReactComponent as Ghost } from 'assets/images/icons/ghost.svg';

/*
 * Style
 */
const badgeStyle = {
  height: '1.20rem',
  width: '1.20rem',
};

export const getRoleColor = (props, role) => {
  if (role === ROLES.ROLE_TEACHER) {
    return props.theme.primary._1;
  }
  if (role === ROLES.ROLE_HELPER) {
    return props.theme.success.main;
  }
  if (role === ROLES.ROLE_GHOST) {
    return props.theme.text._4;
  }
  return null;
};

export const getRoleBgColor = (props, role) => {
  if (role === ROLES.ROLE_TEACHER) {
    return props.theme.primary._5;
  }
  if (role === ROLES.ROLE_HELPER) {
    return props.theme.success.background;
  }
  if (role === ROLES.ROLE_GHOST) {
    return props.theme.background._8;
  }
  return null;
};

export const StyledUilShieldCheck = styled(UilShieldCheck)((props) => ({
  color: getRoleColor(props, ROLES.ROLE_TEACHER),
  ...badgeStyle,
}));

export const StyledUilShieldPlus = styled(UilShieldPlus)((props) => ({
  color: getRoleColor(props, ROLES.ROLE_HELPER),
  ...badgeStyle,
}));

export const StyledShieldGhost = styled(Ghost)((props) => ({
  color: getRoleColor(props, ROLES.ROLE_GHOST),
  ...badgeStyle,
}));

// We can add roles here
export const badges = {
  teacher: StyledUilShieldCheck,
  helper: StyledUilShieldPlus,
  ghost: StyledShieldGhost,
  student: null,
};

export const adminRolesLabels = {
  teacher: {
    label: 'Formateur',
  },
  helper: {
    label: 'Helper',
  },
  ghost: {
    label: 'Fantôme',
  },
};

export const labels = {
  ...adminRolesLabels,
  student: {
    label: 'Etudiant',
  },
};

export const rolesScore = {
  teacher: 2,
  helper: 1,
  student: 0,
  ghost: -1,
};
