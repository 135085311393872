/*
 * Package Import
 */

/*
 * Local Import
 */
import { Promotion } from 'src/schemas/Entities/Promotion';
import { getStartDefaultValue, getEndDefaultValue } from './utils';

/*
 * Code
 */
export const promotionsActivityFormData = {
  header: "Export de l'activité",
  inputs: {
    promotionId: {
      title: 'promotionId',
      display: 'Promotion Id',
      type: 'hidden',
    },
    start: {
      title: 'start',
      display: 'Date de début *',
      type: 'datetime-local',
      defaultValue: (promotion: Promotion): string | undefined => getStartDefaultValue(promotion),
    },
    end: {
      title: 'end',
      display: 'Date de fin *',
      type: 'datetime-local',
      defaultValue: (promotion: Promotion): string | undefined => getEndDefaultValue(promotion),
    },
  },
};
