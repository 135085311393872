/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { CourseFormDataValidated, CourseDTO } from 'src/schemas/Entities/Course';
import { EdusignStatusEnum } from 'src/schemas/Entities/utils';
import { createCourse } from './createCourse';

/*
 * Code
 */
export const useCreateCourse = (): UseMutationResult<
CourseDTO,
unknown,
CourseFormDataValidated,
unknown
> => {
  const courseMutation = useMutation(
    (courseFormData: CourseFormDataValidated) => createCourse(courseFormData),
    {
      onSettled: () => {
        queryClient.invalidateQueries('courses');
      },
      onSuccess: (newData) => {
        toast('Le cours a bien été créé.', { type: 'success' });

        const { edusignStatus } = newData;

        if (edusignStatus === EdusignStatusEnum.ERROR) {
          toast("Une ou plusieurs séances n'ont pas pu être créées sur Edusign.", {
            type: 'error',
          });
        }
      },
    },
  );

  return courseMutation;
};
