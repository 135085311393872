/*
 * Package Import
 */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Column,
  Row,
  ColumnDef,
  Updater,
  SortingFn,
  VisibilityState,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Icon } from '@oclock/crumble';

/*
 * Local Import
 */

import Table from 'src/components/Admin/Tables/Table';
import * as T from 'src/components/Admin/TableElements';
import { sortDateTable, dateFilterMethod, noMatchCaseSort } from 'src/utils/table';
import { status, defaultStatusFilterValues } from 'src/constants/status';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { getStatus } from './utils';

/*
 * Types
 */
export type PromotionRow = Promotion & {
  subRows?: Promotion[];
};

/*
 * Code
 */

// Filters
// Define a default UI for filtering
const DefaultFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<PromotionRow, unknown>;
}) => (
  <T.FilterText
    filterValue={getFilterValue() as string | null | undefined}
    setFilter={setFilterValue}
    header={columnDef.header as string}
  />
);

const StatusFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<PromotionRow, unknown>;
}) => (
  <T.FilterCheckbox
    filterValue={getFilterValue() as (string | null | undefined)[]}
    setFilter={setFilterValue}
    options={status}
    header={columnDef.header as string}
  />
);

const DateFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<PromotionRow, unknown>;
}) => (
  <T.FilterDate
    filterValue={getFilterValue() as Date}
    setFilter={setFilterValue}
    header={columnDef.header as string}
  />
);

// Cells components
const StatusCell = ({ row }: { row: Row<PromotionRow> }) =>
  (row?.original ? <T.Status status={getStatus(row.original)} /> : null);

const ExpanderCell = ({ row }: { row: Row<PromotionRow> }) =>
  (row.getCanExpand() ? (
    <T.Expander {...{ onClick: row.getToggleExpandedHandler() }}>
      <Icon name={row.getIsExpanded() ? 'AngleUp' : 'AngleDown'} />
    </T.Expander>
  ) : null);

const NameCell = ({ row }: { row: Row<PromotionRow> }) => (
  <div style={{ paddingLeft: row.original?.isChild ? '1rem' : '0' }}>
    {row.original?.name || ''}
  </div>
);

const formatData = (data: Promotion[]): PromotionRow[] => {
  const parentRows: PromotionRow[] = data.filter((promotion) => !promotion.isChild);

  const formattedRows = parentRows.reduce((promotionRows, parentRow) => {
    if (!promotionRows.some((promotion) => promotion.id === parentRow.id)) {
      const parentChildren = data.filter(
        (promotion) => promotion.isChild && promotion.parentId === parentRow.id,
      );

      parentRow.subRows = [...parentChildren];

      promotionRows.push(parentRow);
    }

    return promotionRows;
  }, [] as PromotionRow[]);
  return formattedRows;
};

// Hook receiving datas and returning corresponding Table and tableInstance
const usePromotionTable = (newData: Promotion[] = []) => {
  const [data, setData] = useState(formatData(newData));
  const skipPageResetRef = useRef(true);

  // If we receive new Data, we update table data without reset pagination
  useEffect(() => {
    skipPageResetRef.current = true;
    setData(formatData(newData));
  }, [newData]);

  // And after a little delay, we reactive reset pagination for sort, search, filters...
  useEffect(() => {
    if (skipPageResetRef.current) {
      setTimeout(() => {
        skipPageResetRef.current = false;
      }, 500);
    }
  });

  // Custom sorting for dates
  const sortDateBy = useMemo(() => sortDateTable, []);

  // Insensitive case sort
  const insensitiveCaseSort: SortingFn<PromotionRow> = (rowA, rowB, columnId) =>
    noMatchCaseSort({
      valueA: rowA.getValue(columnId),
      valueB: rowB.getValue(columnId),
    });

  // Columns configuration
  const columns: ColumnDef<PromotionRow>[] = useMemo(
    () => [
      {
        id: 'expander',
        header: '',
        cell: ExpanderCell,
        meta: {
          width: '1%',
          customColumnStyle: {
            padding: '0px',
          },
        },
      },
      {
        header: 'Id',
        id: 'id',
        accessorKey: 'id',
      },
      {
        header: 'Nom',
        id: 'name',
        accessorKey: 'name',
        sortingFn: insensitiveCaseSort,
        cell: NameCell,
      },
      {
        header: 'Date de début',
        id: 'start',
        accessorFn: (row: PromotionRow) => format(row.start, 'dd/MM/yyyy', { locale: frLocale }),
        cell: ({ row }: { row: Row<PromotionRow> }) => row.getValue('start'),
        filterFn: dateFilterMethod,
        sortingFn: sortDateBy,
      },
      {
        header: 'Date de fin',
        id: 'end',
        accessorFn: (row: Promotion) => format(row.end, 'dd/MM/yyyy', { locale: frLocale }),
        cell: ({ row }: { row: Row<PromotionRow> }) => row.getValue('end'),
        filterFn: dateFilterMethod,
        sortingFn: sortDateBy,
      },
      {
        header: 'Statut',
        id: 'status',
        accessorFn: (row: Promotion) => getStatus(row).label,
        cell: StatusCell,
        filterFn: (row, columnId, filterValue) =>
          filterValue.length && filterValue.includes(row.getValue(columnId)),
      },
      {
        header: 'Archivage',
        id: 'archive',
        accessorFn: (row: Promotion) => !!row.deactivatedAt,
        filterFn: (row, columnId, filterValue) => row.getValue(columnId) === filterValue,
      },
    ],
    [sortDateBy, insensitiveCaseSort],
  );

  const initialState = {
    columnVisibility: {
      id: false,
      archive: false,
    },
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    columnFilters: [
      { id: 'archive', value: false },
      { id: 'status', value: [...defaultStatusFilterValues] },
      { id: 'start', value: { start: '', end: '' } },
      { id: 'end', value: { start: '', end: '' } },
    ],
    sorting: useMemo(
      () => [
        {
          id: 'start',
          desc: true,
        },
      ],
      [],
    ),
    hideColumns: ({
      rows,
      setColumnVisibility,
    }: {
      rows: Row<PromotionRow>[];
      setColumnVisibility: (prevState: Updater<VisibilityState>) => void;
    }) => {
      // Hide expander column when no child promo on page
      // (check on page beacuse of filters and pagination)
      const isSubRows = rows.some((row) => row.getCanExpand());
      setColumnVisibility((prevState) => ({ ...prevState, expander: isSubRows }));
    },
    filtersTypes: {
      id: {
        Filter: ({ column }: { column: Column<PromotionRow, unknown> }) =>
          DefaultFilter({ column }),
      },
      name: {
        Filter: ({ column }: { column: Column<PromotionRow, unknown> }) =>
          DefaultFilter({ column }),
      },
      start: {
        Filter: ({ column }: { column: Column<PromotionRow, unknown> }) => DateFilter({ column }),
      },
      end: {
        Filter: ({ column }: { column: Column<PromotionRow, unknown> }) => DateFilter({ column }),
      },
      status: {
        Filter: ({ column }: { column: Column<PromotionRow, unknown> }) => StatusFilter({ column }),
      },
    },
  };

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetAll: !skipPageResetRef.current,
  });
  return [tableInstance, Table] as const;
};

/*
 * Export
 */
export default usePromotionTable;
