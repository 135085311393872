/*
 * Package Import
 */

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { PromotionDTO, PromotionFormDataValidated } from 'src/schemas/Entities/Promotion';
import { transformPromotionFormDataToPromotionDTO } from 'src/schemas/Adapters/Promotions/transformPromotionFormDataToPromotionDTO';
import { API_UPDATE_PROMOTION, API_PUT } from 'src/constants/apiRoutes';
import { toast } from 'react-toastify';
import { MongoId } from 'src/schemas/Entities/utils';

/*
 * Code
 */
export interface UpdatePromotionProps {
  promotionId: MongoId;
  promotionFormData: PromotionFormDataValidated;
}
export const updatePromotion = async ({
  promotionId,
  promotionFormData,
}: UpdatePromotionProps): Promise<PromotionDTO> => {
  try {
    const promotionDTO = transformPromotionFormDataToPromotionDTO(promotionFormData);

    const payload: MutationPayload = {
      method: API_PUT,
      url: API_UPDATE_PROMOTION,
      data: {
        param: { _id: promotionId },
        newDatas: promotionDTO,
      },
    };
    const { data: promotionData } = await api(payload);

    return promotionData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
