/*
 * Package Import
 */
import { useQuery, UseQueryResult } from 'react-query';

/*
 * Local Import
 */
import { Course } from 'src/schemas/Entities/Course';
import { useGetPromotions } from '../Promotions/useGetPromotions';
import { useGetUsers } from '../Users/useGetUsers';
import { fetchCourses } from './fetchCourses';

/*
 * Code
 */
export const useGetCourses = (): UseQueryResult<Course[]> => {
  useGetPromotions();
  useGetUsers();

  const coursesQuery = useQuery('courses', fetchCourses, {
    initialData: [],
    staleTime: 5000,
  });

  return coursesQuery;
};
