import type { User } from 'src/schemas/Entities/User';
import type { UserRelation } from 'src/schemas/Entities/Course';
import { roles } from 'src/constants/roles';
import type { Option } from 'src/components/Admin/Modals/FormElements/type';
import { Slot } from 'src/components/Admin/Modals/FormElements/Timeslot/utils';
import { getPromotionFromId } from 'src/schemas/Repositories/promotions';
import { getUserFromId } from 'src/schemas/Repositories/users';
import type { Promotion } from 'src/schemas/Entities/Promotion';
import { dateToNumberValue, dateStringToNumberValue } from 'src/utils/time';
import { MongoId } from 'src/schemas/Entities/utils';

export const findUserAndCheckIfPeda = (
  user: UserRelation | Option,
  usersData: User[] | undefined,
): boolean => {
  const userFound = usersData?.find(
    (userData) => userData.id === ('value' in user ? user.value : user.id),
  );
  if (!userFound) return false;
  const userRole = roles.find((role) => role.name === userFound.role);
  return !!userRole?.isPeda;
};

// Can't use directly schema FormData type b/c cyclic dependency
export interface CourseFormDataPartialStatic {
  id?: MongoId;
  promotion: Option | null;
  date: string;
  timeslots?: Slot[] | null;
}
export const findPromotionAndCheckCourseDate = (
  data: CourseFormDataPartialStatic,
  promotionsData: Promotion[] | undefined,
): boolean => {
  // If promotion not chosen => don't check dates
  if (!data.promotion) {
    return true;
  }

  const promotion = getPromotionFromId({
    promotions: promotionsData,
    id: data.promotion?.value,
  });

  if (!promotion) {
    return false;
  }

  if (
    dateStringToNumberValue(data.date) >= dateToNumberValue(promotion.start)
    && dateStringToNumberValue(data.date) <= dateToNumberValue(promotion.end)
  ) {
    return true;
  }
  return false;
};

export const findPromotionAndCheckIfActive = (
  promotionOption: Option | null,
  promotionsData: Promotion[] | undefined,
): boolean => {
  // If promotion not chosen => don't check if active
  if (!promotionOption) {
    return true;
  }

  const promotion = getPromotionFromId({
    promotions: promotionsData,
    id: promotionOption?.value,
  });

  // Promotion not found ? Reject
  if (!promotion) {
    return false;
  }

  // Promotion not archived => accept
  if (!promotion.deactivatedAt) {
    return true;
  }
  return false;
};

export const findUserAndCheckIfActive = (
  userOption: Option | null,
  usersData: User[] | undefined,
): boolean => {
  // If user not chosen => don't check if active
  if (!userOption) {
    return true;
  }

  const user = getUserFromId({
    users: usersData,
    id: userOption?.value,
  });

  // User not found ? Reject
  if (!user) {
    return false;
  }

  // User not archived => accept
  if (!user.deactivatedAt) {
    return true;
  }
  return false;
};
