import { useContext, useEffect } from 'react';

import { SocketContext } from 'src/context/SocketProvider';

export function useWebsocket(courseID = null) {
  const socketContext = useContext(SocketContext);

  useEffect(() => {
    if (courseID) {
      socketContext.setCourseId(courseID);
    }
  }, [courseID]);

  return socketContext.webSocket;
}
