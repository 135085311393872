/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MessagesList from 'src/components/Chats/Channel/Chat/MessagesList';

// Helpers
import { setChannelUnread } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  // Active
  const active = state.chats.active === ownProps.chatId;

  return {
    active,
    textSize: state.settings.layout.textSize,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setChannelUnread,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(MessagesList);
