/**
 * Local Import
 */
import type { ReactionMessageProps } from 'src/utils/types';

/**
 * Get the list of user reactions, formatted for a human
 * [x, y and z] [reacted with] [:emoji:]
 */
export const getReactionTip = (reaction: ReactionMessageProps) => {
  let label = '';

  if (reaction?.users?.length) {
    // First piece, users list !
    label = reaction.users.join(', ');

    // If we have multiples users, replace the last `,`
    if (reaction.users.length >= 2) {
      const lastIndex = label.lastIndexOf(',');
      label = label.slice(0, lastIndex) + label.slice(lastIndex).replace(',', ' et');
    }

    // Second piece, verbs conjugation + reaction name !
    if (reaction.users.includes('Vous') || reaction.users.includes('vous')) {
      label += ` avez réagi avec :${reaction.name}:`;
    }
    else if (reaction.users.length > 1) {
      label += ` ont réagi avec :${reaction.name}:`;
    }
    else {
      label += ` a réagi avec :${reaction.name}:`;
    }
  }

  return label;
};
