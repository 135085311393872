/*
 * Package Import
 */
import React from 'react';
import ReactModal from 'react-modal';
import { useTheme } from '@emotion/react';

/*
 * Local Import
 */
import { Container } from './FormElements';

/*
 * Style
 */
interface ModalProps {
  children: JSX.Element;
  isOpen: boolean;
  onRequestClose: (evt: React.MouseEvent) => void;
}
export const Modal = ({ children, isOpen, onRequestClose }: ModalProps): JSX.Element => {
  const theme = useTheme();
  const customStyles: ReactModal.Styles = {
    content: {
      inset: 'initial', // to reset default library styles
      border: `2px solid ${theme.text._7}`,
      backgroundColor: theme.background._2,
      borderRadius: '20px',
      padding: 0,
      position: 'relative', // to reset default library styles
      display: 'inline-block',
    },
    overlay: {
      background: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  ReactModal.setAppElement('#root');

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <Container>{children}</Container>
    </ReactModal>
  );
};
