/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ResetSignature from 'src/components/TopBar/Signature/ResetSignature';
import { resetSignatures, confirmResetSignatures } from 'src/store/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ resetSignatures, confirmResetSignatures }, dispatch),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(ResetSignature);
