/*
 * Package Import
 */
import React, { useId, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
// Helpers
import CHATS from 'src/constants/chats';
import { isEnter, isSpace } from 'src/utils/events';
import { hasPrerequisitesAskSurvey } from './utils';

// Style
import * as S from './style';

/*
 * Code
 */
const initialState = {
  question: '',
  answers: new Array(CHATS.SURVEY_DEFAULT_ANSWER).fill(''),
  multiple: false,
  shareResult: false,
};

/*
 * Component
 */
const CreatePoll = ({ actions, display }) => {
  /**
   * Hooks
   */
  const id = useId();

  /*
   * State
   */
  const [state, setState] = useState(initialState);

  /**
   * onClose
   * @return {void}
   */
  const onClose = () => {
    actions.closeModal();
  };

  /*
   * Submit the survey
   */
  const onSubmit = async (evt) => {
    evt.preventDefault();
    const { question, answers, multiple, shareResult } = state;

    if (!hasPrerequisitesAskSurvey(question, answers)) {
      return;
    }

    // Format answers · Construction of the response object
    const newAnswers = answers
      .filter((answer) => answer !== '')
      .map((answer, index) => ({ id: index, content: answer, votes: [] }));

    // Send to WS
    await actions.askSurvey({
      /* Data survey */
      question,
      answers: newAnswers,

      /* Options survey */
      multiple,
      shareResult,
    });

    setState({
      ...state,
      answers: ['', ''],
      question: '',
    });
    // Close the modal
    onClose();
  };

  /**
   * Update the question input of survey
   * @param  {String} name
   * @param  {String} value
   */
  const onQuestionChange = (evt) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
  };

  /**
   * Update the answers inputs of survey
   * @param  {String} name
   * @param  {String} value
   * @return {void}
   */
  const onAnswerChange = (evt) => {
    // Shallow copy
    const answers = [...state.answers];
    answers[evt.target.name] = evt.target.value;

    //
    setState({ ...state, answers });
  };

  /**
   * Create a new item from the answers list
   * @return {void}
   */
  const addAnswer = () => {
    // 6 Answers max
    if (state.answers.length < CHATS.SURVEY_MAX_ANSWER) {
      setState({
        ...state,
        answers: [...state.answers, ''],
      });
    }
  };

  /**
   * Remove an item from the answers list
   * @param {Number} · index to delete
   * @return {void}
   */
  const removeAnswer = (answerIndex) => () => {
    setState({
      ...state,
      answers: state.answers.filter((__, index) => index !== answerIndex),
    });
  };

  /**
   * Update the question input of survey
   * @param  {String} type
   */
  const onCheckboxChange = (type) => {
    setState({
      ...state,
      [type]: !state[type],
    });
  };

  /**
   * Update the question input of survey with keyboard
   * @param  {String} type
   * @param  {Event} evt
   */
  const keyboardCheck = (type) => (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      onCheckboxChange(type);
    }
  };

  /*
   * Render
   */
  return (
    <S.PollModal
      isOpen={display}
      onRequestClose={onClose}
      top="auto"
      left="auto"
      ariaLabel="Sondage"
    >
      <S.Form onSubmit={onSubmit}>
        <S.Title>Créer un sondage</S.Title>

        {/* Questions */}
        <S.LabelContainer htmlFor="question" />
        <S.Label>Question</S.Label>
        <S.Input
          type="text"
          id="question"
          data-testid="survey-question"
          name="question"
          placeholder="Posez votre question"
          onChange={onQuestionChange}
          value={state.question}
        />
        <S.Divider />

        {/* Answers */}
        <fieldset>
          {state.answers.map((_answer, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <S.Answer key={`answer-id-${index}`}>
              <S.LabelContainer htmlFor={index}>
                <S.Label>Réponse {index + 1}</S.Label>
                <S.Input
                  type="text"
                  id={index}
                  data-testid={`survey-answer-${index}`}
                  name={index}
                  value={state.answers[index]}
                  onChange={onAnswerChange}
                />
              </S.LabelContainer>

              {/* - Remove an answer */}
              {index > CHATS.SURVEY_DEFAULT_ANSWER - 1 && (
                <S.Remove
                  type="button"
                  data-testid={`survey-remove-answer-${index}`}
                  aria-label="Supprimer une réponse"
                  onClick={removeAnswer(index)}
                >
                  Supprimer
                </S.Remove>
              )}
            </S.Answer>
          ))}
        </fieldset>

        {/* + Add an answer */}
        <S.Add
          type="button"
          data-testid="survey-add-answer"
          aria-label="Ajouter une réponse supplémentaire"
          onClick={addAnswer}
        >
          <S.IconAdd aria-hidden="true" /> <span>Ajouter une réponse</span>
        </S.Add>

        {/* Options */}
        <S.OptionContainer htmlFor={`${id}-multiple`}>
          <S.Checkbox
            type="checkbox"
            id={`${id}-multiple`}
            data-testid="survey-option-multiple"
            onChange={() => onCheckboxChange('multiple')}
            checked={state.multiple}
            aria-label="choix multiples"
          />
          <S.LabelOption>
            <S.CheckboxCustom
              aria-labelledby={`${id}-multiple`}
              tabIndex="0"
              role="checkbox"
              aria-checked={state.multiple}
              onKeyDown={keyboardCheck('multiple')}
            >
              <S.Checked />
            </S.CheckboxCustom>
            <S.Text>Choix multiples</S.Text>
          </S.LabelOption>
        </S.OptionContainer>

        <S.OptionContainer htmlFor={`${id}-share`}>
          <S.Checkbox
            type="checkbox"
            id={`${id}-share`}
            data-testid="survey-option-share"
            onChange={() => onCheckboxChange('shareResult')}
            checked={state.shareResult}
            aria-label="partager les résultats"
          />
          <S.LabelOption>
            <S.CheckboxCustom
              aria-labelledby={`${id}-share`}
              tabIndex="0"
              role="checkbox"
              aria-checked={state.shareResult}
              onKeyDown={keyboardCheck('shareResult')}
            >
              <S.Checked />
            </S.CheckboxCustom>
            <S.Text>Partager les résultats avec les participants</S.Text>
          </S.LabelOption>
        </S.OptionContainer>

        {/* CTA */}
        <S.Buttons>
          <S.Button
            type="button"
            data-testid="survey-ask-cancel"
            isCancel
            aria-label="Annuler le sondage"
            onClick={onClose}
          >
            Annuler
          </S.Button>
          <S.Button
            type="submit"
            data-testid="survey-ask-submit"
            isMain
            aria-label="Envoyer un sondage"
            disabled={!hasPrerequisitesAskSurvey(state.question, state.answers)}
          >
            Envoyer
          </S.Button>
        </S.Buttons>
      </S.Form>
    </S.PollModal>
  );
};

/*
 * PropTypes
 */
CreatePoll.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  display: PropTypes.bool,
};

CreatePoll.defaultProps = {
  display: false,
};

/*
 * Export
 */
export default CreatePoll;
