/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { Emoji } from 'src/components/Format/Formatizer';
import { NO_EMOTION, emotions } from 'src/constants/emotions';

// Style
import * as S from './style';

/*
 * Component
 */
// Props coming from container
const ListEmotions = ({ emotion }) => {
  /*
   * Render
   */
  if (emotion !== NO_EMOTION) {
    return (
      <S.ListEmotions>
        <S.EmojiContainer>
          <Emoji>{emotion}</Emoji>
        </S.EmojiContainer>
        <S.Label>
          {emotions.reduce((feeling, item) => {
            if (item.emoji === emotion) {
              feeling = item.feeling;
            }
            return feeling;
          }, '')}
        </S.Label>
      </S.ListEmotions>
    );
  }
  return <S.ListEmotions />;
};

/*
 * PropTypes
 */
ListEmotions.propTypes = {
  emotion: PropTypes.string,
  connected: PropTypes.bool,
};

ListEmotions.defaultProps = {
  emotion: NO_EMOTION,
  connected: true,
};

/*
 * Export
 */
export default ListEmotions;
