import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

export const Buttons = styled.div({
  width: '18.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
  marginTop: '2rem',

  '& > button:first-of-type': {
    marginRight: '1rem',
  },
});

const getLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.label;
export const Button = styled('button')(({ theme }) => ({
  color: theme.colors.monochrome.white,
  background: theme.button.main.background._1,
  borderRadius: '8px',
  border: 'none',
  minWidth: '8.75rem',
  minHeight: '2.5rem',
  padding: '0 1rem',
  ...getLabelTypoStyle(theme),

  // Hover
  '&:hover': {
    background: theme.button.main.background._2,
    border: 'none',
  },
  // Focus
  '&:focus': {
    background: theme.button.main.background._2,
    border: `2px solid ${theme.primary._2}`,
  },
  // Active
  '&:active': {
    background: theme.button.main.background._3,
    border: 'none',
  },
  // Disable
  '&:disabled': {
    background: theme.background._8,
    color: theme.text._6,
    cursor: 'not-allowed',
  },

  '&.cancel': {
    color: theme.text._4,
    background: theme.button.cancel.background._2,
    boxShadow: 'none',
    minWidth: '8.75rem',
    minHeight: '2.5rem',
    marginRight: '1rem',
  },

  // Cancel Hover
  '&.cancel:hover': {
    color: theme.text._3,
    background: theme.button.cancel.background._1,
    border: 'none',
  },
  // Cancel Focus
  '&.cancel:focus': {
    color: theme.text._4,
    background: theme.button.cancel.background._2,
    border: `2px solid ${theme.primary._1}`,
  },
  // Cancel Active
  '&.cancel:active': {
    color: theme.text._1,
    background: theme.button.cancel.background._1,
    border: 'none',
  },
  // Deactivate
  '&.deactivate': {
    background: theme.danger.background,
    color: theme.danger.main,
  },
}));

export const CopyButton = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '.5rem',
}));

export const CloseButton = styled('button')(({ theme }) => ({
  color: theme.text._4,
  background: theme.button.cancel.background._2,
  borderRadius: '16px',
  position: 'absolute',
  top: '.5rem',
  right: '.5rem',
  display: 'flex',
}));
