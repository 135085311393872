/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Package Import
 */
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

/**
 * Local Import
 */
import { AntMediaWebRTCManager } from 'src/utils/webRTCManager/antMediaWebRTCManager';
import { WebRTCManager } from 'src/utils/webRTCManager/abstractWebRTCManager';
import { WebRTCEvent } from 'src/utils/webRTCManager/webRTCEvent';
import * as actions from 'src/store/actions';
import {
  MediaStreamDictionnary,
  StreamCategory,
} from 'src/utils/webRTCManager/mediaStreamDictionnary';
import { StreamListStatus } from 'src/constants/mediaDevices';
import * as types from '../../types';

/**
 * constants and variables
 */
let webRTCManager: WebRTCManager;

const listenEvents = (instance: WebRTCManager, store: MiddlewareAPI<Dispatch<AnyAction>, any>) => {
  // when we are correctly connected to the server, join the rooms
  instance.addEventListener(WebRTCEvent.WEBRTC_CONNECTED, () => {
    instance.joinAllRooms();
  });

  // when all rooms are joined, dispatch the action.
  instance.addEventListener(WebRTCEvent.WEBRTC_ROOMS_JOINED, () => {
    store.dispatch(
      actions.setWebRTCConnected(
        instance.camAndMicStreamIdentifier,
        instance.screenStreamIdentifier,
      ),
    );
  });

  // when a new Stream is available, dispatch the action.
  instance.addEventListener(WebRTCEvent.NEW_CAM_STREAM_AVAILABLE, () => {
    const streamList = MediaStreamDictionnary.getAllStreamIds(StreamCategory.CAM);
    store.dispatch(actions.setStreamList(streamList, 'cams'));
    store.dispatch(actions.setStreamListStatus('cams', StreamListStatus.PENDING));
  });

  instance.addEventListener(WebRTCEvent.NEW_SCREEN_STREAM_AVAILABLE, () => {
    const streamList = MediaStreamDictionnary.getAllStreamIds(StreamCategory.SCREEN);
    store.dispatch(actions.setStreamList(streamList, 'screen'));
  });

  // when the mic has been disabled...
  instance.addEventListener(WebRTCEvent.LOCAL_MIC_DISABLED, () => {
    store.dispatch(actions.setMicrophoneStatus(false));
    store.dispatch(actions.disconnectTranscription());
  });
  // ...or enabled
  instance.addEventListener(WebRTCEvent.LOCAL_MIC_ENABLED, () => {
    store.dispatch(actions.setMicrophoneStatus(true));
    store.dispatch(actions.connectTranscription());
  });

  // same for webcam
  instance.addEventListener(WebRTCEvent.LOCAL_VIDEO_DISABLED, () => {
    store.dispatch(actions.setUserWebcamMuted(true));
  });
  instance.addEventListener(WebRTCEvent.LOCAL_VIDEO_ENABLED, () => {
    store.dispatch(actions.setUserWebcamMuted(false));
  });

  // When my cam and mic stream starts...
  instance.addEventListener(WebRTCEvent.CAM_MIC_STREAM_STARTED, () => {
    store.dispatch(actions.setStreamListStatus('cams', StreamListStatus.PENDING));
    const streamList = MediaStreamDictionnary.getAllStreamIds(StreamCategory.CAM);
    store.dispatch(actions.setStreamList(streamList, 'cams'));
    store.dispatch(actions.setMyCamAndMicPublishing(true));
    store.dispatch(actions.setStreamListStatus('cams', StreamListStatus.READY));
  });
  // ...and when it stops
  instance.addEventListener(WebRTCEvent.CAM_MIC_STREAM_STOPPED, () => {
    const streamList = MediaStreamDictionnary.getAllStreamIds(StreamCategory.CAM);
    store.dispatch(actions.setStreamList(streamList, 'cams'));
    store.dispatch(actions.setMyCamAndMicPublishing(false));
  });

  // When a stream play starts
  instance.addEventListener(WebRTCEvent.CAM_MIC_PLAY_STARTED, () => {
    store.dispatch(actions.setStreamListStatus('cams', StreamListStatus.READY));
  });

  // same for screen
  instance.addEventListener(WebRTCEvent.SCREEN_STREAM_STARTED, () => {
    store.dispatch(actions.setScreenStatus(true));
  });
  instance.addEventListener(WebRTCEvent.SCREEN_STREAM_STOPPED, () => {
    store.dispatch(actions.setScreenStatus(false));
    store.dispatch(actions.setStreamList([], 'screen'));
  });

  // When localStream is ready from antmedia side
  instance.addEventListener(WebRTCEvent.ANTMEDIA_LOCALSTREAM_READY, () => {
    store.dispatch(actions.setAntmediaLocalStreamReady(true));
  });
};

/*
 * Middleware
 */
const webRTCMiddleware: Middleware = (store) => (next) => (action) => {
  const state = store.getState();

  // INIT !
  switch (action.type) {
    case types.WEBSOCKET_CONNECT_SUCCESS:
      if (!webRTCManager) {
        webRTCManager = new AntMediaWebRTCManager({
          antServer: state.course.antServer,
          courseId: state.course.courseId,
          clientId: state.course.clientId,
          organization: state.organization,
        });
        listenEvents(webRTCManager, store);
      }
      break;

    case types.START_MY_WEBCAM_STREAM:
      webRTCManager?.startMyCamAndMicStream({
        audioOnly: action.audioOnly,
      });
      break;

    case types.STOP_MY_WEBCAM_STREAM:
      webRTCManager?.stopMyCamAndMicStream();
      break;

    case types.DISABLE_MICROPHONE:
      webRTCManager?.disableLocalMic();
      break;

    case types.ENABLE_MICROPHONE:
      webRTCManager?.enableLocalMic();
      break;

    case types.ENABLE_WEBCAM:
      webRTCManager?.enableLocalVideo();
      break;

    case types.DISABLE_WEBCAM:
      webRTCManager?.disableLocalVideo();
      break;

    case types.ENABLE_SCREEN:
      webRTCManager?.startMyScreenStream();
      break;

    case types.DISABLE_SCREEN:
      webRTCManager?.stopMyScreenStream();
      break;

    default:
      break;
  }

  return next(action);
};

export default webRTCMiddleware;
