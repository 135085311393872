/**
 * Packagee Import
 */
import React from 'react';
import { components } from 'react-select';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { OptionTooltip } from '../../type';

export const DropdownIndicator = (
  props: React.ComponentProps<typeof components.DropdownIndicator>,
): JSX.Element => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <UilAngleUp /> : <UilAngleDown />}
  </components.DropdownIndicator>
);

export const CustomOption = (
  props: React.ComponentProps<typeof components.Option>,
): JSX.Element => {
  const option = props.data;
  const allTooltipsData: OptionTooltip[] | undefined = props.selectProps.optionsExtraData;
  const optionTooltipData = allTooltipsData?.find((tooltipData) => tooltipData.id === option.value);

  return optionTooltipData ? (
    <Tooltip content={optionTooltipData.component(optionTooltipData.infos)}>
      <components.Option {...props} />
    </Tooltip>
  ) : (
    <components.Option {...props} />
  );
};

export const MultiValue = (
  props: React.ComponentProps<typeof components.MultiValue>,
): JSX.Element => {
  const option = props.data;
  const allTooltipsData: OptionTooltip[] | undefined = props.selectProps.optionsExtraData;
  const optionTooltipData = allTooltipsData?.find((tooltipData) => tooltipData.id === option.value);

  return optionTooltipData ? (
    <Tooltip content={optionTooltipData.component(optionTooltipData.infos)}>
      <components.MultiValue {...props} />
    </Tooltip>
  ) : (
    <Tooltip content={props.data.label}>
      <components.MultiValue {...props} />
    </Tooltip>
  );
};
