/**
 * Local Import
 */
import { MEDIAS_STREAMS_MAX_LIMIT, StreamListStatus } from 'src/constants/mediaDevices';

/**
 * Get if the max of video flux is reached
 */
type MaxVideoTracksReachedProps = {
  camsStreamList: string[];
  streamListStatus: StreamListStatus;
  usersWebcamMuted: string[];
  userById: {
    [userId: string]: {
      id: string;
      name: string;
      avatar: string | null;
      connected: boolean;
      emotion: string;
      role: string;
    };
  };
};
export const isMaxVideoTracksReached = ({
  camsStreamList,
  streamListStatus,
  usersWebcamMuted,
  userById,
}: MaxVideoTracksReachedProps) => {
  if (streamListStatus === StreamListStatus.PENDING) {
    return true;
  }

  const educatorsMuted = usersWebcamMuted.filter(
    (userId) =>
      userById[userId]
      && userById[userId].connected
      && (userById[userId].role === 'teacher' || userById[userId].role === 'helper'),
  );

  const displayedWebcamsNb = camsStreamList.length - educatorsMuted.length;

  return displayedWebcamsNb === MEDIAS_STREAMS_MAX_LIMIT;
};
