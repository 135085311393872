/*
 * Package Import
 */
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import Chats from 'src/components/Chats/container';
import HotkeysListener from 'src/components/Hotkeys/container';
import { useAction, useChat, useMessage, useReaction, useSurvey } from 'src/components/Classroom/hooks';
import { useWebsocket } from 'src/hooks';

// Context
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';

// Helpers
import { api } from 'src/utils/api';
import { trackEvent } from 'src/utils/tracking';

// Constants
import { CLASSROOM_EXTERNAL_TITLE } from 'src/constants/titles';

// Style
import * as S from 'src/components/Classroom/style';

/*
 * Component
 */
const ExternalChat = ({ actions }) => {
  /*
   * Hooks
   */
  const { course } = useParams();
  const { pathname } = useLocation();
  const webSocket = useWebsocket(course);

  // Socket events listeners
  useAction(webSocket);
  useChat(webSocket);
  useMessage(webSocket);
  useReaction(webSocket);
  useSurvey(webSocket);

  /*
   * Context
   */
  const { initializeData } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  /*
   * State
   */
  const [isLoading, setLoading] = useState(
    /** @type {Boolean} */
    true,
  );
  const isClassroomFetching = useRef(true);

  /**
   * Get initialize data
   * @return {Promise}
   */
  const fetchData = async () => {
    await api({
      method: 'GET',
      url: 'initialize/c/list',
      params: { userId: user.id, courseId: course },
    })
      .then(({ data }) => {
        // 1. Initialize user
        actions.logUserIn(user);

        // 2. Initialize the Redux store
        actions.setInitializeData(data);

        // 3. Initialize data in MessageContext
        initializeData(data.messages);

        // Let's gooooo, start the Chat 🏁 💯 🔥
        setLoading(false);
        isClassroomFetching.current = false;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 404) {
          window.location.href = '/';
        }
      });
  };

  /*
   * LifeCycles
   */
  useEffect(() => {
    document.title = CLASSROOM_EXTERNAL_TITLE;

    // Mixpanel tracking event
    trackEvent('Page Viewed', {
      title: CLASSROOM_EXTERNAL_TITLE,
      url: pathname,
    });
  }, []);

  useEffect(() => {
    // Initialize chat
    if (user && user.id) {
      fetchData();
    }
  }, [course, user]);

  /*
   * Render
   */
  if (isLoading) {
    return <Loader overlay size={48} />;
  }

  return (
    <S.Main role="main" id="main">
      <S.Popout>
        <HotkeysListener />
        <Chats isPopout />
      </S.Popout>
    </S.Main>
  );
};

/*
 * PropTypes
 */
ExternalChat.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
};

/*
 * Export
 */
export default ExternalChat;
