/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Format from '../Format';

/*
 * Component
 */
const Formatizer = ({ children, ...props }) => <Format {...props}>{children}</Format>;

Formatizer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
Formatizer.defaultProps = {
  children: '',
};

/*
 * Export
 */
export default Formatizer;
