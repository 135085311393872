/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, Control, FieldValues } from 'react-hook-form';

/*
 * Local Import
 */
import { Option, OptionTooltip } from 'src/components/Admin/Modals/FormElements/type';
import useStyles from '../../hooks/useSelectStyles';
import { DropdownIndicator, CustomOption, MultiValue } from './components';

/*
 * Code
 */
interface CustomSelectProps {
  data: Option[];
  selectName: string;
  control: Control<FieldValues>;
  defaultValue?: Option[] | Option;
  optionsToDisable?: Option | Option[];
  isMulti: boolean;
  isError: boolean;
  optionsExtraData?: OptionTooltip[];
  mustValidateAtDisplay?: (fieldName: string) => void;
  placeholder?: string;
}

export const CustomSelect = ({
  data,
  selectName,
  control,
  defaultValue,
  optionsToDisable,
  isMulti,
  isError,
  optionsExtraData,
  mustValidateAtDisplay,
  placeholder = 'Sélectionnez',
}: CustomSelectProps): JSX.Element => {
  const [selected, setSelected] = useState();

  const colourStyles = useStyles(data);

  const handleDisableOption = (option: Option): boolean => {
    if (!optionsToDisable) return false;
    if (optionsToDisable instanceof Array) {
      return optionsToDisable.some((optionToDisable) => optionToDisable.value === option.value);
    }
    return optionsToDisable.value === option.value;
  };

  useEffect(() => {
    if (mustValidateAtDisplay) {
      (async () => {
        await mustValidateAtDisplay(selectName);
      })();
    }
  }, [mustValidateAtDisplay, selectName]);

  return (
    <Controller
      as={Select}
      name={selectName}
      styles={colourStyles}
      onChange={setSelected}
      defaultValue={defaultValue || (isMulti ? [] : null)}
      value={selected}
      options={data}
      isMulti={isMulti}
      control={control}
      isOptionDisabled={handleDisableOption}
      isError={isError}
      menuPortalTarget={document.body}
      components={{ DropdownIndicator, Option: CustomOption, MultiValue }}
      placeholder={placeholder}
      optionsExtraData={optionsExtraData}
    />
  );
};
