/*
 * Package Import
 */
import { useEffect, useState } from 'react';

/*
 * Local Import
 */
import { MEDIA_ERRORS } from 'src/constants/mediaDevices';
import devicesMediasRTC, {
  setConstraints,
  getConstraints,
  getDevices,
  getStream,
  getUserMedia,
  trackDeviceChange,
} from 'src/utils/medias';

/**
 * Media devices hooks ⚓️
 *
 * Request authorization to use your devices (MediasAuthorization) in the app.
 * If the user has authorized the devices, he can manage (MediasChoose) his
 * different devices (camera, microphone, speaker). If the user has denied
 * the devices, we display a warning message (MediasDenied).
 *
 * For the userflow, with all possible use-case in intermediate stage :
 * @doc https://whimsical.com/etape-intermediaire-LDcfSmeWSmqPATrzSLKvdR
 */
export const useMediasDevices = ({ cameraId, microphoneId }) => {
  /**
   * State
   */
  const [status, setStatus] = useState(
    /** @type {String} <loading|requestingPermission|requestedPermission> */
    'loading',
  );

  const [mediaPermissionStatus, setMediaPermissionStatus] = useState(
    /** @type {String} <prompt|granted|denied> */
    'prompt',
  );

  const [localStreamError, setLocalStreamError] = useState(
    /**
     * For common getUserMedia errors, see the following link
     * @doc https://blog.addpipe.com/common-getusermedia-errors/
     * @type {Object}
     */
    null,
  );

  /**
   * Get initial constraints
   */
  const initialConstraints = () => {
    const options = {};

    // Get the preferred camera
    if (typeof cameraId === 'string' && cameraId.length) {
      options.video = { deviceId: { ideal: cameraId } };
    }

    // Get the preferred microphone
    if (typeof microphoneId === 'string' && microphoneId.length) {
      options.audio = { deviceId: { ideal: microphoneId } };
    }

    // Override the default constraints with data stored in localStorage
    const constraints = getConstraints(options);
    return constraints;
  };

  /**
   * Ask/get navigator permissions, for user's devices
   * @return {Promise}
   */
  const getAuthorization = async () => {
    const constraints = initialConstraints();
    setConstraints(constraints);

    return getUserMedia(constraints)
      .then(getStream)
      .then(getDevices)
      .then(() => setMediaPermissionStatus('granted'))
      .catch((error) => {
        setLocalStreamError(error);

        // Denied access
        if (
          error.name === MEDIA_ERRORS.NOT_ALLOWED
          || error.name === MEDIA_ERRORS.PERMISSION_DENIED
        ) {
          setMediaPermissionStatus('denied');
        }

        // eslint-disable-next-line no-console
        console.error(`getUserMedia ${error}, ${JSON.stringify(constraints, null, 2)}`);
      });
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    const init = async () => {
      await getDevices();
      await trackDeviceChange();

      setStatus('requestingPermission');

      // No devices ?
      if (!devicesMediasRTC.hasWebcam && !devicesMediasRTC.hasMicrophone) {
        setLocalStreamError({ name: 'nodevices' });
      }
      else if (
        devicesMediasRTC.hasWebcamPermissions
        || devicesMediasRTC.hasMicrophonePermissions
      ) {
        getAuthorization();
        setStatus('requestedPermission');
      }
    };

    /**
     * Initialization
     */
    init();
  }, []);

  /**
   * Render
   */
  return {
    // Actions
    getAuthorization,

    // Values
    localStreamError,
    mediaPermissionStatus,
    status,
  };
};
