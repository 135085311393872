/*
 * Package Import
 */
import produce from 'immer';

/*
 * Local Import
 */
import * as types from 'src/store/types';

/*
 * Initial State
 */
const initialState = {
  isOpen: false,
  isSigninSheetOpen: false,
  isFollowingOpen: false,
  signatureLink: '',
  canSign: false,
  studentNeedSign: [],
  resetIsOpen: false,
  currentTimeslotId: '',
};

/*
 * Reducer for tracking activities (not MixPanel)
 */
export default (state = initialState, action = {}) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.INITIALIZE_DATA: {
        draft.canSign = action.data.signature.canSign;
        draft.studentNeedSign = action.data.signature.edusignStudents;
        draft.currentTimeslotId = action.data.signature.currentTimeslotId;
        break;
      }

      case types.SET_MENU_SIGNATURE: {
        draft.isOpen = action.isOpen;
        break;
      }

      case types.SET_SIGNIN_SHEET_MODAL: {
        draft.signatureLink = '';
        draft.isSigninSheetOpen = action.isOpen;
        break;
      }

      case types.SET_SIGN_FOLLOWING_MODAL: {
        draft.isFollowingOpen = action.isOpen;
        break;
      }

      case types.RECEIVED_SIGNATURE_LINKS: {
        draft.isOpen = false;
        draft.isSigninSheetOpen = true;
        draft.signatureLink = action.signatureLink;
        break;
      }

      case types.SIGNATURE_DONE: {
        draft.canSign = action.isTeacherType;
        draft.studentNeedSign = action.edusignStudents;
        break;
      }

      case types.SIGNATURE_FAIL: {
        draft.isSigninSheetOpen = false;
        draft.signatureLink = '';
        break;
      }

      case types.EDUSIGN_STUDENT_SIGNATURE_NEEDED: {
        draft.canSign = true;
        break;
      }

      case types.SIGNATURE_UPDATE_STUDENTS: {
        draft.studentNeedSign = action.edusignStudents;
        break;
      }

      case types.CONFIRM_RESET_SIGNATURES: {
        draft.resetIsOpen = action.resetIsOpen;
        break;
      }

      case types.SIGNATURES_RESET: {
        draft.studentNeedSign = action.edusignStudents;
        break;
      }

      case types.CHECKED_SIGNATURE_TIMESLOT: {
        if (action.currentTimeslotId !== draft.currentTimeslotId) {
          draft.canSign = false;
          draft.studentNeedSign = [];
        }
        draft.currentTimeslotId = action.currentTimeslotId;
        break;
      }

      default:
        return state;
    }
  });
