/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ConferenceItem from 'src/components/Course/Conference/Item';
import {
  disableMicrophone,
  sendUnraisedHand,
  setConferenceStatus,
  startMyWebcamStream,
  stopMyWebcamStream,
  connectTranscription,
  disconnectTranscription,
  sendTakeVoice,
} from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  clientId: state.users.client.id,
  courseId: state.course.courseId,
  handStatus: state.course.peerById[state.users.client.id]?.hand,
  soundEnabled: state.course.medias.soundEnabled,
  webRTCConnected: state.webRTC.connected,
  streamList: state.webRTC.streamList.cams,
  isLocalStreamReady: state.webRTC.antmediaLocalStream,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      disableMicrophone,
      sendUnraisedHand,
      setConferenceStatus,
      startMyWebcamStream,
      stopMyWebcamStream,
      connectTranscription,
      disconnectTranscription,
      sendTakeVoice,
    },
    dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(ConferenceItem);
