/* eslint-disable no-console */
/* eslint-disable brace-style */
/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import { getRoute } from 'src/utils/routes';
import { MongoId } from 'src/schemas/Entities/utils';
import * as F from 'src/components/Admin/Modals/FormElements';
import {
  Promotion,
  promotionActivityExportFormDataSchema,
  PromotionActivityExportFormData,
  PromotionActivityExportFormDataValidated,
} from 'src/schemas/Entities/Promotion';
import { fetchPromotionActivity } from 'src/serverStateManagers/queries/Promotions/activity/fetchPromotionActivity';
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { isAfterOrEqual } from 'src/utils/time';
import { promotionsActivityFormData } from './config';
import { getMinDate, getMaxDate } from './utils';

/*
 * Code
 */
type ActivityExportProps = {
  isOpen: boolean;
  idToExport: MongoId | null;
};

const ActivityExport = ({ isOpen, idToExport }: ActivityExportProps): JSX.Element => {
  const history = useHistory();

  const { data: promotionsData } = useGetPromotions();
  const [promotionData, setPromotionData] = useState(
    promotionsData?.find((promotion: Promotion) => promotion.id === idToExport),
  );
  useEffect(() => {
    setPromotionData(promotionsData?.find((promotion: Promotion) => promotion.id === idToExport));
  }, [promotionsData, idToExport]);

  // Config
  const { header, inputs } = promotionsActivityFormData;
  const { promotionId: promotionIdField, start: startField, end: endField } = inputs;

  const [activityFormData, setActivityFormData] = useState(
    null as PromotionActivityExportFormDataValidated | null,
  );

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    reset,
  } = useForm({
    resolver: zodResolver(promotionActivityExportFormDataSchema),
  });

  useEffect(() => {
    const today = new Date();
    if (promotionData && isAfterOrEqual(promotionData.start, today)) {
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [history, promotionData]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  const onSubmit = (data: PromotionActivityExportFormData): void => {
    setActivityFormData(data);
  };

  useEffect(() => {
    (async () => {
      try {
        if (activityFormData && activityFormData?.promotionId) {
          const {
            promotionId: promotionIdFormData,
            start: startFormData,
            end: endFormData,
          } = activityFormData;
          const data = await queryClient.fetchQuery(
            ['activity', promotionIdFormData, { startFormData, endFormData }],
            () =>
              fetchPromotionActivity(promotionIdFormData, {
                start: startFormData,
                end: endFormData,
              }),
          );

          const url = window.URL.createObjectURL(new Blob([data.file]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data.promoName}-${startFormData}-${endFormData}.csv`);
          document.body.appendChild(link);
          link.click();

          toast('Fichier téléchargé', { type: 'success' });
        }
        // eslint-disable-next-line brace-style
      } catch (error) {
        // eslint-disable-next-line no-console
        console.trace(error);
        toast('Erreur au chargement des données', { type: 'error' });
      }
    })();
  }, [activityFormData]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    clearErrors();
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>
        <F.Field>
          <F.Input
            ref={register}
            name={promotionIdField.title}
            type={promotionIdField.type}
            defaultValue={idToExport ?? undefined}
          />
        </F.Field>
        <F.Field>
          <F.Label htmlFor={startField.title}>{startField.display}</F.Label>
          <F.Input
            name={startField.title}
            type={startField.type}
            ref={register}
            className={errors.start ? 'is-error' : undefined}
            defaultValue={promotionData ? startField.defaultValue(promotionData) : undefined}
            min={promotionData ? getMinDate(promotionData) : undefined}
            max={promotionData ? getMaxDate(promotionData) : undefined}
          />
          {errors.start?.message && <F.ErrorMessage>{errors.start.message}</F.ErrorMessage>}
        </F.Field>
        <F.Field>
          <F.Label htmlFor={endField.title}>{endField.display}</F.Label>
          <F.Input
            name={endField.title}
            type={endField.type}
            ref={register}
            className={errors.end ? 'is-error' : undefined}
            defaultValue={promotionData ? endField.defaultValue(promotionData) : undefined}
            min={promotionData ? getMinDate(promotionData) : undefined}
            max={promotionData ? getMaxDate(promotionData) : undefined}
          />
          {errors.end?.message && <F.ErrorMessage>{errors.end.message}</F.ErrorMessage>}
        </F.Field>

        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default ActivityExport;
