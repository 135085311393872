/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Transcriptions from 'src/components/Course/Transcriptions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  transcriptions: state.transcription.data,
});

const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Transcriptions);
