/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import Profile from 'src/components/Users/Profile/container';
import UsersPanel from 'src/components/Users/UsersPanel/container';

// Style
import * as S from './style';

/*
 * Component
 */
const Users = () => (
  <S.Users>
    <Profile />
    <UsersPanel />
  </S.Users>
);

/*
 * Export
 */
export default Users;
