/* eslint-disable max-len,react/jsx-key */

/*
 * Package Import
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useVirtual } from 'react-virtual';
import { flexRender } from '@tanstack/react-table';
/*
 * Local Import
 */
import * as T from 'src/components/Admin/TableElements';
import * as S from './style';

/*
 * Code
 */
const UpdatePromotionUsersTable = ({ tableInstance, rowIsDisabled, isPromotionChild }) => {
  const {
    getHeaderGroups,
    getRowModel,
    getVisibleFlatColumns,
    getColumn,
    getFilteredRowModel,
    getState,
    setGlobalFilter,
  } = tableInstance;

  const tableContainerRef = useRef(null);
  const { filterTypes, globalFilter } = getState();
  const headerGroups = getHeaderGroups();
  const visibleColumns = getVisibleFlatColumns();
  const { rows } = getRowModel();

  const usersColumn = getColumn('users');

  /**
   * useVirtual nous sert à virtualiser les lignes du tableau pour ne pas tout render d'un coup
   * https://tanstack.com/table/v8/docs/examples/react/virtualized-rows
   */
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <>
      {usersColumn
        && filterTypes[usersColumn.id]
        && filterTypes[usersColumn.id].getFilter({ column: usersColumn })}
      <S.TableContainer ref={tableContainerRef}>
        <S.TableRoot>
          <colgroup>
            <col style={{ width: '8%' }} />
            {visibleColumns.map((column) => (
              <col key={column.id} style={{ width: `${100 / (visibleColumns.length - 1)}%` }} />
            ))}
          </colgroup>
          <T.TableHead>
            {headerGroups.map((headerGroup) => (
              <T.TableRowWithoutHover key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <T.TableCellHeader key={header.id}>
                    <T.HeaderContainer key={header.id}>
                      <T.Header>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </T.Header>
                    </T.HeaderContainer>
                  </T.TableCellHeader>
                ))}
              </T.TableRowWithoutHover>
            ))}
          </T.TableHead>
          <S.TableBody>
            {!getFilteredRowModel().rows.length && (
              <S.TableRow>
                <S.TableCell colSpan={visibleColumns.length} style={{ textAlign: 'center' }}>
                  <div>Aucun résultat trouvé</div>
                </S.TableCell>
              </S.TableRow>
            )}
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <S.TableRow
                  key={row.index}
                  disabled={rowIsDisabled ? rowIsDisabled(row.original) : false}
                >
                  {row.getVisibleCells().map((cell) => (
                    <S.TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </S.TableCell>
                  ))}
                </S.TableRow>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </S.TableBody>
        </S.TableRoot>
        {isPromotionChild && (
          <T.AllUsersCheckbox globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        )}
      </S.TableContainer>
    </>
  );
};

UpdatePromotionUsersTable.propTypes = {
  tableInstance: PropTypes.object.isRequired,
  rowIsDisabled: PropTypes.func.isRequired,
  isPromotionChild: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default UpdatePromotionUsersTable;
