/**
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';
import { UilArrowUp, UilArrowDown } from '@iconscout/react-unicons';

/**
 * Styles
 */
export const ArrowUp = styled(UilArrowUp)(() => ({
  display: 'inline-block',
  position: 'absolute',
  bottom: '-3px',
}));

export const ArrowUpHover = styled(ArrowUp)(({ theme }) => ({
  color: theme.text._6,
}));

export const ArrowDown = styled(UilArrowDown)(() => ({
  display: 'inline-block',
  position: 'absolute',
  bottom: '-3px',
}));

export const ArrowDownHover = styled(ArrowDown)(({ theme }) => ({
  color: theme.text._6,
}));

export const ArrowContainer = styled.span(() => ({
  position: 'relative',
}));

/**
 * Types
 */
interface ArrowSortProp {
  direction: boolean;
}

/**
 * Components
 */
export const ArrowSort = ({ direction }: ArrowSortProp): JSX.Element => (
  <ArrowContainer>{direction ? <ArrowUp /> : <ArrowDown />}</ArrowContainer>
);

export const ArrowHover = ({ direction }: ArrowSortProp): JSX.Element => (
  <ArrowContainer>{direction ? <ArrowUpHover /> : <ArrowDownHover />}</ArrowContainer>
);
