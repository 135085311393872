/**
 * Constants Courses List
 */

/**
 * COURSE ROLE
 */
export const COURSE_ROLE = Object.freeze({
  TEACHER: 'teacher',
  HELPER: 'helper',
  GHOST: 'ghost',
  STUDENT: 'student',
});

/**
 * COURSE STATUS
 */
export const COURSE_STATUS = Object.freeze({
  ONGOING: 'ongoing',
  PAUSED: 'paused',
  PENDING: 'pending',
  COMING: 'coming',
});

/**
 * COURSE JOIN STATUS
 */
export const COURSE_JOIN_STATUS = Object.freeze({
  MAX_PARTICIPANTS_LIMIT_REACHED: 'maxParticipantsLimitReached',
  BEFORE: 'before',
  AFTER: 'after',
  BETWEEN: 'between',
});

/**
 * COURSE JOIN MESSAGE
 */
export const COURSE_JOIN_MESSAGE = Object.freeze({
  [COURSE_JOIN_STATUS.MAX_PARTICIPANTS_LIMIT_REACHED]:
    'La classe virtuelle est remplie ! Veuillez contacter l’administrateur pour augmenter cette limite et vous connecter à la classe virtuelle',
  [COURSE_JOIN_STATUS.BEFORE]:
    'Le cours n’a pas commencé. Accédez à la classe virtuelle 15 minutes avant le cours.',
  [COURSE_JOIN_STATUS.AFTER]: 'Le cours est terminé, vous ne pouvez plus vous y connecter.',
  [COURSE_JOIN_STATUS.BETWEEN]:
    'La prochaine séance n’a pas encore commencé. Accédez à la classe virtuelle 15min avant le cours.',
});

// Time in milliseconds for join course before and after timeslots date
export const EXTENDED_TIMESLOT_TIME = 15 * 60 * 1000;
