import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.label;
const getSmallLabelTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.smallLabel;
const getSmallBodyTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.bodyText.smallBody;

export const Label = styled('label')(({ theme }) => ({
  ...getLabelTypoStyle(theme),
}));

export const LabelOptionnal = styled('span')(({ theme }) => ({
  color: theme.text._4,
  marginLeft: '.25rem',
  ...getSmallLabelTypoStyle(theme),
}));

export const DeactivateLabel = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '1rem',
  ...getSmallLabelTypoStyle(theme),
}));

export const SmallBody = styled('p')(({ theme }) => ({
  textAlign: 'center',
  ...getSmallBodyTypoStyle(theme),
}));
