/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.background._2,
  padding: '0 2rem 4rem 2rem',
  height: 'calc(100vh - 7rem)',
  overflow: 'scroll',
  maxWidth: '100%',
  width: '100%',
}));
