/**
 * Package Import
 */
import * as z from 'zod';

// ENTITIES
export const entities = {
  courses: 'courses',
  users: 'users',
  promotions: 'promotions',
} as const;

export const entityEnum = z.nativeEnum(entities);

export type EntityEnum = z.infer<typeof entityEnum>;

export const declinationStrings = [
  {
    name: 'courses',
    singular: 'cours',
    plural: 'cours',
    gender: 'male',
  },
  {
    name: 'users',
    singular: 'utilisateur',
    plural: 'utilisateurs',
    gender: 'male',
    femaleVariants: {
      singular: 'utilisatrice',
      plural: 'utilisatrices',
    },
  },
  {
    name: 'promotions',
    singular: 'promotion',
    plural: 'promotions',
    gender: 'female',
  },
];
