/*
 * Package Import
 */
import styled from '@emotion/styled';
import { DialogTitle, Dialog } from '@material-ui/core';

/*
 * Local Import
 */
import { base } from 'src/theme';
/*
 * Style
 */

export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter !important',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: 'auto',
  '.MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
    position: 'relative',
    minWidth: '24rem',
    minHeight: 'unset',
    maxWidth: 'unset',
    background: theme.background._2,
    border: `2px solid ${theme.text._7}`,
    borderRadius: '16px',
    ...theme.shadows.plus3,
  },
}));

export const Title = styled(DialogTitle)(({ theme }) => ({
  padding: '0 !important',
  h2: {
    textAlign: 'center',
    color: theme.text._1,
    ...theme.typo.headlines.H5,
    marginBottom: '2rem',
  },
}));

export const Form = styled.form`
  display: contents;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ContainerFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.label(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  paddingBottom: '.5rem',
  color: theme.text._1,
  ...theme.typo.labels.smallLabel,
}));

export const Select = styled.select(({ theme, error }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '6.18rem',
  height: '2.5rem',
  border: '1px solid',
  borderColor: error ? theme.danger.main : theme.text._6,
  background: theme.background._2,
  color: theme.text._1,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: '0.5rem 0.75rem',
  margin: 'auto',
  marginBottom: '1.5rem',
  option: {
    background: theme.background._2,
    color: theme.text._1,
    padding: '8px 0px 0px 12px',
  },

  ...theme.focusVisible,
}));

export const Textarea = styled.textarea(({ theme }) => ({
  resize: 'auto',
  margin: 'auto',
  marginBottom: '2rem',
  padding: '.5rem',
  minHeight: '7.5rem',
  minWidth: '80%',
  borderRadius: '8px',
  border: `1px solid ${theme.text._7}`,
  color: theme.text._1,
  fontFamily: 'Inter !important',
  background: theme.background._3,
  ...theme.typo.bodyText.body,
  '&::placeholder': {
    color: theme.text._6,
  },
  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const Button = styled.button(({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '5.80rem',
  flex: 1,

  ':first-child': {
    marginRight: '1rem',
  },
}));

export const FormAction = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  padding: 0;
`;

export const ErrorHelper = styled.span(({ theme }) => ({
  margin: '0 .5rem',
  paddingBottom: '.5rem',
  textAlign: 'center',
  color: theme.danger.main,
  ...theme.typo.labels.tinyLabel,
}));
