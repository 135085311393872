/**
 * Package Import
 */
import { createMachine } from 'xstate';

/*
 * xState
 */
export const classroomMachine = createMachine({
  id: 'applicationState',
  initial: 'started',
  states: {
    started: {
      on: {
        JOINED: 'joined',
      },
    },
    joined: {
      on: {
        LEAVING: 'leaving',
      },
    },
    leaving: {
      on: {
        LEAVED: 'leaved',
        JOINED: 'joined',
      },
    },
    leaved: {},
  },
});
