/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Users = styled('div')((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100vh', // Needed to scroll in users list
  // minWidth: '164px',
  width: '100%',
  backgroundColor: props.theme.background._2,
}));
