/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

/*
 * Styles
 */
export const TagsList = styled.div(() => ({
  display: 'flex',
  maxWidth: '200px',
  flexWrap: 'wrap',

  '> div': {
    margin: '0.3rem',
  },
}));

const getTinyLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;
export const Tag = styled.span(({ theme }) => ({
  backgroundColor: theme.background._4,
  margin: '0.3rem',
  borderRadius: '25px',
  padding: '0.2rem 0.4rem',
  color: theme.text._4,
  whiteSpace: 'nowrap',
  ...getTinyLabelStyle(theme),
}));
