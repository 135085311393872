/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */

export const Container = styled.div`
  width: 100%;

  span.mention {
    color: ${({ theme }) => theme.primary._1};
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  a {
    color: ${({ theme }) => theme.primary._2};
  }

  div.quill {
    border: 1px solid ${({ theme }) => theme.background._5};
    border-radius: 8px;
    padding: 0.25rem;
    width: 100%;

    :focus-within {
      border: 2px solid ${({ theme }) => theme.primary._1};

      .ql-container {
        border: none;
      }
    }

    :hover {
      border: 2px solid ${({ theme }) => theme.background._6};

      .ql-container {
        border: none;
      }
    }
  }

  div.ql-container {
    position: initial;
    width: 100%;
    max-height: 240px;
    overflow: hidden scroll;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.background._2};
    border: 1px solid transparent;
    padding-right: 2.25rem;
    font-size: 16px;

    scrollbar-color: ${({ theme }) => theme.text._7} transparent;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    ::-webkit-scrollbar-track {
      background: none;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.background._7};
      border-radius: 5px;
    }

    .ql-emoji {
      width: 1.25rem;
      margin-right: 0.125rem;
      margin-left: 0.125rem;
      height: auto;
    }

    .ql-editor {
      position: relative;
    }

    .ql-editor::before {
      font-weight: 400;
      color: ${({ theme }) => theme.text._4};
      font-style: normal;
      width: 231px;
    }

    .ql-tooltip {
      display: none;
    }
  }

  .ql-editor > code {
    display: inline-block;
    color: ${({ theme }) => theme.text._1};
    padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.background._6};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.background._4};
  }

  .ql-editor > pre {
    display: inline-block;
    color: ${({ theme }) => theme.text._1};
    padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.background._5};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.background._2};
  }

  .ql-mention-list-container {
    z-index: 10;
    left: 0 !important;
    top: -306px !important;
    width: 311px;
    height: 296px;
    overflow: auto;
    background-color: ${({ theme }) => theme.background._2};
    border: 1px solid ${({ theme }) => theme.background._5};
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
      0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 8px;

    ::-webkit-scrollbar {
      display: none;
    }

    .ql-mention-list-item {
      width: 296px;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: Inter;
      font-size: 16px;
      line-height: 150%;
      border-radius: 8px;
      padding: 0 8px;
      color: ${({ theme }) => theme.text._1};
      cursor: pointer;

      &.selected {
        background-color: ${({ theme }) => theme.background._3};
      }
    }
  }
`;
