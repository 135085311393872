/*
 * Package Import
 */
import React, { useState } from 'react';

// Theming
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import {
  ThemeProvider as CrumbleThemeProvider,
  ThemeContext as CrumbleThemeContext,
} from '@oclock/crumble';

/*
 * Local Import
 */
import themes, { ThemeInterface } from 'src/theme/slippersTheme/themes';
import { getStorage } from 'src/utils/storage';

/**
 * Types
 */
type ThemeProviderProps = {
  children: React.ReactChild;
};

/**
 * Context
 */
export const ThemeProvider = ({
  children,
}: ThemeProviderProps): React.ReactElement<ThemeInterface> => {
  /**
   * Size
   */
  const size = getStorage('middleware_settings_layout').textSize || 1;
  const [textSize, setTextSize] = useState(size);
  const chooseTextSize = (newSize: number): void => setTextSize(newSize);

  /**
   * Render
   */
  return (
    <CrumbleThemeProvider>
      {/*
       * @TODO pour le moyen/long terme :
       * Supprimer le Consumer + le ThemeProvider de Émotion.
       *
       * Le Consumer est là en attendant de faire complétement la transition
       * vers Crumble, afin de faire cohabiter les deux Provider ensemble.
       */}
      <CrumbleThemeContext.Consumer>
        {({ theme, setTheme }) => {
          const themeName = theme ?? 'light';
          const currentTheme = themes[themeName];

          return (
            <EmotionThemeProvider
              theme={{
                ...currentTheme,
                themeName,
                chooseTheme: setTheme,
                textSize,
                chooseTextSize,
              }}
            >
              {children}
            </EmotionThemeProvider>
          );
        }}
      </CrumbleThemeContext.Consumer>
    </CrumbleThemeProvider>
  );
};
