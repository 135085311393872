import React from 'react';

interface SettingsOption {
  icon?: React.ReactNode;
  label: string;
  value: unknown;
}

/**
 * Apparence du chat
 */
export const chatAppearanceOptions: SettingsOption[] = [
  {
    label: 'Aéré',
    value: 'spaced',
  },
  {
    label: 'Compact',
    value: 'compact',
  },
];

/**
 * Largeur du chat
 */
export const chatSizeOptions: SettingsOption[] = [
  {
    label: 'Etroit',
    value: 'thin',
  },
  {
    label: 'Large',
    value: 'large',
  },
];

/**
 * Taille des messages
 */
export const textSizeOptions: SettingsOption[] = [
  {
    label: 'Petit',
    value: 0.75,
  },
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: 'Grand',
    value: 1.5,
  },
];

/**
 * Affichage des participants
 */
export const userListStyleOptions: SettingsOption[] = [
  {
    label: 'Liste',
    value: 'list',
  },
  {
    label: 'Colonne',
    value: 'grid',
  },
];
