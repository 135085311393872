/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilExternalLinkAlt } from '@iconscout/react-unicons';
import { ReactComponent as Magic } from 'assets/images/icons/magic.svg';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Icon
 */
const iconExternalWindowStyle = (theme) => ({
  color: theme.text._5,
  height: '1rem',
  width: '1rem',
});

export const IconExternalWindow = styled(UilExternalLinkAlt)(({ theme, isactive }) => ({
  ...iconExternalWindowStyle(theme, isactive),
}));

export const IconMagic = styled(Magic)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  color: theme.primary._1,
}));

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  background: theme.background._2,
}));

export const SubTitle = styled.h3(({ theme, isActive }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.text._5,
  display: 'flex',
  fontWeight: 500,
  justifyContent: 'center',
  height: '3rem',
  position: 'relative',

  ...(isActive && {
    color: theme.primary._1,
    '::before': {
      content: '""',
      background: theme.primary._1,
      borderRadius: '50px',
      width: '100%',
      height: '2px',
      position: 'absolute',
      bottom: '0px',
      zIndex: '2',
    },
  }),
}));

export const Tabs = styled.ul`
  display: flex;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.text._7};
  justify-content: space-evenly;
`;

export const Tab = styled.li(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  height: '3rem',
  margin: '0 0.5em',
  outline: 'none',
  ...theme.typo.labels.label,

  ':hover': {
    [SubTitle]: {
      color: theme.primary._2,
      '::before': {
        background: theme.primary._2,
      },
    },
  },

  // focus-visible dont work properly on chromium with drawer
  ':focus': {
    '::after': {
      content: '""',
      position: 'absolute',
      top: '4px',
      right: '-8px',
      bottom: '4px',
      left: '-8px',
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },
  },
  ':-moz-focusring': {
    '::after': {
      content: '""',
      position: 'absolute',
      top: '4px',
      right: '-8px',
      bottom: '4px',
      left: '-8px',
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },
  },
}));

export const ExtWindowTab = styled.button(({ theme }) => ({
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '1.25rem',
  width: '2rem',
  position: 'absolute',
  top: '4px',
  right: '0',
  outline: 'none',

  ':hover': {
    [IconExternalWindow]: {
      color: theme.primary._1,
    },
  },
  ':focus-visible': {
    '::after': {
      content: '""',
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '-4px',
      left: '8px',
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },
  },
  ':-moz-focusring': {
    '::after': {
      content: '""',
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '-4px',
      left: '8px',
      border: `2px solid ${theme.primary._1}`,
      borderRadius: '8px',
    },
  },
}));

export const Button = styled.button(({ isMain, theme }) => ({
  ...base.button({ isMain, theme }),
  padding: '.5rem 1rem',
  borderRadius: '50px',
  margin: '1.5rem auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TitleSrOnly = styled.h2(({ theme }) => ({
  ...theme.srOnly,
}));
