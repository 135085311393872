/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { PromotionFormDataValidated, PromotionDTO } from 'src/schemas/Entities/Promotion';
import { createPromotion } from './createPromotion';

/*
 * Code
 */
export const useCreatePromotion = (): UseMutationResult<
PromotionDTO,
unknown,
PromotionFormDataValidated,
unknown
> => {
  const PromotionMutation = useMutation(
    (PromotionFormData: PromotionFormDataValidated) => createPromotion(PromotionFormData),
    {
      onSettled: () => {
        queryClient.invalidateQueries('promotions');
      },
      onSuccess: () => {
        toast('Promotion créée avec succès !', { type: 'success' });
      },
    },
  );

  return PromotionMutation;
};
