/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
/*
 * Style
 */
export const UnreadNotification = styled.div(({ theme }) => ({
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
  borderRadius: '100%',
  textAlign: 'center',
  height: '1rem',
  width: '1rem',
  zIndex: 10,
  color: theme.colors.monochrome.white,
  background: theme.danger.main,
  marginLeft: '0.375rem',
  ...theme.typo.bodyText.veryTinyText,
}));
