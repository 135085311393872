/*
 * Package Import
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/*
 * Local Import
 */
import { getRoute } from 'src/utils/routes';
import { Modal } from 'src/components/Admin/Modals/Modal';
import { useStore } from 'src/localStateManagers/store';
import CsvUpload from './Upload';
import Confirmation from './Confirmation';
import { Option } from '../../FormElements/type';

/*
 * Code
 */
type AddMultipleUsersProps = {
  isOpen: boolean;
};
const AddMultipleUsers = ({ isOpen }: AddMultipleUsersProps): JSX.Element => {
  const history = useHistory();
  const { newUsers } = useStore();

  const [promotionSelected, setPromotionSelected] = useState(null as Option | null);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('usersTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <>
        {Array.isArray(newUsers) && newUsers.length === 0 && (
          <CsvUpload setPromotionSelected={setPromotionSelected} />
        )}
        {Array.isArray(newUsers) && newUsers.length > 0 && (
          <Confirmation promotionSelected={promotionSelected} />
        )}
      </>
    </Modal>
  );
};

export default AddMultipleUsers;
