/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { API_DEACTIVATE_COURSE, API_PATCH } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const deactivateCourse = async (courseId: string): Promise<void> => {
  try {
    const payload: Payload = {
      method: API_PATCH,
      url: API_DEACTIVATE_COURSE,
      data: {
        _id: courseId,
      },
    };

    await api(payload);
  }
  catch (error) {
    toast(`Erreur pendant l'archivage : ${error}`, { type: 'error' });
    throw error;
  }
};
