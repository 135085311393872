/*
 * Package Import
 */
import { colors as themeColors, colorsRGB as themeColorsRGB } from '@o-clock-dev/theme-oclock';

/*
 * Basic Colors
 */
export const colors = {
  ...themeColors,

  // Theme
  primary: '',
  second: '',
};

/*
 * RGB Colors
 */
export const colorsRGB = {
  ...themeColorsRGB,
};
