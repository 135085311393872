/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import Modal from 'src/components/Modal';

/*
 * Style
 */
export const SignatureModal = styled(Modal)({});

export const SignatureContent = styled.div(({ theme }) => ({
  width: '24rem',
  backgroundColor: theme.background._2,
  padding: '1rem',
  borderRadius: '8px',
}));

export const Header = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0.125rem 0.25rem 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
`;

export const Title = styled.h2(({ theme }) => ({
  cursor: 'default',
  color: theme.text._1,
  ...theme.typo.labels.label,

  marginBottom: '0.5rem',
}));

export const Text = styled.p(({ theme }) => ({
  color: theme.text._3,
  ...theme.typo.bodyText.tinyText,
}));

export const Actions = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  button: {
    marginTop: '1rem',
  },

  '#start-signature': {
    backgroundColor: '#FEC007',
    border: '2px solid #FEC007',
    color: theme.text._1,

    '&:hover': {
      background: 'none',
    },

    '&:disabled': {
      color: theme.text._5,
      backgroundColor: theme.background._5,
      borderColor: theme.background._5,
    },
  },
}));

export const FollowingSignature = styled.div(({ theme }) => ({
  ...theme.typo.bodyText.tinyText,
  color: theme.primary._1,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

export const FollowingSignatureBar = styled.div(({ theme, currentSignatures, totalStudents }) => ({
  position: 'relative',
  width: '100%',
  height: '4px',
  backgroundColor: theme.background._5,
  borderRadius: '6px',
  overflow: 'hidden',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: `${(currentSignatures / totalStudents) * 100}%`,
    height: '4px',
    backgroundColor: theme.primary._1,
    borderRadius: '6px',
  },
}));
