/**
 * Shortcuts keys
 */
export default [
  // INTERFACE
  {
    title: 'Interface',
    actions: [
      {
        label: 'Activer / Désactiver le mode nuit',
        keys: ['Alt', 'Shift', 'Y'],
      },
      {
        label: 'Ouvrir / fermer le chat',
        keys: ['Alt', 'Shift', 'C'],
      },
      {
        label: 'Ouvrir la liste des participants',
        keys: ['Alt', 'Shift', 'G'],
      },
      {
        label: 'Réinitialiser l’interface',
        keys: ['Alt', 'Shift', 'R'],
      },
    ],
  },
  // Médias
  {
    title: 'Médias',
    actions: [
      // Not implemented yet
      // {
      //   label: 'Changer de périphérique',
      //   keys: ['Alt', 'Shift', 'U'],
      // },
      {
        label: 'Demander la parole',
        keys: ['Alt', 'R'],
      },
      {
        label: 'Activer / Couper le micro',
        keys: ['Alt', 'U'],
      },
      {
        label: 'Activer / Désactiver sa webcam',
        keys: ['Alt', 'W'],
      },
    ],
  },
  // Messagerie
  {
    title: 'Messagerie',
    actions: [
      {
        label: 'Snippet',
        keys: ['Alt', 'I'],
      },
      {
        label: 'Texte en italique ',
        keys: ['Ctrl', 'I'],
      },
      {
        label: 'Texte en gras',
        keys: ['Ctrl', 'B'],
      },
      {
        label: 'Emoji',
        keys: [':nom-de-l-emoji:'],
      },
      {
        label: 'Retour à la ligne',
        keys: ['Shift', 'Enter'],
      },
      {
        label: 'Identifier un utilisateur',
        keys: ['@', "Nom de l'utilisateur"],
      },
      {
        label: 'Afficher le chat',
        keys: ['Alt', 'Shift', 'X'],
      },
      {
        label: 'Écrire un message',
        keys: ['Alt', 'X'],
      },
      {
        label: 'Envoyer le message en spoiler',
        keys: ['Alt', 'Shift', 'Enter'],
      },
    ],
  },
  // Sondages
  {
    title: 'Sondages',
    actions: [
      {
        label: 'Afficher les sondages',
        keys: ['Alt', 'Shift', 'P'],
      },
      {
        label: 'Créer un sondage',
        keys: ['Alt', 'P'],
      },
    ],
  },
  // Autres fonctionnalitées
  {
    title: 'Divers',
    actions: [
      {
        label: 'Afficher les raccourcis clavier',
        keys: ['Alt', 'K'],
      },
      {
        label: 'Ouvrir les notifications',
        keys: ['Alt', 'V'],
      },
      {
        label: 'Activer / Désactiver les notifications',
        keys: ['Alt', 'Shift', 'V'],
      },
      {
        label: 'Prendre une pause',
        keys: ['Alt', 'Z'],
      },
      {
        label: 'Quitter le cours',
        keys: ['Alt', 'E'],
      },
    ],
  },
];
