/**
 * Package Import
 */
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

/**
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

interface ScrollShadowContainerProps {
  isTop: boolean;
  theme: Theme;
}

const ScrollShadowContainer = styled.div(({ isTop, theme }: ScrollShadowContainerProps) => ({
  zIndex: 1,
  ...(!isTop && theme.themeName === LIGHT_THEME_NAME && { ...theme.shadows.plus2 }),
}));

export default ScrollShadowContainer;
