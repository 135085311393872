/*
 * Package Import
 */
import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingFn,
  Row,
  Column,
  ColumnDef,
  CellContext,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

/*
 * Local Import
 */

import Table from 'src/components/Admin/Tables/Table';
import * as T from 'src/components/Admin/TableElements';
import { sortDateTable, dateFilterMethod, noMatchCaseSort } from 'src/utils/table';
import { status, defaultStatusFilterValues } from 'src/constants/status';
import { Course } from 'src/schemas/Entities/Course';
import { getStatus, getSortedTimeslots, getAllDurationFromTimeslots } from './utils';

/*
 * Code
 */

// Filters
// Define a default UI for filtering
const DefaultFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<Course, unknown>;
}) => (
  <T.FilterText
    filterValue={getFilterValue() as string | null | undefined}
    setFilter={setFilterValue}
    header={columnDef.header as string}
  />
);

const StatusFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<Course, unknown>;
}) => (
  <T.FilterCheckbox
    filterValue={getFilterValue() as (string | null | undefined)[]}
    setFilter={setFilterValue}
    options={status}
    header={columnDef.header as string}
  />
);

const DateFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<Course, unknown>;
}) => (
  <T.FilterDate
    filterValue={getFilterValue() as Date}
    setFilter={setFilterValue}
    header={columnDef.header as string}
  />
);

// Cells components
const PromotionCell = ({ row }: { row: Row<Course> }) =>
  row.original?.promotion
  && (row.original.promotion.deactivatedAt ? (
    <T.DeactivatedRelation
      content={row.original.promotion.displayName || ''}
      withTag
      tooltipContent="Promotion archivée"
    />
  ) : (
    <T.Tag>{row.original.promotion.displayName}</T.Tag>
  ));

const TeacherCell = ({ row }: { row: Row<Course> }) =>
  row.original?.teacher
  && (row.original.teacher.deactivatedAt ? (
    <T.DeactivatedRelation
      content={`${row.original.teacher.firstname} ${row.original.teacher.lastname}`}
      withTag={false}
      tooltipContent="Utilisateur archivé"
    />
  ) : (
    `${row.original.teacher.firstname} ${row.original.teacher.lastname}`
  ));

const StatusCell = ({ row }: { row: Row<Course> }) =>
  row.original && <T.Status status={getStatus(row.original)} />;

const TimeslotsCell = ({ row }: { row: Row<Course> }) =>
  row.original
  && `${row.original.timeslots.length} séance${row.original.timeslots.length > 1 ? 's' : ''}`;

const TimeslotsHoursCell = ({ row }: { row: Row<Course> }) => {
  if (!row.original?.timeslots.length) {
    return '';
  }

  const timeslotsSorted = getSortedTimeslots(row.original.timeslots);

  return `${format(timeslotsSorted[0].start, "H'h'mm")} - ${format(
    timeslotsSorted[timeslotsSorted.length - 1].end,
    "H'h'mm",
  )}`;
};

const TimeslotsDurationCell = ({ row }: { row: Row<Course> }) => {
  if (!row.original) {
    return '';
  }
  const timeslotsSorted = getSortedTimeslots(row.original.timeslots);
  return getAllDurationFromTimeslots(timeslotsSorted);
};

// Hook receiving datas and returning corresponding Table and tableInstance
const useCourseTable = (newData: Course[] = []) => {
  const [data, setData] = useState(newData);
  const skipPageResetRef = useRef(true);

  // If we receive new Data, we update table data without reset pagination, sort, filters...
  useEffect(() => {
    skipPageResetRef.current = true;
    setData(newData);
  }, [newData]);

  // And after a little delay, we reactive reset pagination for sort, search, filters...
  useEffect(() => {
    if (skipPageResetRef.current) {
      setTimeout(() => {
        skipPageResetRef.current = false;
      }, 500);
    }
  });

  // Custom sorting for dates
  const sortDateBy = useMemo(() => sortDateTable, []);

  // Insensitive case sort
  const insensitiveCaseSort: SortingFn<Course> = (rowA, rowB, columnId) =>
    noMatchCaseSort({
      valueA: rowA.getValue(columnId),
      valueB: rowB.getValue(columnId),
    });

  // Columns configuration
  const columns: ColumnDef<Course>[] = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'title',
        header: 'Titre du cours',
        accessorKey: 'title',
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'date',
        header: 'Date',
        accessorFn: (row: Course) => format(row.date, 'dd/MM/yyyy', { locale: frLocale }),
        cell: ({ getValue }: CellContext<Course, unknown>) => getValue(),
        filterFn: dateFilterMethod,
        sortingFn: sortDateBy,
      },
      {
        id: 'promotion',
        header: 'Promotion',
        accessorFn: (row: Course) => row.promotion.displayName,
        cell: PromotionCell,
        enableSorting: false,
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'teacher',
        header: 'Formateur',
        accessorFn: (row: Course) => `${row.teacher.firstname} ${row.teacher.lastname}`,
        cell: TeacherCell,
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'helpers',
        header: 'Helper',
        accessorFn: (row: Course) =>
          row.helpers.map(
            (helper) => helper && !helper.deactivatedAt && `${helper.firstname} ${helper.lastname}`,
          ),
        filterFn: (row: Row<Course>, columnId: string, filterValue: string) => {
          const helpersValue: string[] = row.getValue(columnId);
          return helpersValue.some((helper) =>
            helper.toLowerCase().includes(filterValue.toLowerCase()),
          );
        },
        cell: ({ row }: { row: Row<Course> }) =>
          row.original?.helpers.map(
            (helper) =>
              helper
              && (helper.deactivatedAt ? (
                <T.DeactivatedRelation
                  key={helper.id}
                  content={`${helper.firstname} ${helper.lastname}`}
                  tooltipContent="Utilisateur archivé"
                />
              ) : (
                <div key={helper.id}>{`${helper.firstname} ${helper.lastname}`}</div>
              )),
          ),
        enableSorting: false,
      },
      {
        id: 'status',
        header: 'Statut',
        accessorFn: (row: Course) => getStatus(row).label,
        cell: StatusCell,
        filterFn: (row: Row<Course>, columnId: string, filterValue: string) =>
          !!filterValue.length && filterValue.includes(row.getValue(columnId)),
      },
      {
        id: 'timeslotsNb',
        header: 'Séances',
        accessorFn: (row: Course) => `${row.timeslots.length}`,
        enableColumnFilter: false,
        cell: TimeslotsCell,
      },
      {
        id: 'timeslots',
        header: 'Horaires',
        cell: TimeslotsHoursCell,
        enableColumnFilter: false,
      },
      {
        id: 'duration',
        header: 'Durée totale',
        accessorFn: (row: Course) => {
          const timeslotsSorted = getSortedTimeslots(row.timeslots);
          return getAllDurationFromTimeslots(timeslotsSorted);
        },
        enableColumnFilter: false,
        cell: TimeslotsDurationCell,
      },
      {
        id: 'archive',
        header: 'Archivage',
        accessorFn: (row: Course) => !!row.deactivatedAt,
        filterFn: (row: Row<Course>, columnId: string, filterValue: boolean) =>
          row.getValue(columnId) === filterValue,
      },
    ],
    [sortDateBy, insensitiveCaseSort],
  );

  const initialState = {
    columnVisibility: {
      id: false,
      archive: false,
    },
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    columnFilters: [
      { id: 'archive', value: false },
      { id: 'status', value: [...defaultStatusFilterValues] },
      { id: 'date', value: { start: '', end: '' } },
    ],
    sorting: useMemo(
      () => [
        {
          id: 'date',
          desc: true,
        },
      ],
      [],
    ),
    filtersTypes: {
      id: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DefaultFilter({ column }),
      },
      title: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DefaultFilter({ column }),
      },
      date: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DateFilter({ column }),
      },
      promotion: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DefaultFilter({ column }),
      },
      teacher: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DefaultFilter({ column }),
      },
      helpers: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => DefaultFilter({ column }),
      },
      status: {
        Filter: ({ column }: { column: Column<Course, unknown> }) => StatusFilter({ column }),
      },
    },
  };

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetAll: !skipPageResetRef.current,
  });
  return [tableInstance, Table] as const;
};

/*
 * Export
 */
export default useCourseTable;
