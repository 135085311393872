/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Code
 */
export const ActionsContainer = styled.ul(({ isMenuDisplay }: { isMenuDisplay: boolean }) => ({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  visibility: `${isMenuDisplay ? 'visible' : 'hidden'}`,
  transition: 'all 0.3s ease-out',
}));
