/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import SurveysListInner from './Inner';

// Style
import * as S from './style';

/**
 * Component
 */
const SurveysList = ({ actions, isEducator }) => (
  <S.Poll id="Sondages">
    <SurveysListInner actions={actions} isEducator={isEducator} />
  </S.Poll>
);

/**
 * PropTypes
 */
SurveysList.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  isEducator: PropTypes.bool,
};

SurveysList.defaultProps = {
  isEducator: false,
};

/**
 * Export
 */
export default SurveysList;
