/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
const getListEmotionStyle = (theme) => ({
  ...theme.typo.labels.smallLabel,
  color: theme.text._1,
  margin: '0 0 0 0.5rem',
  display: 'flex',
  width: '100%',
  paddingRight: '20%',
});

export const ListEmotions = styled.div(({ theme }) => ({
  ...getListEmotionStyle(theme),
}));

export const DisconnectedListEmotions = styled.div(({ theme }) => ({
  ...getListEmotionStyle(theme),
  fontStyle: 'italic',
}));

export const EmojiContainer = styled('span')({
  display: 'flex',
  alignItems: 'center',

  '& span': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '.125rem',
  },
});

export const Label = styled.div(({ theme }) => ({
  color: theme.text._1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
