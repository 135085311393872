/*
 * Package Import
 */
import React from 'react';
import { RouteProps, Route as DefaultRoute } from 'react-router-dom';

/*
 * Local Import
 */
import Nav from 'src/components/Dashboard/Nav';

/*
 * Types
 */
interface AuthRouteProps extends RouteProps {
  hasNavigation?: boolean;
}

/*
 * Component
 */
const Route = ({ children, hasNavigation = false, ...props }: AuthRouteProps) => {
  /**
   * Render
   */
  if (hasNavigation) {
    return (
      <DefaultRoute {...props}>
        <div style={{ display: 'flex', height: '100%' }}>
          <>
            <Nav />
            {children}
          </>
        </div>
      </DefaultRoute>
    );
  }

  /**
   *
   */
  return <DefaultRoute {...props}>{children}</DefaultRoute>;
};

/*
 * Export
 */
export default Route;
