/*
 * Package Import
 */
import { format } from 'date-fns';

/*
 * Local Import
 */
import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { getPedaOptions } from 'src/components/Admin/Modals/FormElements/utils';

// Schema
import { Promotion } from 'src/schemas/Entities/Promotion';
import { Client, User } from 'src/schemas/Entities/User';

/*
 * Code
 */
export const getMinDate = (
  promotionsData: Promotion[] | undefined,
  watched: Option | null,
): string | undefined => {
  if (!promotionsData) {
    return undefined;
  }

  if (watched) {
    const watchedPromotion = promotionsData.find((promotion) => promotion.id === watched.value);

    if (watchedPromotion) {
      return format(watchedPromotion.start, 'yyyy-MM-dd');
    }
  }

  return undefined;
};

export const getMaxDate = (
  promotionsData: Promotion[] | undefined,
  watched: Option | null,
): string | undefined => {
  if (!promotionsData) {
    return undefined;
  }

  if (watched) {
    const watchedPromotion = promotionsData.find((promotion) => promotion.id === watched.value);

    if (watchedPromotion) {
      return format(watchedPromotion.end, 'yyyy-MM-dd');
    }
  }

  return undefined;
};

export const getDefaultTeacher = (
  client: Client | null,
  usersData: User[] | undefined,
): Option | undefined => getPedaOptions(usersData).find((option) => option.value === client?.id);
