/**
 * Packages import
 */
import React, { memo, useMemo } from 'react';
import { UilUsersAlt } from '@iconscout/react-unicons';
import { Chips } from '@oclock/crumble';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import * as S from './style';
import * as P from '../../style';

const ParticipantsCount = ({ users }) => {
  const connectedCount = useMemo(() => users.filter((user) => user.connected).length, [users]);

  return (
    <S.SectionHeader>
      <UilUsersAlt aria-hidden="true" />
      <P.SectionTitle>Participants</P.SectionTitle>
      <Chips>
        {connectedCount}&nbsp;/&nbsp;{users.length}
      </Chips>
    </S.SectionHeader>
  );
};

ParticipantsCount.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(ParticipantsCount);
