/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { UilChart } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import TimestampMessage from 'src/components/Chats/Channel/Chat/Message/Timestamp';
import { setFocus } from 'src/utils';

import * as S from './style';

/*
 * Component
 */
const Event = ({ actions, chatAppearance, message }) => {
  /**
   * Custom render component for channel event messages
   * eg: X has left the room… Y has join the room…
   */
  if (message.type === 'channel.event') {
    return <div role="listitem">{message.content}</div>;
  }

  /**
   * Custom render component for system messages
   * eg: The new X has been delivered…
   */
  if (message.type === 'system') {
    return <div role="listitem">{message.content}</div>;
  }

  /**
   * Custom render component for messages events
   * eg: A new X has been published.
   */
  if (message.kind === 'survey') {
    /** Open the related tab to the event */
    const onClick = () => {
      actions.changeMainChatView(message.kind);
      setFocus('survey');
    };

    return (
      <S.Event role="listitem">
        {chatAppearance === 'spaced' && message?.time && <TimestampMessage time={message.time} />}
        <S.Container onClick={onClick}>
          <UilChart aria-hidden />
          <S.Label>Nouveau sondage disponible</S.Label>
        </S.Container>
      </S.Event>
    );
  }

  return null;
};

/*
 * PropTypes
 */
Event.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Chat appearance ('spaced' | 'compact') */
  chatAppearance: PropTypes.string.isRequired,
  /** The message object */
  message: PropTypes.object.isRequired,
};

/*
 * Export
 */
export default Event;
