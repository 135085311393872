const monochrome = {
  black: '#000000',
  white: '#FFFFFF',
};
const primary = {
  _50: '#EBF5FF',
  _100: '#E1EFFE',
  _200: '#C3DDFD',
  _300: '#A4CAFE',
  _400: '#76A9FA',
  _500: '#3F83F8',
  _600: '#1C64F2',
  _700: '#1A56DB',
  _800: '#1E429F',
  _900: '#233876',
};
const coolGrey = {
  _50: '#FBFDFE',
  _100: '#F1F5F9',
  _150: '#F2F5F9',
  _200: '#E2E8F0',
  _300: '#CFD8E3',
  _350: '#A6B6C9',
  _400: '#97A6BA',
  _500: '#64748B',
  _600: '#475569',
  _700: '#364152',
  _800: '#27303F',
  _850: '#1F2633',
  _900: '#1A202E',
  _950: '#1A1D23',
};
const success = {
  _50: '#F3FAF7',
  _100: '#DEF7EC',
  _200: '#BCF0DA',
  _300: '#84E1BC',
  _400: '#31C48D',
  _500: '#0E9F6E',
  _600: '#057A55',
  _700: '#046C4E',
  _800: '#03543F',
  _900: '#014737',
};
const info = {
  _50: '#F0F5FF',
  _100: '#EDEBFE',
  _200: '#DCD7FE',
  _300: '#CABFFD',
  _400: '#AC94FA',
  _500: '#9061F9',
  _600: '#7E3AF2',
  _700: '#6C2BD9',
  _800: '#5521B5',
  _900: '#4A1D96',
};
const warning = {
  _50: '#FFF8F1',
  _100: '#FEECDC',
  _200: '#FCD9BD',
  _300: '#FDBA8C',
  _400: '#FF8A4C',
  _500: '#FF5A1F',
  _600: '#D03801',
  _700: '#B43403',
  _800: '#8A2C0D',
  _900: '#771D1D',
};
const danger = {
  _50: '#FDF2F2',
  _100: '#FDE8E8',
  _200: '#FBD5D5',
  _300: '#F8B4B4',
  _400: '#F98080',
  _500: '#F05252',
  _600: '#E02424',
  _700: '#C81E1E',
  _800: '#9B1C1C',
  _900: '#771D1D',
};

export default {
  monochrome,
  primary,
  coolGrey,
  success,
  info,
  warning,
  danger,
};
