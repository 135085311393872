/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Edit from 'src/components/Chats/Channel/Chat/Input/Edit';
import { editMessage } from 'src/store/actions';

/*
 * Code
 */
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ editMessage }, dispatch),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(Edit);
