/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Reaction from 'src/components/Chats/Channel/Chat/Message/Reactions/Reaction';
import { getUsersNamesForReaction } from 'src/store/selectors/users';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => ({
  ...getUsersNamesForReaction(state, ownProps),
});

/*
 * Export
 */
export default connect(mapStateToProps)(Reaction);
