/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { User, UserDTO } from 'src/schemas/Entities/User';
import { validateAndTransformUserDTOToUser } from 'src/schemas/Adapters/Users/transformUserDTOToUser';
import { sendInvitation } from './sendInvitation';

/*
 * Code
 */
export const useSendInvitation = (): UseMutationResult<UserDTO, unknown, string, unknown> => {
  const userMutation = useMutation((email: string) => sendInvitation(email), {
    // If error (normally not possible with fresh data),
    // we reload all users to be sure to have fresh data
    onError: () => {
      queryClient.invalidateQueries('users');
    },
    onSuccess: (userDTO) => {
      // If success, we validate new user data
      const validation = validateAndTransformUserDTOToUser(userDTO);
      // If validation fail, reload all users to be sure
      if (!validation.success) {
        queryClient.invalidateQueries(['users']);
        return;
      }
      const user = validation.data;
      // If validation success, we can update juste this user
      // in the cache to avoid useless request
      const updateFonction = (oldData: User[] | undefined): User[] => {
        if (user) {
          if (!oldData) {
            return [user];
          }
          const newData = oldData.map((userObject) =>
            (userObject.email === user.email ? user : userObject),
          );
          return newData;
        }
        return [];
      };
      queryClient.setQueryData(['users'], updateFonction);

      toast(`Le mail d'invitation a bien été renvoyé à ${user.email}.`, {
        type: 'success',
      });
    },
  });

  return userMutation;
};
