/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Typing = styled.span(({ theme }) => ({
  ...theme.typo.bodyText.tinyTextAdjustable,
  color: theme.text._6,
  padding: '.5rem auto',
  marginBottom: '.5rem',
  resize: 'none',
  overflowY: 'hidden',
  display: 'block',
  minHeight: '18px',
}));
