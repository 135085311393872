/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Mugshot from 'src/components/Users/Profile/Image/Video/Mugshot';
import { sendMugshot } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const user = state.users.client.id;

  return {
    // user ID
    user,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendMugshot }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Mugshot);
