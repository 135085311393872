/**
 * Package Import
 */
import { useQuery, UseQueryResult } from 'react-query';

/**
 * Local Import
 */
import { LimitationDTO } from 'src/schemas/Entities/Limitation';
import { fetchLimitations } from './fetchLimitations';

/**
 * Code
 */
export const useGetLimitations = (): UseQueryResult<LimitationDTO | null> => {
  const limitationsQuery = useQuery(['limitations'], fetchLimitations(), {
    staleTime: 60 * 1000,
  });

  return limitationsQuery;
};
