/**
 * Package Import
 */
import React from 'react';
import { UilCheck, UilCommentQuestion } from '@iconscout/react-unicons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import { useAction } from 'src/components/Classroom/hooks';
import { getChatAppearance } from 'src/store/selectors/settings';
import * as S from './style';

/**
 * Component
 */
const ContentQuestion = ({ actions, children, canAnswer, messageId }) => {
  const { setQuestionAnswered } = useAction();
  const chatAppearance = useSelector(getChatAppearance);

  /**
   * Handle the CTA to flag the question as answered
   * @return {void}
   */
  const handleAnsweredQuestion = () => {
    actions.setQuestionMessageAnsweredStatus({ answered: true });
    setQuestionAnswered({ messageId, answered: true });
  };

  /**
   * Render
   */
  return (
    <S.Container chatAppearance={chatAppearance}>
      {/* Header */}
      <S.Header>
        <S.HeaderTitle>
          <S.Icon>
            <UilCommentQuestion />
          </S.Icon>
          <S.Title>Question</S.Title>
        </S.HeaderTitle>

        {/* CTA */}
        {canAnswer && (
          <S.Success
            type="button"
            onClick={handleAnsweredQuestion}
            aria-label="Marquer cette question comme résolue"
            isGreen
            isIcon
          >
            <UilCheck />
          </S.Success>
        )}
      </S.Header>

      {/* Content */}
      <S.Content>{children}</S.Content>
    </S.Container>
  );
};

/**
 * PropTypes
 */
ContentQuestion.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  children: PropTypes.node.isRequired,
  canAnswer: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
};

/**
 * Export
 */
export default ContentQuestion;
