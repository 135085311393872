/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Local Import
 */
import { WebRTCEvent } from './webRTCEvent';

/**
 * Types
 */
export interface WebRTCManagerInitialProps {
  antServer: string;
  courseId: string;
  clientId: string;
  organization: Record<string, string>;
}

/**
 * WebRTC Manager
 */
export abstract class WebRTCManager {
  /**
   * EventEmiter pattern
   */
  // a property to store all the listeners
  private listeners: { [event in WebRTCEvent]?: Array<() => void> };

  // a method to add a listener related to an event
  addEventListener(event: WebRTCEvent, listener: () => void) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event]?.push(listener);
  }

  // a method to remove a listener related to an event
  protected removeListeners(event: WebRTCEvent) {
    if (this.listeners[event]) {
      delete this.listeners[event];
    }
  }

  // a method to emit an event, and therefore call all the listeners related to it
  protected emit(event: WebRTCEvent) {
    this.listeners[event]?.forEach((listener) => listener());
  }

  /**
   * Properties
   */
  // those can not by typed correctly
  // because it may heavily change between different systems
  webRTCCamsAndMicsAdaptor: any;

  webRTCScreensAdaptor: any;

  // rooms identifiers
  camsAndMicsRoomIdentifier!: string;

  screensRoomIdentifier!: string;

  // streams identifiers (it represent the stream the user WOULD want to publish at any time)
  camAndMicStreamIdentifier!: string;

  screenStreamIdentifier!: string;

  // base url for WebSocket service
  baseWebSocketURL: string;

  /**
   * constructor !
   */
  constructor(props: WebRTCManagerInitialProps) {
    this.listeners = {};

    const { antServer } = props;
    const { orga, ant_wss: ANT_WSS } = props.organization;

    // setup identifiers and urls from constants
    this.baseWebSocketURL = antServer ? `wss://${antServer}.slippers.live` : ANT_WSS;

    this.camsAndMicsRoomIdentifier = `${orga}-${props.courseId}-cams`;
    this.screensRoomIdentifier = `${orga}-${props.courseId}-screen`;

    this.camAndMicStreamIdentifier = `${orga}-${props.courseId}-${props.clientId}-cams`;
    this.screenStreamIdentifier = `${orga}-${props.courseId}-${props.clientId}-screen`;
  }

  /**
   * Abstract methods
   */
  abstract joinAllRooms(): void;
  abstract joinCamsAndMicsRoom(): void;
  abstract joinScreensRoom(): void;

  abstract leaveAllRooms(): void;
  abstract leaveCamsAndMicsRoom(): void;
  abstract leaveScreensRoom(): void;

  abstract startMyCamAndMicStream(params: { audioOnly: boolean }): void;
  abstract stopMyCamAndMicStream(): void;

  abstract startMyScreenStream(): void;
  abstract stopMyScreenStream(): void;

  abstract enableLocalMic(): void;
  abstract disableLocalMic(): void;

  abstract enableLocalVideo(): void;
  abstract disableLocalVideo(): void;

  abstract pause(): void;
  abstract unpause(): void;
}
