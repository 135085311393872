/*
 * Package Import
 */
import * as Sentry from '@sentry/react';

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { validateLimitationDTO } from 'src/schemas/Adapters/Limitations/validateLimitationDTO';

// Schemas
import { LimitationDTO } from 'src/schemas/Entities/Limitation';

// Constants
import { API_FIND_LIMITATIONS, API_GET } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const fetchLimitations = () => async (): Promise<LimitationDTO | null> => {
  try {
    const payload: Payload = {
      method: API_GET,
      url: API_FIND_LIMITATIONS,
    };
    const { data: limitationData } = await api(payload);

    if (!limitationData) {
      return null;
    }

    const { limitation, error } = validateLimitationDTO(limitationData);

    if (error) {
      const errorMessage = `Limitations validation problem : ${error.message}.`;
      // eslint-disable-next-line no-console
      console.error(`${errorMessage}\n Please contact support`);
    }
    return limitation;
  }
  catch (error) {
    Sentry.captureException(error);
    return null;
  }
};
