/*
 * Package Import
 */
import React from 'react';
import { Row, Column } from '@tanstack/react-table';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { Info } from 'src/components/Admin/Modals/FormElements/Icon';

// Schemas
import { UserDisplayBeforeCreate } from 'src/schemas/Entities/User';
import { ZodValidationFailure, ZodValidationSuccess } from 'src/schemas/Adapters/Users/utils';

// Helpers
import { getAnomaliesDetails, AnomaliesDetails } from './utils';

// Style
import * as S from './style';

/*
 * Types
 */
type InformationsProps = {
  row: Row<ZodValidationSuccess | ZodValidationFailure>;
};

type AnomaliesInformationsProps = {
  row: Row<ZodValidationSuccess | ZodValidationFailure>;
  allColumns: Column<ZodValidationSuccess | ZodValidationFailure, unknown>[];
};

type InformationsTooltipContentProps = {
  user: UserDisplayBeforeCreate | undefined;
};

type AnomaliesTooltipContentProps = {
  anomaliesDetails: AnomaliesDetails[];
};

/*
 * Components
 */
const InformationsTooltipContent = ({ user }: InformationsTooltipContentProps): JSX.Element => {
  const notEntered = 'N/C';
  const getPromotionsString = (userInfo: UserDisplayBeforeCreate): string => {
    if (userInfo.promotions.length === 0) return '';
    const string = userInfo.promotions
      .map((promotion, index) => (promotion ? `${index !== 0 ? ', ' : ''}${promotion.name}` : ''))
      .join('');
    return string;
  };
  return (
    <>
      {user && (
        <>
          <S.InformationsHeader>Informations</S.InformationsHeader>
          <ul>
            <li>• Prénom : {user.firstname}</li>
            <li>• Nom : {user.lastname}</li>
            <li>• Email : {user.email}</li>
            <li>• Ville : {user.town || notEntered}</li>
            <li>• Pays : {user.country || notEntered}</li>
            <li>• Profil Github : {user.githubProfile || notEntered}</li>
            <li>• Profil Discord : {user.discordProfile || notEntered}</li>
            <li>• Promotions : {getPromotionsString(user) || notEntered}</li>
          </ul>
        </>
      )}
    </>
  );
};

export const Informations = ({ row }: InformationsProps): JSX.Element => {
  const { original: zodResult } = row;

  return (
    <Tooltip content={<InformationsTooltipContent user={zodResult?.data} />}>
      <Info>
        <S.CsvInfoIcon />
      </Info>
    </Tooltip>
  );
};

const AnomaliesTooltipContent = ({
  anomaliesDetails,
}: AnomaliesTooltipContentProps): JSX.Element => (
  <>
    <S.InformationsHeader>Attention !</S.InformationsHeader>
    Il semblerait que cet utilisateur présente des anomalies sur les informations suivantes :
    <ul>
      {anomaliesDetails?.length
        && anomaliesDetails.map((anomalyDetails) => (
          <li key={anomalyDetails.id}>
            • {anomalyDetails.display} : {anomalyDetails.message}
          </li>
        ))}
    </ul>
  </>
);

export const AnomaliesInformations = ({
  row,
  allColumns,
}: AnomaliesInformationsProps): JSX.Element => {
  const { original: zodResult } = row;
  const { error: zodError } = zodResult as ZodValidationFailure;
  const anomaliesDetails = getAnomaliesDetails(zodError, allColumns);

  return (
    <Tooltip content={<AnomaliesTooltipContent anomaliesDetails={anomaliesDetails} />}>
      <Info>
        <S.CsvErrorIcon />
      </Info>
    </Tooltip>
  );
};
