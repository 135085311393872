/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilSmile } from '@iconscout/react-unicons';
import { MenuButton } from 'react-menu-list';

/*
 * Icons
 */

const iconStyle = (theme) => ({
  color: theme.text._1,
  height: '1.25rem',
  width: '1.25rem',
});

export const Smile = styled(UilSmile)(({ theme }) => ({
  ...iconStyle(theme),
}));

/*
 * Style
 */
export const Buttons = styled.div`
  position: absolute;
  right: 0;
  top: -0.875rem;
  opacity: 0;
  padding: 0 0.875rem 0 2rem;
`;

export const Wrapper = styled.div(({ theme }) => ({
  background: theme.background._2,
  borderRadius: '8px',
  display: 'grid',
  gridAutoFlow: 'column',
  padding: '.25rem',
  transition: 'box-shadow 0.1s ease-out',
  ...theme.shadows.plus1,
}));

export const Button = styled(MenuButton)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  margin-right: 1px;
  flex: 0 0 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  transition: background 0.2s;
  border-radius: 8px;

  :hover {
    background: ${({ theme }) => theme.background._4};
  }

  :focus-visible {
    border: 2px solid ${({ theme }) => theme.primary._1};
  }
  :-moz-focusring {
    border: 2px solid ${({ theme }) => theme.primary._1};
  },
`;
