/*
 * Package Import
 */
import React from 'react';
import { useTheme } from '@emotion/react';
import { LoaderProps } from 'react-loader-spinner';

/**
 * Local Import
 */
import { Loading } from './style';

/**
 * Types
 */
interface AppLoaderProps extends LoaderProps {
  overlay?: boolean;
  size?: number;
}

/*
 * Component
 */
const Loader = ({ color, overlay = false, type = 'Oval', size = 24 }: AppLoaderProps) => {
  /*
   * Variable
   */
  const theme = useTheme();

  /*
   * Render
   */
  return (
    <Loading
      color={color || theme.primary._1}
      overlay={overlay}
      type={type}
      // Size
      height={size}
      width={size}
    />
  );
};

/*
 * Export
 */
export default Loader;
