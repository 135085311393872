/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@oclock/crumble';

/*
 * Local Import
 */
import { getFormatTime } from 'src/utils/time';
import * as S from './style';

/*
 * Component
 */
const DateSeparator = ({ date }) => {
  // Fomat the date to display, e.g : Dimanche 15 Novembre
  const formattedDate = getFormatTime(date, 'EEEE dd MMMM');

  // Render
  return (
    <S.DividerContainer>
      <Divider>{formattedDate}</Divider>
    </S.DividerContainer>
  );
};

/*
 * PropTypes
 */
DateSeparator.propTypes = {
  /** The date to format */
  date: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.instanceOf(Date).isRequired])
    .isRequired,
};

/*
 * Export
 */
export default React.memo(DateSeparator);
