/**
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { useInterpret } from '@xstate/react';

/**
 * Local Import
 */
import { classroomMachine } from 'src/components/Launch/machine';

/**
 * Context
 */
export const GlobalStateContext = createContext({});

/**
 * Provider
 */
export const GlobalStateProvider = ({ children }) => {
  /**
   * Services
   */
  const classroomService = useInterpret(classroomMachine);

  /**
   * Render
   */
  return (
    <GlobalStateContext.Provider value={{ classroomService }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

/**
 * PropTypes
 */
GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
