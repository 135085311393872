/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Modal = styled.div(({ theme }) => ({
  display: 'flex',
  minWidth: '21.625rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  margin: 'auto',
  padding: '1rem',
  background: theme.background._2,
  border: `1px solid ${theme.text._7}`,
  borderRadius: '16px',
  ...theme.shadows.plus3,
}));

export const Title = styled.h2(({ theme }) => ({
  textAlign: 'center',
  color: theme.text._1,
  ...theme.typo.headlines.H5,
}));

export const Body = styled.div(({ theme }) => ({
  margin: '.25rem 1rem 1.5rem',
  textAlign: 'center',
  color: theme.text._3,
  ...theme.typo.bodyText.smallBody,
}));

export const Button = styled.button(({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  marginRight: '1rem',
  flex: 1,

  ':last-child': {
    marginRight: '0 !important',
  },
}));

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;
