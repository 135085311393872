/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import {
  changeAway,
  changeAwayTime,
  setModalAway,
  sendTakeVoice,
  disableWebcam,
  disableMicrophone,
  disableScreen,
  disableSound,
} from 'src/store/actions';
import AwayChoice from 'src/components/TopBar/Menu/AwayChoice';

// Helpers
import { isTeacher, isHelper, isStudent, isGhost, isEducator } from 'src/utils/roles';

// Constants
import { HAND_STATUS } from 'src/constants/conference';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { client } = state.users;
  const { peerById } = state.course;

  return {
    user: client.id,
    isOpen: state.breaktime.isOpen,
    isPeda: isEducator(client.role),
    isClientTeacher: isTeacher(client.role),
    isClientHelper: isHelper(client.role),
    isClientStudent: isStudent(client.role) || isGhost(client.role),
    handStatus: peerById[client.id]?.hand || HAND_STATUS.UNRAISED,
    screenEnabled: state.course.medias.screenEnabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeAway,
      changeAwayTime,
      setModalAway,
      disableWebcam,
      disableMicrophone,
      disableScreen,
      sendTakeVoice,
      disableSound,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(AwayChoice);
