/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import endOfYesterday from 'date-fns/endOfYesterday';
import isAfter from 'date-fns/isAfter';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { MongoId } from 'src/schemas/Entities/utils';
import Add from 'src/components/Admin/Modals/Add/User';
import MultipleAdd from 'src/components/Admin/Modals/Add/Users';
import Vm from 'src/components/Admin/Modals/VM/User';
import Update from 'src/components/Admin/Modals/Update/User';
import Deactivate from 'src/components/Admin/Modals/Deactivate/User';
import Reactivate from 'src/components/Admin/Modals/Reactivate/User';
import Delete from 'src/components/Admin/Modals/Delete';
import { ParamsProps, actions, IdToAction } from 'src/constants/routes';
import { isProperUrl, getRoute } from 'src/utils/routes';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';
import { useStore } from 'src/localStateManagers/store';
import { useSendInvitation } from 'src/serverStateManagers/mutations/special/Users/sendInvitation/useSendInvitation';
import { findClientRoleAndCheckIfUserUpdatedIsRoleLower } from 'src/schemas/Refines/Validations/users';
import { menuOptions } from 'src/components/Admin/TableElements/ActionsMenuOptions';
import * as T from 'src/components/Admin/TableElements';
import * as S from './style';
import useTable from './hooks/useUserTable';

/*
 * Code
 */
const UserTable = (): JSX.Element => {
  const { isError, data } = useGetUsers();

  const client = useStore((state) => state.client);
  const sendInvitationMutation = useSendInvitation();

  const [idToAction, setIdToAction] = useState({
    update: null,
    deactivate: null,
    reactivate: null,
    vm: null,
    delete: null,
  } as IdToAction);

  const match = useRouteMatch();
  const params = match.params as ParamsProps;
  const history = useHistory();

  const handleAddModal = (): void => {
    history.push(getRoute('usersAdd').getUrl({}));
  };
  const handleMultipleAddModal = (): void => {
    history.push(getRoute('usersMultipleAdd').getUrl({}));
  };
  const handleDeactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('usersDeactivate').getUrl({ id }));
    }
  };
  const handleReactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('usersReactivate').getUrl({ id }));
    }
  };
  const handleUpdateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('usersUpdate').getUrl({ id }));
    }
  };
  const handleVmModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('usersVm').getUrl({ id }));
    }
  };

  const handleDeleteModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('usersDelete').getUrl({ id }));
    }
  };

  useEffect(() => {
    if (!isProperUrl('users', data, params)) {
      history.push('/404');
    }

    if (params.action && params.id) {
      const { action, id } = params;
      setIdToAction((prevState) => ({
        ...prevState,
        [action]: id,
      }));
    }
  }, [params, data, history]);

  const {
    updateOption,
    deactivateOption,
    reactivateOption,
    vmOption,
    sendInvitationOption,
    deleteOption,
  } = menuOptions;

  const updateHandle = (id: MongoId | null): void => {
    handleUpdateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      update: id,
    }));
  };
  const configuredUpdateOption = updateOption(updateHandle);
  const deactivateHandle = (id: MongoId | null): void => {
    handleDeactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      deactivate: id,
    }));
  };
  const isDeactive = (rowId: MongoId | null): boolean => {
    const currentUser = data?.find((user) => user.id === rowId);
    if (!currentUser) {
      return false;
    }
    return !!currentUser.deactivatedAt;
  };
  const isHigherRole = (rowId: MongoId | null): boolean => {
    if (!rowId) return false;
    return findClientRoleAndCheckIfUserUpdatedIsRoleLower(rowId, client, data);
  };
  const isDeactivateDisplayed = (rowId: MongoId | null) =>
    !isDeactive(rowId) && isHigherRole(rowId);
  const configuredDeactivateOption = deactivateOption(deactivateHandle, isDeactivateDisplayed);

  const reactivateHandle = (id: MongoId | null): void => {
    handleReactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      reactivate: id,
    }));
  };
  const isReactivateDisplayed = (rowId: MongoId | null) => isDeactive(rowId) && isHigherRole(rowId);
  const configuredReactivateOption = reactivateOption(reactivateHandle, isReactivateDisplayed);

  const vmHandle = (id: MongoId | null): void => {
    handleVmModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      vm: id,
    }));
  };
  const isStudent = (rowId: MongoId | null): boolean => {
    const currentUser = data?.find((user) => user.id === rowId);
    if (!currentUser) {
      return false;
    }
    return currentUser.role === 'basic';
  };

  const configuredVmOption = vmOption(vmHandle, isStudent);

  const sendInvitationHandle = (id: MongoId | null): void => {
    const currentUser = data?.find((user) => user.id === id);
    // Must not happen but in doubt we control it
    if (!currentUser || !currentUser.email) {
      toast("Nous n'avons pas trouvé l'utilisateur correspondant", { type: 'error' });
      return;
    }
    sendInvitationMutation.mutate(currentUser.email);
  };

  const isSpam = (rowId: MongoId | null): boolean => {
    const currentUser = data?.find((user) => user.id === rowId);
    if (!currentUser || !currentUser.invitationDate) {
      return false;
    }
    const midnightYesterday = endOfYesterday();

    return isAfter(currentUser.invitationDate, midnightYesterday);
  };

  const isSendInvitationDisabled = (rowId: MongoId | null): boolean => isSpam(rowId);

  const isNotRegistered = (rowId: MongoId | null): boolean => {
    const currentUser = data?.find((user) => user.id === rowId);
    if (!currentUser) {
      return false;
    }
    return !currentUser.isRegistered;
  };

  const sendInvitationTooltipContent = (rowId: MongoId | null) => {
    if (isSpam(rowId)) {
      return "Un mail a déjà été renvoyé aujourd'hui. Attendez demain pour en renvoyer un autre.";
    }
    return 'Renvoyer une invitation par mail à cet utilisateur. Utilisable une fois par jour.';
  };

  const configuredSendInvitationOption = sendInvitationOption(
    sendInvitationHandle,
    isNotRegistered,
    isSendInvitationDisabled,
    sendInvitationTooltipContent,
  );

  const deleteHandle = async (id: MongoId | null): Promise<void> => {
    handleDeleteModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      delete: id,
    }));
  };

  const configuredDeleteOption = deleteOption(deleteHandle, isNotRegistered);

  const [tableInstance, Table] = useTable(data);

  return (
    <S.Container>
      {!isError && (
        <>
          <Table
            title="Utilisateurs"
            tableInstance={tableInstance}
            openAddModal={handleAddModal}
            menuOptions={[
              configuredSendInvitationOption,
              configuredUpdateOption,
              configuredDeactivateOption,
              configuredReactivateOption,
              configuredVmOption,
              configuredDeleteOption,
            ]}
            customInterface={{
              topLeft: <T.ArchiveFilter tableInterface={tableInstance} />,
              topRight: <T.UsersImportButton openMultipleAddModal={handleMultipleAddModal} />,
            }}
          />
          <Add isOpen={!!params.action && params.action === actions.add} />
          <MultipleAdd isOpen={!!params.action && params.action === actions.multipleAdd} />
          <Update
            isOpen={!!params.action && !!params.id && params.action === actions.update}
            idToUpdate={idToAction.update}
          />
          <Deactivate
            isOpen={!!params.action && !!params.id && params.action === actions.deactivate}
            idToDeactivate={idToAction.deactivate}
          />
          <Reactivate
            isOpen={!!params.action && !!params.id && params.action === actions.reactivate}
            idToReactivate={idToAction.reactivate}
          />
          <Vm
            isOpen={!!params.action && !!params.id && params.action === actions.vm}
            idToConnect={idToAction.vm}
          />
          <Delete
            isOpen={!!params.action && !!params.id && params.action === actions.delete}
            idToDelete={idToAction.delete}
          />
        </>
      )}
    </S.Container>
  );
};

/*
 * Export
 */

export default UserTable;
