/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Filters from 'src/components/Chats/Channel/Filters';
import { setVisibilityFilter } from 'src/store/actions';

/*
 * Redux
 */

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setVisibilityFilter }, dispatch),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(Filters);
