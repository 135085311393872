/*
 * Package Import
 */
import { ZodError } from 'zod';

/*
 * Local Import
 */
import { LimitationDTO, limitationDTOSchema } from 'src/schemas/Entities/Limitation';

interface ValidateLimitationDTOResponse {
  limitation: LimitationDTO;
  error?: ZodError;
}

export const validateLimitationDTO = (
  limitationData: LimitationDTO,
): ValidateLimitationDTOResponse => {
  const limitationDTOParseResult = limitationDTOSchema.safeParse(limitationData);

  if (!limitationDTOParseResult.success) {
    return {
      error: limitationDTOParseResult.error,
      limitation: limitationData,
    };
  }

  return {
    limitation: limitationDTOParseResult.data,
  };
};
