/**
 * Constants · Mugshots
 * @type {Object}
 */
export default {
  /*
   * Configuration
   */

  // Teacher
  INTERVAL_SNAPSHOT_TEACHER: 60000,
  HEIGHT_SNAPSHOT_TEACHER: 45,
  WIDTH_SNAPSHOT_TEACHER: 45,

  // Helper
  INTERVAL_SNAPSHOT_HELPER: 3000,
  HEIGHT_SNAPSHOT_HELPER: 100,
  WIDTH_SNAPSHOT_HELPER: 123,

  // Student
  INTERVAL_SNAPSHOT_STUDENT: 10000,
  HEIGHT_SNAPSHOT_STUDENT: 45,
  WIDTH_SNAPSHOT_STUDENT: 55,

  // Avatar
  INTERVAL_SNAPSHOT_AVATAR: 10000,
  HEIGHT_SNAPSHOT_AVATAR: 48,
  WIDTH_SNAPSHOT_AVATAR: 48,
};
