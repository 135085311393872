import { v4 as uuid } from 'uuid';

/**
 * Local Import
 */
import { cleanCommand } from './utils';
import type { CommandProps } from './types';

/**
 * Question command
 */
export const commandQuestion = (props: CommandProps) => {
  const { addMessage, actions, chatId, context, value } = props;

  // Clean message without the `/commands`
  const cleanedCommand = cleanCommand({ command: 'question', value });

  if (cleanedCommand === '') return false;

  const content = {
    ops: [{
      insert: cleanedCommand,
    }],
  };

  const payload = {
    chatId,
    content,
    context,
    isAsked: true,
    isCommand: true,
    messageUuid: uuid(),
  };

  // Send with `question` option
  actions.sendMessage(payload);
  addMessage?.(payload);

  return true;
};
