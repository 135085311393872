/*
 * Package Import
 */
import styled from '@emotion/styled';
import Loader from 'react-loader-spinner';

// Style
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

/*
 * Local Import
 */

/*
 * Style
 */
export const Loading = styled(Loader)(({ overlay }: { overlay: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(overlay && { height: '100%' }),
  ...(overlay && { width: '100%' }),
}));
