/**
 * Package Import
 */
import styled from '@emotion/styled';
import SyntaxHighlighter from 'react-syntax-highlighter';

/**
 * Style
 */
export const Container = styled(SyntaxHighlighter)`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.text._1};
  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.background._5};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background._2};

  scrollbar-color: ${({ theme }) => theme.background._7} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background._7};
    border: 2px solid transparent;
    border-radius: 4px;
    background-clip: padding-box;
  }
`;
