/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  User,
  UserFormDataValidated,
  userSchema,
  userFormDataSchema,
} from 'src/schemas/Entities/User';
import { roles } from 'src/constants/roles';

/*
 * Code
 */
export const transformUserToUserFormData = (userData: User): UserFormDataValidated => {
  const userDataParseResult = userSchema.parse(userData);

  const {
    id,
    firstname,
    lastname,
    role,
    email,
    avatar,
    promotions,
    town,
    country,
    discordProfile,
    githubProfile,
    integrations,
  } = userDataParseResult;

  const userFormData = {
    id,
    firstname,
    lastname,
    role: {
      value: role,
      label: roles.find((roleData) => roleData.name === role)?.display,
    },
    email,
    promotions: promotions.map((promotion) => ({
      value: promotion?.id,
      label: promotion?.name,
    })),
    avatar: avatar || '',
    town: town || '',
    country: country || '',
    discordProfile: discordProfile || '',
    githubProfile: githubProfile || '',
    integrations,
  };

  const userFormDataParseResult = userFormDataSchema.parse(userFormData);

  return userFormDataParseResult as UserFormDataValidated;
};
