/*
 * Package Import
 */
import React from 'react';
import { Tooltip, TooltipProps } from '@oclock/crumble';

/**
 * Types
 */
interface InterfaceTooltip extends TooltipProps {
  hasDisplayParamEnabled?: boolean;
  isEnabled?: boolean;
}

/*
 * Component
 */
const TooltipContainer = ({
  children,
  content,
  hasDisplayParamEnabled = true,
  isEnabled = hasDisplayParamEnabled,
  size = 'small',
  ...options
}: InterfaceTooltip): JSX.Element => {
  /*
   * Render
   */
  if (!hasDisplayParamEnabled || !isEnabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip content={content} size={size} {...options}>
      {children}
    </Tooltip>
  );
};

/*
 * Export
 */
export default TooltipContainer;
