/*
 * Package Import
 */
import * as z from 'zod';

/**
 * Constants • Chats • Tabs
 */
export const CHAT_TABS = {
  TAB_CHAT: 'chat',
  TAB_SURVEY: 'survey',
  TAB_DOCUMENT: 'document',
  TAB_EXTERNAL_WINDOW: 'external-window',
} as const;

// Types
export const CHAT_TABS_SCHEMA = z.nativeEnum(CHAT_TABS);
export type ChatTabsEnum = z.infer<typeof CHAT_TABS_SCHEMA>;

/**
 * Constants • Chats • Types
 */
export const CHAT_TYPES = {
  CHAT_GENERAL: 'general',
  CHAT_PRIVATE: 'private',
} as const;

// Types
export const CHAT_TYPES_SCHEMA = z.nativeEnum(CHAT_TYPES);
export type ChatTypesEnum = z.infer<typeof CHAT_TYPES_SCHEMA>;

/**
 * Constants • Channel • Panel
 */
export const CHANNEL_PANEL = {
  CHAT_GENERAL: 'general',
  CHAT_HOME_PRIVATE: 'private.home',
  CHAT_PRIVATE: 'private',
} as const;

// Types
export const CHANNEL_PANEL_SCHEMA = z.nativeEnum(CHANNEL_PANEL);
export type ChannelPanelEnum = z.infer<typeof CHANNEL_PANEL_SCHEMA>;

/**
 * Constants · Chats
 */
export default Object.freeze({
  ...CHAT_TABS,
  ...CHAT_TYPES,
  ...CHANNEL_PANEL,

  // Surveys
  SURVEY_DEFAULT_ANSWER: 2,
  SURVEY_MAX_ANSWER: 6,

  // isTyping
  TYPING_TIMEOUT_DURATION: 10000, // in ms
});
