/**
 * Package Import
 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

/**
 * Local Import
 */
import ButtonsSelector from 'src/components/TopBar/Menu/SubMenus/ButtonsSelector';
import { textSizeOptions } from 'src/data/settings';

// Style
import * as G from '../../../style';

/**
 * Component
 */
const TextSize = (props) => {
  const { actions } = props;

  /**
   * Hooks
   */
  const theme = useTheme();

  /**
   * Update the text size value
   * @param {Number} value
   * @return {void}
   */
  const handleChange = useCallback((value) => {
    actions.changeTextSize(value);
    theme.chooseTextSize(value);
  }, []);

  /**
   * Render
   */
  return (
    <ButtonsSelector
      icon={<G.IconTextSize aria-hidden="true" />}
      onChange={handleChange}
      options={textSizeOptions}
      title="Taille des messages"
      value={theme.textSize}
      {...props}
    />
  );
};

/**
 * PropTypes
 */
TextSize.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
};

/**
 * Export
 */
export default TextSize;
