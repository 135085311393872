/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import ChannelHeader from 'src/components/Chats/Channel/ChannelHeader';

// Helpers
import { getUnreadPrivates } from 'src/store/selectors/chats';
import { closeChannel, changeChannelId, changeChannelView } from 'src/store/actions';
import { isHelper, isTeacher } from 'src/utils/roles';

// Constants
import CHATS from 'src/constants/chats';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const activeId = state.chats.active;
  const channel = state.chats.byId[activeId];
  const generalId = state.chats.general;
  const currentView = state.chats.currentView;

  // Unread property
  const privatesUnread = getUnreadPrivates(state) ?? 0;
  const generalUnread = state.chats.byId[generalId]?.unread ?? 0;

  // Get user for private message
  const userProps = {};
  if (currentView === CHATS.CHAT_PRIVATE) {
    // Users props
    const client = state.users.client;
    const participant = channel.usersIds.find((userId) => userId !== client?.id);

    const user = participant
      ? state.users.userById?.[participant]
      : state.users.userById?.[client.id];

    if (user) {
      userProps.user = user;
      userProps.name = user.name;

      // Role
      userProps.isMe = user.id === client.id;
      userProps.isHelper = isHelper(user.role);
      userProps.isTeacher = isTeacher(user.role);
    }
  }

  return {
    activeId,
    currentView,
    generalId,
    privatesUnread,
    generalUnread,
    ...userProps,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  actions: bindActionCreators(
    {
      closeChannel,
      changeChannelView,
      switchToGeneral: changeChannelId(stateProps.generalId),
      switchToHome: changeChannelId(null),
    },
    dispatchProps.dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, null, mergeProps)(ChannelHeader);
