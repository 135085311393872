/*
 * Package Import
 */
import * as z from 'zod';

/*
 * Local Import
 */
import { MongoId, mongoIdSchema } from 'src/schemas/Entities/utils';
import { entityEnum } from './entities';

/**
 * URLs
 */
export const BASE_URL = '/';
export const PAGE_NOT_FOUND_URL = '/404';
export const VIRTUAL_MACHINE_URL = '/vm';

// Settings
export const SETTING_URL = '/settings';
export const ACCOUNT_URL = '/settings/account';
export const APPLICATIONS_URL = '/settings/applications';
export const SETTINGS_URL = [SETTING_URL, ACCOUNT_URL, APPLICATIONS_URL];

// Classroom
export const CLASSROOM_URL = '/classroom/:course';
export const CLASSROOM_CHAT_URL = '/classroom/chat/:course';

// Admin
export const ADMIN_URL = '/admin';
export const ADMIN_ENTITY_URL = '/admin/:entity';
export const ADMIN_ENTITY_ACTION_URL = '/admin/:entity/:action';
export const ADMIN_ENTITY_ACTION_ID_URL = '/admin/:entity/:action/:id';
export const ADMINS_URLS = [
  ADMIN_URL,
  ADMIN_ENTITY_URL,
  ADMIN_ENTITY_ACTION_URL,
  ADMIN_ENTITY_ACTION_ID_URL,
];

export const ADMIN_COURSES_URL = '/admin/courses';
export const ADMIN_COURSES_ACTION_URL = '/admin/courses/:action';
export const ADMIN_COURSES_ACTION_ID_URL = '/admin/courses/:action/:id';
export const ADMINS_COURSES_URLS = [
  ADMIN_COURSES_ACTION_ID_URL,
  ADMIN_COURSES_ACTION_URL,
  ADMIN_COURSES_URL,
];

export const ADMIN_USERS_URL = '/admin/users';
export const ADMIN_USERS_ACTION_URL = '/admin/users/:action';
export const ADMIN_USERS_ACTION_ID_URL = '/admin/users/:action/:id';
export const ADMINS_USERS_URLS = [
  ADMIN_USERS_ACTION_ID_URL,
  ADMIN_USERS_ACTION_URL,
  ADMIN_USERS_URL,
];

export const ADMIN_PROMOTIONS_URL = '/admin/promotions';
export const ADMIN_PROMOTIONS_ACTION_URL = '/admin/promotions/:action';
export const ADMIN_PROMOTIONS_ACTION_ID_URL = '/admin/promotions/:action/:id';
export const ADMINS_PROMOTIONS_URLS = [
  ADMIN_PROMOTIONS_ACTION_ID_URL,
  ADMIN_PROMOTIONS_ACTION_URL,
  ADMIN_PROMOTIONS_URL,
];

/*
 * Code
 */

// ACTIONS
export const actions = {
  add: 'add',
  multipleAdd: 'multipleAdd',
  update: 'update',
  deactivate: 'deactivate',
  reactivate: 'reactivate',
  vm: 'vm',
  activity: 'activity',
  promotionUsers: 'promotionUsers',
  delete: 'delete',
} as const;

export const actionEnum = z.nativeEnum(actions);

export type ActionEnum = z.infer<typeof actionEnum>;

// ROUTENAMES
export const routeNames = {
  coursesTable: 'coursesTable',
  coursesAdd: 'coursesAdd',
  coursesUpdate: 'coursesUpdate',
  coursesDeactivate: 'coursesDeactivate',
  coursesReactivate: 'coursesReactivate',
  usersTable: 'usersTable',
  usersAdd: 'usersAdd',
  usersMultipleAdd: 'usersMultipleAdd',
  usersUpdate: 'usersUpdate',
  usersDeactivate: 'usersDeactivate',
  usersReactivate: 'usersReactivate',
  usersVm: 'usersVm',
  usersDelete: 'usersDelete',
  promotionsTable: 'promotionsTable',
  promotionsAdd: 'promotionsAdd',
  promotionsAddChild: 'promotionsAddChild',
  promotionsUpdate: 'promotionsUpdate',
  promotionsDeactivate: 'promotionsDeactivate',
  promotionsReactivate: 'promotionsReactivate',
  promotionsActivity: 'promotionsActivity',
  promotionsUsers: 'promotionsUsers',
} as const;

const routeNameEnum = z.nativeEnum(routeNames);
export type RouteEnum = z.infer<typeof routeNameEnum>;

// ROUTES
export const route = z.object({
  name: routeNameEnum,
  entity: entityEnum,
  action: actionEnum.optional(),
  needsId: z.boolean(),
  getUrl: z.function(z.tuple([z.object({ id: mongoIdSchema.optional() })]), z.string()),
});

export type Route = z.infer<typeof route>;

export const routes: Route[] = [
  {
    name: 'coursesTable',
    entity: 'courses',
    needsId: false,
    getUrl: () => '/admin/courses',
  },
  {
    name: 'coursesAdd',
    entity: 'courses',
    action: 'add',
    needsId: false,
    getUrl: () => '/admin/courses/add',
  },
  {
    name: 'coursesUpdate',
    entity: 'courses',
    action: 'update',
    needsId: true,
    getUrl: ({ id }) => `/admin/courses/update/${id}`,
  },
  {
    name: 'coursesDeactivate',
    entity: 'courses',
    action: 'deactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/courses/deactivate/${id}`,
  },
  {
    name: 'coursesReactivate',
    entity: 'courses',
    action: 'reactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/courses/reactivate/${id}`,
  },
  {
    name: 'usersTable',
    entity: 'users',
    needsId: false,
    getUrl: () => '/admin/users',
  },
  {
    name: 'usersAdd',
    entity: 'users',
    action: 'add',
    needsId: false,
    getUrl: () => '/admin/users/add',
  },
  {
    name: 'usersMultipleAdd',
    entity: 'users',
    action: 'multipleAdd',
    needsId: false,
    getUrl: () => '/admin/users/multipleAdd',
  },
  {
    name: 'usersUpdate',
    entity: 'users',
    action: 'update',
    needsId: true,
    getUrl: ({ id }) => `/admin/users/update/${id}`,
  },
  {
    name: 'usersDeactivate',
    entity: 'users',
    action: 'deactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/users/deactivate/${id}`,
  },
  {
    name: 'usersReactivate',
    entity: 'users',
    action: 'reactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/users/reactivate/${id}`,
  },
  {
    name: 'usersVm',
    entity: 'users',
    action: 'vm',
    needsId: true,
    getUrl: ({ id }) => `/admin/users/vm/${id}`,
  },
  {
    name: 'usersDelete',
    entity: 'users',
    action: 'delete',
    needsId: true,
    getUrl: ({ id }) => `/admin/users/delete/${id}`,
  },
  {
    name: 'promotionsTable',
    entity: 'promotions',
    needsId: false,
    getUrl: () => '/admin/promotions',
  },
  {
    name: 'promotionsAdd',
    entity: 'promotions',
    action: 'add',
    needsId: false,
    getUrl: () => '/admin/promotions/add',
  },
  {
    name: 'promotionsUpdate',
    entity: 'promotions',
    action: 'update',
    needsId: true,
    getUrl: ({ id }) => `/admin/promotions/update/${id}`,
  },
  {
    name: 'promotionsDeactivate',
    entity: 'promotions',
    action: 'deactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/promotions/deactivate/${id}`,
  },
  {
    name: 'promotionsReactivate',
    entity: 'promotions',
    action: 'reactivate',
    needsId: true,
    getUrl: ({ id }) => `/admin/promotions/reactivate/${id}`,
  },
  {
    name: 'promotionsActivity',
    entity: 'promotions',
    action: 'activity',
    needsId: true,
    getUrl: ({ id }) => `/admin/promotions/activity/${id}`,
  },
  {
    name: 'promotionsUsers',
    entity: 'promotions',
    action: 'promotionUsers',
    needsId: true,
    getUrl: ({ id }) => `/admin/promotions/promotionUsers/${id}`,
  },
];

export interface ParamsProps {
  action?: ActionEnum;
  id?: MongoId;
}

export interface IdToAction {
  update: null | MongoId;
  deactivate: null | MongoId;
  reactivate: null | MongoId;
  vm: null | MongoId;
  delete: null | MongoId;
  activity: null | MongoId;
  promotionUsers: null | MongoId;
  add: null | MongoId;
}
