/*
 * Package Import
 */
import React, { useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';

type QueryProviderProps = {
  children: React.ReactChild | null;
};

const QueryProvider = ({ children }: QueryProviderProps): JSX.Element => {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = queryClient;
  }

  return <QueryClientProvider client={queryClientRef.current}>{children}</QueryClientProvider>;
};

/*
 * Export
 */
export default QueryProvider;
