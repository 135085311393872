/*
 * Package Import
 */
import { useQuery, UseQueryResult } from 'react-query';

/*
 * Local Import
 */
import { User } from 'src/schemas/Entities/User';
import { useGetPromotions } from '../Promotions/useGetPromotions';
import { fetchUsers } from './fetchUsers';

/*
 * Code
 */
export const useGetUsers = (): UseQueryResult<User[]> => {
  useGetPromotions();

  const usersQuery = useQuery('users', fetchUsers, {
    initialData: [],
    staleTime: 5000,
  });

  return usersQuery;
};
