import { flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import * as T from 'src/components/Admin/TableElements';
import Tooltip from 'src/components/Tooltip';

const TableHeader = ({ filtersType, header }) => {
  const [isHover, setIsHover] = useState(false);

  const getFilter = useCallback(() => {
    if (!filtersType) return null;

    const { Filter } = filtersType;

    return <Filter column={header.column} />;
  }, [filtersType]);

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  return (
    <T.TableCellHeader>
      <T.HeaderContainer>
        <T.Header
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={header.column.getToggleSortingHandler()}
          style={{ ...(header.column.getCanSort() && { cursor: 'pointer' }) }}
        >
          <Tooltip
            content={`Tri de la colonne ${header.column.columnDef.header}`}
            disabled={!header.column.getCanSort()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {isHover && !header.column.getIsSorted() && header.column.getCanSort() && (
              <T.ArrowHover direction={header.column.getIsSorted() === 'desc'} />
            )}
            {header.column.getIsSorted() ? (
              <T.ArrowSort direction={header.column.getIsSorted() === 'desc'} />
            ) : null}
          </Tooltip>
        </T.Header>
        {header.column.getCanFilter() && filtersType && getFilter()}
      </T.HeaderContainer>
    </T.TableCellHeader>
  );
};

TableHeader.propTypes = {
  filtersType: PropTypes.object,
  header: PropTypes.any.isRequired,
};

TableHeader.defaultProps = {
  filtersType: null,
};

export default TableHeader;
