/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import PositionedSnackbar from 'src/components/Snackbar';
import { closeSnackNotif } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { snackIsOpen, snackMessageTitle, snackMessageInfo, severity } = state.notifications;

  return {
    snackIsOpen,
    snackMessageTitle,
    snackMessageInfo,
    severity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ closeSnackNotif }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(PositionedSnackbar);
