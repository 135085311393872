/*
 * Package Import
 */
import React, { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Format from 'src/components/Format';
import Unread from 'src/components/Chats/Channel/Unread';
import { ErrorMessage } from 'src/components/Chats/Channel/ErrorMessage';
import Mugshot from 'src/components/Chats/Channel/Mugshot';
import TimestampPreview from 'src/components/Chats/Channel/ChannelPreview/Timestamp';
import { MessageContext } from 'src/context/Message';

// Style
import * as S from './style';
import { getLatestMessagePreview } from './utils';

/*
 * Component
 */
const ChannelPreview = ({ actions, channelId, unread, ...userProps }) => {
  const { user, isMe, isHelper, isTeacher, isGhost, label } = userProps;
  const { getLastMessage } = useContext(MessageContext);
  const theme = useTheme();

  const lastMessage = getLastMessage(channelId);
  const messagePreview = getLatestMessagePreview(lastMessage);

  const time = lastMessage && lastMessage.time;

  /**
   * Change the current chat
   * @param {MouseEvent}
   * @return {void}
   */
  const onClick = () => {
    actions.changeChannelId();
  };

  /*
   * Render
   */
  return (
    <li>
      <S.TabButton id={`preview-chat-${channelId}`} type="button" onClick={onClick}>
        <Mugshot user={user} size={48} statusSize={0.75} />

        <S.Container textSize={theme.textSize}>
          {/* Infos */}
          <S.Infos>
            <S.Name>
              <S.Label>{label}</S.Label>
              {isHelper && <S.HelperIcon aria-label="Helper" />}
              {isTeacher && <S.TeacherIcon aria-label="Enseignant" />}
              {isGhost && <S.GhostIcon aria-label="Fantôme" />}
              {isMe && <S.You>&nbsp;(vous)&nbsp;</S.You>}
            </S.Name>
            <S.TimestampContainer>
              {time && <TimestampPreview time={time} />}
              <S.Arrow />
            </S.TimestampContainer>
          </S.Infos>

          {/* Content */}
          <ErrorBoundary fallback={ErrorMessage}>
            <S.Content>
              <S.Message unread={unread}>
                {messagePreview.ops.map((element, index) => (
                  <Format
                    // eslint-disable-next-line react/no-array-index-key
                    key={`key-content-element-${index}`}
                    content={messagePreview}
                    element={element}
                    index={index}
                  />
                ))}
              </S.Message>
              {!!unread && (
                <S.Unread>
                  <Unread unread={unread} />
                </S.Unread>
              )}
            </S.Content>
          </ErrorBoundary>
        </S.Container>
      </S.TabButton>
    </li>
  );
};

/*
 * PropTypes
 */
ChannelPreview.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Add the badge `you` if we're owner of the message  */
  isMe: PropTypes.bool,
  /** Add the badge `helper` */
  isHelper: PropTypes.bool,
  /** Add the badge `teacher` */
  isTeacher: PropTypes.bool,
  /** Add the badge `ghost` */
  isGhost: PropTypes.bool,
  /** Name of the author */
  label: PropTypes.string.isRequired,
  /** Number of unread messages */
  unread: PropTypes.number.isRequired,
  /** Channel ID */
  channelId: PropTypes.string,
  /** User */
  user: PropTypes.object.isRequired,
};

ChannelPreview.defaultProps = {
  channelId: null,
  isMe: false,
  isHelper: false,
  isTeacher: false,
  isGhost: false,
};

/*
 * Export
 */
export default React.memo(ChannelPreview);
