/*
 * Package Import
 */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Column, Table } from '@tanstack/react-table';

/*
 * Local Import
 */
import { useStore } from 'src/localStateManagers/store';
import { ZodValidationUser } from 'src/schemas/Adapters/Users/utils';
import { AnomalyTag } from './AnomalyTag';
import { isValueAnomaly, safeParseUser } from './utils';
import * as S from './style';

/*
 * Code
 */
type EditFormData = {
  firstname?: string;
  lastname?: string;
  email?: string;
};

type ColumnIds = 'firstname' | 'lastname' | 'email';

type EditableCellProps = {
  row: Row<ZodValidationUser>;
  column: Column<ZodValidationUser, unknown>;
  table: Table<ZodValidationUser>;
};

export const EditableCell = ({ row, column, table }: EditableCellProps): JSX.Element => {
  const { original: zodResult } = row;
  const user = zodResult?.data;
  const [edit, setEdit] = useState(false);

  // Form
  const { register, handleSubmit } = useForm();

  const handleEdit = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    setEdit(true);
  };

  const handleCancel = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    setEdit(false);
  };

  const handleValidateEdit = (data: EditFormData): void => {
    // ensure that data includes a value, allowing empty string
    if (
      user
      && (data[column.id as keyof EditFormData] !== null
        || data[column.id as keyof EditFormData] !== undefined)
    ) {
      // update the proper value in the row data
      const newUserBeforValidate = {
        ...user,
        [column.id]: data[column.id as keyof EditFormData],
      };

      // parse it as done for csv parsing
      const updatedUser = safeParseUser(newUserBeforValidate);
      // update user in the store & restart zod validation for all others
      // in order to update data & anomalies in table
      // especially in case of emails duplicate
      useStore.setState((state) => {
        const { newUsers } = state;
        const newUsersWithoutModifiedOne = newUsers
          .filter((newUser) => newUser.data.id !== user.id)
          .map((userZodValidation) => safeParseUser(userZodValidation.data));
        return {
          ...state,
          newUsers: [updatedUser, ...newUsersWithoutModifiedOne],
        };
      });
    }

    setEdit(false);
  };

  return (
    <S.Form>
      {edit && user && column.id && (
        <S.EditInput
          name={column.id}
          type="text"
          defaultValue={user[column.id as ColumnIds]}
          ref={register}
        />
      )}
      {!edit
        && user
        && (isValueAnomaly(zodResult, column) ? (
          <S.AnomalyValue onDoubleClick={handleEdit}>
            <AnomalyTag zodResult={zodResult} allColumns={table.getAllColumns()} column={column} />
          </S.AnomalyValue>
        ) : (
          <S.Value onDoubleClick={handleEdit}>{user[column.id as ColumnIds]}</S.Value>
        ))}
      {edit ? (
        <S.ValidationButtons>
          <S.CheckButton onClick={handleSubmit(handleValidateEdit)}>
            <S.CheckIcon />
          </S.CheckButton>
          <S.CancelButton onClick={handleCancel}>
            <S.CancelIcon />
          </S.CancelButton>
        </S.ValidationButtons>
      ) : (
        <S.EditButton onClick={handleEdit}>
          <S.EditIcon />
        </S.EditButton>
      )}
    </S.Form>
  );
};
