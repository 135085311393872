/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes/index';

/*
 * Styles
 */
const getTinyLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;

const StatusTag = styled.span(({ theme }) => ({
  margin: '0.3rem',
  borderRadius: '25px',
  padding: '0.2rem 0.4rem',
  whiteSpace: 'nowrap',
  ...getTinyLabelStyle(theme),

  '&.ongoing': {
    backgroundColor: theme.primary._5,
    color: theme.primary._1,
  },

  '&.finished': {
    backgroundColor: theme.success.background,
    color: theme.success.main,
  },

  '&.coming': {
    backgroundColor: theme.background._5,
    color: theme.text._4,
  },
}));

/*
 * Types
 */
type StatusProps = {
  status: {
    value: string;
    label: string;
  };
};

/*
 * Components
 */
export const Status = ({ status }: StatusProps): JSX.Element => (
  <StatusTag className={status.value}>{status.label}</StatusTag>
);
