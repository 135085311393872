/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  getPedaOptions,
  getPromoOptions,
  getPromoOptionsTooltipsInfos,
} from 'src/components/Admin/Modals/FormElements/utils';
import { getMinDate, getMaxDate } from './utils';

/*
 * Code
 */
export const coursesUpdateFormData = {
  header: 'Modifier le cours',
  inputs: {
    id: {
      title: 'id',
      display: 'Id',
      type: 'hidden',
    },
    title: {
      title: 'title',
      display: 'Titre du cours *',
      type: 'text',
    },
    date: {
      title: 'date',
      display: 'Date du cours *',
      type: 'date',
      getMinDate,
      getMaxDate,
    },
    selectPromotion: {
      title: 'promotion',
      display: 'Promotion *',
      isMulti: false,
      getPromoOptions,
      getPromoOptionsTooltipsInfos,
    },
    selectTeacher: {
      title: 'teacher',
      display: 'Formateur *',
      isMulti: false,
      selectToWatch: 'helpers',
      getPedaOptions,
    },
    selectHelpers: {
      title: 'helpers',
      display: 'Helpers',
      isMulti: true,
      selectToWatch: 'teacher',
      getPedaOptions,
    },
  },
};
