/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import SigninSheet from 'src/components/TopBar/Menu/SigninSheet';
import { bindActionCreators } from 'redux';
import { setSignatureModal } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { isSigninSheetOpen, signatureLink } = state.signature;

  return {
    isOpen: isSigninSheetOpen,
    signatureLink,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setSignatureModal }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(SigninSheet);
