/*
 * Package Import
 */
import styled from '@emotion/styled';

export const LoaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
});
