/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Shortcuts from 'src/components/TopBar/Menu/Shortcuts';
import { setModalShortcuts } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  isOpen: state.shortcuts.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setModalShortcuts }, dispatch),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Shortcuts);
