/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface ShortcutsState {
  isOpen: boolean;
}

/*
 * Initial state
 */
const initialState: ShortcutsState = {
  isOpen: false,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_MODAL_SHORTCUTS:
        draft.isOpen = action.isOpen;
        break;

      default:
        return state;
    }
  });
