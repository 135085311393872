/*
 * Package Import
 */
import React from 'react';
import { useIsFetching } from 'react-query';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import * as S from './style';

/*
 * Types
 */
interface HeaderProps {
  pageTitle: string;
}

/*
 * Code
 */
const Header = ({ pageTitle }: HeaderProps): JSX.Element => {
  const isFetching = useIsFetching();

  return (
    <S.Container>
      <S.Title>{pageTitle}</S.Title>
      <S.LoaderContainer>{!!isFetching && <Loader size={48} type="Oval" />}</S.LoaderContainer>
    </S.Container>
  );
};

/*
 * Export
 */
export default Header;
