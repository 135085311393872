/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/**
 * Const
 */
const drawerWidth = 20.5;
const chatLargeWidth = 26.5;

/*
 * Style
 */
export const Main = styled.main(({ theme, leftOpened, rightOpened, chatSize }) => ({
  display: 'grid',
  height: '100%',
  overflow: 'hidden',
  gridTemplateRows: '3rem 1fr',
  gridTemplateColumns: `
    ${leftOpened ? `${drawerWidth}rem` : 0}
    1fr
    ${rightOpened ? `${chatSize === 'large' ? chatLargeWidth : drawerWidth}rem` : 0}
  `,
  transition: 'grid-template-columns 0.2s',
  background: theme.themeName === LIGHT_THEME_NAME ? theme.background._8 : theme.background._5,
}));

export const MainTitleSrOnly = styled.h1(({ theme }) => ({
  ...theme.srOnly,
}));

export const AppBar = styled.div(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.background._2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.text._7}`,
  gridColumn: '2',
  width: '100%',
  overflow: 'hidden',
  // Fix stacking context issue
  // https://slippers.atlassian.net/browse/SLP-23
  zIndex: 1,
}));

export const Drawer = styled.div(({ leftOpened, rightOpened, anchor, chatSize, theme }) => {
  let sideStyle;

  switch (anchor) {
    case 'left':
      sideStyle = {
        transform: !leftOpened && `translate(-${drawerWidth}rem)`,
        borderRight: `1px solid ${theme.text._7}`,
        color: theme.text._1,
        height: '100vh',
        visibility: !leftOpened && 'hidden',
      };
      break;

    case 'right':
      sideStyle = {
        transform: !rightOpened && `translate(${drawerWidth}rem)`,
        borderLeft: `1px solid ${theme.text._7}`,
        color: theme.text._1,
        height: '100vh',
        visibility: !rightOpened && 'hidden',
        ...(chatSize === 'large' && { width: `${chatLargeWidth}rem` }),
      };
      break;

    default:
      break;
  }

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: `${drawerWidth}rem`,
    gridRow: '1/-1',
    gridColumn: anchor === 'right' && '3',
    overflow: 'hidden',
    background: theme.background._2,
    transitionProperty: 'height, transform, visibility, width',
    transitionDuration: '0.2s',
    ...sideStyle,
  };
});

export const Center = styled.section(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  background: theme.themeName === LIGHT_THEME_NAME ? theme.background._8 : theme.background._5,
}));

export const Popout = styled.div(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  maxWidth: '100vw',
  maxHeight: '100vh',
  color: theme.text._1,
  background: theme.background._2,
  position: 'relative',
  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));
