/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local import
 */
import { openChat, changeMainChatView } from 'src/store/actions';
import ProfileCard from './index';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => ({
  mugshot: state.users.mugshotById[ownProps?.id],
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      openChat,
      changeMainChatView,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
