/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Labels from 'src/components/Users/Profile/Labels';
import Image from 'src/components/Users/Profile/Image/index';
import AvatarTooltip from 'src/components/Tooltip/AvatarTooltip';

// Style
import * as S from './style';

/*
 * Component
 */
// Props user coming from container
const Profile = ({ user, webcamEnabled }) => (
  <S.Profile>
    <S.Container>
      <AvatarTooltip name={user.name} connected={user.connected} away={user.away}>
        <Image user={user} webcamEnabled={webcamEnabled} />
      </AvatarTooltip>
      <Labels name={user.name} role={user.role} />
    </S.Container>
  </S.Profile>
);

/*
 * PropTypes
 */
Profile.propTypes = {
  user: PropTypes.object.isRequired,
  webcamEnabled: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default Profile;
