/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
// Helpers
import { isEnter, isSpace } from 'src/utils/events';

// Style
import * as S from './style';

/*
 * Component
 */
const UserSearch = ({ searchTerm, setSearchTerm }) => {
  /*
   * State
   */
  const [inputValue, setInputValue] = useState(searchTerm);
  const [focus, updateFocus] = useState('false');

  /*
   * Handlers
   */

  const changeSearchTerm = (value) => {
    setSearchTerm(value);
    setInputValue(value);
  };
  const onSearchChange = (evt) => {
    changeSearchTerm(evt.target.value);
  };
  const resetSearch = () => {
    changeSearchTerm('');
  };

  const onKeyDown = (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      resetSearch();
    }
  };

  /*
   * Render
   */
  return (
    <S.SearchContainer>
      <S.SubTitleSrOnly>Rechercher un participant</S.SubTitleSrOnly>
      <S.SearchInput
        value={inputValue}
        id="userSearchInput"
        type="search"
        searchTerm={searchTerm}
        onChange={onSearchChange}
        placeholder="Rechercher un participant"
        onFocus={() => updateFocus('true')}
        onBlur={() => updateFocus('false')}
        aria-label="Rechercher un participant (résultats mis à jour ci-dessous pendant la saisie)"
      />
      <S.StyledUilSearch
        searchterm={searchTerm}
        focus={focus}
        onClick={() => resetSearch()}
        aria-hidden="true"
      />
      <S.StyledUilTimes
        searchterm={searchTerm}
        role="button"
        focus={focus}
        onClick={() => resetSearch()}
        aria-label="annuler la recherche"
        tabIndex="0"
        onKeyDown={onKeyDown}
      />
    </S.SearchContainer>
  );
};

/*
 * PropTypes
 */
UserSearch.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
};

UserSearch.defaultProps = {
  searchTerm: '',
};

/*
 * Export
 */
export default UserSearch;
