/**
 * WebSocket connect management
 */
export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_CONNECT_SUCCESS = 'WEBSOCKET_CONNECT_SUCCESS';
export const WEBSOCKET_CONNECT_ERROR = 'WEBSOCKET_CONNECT_ERROR';
export const WEBSOCKET_GET_UNAUTH_ERROR = 'WEBSOCKET_UNAUTHORIZED';

/*
 * Initialization
 */
export const INITIALIZE_DATA = 'INITIALIZE_DATA';
export const INITIALIZE_ORGANIZATION = 'INITIALIZE_ORGANIZATION';
export const INITIALIZE_KC_TOKEN = 'INITIALIZE_KC_TOKEN';

/**
 * Breaktime
 */

export const CHANGE_AWAY_TIME = 'CHANGE_AWAY_TIME';
export const SET_MODAL_AWAY = 'SET_MODAL_AWAY';

/*
 * Chats
 */
export const OPEN_CHAT = 'chat.open';
export const OPENED_CHAT = 'chat.opened';
export const CLOSE_CHAT = 'chat.close';
export const CLOSED_CHAT = 'chat.closed';
export const CHANGE_CHAT = 'chat.change';
export const CHANGED_CHAT = 'chat.changed';
export const CHANGE_ACTIVE_VIEW = 'chat.change.view';
export const SET_CHAT_UNREAD = 'chat.set.unread';
export const CREATE_NEW_CHAT = 'chat.create';
export const CHAT_CREATED = 'chat.created';
export const CHANGE_MAIN_CHAT_VIEW = 'chat.change.main.view';
export const SET_POPOUT = 'chat.set.popout';

// Requests
export const CHAT_I_AM_TYPING = 'chat.i_am_typing';
export const SET_X_IS_TYPING = 'chat.x_.is_typing';

/*
 * Course
 */
export const ENABLE_MICROPHONE = 'COURSE_MICROPHONE_ENABLE';
export const DISABLE_MICROPHONE = 'COURSE_MICROPHONE_DISABLE';
export const SET_MICROPHONE_STATUS = 'SET_MICROPHONE_STATUS';
export const ENABLE_WEBCAM = 'COURSE_WEBCAM_ENABLE';
export const DISABLE_WEBCAM = 'COURSE_WEBCAM_DISABLE';
export const ENABLE_SOUND = 'COURSE_SOUND_ENABLE';
export const DISABLE_SOUND = 'COURSE_SOUND_DISABLE';
export const ENABLE_SCREEN = 'COURSE_SCREEN_ENABLE';
export const DISABLE_SCREEN = 'COURSE_SCREEN_DISABLE';
export const SET_SCREEN_STATUS = 'SET_SCREEN_STATUS';

// Media device management
export const UPDATE_CURRENT_DEVICE = 'COURSE_UPDATE_CURRENT_DEVICE';

// Raise Hand
export const SEND_RAISE_HAND = 'send.raise.hand';
export const RECEIVE_HAND_RAISED = 'receive.hand.raised';
export const SEND_UNRAISE_HAND = 'send.unraise.hand';
export const RECEIVE_HAND_UNRAISED = 'receive.hand.unraised';
export const SEND_REFUSE_GIVE_VOICE = 'send.refuse.give.voice';
export const RECEIVE_REFUSE_GIVE_VOICE = 'receive.refuse.give.voice';

// Give Voice
export const SEND_GIVE_VOICE = 'send.give.voice';
export const RECEIVE_VOICE_GIVEN = 'receive.voice.given';
export const SEND_TAKE_VOICE = 'send.take.voice';
export const RECEIVE_VOICE_TAKEN = 'receive.voice.taken';
// New comer asks for audio streams
export const SEND_AUDIO_STREAM = 'send.audio.stream';
export const RECEIVE_AUDIO_STREAMS = 'receive.audio.streams';
// Webcams
export const SET_USER_WEBCAM_MUTED = 'send.webcam.muted';
// Screen
export const SEND_HAS_SCREEN = 'send.has.screen';

// Course events
export const COURSE_END_SOON = 'COURSE_END_SOON';
export const COURSE_ENDED = 'COURSE_ENDED';

/**
 * Audio Conference
 */
export const SET_AUDIO_CONFERENCE_STATUS = 'COURSE_SET_AUDIO_CONFERENCE_STATUS';
export const GET_AUDIO_CONFERENCE_PEERS = 'COURSE_GET_AUDIO_CONFERENCE_PEERS';
export const SET_AUDIO_CONFERENCE_PEERS = 'COURSE_SET_AUDIO_CONFERENCE_PEERS';

/*
 * Feedback
 */
export const SET_MODAL_FEEDBACK = 'SET_MODAL_FEEDBACK';
export const SET_SELECT_FEEDBACK = 'SET_SELECT_FEEDBACK';
export const SET_COMMENT_FEEDBACK = 'SET_COMMENT_FEEDBACK';
export const SET_ERROR_FEEDBACK = 'SET_ERROR_FEEDBACK';
export const FEEDBACK_SEND = 'feedback.send';
export const FEEDBACK_SEND_ERROR = 'feedback.send.error';
export const RECEIVED_FEEDBACK = 'feedback.received';
export const FEEDBACK_SENT = 'feedback.sent';

/**
 * Inputs
 */
export const MESSAGE_INPUT_CHANGE = 'MESSAGE_INPUT_CHANGE';
export const RESET_INPUT = 'RESET_INPUT';

/*
 * Messages
 */
export const CREATED_MESSAGE = 'message.created';
export const RECEIVED_MESSAGE = 'message.received';
export const SEND_MESSAGE = 'message.send';
export const SELF_MESSAGE = 'message.self';

// Messages º Reactions
export const ADD_REACTION = 'message.reaction.add';
export const REMOVE_REACTION = 'message.reaction.remove';

// Messages º Flags
export const ADD_PIN = 'message.pin.add';
export const REMOVE_PIN = 'message.pin.remove';
export const FLAG_MESSAGE_AS_QUESTION = 'message.ask.flag';
export const SET_QUESTION_MESSAGE_ANSWERED_STATUS = 'message.ask.answered';

// Messages º Other message actions...
export const EDIT_MESSAGE = 'message.edit';
export const DELETE_MESSAGE = 'message.delete';
export const CONFIRM_COPY = 'message.confirm.copy';

// Messages º Filter
export const SET_VISIBILITY_FILTER = 'message.set.filter';

/*
 * Modal
 */
export const OPEN_MODAL = 'modal.open';
export const CLOSE_MODAL = 'modal.close';
export const OPEN_CREATE_SURVEY = 'survey.create.open';

/*
 * Surveys
 */
export const ASK_SURVEY = 'survey.ask';
export const RECEIVED_SURVEY = 'survey.received';
export const ERROR_SEND_SURVEY = 'survey.error';
export const VOTE_SURVEY = 'survey.vote';

/*
 * Notifications
 */
export const ADD_NOTIFICATION = 'notification.add';
export const SET_MENU_NOTIFICATIONS = 'SET_MENU_NOTIFICATIONS';
export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const SET_MODAL_NOTIFICATION_READER = 'SET_MODAL_NOTIFICATION_READER';
export const SET_CURRENT_NOTIFICATION = 'SET_CURRENT_NOTIFICATION';
export const CLOSE_SNACK_NOTIFICATIONS = 'CLOSE_SNACK_NOTIFICATIONS';

/*
 * Settings
 */

// Layout
export const RESET_LAYOUT = 'SETTINGS_RESET_LAYOUT';
export const TEXTSIZE_CHANGE = 'SETTINGS_TEXTSIZE_CHANGE';
export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
export const TOGGLE_CHAT_DRAWER = 'TOGGLE_CHAT_DRAWER';
export const TOGGLE_USERS_DRAWER = 'TOGGLE_USERS_DRAWER';
export const SET_CHAT_APPEARANCE = 'SET_CHAT_APPEARANCE';
export const SET_CHAT_SIZE = 'SET_CHAT_SIZE';
export const SET_USERLIST_STYLE = 'SET_USERLIST_STYLE';
export const SET_POPOUT_CHAT = 'SET_POPOUT_CHAT';
export const SET_POPOUT_CAM = 'SET_POPOUT_CAM';
export const SET_POPOUT_SCREEN = 'SET_POPOUT_SCREEN';

// Notifications
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const CHANGE_NOTIFICATIONS_SOUND = 'CHANGE_SOUND';

// Medias
export const CHANGE_CAMERA = 'CHANGE_CAMERA';
export const CHANGE_MICROPHONE = 'CHANGE_MICROPHONE';

/*
 * Shortcuts
 */
export const SET_MODAL_SHORTCUTS = 'SET_MODAL_SHORTCUTS';

/**
 * Tracking (Activities only)
 */
export const CHANGE_TRACKING_STATUS = 'CHANGE_TRACKING_STATUS';

/*
 * Users
 */
export const LOG_USER_IN = 'LOG_USER_IN';
export const GET_CONNECTED_USERS = 'GET_CONNECTED_USERS';
export const RECEIVE_CONNECTED_USERS = 'RECEIVE_CONNECTED_USERS';
// Status
export const HAS_LEFT = 'user.left';
export const HAS_JOINED = 'user.join';
export const CHANGE_AWAY = 'user.away';
export const MAIN_CONNEXION_CLOSED = 'user.main.closed';

// Data
export const SEND_MUGSHOT = 'user.mugshot';
export const CHANGE_EMOTION = 'user.emotion';

/**
 * Transcription
 */
export const TRANSCRIPTION_INIT = 'TRANSCRIPTION_INIT';
export const TRANSCRIPTION_REFRESH_TOKEN = 'TRANSCRIPTION_REFRESH_TOKEN';

export const SET_TEXT = 'SET_TEXT';
export const SET_TRANSCRIPTION_ENABLED = 'SET_TRANSCRIPTION_ENABLED';
export const CONNECT_TRANSCRIPTION = 'CONNECT_TRANSCRIPTION';
export const DISCONNECT_TRANSCRIPTION = 'DISCONNECT_TRANSCRIPTION';
export const SET_PREVIOUS_ID = 'SET_PREVIOUS_ID';

// Ws emit & on
export const SEND_TRANSCRIPTION = 'send.transcription';
export const RECEIVE_TRANSCRIPTION = 'receive.transcription';

// WebRTC
export const WEBRTC_CONNECT = 'WEBRTC_CONNECT';
export const SET_LOCAL_WEBCAM_STREAM_ID = 'SET_LOCAL_WEBCAM_STREAM_ID';
export const SET_LOCAL_SCREEN_STREAM_ID = 'SET_LOCAL_SCREEN_STREAM_ID';
export const START_MY_WEBCAM_STREAM = 'START_MY_WEBCAM_STREAM';
export const STOP_MY_WEBCAM_STREAM = 'STOP_MY_WEBCAM_STREAM';
export const SET_STREAM_LIST = 'SET_STREAM_LIST';
export const SET_STREAM_LIST_STATUS = 'SET_STREAM_LIST_STATUS';
export const SET_MY_CAM_AND_MIC_PUBLISHING = 'SET_MY_CAM_AND_MIC_PUBLISHING';
export const ANTMEDIA_LOCALSTREAM_READY = 'ANTMEDIA_LOCALSTREAM_READY';

/**
 * VIRTUAL DESKTOP
 */
export const SET_VIRTUAL_DESKTOP_STATUS = 'VIRTUAL_DESKTOP_SET_STATUS';

/**
 * Tracking (Events Mixpanel)
 */
export const CHANGE_THEME = 'CHANGE_THEME';
export const COURSE_LEFT = 'COURSE_LEFT';

/**
 * SigninSheet
 */
export const EDUSIGN_TEACHER_SIGNATURE_NEEDED = 'EDUSIGN_TEACHER_SIGNATURE_NEEDED';
export const EDUSIGN_STUDENT_SIGNATURE_NEEDED = 'EDUSIGN_STUDENT_SIGNATURE_NEEDED';
export const SET_MENU_SIGNATURE = 'SET_MENU_SIGNATURE';
export const SET_SIGNIN_SHEET_MODAL = 'SET_SIGNIN_SHEET_MODAL';
export const SET_SIGN_FOLLOWING_MODAL = 'SET_SIGN_FOLLOWING_MODAL';
export const RECEIVED_SIGNATURE_LINKS = 'RECEIVED_SIGNATURE_LINKS';
export const START_SIGNATURE = 'START_SIGNATURE';
export const SIGNATURE_DONE = 'SIGNATURE_DONE';
export const SIGNATURE_FAIL = 'SIGNATURE_FAIL';
export const SIGNATURE_STUDENT_OPEN = 'SIGNATURE_STUDENT_OPEN';
export const SIGNATURE_UPDATE_STUDENTS = 'SIGNATURE_UPDATE_STUDENTS';
export const CONFIRM_RESET_SIGNATURES = 'CONFIRM_RESET_SIGNATURES';
export const RESET_SIGNATURES = 'RESET_SIGNATURES';
export const SIGNATURES_RESET = 'signatures.reset';
export const RESET_SIGNATURES_FAIL = 'reset.signatures.fail';
export const RESEND_SIGNATURE_NOTIFICATIONS = 'RESEND_SIGNATURE_NOTIFICATIONS';
export const RESEND_SIGNATURE_NOTIFICATIONS_SUCCESS = 'RESEND_SIGNATURE_NOTIFICATIONS_SUCCESS';
export const CHECK_SIGNATURE_TIMESLOT = 'CHECK_SIGNATURE_TIMESLOT';
export const CHECKED_SIGNATURE_TIMESLOT = 'CHECKED_SIGNATURE_TIMESLOT';
// error
export const SIGNATURE_START_ERROR = 'signature.start.error';
export const SIGNATURE_RESEND_ERROR = 'signature.resend.error';
