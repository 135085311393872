/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */

// Helpers
import { HAND_STATUS } from 'src/constants/conference';
import { awayTime } from './data';

// Style
import * as S from './style';

/*
 * Components
 */
const AwayChoice = ({ actions, user, isOpen, handStatus, screenEnabled, ...userProps }) => {
  /**
   * Props
   */
  const { isClientStudent } = userProps;

  /**
   * Disable microphone 🎤
   */
  const disableMicrophone = () => {
    actions.disableMicrophone();
  };

  /**
   * Disable webcam 📽
   */
  const disableWebcam = () => {
    // State device management
    actions.disableWebcam();
  };

  /**
   * Disable sound
   */
  const disableSound = () => {
    actions.disableSound();
  };

  /**
   * Disable devices (Microphone, webcam, and screen)
   */
  const disableDevices = () => {
    disableMicrophone();
    disableWebcam();
    disableSound();

    if (screenEnabled) {
      actions.disableScreen();
    }
  };

  /**
   * Have a break, have a Kit Kat 💃
   */
  const onClick = () => {
    // Send to WS
    actions.changeAway({ away: true, user });
    actions.setModalAway({ isOpen: false });

    // If student's speaking, take voice back
    if (isClientStudent && handStatus === HAND_STATUS.VOICE_GIVEN) {
      actions.sendTakeVoice(user);
    }

    // Disable devices (Microphone, webcam, and screen)
    disableDevices();
  };

  /**
   * How long to take a break
   * @param {*} evt
   */
  const onChangeTime = (evt) => {
    const selectedTimeValue = parseInt(evt.target.value, 10);
    actions.changeAwayTime(selectedTimeValue);
  };

  /**
   * Don't take a break 🚶‍♀️
   * @param {*} evt
   */
  const onClose = (evt) => {
    evt.preventDefault();
    actions.setModalAway({ isOpen: false });
  };

  /**
   * Render
   */
  return (
    <S.Modal open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <S.Away>
        <S.Header variant="h1">Faire une pause</S.Header>
        <S.Label>On se prend une pause ?</S.Label>
        <S.Choose>
          <S.SelectLabel>Mettre un minuteur de :</S.SelectLabel>
          <S.StyledSelect onChange={(evt) => onChangeTime(evt)}>
            {awayTime.map((time) => (
              <S.Option key={time.label} value={time.value}>
                {time.label}
              </S.Option>
            ))}
          </S.StyledSelect>
        </S.Choose>
        <S.Buttons>
          <S.StyledButton
            data-id="away-no"
            onClick={onClose}
            // Style props
            isCancel
            // a11y
            type="button"
            aria-label="Non"
          >
            Annuler
          </S.StyledButton>
          <S.StyledButton
            data-id="away-yes"
            onClick={onClick}
            // Style props
            isMain
            // a11y
            type="button"
            aria-label="Oui"
          >
            Faire une pause
          </S.StyledButton>
        </S.Buttons>
      </S.Away>
    </S.Modal>
  );
};

AwayChoice.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  user: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  /** Current user hand status */
  handStatus: PropTypes.string.isRequired,
  screenEnabled: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default AwayChoice;
