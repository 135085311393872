/**
 * Code
 */
let audioUnlocked = false;

/**
 * Some browsers/devices will only allow audio to be played after a user
 * interaction. Attempt to automatically unlock audio on the first user
 * interaction.
 *
 * Concept from: http://paulbakaus.com/tutorials/html5/web-audio-on-ios/
 */
export const unlockAudio = () => {
  // Only run this if it hasn't already been unlocked.
  if (audioUnlocked) {
    return;
  }

  const unlock = () => {
    try {
      const audioNode = new Audio('/static/media/audios/notification/default.m4a');
      // Mark this Audio object as unlocked
      audioNode._unlocked = true;
    }
    catch (err) {
      /**/
    }

    // Update the unlocked state and prevent this check from happening again.
    audioUnlocked = true;

    // Remove the touch start listener.
    document.removeEventListener('touchstart', unlock, true);
    document.removeEventListener('touchend', unlock, true);
    document.removeEventListener('click', unlock, true);
    document.removeEventListener('keydown', unlock, true);
  };

  // Setup a touch start listener to attempt an unlock in.
  document.addEventListener('touchstart', unlock, true);
  document.addEventListener('touchend', unlock, true);
  document.addEventListener('click', unlock, true);
  document.addEventListener('keydown', unlock, true);
};
