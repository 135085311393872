/**
 * Purify data
 * @param {*} value
 * @returns
 */
export const purify = (value) => {
  if (value) {
    // Strings, like the `Town`, `firstName`, `lastName`, etc… fields
    if (typeof value === 'string' && value.length > 0 && value.trim() !== '') {
      return value.trim();
    }

    // Objects, like field the `Avatar` field
    if (typeof value === 'object') {
      return value;
    }
  }

  return null;
};
