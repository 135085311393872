/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { UserDTO } from 'src/schemas/Entities/User';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { updatePromotionUsers, UpdatePromotionUsersProps } from './updatePromotionUsers';

/*
 * Code
 */
export const useUpdatePromotionUsers = (): UseMutationResult<
UserDTO[],
unknown,
UpdatePromotionUsersProps,
unknown
> => {
  const promotionUsersMutation = useMutation(
    (updatePromotionUsersProps: UpdatePromotionUsersProps) =>
      updatePromotionUsers(updatePromotionUsersProps),
    {
      onSettled: () => {
        queryClient.invalidateQueries('users');
      },
      onSuccess: (data, { promotionId }) => {
        const usersWithNewPromotion = data.find((user) => user.promosIds.includes(promotionId));
        const usersWithoutNewPromotion = data.find((user) => !user.promosIds.includes(promotionId));
        if (usersWithNewPromotion) {
          if (usersWithoutNewPromotion) {
            toast('Utilisateurs ajoutés/retirés à la promotion avec succès', {
              type: 'success',
            });
          }
          else {
            toast('Utilisateurs ajoutés à la promotion avec succès', { type: 'success' });
          }
        }
        else {
          toast('Utilisateurs retirés à la promotion avec succès', { type: 'success' });
        }
      },
    },
  );

  return promotionUsersMutation;
};
