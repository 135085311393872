/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local import
 */
import { breakpoints, medias } from 'src/theme';

export const Container = styled.div({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '30px',
  padding: '0 10px',
  marginTop: '40px',
  [medias.minWidth(breakpoints.m)]: {
    justifyContent: 'center',
    fontSize: '40px',
    marginTop: 0,
    height: '100vh',
  },
});

export const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 115%;
  letter-spacing: -0.89px;
  color: #1a202e;
  margin-bottom: 16px;
  text-align: center;
`;

export const Text = styled.p`
  max-width: 519px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 0.5em;
  line-height: 150%;
  letter-spacing: -0.33px;
  color: #64748b;
  text-align: center;
`;

export const Browsers = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '80px',
  [medias.minWidth(breakpoints.m)]: {
    marginTop: '80px',
  },
});

export const Browser = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 80px;
`;

export const BrowserLogo = styled.img({
  width: '100px',
  [medias.minWidth(breakpoints.m)]: {
    width: '200px',
  },
});

export const BrowserName = styled.p({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '150%',
  letterSpacing: '-0.33px',
  color: '#1c64f2',
  fontSize: '.5em',
  marginBottom: '20px',
  [medias.minWidth(breakpoints.m)]: {
    marginBottom: 0,
  },
});
