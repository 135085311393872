/* eslint-disable max-len */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Badge from 'src/components/Users/UsersPanel/List/UserCard/Badge';
import ListEmotions from 'src/components/Users/UsersPanel/Emotions/ListEmotions';
import { NO_EMOTION } from 'src/constants/emotions';
import { adminRolesLabels } from 'src/components/Users/data/roles';

// Style
import * as S from './style';

/*
 * Component
 */
const Labels = ({ name, role, emotion }) => (
  <S.Container>
    <S.Line>
      <S.Name>{name}</S.Name>
      {!!adminRolesLabels[role] && <Badge role={role} />}
    </S.Line>
    <S.Line>
      {!adminRolesLabels[role] && <ListEmotions emotion={emotion} />}
      {!!adminRolesLabels[role] && <S.Role roleName={role}>{adminRolesLabels[role].label}</S.Role>}
    </S.Line>
  </S.Container>
);

/*
 * PropTypes
 */
Labels.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  emotion: PropTypes.string,
};

Labels.defaultProps = {
  emotion: NO_EMOTION,
};

/*
 * Export
 */
export default Labels;
