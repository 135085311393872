import styled from '@emotion/styled';

export const TimeSeparatorContainer = styled.div(({ theme }) => ({
  paddingTop: '0.5em',
  paddingLeft: '0.5rem',

  div: {
    borderTopColor: `${theme.colors.coolGrey._200} !important`,
    margin: 0,

    span: {
      border: 'none',
      borderRadius: 0,

      time: {
        fontSize: `calc(0.75 * ${theme.textSize}rem)`,
        marginLeft: 0,
      },
    },
  },
}));
