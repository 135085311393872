/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilGraduationCap,
  UilCommentAltDots,
  UilAngleLeft,
  UilShieldCheck,
  UilShieldPlus,
  UilTimes,
} from '@iconscout/react-unicons';

/*
 * Style
 */
const iconStyle = {
  height: '1rem',
  width: '1rem',
};

export const ClassIcon = styled(UilGraduationCap)({
  height: '1.35rem',
  width: '1.35rem',
});

export const PrivateIcon = styled(UilCommentAltDots)({
  height: '1.25rem',
  width: '1.25rem',
});

export const ReturnArrow = styled(UilAngleLeft)(({ theme }) => ({
  height: '1.75rem',
  width: '1.75rem',
  color: theme.text._5,
}));

export const IconClose = styled(UilTimes)(({ theme }) => ({
  height: '1.5rem',
  width: '1.5rem',
  color: theme.text._5,
}));

export const TeacherIcon = styled(UilShieldCheck)(({ theme }) => ({
  color: theme.primary._1,
  ...iconStyle,
}));

export const HelperIcon = styled(UilShieldPlus)(({ theme }) => ({
  color: theme.success.main,
  ...iconStyle,
}));

export const Tabs = styled.ul`
  display: flex;
`;

export const Label = styled.span(({ theme, isActive }) => ({
  maxHeight: '1.5rem',
  overflow: 'hidden',
  ...(!isActive
    ? theme.srOnly
    : {
      display: 'inline',
      color: theme.primary._1,
      marginLeft: '.5rem',
      ...theme.typo.labels.smallLabel,
    }),
}));

export const Tab = styled.li`
  position: relative;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.text._7};
  border-bottom: 1px solid ${({ theme }) => theme.text._7};
  height: 3.125rem;
  width: ${({ isActive }) => (isActive ? '80%' : '20%')};
  background: ${({ isActive, theme }) => (isActive ? theme.background._2 : theme.background._4)};
  transition: width 0.5s;
  outline: none;

  ${ClassIcon},
  ${PrivateIcon} {
    color: ${({ theme, isActive }) => (isActive ? theme.primary._1 : theme.text._5)};
  }

  :hover {
    ${Label},
    ${ClassIcon},
    ${PrivateIcon} {
      color: ${({ theme }) => theme.primary._2};
    }
  }

  :focus-visible {
    ::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border: ${({ theme }) => `2px solid ${theme.primary._1}`};
      border-radius: 8px;
    }
  }
  :-moz-focusring {
    ::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;
      border: ${({ theme }) => `2px solid ${theme.primary._1}`};
      border-radius: 8px;
    }
  }
`;

export const Buttons = styled.div(({ theme }) => ({
  alignItems: 'center',
  background: theme.background._2,
  color: theme.text._5,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  ...theme.typo.labels.smallLabel,
  height: '48px',
  padding: '.5rem .75rem .5rem .25rem',
  position: 'relative',
  borderBottom: `1px solid ${theme.text._7}`,
  ...theme.shadows.plus1,
}));

export const Button = styled.button(({ theme }) => ({
  alignItems: 'center',
  background: theme.background._2,
  color: theme.text._5,
  display: 'flex',
  padding: '0.1rem',
  borderRadius: '8px',
  'first-of-type': {
    marginRight: '.25rem',
  },
  ':hover': {
    color: theme.primary._2,
    [ReturnArrow]: {
      color: theme.primary._2,
    },
    [IconClose]: {
      color: theme.primary._2,
    },
  },

  ...theme.focusVisible,
}));

export const Infos = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Name = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const User = styled.h3(({ theme }) => ({
  color: theme.text._1,
  marginRight: '.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ...theme.typo.headlines.H6,
  maxWidth: '12.5rem',
}));

export const You = styled.span(({ theme }) => ({
  color: theme.text._5,
  ...theme.typo.labels.tinyLabel,
}));

export const MinorTitleSrOnly = styled.h4(({ theme }) => ({
  ...theme.srOnly,
}));
