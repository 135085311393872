import { useState } from 'react';
import { useInterval } from 'src/hooks';

export const useTimer = ({
  initialTimer = 0, // if we begin to 0 or not
  target = 30000, // when it's ready
  initialReady = true, // if it's beginning ready or not
}) => {
  const [timer, setTimer] = useState(initialTimer);
  const [ready, setReady] = useState(initialReady);

  // Each second we add one second at timer and check if it's ready
  const handleSecond = () => {
    const newTimer = timer + 1000;
    setTimer(newTimer);
    if (newTimer >= target && !ready) {
      setReady(true);
    }
  };
  useInterval(handleSecond, 1000);

  const resetTimer = () => {
    setTimer(0);
    setReady(false);
  };

  return [timer, ready, resetTimer];
};
