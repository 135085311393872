/*
 * Package Import
 */

/*
 *  Local Import
 */
import { makeRequest, VMPayload } from 'src/utils/virtualMachine';
import { User } from 'src/schemas/Entities/User';

/*
 * Code
 */
interface Endpoint {
  url: string;
}

export const getEndpoint = async (user: User): Promise<string | undefined> => {
  const payload: VMPayload = {
    url: 'status/',
    user,
  };

  const { data } = await makeRequest(payload);

  const httpsRegex = /^https/;
  const endpointData = data.endpoints.find((endpoint: Endpoint) => httpsRegex.test(endpoint.url));

  return endpointData?.url;
};
