/*
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { UilMicrophone, UilVideo, UilVolume } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import devicesMediasRTC, { switchAudioDevice, switchVideoDevice } from 'src/utils/medias';

// Style
import * as S from '../index.style';

/*
 * Component
 */
const MediasManagement = ({ actions, cameraId, microphoneId, speakerId, setApplicationState }) => {
  /**
   * Refs
   */
  const previewRef = useRef();
  const buttonRef = useRef();

  /**
   * Update id of the current camera device
   * @param {ChangeEvent} event
   */
  const selectCameraDevice = (event) => {
    actions.updateCurrentDevice({
      name: event.target.name,
      value: event.target.value,
    });

    // Update the preview element
    switchVideoDevice(event.target.value).then(() => {
      previewRef.current.srcObject = devicesMediasRTC.localStream;
    });
  };

  /**
   * Update id of the current microphone device
   * @param {ChangeEvent} event
   */
  const selectMicrophoneDevice = (event) => {
    actions.updateCurrentDevice({
      name: event.target.name,
      value: event.target.value,
    });

    // Update the preview element
    switchAudioDevice(event.target.value).then(() => {
      previewRef.current.srcObject = devicesMediasRTC.localStream;
    });
  };

  /**
   * Update id of the current speaker device
   * @param {ChangeEvent} event
   */
  const selectSpeakerDevice = (event) => {
    actions.updateCurrentDevice({
      name: event.target.name,
      value: event.target.value,
    });
  };

  /**
   * Join classroom
   * @return {void}
   */
  const joinClassroom = () => {
    setApplicationState('JOINED');
  };

  /*
   * LifeCycles
   */
  useEffect(() => {
    buttonRef.current.focus();
  }, []);

  useEffect(() => {
    if (devicesMediasRTC.localStream) {
      previewRef.current.srcObject = devicesMediasRTC.localStream;
      previewRef.current.muted = true;
    }
  }, [devicesMediasRTC.localStream]);

  /**
   * Render
   */
  return (
    <S.Container>
      <S.Alert as="form">
        <S.IconContainer>
          <S.Setting aria-hidden="true" />
        </S.IconContainer>
        <S.Title>Accès aux périphériques</S.Title>
        <S.Description>
          Vérifiez vos périphériques avant de rejoindre la salle de classe
        </S.Description>

        {/* Preview */}
        <S.Preview>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video autoPlay playsInline aria-hidden="true" ref={previewRef}>
            Sorry, Web RTC is not available in your browser
          </video>
        </S.Preview>

        {/* Devices */}
        <S.Devices>
          {/* Cameras 📽 */}
          <label htmlFor="camera">
            <S.SpanSrOnly>Caméra</S.SpanSrOnly>
            <div className="device device-camera">
              <S.Select
                id="camera"
                name="cameraId"
                data-testid="medias-camera-select"
                value={cameraId}
                onChange={selectCameraDevice}
                disabled={!devicesMediasRTC.devices.videoinput.length}
              >
                {devicesMediasRTC.devices.videoinput.length ? (
                  devicesMediasRTC.devices.videoinput.map((camera) => (
                    <option
                      key={`key-${camera.kind}-${camera.deviceId}`}
                      id={`camera-${camera.deviceId}`}
                      value={camera.deviceId}
                    >
                      {camera.label}
                    </option>
                  ))
                ) : (
                  <option>Aucune caméra détectée</option>
                )}
              </S.Select>
              <S.Icon isDisabled={!devicesMediasRTC.devices.videoinput.length}>
                <UilVideo />
              </S.Icon>
            </div>
          </label>

          {/* Microphones 🎤 */}
          <label htmlFor="microphone">
            <S.SpanSrOnly>Microphone</S.SpanSrOnly>
            <div className="device device-microphone">
              <S.Select
                id="microphone"
                name="microphoneId"
                data-testid="medias-microphone-select"
                value={microphoneId}
                onChange={selectMicrophoneDevice}
                disabled={!devicesMediasRTC.devices.audioinput.length}
              >
                {devicesMediasRTC.devices.audioinput.length ? (
                  devicesMediasRTC.devices.audioinput.map((microphone) => (
                    <option
                      key={`key-${microphone.kind}-${microphone.deviceId}`}
                      id={`microphone-${microphone.deviceId}`}
                      value={microphone.deviceId}
                    >
                      {microphone.label}
                    </option>
                  ))
                ) : (
                  <option>Aucun microphone détecté</option>
                )}
              </S.Select>
              <S.Icon isDisabled={!devicesMediasRTC.devices.audioinput.length}>
                <UilMicrophone />
              </S.Icon>
            </div>
          </label>

          {/* Headphones 🔊 */}
          <label htmlFor="headphone">
            <S.SpanSrOnly>Haut-parleur</S.SpanSrOnly>
            <div className="device device-headphone">
              <S.Select
                id="headphone"
                name="speakerId"
                data-testid="medias-headphone-select"
                value={speakerId}
                onChange={selectSpeakerDevice}
              >
                {devicesMediasRTC.devices.audiooutput.length ? (
                  devicesMediasRTC.devices.audiooutput.map((headphone) => (
                    <option
                      key={`key-${headphone.kind}-${headphone.deviceId}`}
                      id={`headphone-${headphone.deviceId}`}
                      value={headphone.deviceId}
                    >
                      {headphone.label}
                    </option>
                  ))
                ) : (
                  <option value="">Default</option>
                )}
              </S.Select>
              <S.Icon>
                <UilVolume />
              </S.Icon>
            </div>
          </label>
        </S.Devices>

        {/* <S.RememberMedias>
          <label htmlFor="remember-medias-devices">
            <S.CheckBox type="checkbox" id="remember-medias-devices" />
            Ne plus me demander
          </label>
        </S.RememberMedias> */}

        <S.Button
          isMain
          type="button"
          data-testid="medias-join-classroom-button"
          aria-label="Valider le choix des appareils et rejoindre la salle de classe"
          onClick={joinClassroom}
          ref={buttonRef}
        >
          Rejoindre la salle de classe
        </S.Button>
      </S.Alert>
    </S.Container>
  );
};

/*
 * PropTypes
 */
MediasManagement.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  cameraId: PropTypes.string,
  microphoneId: PropTypes.string,
  speakerId: PropTypes.string,
  setApplicationState: PropTypes.func.isRequired,
};

MediasManagement.defaultProps = {
  cameraId: '',
  microphoneId: '',
  speakerId: '',
};

/*
 * Export
 */
export default MediasManagement;
