/*
 * Package Import
 */
import React from 'react';
import { Route as DefaultRoute, Switch } from 'react-router-dom';

/*
 * Local Import
 */
import { Route } from 'src/components/Auth';
import GiveAccess from 'src/components/Authorization/GiveAccess';

// Components
import Launch from 'src/components/Launch';
import ExternalChat from 'src/components/Chats/External/container';
import Settings from 'src/components/Dashboard/Settings';
import ManagementVM from 'src/components/VM/container';
import Admin from 'src/components/Admin';
import Dashboard from 'src/components/Dashboard/CoursesList';

// Components Utils
import IncompatibleBrowser from 'src/components/IncompatibleBrowser';
import NotFound from 'src/components/NotFound';

// Helpers
import { navigatorAllowed } from 'src/utils/navigator';
import {
  ADMINS_URLS,
  BASE_URL,
  CLASSROOM_CHAT_URL,
  CLASSROOM_URL,
  PAGE_NOT_FOUND_URL,
  SETTINGS_URL,
  VIRTUAL_MACHINE_URL,
} from 'src/constants/routes';
import useTracking from 'src/hooks/useTracking';

/*
 * Component
 */
const Router = () => {
  useTracking();

  if (!navigatorAllowed()) {
    return <IncompatibleBrowser />;
  }

  return (
    <Switch>
      {/* Routes require auth */}
      <Route exact path={BASE_URL} hasNavigation>
        <Dashboard />
      </Route>
      <Route exact path={CLASSROOM_URL}>
        <Launch />
      </Route>
      <Route exact path={CLASSROOM_CHAT_URL}>
        <ExternalChat />
      </Route>
      <Route exact path={SETTINGS_URL} hasNavigation>
        <Settings />
      </Route>
      <Route exact path={VIRTUAL_MACHINE_URL} hasNavigation>
        <GiveAccess when={(client) => !!client.canCreateVM}>
          <ManagementVM />
        </GiveAccess>
      </Route>
      <Route exact path={ADMINS_URLS} hasNavigation>
        <Admin />
      </Route>

      {/* Default routes, no need auth */}
      <DefaultRoute exact path={PAGE_NOT_FOUND_URL}>
        <NotFound />
      </DefaultRoute>
      <DefaultRoute>
        <NotFound />
      </DefaultRoute>
    </Switch>
  );
};

/*
 * Export
 */
export default Router;
