/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Time = styled.time(({ theme }) => ({
  color: theme.text._5,
  ...theme.typo.bodyText.veryTinyTextAdjustable,
  cursor: 'default',
  display: 'inline-block',
  marginLeft: '0.25rem',
}));
