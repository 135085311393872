/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { UserFormDataValidated, UserDTO } from 'src/schemas/Entities/User';
import { MongoId } from 'src/schemas/Entities/utils';
import { api, MutationPayload } from 'src/utils/api';
import { API_PUT, API_UPDATE_USERS } from 'src/constants/apiRoutes';
import { transformUsersAndSetNewData } from './utils';

/*
 * Code
 */
export interface UpdatePromotionUsersProps {
  promotionId: MongoId;
  updatePromotionUsersFormData: {
    selectedUsersValidated: UserFormDataValidated[];
    removedUsersValidated: UserFormDataValidated[];
  };
}
export const updatePromotionUsers = async ({
  promotionId,
  updatePromotionUsersFormData,
}: UpdatePromotionUsersProps): Promise<UserDTO[]> => {
  try {
    const transformedUsersWithNewData = transformUsersAndSetNewData(
      updatePromotionUsersFormData,
      promotionId,
    );

    const payload: MutationPayload = {
      method: API_PUT,
      url: API_UPDATE_USERS,
      data: {
        newData: transformedUsersWithNewData,
      },
    };

    const { data: usersData } = await api(payload);

    return usersData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
