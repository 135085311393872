/*
 * Package Import
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

/**
 * Local Import
 */
import PageError from 'src/components/PageError';
import App from 'src/components/App';

// Store / Helpers
import store from 'src/store';
import init from 'src/utils/tracking';
import { unlockAudio } from 'src/utils/audio';

// Providers
import { ThemeProvider } from 'src/context/ThemeProvider';
import { GlobalStateProvider } from 'src/context/GlobalStateMachine';

// Helpers
import packageJson from '../../package.json';

// Style
import '@oclock/crumble/dist/css/styles.css';

Sentry.init({
  dsn: process.env.DSN_SENTRY,
  environment: process.env.NODE_ENV,
  release: packageJson.version,
  integrations: process.env.NODE_ENV === 'production' ? [new BrowserTracing()] : [],
  debug: process.env.NODE_ENV !== 'production',
  autoSessionTracking: true,
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  ignoreErrors: [],
});

/*
 * Render
 */
document.addEventListener('DOMContentLoaded', () => {
  // Initialize the 3rd party widgets like Stonly, HubSpot, etc… BEFORE rendering
  init();

  // Initialize the Application
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <Sentry.ErrorBoundary
          fallback={PageError}
          onError={(error) => Sentry.captureException(error)}
        >
          <ThemeProvider>
            <GlobalStateProvider>
              <App />
            </GlobalStateProvider>
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      </Provider>
    </BrowserRouter>,
  );

  // Some browsers/devices, like Safari, will only allow audio to be played
  // after a user interaction. Attempt to automatically unlock audio.
  unlockAudio();
});
