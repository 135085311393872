/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@szhsin/react-menu';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';

// Style
import * as G from '../Message/style';
import * as S from './style';

/*
 * Component
 */
const SendButton = ({ sendMessage }) => (
  <S.Container>
    <Tooltip content="Envoyer le message">
      <G.Button type="button" aria-label="Envoyer le message" onClick={sendMessage}>
        <G.Send />
      </G.Button>
    </Tooltip>

    {/* Options */}
    <Menu
      menuClassName="menu-message"
      direction="top"
      menuButton={(
        <S.MenuButton type="button">
          <S.IconMenu />
        </S.MenuButton>
      )}
    >
      <MenuItem
        className={({ hover, active }) => `menu-message-item ${hover || active ? 'hovered' : ''}`}
        onClick={() => sendMessage({ options: { isSpoiler: true } })}
      >
        <S.IconEye />
        Envoyer en spoiler
      </MenuItem>
      <MenuItem
        className={({ hover, active }) => `menu-message-item ${hover || active ? 'hovered' : ''}`}
        onClick={() => sendMessage({ options: { isAsked: true } })}
      >
        <S.IconQuestion />
        Envoyer en question
      </MenuItem>
    </Menu>
  </S.Container>
);

/*
 * PropTypes
 */
SendButton.propTypes = {
  /** The function to send a message on channel */
  sendMessage: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default SendButton;
