/*
 * Package Import
 */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import ButtonLoader from 'src/components/ButtonLoader';
import Tooltip from 'src/components/Tooltip';
import { useWebsocket } from 'src/hooks';
import { RECEIVED_SIGNATURE_LINKS, SIGNATURE_START_ERROR } from 'src/store/types';

// Styles
import * as S from './style';

/*
 * Component
 */
const Signature = ({
  actions,
  chatPanelOpened,
  isEducator,
  isTeacher,
  canSign,
  studentNeedSign,
  studentHaveSign,
}) => {
  const socket = useWebsocket();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTime, setResendTime] = useState(60);
  const [startSignatureLoading, setStartSignatureLoading] = useState(false);

  const handleResend = () => {
    actions.resendSignNotifs();
    setResendDisabled(true);

    const startTime = new Date().getTime();
    const timeInterval = setInterval(() => {
      const time = 60000 - (new Date().getTime() - startTime);
      setResendTime(Math.ceil(time / 1000));
    }, 1000);

    setTimeout(() => {
      clearInterval(timeInterval);
      setResendDisabled(false);
    }, 60000);
  };

  /*
   * Handlers
   */
  const handleCloseMenu = () => {
    actions.setMenuSignature({ isOpen: false });
  };

  const handleStartSignature = useCallback(() => {
    setStartSignatureLoading(true);
    actions.startSignature();
  }, []);

  const resetStartSignature = useCallback(() => {
    setStartSignatureLoading(false);
  }, []);

  /**
   * Get the position (top, right) of `notifications` panel
   * @return {Object}
   */
  const getPanelPosition = () => {
    const defaultMargin = 16;
    const elementRight = document.getElementById('workspace-drawer-right');
    const elementNavigation = document.getElementById('workspace-navigation');

    return {
      top: elementNavigation.offsetHeight + defaultMargin,
      right: chatPanelOpened ? elementRight.offsetWidth + defaultMargin : defaultMargin,
    };
  };

  const getMainText = () => {
    if (isEducator) {
      if (isTeacher) {
        return "Envoyez et gérez la feuille d'émargement du cours pour que vos apprenants puissent justifier leur présence à votre organisme de formation.";
      }
      return "Gérez la feuille d'émargement du cours pour que vos apprenants puissent justifier leur présence à votre organisme de formation.";
    }

    return "Vous êtes invité à signer la feuille d'émargement. Émarger vous permet de prouver votre présence, notamment à votre organisme financeur.";
  };

  useEffect(() => {
    socket?.on(RECEIVED_SIGNATURE_LINKS, resetStartSignature);
    socket?.on(SIGNATURE_START_ERROR, resetStartSignature);

    return () => {
      socket?.off(RECEIVED_SIGNATURE_LINKS, resetStartSignature);
      socket?.off(SIGNATURE_START_ERROR, resetStartSignature);
    };
  }, [socket]);

  useEffect(() => {
    actions.checkSignatureTimeslot();
  }, []);

  /*
   * Render
   */
  return (
    <S.SignatureModal isOpen onRequestClose={handleCloseMenu} {...getPanelPosition()}>
      <S.SignatureContent>
        <S.Header>
          <S.Title>Émargement</S.Title>
          {isEducator && canSign ? (
            <>
              <S.FollowingSignature>
                {studentHaveSign.length}/{studentNeedSign.length}
              </S.FollowingSignature>
              <S.FollowingSignatureBar
                currentSignatures={studentHaveSign.length}
                totalStudents={studentNeedSign.length}
              />
            </>
          ) : (
            <S.Text>{getMainText()}</S.Text>
          )}
        </S.Header>
        <S.Actions>
          {isEducator ? (
            <>
              {isTeacher && (
                <Button
                  onClick={handleStartSignature}
                  disabled={canSign || startSignatureLoading}
                  fullWidth
                  id="start-signature"
                >
                  Lancer l&apos;émargement
                  <ButtonLoader isLoading={startSignatureLoading} />
                </Button>
              )}
              <Tooltip
                content={`Vous ne pouvez pas relancer avant ${resendTime} ${
                  resendTime > 1 ? 'secondes' : 'seconde'
                }`}
                isEnabled={resendDisabled}
              >
                <Button
                  onClick={handleResend}
                  style={{ padding: '8px 33px' }}
                  disabled={!canSign || resendDisabled}
                >
                  Relancer les apprenants
                </Button>
              </Tooltip>
              <Button
                onClick={() => actions.setFollowingModal({ isOpen: true })}
                variant="secondary"
                style={{ padding: '8px 20px' }}
                disabled={!canSign}
              >
                Suivre
              </Button>
              {isTeacher && (
                <Button
                  variant="danger"
                  disabled={!canSign}
                  fullWidth
                  onClick={() => actions.confirmResetSignatures({ resetIsOpen: true })}
                >
                  Réinitialiser l&apos;émargement
                </Button>
              )}
            </>
          ) : (
            <Button
              disabled={startSignatureLoading}
              fullWidth
              id="start-signature"
              onClick={handleStartSignature}
            >
              Émarger
              <ButtonLoader isLoading={startSignatureLoading} />
            </Button>
          )}
        </S.Actions>
      </S.SignatureContent>
    </S.SignatureModal>
  );
};

/*
 * Proptypes
 */
Signature.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** The right panel (chat) is opened ? */
  chatPanelOpened: PropTypes.bool.isRequired,
  isEducator: PropTypes.bool.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  canSign: PropTypes.bool.isRequired,
  studentNeedSign: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      hasSigned: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  studentHaveSign: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      hasSigned: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

Signature.defaultProps = {};

/*
 * Export
 */
export default Signature;
