/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { UilCalender } from '@iconscout/react-unicons';
import { format, parse } from 'date-fns';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import {
  FilterModalButton,
  FilterModal,
  Close,
} from 'src/components/Admin/TableElements/Filters/Modal';

/*
 * Styles
 */
const ButtonDateFilterIcon = styled(UilCalender)(() => ({
  width: '1.25rem',
}));

const FilterDateContainer = styled.div(({ theme }) => ({
  padding: '.25rem',
  ...theme.typo.labels.label,
}));

const FilterDateFieldsContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',

  div: {
    marginBottom: '.5rem',
  },
}));

const ResetContainer = styled.div(() => ({
  display: 'grid',
  justifyContent: 'right',
  marginBottom: '.25rem',
}));

const DateInputContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const DateInputLabel = styled.div(() => ({
  marginBottom: '.25rem',
}));

const ResetButton = styled.button(({ theme }) => ({
  color: theme.primary._1,
  ...theme.typo.labels.smallLabel,
}));

const DateInput = styled.input(({ theme, value }) => ({
  border: `1px solid ${theme.text._7}`,
  width: '100%',
  borderRadius: '8px',
  minHeight: '2.5rem',
  padding: '.5rem .75rem',
  fontFamily: 'Inter',
  background: theme.background._2,
  color: value ? theme.text._1 : theme.text._4,
  colorScheme: theme.themeName,

  ...theme.typo.bodyText.body,
  ':hover': {
    border: `2px solid ${theme.text._6}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    background: theme.background._8,
  },
  ':focus': {
    border: `2px solid ${theme.primary._1}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
}));

/*
 * Component
 */
export const FilterDate = ({ filterValue, setFilter, header }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChange = (evt) => {
    const element = evt.currentTarget;
    if (element.value) {
      const formattedValue = parse(element.value, 'yyyy-MM-dd', new Date());
      setFilter({
        ...filterValue,
        [element.name]: formattedValue,
      });
    }
    else {
      setFilter({
        ...filterValue,
        [element.name]: '',
      });
    }
  };

  const handleReset = () => {
    const filterCopy = Object.keys(filterValue).reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = '';
      }
      return acc;
    }, {});
    setFilter({
      ...filterCopy,
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.getElementById('filter-date-start').focus();
    }
  }, [isOpen]);

  const isFilterValue = Object.keys(filterValue).find((key) => filterValue[key] !== '');

  return (
    <Tooltip content={`Filtres sur la colonne ${header}`}>
      <FilterModalButton
        onClick={isOpen ? handleClose : handleOpen}
        isOpen={isOpen}
        isFilterValue={isFilterValue}
      >
        <ButtonDateFilterIcon />
      </FilterModalButton>
      {isOpen && (
        <FilterModal isOpen={isOpen}>
          <FilterDateContainer>
            <ResetContainer>
              <ResetButton onClick={handleReset}>Réinitialiser</ResetButton>
            </ResetContainer>
            <FilterDateFieldsContainer>
              <div>
                <DateInputLabel>Début</DateInputLabel>
                <DateInputContainer>
                  <DateInput
                    type="date"
                    name="start"
                    id="filter-date-start"
                    onChange={handleChange}
                    value={filterValue.start && format(filterValue.start, 'yyyy-MM-dd')}
                  />
                </DateInputContainer>
              </div>
              <div>
                <DateInputLabel>Fin</DateInputLabel>
                <DateInput
                  type="date"
                  name="end"
                  id="filter-date-end"
                  onChange={handleChange}
                  value={filterValue.end && format(filterValue.end, 'yyyy-MM-dd')}
                />
              </div>
            </FilterDateFieldsContainer>
          </FilterDateContainer>
        </FilterModal>
      )}
      {isOpen && <Close onClick={handleClose} />}
    </Tooltip>
  );
};

FilterDate.propTypes = {
  filterValue: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};
