/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UilTimes } from '@iconscout/react-unicons';

/*
 * Local Import
 */

// Constants
import { titles } from 'src/constants/notifications';
import Tooltip from 'src/components/Tooltip/container';

// utils
import { setIconName } from 'src/utils/icons';

// Styles
import * as S from './style';
import * as P from '../style';

/*
 * Component
 */
const Notification = ({
  actions,
  id,
  title,
  description,
  userId,
  userIdMessage,
  name,
  newClass,
}) => {
  /*
   * State
   */
  const [cls, setCls] = useState('');
  const Icon = S[setIconName(title)];

  /*
   * Varibles
   */
  let beginDescription;
  if (title === titles.NEW_PIN) {
    beginDescription = userIdMessage === userId ? 'Votre ' : 'Un ';
  }

  if (title === titles.REQUEST_TALK) {
    beginDescription = name;
  }

  /*
   * Handlers
   */
  const handleDeleteNotification = () => {
    setCls('removed');
    setTimeout(() => {
      actions.deleteNotifications(id);
    }, 500);
  };

  const handleOpenNotification = () => {
    actions.dispatchActionOnClick({ title, name, id });
  };

  /*
   * Render
   */
  return (
    <S.Notification className={`${cls} ${newClass}`}>
      <S.IconContainer>
        <Icon />
      </S.IconContainer>
      <S.ContainerContent type="button" onClick={handleOpenNotification}>
        <S.TitleNotification>{title}</S.TitleNotification>
        <S.Description>
          {beginDescription}
          {description}
        </S.Description>
      </S.ContainerContent>
      <P.Actions>
        <Tooltip content="Supprimer">
          <P.Action aria-label="Supprimer" onClick={handleDeleteNotification}>
            <UilTimes />
          </P.Action>
        </Tooltip>
      </P.Actions>
    </S.Notification>
  );
};

/*
 * Proptypes
 */
Notification.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  // Add class for animation remove notif
  newClass: PropTypes.string.isRequired,
  /** Notification's id */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Title notification */
  title: PropTypes.string.isRequired,
  /** Content of notification */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** User id of message concern in notification for custom notification */
  userIdMessage: PropTypes.string,
  /** User name of message concern in notification for custom notification */
  name: PropTypes.string,
  /** Current user id for custom notification */
  userId: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  userIdMessage: null,
  name: null,
};

/*
 * Export
 */
export default Notification;
