/*
 * Package Import
 */
import { Row } from '@tanstack/react-table';

/*
 * Local Import
 */
import { User, UserFormDataValidated } from 'src/schemas/Entities/User';
import { transformUserToUserFormData } from 'src/schemas/Adapters/Users/transformUserToUserFormData';
import { store } from 'src/localStateManagers/store';
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { findClientRoleAndCheckIfUserUpdatedIsRoleLower } from 'src/schemas/Refines/Validations/users';

/*
 * Types
 */
export type PromotionUsersFormData = {
  selectedUsers: User[];
  removedUsers: User[];
};

export type PromotionUsersFormDataValidated = {
  selectedUsersValidated: UserFormDataValidated[];
  removedUsersValidated: UserFormDataValidated[];
};

export type FormError = {
  type: string;
  message: string;
};

type InitialSelectedState = {
  [index: string]: boolean;
};

export type PartialRow = Partial<Row<User>>;

export enum AllUsersFilter {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
}

/*
 * Code
 */
export const getSelectedUsers = (
  selectedRows: (Row<User> | PartialRow)[],
  promotionId: string | null,
): User[] => {
  const selectedRowsValues = selectedRows
    .filter(
      (row) =>
        row.original && !row.original.promotions.find((promotion) => promotion?.id === promotionId),
    )
    .reduce((allRows, row) => {
      if (row.original) {
        allRows.push(row.original);
      }
      return allRows;
    }, [] as User[]);
  return selectedRowsValues;
};

export const getRemovedUsers = (
  usersData: User[] | undefined,
  selectedRows: (Row<User> | PartialRow)[],
  promotionId: string | null,
): User[] => {
  if (!usersData?.length) {
    return [];
  }

  // Get current users with current promotion
  const promotionUsers = usersData?.filter((user) =>
    user.promotions.find((promotion) => promotion && promotion.id === promotionId),
  );
  // Get those who are not in selection
  const promotionUsersNotSelected = promotionUsers?.filter(
    (promotionUser) =>
      !selectedRows.find((selectedRow) => selectedRow.original?.id === promotionUser.id),
  );
  return promotionUsersNotSelected ?? [];
};

// For each users, parse it to validate it according to schemas
// And return same global object but validated
export const validatePromotionUsersFormData = (
  promotionUsersFormData: PromotionUsersFormData,
): PromotionUsersFormDataValidated => {
  // For each users group (selected and removed)
  const promotionUsersFormDataValidatedResult = (
    Object.keys(promotionUsersFormData) as Array<keyof PromotionUsersFormData>
  ).reduce((validatedUsers, key) => {
    // Construct a new key for output object
    // to indicate that is validated
    const newKey = `${key}Validated` as keyof PromotionUsersFormDataValidated;
    if (!validatedUsers[newKey]) {
      // transform user to user form data and parse it
      // to detect errors
      const parsedUsers = promotionUsersFormData[key].map((user) =>
        transformUserToUserFormData(user),
      );
      // then fill the new key with validated users
      validatedUsers[newKey] = parsedUsers;
    }
    return validatedUsers;
  }, {} as PromotionUsersFormDataValidated);
  return promotionUsersFormDataValidatedResult;
};

export const isDisabledRow = () =>
  (rowOriginal?: User): boolean => {
    const { getState } = store;
    const usersData: User[] | undefined = queryClient.getQueryData('users');
    const client = getState().client;

    return (
      !rowOriginal
      || !findClientRoleAndCheckIfUserUpdatedIsRoleLower(rowOriginal.id, client, usersData)
    );
  };

export const getInitialSelectedUsers = (data: User[], idToAddUsers: string | null) => {
  const initalSelectedUsers = data.reduce((initialState, user, index) => {
    const isInPromotion = user.promotions.some((promotion) => promotion?.id === idToAddUsers);

    if (isInPromotion && !initialState[index]) {
      initialState[index] = true;
    }

    return initialState;
  }, {} as InitialSelectedState);
  return initalSelectedUsers;
};
