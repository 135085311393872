/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Counter = ({ assertiveNeeded, isActive, nbusers }) => (
  <S.CounterContainer isActive={isActive}>
    <S.Number isActive={isActive} aria-live={assertiveNeeded ? 'assertive' : 'off'}>
      {nbusers}
    </S.Number>
    <S.StyledUilUsersAlt isActive={isActive} aria-hidden />
  </S.CounterContainer>
);

/*
 * PropTypes
 */
Counter.propTypes = {
  isActive: PropTypes.bool.isRequired,
  assertiveNeeded: PropTypes.bool,
  nbusers: PropTypes.number.isRequired,
};

Counter.defaultProps = {
  assertiveNeeded: false,
};

/*
 * Export
 */
export default Counter;
