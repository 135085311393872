import styled from '@emotion/styled';

const tableBorderRadius = '10px';

const TableRoot = styled.table(({ theme }) => ({
  tableLayout: 'auto',
  backgroundColor: theme.background._2,
  width: '100%',
  minWidth: '1550px',
  border: `1px solid ${theme.text._7}`,
  borderCollapse: 'separate',
  borderRadius: tableBorderRadius,

  '& > thead': {
    borderTopLeftRadius: tableBorderRadius,
    borderTopRightRadius: tableBorderRadius,
    '& > tr:first-of-type': {
      borderTopLeftRadius: tableBorderRadius,
      borderTopRightRadius: tableBorderRadius,

      '& > td': {
        borderTopLeftRadius: tableBorderRadius,
        borderTopRightRadius: tableBorderRadius,
      },
    },
  },

  '& > tfoot': {
    borderBottomLeftRadius: tableBorderRadius,
    borderBottomRightRadius: tableBorderRadius,
    '& > tr:last-child': {
      borderBottomLeftRadius: tableBorderRadius,
      borderBottomRightRadius: tableBorderRadius,

      '& > td': {
        borderBottomLeftRadius: tableBorderRadius,
        borderBottomRightRadius: tableBorderRadius,
      },
    },
  },
}));
export default TableRoot;
