/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@oclock/crumble';
import format from 'date-fns/format';

/*
 * Local Import
 */
import {
  promotionFormDataSchema,
  PromotionFormData,
  PromotionFormDataValidated,
  promotionFormDataValidatedSchema,
  Promotion,
} from 'src/schemas/Entities/Promotion';
import { useCreatePromotion } from 'src/serverStateManagers/mutations/create/Promotions/useCreatePromotion';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';

import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';

import { getRoute } from 'src/utils/routes';
import { MongoId } from 'src/schemas/Entities/utils';
import { promotionsAddFormData } from './config';

/*
 * Code
 */
type AddPromotionProps = {
  isOpen: boolean;
  parentId: MongoId | null;
};
const AddPromotion = ({ isOpen, parentId }: AddPromotionProps): JSX.Element | null => {
  const { data: promotionsData } = useGetPromotions();
  const createPromotionMutation = useCreatePromotion();
  const history = useHistory();

  const [parentPromotionData, setParentPromotionData] = useState(
    promotionsData?.find((promotion: Promotion) => promotion.id === parentId),
  );
  useEffect(() => {
    setParentPromotionData(
      promotionsData?.find((promotion: Promotion) => promotion.id === parentId),
    );
  }, [promotionsData, parentId]);

  // Config
  const { header, inputs } = promotionsAddFormData(!!parentId);
  const { name, start, end } = inputs;

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    reset,
  } = useForm({
    resolver: zodResolver(promotionFormDataSchema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  const onSubmit = (data: PromotionFormData): void => {
    // eslint-disable-next-line max-len
    const promotionFormDataValidatedResult: PromotionFormDataValidated = promotionFormDataValidatedSchema.parse(data);
    clearErrors();
    createPromotionMutation.mutate(promotionFormDataValidatedResult);
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    reset();
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>
        {parentId && (
          <F.Field>
            <F.Input
              ref={register}
              name={inputs.parentId.title}
              type={inputs.parentId.type}
              defaultValue={parentId}
            />
          </F.Field>
        )}
        <F.Field>
          <F.Label htmlFor={name.title}>{name.display}</F.Label>
          <F.Input
            className={errors.name ? 'is-error' : undefined}
            ref={register}
            name={name.title}
            type={name.type}
          />
          {errors.name?.message && <F.ErrorMessage>{errors.name.message}</F.ErrorMessage>}
        </F.Field>
        <F.Field>
          <F.Label htmlFor={start.title}>{start.display}</F.Label>
          <F.Input
            className={errors.start ? 'is-error' : undefined}
            ref={register}
            name={start.title}
            type={start.type}
            defaultValue={
              parentPromotionData ? format(parentPromotionData?.start, 'yyyy-MM-dd') : ''
            }
          />
          {errors.start?.message && <F.ErrorMessage>{errors.start.message}</F.ErrorMessage>}
        </F.Field>
        <F.Field>
          <F.Label htmlFor={end.title}>{end.display}</F.Label>
          <F.Input
            className={errors.end ? 'is-error' : undefined}
            ref={register}
            name={end.title}
            type={end.type}
            defaultValue={parentPromotionData ? format(parentPromotionData?.end, 'yyyy-MM-dd') : ''}
          />
          {errors.end?.message && <F.ErrorMessage>{errors.end.message}</F.ErrorMessage>}
        </F.Field>

        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default AddPromotion;
