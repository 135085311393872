export const getPluralizeFunc = (number: number) =>
  (endingPlural: string, endingSingular = '') =>
    (number > 1 ? endingPlural : endingSingular);

export const concatStringListNicely = ({
  stringArray,
  middleSeparator = ', ',
  lastSeparator = ' et ',
}: {
  stringArray: string[];
  middleSeparator?: string;
  lastSeparator?: string;
}) => {
  if (stringArray.length === 1) return stringArray[0];
  const lastString = stringArray.pop();
  const firstStringsJoined = stringArray.join(middleSeparator);
  const stringsJoined = [firstStringsJoined, lastString].join(lastSeparator);
  return stringsJoined;
};
