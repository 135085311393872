/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface BreaktimeState {
  timeValue: number;
  isOpen: boolean;
  pauseScreenIsOpen: boolean;
}

/*
 * Initial State
 */
const initialState: BreaktimeState = {
  timeValue: 0,
  isOpen: false,
  pauseScreenIsOpen: false,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.CHANGE_AWAY_TIME:
        draft.timeValue = action.value;
        break;

      case types.SET_MODAL_AWAY:
        draft.isOpen = action.isOpen;
        break;

      default:
        return state;
    }
  });
