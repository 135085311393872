/*
 * Package Import
 */
import axios from 'axios';
import { v4 as uuid } from 'uuid';

/*
 * Local Import
 */
import { createSave } from 'src/store/middlewares/localStorage';

// Constants
import CHATS from 'src/constants/chats';
import MESSAGES from 'src/constants/messages';
import { titles } from 'src/constants/notifications';

import SlipperApi from 'src/context/Keycloak/SlippersApi';

// Types
import * as types from './types';

/*
 * Code
 */
const saveSettings = (key) => createSave('settings', key);
const saveCourse = (key) => createSave('course', key);

/**
 * Initialize the connection to the WebSocket server
 */
export const wsConnect = () => (dispatch, getState) => {
  const state = getState();
  const client = state.users.client.id;

  dispatch({
    type: types.WEBSOCKET_CONNECT,
    client,
  });
};

/**
 * Initialize user data. We got all the users of the classroom,
 * and the different chats and messages from the logged user.
 */
export const setInitializeData = (data) => ({
  type: types.INITIALIZE_DATA,
  data,
});

/**
 * Breaktime
 */
export const changeAwayTime = (value) => ({
  type: types.CHANGE_AWAY_TIME,
  value,
});

export const setModalAway = ({ isOpen }) => ({
  type: types.SET_MODAL_AWAY,
  isOpen,
});

/*
 * Channels
 */
export const changeChannelId = (chatId, methodLocation = '') =>
  () => ({
    type: types.CHANGE_CHAT,
    ws: true,
    chatId,
    methodLocation,
  });

export const changeChannelView = (view) => ({
  type: types.CHANGE_ACTIVE_VIEW,
  ws: true,
  view,
});

export const setChannelUnread = ({ chatId, unread }) => ({
  type: types.SET_CHAT_UNREAD,
  ws: true,
  chatId,
  unread,
});

export const openChat = (receiverId, methodLocation = '') =>
  (dispatch, getState) => {
    const store = getState();
    const { promoId } = store.course;
    const { active, byId, popout } = store.chats;

    // Does the chat already exists ?
    const channelAlreadyExist = Object.keys(byId).find((chat) => {
      if (receiverId === store.users.client.id) {
        return (
          byId[chat].kind !== CHATS.CHAT_GENERAL
          && byId[chat].usersIds
          && byId[chat].usersIds[0] === receiverId
          && byId[chat].usersIds[1] === receiverId
        );
      }

      return (
        byId[chat].kind !== CHATS.CHAT_GENERAL
        && byId[chat].usersIds
        && byId[chat].usersIds.includes(receiverId)
      );
    });

    // if the chat doesn't exists
    if (channelAlreadyExist === undefined) {
      // dispatch to the back CREATE_NEW_CHAT
      dispatch({
        type: types.CREATE_NEW_CHAT,
        ws: true,
        receiverId,
        promoId,
        kind: 'private',
        methodLocation,
      });
      return;
    }

    // If chat already exists open chat and get messages
    if (channelAlreadyExist !== active || popout) {
      // Optimistic rendering, OPEN_CHAT
      dispatch({
        type: types.OPEN_CHAT,
        ws: true,
        chatId: channelAlreadyExist,
        methodLocation,
      });
    }
  };

export const closeChannel = (chatId) => ({
  type: types.CLOSE_CHAT,
  ws: true,
  chatId,
});

export const changeMainChatView = (view) => ({
  type: types.CHANGE_MAIN_CHAT_VIEW,
  ws: true,
  view,
});

export const setPopout = (isOpen) => ({
  type: types.SET_POPOUT,
  isOpen,
});

/*
 * Feedback
 */
export const setModalFeedback = ({ isOpen }) => ({
  type: types.SET_MODAL_FEEDBACK,
  isOpen,
});

export const setInputFeedback = (type, value = '') => ({
  type,
  value,
});

export const setErrorFeedback = (value) => ({
  type: types.SET_ERROR_FEEDBACK,
  value,
});

export const sendFeedback = (payload) => ({
  type: types.FEEDBACK_SEND,
  ws: true,
  payload,
});

export const isTyping = (chatId) =>
  (remove = false) => ({
    type: types.CHAT_I_AM_TYPING,
    ws: true,
    chatId,
    remove,
  });

/**
 * Inputs
 */
export const inputChange = (chatId) => (value) => ({
  type: types.MESSAGE_INPUT_CHANGE,
  chatId,
  value,
});

export const resetInput = (chatId) => ({
  type: types.RESET_INPUT,
  chatId,
});

/*
 * Messages
 */
export const sendMessage = ({
  content,
  context,
  hasError,
  messageId,
  isAsked = false,
  isPinned = false,
  isSpoiler = false,
  isCommand = false,
  messageUuid = undefined,
}) =>
  (dispatch, getState) => {
    // State
    const state = getState();
    // checking if the action is trying to resend a message
    const isSentAfterError = hasError !== undefined;

    // Message content
    const time = Date.now();
    const userId = state.users.client.id;
    const { name } = state.users.userById[userId];
    const chatId = state.chats.active;

    // Message data
    const message = {
      time,
      userId,
      content,
      chatId,
      name,
      isSpoiler,
      isAsked,
      isCommand,
      pinned: isPinned,
    };

    // Optimistic Rendering, only for messages
    let clientMessageId = messageId;

    if (!isSentAfterError) {
      clientMessageId = messageUuid ?? uuid();
    }

    switch (context) {
      case CHATS.CHAT_GENERAL: {
        // Send to the server
        dispatch({
          type: types.SEND_MESSAGE,
          ws: true,
          payload: {
            type: context,
            kind: MESSAGES.TYPE_TEXT,
            message,
            clientMessageId,
          },
        });

        break;
      }

      case CHATS.CHAT_PRIVATE: {
        const chat = state.chats.byId[chatId];
        let receiver = chat.usersIds.find((id) => id !== state.users.client.id);

        if (!receiver) {
          receiver = state.users.client.id;
        }

        // Send to the server
        dispatch({
          type: types.SEND_MESSAGE,
          ws: true,
          payload: {
            message,
            receiver,
            clientMessageId,
          },
        });

        break;
      }

      default:
    }

    resetInput(chatId);
  };

export const addReaction = ({ messageId, reaction, userId }) => ({
  type: types.ADD_REACTION,
  ws: true,
  messageId,
  reaction,
  userId,
});

export const removeReaction = ({ messageId, reaction, userId }) => ({
  type: types.REMOVE_REACTION,
  ws: true,
  messageId,
  reaction,
  userId,
});

export const pinMessage = ({ messageId, messageUserId, userId }) =>
  () => ({
    type: types.ADD_PIN,
    ws: true,
    messageId,
    messageUserId,
    userId,
  });

export const unpinMessage = ({ messageId, messageUserId, userId }) =>
  () => ({
    type: types.REMOVE_PIN,
    ws: true,
    messageId,
    messageUserId,
    userId,
  });

export const askMessage = ({ messageId, messageUserId, userId }) =>
  () => ({
    type: types.FLAG_MESSAGE_AS_QUESTION,
    messageId,
    messageUserId,
    userId,
    ws: true,
  });

export const setQuestionMessageAnsweredStatus = ({ messageId, messageUserId }) =>
  ({ answered }) => ({
    type: types.SET_QUESTION_MESSAGE_ANSWERED_STATUS,
    messageId,
    messageUserId,
    answered,
    ws: true,
  });

export const editMessage = ({ messageId, messageUserId, newMessage }) => ({
  type: types.EDIT_MESSAGE,
  ws: true,
  messageId,
  messageUserId,
  newMessage,
});

export const deleteMessage = ({ chatId, messageId, messageUserId }) => ({
  type: types.DELETE_MESSAGE,
  ws: true,
  chatId,
  messageId,
  messageUserId,
});

export const setVisibilityFilter = ({ chatId, filter }) => ({
  type: types.SET_VISIBILITY_FILTER,
  filter,
  chatId,
});

export const confirmCopy = (username) => ({
  type: types.CONFIRM_COPY,
  username,
});

/*
 * Surveys
 */
export const openModalSurvey = () => ({
  type: types.OPEN_MODAL,
  modalType: types.OPEN_CREATE_SURVEY,
  modalProps: { display: true },
});

export const askSurvey = ({ chatId }) =>
  ({ question, answers, multiple, shareResult, shortcut = false }) => ({
    type: types.ASK_SURVEY,
    ws: true,
    payload: {
      kind: MESSAGES.TYPE_SURVEY,
      message: {
        chatId,
        content: {
          question,
          answers,
          multiple,
          shareResult,
          shortcut,
        },
      },
    },
  });

export const voteSurvey = ({ surveyId, answersIds, time, userId }) => ({
  type: types.VOTE_SURVEY,
  ws: true,
  surveyId,
  time,
  answersIds,
  userId,
});

export const errorCreatingSurvey = (message) => ({
  type: types.ERROR_SEND_SURVEY,
  error: {
    name: message,
    status: 400,
  },
});

/*
 * Modals
 */
export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});

/*
 * Notifications
 */
export const setMenuNotifications = ({ isOpen }) => ({
  type: types.SET_MENU_NOTIFICATIONS,
  isOpen,
});

export const deleteNotifications = (id = null) => ({
  type: types.DELETE_NOTIFICATIONS,
  id,
});

export const setCurrentNotifications = (id) => ({
  type: types.SET_CURRENT_NOTIFICATION,
  id,
});

export const setModalNotificationReader = ({ readerIsOpen }) => ({
  type: types.SET_MODAL_NOTIFICATION_READER,
  readerIsOpen,
});

export const addNotification = ({ title, description, severity = 'info' }) => ({
  type: types.ADD_NOTIFICATION,
  title,
  description,
  severity,
});

/*
 * Settings
 */

// Layout
export const changeTextSize = saveSettings('layout')((value) => ({
  type: types.TEXTSIZE_CHANGE,
  value,
}));

export const resetLayout = saveSettings('layout')(() => ({
  type: types.RESET_LAYOUT,
}));

export const toggleLayout = saveSettings('layout')((name) => ({
  type: types.TOGGLE_LAYOUT,
  name,
}));

export const toggleChat = saveSettings('layout')(() => ({
  type: types.TOGGLE_CHAT_DRAWER,
}));

export const toggleUsers = saveSettings('layout')(() => ({
  type: types.TOGGLE_USERS_DRAWER,
}));

export const setPopoutChat = (isOpen) => ({
  type: types.SET_POPOUT_CHAT,
  isOpen,
});

export const setPopoutCam = (userId, isOpen) => ({
  type: types.SET_POPOUT_CAM,
  userId,
  isOpen,
});

export const setPopoutScreen = (isOpen) => ({
  type: types.SET_POPOUT_SCREEN,
  isOpen,
});

export const setChatAppearance = saveSettings('layout')((value) => ({
  type: types.SET_CHAT_APPEARANCE,
  value,
}));

export const setChatSize = saveSettings('layout')((value) => ({
  type: types.SET_CHAT_SIZE,
  value,
}));

export const setTranscritionEnabled = saveSettings('layout')((value) => ({
  type: types.SET_TRANSCRIPTION_ENABLED,
  value,
}));

export const setUserListStyle = saveSettings('layout')((value) => ({
  type: types.SET_USERLIST_STYLE,
  value,
}));

// Notifications
export const toggleNotification = saveSettings('notifications')((name) => ({
  type: types.TOGGLE_NOTIFICATION,
  name,
}));

export const changeSound = saveSettings('notifications')((sound) => ({
  type: types.CHANGE_NOTIFICATIONS_SOUND,
  sound,
}));

export const dispatchActionOnClick = ({ title, id }) =>
  (dispatch, getState) => {
    const { notifications, settings } = getState();
    switch (title) {
      case titles.PRIVATE_MESSAGE: {
        const notification = notifications.allNotifications.find((notif) => notif.id === id);

        // Activer le panel chat si pas activer
        if (!settings.layout.displayChat) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleChat());
        }
        // Ouvrir le panel chat si pas ouvert
        if (!settings.layout.chatOpened) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleLayout('chatOpened'));
        }

        // Ouvrir la view chat
        dispatch(changeMainChatView('chat'));

        // Change chat
        dispatch({
          type: types.CHANGE_CHAT,
          chatId: notification.chatId,
          ws: true,
          methodLocation: 'Notification',
        });
        dispatch(setMenuNotifications({ isOpen: false }));
        break;
      }

      case titles.REQUEST_TALK:
        if (!settings.layout.displayUsers) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleUsers());
        }
        if (!settings.layout.usersOpened) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleLayout('usersOpened'));
        }
        dispatch(setModalNotificationReader({ readerIsOpen: false }));
        break;

      case titles.REPORT_SOUND:
        dispatch(setCurrentNotifications(id));
        dispatch(setModalNotificationReader({ readerIsOpen: true }));
        break;

      case titles.REPORT_VIDEO:
        dispatch(setCurrentNotifications(id));
        dispatch(setModalNotificationReader({ readerIsOpen: true }));
        break;

      case titles.NEW_DOCUMENT:
        // openDocuments();
        break;

      case titles.NEW_MENTION:
        // openMention();
        break;

      case titles.NEW_QUESTION:
        // openQuestion();
        break;

      case titles.NEW_PIN:
        break;

      case titles.NEW_SURVEY: {
        // Activer le panel chat si pas activer
        if (!settings.layout.displayChat) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleChat());
        }

        // Ouvrir le panel chat si pas ouvert
        if (!settings.layout.chatOpened) {
          // eslint-disable-next-line no-use-before-define
          dispatch(toggleLayout('chatOpened'));
        }
        // Ouvrir l'onglet sondage
        dispatch(changeMainChatView(CHATS.TAB_SURVEY));

        // Close Notifications menu
        dispatch(setMenuNotifications({ isOpen: false }));
        break;
      }

      case titles.EDUSIGN_SIGNATURE_NEEDED:
        // Open Edusign signature
        break;

      default:
        break;
    }
  };

export const closeSnackNotif = () => ({
  type: types.CLOSE_SNACK_NOTIFICATIONS,
});

/*
 * Shortcuts
 */

/**
 * @param {bool} isOpen - Display modal : true / false,
 */
export const setModalShortcuts = ({ isOpen, method = 'Menu' }) => ({
  type: types.SET_MODAL_SHORTCUTS,
  isOpen,
  method,
});

/**
 * Tracking (Activities only)
 */
export const changeTrackingStatus = (isEvent) => ({
  type: types.CHANGE_TRACKING_STATUS,
  isEvent,
});

/*
 * Users
 */
export const logUserIn = (user) => ({
  type: types.LOG_USER_IN,
  user,
});

export const sendMugshot = ({ mugshot, user }) => ({
  type: types.SEND_MUGSHOT,
  ws: true,
  mugshot,
  user,
});

export const changeEmotion = ({ emotion, user }) => ({
  type: types.CHANGE_EMOTION,
  ws: true,
  emotion,
  user,
});

export const changeAway = ({ away, user }) => ({
  type: types.CHANGE_AWAY,
  ws: true,
  away,
  user,
});

/*
 * Course
 */
export const enableMicrophone = () => ({
  type: types.ENABLE_MICROPHONE,
});

export const disableMicrophone = () => ({
  type: types.DISABLE_MICROPHONE,
});

export const setMicrophoneStatus = (isEnabled) => ({
  type: types.SET_MICROPHONE_STATUS,
  isEnabled,
});

export const enableWebcam = () => ({
  type: types.ENABLE_WEBCAM,
});

export const disableWebcam = (isMaxTracksReached = false) => ({
  type: types.DISABLE_WEBCAM,
  isMaxTracksReached,
});

export const enableSound = () => ({
  type: types.ENABLE_SOUND,
});

export const disableSound = () => ({
  type: types.DISABLE_SOUND,
});

export const enableScreen = () => ({
  type: types.ENABLE_SCREEN,
});

export const disableScreen = () => ({
  type: types.DISABLE_SCREEN,
});

export const setScreenStatus = (isEnabled) => ({
  type: types.SET_SCREEN_STATUS,
  isEnabled,
});

export const updateCurrentDevice = saveCourse('medias')(({ name, value }) => ({
  type: types.UPDATE_CURRENT_DEVICE,
  name,
  value,
}));

export const sendRaisedHand = (user) => () => ({
  type: types.SEND_RAISE_HAND,
  user,
  ws: true,
});

export const sendUnraisedHand = (userId) => ({
  type: types.SEND_UNRAISE_HAND,
  userId,
  ws: true,
});

export const refuseGiveVoice = (userId) => ({
  type: types.SEND_REFUSE_GIVE_VOICE,
  userId,
  ws: true,
});

export const sendGiveVoice = (userId) => ({
  type: types.SEND_GIVE_VOICE,
  userId,
  ws: true,
});

export const sendTakeVoice = (userId) => ({
  type: types.SEND_TAKE_VOICE,
  userId,
  ws: true,
});

export const setUserWebcamMuted = (isMuted) => ({
  type: types.SET_USER_WEBCAM_MUTED,
  isMuted,
  ws: true,
});

export const sendHasScreen = () => ({
  type: types.SEND_HAS_SCREEN,
  ws: true,
});

export const courseEndSoon = () => ({
  type: types.COURSE_END_SOON,
  ws: true,
});

export const courseEnded = () => ({
  type: types.COURSE_ENDED,
  ws: true,
});

/**
 *
 * Conference
 *
 */
export const getConferencePeers = () => ({
  type: types.GET_AUDIO_CONFERENCE_PEERS,
  ws: true,
});

export const setConferenceStatus = (conferenceStatus) => ({
  type: types.SET_AUDIO_CONFERENCE_STATUS,
  conferenceStatus,
  ws: true,
});

/**
 * Transcription
 */
export const transcriptionInit = () => (dispatch) => {
  dispatch({
    type: types.TRANSCRIPTION_INIT,
  });
};

export const setTranscriptionText = (text) => ({
  type: types.SET_TEXT,
  text,
});

export const connectTranscription = () => ({
  type: types.CONNECT_TRANSCRIPTION,
});

export const disconnectTranscription = () => ({
  type: types.DISCONNECT_TRANSCRIPTION,
});

export const simpleSendTakeVoice = (userId) => ({
  type: types.SEND_TAKE_VOICE,
  userId,
  ws: true,
});

/** WebRTC Management */
export const setWebRTCConnected = (localWebcamStreamId, localScreenStreamId) => ({
  type: types.WEBRTC_CONNECT,
  status: true,
  localWebcamStreamId,
  localScreenStreamId,
});

export const setWebRTCDisconnected = () => ({
  type: types.WEBRTC_CONNECT,
  status: false,
});

export const startMyWebcamStream = ({ audioOnly }) => ({
  type: types.START_MY_WEBCAM_STREAM,
  audioOnly,
});

export const stopMyWebcamStream = () => ({
  type: types.STOP_MY_WEBCAM_STREAM,
});

export const setStreamList = (streamList, streamType) => ({
  type: types.SET_STREAM_LIST,
  streamList,
  streamType,
});

export const setStreamListStatus = (streamType, status) => ({
  type: types.SET_STREAM_LIST_STATUS,
  streamType,
  status,
});

export const setMyCamAndMicPublishing = (isMyCamAndMicPublishing) => ({
  type: types.SET_MY_CAM_AND_MIC_PUBLISHING,
  isMyCamAndMicPublishing,
});

export const setAntmediaLocalStreamReady = (isLocalStreamReady) => ({
  type: types.ANTMEDIA_LOCALSTREAM_READY,
  isLocalStreamReady,
});

/**
 * VIRTUAL DESKTOP
 */
export const setVirtualDesktopStatus = (status) => ({
  type: types.SET_VIRTUAL_DESKTOP_STATUS,
  status,
});

/**
 * Tracking (Mixpanel)
 */
export const changeTheme = (value) => ({
  type: types.CHANGE_THEME,
  value,
});

export const courseLeft = (method) => ({
  type: types.COURSE_LEFT,
  method,
});

/**
 * SigninSheet
 */
export const setMenuSignature = ({ isOpen }) => ({
  type: types.SET_MENU_SIGNATURE,
  isOpen,
});

export const setSignatureModal = ({ isOpen }) => ({
  type: types.SET_SIGNIN_SHEET_MODAL,
  isOpen,
});

export const setFollowingModal = ({ isOpen }) => ({
  type: types.SET_SIGN_FOLLOWING_MODAL,
  isOpen,
});

export const startSignature = () => ({
  type: types.START_SIGNATURE,
  ws: true,
});

export const confirmResetSignatures = ({ resetIsOpen }) => ({
  type: types.CONFIRM_RESET_SIGNATURES,
  resetIsOpen,
});

export const resetSignatures = () => ({
  type: types.RESET_SIGNATURES,
  ws: true,
});

export const resendSignNotifs = () => ({
  type: types.RESEND_SIGNATURE_NOTIFICATIONS,
  ws: true,
});

export const checkSignatureTimeslot = () => ({
  type: types.CHECK_SIGNATURE_TIMESLOT,
  ws: true,
});

export const fetchOrganizations = () => (dispatch) =>
  axios({
    method: 'GET',
    url: 'https://org.slippers.live/prod/orga',
  }).then(({ data }) => {
    SlipperApi.setApiUrl(data.api_url);
    // Save data in the organization store
    return dispatch({
      type: types.INITIALIZE_ORGANIZATION,
      data,
    });
  });

export const initializeKcToken = (token) => ({
  type: types.INITIALIZE_KC_TOKEN,
  token,
});
