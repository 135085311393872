/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Code
 */
export const Audio = styled.audio`
  display: none;
`;
