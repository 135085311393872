/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { PromotionDTO } from 'src/schemas/Entities/Promotion';
import { updatePromotion, UpdatePromotionProps } from './updatePromotion';

/*
 * Code
 */
export const useUpdatePromotion = (): UseMutationResult<
PromotionDTO,
unknown,
UpdatePromotionProps,
unknown
> => {
  const promotionMutation = useMutation(
    (updatePromotionProps: UpdatePromotionProps) => updatePromotion(updatePromotionProps),
    {
      onSettled: () => {
        queryClient.invalidateQueries('promotions');
      },
      onSuccess: (promotionData) => {
        toast(`Promotion ${promotionData.name} modifiée avec succès !`, {
          type: 'success',
        });
      },
    },
  );

  return promotionMutation;
};
