import { useCallback, useContext, useEffect } from 'react';

import { MessageContext } from 'src/context/Message';
import {
  ADD_PIN,
  FLAG_MESSAGE_AS_QUESTION,
  REMOVE_PIN,
  SET_QUESTION_MESSAGE_ANSWERED_STATUS,
} from 'src/store/types';

/**
 * Hook that handles pining, unpining, asking & answering messages.
 * If given a socket, it will add listeners for those actions.
 */
export const useAction = (socket) => {
  const { messagesById, updateMessage } = useContext(MessageContext);

  /**
   * Ask message as question
   * Can be triggered by websocket event
   */
  const setMessageAsQuestion = useCallback(
    ({ messageId }) => {
      updateMessage(messageId, {
        isAsked: true,
      });
    },
    [messagesById, updateMessage],
  );

  /**
   * Pin message
   */
  const setMessagePinned = useCallback(
    ({ messageId }) => {
      updateMessage(messageId, {
        pinned: true,
      });
    },
    [messagesById, updateMessage],
  );

  /**
   * Unpin message
   */
  const setMessageUnpinned = useCallback(
    ({ messageId }) => {
      updateMessage(messageId, {
        pinned: false,
      });
    },
    [messagesById, updateMessage],
  );

  /**
   * Updates message isAnswered status
   * Can be triggered by websocket event
   */
  const setQuestionAnswered = useCallback(
    ({ messageId, answered }) => {
      updateMessage(messageId, {
        isAnswered: answered,
      });
    },
    [messagesById, updateMessage],
  );

  useEffect(() => {
    socket?.on(ADD_PIN, setMessagePinned);
    socket?.on(FLAG_MESSAGE_AS_QUESTION, setMessageAsQuestion);
    socket?.on(REMOVE_PIN, setMessageUnpinned);
    socket?.on(SET_QUESTION_MESSAGE_ANSWERED_STATUS, setQuestionAnswered);

    return () => {
      socket?.off(ADD_PIN, setMessagePinned);
      socket?.off(FLAG_MESSAGE_AS_QUESTION, setMessageAsQuestion);
      socket?.off(REMOVE_PIN, setMessageUnpinned);
      socket?.off(SET_QUESTION_MESSAGE_ANSWERED_STATUS, setQuestionAnswered);
    };
  }, [setMessageAsQuestion, setMessagePinned, setMessageUnpinned, setQuestionAnswered, socket]);

  return {
    setMessageAsQuestion,
    setMessagePinned,
    setMessageUnpinned,
    setQuestionAnswered,
  };
};
