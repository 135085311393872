/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Component
 */
const Transcription = ({ index, isSameAuthor, name, text }) => (
  <>
    {(!index || !isSameAuthor) && (
      <S.User>
        {!!index && <br />}[{name}]&nbsp;
      </S.User>
    )}
    <S.Text>{text}&nbsp;</S.Text>
  </>
);

/*
 * PropTypes
 */
Transcription.propTypes = {
  index: PropTypes.number.isRequired,
  isSameAuthor: PropTypes.bool,
  name: PropTypes.string,
  text: PropTypes.string,
};

Transcription.defaultProps = {
  isSameAuthor: false,
  name: 'Utilisateur inconnu',
  text: '',
};

/*
 * Export
 */
export default Transcription;
