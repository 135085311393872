/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import { isMac } from 'src/utils/navigator';

// Data
import data from 'src/data/shortcuts';

// Style
import * as S from '../style';

/*
 * Component
 */
const Sections = () =>
  data.map((obj) => (
    <section key={obj.title}>
      <S.SubTitle>{obj.title}</S.SubTitle>
      {obj.actions.map((action) => (
        <S.Line key={action.label}>
          <S.Action>{action.label}</S.Action>
          <S.Shorcut>
            {action.keys.map((key, index, array) => {
              const osKey = isMac() ? key.replace('Alt', 'Option').replace('Ctrl', 'Command') : key;
              return (
                <React.Fragment key={osKey}>
                  <S.Key>{osKey}</S.Key>
                  {index < array.length - 1 ? <S.Separator> + </S.Separator> : null}
                </React.Fragment>
              );
            })}
          </S.Shorcut>
        </S.Line>
      ))}
    </section>
  ));

/*
 * Export
 */
export default Sections;
