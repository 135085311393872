/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local import
 */
import { ReactComponent as EdusignIcon } from 'assets/images/icons/edusign.svg';

/*
 * Style
 */
export const ClassTitle = styled.span(({ theme }) => ({
  ...theme.typo.headlines.H6,
  display: 'block',
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center',
}));

export const ClassTitleContainer = styled.div({
  margin: '0 1.5rem',
  overflow: 'hidden',
});

export const Container = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

export const Button = styled.button(({ isDisplay, theme, disabled }) => {
  let color = theme.primary._1;

  if (disabled) color = theme.text._5;
  else if (!isDisplay) color = theme.text._4;

  return {
    alignItems: 'center',
    borderRadius: '8px',
    color,
    display: 'flex',
    margin: '0.25rem',
    padding: '0.5rem 1rem',
    position: 'relative',
    zIndex: '5',

    path: {
      fill: color,
    },

    ...(!disabled && {
      ':hover': {
        background: theme.background._8,
        color: isDisplay ? theme.primary._1 : theme.text._1,

        path: {
          fill: isDisplay ? theme.primary._1 : theme.text._1,
        },
      },

      ':focus-visible': {
        background: 'none',
        border: `2px solid ${theme.primary._1}`,
      },

      ':-moz-focusring': {
        background: 'none',
        border: `2px solid ${theme.primary._1}`,
      },

      '.icon': {
        margin: '0',
      },
    }),
  };
});

export const Count = styled.span`
  background: ${(props) => props.theme.primary._5};
  border-radius: 200px;
  color: ${(props) => props.theme.primary._1};
  margin-left: 0.5rem;
  margin-top: -0.25rem; /** hackz for style purpose on topbar. Related to Y axis padding */
  margin-bottom: -0.25rem; /** hackz for style purpose on topbar. Related to Y axis padding */
  padding: 0.25rem 0.5rem;
  ${(props) => ({ ...props.theme.typo.labels.tinyLabel })};
  line-height: 170%;
`;

export const Label = styled.span`
  margin-left: 0.5rem;
`;

export const Badge = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '.125rem',
  right: '.125rem',
  width: '.5rem',
  height: '.5rem',
  borderRadius: '50%',
  background: theme.danger.main,
}));

export const TitleSrOnly = styled.h2(({ theme }) => ({
  ...theme.srOnly,
}));

export const EdusignLogo = styled(EdusignIcon)({
  width: '24px',
  height: '24px',
});
