/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilExclamationCircle } from '@iconscout/react-unicons';

/*
 * Local Import
 */

/*
 * Style
 */
export const Account = styled.div`
  max-width: 620px;
  margin: 0 auto;
  width: 100%;
`;

export const Title = styled.h3(({ theme }) => ({
  ...theme.typo.headlines.H3,
  marginBottom: '.5rem',
}));

export const Form = styled.form`
  margin: 0 auto;
`;

export const Fieldset = styled.fieldset({
  width: '100%',
});

export const Label = styled.label`
  color: var(--text-1);
  height: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.18px;
  text-align: left;
`;

export const Text = styled.p({
  margin: '0 1.5rem 0 0.5rem',
  fontSize: '0.875rem',
  lineHeight: '150%',
});

export const Preview = styled.img(({ theme, error }) => ({
  display: 'block',
  height: '5rem',
  width: '5rem',
  marginTop: '0.25rem',
  borderRadius: '0.5rem',
  border: `4px solid ${error ? theme.colors.danger._600 : 'transparent'}`,
}));

export const AvatarContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
});

export const UploadContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '1.3rem',
});

export const Format = styled.li(({ theme }) => ({
  color: theme.colors.coolGrey._600,
  fontSize: '0.625rem',
  fontWeight: '500',
  listStyle: 'initial',
  listStylePosition: 'inside',
  marginBottom: '4px',
}));

export const AvatarError = styled.div(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.danger._600,
  display: 'flex',
  fontSize: '0.75rem',

  // eslint-disable-next-line id-length
  p: {
    width: '10.8rem',
  },
}));

export const ErrorIcon = styled(UilExclamationCircle)({
  width: '1rem',
  marginRight: '5.33px',
});

export const Upload = styled.div(({ theme }) => ({
  color: theme.colors.primary._600,
  cursor: 'pointer',
  fontWeight: '500',
  textAlign: 'center',
}));

export const Input = styled.input(({ theme, isAvatar }) => ({
  width: '18.5rem',
  height: '2.5rem',
  padding: '8px 12px',
  marginTop: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: `1px solid ${theme.colors.coolGrey._300}`,
  borderRadius: '8px',

  ':disabled': {
    background: theme.colors.coolGrey._200,
  },

  ...theme.focusVisible,
  ...(isAvatar && { display: 'none' }),
}));

export const ButtonContainer = styled.div({
  marginTop: '.5rem',
  display: 'flex',
  justifyContent: 'flex-end',

  '& > button:last-child': {
    marginLeft: '1rem',
  },
});

export const Box = styled.div(({ withMargin }) => ({
  width: '100%',
  marginRight: withMargin ? '1.5rem' : '0',
}));

export const Line = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '2rem',

  ':first-of-type': {
    marginBottom: '1.5rem',
  },
});

export const Caption = styled.p(({ theme }) => ({
  ...theme.typo.bodyText.tinyText,
}));

export const FormHeader = styled.div({
  margin: '1rem auto 1.5rem',
});
