/**
 * Local Import
 */
import type { MessageProps } from 'src/utils/types';

/**
 * Get the latest preview message
 */
export const getLatestMessagePreview = (lastestMessage: MessageProps) => {
  if (lastestMessage) {
    // First priority : Message deleted
    if (lastestMessage.deletedAt) {
      return { ops: [{ insert: 'Message supprimé' }] };
    }

    // Second priority : Content (message)
    if (
      lastestMessage.content
      && 'ops' in lastestMessage.content
      && Array.isArray(lastestMessage.content.ops)
      && lastestMessage.content.ops.length
    ) {
      return lastestMessage.content;
    }
  }

  // By default : Undefined message
  return { ops: [] };
};
