/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';
import { UilEllipsisV } from '@iconscout/react-unicons';
import { MenuButton as ReactMenuListButton } from 'react-menu-list';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import { MongoId } from 'src/schemas/Entities/utils';
import Tooltip from 'src/components/Tooltip';
import { MenuOption } from './ActionsMenuOptions';

/*
 * Styles
 */
const MenuButton = styled(ReactMenuListButton)(({ theme }) => ({
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    background: theme.background._5,
  },

  ...theme.focusVisible,

  '&:active': {
    background: theme.primary._3,
    svg: {
      color: theme.primary._1,
    },
  },
}));

const MenuIcon = styled(UilEllipsisV)(({ theme }) => ({
  color: theme.text._4,
}));

const Menu = styled('div')(({ theme }) => ({
  borderRadius: '16px',
  border: `2px solid ${theme.text._7}`,
  background: theme.background._2,
  padding: '.5rem .5rem .25rem .5rem',
  marginRight: '.25rem',
  ...theme.shadows.plus2,
}));

const MenuItem = styled('button')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  color: theme.text._1,
  padding: '.25rem .5rem .25rem .5rem',
  marginBottom: '.25rem',
  borderRadius: '8px',
  alignItems: 'center',
  ':hover:enabled': {
    background: theme.background._8,
  },
  ':disabled': {
    color: theme.text._6,
    cursor: 'not-allowed',
  },
}));

const getMenuTextStyles = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.smallLabel;
const MenuItemText = styled('div')(({ theme }) => ({
  marginLeft: '.5rem',
  ...getMenuTextStyles(theme),
}));

/*
 * Actions Menu Component
 */
interface ActionsMenuProps {
  rowId: MongoId | null;
  menuOptions: MenuOption[];
}

const getTooltipProps = (
  getTooltipContent: ((rowId: string | null) => string) | undefined,
  rowId: MongoId | null,
) => {
  if (getTooltipContent) {
    return {
      disabled: false,
      content: getTooltipContent(rowId),
    };
  }
  return {
    disabled: true,
    content: '',
  };
};

const ActionsMenu = ({ rowId, menuOptions }: ActionsMenuProps): JSX.Element => (
  <>
    <MenuButton
      menuZIndex={10}
      positionOptions={{ position: 'left', vAlign: 'top' }}
      menu={(
        <Menu role="menu">
          {menuOptions.map(
            (option) =>
              (!option.isDisplayed || option.isDisplayed(rowId)) && (
                <Tooltip {...getTooltipProps(option.getTooltipContent, rowId)} key={option.key}>
                  <MenuItem
                    type="button"
                    role="menuitem"
                    disabled={option.isDisabled && option.isDisabled(rowId)}
                    onClick={option.getHandle(rowId)}
                  >
                    {option.icon}
                    <MenuItemText>{option.label}</MenuItemText>
                  </MenuItem>
                </Tooltip>
              ),
          )}
        </Menu>
      )}
    >
      <MenuIcon />
    </MenuButton>
  </>
);

export default ActionsMenu;
