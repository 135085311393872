/*
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UilSearch } from '@iconscout/react-unicons';

/*
 * Local Import
 */

/*
 * Styles
 */
const FilterContainer = styled.div(({ theme, isValue }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  paddingLeft: '1rem',
  marginBottom: '2rem',
  borderRadius: '8px',
  border: `1px solid ${theme.text._7}`,
  boxShadow: isValue ? `0 0 0 2px ${theme.primary._1}` : '',
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${theme.primary._1}`,
  },
}));

const StyledSearchIcon = styled(UilSearch)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '1rem',
  color: theme.text._4,
}));

const Input = styled.input(({ theme }) => ({
  ...theme.typo.bodyText.body,
  background: 'transparent',
  lineHeight: '1.5rem',
  marginLeft: '1.2rem',
  width: '100%',
  textOverflow: 'ellipsis',
  color: theme.text._1,
  padding: '.5rem 0',
}));

/*
 * Components
 */
export const UpdatePromoUsersFilter = ({ usersFilterValue, setFilter }) => {
  const [value, setValue] = useState(usersFilterValue);
  const onChange = (inputValue) => {
    setFilter(inputValue || undefined); // Set undefined to remove the filter entirely
  };

  return (
    <FilterContainer isValue={!!value}>
      <StyledSearchIcon />
      <Input
        value={value || ''}
        onChange={(event) => {
          event.preventDefault();
          onChange(event.target.value);
          setValue(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        placeholder="Rechercher un participant"
        id="search-filter"
      />
    </FilterContainer>
  );
};

UpdatePromoUsersFilter.propTypes = {
  usersFilterValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};
UpdatePromoUsersFilter.defaultProps = {
  usersFilterValue: '',
};
