/*
 * Package Import
 */

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { API_DELETE_USER, API_DELETE } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const deleteUser = async (userId: string): Promise<void> => {
  const payload: Payload = {
    method: API_DELETE,
    url: API_DELETE_USER,
    data: {
      _id: userId,
    },
  };

  await api(payload);
};
