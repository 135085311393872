/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MediasManagement from 'src/components/Medias/Management';
import { updateCurrentDevice } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  cameraId: state.course.medias.cameraId,
  microphoneId: state.course.medias.microphoneId,
  speakerId: state.course.medias.speakerId,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateCurrentDevice,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(MediasManagement);
