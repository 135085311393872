/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Package Import
 */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

/**
 * Local Import
 */
import Account from 'src/components/Dashboard/Settings/Account';
import Integrations from 'src/components/Dashboard/Settings/Integrations';
import Navigation from 'src/components/Dashboard/Settings/Navigation';
import GiveAccess from 'src/components/Authorization/GiveAccess';
import ToastContainer from 'src/components/Admin/ToastContainer';

// Helpers
import { SETTING_URL, ACCOUNT_URL, APPLICATIONS_URL } from 'src/constants/routes';
import { SETTINGS_TITLE } from 'src/constants/titles';
import { trackEvent } from 'src/utils/tracking';
import { roleEnum } from 'src/constants/roles';

// Style
import * as S from './style';

/**
 * Component
 */
const Settings = () => {
  /**
   * Hooks
   */
  const { pathname } = useLocation();

  /**
   * LifeCycles
   */
  useEffect(() => {
    document.title = SETTINGS_TITLE;

    // Tracking event
    trackEvent('Page Viewed', { title: SETTINGS_TITLE, url: pathname });
  }, []);

  /**
   * Render
   */
  return (
    <S.Center>
      <S.TitleContainer>
        <S.PageTitle>Paramètres</S.PageTitle>
      </S.TitleContainer>
      <Navigation />
      <S.Main>
        <Switch>
          <Route exact path={SETTING_URL}>
            <Redirect to={ACCOUNT_URL} />
          </Route>
          <Route exact path={ACCOUNT_URL}>
            <Account />
          </Route>
          <Route exact path={APPLICATIONS_URL}>
            <GiveAccess when={(client) => client.isMinRole(roleEnum.superAdmin)}>
              <Integrations />
            </GiveAccess>
          </Route>
        </Switch>
      </S.Main>

      <ToastContainer position="top-center" />
    </S.Center>
  );
};

/**
 * Export
 */
export default Settings;
