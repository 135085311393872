/**
 * Title
 */
export const DASHBOARD_TITLE = 'Tableau de bord - Slippers';
export const SETTINGS_TITLE = 'Paramètres - Slippers';
export const VM_TITLE = 'Bureau virtuel - Slippers';
export const NOT_FOUND_TITLE = 'Page non trouvé - Slippers';

// Admin
export const ADMIN_COURSES_TITLE = 'Gestion des cours - Slippers';
export const ADMIN_USERS_TITLE = 'Gestion des utilisteurs - Slippers';
export const ADMIN_PROMOTIONS_TITLE = 'Gestion des promotions - Slippers';

// Classroom
export const CLASSROOM_EXTERNAL_TITLE = 'Chat/Sondage/Participants - Slippers';
export const CLASSROOM_WEBCAM_TITLE = 'Webcam - Slippers';
export const CLASSROOM_SCREEN_TITLE = "Partage d'écran - Slippers";
export const getClassroomTitle = (courseTitle: string) => `${courseTitle} - Slippers`;
