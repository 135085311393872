/**
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/**
 * Style
 */
export const SectionTitle = styled.h4((props) => ({
  ...props.theme.typo.labels.label,
  margin: '0.25 0 0.25 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 1 auto',
  color: props.theme.text._1,
}));

export const ShadowContainer = styled.div(({ isTop, theme }) => ({
  zIndex: 1,
  ...(!isTop && theme.themeName === LIGHT_THEME_NAME && { ...theme.shadows.plus2 }),
}));

export const StudentItem = styled.li(({ fullWidth, listItem }) => ({
  width: fullWidth && '100%',
  ...(listItem && {
    padding: '0.4rem 0',
    ':first-of-type': { paddingTop: '0' },
  }),
}));

export const StudentList = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: '1rem',
});
