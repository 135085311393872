import {
  eachMinuteOfInterval,
  isSameDay,
  isSameMinute,
  isWithinInterval,
  differenceInMinutes,
  Interval,
  isAfter,
  min,
  max,
} from 'date-fns';

import type { Course } from 'src/schemas/Entities/Course';
import type { Option } from 'src/components/Admin/Modals/FormElements/type';
import { Slot } from 'src/components/Admin/Modals/FormElements/Timeslot/utils';

import { LimitationDTO } from 'src/schemas/Entities/Limitation';
import { MongoId } from 'src/schemas/Entities/utils';

interface CoursesSimultaneousCheckParams {
  data: CourseFormDataPartialStatic;
  coursesData: Course[] | undefined;
  limitationsData: LimitationDTO | undefined;
}
// Can't use directly schema FormData type b/c cyclic dependency
export interface CourseFormDataPartialStatic {
  id?: MongoId;
  promotion: Option | null;
  date: string;
  timeslots?: Slot[] | null;
}
// We transform minutes array in intervals without "holes"
const getProblematicIntervalsInTimeslot = (minutesArray: Date[]) => {
  let indexInArray = 0;

  const minutesGroupedInSubArrays = minutesArray.reduce(
    (arrayAccumulator: Date[][], minute: Date, currentIndex: number) => {
      // If first minute, we put it in one array directly
      if (currentIndex === 0) {
        arrayAccumulator.push([minute]);
        return arrayAccumulator;
      }
      // If minute is less than 1 minute from precedent, we add it at same sub array
      if (differenceInMinutes(minute, minutesArray[currentIndex - 1]) === 1) {
        arrayAccumulator[indexInArray] = [...arrayAccumulator[indexInArray], minute];
        return arrayAccumulator;
      }
      // If minute is more than 1 minute from precedent, we add it at a new array
      if (differenceInMinutes(minute, minutesArray[currentIndex - 1]) > 1) {
        indexInArray += 1;
        arrayAccumulator[indexInArray] = [minute];
        return arrayAccumulator;
      }

      return arrayAccumulator;
    },
    [],
  );

  // For each sub array we transform it in interval
  const problematicIntervals = minutesGroupedInSubArrays.map((minutesSubArray) => ({
    start: min(minutesSubArray),
    end: max(minutesSubArray),
  }));

  // And we filter out intervals where its the same minute at start and end
  const problematicIntervalsAtLeastOneMinute = problematicIntervals.filter(
    (interval) => !isSameMinute(interval.start, interval.end),
  );

  return problematicIntervalsAtLeastOneMinute;
};

// Receive course form data, courses and limitations from cache
// And return timeslots with error data where limitation exceeded
export const findSimultaneousTimeslotsAndCheckLimitation = (
  params: CoursesSimultaneousCheckParams,
) => {
  const { data, coursesData, limitationsData } = params;

  const problematicTimeslots: {
    index: number;
    intervals: Interval[];
    limitationQuantity: number;
  }[] = [];

  const { timeslots } = data;
  const simultaneousCoursesLimitation = limitationsData?.find(
    (limitationData) => limitationData.feature === 'simultaneousCourses',
  );

  // If no limitation received, don't even check
  if (
    !simultaneousCoursesLimitation
    || !simultaneousCoursesLimitation.active
    || simultaneousCoursesLimitation.quantity === null
    || simultaneousCoursesLimitation.quantity === undefined
  ) return [];
  const limitationQuantity = simultaneousCoursesLimitation.quantity;

  // If no other courses, no need to check
  if (!coursesData) return [];
  // If no timeslots, no need to check here
  if (!timeslots) return [];

  // We filter to keep only courses of the actual course date
  const sameDayCourses = coursesData.filter((course) =>
    isSameDay(course.date, new Date(data.date)),
  );

  // For each timeslot receive from form i create interval with one
  // Date for start and for end (like timeslots of Course entity)
  timeslots.forEach((timeslot, index) => {
    const timeslotDate = {
      start: new Date(`${data.date}T${timeslot.start}`),
      end: new Date(`${data.date}T${timeslot.end}`),
    };
    // Check if start is after end, and exit early to avoid errors if it's the case
    // (another validation control it)
    if (isAfter(timeslotDate.start, timeslotDate.end)) {
      return;
    }

    // And for this interval, i create an array of each minutes to control
    // them one by one
    const eachMinuteOfTimeslot = eachMinuteOfInterval(timeslotDate);

    // If data.id exists, its update form so
    // I filter the courses list to remove the course we are updating, to avoid to count 2 times
    // its timeslots
    const coursesWithoutActual = sameDayCourses.filter((course) => course.id !== data.id);

    // I get all timeslots of courses filtered in one big array
    const timeslotsCourses = coursesWithoutActual.flatMap((course) => course.timeslots);

    // If no timeslots found, exit early, no control to make
    if (timeslotsCourses.length === 0) return;

    // For each minute of this timeslot, check number of time where it intersect and if it
    // exceed limitation
    const problematicMinutes = eachMinuteOfTimeslot.filter((minute) => {
      const overlappingMinuteAndTimeslots = timeslotsCourses.filter((timeslotCourse) =>
        isWithinInterval(minute, timeslotCourse),
      );
      // If timeslots number in conflic + actual one is greater to limitation
      // We keep minute as problematic
      return overlappingMinuteAndTimeslots.length + 1 > limitationQuantity;
    });

    // If no problematic minute on this tomeslot, return early
    if (problematicMinutes.length === 0) return;
    // We create problematic intervals in regrouping adjacent minutes
    const problematicIntervalsInTimeslot = getProblematicIntervalsInTimeslot(problematicMinutes);
    // If no intervals generated (maybe b/c intervals found < 1 minute), return early
    if (problematicIntervalsInTimeslot.length === 0) return;
    // Else, add data at problematic timeslots
    problematicTimeslots.push({
      index,
      intervals: problematicIntervalsInTimeslot,
      limitationQuantity,
    });
  });

  return problematicTimeslots;
};
