/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilTimes } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import { ReactComponent as EdusignIcon } from 'assets/images/icons/edusign.svg';

/**
 * Styles
 */
export const Connexion = styled.div`
  border-bottom: 1px dashed #e2e8f0;
  height: 3.375rem;
  width: 75px;
  transform: translateY(-50%);
`;

export const LogoContainer = styled.div`
  display: flex;
  padding: 1.5rem 0;
`;

export const EdusignLogoContainer = styled.div`
  align-items: center;
  background: #fec007;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  height: 3.375rem;
  width: 3.375rem;
`;

export const SlippersLogoContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.background._2};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 3.375rem;
  padding: 0.75rem;
  width: 3.375rem;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
`;

export const EdusignCTA = styled.button(({ theme }) => ({
  ...theme.typo.labels.label,
  background: '#fec007',
  border: '1px solid rgba(0, 0, 0, 0)',
  borderRadius: '8px',
  color: '#ffffff',
  textAlign: 'center',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '2.5rem',
  margin: '1.5rem 0',
  padding: '0.75rem',

  ':hover': {
    backgroundColor: '#f9bb00',
    borderColor: 'transparent',
  },
}));

export const BodyFlag = styled.div(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  color: theme.text._1,
}));

export const ContentContainer = styled.div`
  width: 100%;
`;

export const List = styled.ul`
  text-align: left;
`;

export const Item = styled.li`
  list-style: disc;
  margin: 1.5rem 0;
`;

export const Close = styled.button(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  top: '0.75rem',
  right: '0.75rem',
  borderRadius: '4px',
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ':hover': {
    background: theme.background._8,
  },

  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

export const StyledUilClose = styled(UilTimes)((props) => ({
  color: props.theme.text._5,
}));

export const ModalCTA = styled.button(({ theme }) => ({
  ...theme.typo.bodyText.body,
  color: theme.primary._1,
  margin: '1rem 0',
  float: 'right',
}));

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.5rem 1.5rem;
  max-width: 740px;
  text-align: ${({ isAlignCenter }: { isAlignCenter?: boolean }) =>
    (isAlignCenter ? 'center' : 'left')};
`;

export const ModalTitle = styled.h2(({ theme }) => ({
  ...theme.typo.headlines.H2,
  color: theme.text._1,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  margin: '2rem 0 2.5rem',
}));

export const Img = styled.img(({ theme }) => ({
  border: `1px solid ${theme.text._6}`,
  borderRadius: '8px',
  margin: '0 auto',
  maxHeight: '426px',
  maxWidth: '659px',
}));

export const Input = styled.div`
  align-items: center;
  display: flex;
`;

export const Radio = styled.input`
  appearance: radio;
`;

export const Label = styled.label(({ theme }) => ({
  ...theme.typo.bodyText.body,
  margin: '0.75rem 0.5rem',
  textAlign: 'left',
}));

export const EdusignLogo = styled(EdusignIcon)(() => ({
  width: '36px',
  height: '37px',
}));
