/*
 * Package Import
 */
import Papa from 'papaparse';
/*
 * Local Import
 */
import { usersImportExampleData, fileFieldsLabels } from 'src/constants/usersCSV';
import { UserFormData } from 'src/schemas/Entities/User';
import { Option } from '../../FormElements/type';

/*
 * Code
 */
export type PromotionFormData = {
  promotion: Option | null;
};

export const usersCSVExample = Papa.unparse({
  data: usersImportExampleData,
  fields: fileFieldsLabels,
});

export const addPromotionToUsers = (
  newUsers: UserFormData[],
  promotionFormData: PromotionFormData,
): UserFormData[] => {
  const newUsersWithNewPromotion = newUsers.map((newUser) => {
    newUser.promotions.push(promotionFormData.promotion);
    return newUser;
  });
  return newUsersWithNewPromotion;
};
