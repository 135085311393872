/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { MongoId } from 'src/schemas/Entities/utils';
import { PromotionActivityExportFormDataValidated } from 'src/schemas/Entities/Promotion';
import { api, QueryPayload } from 'src/utils/api';
import { API_ACTIVITY_EXPORT, API_GET } from 'src/constants/apiRoutes';

/*
 * Code
 */
export type PromotionActivity = {
  promoName: string;
  file: string;
};

export const fetchPromotionActivity = async (
  promotionId: MongoId,
  promotionActivityFormData: PromotionActivityExportFormDataValidated,
): Promise<PromotionActivity> => {
  try {
    const { start, end } = promotionActivityFormData;

    const payload: QueryPayload = {
      method: API_GET,
      url: `${API_ACTIVITY_EXPORT}${promotionId}`,
      params: {
        start: Date.parse(start),
        end: Date.parse(end),
        csv: true,
      },
    };

    const { data: activityData } = await api(payload);

    return activityData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
