/*
 * Package Import
 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingFn,
  Row,
  ColumnDef,
  Column,
} from '@tanstack/react-table';

/*
 * Local Import
 */

import Table from 'src/components/Admin/Tables/Table';
import { roles } from 'src/constants/roles';
import * as T from 'src/components/Admin/TableElements';
import { noMatchCaseSort } from 'src/utils/table';
import { getIsRegisteredStrings } from 'src/constants/status';
import { User } from 'src/schemas/Entities/User';
import { rolesFilterOptions } from './utils';

/*
 * Code
 */

// Filters
// Define a default UI for filtering
const DefaultFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<User, unknown>;
}) => (
  <T.FilterText
    filterValue={getFilterValue() as string | null | undefined}
    setFilter={setFilterValue}
    header={columnDef.header as string}
  />
);

// Role filter is a select and not a search text
const RoleFilter = ({
  column: { getFilterValue, setFilterValue, columnDef },
}: {
  column: Column<User, unknown>;
}) => (
  <T.FilterCheckbox
    filterValue={getFilterValue() as (string | null | undefined)[]}
    setFilter={setFilterValue}
    options={rolesFilterOptions}
    header={columnDef.header as string}
  />
);

// Cells components
const AvatarCell = ({ row }: { row: Row<User> }) => (
  <T.Avatar
    avatar={row.original?.avatar || ''}
    firstname={row.original?.firstname || ''}
    lastname={row.original?.lastname || ''}
  />
);

const RoleCell = ({ row }: { row: Row<User> }) => (
  <T.Tag>{roles.find((role) => role.name === row.original?.role)?.display}</T.Tag>
);

const PromotionCell = ({ row }: { row: Row<User> }) =>
  (row.original?.promotions && row.original.promotions.length > 0 ? (
    <T.TagsList>
      {row.original.promotions
        ?.filter((promotion) => !!promotion)
        .map((promotion) =>
          (promotion?.deactivatedAt ? (
            <T.DeactivatedRelation
              key={promotion?.id}
              content={promotion?.displayName || ''}
              withTag
              tooltipContent="Promotion archivée"
            />
          ) : (
            <T.Tag key={promotion?.id}>{promotion?.displayName}</T.Tag>
          )),
        )}
    </T.TagsList>
  ) : (
    <div />
  ));

const RegistrationStatusCell = ({ row }: { row: Row<User> }) => (
  <T.RegistrationStatus status={row.original?.isRegistered || false} />
);

const AccessVMStatusCell = ({ row }: { row: Row<User> }) => (
  <T.AccessVMStatus status={row.original?.canCreateVM || false} />
);

// Hook receiving datas and returning corresponding Table and tableInstance
const useUserTable = (newData: User[] = []) => {
  const [data, setData] = useState(newData);
  const skipPageResetRef = useRef(true);

  // If we receive new Data, we update table data without reset pagination
  useEffect(() => {
    skipPageResetRef.current = true;
    setData(newData);
  }, [newData]);

  // And after a little delay, we reactive reset pagination for sort, search, filters...
  useEffect(() => {
    if (skipPageResetRef.current) {
      setTimeout(() => {
        skipPageResetRef.current = false;
      }, 500);
    }
  });

  // Insensitive case sort
  const insensitiveCaseSort: SortingFn<User> = (rowA, rowB, columnId) =>
    noMatchCaseSort({
      valueA: rowA.getValue(columnId),
      valueB: rowB.getValue(columnId),
    });

  // Columns configuration
  const columns: ColumnDef<User>[] = useMemo(
    () => [
      {
        id: 'id',
        header: 'Id',
        accessorKey: 'id',
      },
      {
        id: 'avatar',
        header: 'Avatar',
        // Custom rendering of this column for avatars
        cell: AvatarCell,
        accessorKey: 'avatar',
        // No filters and sorting for avatar: meaningless
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'firstname',
        header: 'Prénom',
        accessorKey: 'firstname',
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'lastname',
        header: 'Nom',
        accessorKey: 'lastname',
        sortingFn: insensitiveCaseSort,
      },
      {
        id: 'role',
        header: 'Rôle',
        accessorFn: (row: User) => roles.find((role) => role.name === row.role)?.display,
        filterFn: (row: Row<User>, columnId: string, filterValue: string) =>
          !!filterValue.length && filterValue.includes(row.getValue(columnId)),
        cell: RoleCell,
      },
      {
        id: 'promotions',
        header: 'Promotion',
        accessorFn: (row: User) => row.promotions.map((promo) => promo?.displayName).toString(),
        cell: PromotionCell,
        enableSorting: false,
      },
      {
        id: 'email',
        header: 'E-mail',
        accessorKey: 'email',
      },
      {
        id: 'town',
        header: 'Ville',
        accessorKey: 'town',
      },
      {
        id: 'country',
        header: 'Pays',
        accessorKey: 'country',
      },
      {
        id: 'githubProfile',
        header: 'Github',
        accessorKey: 'githubProfile',
      },
      {
        id: 'discordProfile',
        header: 'Discord',
        accessorKey: 'discordProfile',
      },
      {
        id: 'archive',
        header: 'Archivage',
        accessorFn: (row: User) => !!row.deactivatedAt,
        filterFn: (row: Row<User>, columnId: string, filterValue: boolean) =>
          row.getValue(columnId) === filterValue,
      },
      {
        header: 'Statut',
        cell: RegistrationStatusCell,
        id: 'isRegistered',
        accessorFn: (row: User) => getIsRegisteredStrings(row.isRegistered || false).statusString,
        // No filters for registrationStatus: meaningless
        enableColumnFilter: false,
      },
      {
        header: 'Accès VM',
        id: 'canCreateVM',
        accessorKey: 'canCreateVM',
        enableColumnFilter: false,
        enableSorting: false,
        cell: AccessVMStatusCell,
      },
    ],
    // Dependency for useMemo
    [insensitiveCaseSort],
  );

  const initialState = {
    columnVisibility: {
      id: false,
      country: false,
      town: false,
      email: false,
      githubProfile: false,
      discordProfile: false,
      archive: false,
    },
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    columnFilters: [{ id: 'archive', value: false }],
    sorting: useMemo(
      () => [
        {
          id: 'lastname',
          desc: false,
        },
      ],
      [],
    ),
    filtersTypes: {
      id: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      firstname: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      lastname: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      role: {
        Filter: ({ column }: { column: Column<User, unknown> }) => RoleFilter({ column }),
      },
      promotions: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      email: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      town: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      country: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      githubProfile: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
      discordProfile: {
        Filter: ({ column }: { column: Column<User, unknown> }) => DefaultFilter({ column }),
      },
    },
  };

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetAll: !skipPageResetRef.current,
  });
  return [tableInstance, Table] as const;
};

/*
 * Export
 */
export default useUserTable;
