/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.background._3,
}));
