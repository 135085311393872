import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

export const TitleContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const getBigLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.bigLabel;
export const Title = styled.span(({ theme }) => ({
  ...getBigLabelStyle(theme),
  marginLeft: '0.5rem',
}));

export const TitleSearchContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const OverheadButtons = styled.div(() => ({
  display: 'flex',
  margin: '0.5rem 0',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const AddButtons = styled.div(() => ({
  display: 'flex',
}));
