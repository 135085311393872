/* eslint-disable camelcase */

/*
 * Package import
 */
import React, { useContext } from 'react';
// import { useSelector } from 'react-redux';
import { SlippersMenu } from '@oclock/crumble';

/*
 * Local import
 */
import { UserContext } from 'src/context/User';

/*
 * Component
 */
const Nav = () => {
  /*
   * Context
   */
  const { user, signOut } = useContext(UserContext);

  /*
   * Vars
   */
  // const { replays_url: replaysURL } = useSelector(getOrganization);

  /*
   * Render
   */
  if (!user) {
    return null;
  }

  return (
    <SlippersMenu
      user={user}
      urls={{
        classroom: { to: '/' },
        settings: { to: '/settings' },
        ...(user.canCreateVM && { vm: { to: '/vm', label: 'Bureau Virtuel' } }),
        // replay: { href: replaysURL },
        admin: {
          courses: { to: '/admin/courses' },
          users: { to: '/admin/users' },
          promotions: { to: '/admin/promotions' },
        },
      }}
      onDisconnect={signOut}
    />
  );
};

/*
 * Export
 */
export default Nav;
