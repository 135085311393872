/* eslint-disable max-len */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import type { Course } from 'src/schemas/Entities/Course';
import { store } from 'src/localStateManagers/store';
import type { User, UserDisplayBeforeCreate } from 'src/schemas/Entities/User';
import {
  findCoursesAndCheckIfUserMustBePeda,
  findClientRoleAndCheckIfRoleGivenIsLowerOrEqual,
  findClientRoleAndCheckIfUserUpdatedIsRoleLower,
  isMailNotAlreadyUsedInUser,
  isMailNotAlreadyUsedInUserDisplayBeforeCreate,
  UserFormDataPartialStatic,
  UserCsvImportTablePartialStatic,
} from 'src/schemas/Refines/Validations/users';

export const userFormMustBePeda = (data: UserFormDataPartialStatic): boolean => {
  const coursesData: Course[] | undefined = queryClient.getQueryData('courses');
  return findCoursesAndCheckIfUserMustBePeda(data, coursesData);
};

export const userFormMustGivenLowerOrEqualRole = (data: UserFormDataPartialStatic): boolean => {
  const { getState } = store;
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  const client = getState().client;
  return findClientRoleAndCheckIfRoleGivenIsLowerOrEqual(data, client, usersData);
};

export const userFormMustChangeLowerRole = (data: UserFormDataPartialStatic): boolean => {
  // If not id => it's Add form => no pb
  if (!data.id) return true;
  const { getState } = store;
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  const client = getState().client;
  return findClientRoleAndCheckIfUserUpdatedIsRoleLower(data.id, client, usersData);
};

export const userFormEmailMustBeUnique = (data: UserFormDataPartialStatic): boolean => {
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  if (!usersData) return true;
  return isMailNotAlreadyUsedInUser(data, usersData);
};

export const userCsvEmailMustBeUniqueRelativeToExistingUsers = (
  data: UserCsvImportTablePartialStatic,
): boolean => {
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  if (!usersData) return true;
  return isMailNotAlreadyUsedInUser(data, usersData);
};

export const userCsvEmailMustBeUniqueInCsvWhenEdit = (
  data: UserCsvImportTablePartialStatic,
): boolean => {
  const usersInCsv: UserDisplayBeforeCreate[] = store
    .getState()
    .newUsers.map((zodResult) => zodResult.data);

  return isMailNotAlreadyUsedInUserDisplayBeforeCreate(data, usersInCsv);
};

export const userCsvEmailMustBeUniqueInCsvWhenUploading = (allNewUsers: UserDisplayBeforeCreate[]) =>
  (data: UserCsvImportTablePartialStatic): boolean =>
    isMailNotAlreadyUsedInUserDisplayBeforeCreate(data, allNewUsers);
