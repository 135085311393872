/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const List = styled.ol`
  min-width: 0;
  // Window height - ChannelHeader height
  height: calc(100vh - 6.125rem);
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-color: ${({ theme }) => theme.background._7} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background._7};
    border-radius: 5px;
  }

  padding-bottom: 1rem;
`;
