import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getBodyTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.bodyText.body;
export const Input = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.text._7}`,
  width: '100%',
  borderRadius: '8px',
  minHeight: '2.5rem',
  padding: '.5rem .75rem',
  fontFamily: 'Inter',
  background: theme.background._2,
  color: theme.text._1,
  ...getBodyTypoStyle(theme),
  ':hover': {
    border: `2px solid ${theme.text._6}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    background: theme.background._8,
  },
  '&.is-error': {
    border: `2px solid ${theme.danger.main}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
  '&.readOnly': {
    background: theme.background._8,
    color: theme.text._6,
  },
  ':focus': {
    border: `2px solid ${theme.primary._1}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
  ':disabled': {
    background: theme.background._8,
    color: theme.text._6,
  },
}));

export const CheckboxInput = styled('input')(() => ({
  appearance: 'checkbox',
  width: '1.25rem',
  height: '1.25rem',
  marginRight: '.625rem',
}));
