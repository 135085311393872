/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Transcription from 'src/components/Course/Transcriptions/Transcription/container';
import * as S from './style';

/*
 * Component
 */
const Transcriptions = ({ transcriptions }) => {
  /*
   * Render
   */
  if (!transcriptions?.length) {
    return null;
  }

  return (
    <S.TranscriptionContainer>
      <S.TranscriptionBackground>
        <S.Transcription>
          {transcriptions.map((transcription, index) => {
            // Get the previous transcription
            const currentIndex = transcriptions.findIndex((item) => item.id === transcription.id);

            const previous = transcriptions[currentIndex - 1];

            return (
              <Transcription
                key={`key-transcription-${transcription.id}`}
                previousAuthorId={previous?.userId}
                {...transcription}
                index={index}
              />
            );
          })}
        </S.Transcription>
      </S.TranscriptionBackground>
    </S.TranscriptionContainer>
  );
};

/*
 * PropTypes
 */
Transcriptions.propTypes = {
  /** Transcripton text  */
  transcriptions: PropTypes.array.isRequired,
};

/*
 * Export
 */
export default Transcriptions;
