/**
 * Package Import
 */
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface ModalState {
  modalType: string | null;
  modalProps: Record<string, boolean>;
}

/*
 * Initial State
 */
const initialState: ModalState = {
  modalType: null,
  modalProps: {},
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    /**
     * Open a modal with differents props
     */
    case types.OPEN_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };

    /**
     * Close the modal
     */
    case types.CLOSE_MODAL:
      return initialState;

    default:
      return state;
  }
};
