/**
 * Package Import
 */
import { useContext, useEffect } from 'react';

/**
 * Local Import
 */
import { useInterval } from 'src/hooks';
import { UserContext } from 'src/context/User';

// Helpers
import VM, { STATUS_VM } from 'src/constants/virtualDesktop';
import { makeRequest } from 'src/utils/virtualMachine';

/**
 * Provider
 */
export const useGetVirtualDesktopStatus = ({ setVirtualDesktopStatus }) => {
  /**
   * Context
   */
  const { user } = useContext(UserContext);

  /**
   * Get the current status of the virtual machine.
   * @return {Promise}
   */
  const getStatus = () =>
    makeRequest({ user, url: 'status/' })
      .then(({ data }) => {
        if (data?.status) {
          setVirtualDesktopStatus(data.status);
        }
      })
      .catch((error) => {
        // 4o4
        if (error?.response?.status === 404) {
          return setVirtualDesktopStatus(STATUS_VM.NOT_FOUND);
        }

        // All others errors
        return setVirtualDesktopStatus(STATUS_VM.ERROR);
      });

  /*
   * Hooks
   */
  useInterval(() => {
    getStatus();
  }, VM.GET_STATUS_DELAY);

  /**
   * LifeCycles
   */
  useEffect(() => {
    // Get the current status of the virtual machine
    if (user?.id) {
      getStatus();
    }
  }, [user?.id]);

  /**
   * Render
   */
  return {
    getStatus,
  };
};
