/*
 * Local import
 */
import { getStorage, setStorage, deleteStorage } from 'src/utils/storage';
import { INITIALIZE_ORGANIZATION } from 'src/store/types';

/*
 * Types
 */
const SAVE_LOCAL_STORAGE = 'LOCAL_STORAGE_SAVE';

/*
 * Code
 */
let hasLocalStorage;

try {
  if ('localStorage' in window && window.localStorage !== null) {
    window.localStorage.setItem('__localStorageTest__', '1');
    window.localStorage.removeItem('__localStorageTest__');
  }
  hasLocalStorage = true;
}
catch (err) {
  hasLocalStorage = false;
}

// Get key
export const getKey = (save) => `middleware_${save.join('_')}`;

// Get item
export const getItem = (object, array) => array.reduce((cumul, item) => cumul[item], object);

// Get storage
export const getSave = (...save) => getStorage(getKey(save));

// Delete storage
export const deleteSave = (...save) => deleteStorage(getKey(save));

/*
 * Middlewares
 */
export default (store) => (next) => (action) => {
  if (hasLocalStorage) {
    switch (action.type) {
      case SAVE_LOCAL_STORAGE: {
        const { save } = action;
        const state = store.getState();
        setStorage(getKey(save), getItem(state, save));
        break;
      }

      case INITIALIZE_ORGANIZATION: {
        setStorage('organization', JSON.stringify(action.data));
        break;
      }

      default:
        break;
    }
  }

  return next(action);
};

/*
 * Actions Creators
 */
export const createSave = (...save) =>
  (actCreator) =>
    (payload) =>
      (dispatch) => {
        dispatch(actCreator(payload));
        dispatch({
          type: SAVE_LOCAL_STORAGE,
          save,
        });
      };
