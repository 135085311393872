/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Message from 'src/components/Chats/Channel/Chat/Message';
import { openChat, confirmCopy } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  // Props
  let next = false;
  const userProps = {};

  //
  const message = ownProps?.message;
  const clientId = state.users.client?.id;

  if (message) {
    const user = state.users.userById[message?.userId];

    // User props
    if (user) {
      userProps.user = user;
    }

    // Next message?
    const previousMessage = ownProps?.previousMessage;

    if (previousMessage) {
      next = previousMessage.userId === message.userId // Both have same author
        && previousMessage.kind === message.kind // Both have the same type
        && message.time - previousMessage.time < 60000 // Less than a 1 min ago
        && !message.pinned; // Message is not pinned
    }
  }

  return {
    ...message,
    ...userProps,
    clientId,
    next,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ openChat, confirmCopy }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps, null)(Message);
