/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import More from 'src/components/Chats/Channel/Chat/Message/Actions/More';
import { askMessage, setQuestionMessageAnsweredStatus } from 'src/store/actions';

// Helpers
import { isTeacher, isHelper } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  // Props
  const { role, id } = state.users.client;
  const { messageUserId } = ownProps;

  const isMyMessage = messageUserId === id;

  // Does the user has permission to access at these actions ?
  const canEdit = isTeacher(role) || isHelper(role) || isMyMessage;
  const canDelete = canEdit;
  const canAsk = canEdit;

  // Check if navigator support copy command
  const canCopy = document.queryCommandSupported('copy');

  return {
    canEdit,
    canDelete,
    canCopy,
    canAsk,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators(
    {
      askMessage: askMessage(ownProps),
      setQuestionMessageAnsweredStatus: setQuestionMessageAnsweredStatus(ownProps),
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(More);
