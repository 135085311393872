/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';

// Medias stages
import MediasManagement from 'src/components/Medias/Management/container';
import MediasAuthorization from 'src/components/Medias/Authorization';
import MediasDenied from 'src/components/Medias/Denied';

// Hooks
import { useMediasDevices } from 'src/components/Medias/index.hooks';

// Style
import * as S from './index.style';

/*
 * Component
 */
const MediasIntermediateStage = (props) => {
  const { setApplicationState } = props;

  /*
   * Hooks
   */
  const {
    // Values
    localStreamError,
    mediaPermissionStatus,
    status,

    // Actions
    getAuthorization,
  } = useMediasDevices(props);

  /**
   * Loading 🌀
   */
  if (status === 'loading') {
    return (
      <S.Container>
        <Loader overlay size={64} />
      </S.Container>
    );
  }

  /**
   * Authorization granted ✅
   * -> Display the management view to choose our devices
   */
  if (mediaPermissionStatus === 'granted') {
    return <MediasManagement setApplicationState={setApplicationState} />;
  }

  /**
   * We have an error during initialization ❌
   * -> Show the warning view
   */
  if (localStreamError) {
    return (
      <MediasDenied localStreamError={localStreamError} setApplicationState={setApplicationState} />
    );
  }

  /**
   * Request permission to use the user medias 🎥 🎤
   * -> Default view
   */
  if (mediaPermissionStatus === 'prompt' && status === 'requestingPermission') {
    return <MediasAuthorization getAuthorization={getAuthorization} />;
  }

  /**
   *
   */
  return null;
};

/*
 * PropTypes
 */
MediasIntermediateStage.propTypes = {
  cameraId: PropTypes.string,
  microphoneId: PropTypes.string,
  setApplicationState: PropTypes.func.isRequired,
};

MediasIntermediateStage.defaultProps = {
  cameraId: '',
  microphoneId: '',
};

/*
 * Export
 */
export default MediasIntermediateStage;
