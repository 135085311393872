/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local import
 */
import * as types from 'src/store/types';

/**
 * Types
 */
export interface FeedbackProps {
  id: string;
  title: string;
  description: string;
  comment: string;
}

export interface FeedbackState {
  isOpen: boolean;
  select: string;
  comment: string;
  error: boolean;
  loader: boolean;
  errorSend: boolean;
  feedbacks: FeedbackProps[];
}

/*
 * Initial state
 */
const initialState: FeedbackState = {
  isOpen: false,
  select: '',
  comment: '',
  error: false,
  loader: false,
  errorSend: false,
  feedbacks: [],
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_MODAL_FEEDBACK:
        draft.isOpen = action.isOpen;
        break;

      case types.SET_SELECT_FEEDBACK:
        draft.select = action.value;
        break;

      case types.SET_COMMENT_FEEDBACK:
        draft.comment = action.value;
        break;

      case types.SET_ERROR_FEEDBACK:
        draft.error = action.value;
        break;

      case types.FEEDBACK_SEND:
        draft.loader = true;
        break;

      case types.FEEDBACK_SEND_ERROR:
        draft.loader = false;
        draft.errorSend = true;
        break;

      case types.FEEDBACK_SENT:
        draft.errorSend = false;
        draft.loader = false;
        draft.select = '';
        draft.comment = '';
        draft.isOpen = false;
        break;

      case types.RECEIVED_FEEDBACK: {
        const { id, title, comment, description } = action.data;
        const newFeedback = {
          id,
          title,
          description,
          comment,
        };
        draft.feedbacks.push(newFeedback);
        break;
      }

      default:
        return state;
    }
  });
