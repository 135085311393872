/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import SignFollowing from 'src/components/TopBar/Menu/SignFollowing';
import { bindActionCreators } from 'redux';
import { setFollowingModal } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { isFollowingOpen, studentNeedSign } = state.signature;

  return {
    isOpen: isFollowingOpen,
    users: studentNeedSign.map((student) => {
      const user = state.users.userById[student.id];
      return {
        ...user,
        hasSigned: student.hasSigned,
      };
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setFollowingModal }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignFollowing);
