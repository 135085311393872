/*
 * Package Import
 */
import Fuse from 'fuse.js';
import { useState, useMemo } from 'react';

/**
 * useSearch · Filters an array using Fuse.js.
 *
 * @param  {Array} items
 * @param  {Object} options
 * @return {Object}
 */
export const useSearch = ({ items, ...options }) => {
  /*
   * State
   */
  const [term, setTerm] = useState(
    /** @type {String} · Initial term search */
    '',
  );

  /*
   * Options
   */
  const fuseOptions = {
    threshold: 0.5,
    ...options,
  };

  /*
   * Init
   */
  const fuse = useMemo(() => new Fuse(items, fuseOptions), [items, fuseOptions]);

  /*
   * Result · Get results array from `term`
   */
  const result = useMemo(() => {
    const hasTerm = term !== '';

    return hasTerm ? fuse.search(term).map((data) => data.item) : items;
  }, [fuse, term]);

  /*
   * Clear · Reset the state `term`
   */
  const clear = () => setTerm('');

  // Result
  return {
    result,
    term,
    search: setTerm,
    clear,
  };
};
