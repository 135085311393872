/**
 * Package Import
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NewWindow from 'react-new-window';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import { CLASSROOM_SCREEN_TITLE } from 'src/constants/titles';
import { getOrganization } from 'src/store/selectors/organization';
import {
  MediaStreamDictionnary,
  StreamCategory,
} from 'src/utils/webRTCManager/mediaStreamDictionnary';

// Style
import * as S from './style';

/**
 * Component
 */
const Screen = React.forwardRef(
  (
    {
      actions,
      antServer,
      isClientTeacher,
      teacherId,
      transcriptionText,
      transcriptionEnabled,
      userById,
      popoutScreen,
      courseId,
      screenEnabled,
      streamList,
      hasScreen,
    },
    ref,
  ) => {
    /**
     * Ref for video tag
     */
    const videoTag = useRef(null);

    /**
     * Vars
     */
    const { orga, ant_url: ANT_URL } = useSelector(getOrganization);

    const remoteVideoId = `${orga}-${courseId}-${teacherId}-screen`;
    const videoId = isClientTeacher ? 'screenView' : remoteVideoId;

    // Video
    const [isVideoActive, setIsVideoActive] = useState(videoTag.current?.srcObject);

    /**
     * Open the chat in an external window
     */
    const handleExtWindow = () => {
      actions.setPopoutScreen(true);
    };

    /**
     * Close the external window
     */
    const closeExtWindow = () => {
      actions.setPopoutScreen(false);
    };

    /**
     * LifeCycles
     */
    useEffect(() => {
      if (!isClientTeacher) {
        const streamObject = MediaStreamDictionnary.getStream(StreamCategory.SCREEN, videoId);
        setIsVideoActive(!!streamObject);

        if (streamObject && videoTag?.current) {
          videoTag.current.srcObject = streamObject;
        }

        if (!streamObject) {
          if (popoutScreen) {
            closeExtWindow();
          }

          if (videoTag?.current) {
            videoTag.current.srcObject = null;
          }
        }
      }
    }, [streamList, popoutScreen]);

    useEffect(() => {
      // In case the local video isn't disabled by the webRTC service
      if (isClientTeacher) {
        setIsVideoActive(screenEnabled);
        if (!screenEnabled && videoTag.current) {
          videoTag.current.srcObject = null;
        }
      }
    }, [screenEnabled]);

    useEffect(() => {
      if (isClientTeacher && screenEnabled && !hasScreen) {
        actions.sendHasScreen();
      }
    }, [screenEnabled]);

    /**
     * Render
     */
    return (
      <>
        {popoutScreen && (
          <>
            <NewWindow onUnload={closeExtWindow} title={CLASSROOM_SCREEN_TITLE}>
              <S.IframeContainer>
                <iframe
                  width="100%"
                  height="82%"
                  src={`${antServer ? `https://${antServer}.slippers.live` : ANT_URL}/streamScreen/play.html?name=${remoteVideoId}`}
                  frameBorder="0"
                  allowFullScreen
                  title={CLASSROOM_SCREEN_TITLE}
                />
              </S.IframeContainer>
              {!!(transcriptionEnabled && transcriptionText.length) && (
                <S.TranscriptionContainer>
                  <S.TranscriptionBackground>
                    <S.Transcription>
                      {transcriptionText.map((elmt, index) => (
                        <React.Fragment key={elmt.id}>
                          {(!index || transcriptionText[index - 1].userId !== elmt.userId) && (
                            <S.User>
                              {!!index && <br />}[{userById[elmt.userId].name}
                              ]&nbsp;
                            </S.User>
                          )}
                          <S.Text>{elmt.text}&nbsp;</S.Text>
                        </React.Fragment>
                      ))}
                    </S.Transcription>
                  </S.TranscriptionBackground>
                </S.TranscriptionContainer>
              )}
            </NewWindow>
          </>
        )}

        <S.Screen isVideoActive={isVideoActive} popoutIsOpen={popoutScreen} ref={ref}>
          <S.Overlay popoutIsOpen={popoutScreen}>
            <S.InfoText>Lecture en cours dans une autre fenêtre</S.InfoText>
            <S.Button onClick={closeExtWindow}>Ramener le partage d&apos;écran</S.Button>
          </S.Overlay>
          {!popoutScreen && (
            <>
              <S.Video id={videoId} autoPlay playsInline muted ref={videoTag} />
              {!isClientTeacher && isVideoActive && (
                <Tooltip content={<>Ouvrir le partage d&apos;écran dans une nouvelle fenêtre</>}>
                  <S.ButtonExt id="popout-screen" type="button" onClick={handleExtWindow}>
                    <S.IconExternalWindow />
                  </S.ButtonExt>
                </Tooltip>
              )}
            </>
          )}
        </S.Screen>
      </>
    );
  },
);

/**
 * PropTypes
 */
Screen.propTypes = {
  antServer: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Is current user a teacher ? */
  isClientTeacher: PropTypes.bool.isRequired,
  /** Course id */
  courseId: PropTypes.string.isRequired,
  /** Course teacher id */
  teacherId: PropTypes.string.isRequired,
  /** Transcripton text  */
  transcriptionText: PropTypes.array.isRequired,
  /** is transcription enabled ? */
  transcriptionEnabled: PropTypes.bool.isRequired,
  userById: PropTypes.object.isRequired,
  /* popout is open or not */
  popoutScreen: PropTypes.bool.isRequired,
  /** Screen share has already been made at least once in the course */
  hasScreen: PropTypes.bool.isRequired,
  screenEnabled: PropTypes.bool.isRequired,
  streamList: PropTypes.array.isRequired,
};

Screen.defaultProps = {
  antServer: null,
};

/**
 * Export
 */
export default Screen;
