/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { useDeactivateCourse } from 'src/serverStateManagers/mutations/deactivate/Courses/useDeactivateCourse';
import { MongoId } from 'src/schemas/Entities/utils';
import { useHistory } from 'react-router-dom';
import { useGetCourses } from 'src/serverStateManagers/queries/Courses/useGetCourses';
import { getRoute } from 'src/utils/routes';
import { toast } from 'react-toastify';

/*
 * Code
 */
type DeactivateProps = {
  isOpen: boolean;
  idToDeactivate: MongoId | null;
};

const Deactivate = ({ isOpen, idToDeactivate }: DeactivateProps): JSX.Element => {
  const deactivateCourseMutation = useDeactivateCourse();
  const history = useHistory();

  const { data: coursesData } = useGetCourses();
  const courseData = coursesData?.find((course) => course.id === idToDeactivate);

  useEffect(() => {
    if (courseData?.deactivatedAt) {
      history.push(getRoute('coursesTable').getUrl({}));
    }
  }, [courseData?.deactivatedAt, history]);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    history.push(getRoute('coursesTable').getUrl({}));
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    if (courseData?.deactivatedAt) {
      toast('Cours déjà archivé', { type: 'warning' });
      history.push(getRoute('promotionsTable').getUrl({}));
      return;
    }
    if (idToDeactivate) {
      deactivateCourseMutation.mutate(idToDeactivate);
    }
    history.push(getRoute('coursesTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit}>
        <F.DeactivateHeader>Archiver</F.DeactivateHeader>
        <F.DeactivateLabel>Êtes-vous sûr de vouloir archiver ce cours ?</F.DeactivateLabel>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button type="submit" variant="danger" onClick={handleSubmit} fullWidth>
            Archiver
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default Deactivate;
