/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, Payload } from 'src/utils/api';
import { Course } from 'src/schemas/Entities/Course';
import { transformCoursesDTOToCourses } from 'src/schemas/Adapters/Courses/transformCourseDTOToCourse';
import { API_FIND_COURSES, API_GET } from 'src/constants/apiRoutes';
import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
export const fetchCourses = async (): Promise<Course[]> => {
  try {
    const payload: Payload = {
      method: API_GET,
      url: API_FIND_COURSES,
    };
    const { data: coursesData } = await api(payload);

    const { courses, errors } = transformCoursesDTOToCourses(coursesData);

    if (errors.length) {
      const errorsMessages = errors.map(
        (errorWithData) =>
          `Course with title ${errorWithData.data.title} validation problem : ${
            errorWithData.error.message
          }.
          Click here to correct : ${window.location.origin}${getRoute('coursesUpdate').getUrl({
  id: errorWithData.data.id,
})}`,
      );
      // eslint-disable-next-line no-console
      console.error(`${errorsMessages.join('\n')}\n Please contact support`);
      toast(
        'Nous avons détecté des erreurs de validation sur les données reçues, le détail se trouve dans la console',
        { type: 'warning', toastId: 'query' },
      );
    }
    return courses;
  }
  catch (error) {
    toast(`Erreur pendant le chargement des données : ${error}`, {
      type: 'error',
      toastId: 'fetch',
    });
    throw error;
  }
};
