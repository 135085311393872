/*
 * Package Import
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { getFormatRelative, getFormatRelativeFull, getFormatFullTime } from 'src/utils/time';
import { Time } from './style';

/*
 * Component
 */
const TimestampPreview = ({ time }) => {
  /**
   * whenRelative
   * @type {[type]}
   */
  const whenRelative = useMemo(() => getFormatRelative(time), [time]);

  /**
   * whenRelativeFull
   * @type {[type]}
   */
  const whenRelativeFull = useMemo(() => getFormatRelativeFull(time), [time]);

  /*
   * Render
   */
  if (!whenRelative) {
    return null;
  }

  return (
    <Time dateTime={getFormatFullTime(time)} title={whenRelativeFull}>
      {whenRelative}
    </Time>
  );
};

/*
 * PropTypes
 */
TimestampPreview.propTypes = {
  /** Message timestamp */
  time: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default TimestampPreview;
