/*
 * Package Import
 */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';

// Queries
import { fetchURL } from 'src/serverStateManagers/queries/VM/fetchURL';
import { notFoundError } from 'src/serverStateManagers/queries/VM/utils';
import { useGetUsers } from 'src/serverStateManagers/queries/Users/useGetUsers';

// Schemas
import { MongoId } from 'src/schemas/Entities/utils';

// Helpers
import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
type VmProps = {
  isOpen: boolean;
  idToConnect: MongoId | null;
};

const Vm = ({ isOpen, idToConnect }: VmProps): JSX.Element => {
  const history = useHistory();
  const { data: usersData } = useGetUsers();
  const userToConnect = usersData?.find((user) => user.id === idToConnect);

  const {
    data: urlData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useQuery(['vmURL', userToConnect?.id], () => fetchURL(userToConnect), {
    enabled: !!userToConnect,
  });

  useEffect(() => {
    if (isError) {
      toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [isError, error]);

  const [isCopied, setIsCopied] = useState(false);

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    setIsCopied(false);
    history.push(getRoute('usersTable').getUrl({}));
  };

  const handleCopy = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    if (urlData && urlData !== notFoundError.status.toString()) {
      navigator.clipboard.writeText(urlData).then(
        () => {
          setIsCopied(true);
        },
        () => {
          toast("L'élément n'a pas pu être copié", { type: 'error' });
        },
      );
    }
  };

  const handleSubmit = (): void => {
    if (urlData && urlData !== notFoundError.status.toString()) {
      window.open(urlData);
    }
    history.push(getRoute('usersTable').getUrl({}));
  };

  useEffect(() => {
    if (userToConnect && userToConnect.role !== 'basic') {
      history.push(getRoute('usersTable').getUrl({}));
    }
  }, [history, userToConnect]);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <>
        <F.ModalTitle>
          Accéder à la VM {userToConnect ? `de ${userToConnect.name}` : ''}
        </F.ModalTitle>
        <F.VmUrlContainer>
          {isSuccess
            && (urlData !== notFoundError.status.toString() ? (
              <F.VmUrl>{urlData}</F.VmUrl>
            ) : (
              <p>{notFoundError.message}</p>
            ))}
          {isSuccess && urlData !== notFoundError.status.toString() && (
            <Tooltip content={isCopied ? 'Copié' : 'Copier le lien'}>
              {isCopied ? (
                <F.Check>
                  <F.IconCheck />
                </F.Check>
              ) : (
                <F.CopyButton onClick={handleCopy}>
                  <F.IconClipboard />
                </F.CopyButton>
              )}
            </Tooltip>
          )}
          {isLoading && 'Chargement...'}
          {isError && "Une erreur s'est produite"}
        </F.VmUrlContainer>
        <F.Buttons>
          <Button type="button" variant="secondary" onClick={handleClose} fullWidth>
            Annuler
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={urlData === notFoundError.status.toString() || isLoading}
            onClick={handleSubmit}
            fullWidth
          >
            Se connecter
          </Button>
        </F.Buttons>
        <F.CloseButton onClick={handleClose}>
          <F.CloseIcon />
        </F.CloseButton>
      </>
    </Modal>
  );
};

/*
 * Export
 */
export default Vm;
