/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Spoil from 'src/components/Chats/Channel/Chat/Message/Content/Spoil';
import { isEducator } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  openedByDefault: isEducator(state?.users?.client?.role),
});

/*
 * Export
 */
export default connect(mapStateToProps)(Spoil);
