/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Package Import
 */
import React, { useContext, useMemo } from 'react';

/**
 * Local Import
 */
import { ACCOUNT_URL, APPLICATIONS_URL } from 'src/constants/routes';
import { UserContext } from 'src/context/User';
import ROLES from 'src/constants/roles';

import * as S from './style';

/**
 * Component
 */
const SettingsNavigation = () => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  const isUserSuperAdmin = useMemo(
    () => user && user.role === ROLES.ROLE_SUPER_ADMIN,
    [user?.role],
  );

  return (
    <S.NavigationContainer>
      <S.Link to={ACCOUNT_URL}>Mon compte</S.Link>
      {user && isUserSuperAdmin && <S.Link to={APPLICATIONS_URL}>Mes applications</S.Link>}
    </S.NavigationContainer>
  );
};

/**
 * Export
 */
export default SettingsNavigation;
