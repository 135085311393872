/* eslint-disable @typescript-eslint/naming-convention */
/*
 * Package Import
 */
import axios, { AxiosResponse, Method } from 'axios';

import SlippersApi from 'src/context/Keycloak/SlippersApi';

/*
 * Code
 */

export type QueryPayload = {
  method: Method;
  url: string;
  params?: unknown;
};

export type MutationPayload = {
  method: Method;
  url: string;
  data: unknown;
};

export type Payload = QueryPayload | MutationPayload;

/**
 * Promise Api
 * @param  {String|Object} Request
 * String : By default `axios.create` send request with method `GET`,
 *          So, we just need to pass URL.
 *          e.g. : api('/receive/datas')
 *
 * Object : If we send a request with method `POST`
 *          We need to pass an object with many params.
 *          e.g. : api({
 *            method: 'POST',
 *            url: '/send/datas',
 *            data: { email },
 *          })
 *
 * @return {String} Return an error if request failed.
 */
export const api = async (request: Payload): Promise<AxiosResponse> => {
  const api_url = SlippersApi.apiUrl;
  const token = SlippersApi.keycloakInstance?.token;

  // No token in keycloak ?
  if (!token) {
    throw new Error('Unauthorized');
  }

  const req = axios.create({
    baseURL: api_url,
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: true,
  });

  return req(request).catch((error) => {
    throw error;
  });
};
