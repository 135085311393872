/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Message from 'src/components/Chats/Channel/Chat/Input/Message';
import { isTyping, sendMessage, askSurvey, errorCreatingSurvey } from 'src/store/actions';
import { isEducator, isHelper, isTeacher } from '../../../../../../utils/roles';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  // Props
  const { role } = state.users.client;

  // Does the user has permission to access at these actions ?
  const canPin = isTeacher(role) || isHelper(role);

  return {
    context: state.chats.byId[ownProps.chatId]?.kind,
    canAskSurvey: state.chats.currentView === 'general' && isEducator(role),
    canPin,
  };
};

// Actions
const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators(
    {
      isTyping: isTyping(ownProps.chatId),
      sendMessage,
      askSurvey: askSurvey({ chatId: ownProps.chatId }),
      errorCreatingSurvey,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Message);
