/**
 * Types
 */
interface StreamsInterface {
  [streamId: string]: MediaStream;
}

export enum StreamCategory {
  CAM = 'cam',
  SCREEN = 'screen',
}

/**
 * Media stream dictionnary
 */
export class MediaStreamDictionnary {
  private static camStreams: StreamsInterface = {};

  private static screenStreams: StreamsInterface = {};

  private static globalContext: AudioContext;

  private static analyserNodes: { [streamId: string]: AnalyserNode } = {};

  static streamVolumes: { [streamId: string]: number } = {};

  static animationFrameId?: number;

  static resetStreams = (): void => {
    this.camStreams = {};
    this.screenStreams = {};
  };

  static addStream = (type: StreamCategory, streamId: string, mediaStream: MediaStream): void => {
    if (type === StreamCategory.CAM) {
      this.camStreams[streamId] = mediaStream;
      this.attachAnalyserNode(streamId, mediaStream);
      this.startUpdateVolumesLoop();
    }
    else if (type === StreamCategory.SCREEN) {
      this.screenStreams[streamId] = mediaStream;
    }
    else {
      throw new Error('Unknown stream type');
    }
  };

  static removeStream = (streamId: string): void => {
    // no need to test, as delete does not throw any error if the key does not exist
    delete this.camStreams[streamId];
    delete this.screenStreams[streamId];
    this.detachAnalyserNode(streamId);
  };

  static getAllStreams = (type: StreamCategory): StreamsInterface => {
    if (type === StreamCategory.CAM) {
      return this.camStreams;
    }
    if (type === StreamCategory.SCREEN) {
      return this.screenStreams;
    }
    throw new Error('Unknown stream type');
  };

  static getStream = (type: StreamCategory, streamId: string): MediaStream | undefined => {
    if (type === StreamCategory.CAM) {
      return this.camStreams[streamId];
    }
    if (type === StreamCategory.SCREEN) {
      return this.screenStreams[streamId];
    }
    return undefined;
  };

  static getAllStreamIds = (type: StreamCategory): string[] => {
    if (type === StreamCategory.CAM) {
      return Object.keys(this.camStreams);
    }
    if (type === StreamCategory.SCREEN) {
      return Object.keys(this.screenStreams);
    }
    throw new Error('Unknown stream type');
  };

  private static checkGlobalContext = (): void => {
    if (!this.globalContext) {
      this.globalContext = new AudioContext();
    }

    if (this.globalContext.state === 'suspended') {
      this.globalContext.resume();
    }
  };

  static attachAnalyserNode = (streamId: string, stream: MediaStream): void => {
    // always check context state before creating a new node
    this.checkGlobalContext();
    this.detachAnalyserNode(streamId);

    const analyser = MediaStreamDictionnary.globalContext.createAnalyser();
    const source = MediaStreamDictionnary.globalContext.createMediaStreamSource(stream);

    analyser.fftSize = 32;
    source.connect(analyser);

    this.analyserNodes[streamId] = analyser;
  };

  static detachAnalyserNode = (streamId: string): void => {
    if (this.analyserNodes[streamId]) {
      this.analyserNodes[streamId].disconnect();
      delete this.analyserNodes[streamId];
    }
  };

  static startUpdateVolumesLoop = (): void => {
    // start the loop only if its not started yet
    if (!this.animationFrameId) {
      this.animationFrameId = window.requestAnimationFrame(MediaStreamDictionnary.updateVolumes);
    }
  };

  static updateVolumes = () => {
    Object.keys(this.analyserNodes).forEach((streamId) => {
      // Visual
      const analyser = this.analyserNodes[streamId];
      const tableauDonnees = new Uint8Array(analyser.frequencyBinCount);

      // Draw
      analyser.getByteFrequencyData(tableauDonnees);
      const max = Math.max(...tableauDonnees);
      this.streamVolumes[streamId] = max;
    });

    this.animationFrameId = window.requestAnimationFrame(this.updateVolumes);
  };
}
