/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import AudioLevel from 'src/components/Course/AudioLevel';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  clientId: state.users.client.id,
  courseId: state.course.courseId,
});

// Actions
const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(AudioLevel);
