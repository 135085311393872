/*
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Style
 */
export const AudioLevelSample = styled.div(
  ({ theme }) => ({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    boxShadow: `inset 0 0 0 3px ${theme.primary._1}`,
    borderRadius: '8px',
    transformOrigin: 'center center',
    pointerEvents: 'none',
  }),
  ({ volume }) =>
    volume && {
      opacity: `${volume >= 125 ? 1 : 0}`,
    },
);
