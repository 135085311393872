import { createSelector } from 'reselect';
import { RootState } from 'src/store';

/**
 * Gets saved input state by chatId
 * @param state
 * @param chatId
 * @returns [object]
 */
export const getInputContent = createSelector(
  (state: RootState) => state.inputs,
  (state: RootState, chatId: string) => chatId,
  (inputs, chatId) => inputs[chatId]?.input || null,
);
