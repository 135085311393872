/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Mugshot = styled.div(({ size }) => ({
  position: 'relative',
  height: `${size / 16}rem`,
  width: `${size / 16}rem`,
  marginRight: '.25rem',
}));
