/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Avatar = styled.picture(
  ({ away, connected, statusSize, size, theme, showConnected }) => ({
    position: 'relative',
    borderRadius: '8px',
    minWidth: size ? `${size / 16}rem` : '3rem',
    width: size ? `${size / 16}rem` : '3rem',
    height: size ? `${size / 16}rem` : '3rem',
    backgroundColor: theme.background._7,

    // Status circle
    ':before': showConnected && {
      content: '""',
      height: statusSize ? `${statusSize}rem` : '0.75rem',
      width: statusSize ? `${statusSize}rem` : '0.75rem',
      borderRadius: '50px',
      right: statusSize ? '2px' : '0',
      border: `1px solid ${theme.background._2}`,
      position: 'absolute',
      bottom: statusSize ? '2px' : '0',
      ...(connected && { backgroundColor: !away ? theme.success.main : theme.danger.main }),
      ...(!connected && { backgroundColor: theme.colors.coolGrey._500 }),
    },
  }),
);

export const Img = styled.img`
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
