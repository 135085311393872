/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Picker as EmojiPicker } from 'emoji-mart';

/*
 * Local Import
 */
import { i18n } from 'src/components/Format/Formatizer/components/Picker/data';

/*
 * Component
 */
const Picker = React.forwardRef(({ onChange }, ref) => {
  const handleChange = (...args) => onChange(...args);
  return (
    <EmojiPicker
      onSelect={handleChange}
      set="apple"
      emojiTooltip
      title=""
      i18n={i18n}
      autoFocus
      showSkinTones={false}
      ref={ref}
      sheetSize={64}
      backgroundImageFn={(set, sheetSize) =>
        `https://unpkg.com/emoji-datasource-${set}@5.0.1/img/${set}/sheets-256/${sheetSize}.png`}
    />
  );
});

/*
 * PropTypes
 */
Picker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default Picker;
