/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local import
 */
// styles
import * as S from './style';

export default function PositionedSnackbar({
  snackIsOpen,
  snackMessageTitle,
  snackMessageInfo,
  severity,
  actions,
}) {
  /*
   * Handlers
   */
  const handleClose = () => {
    actions.closeSnackNotif();
  };

  const Icon = () => {
    switch (severity) {
      case 'success':
        return <S.IconSuccess severity={severity} />;
      case 'info':
        return <S.IconInfo severity={severity} />;
      case 'warning':
        return <S.IconWarning severity={severity} />;
      case 'danger':
        return <S.IconDanger severity={severity} />;
      default:
        return null;
    }
  };

  /*
   * Render
   */
  return (
    <S.Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={snackIsOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <S.Alert onClose={handleClose} severity={severity}>
        <Icon severity={severity} />
        <S.MessageContainer>
          <S.Title>{snackMessageTitle}</S.Title>
          <S.Description role="status">{snackMessageInfo}</S.Description>
        </S.MessageContainer>
        <S.Close onClick={handleClose}>Fermer</S.Close>
      </S.Alert>
    </S.Snackbar>
  );
}

PositionedSnackbar.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Dispaly snackbar notification */
  snackIsOpen: PropTypes.bool.isRequired,
  /** Title of snakbar alert */
  snackMessageTitle: PropTypes.string.isRequired,
  /** Content of snakbar alert */
  snackMessageInfo: PropTypes.string.isRequired,
  /** If snakbar alert is success - info - warning or danger (error) */
  severity: PropTypes.string,
};

PositionedSnackbar.defaultProps = {
  severity: undefined,
};
