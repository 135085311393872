import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import type { User } from 'src/schemas/Entities/User';
import type { Option } from 'src/components/Admin/Modals/FormElements/type';
import type { Promotion } from 'src/schemas/Entities/Promotion';
import type { Course, UserRelation } from 'src/schemas/Entities/Course';
import {
  findUserAndCheckIfPeda,
  findPromotionAndCheckCourseDate,
  CourseFormDataPartialStatic,
  findPromotionAndCheckIfActive,
  findUserAndCheckIfActive,
} from 'src/schemas/Refines/Validations/courses';
import { findSimultaneousTimeslotsAndCheckLimitation } from 'src/schemas/Refines/Validations/courses/limitations/checkSimultaneousCoursesLimitation';
import { checkCoursesHoursLimitation } from 'src/schemas/Refines/Validations/courses/limitations/checkCoursesHoursLimitation';
import { getHoursOrMinutes, Slot } from 'src/components/Admin/Modals/FormElements/Timeslot/utils';
import { timeStringToNumberValue } from 'src/utils/time';
import { TimeString } from '../Entities/utils';
import { LimitationDTO } from '../Entities/Limitation';

export const courseTeacherMustBePeda = (teacher: UserRelation): boolean => {
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  return findUserAndCheckIfPeda(teacher, usersData);
};

export const courseHelpersMustBePeda = (helpers: (UserRelation | null)[]): boolean => {
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  const helpersNotNull = helpers.filter((helper) => helper !== null) as UserRelation[];
  const usersIsPeda = helpersNotNull.map((helper) => findUserAndCheckIfPeda(helper, usersData));
  return usersIsPeda.every((check) => check);
};

export const courseFormUserMustBePeda = (user: Option | null): boolean => {
  if (user === null) return false;
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  return findUserAndCheckIfPeda(user, usersData);
};

export const courseFormDateMustBeInPromotionDates = (
  data: CourseFormDataPartialStatic,
): boolean => {
  const promotionsData: Promotion[] | undefined = queryClient.getQueryData('promotions');
  return findPromotionAndCheckCourseDate(data, promotionsData);
};

export const courseFormPromotionMustBeActive = (promotionOption: Option | null): boolean => {
  const promotionsData: Promotion[] | undefined = queryClient.getQueryData('promotions');
  return findPromotionAndCheckIfActive(promotionOption, promotionsData);
};

export const courseFormUserMustBeActive = (userOption: Option | null): boolean => {
  const usersData: User[] | undefined = queryClient.getQueryData('users');
  return findUserAndCheckIfActive(userOption, usersData);
};

export const courseTimeslotMustBeFilled = (timeslot: Slot | null): boolean =>
  !(!timeslot?.start && !timeslot?.end);

export const courseTimeslotStartMustBeFilled = (timeslot: Slot | null): boolean =>
  !(!timeslot?.start && timeslot?.end);

export const courseTimeslotEndMustBeFilled = (timeslot: Slot | null): boolean =>
  !(timeslot?.start && !timeslot?.end);

// Min timeslot is 00:00
export const isCourseTimeslotMinZero = (startInput: TimeString): boolean =>
  getHoursOrMinutes(startInput, 'hours') >= 0 && getHoursOrMinutes(startInput, 'minutes') >= 0;

// Max timeslot is 23:59
export const isCourseTimeslotLessThanTwentyFour = (startInput: TimeString): boolean =>
  getHoursOrMinutes(startInput, 'hours') <= 23 && getHoursOrMinutes(startInput, 'minutes') <= 59;

export const isCourseFormTimeslotExceedSimultaneousCourseLimitation = (
  data: CourseFormDataPartialStatic,
): { index: number; intervals: Interval[]; limitationQuantity: number }[] => {
  const coursesData: Course[] | undefined = queryClient.getQueryData('courses');
  const limitationsData: LimitationDTO | undefined = queryClient.getQueryData('limitations');
  return findSimultaneousTimeslotsAndCheckLimitation({ data, coursesData, limitationsData });
};

export const isSimultaneousCourseLimitationIsZero = () => {
  const limitationsData: LimitationDTO | undefined = queryClient.getQueryData('limitations');
  const simultaneousCoursesLimitation = limitationsData?.find(
    (limitationData) => limitationData.feature === 'simultaneousCourses',
  );
  return simultaneousCoursesLimitation?.quantity === 0;
};

export const areTimeslotsOverlaping = (timeslots: Slot[]): string[] => {
  const resultErrors: string[] = [];

  // Check for each elements if he's upper than previous
  timeslots.forEach(({ start, end }, index) => {
    const isValid = timeslots.every((otherTimeslot) => {
      const startFormatted = timeStringToNumberValue(start);
      const endFormatted = timeStringToNumberValue(end);

      const otherStartFormatted = timeStringToNumberValue(otherTimeslot.start);
      const otherEndFormatted = timeStringToNumberValue(otherTimeslot.end);

      return !(
        (startFormatted > otherStartFormatted && startFormatted < otherEndFormatted)
        || (endFormatted > otherStartFormatted && endFormatted < otherEndFormatted)
      );
    });

    if (!isValid) {
      resultErrors.push(index.toString());
    }
  });

  return resultErrors;
};

export const isTimeslotStartBeforeEnd = ({ start, end }: Slot): boolean => {
  const startFormatted = timeStringToNumberValue(start);
  const endFormatted = timeStringToNumberValue(end);

  return startFormatted < endFormatted;
};

/**
 * Limitation • Maximum hours course per month
 */
export const isMonthlyHoursCoursesLimitReached = (courseData: CourseFormDataPartialStatic) => {
  const coursesData: Course[] | undefined = queryClient.getQueryData('courses');
  const limitationsData: LimitationDTO | undefined = queryClient.getQueryData('limitations');

  return checkCoursesHoursLimitation({ courseData, coursesData, limitationsData });
};
