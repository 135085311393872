/*
 * Package Import
 */

/*
 * Local Import
 */
import { UserDTO, UserFormDataValidated } from 'src/schemas/Entities/User';
import { MongoId } from 'src/schemas/Entities/utils';
import { transformUsersFormDataToUsersDTO } from 'src/schemas/Adapters/Users/transformUserFormDataToUserDTO';

/*
 * Code
 */
export const setUsersNewData = (users: UserDTO[], promotionId: MongoId): UserDTO[] => {
  const usersWithNewData = users.map((user) => {
    const promotionIndex = user.promosIds.indexOf(promotionId);
    // If user have already current promotion, it's a removed user, so let's remove promotion
    // Else it's a selected user, so let's add promotion
    if (promotionIndex >= 0) {
      user.promosIds.splice(promotionIndex, 1);
    }
    else {
      user.promosIds.push(promotionId);
    }
    return user;
  });
  return usersWithNewData;
};

export const transformUsersAndSetNewData = (
  updatePromotionUsersFormData: {
    selectedUsersValidated: UserFormDataValidated[];
    removedUsersValidated: UserFormDataValidated[];
  },
  promotionId: MongoId,
): UserDTO[] => {
  const transformedUsersWithNewData = (
    Object.keys(updatePromotionUsersFormData) as Array<keyof typeof updatePromotionUsersFormData>
  )
    .map((key) => {
      // For each kind of users (selected and removed)
      // Transform users in form data to usersDTO
      const transformedUsers = transformUsersFormDataToUsersDTO(updatePromotionUsersFormData[key]);
      // Set new data
      const usersWithNewData = setUsersNewData(transformedUsers, promotionId);
      return usersWithNewData;
    })
    .flat();
  return transformedUsersWithNewData;
};
