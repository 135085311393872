/**
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Styles
 */
export const Container = styled.div(({ theme }) => ({
  border: `1px solid ${theme.text._7}`,
  borderRadius: '8px',
  padding: '1rem',
  height: '21rem',
  minWidth: '39.5rem',
  overflow: 'auto',
  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));

export const Header = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
}));

export const Title = styled.div(({ theme }) => ({
  color: theme.text._1,
  ...theme.typo.headlines.H4,
}));

export const TimeslotLabel = styled.div(({ theme }) => ({
  ...theme.typo.labels.label,
  marginBottom: '0.25rem',
}));

export const InputsContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TimeInput = styled.input(({ theme }) => ({
  padding: '.5rem .75rem',
  border: `1px solid ${theme.text._7}`,
  borderRadius: '8px',
  background: theme.background._2,
  color: theme.text._1,
  fontFamily: 'Inter',
  ...theme.typo.bodyText.body,

  '&.is-error': {
    border: `2px solid ${theme.danger.main}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
}));

export const InterBlock = styled.div(() => ({
  margin: '0 .75rem',
}));

export const Duration = styled.div(({ theme }) => ({
  ...theme.typo.bodyText.smallBody,
  color: theme.text._5,
  margin: '0 1rem',
}));

interface TimeSlotContainerProps {
  isErrors: boolean;
}

export const TimeslotContainer = styled.div(({ isErrors }: TimeSlotContainerProps) => ({
  marginBottom: isErrors ? '.75rem' : '2.25rem',

  ':last-child': {
    marginBottom: 0,
  },
}));
