/**
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { StudentList } from '../../style';

/**
 * Style
 */
export const ListDisplayContainer = styled('div')(({ theme, viewChosen }) => ({
  height: '100%',
  width: '100%',
  color: theme.text._1,
  overflowY: 'scroll',
  paddingTop: '0.5rem',
  paddingRight: 'calc(1rem - 4px)', // substract scrollbar width
  paddingBottom: '1rem',

  scrollbarColor: `${theme.background._7} transparent`,
  scrollbarWidth: 'thin',

  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '4px',
    height: '32px',
  },

  ...(viewChosen === 'grid' && {
    [StudentList]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, auto)',
      gridGap: '0.8rem',
    },
  }),
}));
