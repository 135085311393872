/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { UilSetting } from '@iconscout/react-unicons';
import { applyStatics, MenuItem, SubMenu } from '@szhsin/react-menu';

/*
 * Local Import
 */
import MenuHelp from './SubMenus/MenuHelp/container';
import MenuLayout from './SubMenus/MenuLayout/container';
import MenuNotifications from './SubMenus/MenuNotifications/container';

// Style
import { Button } from '../style';
import * as S from './style';

// HOC to import component and declare them as MenuItem
// https://szhsin.github.io/react-menu/docs#utils-apply-statics
applyStatics(SubMenu)(MenuHelp);
applyStatics(SubMenu)(MenuLayout);
applyStatics(SubMenu)(MenuNotifications);

/*
 * Component
 */
const MainMenu = ({ actions }) => {
  /*
   * Handlers
   */
  const handleOpenAway = () => {
    actions.setModalAway({ isOpen: true });
  };

  /*
   * Render
   */
  return (
    <S.Menu
      portal
      menuButton={(
        <div id="menuLayout" aria-label="Ouvrir le menu des options">
          <Button>
            <UilSetting aria-hidden="true" />
          </Button>
        </div>
      )}
    >
      <MenuItem onClick={handleOpenAway}>
        <S.ContainerLabel>
          <S.IconCoffee aria-hidden />
          &nbsp; Faire une pause
        </S.ContainerLabel>
      </MenuItem>
      <MenuLayout />
      <MenuNotifications />
      <MenuHelp />
    </S.Menu>
  );
};

/*
 * Proptypes
 */
MainMenu.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
};

/*
 * Export
 */
export default MainMenu;
