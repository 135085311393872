/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Composer from 'src/components/Chats/Channel/Chat/Input/Composer';

// Helpers
import { openModalSurvey } from 'src/store/actions';
import { isHelper, isTeacher } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { role } = state.users.client;

  // Can the user access these action (ask survey, share a document, ...) ?
  const canAskSurvey = isTeacher(role) || isHelper(role);
  const canShare = canAskSurvey;

  return {
    canAskSurvey,
    canShare,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ openModalSurvey }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Composer);
