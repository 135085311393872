/**
 * Package Import
 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import { useSurvey } from 'src/components/Classroom/hooks';
import * as P from '../style';
import * as S from './style';

/**
 * Component
 */
const Respond = ({ actions, answers, multiple, surveyId, surveyIndex, time, userId }) => {
  const { voteSurvey } = useSurvey();

  /*
   * State
   */
  const [values, setValues] = React.useState(
    /** @type {Array} · Default user response */
    [],
  );

  /**
   * onChange
   * @param  {MouseEvent || Number} param
   * @return {void}
   */
  const onChange = (param) => {
    let res;

    const value = typeof param === 'number' ? param : Number(param.target.value);

    if (multiple) {
      // If the value already exist in the values array,
      // -delete it. Otherwise, +add it
      res = values.includes(value)
        ? values.filter((val) => val !== value)
        : [...values, value].sort();
    }
    else {
      res = [value];
    }

    // Hydrate state
    setValues(res);
  };

  /**
   * Submit our answers of the survey
   * @return {void}
   */
  const onSubmit = useCallback(() => {
    const payload = { surveyId, answersIds: values, time, userId };

    actions.voteSurvey(payload);
    voteSurvey(payload);
  }, [time, userId, values, voteSurvey]);

  return (
    <P.Answers>
      <S.Form>
        <S.AnswersContainer role="radiogroup" aria-labelledby={surveyId}>
          {/* Answers */}
          {answers.length
            ? answers.map((answer, index) => (
              <S.InputContainer key={`key-respond-survey-${surveyId}-${answer.id}`}>
                <S.Input
                  id={`survey-${surveyId}-${index}`}
                  data-testid={`respond-survey-${surveyId}-answer-${index}`}
                  type={multiple ? 'checkbox' : 'radio'}
                  checked={values.includes(answer.id)}
                    // React
                  value={answer.id}
                  onChange={onChange}
                />
                <S.LabelAnswer htmlFor={`survey-${surveyId}-${index}`}>
                  {answer.content}
                </S.LabelAnswer>
              </S.InputContainer>
            ))
            : null}
        </S.AnswersContainer>
      </S.Form>

      {/* Send button */}
      <S.Button
        type="button"
        data-testid="respond-survey-submit"
        aria-label={`Envoyer mes réponses pour le sondage ${surveyIndex}`}
        onClick={onSubmit}
        disabled={!values?.length}
        isMain
      >
        Envoyer
      </S.Button>
    </P.Answers>
  );
};

/*
 * PropTypes
 */
Respond.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Answers of the survey */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      votes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  ).isRequired,
  /** Can we vote at mutliple answer ? */
  multiple: PropTypes.bool,
  /** ID of the survey */
  surveyId: PropTypes.string.isRequired,
  /** Index of the survey */
  surveyIndex: PropTypes.number.isRequired,
  /** Survey creation time */
  time: PropTypes.number.isRequired,
  /** User (connected) ID */
  userId: PropTypes.string.isRequired,
};

Respond.defaultProps = {
  multiple: false,
};

/**
 * Export
 */
export default Respond;
