/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*
 * Local Import
 */
import Header from 'src/components/Admin/Header';
import UserTable from 'src/components/Admin/Pages/User/UserTable';

// Helpers
import { trackEvent } from 'src/utils/tracking';
import { ADMIN_USERS_TITLE } from 'src/constants/titles';

// Style
import * as S from './style';

/*
 * Code
 */
const UserPage = (): JSX.Element => {
  /*
   * Hooks
   */
  const { pathname } = useLocation();

  /**
   * LifeCycles
   */
  useEffect(() => {
    document.title = ADMIN_USERS_TITLE;

    // Mixpanel tracking event
    trackEvent('Page Viewed', {
      title: ADMIN_USERS_TITLE,
      url: pathname,
    });
  }, []);

  /**
   * Render
   */
  return (
    <S.Container>
      <Header pageTitle="Gestion des utilisateurs" />
      <UserTable />
    </S.Container>
  );
};

/*
 * Export
 */
export default UserPage;
