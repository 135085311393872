/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as S from '../index.style';

/*
 * Component
 */
const MediasAuthorization = ({ getAuthorization }) => (
  <S.Container>
    <S.Alert>
      <S.IconContainer>
        <S.Setting aria-hidden="true" />
      </S.IconContainer>
      <S.Title>Accès au micro et à la webcam</S.Title>
      <S.Description>
        Vérifiez les accès de votre navigateur avant de rejoindre la salle de classe
      </S.Description>
      {/* <S.Help href="#">Trouver de l’aide</S.Help> */}
      <S.Button
        isMain
        type="button"
        data-testid="medias-authorization-button"
        onClick={getAuthorization}
        aria-label="Demander l'autorisation d'utiliser votre caméra et votre microphone"
      >
        Demander les autorisations
      </S.Button>
    </S.Alert>
  </S.Container>
);

/*
 * PropTypes
 */
MediasAuthorization.propTypes = {
  getAuthorization: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default MediasAuthorization;
