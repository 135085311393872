import { CommandProps } from './types';

export const commandPoll = (props: CommandProps) => {
  const { actions, canAskSurvey, commandName, isMultiple, value } = props;

  if (!canAskSurvey) {
    actions.errorCreatingSurvey?.('Vous ne pouvez pas créer de sondage');

    return false;
  }

  const { insert } = value.ops[0];

  // Split string to obtain all lines
  const lines = insert.split('\n');
  // Remove last entry (QuillJS add an empty line at the end)
  lines.pop();
  // Separate question and remove command text
  const question = lines.shift()?.replace(`/${commandName}`, '').trim();

  // Format all lines in answer
  const answers = (lines as string[])
    .filter((answer) => answer !== '')
    .map((answer, index) => ({ id: index, content: answer, votes: [] }));

  // Check if we've a question ...
  if (!question.length) {
    actions.errorCreatingSurvey?.(
      `Vous devez rédiger la question du sondage après "/${commandName}"`,
    );
    return false;
  }

  // ... and have answer
  if (answers.length < 1) {
    actions.errorCreatingSurvey?.(
      'Vous devez ajouter des réponses en revenant à la ligne avec Shift + Entrée',
    );
    return false;
  }

  // ... and more than 1 answer
  if (answers.length < 2) {
    actions.errorCreatingSurvey?.(
      'Vous devez ajouter une autre réponse en revenant à la ligne avec Shift + Entrée',
    );
    return false;
  }

  // send the poll !
  actions.askSurvey?.({
    question,
    answers,
    multiple: !!isMultiple,
    shareResult: false,
    shortcut: true,
  });

  return true;
};
