/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/*
 * Local Import
 */
import * as actions from 'src/store/actions';
import { LIGHT_THEME_NAME, DARK_THEME_NAME } from 'src/constants/theme';
import { setFocus } from 'src/utils';
import CHATS from 'src/constants/chats';
import { HAND_STATUS } from 'src/constants/conference';
import devicesMediasRTC from 'src/utils/medias';
import { isTeacher } from 'src/utils/roles';
import { isCtrl, isAlt, isShift } from './events';

/*
 * Export
 */
/**
 * Get hotKeys from key pressed in windows out of input
 * @param  {Event} evt
 * @param {State} state
 */
export const getGlobalHotKeys = (evt, dispatch, state, theme) => {
  // Récupération des value dans le state
  const { breaktime, course, notifications, shortcuts, users } = state;

  const isClientTeacher = isTeacher(users.client.role);
  const currentHandStatus = course.peerById[users.client.id]?.hand || HAND_STATUS.UNRAISED;

  // Key event
  const key = evt.keyCode || evt.which;

  // Alt Shift
  if (isAlt(evt) && isShift(evt)) {
    switch (key) {
      // C
      case 67:
        console.log('alt + shift + c');
        return dispatch(actions.toggleChat());
      // G
      case 71:
        console.log('alt + shift + g');
        return dispatch(actions.toggleUsers());
      // P
      case 80:
        console.log('Afficher les sondages');
        if (state.chats.currentMainChatView !== CHATS.TAB_SURVEY) {
          dispatch(actions.changeMainChatView(CHATS.TAB_SURVEY));
          setTimeout(() => {
            setFocus('survey');
          }, 0);
          return;
        }
        break;
      // R
      case 82:
        console.log('Réinitialiser l’interface');
        if (theme.themeName !== LIGHT_THEME_NAME) {
          theme.chooseTheme(LIGHT_THEME_NAME);
        }
        return dispatch(actions.resetLayout());
      // U
      // case 85:
      //   // Not implemented for the moment
      //   return console.log('Changer de périphérique');
      // V
      case 86:
        return dispatch(actions.toggleNotification('enabled'));
      // X
      case 88:
        console.log('Afficher le chat');
        if (state.chats.currentMainChatView !== CHATS.TAB_CHAT) {
          evt.preventDefault();
          dispatch(actions.changeMainChatView(CHATS.TAB_CHAT));
          return;
        }
        break;
      // Y
      case 89:
        return theme.chooseTheme(
          theme.themeName === LIGHT_THEME_NAME ? DARK_THEME_NAME : LIGHT_THEME_NAME,
        );
      default:
        return;
    }
  }

  // Ctrl
  if (isCtrl(evt)) {
    switch (key) {
      // B Supported by default in Quill
      case 66:
        return console.log('Bold');
      // I Supported by default in Quill
      case 73:
        return console.log('Italic');
      default:
        return;
    }
  }

  // Alt
  if (isAlt(evt)) {
    switch (key) {
      // E
      case 69:
        console.log('Quitter le cours');
        window.location = '/';
        break;
      // I
      case 73:
        return console.log('Snippet');
      // K
      case 75:
        console.log('Alt + K');
        return dispatch(
          actions.setModalShortcuts({
            isOpen: !shortcuts.isOpen,
            method: 'Shortcut',
          }),
        );
      // P
      case 80:
        console.log('Créer un sondage');
        return dispatch(actions.openModalSurvey());
      // R
      case 82:
        console.log('Demander la parole', isClientTeacher);
        // Teacher can't raise their hands
        // If a hand is raised, you can't re-raise it
        if (!isClientTeacher && currentHandStatus === HAND_STATUS.UNRAISED) {
          dispatch(actions.sendRaisedHand(state.users.client)());
        }
        break;
      // U
      case 85:
        console.log('Activer / Couper le micro');
        if (!devicesMediasRTC.hasMicrophonePermissions) {
          return;
        }

        if (course.medias.microphoneEnabled) {
          dispatch(actions.disableMicrophone());
        }
        else {
          dispatch(actions.enableMicrophone());
        }
        break;
      // V
      case 86:
        return dispatch(actions.setMenuNotifications({ isOpen: !notifications.isOpen }));
      // W
      case 87:
        console.log('Activer / Désactiver sa webcam');
        if (!devicesMediasRTC.hasWebcamPermissions) {
          return;
        }

        if (course.medias.webcamEnabled) {
          dispatch(actions.disableWebcam());
        }
        else {
          dispatch(actions.enableWebcam());
        }
        break;
      // X
      case 88: {
        // Focus input chat
        evt.preventDefault();
        if (state.chats.currentMainChatView !== CHATS.TAB_CHAT) {
          dispatch(actions.changeMainChatView(CHATS.TAB_CHAT));
        }
        setTimeout(() => {
          document.getElementsByClassName('ql-editor')[0].focus();
        }, 0);
        return;
      }
      // Z
      case 90:
        console.log('Prendre une pause');
        return dispatch(actions.setModalAway({ isOpen: !breaktime.isOpen }));
      default:
    }
  }
};
