/*
 * Package Import
 */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import MESSAGES from 'src/constants/messages';
import Tooltip from 'src/components/Tooltip/container';
import { MessageContext } from 'src/context/Message';

// Style
import * as S from './style';

/*
 * Component
 */
const Filters = ({ actions, chatId }) => {
  const { visibilityFilter, setVisibilityFilter } = useContext(MessageContext);

  /**
   * Reset the filter to the default value
   * @return {void}
   */
  const onResetFilter = () => {
    actions.setVisibilityFilter({
      chatId,
      filter: MESSAGES.SHOW_ALL,
    });
    setVisibilityFilter(MESSAGES.SHOW_ALL);
  };

  /**
   * Handler to set the current filter for the messages to display
   * @param {MouseEvent} event
   * @return {void}
   */
  const onVisibilityFilter = (event) => {
    if (event.currentTarget.dataset.action === visibilityFilter) {
      onResetFilter();
    }

    // Set the new visibility filter
    else {
      const filter = event.currentTarget.dataset.action;
      actions.setVisibilityFilter({
        chatId,
        filter,
      });
      setVisibilityFilter(filter);
    }
  };

  /**
   * LifeCycles
   */
  useEffect(
    () => () => {
      onResetFilter();
    },
    [],
  );

  /**
   * Partials
   */
  const getVisibilityPartial = (filter) => {
    switch (filter) {
      case MESSAGES.SHOW_PINNED:
        return (
          <>
            <S.Label visibilityFilter={filter}>Messages épinglés</S.Label>
            <S.CloseButton
              type="button"
              onClick={onResetFilter}
              aria-label="Réinitialiser les filtres"
            >
              <S.CloseIcon />
            </S.CloseButton>
          </>
        );

        // case MESSAGES.SHOW_MENTIONED:
        //   return (
        //     <>
        //       <S.Label visibilityFilter={filter}>Mentions</S.Label>
        //       <S.CloseButton
        //         type="button"
        //         onClick={onResetFilter}
        //         aria-label="Réinitialiser les filtres"
        //       >
        //         <S.CloseIcon />
        //       </S.CloseButton>
        //     </>
        //   );

      case MESSAGES.SHOW_QUESTION:
        return (
          <>
            <S.Label visibilityFilter={filter}>Questions</S.Label>
            <S.CloseButton
              type="button"
              onClick={onResetFilter}
              aria-label="Réinitialiser les filtres"
            >
              <S.CloseIcon />
            </S.CloseButton>
          </>
        );

      default:
        return (
          <>
            <S.Label>Aucun filtre actif</S.Label>
          </>
        );
    }
  };

  /*
   * Render
   */
  return (
    <S.Filter>
      {/* Partial */}
      <S.Container>{getVisibilityPartial(visibilityFilter)}</S.Container>

      <S.Container>
        {/* Questions */}
        <Tooltip content="Afficher les questions">
          <S.Button
            id="messages-filters-questions"
            data-action={MESSAGES.SHOW_QUESTION}
            visibilityFilter={visibilityFilter}
            onClick={onVisibilityFilter}
            isActive={visibilityFilter === MESSAGES.SHOW_QUESTION}
            // a11y
            aria-label="Afficher les questions"
            aria-selected={visibilityFilter === MESSAGES.SHOW_QUESTION}
          >
            <S.QuestionIcon />
          </S.Button>
        </Tooltip>

        {/* Mentions */}
        {/* <Tooltip content="Afficher les mentions qui me concerne">
          <S.Button
            id="messages-filters-mentions"
            data-action={MESSAGES.SHOW_MENTIONED}
            visibilityFilter={visibilityFilter}
            onClick={onVisibilityFilter}
            isActive={visibilityFilter === MESSAGES.SHOW_MENTIONED}
            // a11y
            aria-label="Afficher les mentions qui me concerne"
            aria-selected={visibilityFilter === MESSAGES.SHOW_MENTIONED}
          >
            <S.MentionIcon />
          </S.Button>
        </Tooltip> */}

        {/* Pins */}
        <Tooltip content="Afficher les messages épinglés">
          <S.Button
            id="messages-filters-flags"
            data-action={MESSAGES.SHOW_PINNED}
            visibilityFilter={visibilityFilter}
            onClick={onVisibilityFilter}
            isActive={visibilityFilter === MESSAGES.SHOW_PINNED}
            // a11y
            aria-label="Afficher les messages épinglés"
            aria-selected={visibilityFilter === MESSAGES.SHOW_PINNED}
          >
            <S.PinIcon />
          </S.Button>
        </Tooltip>
      </S.Container>
    </S.Filter>
  );
};

/*
 * PropTypes
 */
Filters.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  chatId: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default React.memo(Filters);
