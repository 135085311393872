/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Signature from 'src/components/TopBar/Signature';
import {
  setMenuSignature,
  startSignature,
  resendSignNotifs,
  setFollowingModal,
  checkSignatureTimeslot,
  confirmResetSignatures,
} from 'src/store/actions';
import { isTeacher, isEducator } from 'src/utils/roles';

/*
 * Code
 */

const mapStateToProps = (state) => ({
  menuSignatureIsOpen: state.signature.isOpen,
  isEducator: isEducator(state.users.client.role),
  isTeacher: isTeacher(state.users.client.role),
  canSign: state.signature.canSign,
  studentNeedSign: state.signature.studentNeedSign,
  studentHaveSign: state.signature.studentNeedSign.filter((student) => student.hasSigned),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setMenuSignature,
      startSignature,
      confirmResetSignatures,
      resendSignNotifs,
      setFollowingModal,
      checkSignatureTimeslot,
    },
    dispatch,
  ),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Signature);
