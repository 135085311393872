/**
 * Package Import
 */
import { isSameMonth } from 'date-fns';

/**
 * Local Import
 */
import { getHoursOrMinutes } from 'src/components/Admin/Modals/FormElements/Timeslot/utils';

// Helpers
import { getTimeDuration } from 'src/utils/time';
import { LIMITATIONS_FEATURES } from 'src/constants/limitations';

// Types
import type { Course } from 'src/schemas/Entities/Course';
import type { LimitationDTO } from 'src/schemas/Entities/Limitation';
import type { CourseFormDataPartialStatic } from '..';

/**
 * Check courses hours limitation
 */
export const checkCoursesHoursLimitation = ({
  courseData,
  coursesData,
  limitationsData,
}: {
  courseData: CourseFormDataPartialStatic;
  coursesData?: Course[];
  limitationsData?: LimitationDTO;
}) => {
  // Check if limitation exists and is active
  const monthlyHoursLimitation = limitationsData
    && limitationsData.find((restrict) => restrict.feature === LIMITATIONS_FEATURES.MONTHLY_HOURS);

  if (
    !monthlyHoursLimitation
    || !monthlyHoursLimitation.active
    || !monthlyHoursLimitation.quantity
  ) {
    return false;
  }

  // Durations
  let coursesDurationMS = 0;
  let currentCourseDurationMS = 0;

  // Limitation quantity
  const limit = monthlyHoursLimitation.quantity;
  const limitationMS = limit * (60000 * 60);

  // All courses data
  if (coursesData && coursesData.length) {
    const coursesInCurrentMonth = coursesData
      // Only the same month on `course.date` matches
      .filter((course) => {
        const newCourseDate = new Date(courseData.date);
        const courseDate = new Date(course.date);
        return isSameMonth(newCourseDate, courseDate);
      })
      // Dont grab the current course twice, if exists
      .filter((course) => course.id !== courseData.id);

    // Total duration of all courses in current month
    const coursesCurrentMonthDuration = coursesInCurrentMonth.reduce((acc, item) => {
      item.timeslots.forEach(({ start, end }) => {
        const startDate = new Date(start).getTime();
        const endDate = new Date(end).getTime();
        const diffTimeMS = endDate - startDate;
        acc += diffTimeMS;
      });

      return acc;
    }, 0);

    coursesDurationMS += coursesCurrentMonthDuration;
  }

  // New course data
  if (courseData && courseData.timeslots && courseData.timeslots.length) {
    const transformedTimeslots = courseData.timeslots.map((timeslot) => {
      const newCourseDate = new Date(courseData.date);
      return {
        start: newCourseDate.setHours(
          getHoursOrMinutes(timeslot.start, 'hours'),
          getHoursOrMinutes(timeslot.start, 'minutes'),
        ),
        end: newCourseDate.setHours(
          getHoursOrMinutes(timeslot.end, 'hours'),
          getHoursOrMinutes(timeslot.end, 'minutes'),
        ),
      };
    });

    // Total duration of the course (created or updated)
    const newCourseDuration = transformedTimeslots.reduce((acc, { start, end }) => {
      const startDate = new Date(start).getTime();
      const endDate = new Date(end).getTime();
      const diffTimeMS = endDate - startDate;
      acc += diffTimeMS;
      return acc;
    }, 0);

    currentCourseDurationMS += newCourseDuration;
  }

  // Limit reached
  const totalCourseDurationMS = coursesDurationMS + currentCourseDurationMS;
  if (totalCourseDurationMS > limitationMS) {
    // Only hours already consumed
    const { hours, minutes, seconds } = getTimeDuration(coursesDurationMS);
    return {
      limit,
      hours,
      minutes,
      seconds,
    };
  }

  // Default behavior
  return false;
};
