/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/AvatarTooltip';
import AudioLevel from 'src/components/Course/AudioLevel/container';
import Avatar from 'src/components/Users/UsersPanel/List/UserCard/Avatar';
import Labels from 'src/components/Users/UsersPanel/List/UserCard/Labels';

// Helpers
import { isEnter } from 'src/utils/events';

// Constants
import CHATS from 'src/constants/chats';
import { triggerLocations } from 'src/constants/tracking';

// Style
import * as S from './style';

/*
 * Component
 */
const UserCard = ({ actions, mugshot, user }) => {
  /*
   * Handlers
   */
  const handleOpenChat = () => {
    actions.changeMainChatView(CHATS.TAB_CHAT);
    actions.openChat(user.id, triggerLocations.USER_LIST);
  };

  const onKeyDown = (evt) => {
    if (isEnter(evt, false)) {
      handleOpenChat(evt);
    }
  };

  /*
   * Render
   */
  return (
    <S.UserCard
      type="button"
      tabIndex="0"
      id={`user-${user.id}`}
      onClick={handleOpenChat}
      onKeyDown={onKeyDown}
    >
      <Tooltip name={user.name} connected={user.connected} away={user.away}>
        <S.Mugshot>
          <Avatar mugshot={mugshot} {...user} showConnected />
          <AudioLevel userId={user.id} />
        </S.Mugshot>
      </Tooltip>
      <Labels name={user.name} role={user.role} emotion={user.emotion} />
    </S.UserCard>
  );
};

/*
 * PropTypes
 */
UserCard.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  mugshot: PropTypes.string,
  user: PropTypes.object.isRequired,
};

UserCard.defaultProps = {
  mugshot: null,
};

/*
 * Export
 */
export default UserCard;
