/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  CourseDTOWithoutId,
  courseDTOSchemaWithoutId,
  CourseFormDataValidated,
  courseFormDataValidatedSchema,
} from 'src/schemas/Entities/Course';
import { MongoId } from 'src/schemas/Entities/utils';
import { purify } from '../utils';
import { transformTimeslotsSimpleStringToDate } from './utils';

/*
 * Code
 */
export const transformCourseFormDataToCourseDTO = (
  courseFormData: CourseFormDataValidated,
): CourseDTOWithoutId => {
  const courseFormDataParseResult = courseFormDataValidatedSchema.parse(courseFormData);

  const { title, promotion, teacher, date, helpers, timeslots } = courseFormDataParseResult;

  const transformedTimeslots = transformTimeslotsSimpleStringToDate(timeslots, date);

  const courseDTO: CourseDTOWithoutId = {
    title: purify(title),
    promoId: promotion.value,
    teacherId: teacher.value,
    date,
    helpersIds: helpers.reduce((ids: MongoId[], helper) => {
      if (helper) ids.push(helper.value);
      return ids;
    }, []),
    timeslots: transformedTimeslots.map((timeslot) => ({
      start: timeslot.start.toISOString(),
      end: timeslot.end.toISOString(),
      ...(timeslot._id !== '' && { _id: timeslot._id }),
    })),
  };

  const courseDTOParseResult = courseDTOSchemaWithoutId.parse(courseDTO);

  return courseDTOParseResult;
};
