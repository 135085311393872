/**
 * Package Import
 */
import React from 'react';
import { Button } from '@oclock/crumble';

/**
 * Local Import
 */
import { declinationStrings } from 'src/constants/entities';

/**
 * Style
 */

/**
 * Types
 */
interface PlusButtonProps {
  title: string;
  openAddModal: () => void;
}

/**
 * Component
 */
export const PlusButton = ({ title, openAddModal }: PlusButtonProps): JSX.Element => {
  const entity = declinationStrings.find(
    (declinationString) => declinationString.plural === title.toLowerCase(),
  );

  const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];

  const newString = (() => {
    if (entity) {
      if (entity.gender === 'female') {
        return 'Nouvelle';
      }

      if (vowels.includes(entity.singular[0])) {
        return 'Nouvel';
      }

      return 'Nouveau';
    }

    return 'Nouveau';
  })();

  const buttonString = `${newString} ${entity?.singular ?? title}`;

  return (
    <Button type="button" variant="primary" icon="Plus" onClick={openAddModal}>
      {buttonString}
    </Button>
  );
};
