/*
 * Package Import
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { useReaction } from 'src/components/Classroom/hooks';
import { Emoji, Picker } from 'src/components/Format/Formatizer';
import { setFocus } from 'src/utils/index';

// Helpers
import { defaultReactions } from 'src/utils/emoji';

// Style
import { ContainerPicker } from 'src/components/Chats/Channel/Chat/Input/Picker/style';
import { Button } from '../style';
import * as S from './style';

/*
 * Component
 */
const Reactions = ({ messageId, userId }) => {
  const { handleReaction } = useReaction();
  /*
   * State
   */
  const [open, setOpen] = useState(
    /** @type {Boolean} · Initial state for Reactions panel */
    false,
  );

  const [display, setDisplay] = useState(
    /** @type {Boolean} · Initial state for Modal Reaction */
    false,
  );

  const [top, setTop] = useState(
    /** @type {string} · Initial position picker */
    '2rem',
  );

  const [left, setLeft] = useState(
    /** @type {string} · Initial position picker */
    '1rem',
  );

  /*
   * Refs
   */
  const menuReactionRef = useRef(null);

  /*
   * Handlers
   */
  /**
   * Display menu && force display action
   * buttons for keep component menu mounted
   */
  const handleMenu = (state) => {
    setOpen(state);
    setTimeout(() => {
      setFocus(state ? 'item-+1' : 'message-reaction');
    }, 0);
  };

  const onClose = () => {
    setDisplay(false);
  };

  /**
   * Select an emoji
   * @param  {Object} emoji
   */
  const handleEmoji = (emoji) => {
    handleReaction({ messageId, userId, reaction: emoji.id });
    setOpen(false);
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const getPosition = () => {
    if (menuReactionRef.current) {
      // positionY = position top menu reaction
      const positionY = menuReactionRef.current.getBoundingClientRect().y;
      // height = height menu reaction
      const height = menuReactionRef.current.getBoundingClientRect().height;

      // 472 = height picker +2 for visuel
      if (positionY + height < 472) {
        setTop('.25rem');
      }
      else {
        setTop(`${(positionY + height - 472) / 16}rem`);
      }

      const totalWidth = window.innerWidth;

      // Note: Modal don't accept right position, so calcul left …
      setLeft(`${(totalWidth - 340 - 16) / 16}rem`);
    }
  };

  const handleClick = () => {
    getPosition();
    setOpen(false);
    setDisplay(true);
  };

  /*
   * Render
   */
  return (
    <>
      {/* Action button */}
      <Button
        role="button"
        onWillOpen={() => handleMenu(!open)}
        onWillClose={() => handleMenu(!open)}
        menuZIndex={15}
        // a11y
        buttonProps={{ 'aria-label': 'Ajouter une réaction' }}
        aria-expanded={open}
        aria-haspopup="true"
        id="message-reaction"
        menu={(
          <S.Menu isOpen={open} ref={menuReactionRef}>
            <S.Header>Envoyer une réaction</S.Header>
            <S.Reactions>
              {defaultReactions.map((reaction) => (
                <S.Button
                  key={`item-${reaction.id}`}
                  type="button"
                  onClick={() => handleEmoji(reaction)}
                  id={`item-${reaction.id}`}
                  // a11y
                  aria-label={`Réagir à ce message en répondant : « ${reaction.name} »`}
                >
                  <Emoji size={20}>{reaction.colons}</Emoji>
                </S.Button>
              ))}

              <S.Button
                type="button"
                onClick={handleClick}
                // a11y
                aria-label="Ouvrir plus de réactions"
              >
                <S.Plus />
              </S.Button>
            </S.Reactions>
          </S.Menu>
        )}
      >
        <S.Smile />
      </Button>

      {/* Modal all reaction */}
      {display && (
        <S.ReactionsModal
          ariaLabel="Reactions"
          isOpen={display}
          onRequestClose={onClose}
          left={left}
          top={top}
        >
          <ContainerPicker>
            <Picker onChange={handleEmoji} />
          </ContainerPicker>
        </S.ReactionsModal>
      )}
    </>
  );
};

/*
 * PropTypes
 */
Reactions.propTypes = {
  messageId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Reactions;
