import { useCallback, useContext, useEffect } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useDispatch } from 'react-redux';

import { MessageContext } from 'src/context/Message';
import {
  addReaction as addReactionAction,
  removeReaction as removeReactionAction,
} from 'src/store/actions';
import { ADD_REACTION, REMOVE_REACTION } from 'src/store/types';

/**
 * Hook that handles adding & deleting reactions.
 * If given a socket, it will add listeners for those actions.
 */
export const useReaction = (socket) => {
  const dispatch = useDispatch();

  const { messagesById, setMessagesById } = useContext(MessageContext);

  /**
   * Adds reaction to message
   */
  const addReaction = useCallback(
    ({ messageId, userId, reaction }) => {
      if (!messagesById[messageId]) return;

      setMessagesById((prevMessagesById) => {
        const reactions = cloneDeep(prevMessagesById[messageId]?.reactions);
        const index = reactions.findIndex((reactionObj) => reactionObj.name === reaction);

        if (index !== -1) {
          reactions[index].users.push(userId);
        }
        else {
          reactions.push({
            name: reaction,
            users: [userId],
          });
        }

        return {
          ...prevMessagesById,
          [messageId]: {
            ...prevMessagesById[messageId],
            reactions,
          },
        };
      });
    },
    [messagesById],
  );

  /**
   * Removes reaction from message
   */
  const removeReaction = useCallback(
    ({ messageId, userId, reaction }) => {
      if (!messagesById[messageId]) return;

      setMessagesById((prevMessagesById) => {
        const reactions = cloneDeep(prevMessagesById[messageId]?.reactions);
        const index = reactions.findIndex((reactionObj) => reactionObj.name === reaction);

        // If reaction is found
        if (index !== -1) {
          // Remove userId from reaction's users
          reactions[index].users.splice(reactions[index].users.indexOf(userId), 1);

          // If reaction's users is empty, remove reaction
          if (!reactions[index].users.length) {
            reactions.splice(index, 1);
          }
        }

        return {
          ...prevMessagesById,
          [messageId]: {
            ...prevMessagesById[messageId],
            reactions,
          },
        };
      });
    },
    [messagesById],
  );

  /**
   * Adds or remove a reaction
   */
  const handleReaction = useCallback(
    ({ messageId, userId, reaction }) => {
      const message = messagesById[messageId];

      if (!message) return;

      if (Array.isArray(message.reactions)) {
        const index = message.reactions.findIndex((reactionObj) => reactionObj.name === reaction);
        const userReactionExist = message.reactions[index]?.users.some((user) => user === userId);
        const payload = { messageId, userId, reaction };

        if (userReactionExist) {
          dispatch(removeReactionAction(payload));
          removeReaction(payload);
        }
        else {
          dispatch(addReactionAction(payload));
          addReaction(payload);
        }
      }
    },
    [messagesById],
  );

  useEffect(() => {
    socket?.on(ADD_REACTION, addReaction);
    socket?.on(REMOVE_REACTION, removeReaction);

    return () => {
      socket?.off(ADD_REACTION, addReaction);
      socket?.off(REMOVE_REACTION, removeReaction);
    };
  }, [addReaction, removeReaction, socket]);

  return {
    addReaction,
    handleReaction,
    removeReaction,
  };
};
