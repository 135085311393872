/*
 * Package Import
 */
import { ZodError } from 'zod';

/*
 * Local Import
 */
import { UserDTO, User, userSchema, userDTOSchema } from 'src/schemas/Entities/User';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { getPromotionFromId } from 'src/schemas/Repositories/promotions';
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { ZodValidationFailure, ZodValidationSuccess } from '../utils';

interface ErrorWithData {
  error: ZodError;
  data: User;
}

interface TransformUsersDTOToUsersResponse {
  users: User[];
  errors: { error: ZodError; data: User }[];
}
/*
 * Code
 */
const transformUserDTOToUser = (userDTO: UserDTO): User => {
  const promotionsData: Promotion[] | undefined = queryClient.getQueryData('promotions');
  const promotions = userDTO.promosIds.map((promoId) => {
    if (!promoId) return null;
    const promotion = getPromotionFromId({ promotions: promotionsData, id: promoId });
    if (!promotion) return null;
    return {
      id: promoId,
      name: promotion.name,
      displayName: promotion.displayName,
      ...(promotion.deactivatedAt && { deactivatedAt: promotion.deactivatedAt }),
    };
  });

  const deactivatedDate = userDTO.deactivatedAt && new Date(Date.parse(userDTO.deactivatedAt));
  const invitationDate = userDTO.invitationDate && new Date(Date.parse(userDTO.invitationDate));

  const {
    id,
    firstname,
    lastname,
    name,
    email,
    role,
    githubProfile,
    discordProfile,
    town,
    country,
    avatar,
    isRegistered,
    integrations,
    canCreateVM,
  } = userDTO;

  return {
    id,
    firstname,
    lastname,
    name,
    email,
    role,
    githubProfile,
    discordProfile,
    town,
    country,
    avatar,
    isRegistered,
    promotions,
    integrations,
    canCreateVM,
    ...(deactivatedDate && { deactivatedAt: deactivatedDate }),
    ...(invitationDate && { invitationDate }),
  };
};

export const validateAndTransformUserDTOToUser = (
  userData: UserDTO,
): ZodValidationSuccess | ZodValidationFailure => {
  const user = transformUserDTOToUser(userData);
  const userDTOParseResult = userDTOSchema.safeParse(userData);
  const userParseResult = userSchema.safeParse(user);
  if (!userDTOParseResult.success) {
    return {
      error: userDTOParseResult.error,
      success: userDTOParseResult.success,
      data: user,
    };
  }
  if (!userParseResult.success) {
    return {
      error: userParseResult.error,
      success: userParseResult.success,
      data: user,
    };
  }

  return userParseResult;
};

export const transformUsersDTOToUsers = (
  usersData: UserDTO[],
): TransformUsersDTOToUsersResponse => {
  const errors = [] as ErrorWithData[];
  const users = usersData.reduce((acc: User[], userData: UserDTO) => {
    // for each UserDto received, we try to transform it in User after validation
    const zodValidationUser = validateAndTransformUserDTOToUser(userData);
    // If response is a validation failure, we push at errors
    if (!zodValidationUser.success) {
      errors.push({ error: zodValidationUser.error, data: zodValidationUser.data });
    }
    // and we push user
    acc.push(zodValidationUser.data);
    return acc;
  }, []);

  return {
    users,
    errors,
  };
};
