/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilInfoCircle, UilCommentsAlt, UilFileAlt, UilChart } from '@iconscout/react-unicons';

import { ReactComponent as Hand } from 'assets/images/icons/hand.svg';
import { ReactComponent as Edusign } from 'assets/images/icons/edusign.svg';

/*
 * Style
 */

// One Notification
export const Notification = styled.li(({ theme }) => ({
  position: 'relative',
  width: '24.75rem',
  minHeight: '3.875rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '.5rem .5rem .5rem .75rem',
  margin: '0.75rem auto 0',
  borderRadius: '8px',
  zIndex: '1',
  background: theme.background._2,
  ...theme.shadows.plus1,
  ':hover': {
    background: theme.background._5,
  },
}));

export const IconContainer = styled.div({
  width: '2.5rem',
  height: '2.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const iconStyle = (theme) => ({
  color: theme.primary._1,
});

export const IconInfoCircle = styled(UilInfoCircle)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const IconCommentsAlt = styled(UilCommentsAlt)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const IconFileAlt = styled(UilFileAlt)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const IconChart = styled(UilChart)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const IconHand = styled(Hand)(({ theme }) => ({
  color: theme.primary._2,
  height: '1.5rem',
  width: '1.5rem',
}));

export const IconEdusign = styled(Edusign)(() => ({
  width: '1.5rem',
  height: '1.5rem',
  path: {
    fill: '#FEC007',
  },

  ...iconStyle,
}));

export const ContainerContent = styled.button({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0 .5rem',
});

export const TitleNotification = styled.label(({ theme }) => ({
  color: theme.text._1,
  ...theme.typo.labels.label,
}));

export const Description = styled.span(({ theme }) => ({
  color: theme.text._4,
  textAlign: 'left',
  ...theme.typo.bodyText.tinyText,
}));

export const Delete = styled.span(({ theme }) => ({
  cursor: 'pointer',
  display: 'block',
  marginRight: '.5rem',
  color: theme.primary._2,
  ...theme.typo.labels.tinyLabel,
  ':hover': {
    color: theme.primary._1,
  },
}));
