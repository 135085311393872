/*
 * Package Import
 */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
// Style
import * as S from './style';

/*
 * Component
 */
const SignFollowing = ({ actions, isOpen, users }) => {
  const [data, setData] = useState(() => users);

  useEffect(() => {
    setData(users);
  }, [users]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Nom',
        meta: {
          width: '80%',
        },
      }),
      columnHelper.accessor('hasSigned', {
        header: 'Signature',
        meta: {
          width: '20%',
        },
        cell: (row) => (
          <S.HasSigned hasSigned={row.getValue()}>
            {row.getValue() ? 'A signé' : 'Doit signer'}
          </S.HasSigned>
        ),
      }),
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  /*
   * Handlers
   */
  const handleClose = (evt) => {
    evt.preventDefault();
    actions.setFollowingModal({ isOpen: false });
  };

  /*
   * Render
   */
  return (
    <S.Modal open={isOpen} onClose={handleClose} aria-labelledby="Signature-modal">
      <S.Header>Émargement</S.Header>

      <S.Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.column.columnDef.meta.width }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{ width: cell.column.columnDef.meta.width }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </S.Table>

      <Button onClick={handleClose}>Fermer</Button>
    </S.Modal>
  );
};

/*
 * PropTypes
 */
SignFollowing.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State modal signature */
  isOpen: PropTypes.bool.isRequired,
  /** List of users */
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SignFollowing.defaultProps = {};

/*
 * Export
 */
export default SignFollowing;
