/**
 * Package Import
 */
import React from 'react';

/**
 * Local Import
 */
import Edusign from './Edusign';
import * as S from './style';

/**
 * Component
 */
const Integrations = () => (
  <S.Container>
    <Edusign />
  </S.Container>
);

/**
 * Export
 */
export default Integrations;
