/*
 * Local Import
 */
import { ActionEnum, Route, ParamsProps, routes, RouteEnum } from 'src/constants/routes';
import { MongoId } from 'src/schemas/Entities/utils';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { Course } from 'src/schemas/Entities/Course';
import { User } from 'src/schemas/Entities/User';
import { EntityEnum } from 'src/constants/entities';

export const getRoute = (routeName: RouteEnum): Route => {
  const routeData = routes.find((route) => route.name === routeName);
  if (!routeData) throw new Error('Route name not found');
  return routeData;
};

const isProperAction = (action: ActionEnum, entityRoutes: Route[]): boolean => {
  const entityActions = entityRoutes.map((route) => route.action);
  return entityActions.includes(action);
};

const isActionSupposedToHaveAnId = (action: ActionEnum, entityRoutes: Route[]): boolean => {
  const properRoute = entityRoutes.find((route) => route.action === action);
  return !!properRoute?.needsId;
};

const isProperId = (id: MongoId, data: User[] | Promotion[] | Course[] | undefined): boolean => {
  const isProperItem = data?.map((item: User | Promotion | Course) => item.id === id && item);
  return !!isProperItem?.find((item) => item);
};

export const isProperUrl = (
  entity: EntityEnum,
  data: User[] | Promotion[] | Course[] | undefined,
  urlParams: ParamsProps,
): boolean => {
  const entityRoutes = routes.filter((route) => route.entity === entity);
  const { action, id } = urlParams;

  if (!action) {
    return true;
  }

  if (!isProperAction(action, entityRoutes)) {
    return false;
  }

  if (id) {
    if (!isActionSupposedToHaveAnId(action, entityRoutes)) {
      return false;
    }
    if (!isProperId(id, data)) {
      return false;
    }
    return true;
  }
  return true;
};
