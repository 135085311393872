/*
 * Package Import
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { getFormatTime, getFormatFullTime } from 'src/utils/time';
import { Time } from './style';

/*
 * Component
 */
const TimestampMessage = ({ time }) => {
  /**
   * [when]
   * @return {String}
   */
  const when = useMemo(() => getFormatTime(time), [time]);

  /**
   * [whenFull]
   * @return {String}
   */
  const whenFull = useMemo(() => getFormatFullTime(time), [time]);

  /*
   * Render
   */
  if (!when) {
    return null;
  }

  return (
    <Time dateTime={whenFull} title={whenFull}>
      <span aria-hidden="true">{when}</span>
    </Time>
  );
};

/*
 * PropTypes
 */
TimestampMessage.propTypes = {
  /** Message timestamp */
  time: PropTypes.number.isRequired,
};

/*
 * Epxort
 */
export default TimestampMessage;
