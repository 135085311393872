/*
 * Package Import
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Portal from 'src/components/Portal';
import * as S from './style';

/*
 * Component
 */
const Overlay = (props) => {
  // Props
  const { children, type, onClose, onClick, ...attrs } = props;

  /*
   * Refs
   */
  const closeRef = useRef(null);

  /*
   * LifeCycles
   */
  useEffect(() => {
    closeRef.current.focus();
  });

  /*
   * Handlers
   */
  if (onClose) {
    attrs.onClick = (evt) => {
      if (evt.target.id.includes('course-overlay')) {
        onClose();
      }

      if (onClick) {
        onClick();
      }
    };

    attrs.onKeyDown = (evt) => {
      // ESC
      if (evt.keyCode === 27) {
        onClose();
      }
    };
  }

  // Return
  return (
    <Portal>
      <S.Overlay
        id="course-overlay"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="overlay"
        aria-modal="true"
        type={type}
        {...attrs}
      >
        <S.Close
          ref={closeRef}
          id="course-overlay-close"
          type="button"
          data-dismiss="dialog"
          title="Fermer"
          aria-label="Fermer la boîte de dialogue"
        />
        {children}
      </S.Overlay>
    </Portal>
  );
};

/*
 * PropTypes
 */
Overlay.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['fixed', 'absolute']),
};

Overlay.defaultProps = {
  type: 'fixed',
  onClose: () => null,
  onClick: () => null,
};

/*
 * Export
 */
export default Overlay;
