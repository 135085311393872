/* eslint-disable max-len */
/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import Counter from 'src/components/Users/UsersPanel/Counter';
import UserCard from 'src/components/Users/UsersPanel/List/UserCard/container';

// Style
import * as S from './style';
import * as G from '../../style';

/**
 * Component
 */
const SearchResult = ({ handleScroll, usersFiltered }) => (
  <S.SearchResultContainer onScroll={handleScroll}>
    <S.SectionHeader>
      <G.SectionTitle>
        Résultats
        <Counter
          isActive={usersFiltered.length > 0}
          nbusers={usersFiltered.length}
          assertiveNeeded
        />
      </G.SectionTitle>
    </S.SectionHeader>
    <G.StudentList>
      {usersFiltered.map((user) => (
        <G.StudentItem key={user.id} fullWidth listItem>
          <UserCard user={user} />
        </G.StudentItem>
      ))}
    </G.StudentList>
  </S.SearchResultContainer>
);

/**
 * PropTypes
 */
SearchResult.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  usersFiltered: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

/**
 * Export
 */
export default SearchResult;
