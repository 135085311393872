/**
 * Package Import
 */
import styled from '@emotion/styled';

// Icons
import { UilBookmark } from '@iconscout/react-unicons';
import { UisBookmark } from '@iconscout/react-unicons-solid';

/*
 * Icons
 */
const iconStyle = () => ({
  height: '1.25rem',
  width: '1.25rem',
});

/**
 * Style
 */
export const Unpin = styled(UilBookmark)(({ theme }) => ({
  ...iconStyle(),
  color: theme.text._1,
}));

export const Pinned = styled(UisBookmark)(({ theme }) => ({
  ...iconStyle(),
  color: theme.primary._1,
}));

export const Button = styled.button`
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-right: 1px;
  flex: 0 0 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  transition: background 0.2s;

  :hover {
    background: ${({ theme }) => theme.background._4};
  }

  :focus-visible {
    border: 2px solid ${({ theme }) => theme.primary._1};
  }
  :-moz-focusring {
    border: 2px solid ${({ theme }) => theme.primary._1};
  },
`;
