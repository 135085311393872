/*
 * Package Import
 */
import { ZodError } from 'zod';

/*
 * Local Import
 */
import { IntegrationDTO, integrationDTOSchema } from 'src/schemas/Entities/Integration';

interface ValidateIntegrationDTOResponse {
  integration: IntegrationDTO;
  error?: ZodError;
}

export const validateIntegrationDTO = (
  integrationData: IntegrationDTO,
): ValidateIntegrationDTOResponse => {
  const integrationDTOParseResult = integrationDTOSchema.safeParse(integrationData);

  if (!integrationDTOParseResult.success) {
    return {
      error: integrationDTOParseResult.error,
      integration: integrationData,
    };
  }

  return {
    integration: integrationDTOParseResult.data,
  };
};
