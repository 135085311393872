/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilSetting,
  UilMicrophoneSlash,
  UilVideoSlash,
  UilExclamationTriangle,
} from '@iconscout/react-unicons';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Container = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.background._5};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const Alert = styled.div`
  ${({ theme }) => theme.shadows.plus3};
  background: ${({ theme }) => theme.background._3};
  border: 2px solid ${({ theme }) => theme.background._7};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 20rem;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.typo.headlines.H5};
  align-items: center;
  color: ${({ theme }) => theme.text._1};
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  text-align: center;
`;

export const Description = styled.p`
  ${({ theme }) => theme.typo.bodyText.smallBody};
  color: ${({ theme }) => theme.text._3};
  margin-bottom: 1.5rem;
  max-width: 18rem;
  text-align: center;

  strong {
    font-weight: 600;
  }
`;

export const Help = styled.a`
  ${({ theme }) => theme.typo.labels.label};
  color: ${({ theme }) => theme.primary._1};
  margin: 1.5rem 0.5rem;
`;

export const Button = styled.button(
  ({ isMain, isDanger, theme }) => ({
    ...base.button({ isMain, isDanger, theme }),
    color: theme.text._10,
    width: '100%',
  }),
  ({ isDanger, theme }) =>
    isDanger && {
      background: theme.colors.warning._600,

      ':hover': {
        background: theme.colors.warning._700,
      },

      '&:focus': {
        background: theme.colors.warning._700,
        border: `2px solid ${theme.primary._2}`,
      },
    },
);

export const Select = styled.select`
  ${({ theme }) => theme.typo.labels.smallLabel};
  ${({ theme }) => ({ ...theme.focusVisible })};
  appearance: none;
  border: 1px solid ${({ theme }) => theme.background._7};
  border-radius: 8px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHdpZHRoPSIyNCI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4yOTMgOC43OTNhMSAxIDAgMDExLjQxNCAwTDEyIDE0LjA4Nmw1LjI5My01LjI5M2ExIDEgMCAxMTEuNDE0IDEuNDE0bC02IDZhMSAxIDAgMDEtMS40MTQgMGwtNi02YTEgMSAwIDAxMC0xLjQxNHoiIGZpbGw9IiM0NzU1NjkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
  background-position: right 12px center;
  background-repeat: no-repeat;
  color: #475569;
  cursor: pointer;
  height: 2.5rem;
  padding: 0.625rem 2.5rem 0.625rem 2.5rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;

  :disabled {
    background: #f1f5f9;
    background-image: none;
    color: #97a6ba;
    cursor: not-allowed;
  }
`;

export const Icon = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? '#97A6BA' : '#475569')};
  margin: 0.5rem 0.25rem 0.5rem 0.75rem;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
`;

const getIntroStyle = (props) => {
  if (props.hasError) {
    return {
      background: '#FDF2F2',
      color: '#E02424',
      padding: '.75rem',
    };
  }

  return {
    background: '#EBF5FF',
    color: '#1C64F2',
    padding: '.5rem',
  };
};

export const IconContainer = styled.div((props) => ({
  ...getIntroStyle(props),
  borderRadius: '50px',
  height: '56px',
  width: '56px',
}));

const getStyle = () => ({
  height: '100%',
  width: '100%',
});

export const Setting = styled(UilSetting)({
  ...getStyle(),
});

export const Microphone = styled(UilMicrophoneSlash)({
  ...getStyle(),
});

export const Video = styled(UilVideoSlash)({
  ...getStyle(),
});

export const Warning = styled(UilExclamationTriangle)({
  ...getStyle(),
});

export const Preview = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 180px;
  width: 100%;

  video {
    background: ${({ theme }) => theme.background._8};
    border: 1px solid ${({ theme }) => theme.background._7};
    border-radius: 4px;

    /* Image */
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const Devices = styled.div`
  .device {
    position: relative;
  }
  .device-camera {
    margin-bottom: 1rem;
  }
  .device-microphone {
    margin-bottom: 1rem;
  }
  .device-headphone {
    margin-bottom: 1.5rem;
  }
`;

export const RememberMedias = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
  width: 100%;
`;

export const CheckBox = styled.input`
  appearance: checkbox;
  margin-right: 0.5rem;
`;

export const SpanSrOnly = styled.span`
  ${({ theme }) => ({ ...theme.srOnly })};
`;
