/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local import
 */
import AWAY from 'src/constants/away';

// Helpers
import { useInterval } from 'src/hooks/useInterval';
import { getElapsed } from 'src/utils/time';

// Data
import * as data from './data';

// Style
import * as S from './style';

/*
 * Component
 */
const Timer = ({ onClose, timeValue }) => {
  /*
   * State
   */
  const [timer, setTimer] = useState(Math.max(timeValue, 0));
  const [timeCounter, setTimeCounter] = useState(0);
  const [awayMessage, setAwayMessage] = useState(null);
  const [index, setIndex] = useState(null);
  const [isActive, setActive] = useState(null);
  const [isActiveTimer, setActiveTimer] = useState(false);

  /*
   * Actions
   */
  useInterval(() => {
    const time = timer - 1;
    const passedTime = timeCounter + 1;

    // Message & random
    let message;
    let random;

    if (timeValue > 0) {
      // Change message only every `changeTime` seconds
      if (passedTime % AWAY.CHANGE_TIME === 1) {
        // Which list of messages do we have to display?
        const list = time > AWAY.VERY_LATE_FROM_TIMER ? data.messages : data.lateMessages;

        // Choose message index
        random = Math.floor(Math.random() * list.length);
        if (random === index) {
          random = (random + 1) % list.length;
        }

        // Choose message
        message = list[random];
      }
      else {
        message = awayMessage;
        random = index;
      }
    }
    else if (timeValue <= 0) {
      // Change message only every `changeTime` seconds
      if (passedTime % AWAY.CHANGE_TIME === 1) {
        // Which list of messages do we have to display?
        const list = passedTime < AWAY.VERY_LATE_FROM ? data.messages : data.lateMessages;

        // Choose message index
        random = Math.floor(Math.random() * list.length);
        if (random === index) {
          random = (random + 1) % list.length;
        }

        // Choose message
        message = list[random];
      }
      else {
        message = awayMessage;
        random = index;
      }
    }

    // Display
    const active = passedTime % AWAY.CHANGE_TIME !== 0;

    // Active timer
    const activeTimer = timeValue > 0;

    // Set state
    setTimer(time);
    setTimeCounter(passedTime);
    setAwayMessage(message);
    setIndex(random);
    setActive(active);
    setActiveTimer(activeTimer);
  }, 1000);
  /*
   * Render
   */
  return (
    <S.StyledFragment isActiveTimer={isActiveTimer}>
      <S.Header variant="h1">En pause</S.Header>
      <S.Timer>
        <S.Message isActive={isActive}>{awayMessage}</S.Message>
        {isActiveTimer && (
          <S.TimerContainer>
            <S.StyledSpan>Temps restant</S.StyledSpan>
            <S.Elapsed isLate={timer <= 0 && timeValue > 0}>{getElapsed(timer)}</S.Elapsed>
          </S.TimerContainer>
        )}
      </S.Timer>
      <S.Buttons>
        <S.StyledButton onClick={onClose} isMain>
          On y retourne
        </S.StyledButton>
      </S.Buttons>
    </S.StyledFragment>
  );
};

/*
 * PropTypes
 */
Timer.propTypes = {
  onClose: PropTypes.func.isRequired,
  timeValue: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default Timer;
