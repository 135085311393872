import styled from '@emotion/styled';
import { AriaAttributes } from 'react';

interface FieldProps extends AriaAttributes {
  isLeft?: boolean;
}

export const Field = styled('div')(({ isLeft }: FieldProps) => ({
  marginBottom: '1.5rem',
  flex: 1,
  marginRight: isLeft ? '2.5rem' : '0',
}));

export const CheckboxField = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Box = styled('div')(({ isLeft }: FieldProps) => ({
  width: '18.5rem',
  marginRight: isLeft ? '2.5rem' : '0',
}));

export const Line = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));
