/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * Package Import
 */
import React from 'react';
import ReactModal, { Styles } from 'react-modal';
import { useTheme } from '@emotion/react';

/*
 * Code
 */
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

/**
 * Types
 */
interface ModalProps {
  children: JSX.Element;
  isOpen: boolean;
  onRequestClose: (evt: React.MouseEvent) => void;
  // Style purpose
  top?: string | number;
  right?: string | number;
  bottom?: string | number;
  left?: string | number;
  // A11Y purpose
  ariaLabel?: string;
  // React-Modal props
  customParent?: string;
  customOverlayCss?: { [key: string]: string };
  // Custom purpose
  withOverlay?: boolean;
}

/*
 * Component
 */
const Modal = ({
  ariaLabel,
  children,
  isOpen,
  onRequestClose,
  top,
  right,
  bottom,
  left,
  customParent = 'main',
  customOverlayCss = {},
  withOverlay = false,
  ...rest
}: ModalProps) => {
  /**
   * Hooks
   */
  const theme = useTheme();

  /**
   * Styles
   */
  const customStyles: Styles = {
    overlay: {
      background: 'none',
      zIndex: '20',
      ...customOverlayCss,
    },
    content: {
      fontFamily: 'Inter',
      width: 'fit-content',
      height: 'fit-content',
      position: 'absolute',
      top,
      right,
      bottom,
      left,
    },
  };

  const customStylesWithOverlay: Styles = {
    overlay: {
      background: 'rgba(0,0,0,0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      inset: 'initial', // to reset default library styles
      border: `2px solid ${theme.text._7}`,
      backgroundColor: theme.background._2,
      borderRadius: '20px',
      padding: 0,
      position: 'relative', // to reset default library styles
      display: 'inline-block',
    },
  };

  /**
   * @doc http://reactcommunity.org/react-modal/
   */
  return (
    // @ts-ignore
    <ReactModal
      // open modal in <main>
      parentSelector={() => document.getElementById(customParent)}
      aria={{ label: ariaLabel }}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={30}
      style={withOverlay ? customStylesWithOverlay : customStyles}
      {...rest}
    >
      {children}
    </ReactModal>
  );
};

/*
 * Export
 */
export default Modal;
