/**
 * Package Import
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import CoursePanel from 'src/components/Course/Panel/container';
import Webcams from 'src/components/Course/Webcams/container';
import Conference from 'src/components/Course/Conference/container';
import Screen from 'src/components/Course/Screen/container';

// Style
import * as S from './style';

/**
 * Component
 */
const Course = ({ hasScreenShared }) => {
  const screenRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleScreenResize = useCallback(
    throttle((entries) => {
      setScreenWidth(entries[0].contentRect.width);
    }, 100),
    [],
  );

  useEffect(() => {
    if (!screenRef.current) return;

    const screenSizeObserver = new ResizeObserver(handleScreenResize);
    screenSizeObserver.observe(screenRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      screenSizeObserver.disconnect();
    };
  }, [handleScreenResize]);

  return (
    <>
      <S.TitleSrOnly id="coursePresentation">Présentation</S.TitleSrOnly>
      <S.Course id="course">
        <S.ScreenContainer>
          {/* Screenshare */}
          <S.SubTitleSrOnly>
            {hasScreenShared ? 'Partage de l’écran du formateur' : 'Aucun partage d’écran'}
          </S.SubTitleSrOnly>
          <Screen ref={screenRef} />
        </S.ScreenContainer>
        <S.BottomContainer screenSize={screenWidth}>
          {/* Conferences */}
          <S.SubTitleSrOnly>Formateur et assistants</S.SubTitleSrOnly>
          <Webcams />
          <Conference />
          <CoursePanel />
        </S.BottomContainer>
      </S.Course>
    </>
  );
};

/**
 * PropTypes
 */
Course.propTypes = {
  /** is screen share turned on ? */
  hasScreenShared: PropTypes.bool.isRequired,
};

/**
 * Export
 */
export default Course;
