/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Introduction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Illustration = styled.div`
  background: ${({ theme, variant }) =>
    (variant === 'info' ? theme.colors.info._100 : theme.primary._5)};
  color: ${({ theme, variant }) =>
    (variant === 'info' ? theme.colors.info._600 : theme.primary._1)};
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Title = styled.p(({ theme }) => ({
  ...theme.typo.headlines.H5,
  textAlign: 'center',
  color: theme.text._1,
  marginBottom: '.5rem',
}));

export const Paragraph = styled.p(({ theme }) => ({
  ...theme.typo.bodyText.body,
  textAlign: 'center',
  color: theme.text._5,
}));

export const Button = styled.button(({ isMain, theme }) => ({
  ...base.button({ isMain, theme }),
  padding: '.5rem 1rem',
  borderRadius: '50px',
  margin: '1.5rem auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
