import styled from '@emotion/styled';
import { zIndex } from 'src/theme';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

type FilterModalButtonProps = {
  theme: ThemeInterface;
  isOpen: boolean;
  isFilterValue: boolean;
};
export const FilterModalButton = styled.button(
  ({ theme, isOpen, isFilterValue }: FilterModalButtonProps) => ({
    borderRadius: '8px',
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: isOpen || isFilterValue ? theme.primary._1 : theme.text._4,
    ...(isOpen && { background: theme.primary._5, zIndex: zIndex.filterModalOpen }),

    ':hover': {
      background: theme.background._8,
      color: isOpen || isFilterValue ? theme.primary._1 : theme.text._1,
    },

    ':focus': {
      border: `2px solid ${theme.primary._1}`,
    },

    ':active': {
      background: theme.primary._5,
      color: theme.primary._1,
    },
  }),
);

type FilterModalProps = {
  theme: ThemeInterface;
  isOpen: boolean;
};
export const FilterModal = styled.div(({ theme, isOpen }: FilterModalProps) => ({
  position: 'absolute',
  right: '1rem',
  background: theme.background._2,
  padding: '.5rem',
  borderRadius: '8px',
  marginTop: '.25rem',
  ...theme.shadows.plus2,
  ...(isOpen && { zIndex: zIndex.filterModalOpen }),
}));

export const Close = styled.div(() => ({
  position: 'fixed',
  inset: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 1,
}));
