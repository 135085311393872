/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { base } from 'src/theme';

/*
 * Style
 */
export const Form = styled.form`
  padding: 0;
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Button = styled.button(({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  margin: '.5rem',
  marginLeft: '0',
  width: 'fit-content',
  minWidth: 'unset',
  minHeight: 'unset',
  ...theme.typo.labels.smallLabel,
  padding: '.5rem 1rem',
  background: isMain ? theme.colors.success._500 : theme.background._2,
  color: isMain ? theme.colors.monochrome.white : theme.text._1,
  ':hover': {
    background: isMain ? theme.colors.success._600 : theme.background._3,
  },
}));
