/*
 * Package Import
 */
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

/*
 * Local Import
 */
import { animations, base } from 'src/theme';

/*
 * KeyFrames
 */
const fadeOutTop = keyframes(animations.fadeOutTop);
const fadeInBottom = keyframes(animations.fadeInBottom);

/*
 * Style
 */
export const StyledFragment = styled.div(({ isActiveTimer, theme }) => ({
  position: 'relative',
  background: theme.background._1,
  width: '28.625rem',
  minHeight: isActiveTimer ? '22rem' : '15rem',
  justifyContent: 'space-between',
  border: `1px solid ${theme.text._7}`,
  borderRadius: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  ...theme.shadows.plus3,
}));

export const Header = styled.h1(({ theme }) => ({
  color: theme.text._1,
  marginBottom: '.25rem',
  ...theme.typo.headlines.H5,
}));

export const Timer = styled.div(({ theme }) => ({
  color: theme.text._1,
}));

export const StyledSpan = styled.span(({ theme }) => ({
  marginBottom: '.5rem',
  ...theme.typo.bodyText.smallBody,
}));

export const TimerContainer = styled.div({
  width: '100%',
});

export const Elapsed = styled.div(({ isLate, theme }) => ({
  color: isLate ? theme.danger.main : theme.info.main,
  marginBottom: '2.5rem',
  ...theme.typo.headlines.H3,
}));

export const Message = styled.div(({ isActive, theme }) => ({
  animation: `.2s ${isActive ? fadeInBottom : fadeOutTop} both`,
  ...theme.typo.bodyText.body,
  marginBottom: '1rem',
  minHeight: '4rem',
}));

export const Buttons = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const StyledButton = styled.button(({ isMain, theme }) => ({
  ...base.button({ isMain, theme }),
  flex: 1,
}));
