/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { deactivateUser } from './deactivateUser';

/*
 * Code
 */
export const useDeactivateUser = (): UseMutationResult<void, unknown, string, unknown> => {
  const UserMutation = useMutation((userId: string) => deactivateUser(userId), {
    onSettled: () => {
      queryClient.invalidateQueries('users');
    },
    onSuccess: () => {
      toast('Utilisateur archivé avec succès !', { type: 'success' });
    },
  });

  return UserMutation;
};
