/*
 * Package import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Emoji as Emojimart } from 'emoji-mart';

/*
 * Local import
 */

/*
 * Code
 */
const emojiStyle = {
  overflow: 'hidden',
  display: 'inline-flex',
  verticalAlign: 'text-top',
  alignItems: 'center',
  objectFit: 'contain',
};

/*
 * Components
 */
const Emoji = ({ children, size }) => (
  <span
    className="formatizer-emoji"
    style={emojiStyle}
    dangerouslySetInnerHTML={{
      __html: Emojimart({
        html: true,
        set: 'apple',
        sheetSize: 64,
        backgroundImageFn: (set, sheetSize) =>
          `https://unpkg.com/emoji-datasource-${set}@5.0.1/img/${set}/sheets-256/${sheetSize}.png`,
        emoji: children,
        fallback: (emojiFound, props) =>
          // eslint-disable-next-line react/prop-types
          (emojiFound ? `:${emojiFound.short_names[0]}:` : props.emoji),
        size,
      }),
    }}
  />
);

/*
 * PropTypes
 */
Emoji.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Emoji.defaultProps = {
  size: 20,
};

/*
 * Export
 */
export default Emoji;
