/*
 * Package Import
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import * as F from 'src/components/Admin/Modals/FormElements';
import { getRoute } from 'src/utils/routes';
import { useStore } from 'src/localStateManagers/store';
import { ZodValidationSuccess, ZodValidationFailure } from 'src/schemas/Adapters/utils';
import { useCreateUsers } from 'src/serverStateManagers/mutations/create/Users/multi/useCreateUsers';
import { Option } from 'src/components/Admin/Modals/FormElements/type';
import { multipleUsersAddFormData } from '../config';
import { getSelectedUsersInCsv, FormError } from './utils';
import useCsvImportTable from './hooks/useCsvImportTable';

/*
 * Code
 */
type ConfirmationProps = {
  promotionSelected: Option | null;
};
const Confirmation = ({ promotionSelected }: ConfirmationProps): JSX.Element => {
  const { newUsers } = useStore();
  const createUsersMutation = useCreateUsers();
  const history = useHistory();
  const [tableInstance, CsvImportTable] = useCsvImportTable(newUsers);

  const [formErrors, setFormErrors] = useState([] as FormError[]);

  // Config
  const {
    header,
    getSubheading,
    caption,
    formErrors: confirmationFormErrors,
  } = multipleUsersAddFormData.confirmation;

  const { getSelectedRowModel } = tableInstance;
  const { flatRows: selectedFlatRows } = getSelectedRowModel();

  const rowIsDisabled = (zodResult: ZodValidationSuccess | ZodValidationFailure): boolean =>
    !zodResult.success;

  const handlePrevious = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    useStore.setState({ newUsers: [] });
    setFormErrors([]);
  };

  const handleSubmit = (evt: React.FormEvent): void => {
    evt.preventDefault();
    const newUsersSelected = getSelectedUsersInCsv(selectedFlatRows);

    if (!newUsersSelected.length) {
      setFormErrors((prevState) => [...prevState, confirmationFormErrors.noData]);
    }
    else {
      createUsersMutation.mutate(newUsersSelected);
      useStore.setState({ newUsers: [] });
      setFormErrors([]);
      history.push(getRoute('usersTable').getUrl({}));
    }
  };

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    useStore.setState({ newUsers: [] });
    setFormErrors([]);
    history.push(getRoute('usersTable').getUrl({}));
  };

  return (
    <F.Form onSubmit={handleSubmit}>
      <F.ImportUsersHeader>{header}</F.ImportUsersHeader>
      <F.Subheading>{getSubheading(promotionSelected)}</F.Subheading>
      <F.CsvCaptionContainer>
        <F.CsvCaption>
          {caption.informations.firstPart}
          <F.CsvConfirmationInfoCaptionIcon />
          {caption.informations.secondPart}
        </F.CsvCaption>
        <F.CsvCaption>
          {caption.anomalies.firstPart}
          <F.CsvConfirmationErrorCaptionIcon />
          {caption.anomalies.secondPart}
        </F.CsvCaption>
        <F.CsvCaption>{caption.checkbox}</F.CsvCaption>
        <F.CsvCaption>{caption.checkboxAnomaly}</F.CsvCaption>
        <F.CsvCaption>{caption.edit}</F.CsvCaption>
      </F.CsvCaptionContainer>
      <CsvImportTable tableInstance={tableInstance} rowIsDisabled={rowIsDisabled} />
      {!!formErrors.length
        && formErrors.map((formError) => (
          <F.ErrorMessage key={formError.type}>{formError.message}</F.ErrorMessage>
        ))}
      <F.Buttons>
        <Button type="button" onClick={handlePrevious} variant="secondary" fullWidth>
          Précédent
        </Button>
        <Button type="submit" variant="primary" onClick={handleSubmit} fullWidth>
          Confirmer
        </Button>
      </F.Buttons>
      <F.CloseButton onClick={handleClose}>
        <F.CloseIcon />
      </F.CloseButton>
    </F.Form>
  );
};

/*
 * Export
 */
export default Confirmation;
