/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { badges, adminRolesLabels } from 'src/components/Users/data/roles';

// Style
import * as S from './style';

/*
 * Component
 */
const Badge = ({ role }) => {
  /*
   * State
   */
  const BadgeIcon = badges[role];

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <S.BadgeContainer>
      <BadgeIcon role="img" aria-label={adminRolesLabels[role].label} />
    </S.BadgeContainer>
  );
};

/*
 * PropTypes
 */
Badge.propTypes = {
  role: PropTypes.string.isRequired,
};

/*
 * Export
 */
export default Badge;
