/**
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/**
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { getAvatar } from 'src/utils/avatar';

/**
 * Style
 */
const Image = styled.img(() => ({
  borderRadius: '8px',
  maxWidth: '2rem',
  height: '2rem',
  marginRight: '.5rem',
}));

const Container = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxHeight: '100%',
}));

const NameContainer = styled.div(() => ({
  maxWidth: '100%',
  overflow: 'hidden',
  position: 'relative',
}));

const Name = styled.div(() => ({
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const NameClone = styled.div(() => ({
  position: 'absolute',
  zIndex: -1,
  visibility: 'hidden',
}));

/**
 * Types
 */
interface UserFullLabelProps {
  avatar: string;
  firstname: string;
  lastname: string;
  rowId: string;
}

/**
 * Component
 */
export const UserFullLabel = ({
  avatar,
  firstname,
  lastname,
  rowId,
}: UserFullLabelProps): JSX.Element => {
  const userFullName = `${firstname} ${lastname}`;
  const userAvatar = getAvatar({ avatar, name: userFullName });
  const nameWidth = document.getElementById(`name-${rowId}`)?.offsetWidth;
  const cloneNameWidth = document.getElementById(`clone-name-${rowId}`)?.offsetWidth;

  return (
    <Container>
      <Image
        alt={`avatar de ${userFullName}`}
        src={userAvatar}
        onError={(event) => {
          if (event.currentTarget.src !== userAvatar) {
            event.currentTarget.src = getAvatar({ name: userFullName });
          }
        }}
      />
      <NameContainer>
        {nameWidth && cloneNameWidth && nameWidth < cloneNameWidth ? (
          <Tooltip content={`${firstname} ${lastname}`}>
            <Name id={`name-${rowId}`}>
              {firstname} {lastname}
            </Name>
          </Tooltip>
        ) : (
          <Name id={`name-${rowId}`}>
            {firstname} {lastname}
          </Name>
        )}
        <NameClone id={`clone-name-${rowId}`}>
          {firstname} {lastname}
        </NameClone>
      </NameContainer>
    </Container>
  );
};
