/**
 * Package Import
 */
import { useContext } from 'react';

/*
 * Local Import
 */
import { UserContext } from 'src/context/User';
import { roles, Role, RoleEnum } from 'src/constants/roles';
import { WrappedClient } from './WrappedClient';

/*
 * Code
 */
const useWrapClient = (): WrappedClient | null => {
  /*
   * Context
   */
  const { user } = useContext(UserContext);

  if (!user) return null;
  return {
    ...user,
    isMinRole: (minRoleName: RoleEnum): boolean => {
      const minRole = roles.find((role: Role) => role.name === minRoleName);
      const clientRole: Role | undefined = roles.find((role: Role) => role.name === user.role);

      if (!minRole || !clientRole) {
        return false;
      }

      return clientRole.score >= minRole.score;
    },
  };
};

export default useWrapClient;
