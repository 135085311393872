/**
 * Package Import
 */
import React, { AriaAttributes, useState, useEffect } from 'react';
import { ArrayField, FieldErrors } from 'react-hook-form';
import { Button } from '@oclock/crumble';

/**
 * Local Import
 */
import { ErrorMessage } from '../ErrorMessage';
import { Input } from '../Input';
import { emptySlot, getDuration } from './utils';
import * as S from './style';

/**
 * Types
 */
interface TimeslotProps extends AriaAttributes {
  index: number;
  slot: Partial<ArrayField<Record<string, string>, 'id'>>;
  handleDeleteSlot: () => void;
  deleteButtonIsDisabled: boolean;
  register: () => React.LegacyRef<HTMLInputElement> | undefined;
  watch: (inputPath: string) => Partial<ArrayField<Record<string, string>, 'id'>>;
  errors: FieldErrors;
}

interface TimeslotsListProps extends AriaAttributes {
  fields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  append: (value: Partial<Record<string, string>> | Partial<Record<string, string>>[]) => void;
  remove: (index?: number | number[] | undefined) => void;
  register: () => React.LegacyRef<HTMLInputElement> | undefined;
  watch: (inputPath: string) => Partial<ArrayField<Record<string, string>, 'id'>>;
  errors: FieldErrors;
}

/**
 * Components
 */
export const Timeslot = ({
  index,
  slot,
  handleDeleteSlot,
  deleteButtonIsDisabled,
  register,
  watch,
  errors,
}: TimeslotProps): JSX.Element => {
  const [duration, setDuration] = useState('');

  const values = watch(`timeslots[${index}]`);

  const timeslotsErrors = errors?.timeslots;

  useEffect(() => {
    const formattedDuration = getDuration(values.start, values.end);
    setDuration(formattedDuration);
  }, [values]);

  return (
    <S.TimeslotContainer isErrors={!!timeslotsErrors?.[index]}>
      <S.TimeslotLabel>Séance {index + 1}</S.TimeslotLabel>
      <S.InputsContainer>
        <Input
          ref={register()}
          name={`timeslots[${index}]._id`}
          type="hidden"
          defaultValue={slot?._id ?? undefined}
        />
        <S.TimeInput
          type="time"
          name={`timeslots[${index}].start`}
          ref={register()}
          defaultValue={slot.start}
          className={
            timeslotsErrors?.[index]?.start || timeslotsErrors?.[index]?.message
              ? 'is-error'
              : undefined
          }
        />
        <S.InterBlock>à</S.InterBlock>
        <S.TimeInput
          type="time"
          name={`timeslots[${index}].end`}
          ref={register()}
          defaultValue={slot.end}
          className={
            timeslotsErrors?.[index]?.end || timeslotsErrors?.[index]?.message
              ? 'is-error'
              : undefined
          }
        />
        <S.Duration>{`Durée : ${duration}`}</S.Duration>
        <Button
          icon="TrashAlt"
          variant="text"
          onClick={handleDeleteSlot}
          disabled={deleteButtonIsDisabled}
        />
      </S.InputsContainer>
      {!!timeslotsErrors?.[index] && (
        <>
          {timeslotsErrors[index]?.message && (
            <ErrorMessage>{timeslotsErrors[index].message}</ErrorMessage>
          )}
          {timeslotsErrors[index].start?.message && (
            <ErrorMessage>{timeslotsErrors[index].start.message}</ErrorMessage>
          )}
          {timeslotsErrors[index].end?.message && (
            <ErrorMessage>{timeslotsErrors[index].end.message}</ErrorMessage>
          )}
        </>
      )}
    </S.TimeslotContainer>
  );
};

export const TimeslotsList = ({
  fields,
  append,
  remove,
  register,
  watch,
  errors,
}: TimeslotsListProps): JSX.Element => {
  const deleteButtonIsDisabled = fields.length <= 1;

  const handleAddSlot = () => {
    append(emptySlot);
  };

  const handleDeleteSlot = (currentIndex: number) => () => {
    if (!deleteButtonIsDisabled) {
      remove(currentIndex);
    }
  };

  return (
    <>
      <S.Header>
        <S.Title>Séances</S.Title>
        <Button variant="text" onClick={handleAddSlot}>
          Ajouter une séance
        </Button>
      </S.Header>
      <S.Container>
        {fields.map((slot, index) => (
          <Timeslot
            index={index}
            key={slot.fieldId}
            slot={slot}
            handleDeleteSlot={handleDeleteSlot(index)}
            deleteButtonIsDisabled={deleteButtonIsDisabled}
            register={register}
            watch={watch}
            errors={errors}
          />
        ))}
      </S.Container>
    </>
  );
};
