/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { UserDisplayBeforeCreate, UserDTO } from 'src/schemas/Entities/User';
import { createUsers } from './createUsers';

/*
 * Code
 */
export const useCreateUsers = (): UseMutationResult<
UserDTO[],
unknown,
UserDisplayBeforeCreate[],
unknown
> => {
  const userMutation = useMutation((users: UserDisplayBeforeCreate[]) => createUsers(users), {
    onSettled: () => {
      queryClient.invalidateQueries('users');
    },
    onSuccess: () => {
      toast('Utilisateurs créés avec succès, une invitation par mail leur a été envoyée !', {
        type: 'success',
      });
    },
  });

  return userMutation;
};
