/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Webcam from 'src/components/Course/Webcams/Webcam';
import {
  sendTakeVoice,
  connectTranscription,
  disconnectTranscription,
  setPopoutCam,
  enableWebcam,
  disableWebcam,
  enableSound,
  disableSound,
  startMyWebcamStream,
  stopMyWebcamStream,
} from 'src/store/actions';
import { isTeacher, isStudent, isGhost } from 'src/utils/roles';

/*
 * Code
 */
const mapStateToProps = (state, { userId }) => {
  const { client, userById } = state.users;
  const { soundEnabled, microphoneEnabled } = state.course.medias;
  const { courseId, peerById, usersWebcamMuted } = state.course;
  const { id, role: myRole } = client;
  const user = state.course.peers.find((peer) => peer === userId);
  const { role } = peerById[user];
  const { name, connected: isUserConnected, avatar, away: isUserAway } = userById[userId];
  const popoutCam = state.settings.popout.popoutCamById[userId];
  const { streamList, antmediaLocalStream } = state.webRTC;

  return {
    antServer: state.course.antServer,
    soundEnabled,
    microphoneEnabled,
    isMyWebcam: id === userId,
    handStatus: peerById[user]?.hand,
    isStudentWebcam: isStudent(role) || isGhost(role),
    isTeacherWebcam: isTeacher(role),
    isClientTeacher: isTeacher(myRole),
    isClientStudent: isStudent(myRole) || isGhost(role),
    isUserConnected,
    name,
    avatar,
    courseId,
    popoutCam,
    webcamMuted: usersWebcamMuted.includes(userId),
    isUserAway,
    streamList: streamList.cams,
    isLocalStreamReady: antmediaLocalStream,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      sendTakeVoice,
      setPopoutCam,
      connectTranscription,
      disconnectTranscription,
      enableWebcam,
      disableWebcam,
      enableSound,
      disableSound,
      startMyWebcamStream,
      stopMyWebcamStream,
    },
    dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Webcam);
