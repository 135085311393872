/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const ClassEmotions = styled('div')((props) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: '-0.5rem',
  top: '-0.5rem',
  borderRadius: '50%',
  height: '1.2rem',
  width: '1.2rem',
  ...props.theme.shadows.plus1, // Box Shadow
  backgroundColor: props.theme.background._2,
  zIndex: '1',
}));
