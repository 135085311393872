/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';
import Tooltip from 'src/components/Tooltip';
import { getAccessVMStrings } from 'src/constants/status';

/*
 * Styles
 */
const getBodyText = (theme: ThemeInterface): Record<symbol, string> => theme.typo.bodyText.body;
const AccessVMStatusLabel = styled.span(({ theme }) => ({
  ...getBodyText(theme),
  '&.access-vm-enabled': {
    color: theme.success.main,
  },
  '&.access-vm-disabled': {
    color: theme.danger.main,
  },
}));

const Container = styled.div(() => ({
  width: 'fit-content',
}));

/*
 * Types
 */
type AccessVMStatusProps = {
  status: boolean;
};

/*
 * Components
 */
export const AccessVMStatus = ({ status }: AccessVMStatusProps): JSX.Element => {
  const { statusString, tooltipString } = getAccessVMStrings(status);
  return (
    <Container>
      <Tooltip content={tooltipString}>
        <AccessVMStatusLabel className={status ? 'access-vm-enabled' : 'access-vm-disabled'}>
          {statusString}
        </AccessVMStatusLabel>
      </Tooltip>
    </Container>
  );
};
