/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import CreatePoll from 'src/components/Chats/Poll/Create';
import { closeModal, askSurvey } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  display: state.modal.modalProps.display,
  generalId: state.chats.general,
});

// Actions
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  actions: bindActionCreators(
    {
      askSurvey: askSurvey({ chatId: stateProps.generalId }),
      closeModal,
    },
    dispatchProps.dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, null, mergeProps)(CreatePoll);
