import { format } from 'date-fns';
import * as z from 'zod';

import { concatStringListNicely } from 'src/utils/string';

/**
 * Mongo Id
 */
const mongoIdRegex = new RegExp(/^[a-fA-F0-9]{24}$/);
const mongoIdParams: [RegExp, { message: string }] = [
  mongoIdRegex,
  { message: "L'id n'a pas la forme attendue" },
];

export const mongoIdSchema = z.string().regex(...mongoIdParams);
export type MongoId = z.infer<typeof mongoIdSchema>;

/**
 * Date string
 */
const dateRegex = new RegExp(/^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/);
export const dateParams: [RegExp, { message: string }] = [
  dateRegex,
  { message: "La date n'a pas le bon format" },
];
export const dateStringSchema = z.string().regex(...dateParams);
export type DateString = z.infer<typeof dateStringSchema>;

/**
 * Date time params
 */
const datetimeRegex = new RegExp(
  // eslint-disable-next-line max-len
  /^(\d{4,})[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])[T ](\d{2}):(\d{2})(?::(\d{2}(?:\.\d+)?))?$/,
);
export const datetimeParams: [RegExp, { message: string }] = [
  datetimeRegex,
  { message: "La date n'a pas le bon format" },
];

/**
 * Time string
 */
const timeRegex = new RegExp(/([0-1][0-9])|(2[0-3]):[0-5][0-9]/);
const timeSchema = z.string().regex(timeRegex);
export type TimeString = z.infer<typeof timeSchema>;

/**
 * Duration string
 */
// Course max duration allowed is 24h00
const durationRegex = new RegExp(/([0-23]h[0-5][0-9])|(24h00)/);
const durationSchema = z.string().regex(durationRegex);
export type DurationString = z.infer<typeof durationSchema>;

/**
 * Edusign Status
 */
export enum EdusignStatusEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}
export const EdusignStatusSchema = z.enum([EdusignStatusEnum.SUCCESS, EdusignStatusEnum.ERROR]);
export type EdusignStatus = z.infer<typeof EdusignStatusSchema>;

export const getIntervalMessages = (intervals: Interval[]) => {
  const messages = intervals.map(
    (interval) => `${format(interval.start, 'HH:mm')} à ${format(interval.end, 'HH:mm')}`,
  );
  const messagesJoined = concatStringListNicely({ stringArray: messages });
  return messagesJoined;
};
