/*
 * Package Import
 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NewWindow from 'react-new-window';
import { useSelector } from 'react-redux';
import { MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import AudioLevel from 'src/components/Course/AudioLevel/container';

// utils
import { getOrganization } from 'src/store/selectors/organization';
import { getAvatar } from 'src/utils/avatar';
import {
  MediaStreamDictionnary,
  StreamCategory,
} from 'src/utils/webRTCManager/mediaStreamDictionnary';
import devicesMediasRTC from 'src/utils/medias';

// Constants
import { CLASSROOM_WEBCAM_TITLE } from 'src/constants/titles';
import { STREAM_TYPES, HAND_STATUS } from 'src/constants/conference';

// Style
import * as S from './style';

/**
 * Code
 */
const menuLabels = {
  enableSound: 'Activer le son',
  disableSound: 'Couper le son',
  enableWebcam: 'Activer la caméra',
  disableWebcam: 'Désactiver la caméra',
  restartPlayVideo: 'Relancer la video',
  displayExternalWindow: 'Ouvrir en miniature',
  takenVoice: 'Couper la parole',
};

/*
 * Component
 */
const Webcam = ({
  antServer,
  actions,
  soundEnabled,
  microphoneEnabled,
  handStatus,
  isUserConnected,
  popoutCam,
  courseId,
  webcamMuted,
  isUserAway,
  streamList,
  isLocalStreamReady,
  ...userProps
}) => {
  /*
   * Props
   */
  const { isStudentWebcam, isMyWebcam, userId, name, avatar } = userProps;

  /*
   * State
   */
  const [isVideoMuted, setVideoMute] = useState(userProps.isStudentWebcam);
  const [isAudioMuted, setAudioMute] = useState(false);
  const [isVideoPlayed, setVideoPlayed] = useState(true);
  const [loading, setLoading] = useState(!isMyWebcam);

  /*
   * Refs
   */
  const videoTag = useRef();

  /*
   * Vars
   */
  const { orga, ant_url: ANT_URL } = useSelector(getOrganization);
  const AudioButton = S.Button({
    id: STREAM_TYPES.AUDIO,
    isMute: !soundEnabled,
  });
  const VideoButton = S.Button({ id: STREAM_TYPES.VIDEO, isMute: isVideoMuted });
  const VideoPlayButton = S.Button({ id: 'videoPlay', isMute: !isVideoPlayed });
  const targetStreamId = `${orga}-${courseId}-${userId}-cams`;

  /**
   * Enable/Disable video stream only
   * @return {void}
   */
  const toggleVideoMute = () => {
    // Get Video tag
    const remoteVideo = videoTag.current;
    // Get tracks from video stream
    const streams = remoteVideo?.srcObject?.getTracks();

    if (streams?.length) {
      // Get only video stream track
      const videoTrack = streams.filter(({ kind }) => kind === STREAM_TYPES.VIDEO);

      if (videoTrack.length) {
        videoTrack[0].enabled = !videoTrack[0].enabled;
        setVideoMute(!isVideoMuted);
      }
    }
  };

  /**
   * Enable/Disable audio stream only
   * @return {void}
   */
  const toggleAudioMute = () => {
    // Get Video tag
    setAudioMute(!isAudioMuted);
    if (videoTag.current) {
      videoTag.current.muted = isAudioMuted;
    }
  };

  /**
   * Stop speaking as student
   * @return {void}
   */
  const handleEndSpeak = () => {
    actions.sendTakeVoice(userId);
  };

  /**
   * Play video when is paused
   *  @return {void}
   */
  const toggleVideoPlay = () => {
    const ID = isMyWebcam ? 'localView' : targetStreamId;
    const promise = document.getElementById(ID).play();

    if (promise !== undefined) {
      promise
        .then(() => {
          // Autoplay started!
        })
        .catch(() => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
        });
    }
  };

  /**
   * Handle called when video is paused
   * @return {void}
   */
  const handleVideoPaused = (status) => () => {
    setVideoPlayed(status);
  };

  const displayExternalWindow = () => {
    actions.setPopoutCam(userId, true);
  };

  const closeExternalWindow = () => {
    actions.setPopoutCam(userId, false);
  };

  /**
   * Hand Status lifecycle
   */
  useEffect(() => {
    // if I'm a student drawing my own camera...
    if (isMyWebcam && isStudentWebcam) {
      // ... and voice is given to me...
      if (handStatus === HAND_STATUS.VOICE_GIVEN && isLocalStreamReady) {
        // stream it with my webcam disabled

        if (devicesMediasRTC.hasWebcamPermissions) {
          actions.disableWebcam();
        }
        actions.startMyWebcamStream({ audioOnly: !devicesMediasRTC.hasWebcamPermissions });
        if (microphoneEnabled) {
          // ensure transcription start when student have voice given
          // only if microphone is enabled
          actions.connectTranscription();
        }
      }
      else {
        // otherwise, don't ;)
        actions.disconnectTranscription();
        actions.stopMyWebcamStream();
      }
    }
  }, [handStatus, isLocalStreamReady]);

  /**
   * Webcam stream lifecycle
   */
  useEffect(() => {
    // When the available streams list is updated
    const mediaStreamObject = MediaStreamDictionnary.getStream(StreamCategory.CAM, targetStreamId);
    if (mediaStreamObject && isLocalStreamReady) {
      // put the stream in the video element !
      videoTag.current.srcObject = mediaStreamObject;
      setLoading(false);
    }
    else {
      // if we dont have ane stream (and it's not my webcam), it might be loading
      setLoading(!isMyWebcam);
    }
  }, [streamList, isLocalStreamReady]);

  const isDisplayed = (!isStudentWebcam && !webcamMuted && isUserConnected && !isUserAway)
    || (isStudentWebcam && handStatus === HAND_STATUS.VOICE_GIVEN && !isUserAway);

  // @TODO : déplacer le menu dans un composant à part
  /*
   * Render
   */
  return (
    <>
      <S.Webcam isDisplayed={isDisplayed} isOpenPopout={popoutCam}>
        {/* Webcam */}
        <S.Video
          id={isMyWebcam ? 'localView' : targetStreamId}
          tabIndex="-1"
          autoPlay
          playsInline
          muted={isMyWebcam || !soundEnabled || isAudioMuted}
          onPause={handleVideoPaused(true)}
          onPlay={handleVideoPaused(false)}
          ref={videoTag}
        />

        {loading && (
          <S.Loading>
            <Loader />
          </S.Loading>
        )}

        {isStudentWebcam && webcamMuted && !loading && (
          <S.AvatarContainer>
            <S.Avatar src={getAvatar({ avatar, name })} />
          </S.AvatarContainer>
        )}

        {/* Controls */}
        {!isMyWebcam && isUserConnected && (
          <S.Menu
            align="start"
            direction="left"
            portal
            menuButton={(
              <S.MenuButton aria-label={`Options concernant le contenu multimédia de ${name}`}>
                <S.StyledUilMenu aria-hidden />
              </S.MenuButton>
            )}
          >
            <MenuItem onClick={displayExternalWindow}>
              <S.ContainerLabel>
                <S.IconExternalWindow aria-hidden />
                {menuLabels.displayExternalWindow}
              </S.ContainerLabel>
            </MenuItem>
            <MenuItem onClick={toggleAudioMute}>
              <S.ContainerLabel>
                <AudioButton aria-hidden />
                {isAudioMuted ? menuLabels.enableSound : menuLabels.disableSound}
              </S.ContainerLabel>
            </MenuItem>
            {!userProps.isStudentWebcam && (
              <MenuItem onClick={toggleVideoMute}>
                <S.ContainerLabel>
                  <VideoButton aria-hidden />
                  {isVideoMuted ? menuLabels.enableWebcam : menuLabels.disableWebcam}
                </S.ContainerLabel>
              </MenuItem>
            )}
            {isVideoPlayed && (
              <MenuItem onClick={toggleVideoPlay}>
                <S.ContainerLabel>
                  <VideoPlayButton />
                  {menuLabels.restartPlayVideo}
                </S.ContainerLabel>
              </MenuItem>
            )}
            {userProps.isClientTeacher && isStudentWebcam && (
              <MenuItem onClick={handleEndSpeak}>
                <S.ContainerLabel>
                  <S.IconHand aria-hidden />
                  {menuLabels.takenVoice}
                </S.ContainerLabel>
              </MenuItem>
            )}
          </S.Menu>
        )}
        <S.Name aria-hidden>{name}</S.Name>
        <AudioLevel userId={userId} />
      </S.Webcam>

      {/* External popout */}
      {popoutCam && (
        <NewWindow onUnload={closeExternalWindow} title={CLASSROOM_WEBCAM_TITLE}>
          <iframe
            width="100%"
            height="100%"
            src={`${antServer ? `https://${antServer}.slippers.live` : ANT_URL}:/streamCam/play.html?name=${targetStreamId}`}
            frameBorder="0"
            allowFullScreen
            title={userId}
          />
        </NewWindow>
      )}
    </>
  );
};

/*
 * PropTypes
 */
Webcam.propTypes = {
  antServer: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  courseId: PropTypes.string.isRequired,
  /** Is this webcam mine ? */
  isMyWebcam: PropTypes.bool.isRequired,
  /** Is audio enabled ? */
  soundEnabled: PropTypes.bool.isRequired,
  /** Is microphone enabled ? */
  microphoneEnabled: PropTypes.bool.isRequired,
  /** Webcam user id */
  userId: PropTypes.string.isRequired,
  /** Webcam user hand status */
  handStatus: PropTypes.string.isRequired,
  /** Is current user a teacher ? */
  isClientTeacher: PropTypes.bool.isRequired,
  /** Is webcam user connected ? */
  isUserConnected: PropTypes.bool.isRequired,
  /** Name of user's webcam */
  name: PropTypes.string.isRequired,

  popoutCam: PropTypes.bool,
  /** Is webcam muted */
  webcamMuted: PropTypes.bool.isRequired,
  /** Is user's away */
  isUserAway: PropTypes.bool.isRequired,
  /** Available streams list */
  streamList: PropTypes.array.isRequired,
  isLocalStreamReady: PropTypes.bool.isRequired,
};

Webcam.defaultProps = {
  antServer: null,
  popoutCam: false,
};

/*
 * Export
 */
export default Webcam;
