/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Transcriptions from 'src/components/Course/Transcriptions/container';
import Controls from 'src/components/Course/Controls/container';

// Style
import * as S from './style';

/*
 * Component
 */
const CoursePanel = ({ transcriptionEnabled, popoutScreen }) => {
  /*
   * State
   */
  const [displayActions, setDisplayActions] = useState(
    /** @type {Boolean} */
    true,
  );

  /**
   * Show the actions panel
   * @return {void}
   */
  const showActions = () => setDisplayActions(true);

  /**
   * Hide the actions panel
   * @return {void}
   */
  const hideActions = () => setDisplayActions(false);

  /**
   * Handle the action panel. Allows more space to display
   * the transcription component
   * @return {void}
   */
  const handleDisplayActions = () => {
    if (displayActions) {
      hideActions();
    }
    else {
      showActions();
    }
  };

  /*
   * LifeCycles
   */
  useEffect(() => {
    if (transcriptionEnabled) {
      if (!popoutScreen) {
        hideActions();
      }
      else {
        showActions();
      }
    }
    else {
      showActions();
    }
  }, [transcriptionEnabled, popoutScreen]);

  /*
   * Render
   */
  return (
    <S.Container isMenuDisplay={displayActions}>
      {/* Transcriptions */}
      {transcriptionEnabled && !popoutScreen && <Transcriptions />}

      {/* Controls */}
      <S.Button
        type="button"
        aria-expanded={displayActions}
        aria-label="Contrôles multimédias de la présentation"
        isMenuDisplay={displayActions}
        onClick={handleDisplayActions}
      >
        <S.IconArrow aria-hidden />
      </S.Button>
      <Controls isMenuDisplay={displayActions} />
    </S.Container>
  );
};

/*
 * PropTypes
 */
CoursePanel.propTypes = {
  /** Is transcription enabled ? */
  transcriptionEnabled: PropTypes.bool.isRequired,
  /** Popout is open or not */
  popoutScreen: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default CoursePanel;
