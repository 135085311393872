/**
 * Pad
 */
export const pad = (str: string | number) =>
  ((typeof str === 'string' && str !== '') || typeof str === 'number' ? `0${str}`.slice(-2) : str);

/**
 * Sort elements of an array
 */
export function sort<T>(array: T[]): T[] {
  return array.sort((elemA, elemB) => {
    if (elemA > elemB) {
      return 1;
    }
    if (elemA < elemB) {
      return -1;
    }
    return 0;
  });
}

/**
 * Check if value exists and is filled
 */
export const isEmpty = (value: unknown): boolean =>
  !value || typeof value === 'undefined' || (typeof value === 'string' && value.trim() === '');

/**
 * getPercent
 */
export const getPercent = (count: number, total: number): number => (count * 100) / total || 0;

/**
 * Set focus on element
 */
export const setFocus = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    element.focus();
  }
};

/**
 * Simple object check.
 */
export const isObject = (item: unknown): boolean =>
  !!item && typeof item === 'object' && !Array.isArray(item);

/**
 * Deep merge two objects.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeDeep = (target: any, ...sources: any): any => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      }
      else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return mergeDeep(target, ...sources);
};
