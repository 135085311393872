/*
 * Package Import
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import { Picker } from 'src/components/Format/Formatizer';
import Reaction from 'src/components/Chats/Channel/Chat/Message/Reactions/Reaction/container';
import { useReaction } from 'src/components/Classroom/hooks';

// Style
import { ContainerPicker } from 'src/components/Chats/Channel/Chat/Input/Picker/style';
import * as S from './style';

/*
 * Component
 */
const Reactions = ({ reactions, ...messageProps }) => {
  const { messageId, userId } = messageProps;
  const { handleReaction } = useReaction();

  /*
   * Refs
   */
  const newReactionRef = useRef(null);

  /*
   * State
   */
  const [isDisplay, setDisplay] = useState(
    /** @type {Boolean} · Display state */
    false,
  );

  const [displayModal, setDisplayModal] = useState(
    /** @type {Boolean} · State for modal */
    false,
  );

  const [top, setTop] = useState(
    /** @type {string} · Position picker • Axis Y */
    '2rem',
  );

  const [left, setLeft] = useState(
    /** @type {string} · Position picker • Axis X */
    '1rem',
  );

  /*
   * Function
   */
  const getPosition = () => {
    if (newReactionRef.current) {
      const positionY = newReactionRef.current.getBoundingClientRect().y;

      if (positionY < 470) {
        setTop('.25rem');
      }
      else {
        setTop(`${(positionY - 470) / 16}rem`);
      }

      // eslint-disable-next-line max-len
      const positionRight = newReactionRef.current.getBoundingClientRect().right;
      const totalWidth = window.innerWidth;
      const positionRightButton = totalWidth - positionRight;

      // Note: Modal don't accept right position, so calcul left …
      if (positionRightButton < 93) {
        setLeft(`${(totalWidth - 348) / 16}rem`);
      }
      else {
        setLeft(`${(totalWidth - 340 - positionRightButton + 85) / 16}rem`);
      }
    }
  };

  /**
   * Display the picker modal
   * @return {void}
   */
  const handleOpenModal = () => {
    getPosition();
    setDisplayModal(true);
  };

  /**
   * Hide the picker modal
   * @return {void}
   */
  const hideModal = () => {
    setDisplay(false);
    setDisplayModal(false);
  };

  /**
   * Handle an emoji from Picker
   * @param  {Object} emoji
   * @return {void}
   */
  const handleEmoji = (emoji) => {
    handleReaction({ messageId, userId, reaction: emoji.id });
    hideModal(false);
  };

  /*
   * Render
   */
  if (!reactions?.length) {
    return null;
  }

  return (
    <S.Reactions role="group" aria-label="Liste des réactions">
      {/* Reactions list */}
      {reactions.map((reaction) => (
        <Reaction key={`key-reaction-${reaction.name}`} {...messageProps} {...reaction} />
      ))}

      {/* Add reaction · Icon Picker Emoji */}
      <Tooltip content="Ajouter une réaction" options={{ delay: 300 }}>
        <S.Button
          type="button"
          ref={newReactionRef}
          onClick={handleOpenModal}
          // a11y
          aria-label="Ajouter une réaction..."
          aria-haspopup="true"
          aria-expanded={isDisplay}
        >
          <S.IconAdd />
        </S.Button>
      </Tooltip>

      {/* Picker Modal */}
      {displayModal && (
        <S.ReactionsModal
          isOpen={displayModal}
          onRequestClose={hideModal}
          top={top}
          left={left}
          ariaLabel="Emojis"
        >
          <ContainerPicker>
            <Picker onChange={handleEmoji} />
          </ContainerPicker>
        </S.ReactionsModal>
      )}
    </S.Reactions>
  );
};

/*
 * PropTypes
 */
Reactions.propTypes = {
  reactions: PropTypes.array.isRequired,
};

/*
 * Export
 */
export default React.memo(Reactions);
