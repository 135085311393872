/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Medias from 'src/components/Medias';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  cameraId: state.course.medias.cameraId,
  microphoneId: state.course.medias.microphoneId,
});

/*
 * Export
 */
export default connect(mapStateToProps, null)(Medias);
