/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Package imports
 */
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import wildcard from 'socketio-wildcard';

/**
 * Local imports
 */
import { CONNECTION_RETRY_DELAY, MANUAL_DISCONNECT_EVENT } from 'src/constants/websocket';
import { getOrganization } from 'src/store/selectors/organization';
import { getKcToken } from 'src/store/selectors/kcToken';
import { getClient } from 'src/store/selectors/users';
import * as types from 'src/store/types';

export const SocketContext = createContext({});

/**
 * Socket instance that can be passed to middleware.
 * Exporting a let is not ideal but is good enough
 */
// eslint-disable-next-line import/no-mutable-exports
export let socketInstance = null;

let lastSocketError = null;

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();

  const retryTimeoutRef = useRef(null);
  const [courseId, setCourseId] = useState(null);
  const [webSocket, setWebsocket] = useState(null);
  const { id: clientId } = useSelector(getClient);
  const { socket_url: SOCKET_URL, orga } = useSelector(getOrganization);
  const kcToken = useSelector(getKcToken);

  const room = useMemo(() => `${orga}-${courseId}`, [orga, courseId]);

  /**
   * Disconnects websocket connection
   * @returns void
   */
  const disconnectWebsocket = useCallback(() => {
    if (webSocket) {
      webSocket.disconnect();
      setWebsocket(null);
    }
  }, [webSocket]);

  /**
   * Handle connection errors
   * Display error toast
   * Try to reconnect after CONNECTION_RETRY_DELAY
   */
  const onConnectionError = useCallback(
    (error) => {
      dispatch({ type: types.WEBSOCKET_CONNECT_ERROR });

      console.error(error);

      if (error.message !== lastSocketError) {
        toast.error(error.message || 'Une erreur est survenue avec la connection au websocket.');
      }

      lastSocketError = error.message;

      if (retryTimeoutRef.current) clearTimeout(retryTimeoutRef.current);

      retryTimeoutRef.current = setTimeout(() => {
        connectWebsocket();
      }, CONNECTION_RETRY_DELAY);
    },
    [connectWebsocket, kcToken, lastSocketError, webSocket],
  );

  /**
   * Try reconnecting on websocket disconnect, only if it wasn't disconnected by the client
   */
  const onDisconnect = useCallback(
    (reason) => {
      if (reason !== MANUAL_DISCONNECT_EVENT) {
        console.error('Websocket disconnected, reason:', reason);
        socketInstance.connect();
      }
    },
    [socketInstance],
  );

  /**
   * Start websocket connection
   * @returns void
   */
  const connectWebsocket = useCallback(() => {
    if (!webSocket) {
      const isPopout = window.location.href?.includes('chat');

      const options = {
        auth: {
          token: `Bearer ${kcToken}`,
          isPopout,
          room,
          courseId,
        },
        autoConnect: false,
        reconnection: false,
        transports: ['websocket'],
      };

      socketInstance = io(SOCKET_URL, options);

      // Allow websocket to use '*' as event on listener
      wildcard(io.Manager)(socketInstance);

      socketInstance.on('connect', () => {
        dispatch({ type: types.WEBSOCKET_CONNECT_SUCCESS });
        lastSocketError = null;
      });

      socketInstance.on('disconnect', onDisconnect);
      socketInstance.on('connect_error', onConnectionError);

      socketInstance.on('*', (packet) => {
        const [type, payload] = packet.data;
        return dispatch({ type, ...payload });
      });

      socketInstance.connect();

      setWebsocket(socketInstance);

      dispatch({ type: types.WEBSOCKET_CONNECT, client: clientId });
    }
  }, [courseId, room, kcToken, webSocket, clientId, onConnectionError, onDisconnect]);

  // Déconnexion du websocket au moment du démontage du provider
  useEffect(() => {
    if (courseId && room && clientId && !webSocket) {
      connectWebsocket();
    }

    return () => {
      disconnectWebsocket();
    };
  }, [courseId, clientId, webSocket]);

  // Updates socket auth token when refreshed
  useEffect(() => {
    if (webSocket && kcToken) {
      socketInstance.auth.token = `Bearer ${kcToken}`;
      socketInstance.connect();
      setWebsocket(socketInstance);
    }
  }, [kcToken]);

  const value = useMemo(
    () => ({
      setCourseId,
      webSocket,
    }),
    [webSocket],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
