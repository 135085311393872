/*
 * Package Import
 */
import produce from 'immer';
import { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';
import { CourseRoleEnum, COURSE_ROLE } from 'src/constants/roles';

/**
 * Types
 */

export type EmotionUserProps = 'none' | ':sob:' | ':sleeping:';

export interface UserProps {
  id: string;
  name: string;
  role: CourseRoleEnum;
  avatar?: string;
  away: boolean;
  connected: boolean;
  emotion?: EmotionUserProps;
}

export interface UserState {
  client: {
    id: string;
    role: CourseRoleEnum;
  };
  usersIds: string[];
  userById: Record<string, UserProps>;
  mugshotById: Record<string, string>;
}

/*
 * Initial State
 */
const initialState: UserState = {
  client: {
    id: '',
    role: COURSE_ROLE.ROLE_STUDENT,
  },
  usersIds: [],
  userById: {},
  mugshotById: {},
};

/*
 * Reducer
 */

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      /*
       * Initialize data
       */
      case types.INITIALIZE_DATA:
        draft.usersIds = action.data.users.ids;
        draft.userById = action.data.users.byId;
        draft.client.role = action.data.users.byId[state.client.id].role;
        break;

      /**
       * User has joined the course
       */
      case types.HAS_JOINED:
        // In case the user does not exist in store, create it
        if (!state.usersIds.includes(action.user.id)) {
          draft.usersIds.push(action.user.id);
        }

        draft.userById[action.user.id] = action.user;
        draft.userById[action.user.id].connected = true;

        break;

      /**
       * User has leaved the course
       */
      case types.HAS_LEFT:
        if (state.usersIds.includes(action.user)) {
          draft.userById[action.user].connected = false;
        }
        break;

      case types.RECEIVE_CONNECTED_USERS:
        draft.userById[state.client.id].connected = true;
        // Updates connection status
        Object.keys(action.clients).forEach((user) => {
          if (state.usersIds.includes(user)) {
            draft.userById[user].connected = true;
          }
        });
        break;

      /*
       * Handlers
       */
      case types.LOG_USER_IN: {
        const { id, role } = action.user;
        draft.client = { ...state.client, id, role };
        break;
      }

      case types.MAIN_CONNEXION_CLOSED: {
        window.close();
        break;
      }

      /**
       * User mugshot
       */
      case types.SEND_MUGSHOT:
        if (state.usersIds.includes(action.user)) {
          draft.mugshotById[action.user] = action.mugshot;
        }
        break;

      /**
       * User status (away)
       */
      case types.CHANGE_AWAY:
        if (state.usersIds.includes(action.user)) {
          draft.userById[action.user].away = action.away;
        }
        break;

      /**
       * User emotion
       */
      case types.CHANGE_EMOTION:
        if (state.usersIds.includes(action.user)) {
          draft.userById[action.user].emotion = action.emotion;
        }
        break;

      default:
        return state;
    }
  });
