/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilMicrophone,
  UilMicrophoneSlash,
  UilVolume,
  UilVolumeMute,
  UilVideo,
  UilVideoSlash,
  UilDesktop,
  UilDesktopSlash,
  UilSignout,
  UilCommentAltLock,
  UilCommentsAlt,
} from '@iconscout/react-unicons';

/*
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';
import { HAND_STATUS } from 'src/constants/conference';
import { CONTROLS_ID } from 'src/constants/controls';

// Assets
import { ReactComponent as Hand } from 'assets/images/icons/hand.svg';

/*
 * Code
 */
const icons = {
  [CONTROLS_ID.MICROPHONE]: {
    on: UilMicrophone,
    off: UilMicrophoneSlash,
  },
  [CONTROLS_ID.SOUND]: {
    on: UilVolume,
    off: UilVolumeMute,
  },
  [CONTROLS_ID.WEBCAM]: {
    on: UilVideo,
    off: UilVideoSlash,
  },
  [CONTROLS_ID.SCREEN]: {
    id: 'screen',
    text: "Partage d'écran",
    on: UilDesktop,
    off: UilDesktopSlash,
  },
  [CONTROLS_ID.HAND]: {
    text: 'Parole',
    on: Hand,
  },
  [CONTROLS_ID.CONFERENCE]: {
    on: UilCommentsAlt,
    off: UilCommentAltLock,
  },
  [CONTROLS_ID.LEAVE]: {
    on: UilSignout,
    off: UilSignout,
  },
};

export const ActionContainer = styled.li({
  position: 'relative',
  alignItems: 'center',
  margin: '0 0.5rem',
  marginTop: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  ':first-of-type': { marginLeft: 0 },
  ':last-of-type': { marginRight: 0 },
});

export const Action = styled.button(
  {
    width: '4rem',
    height: '4rem',
    borderRadius: '16px',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '.5rem',
  },
  ({ theme, isActive, isDisabled }) => {
    let style;

    if (isActive === HAND_STATUS.VOICE_GIVEN) {
      style = {
        background: theme.success.background,
        color: theme.success.main,
        ':hover': {
          background: theme.success.hover,
        },
      };
    }
    else if (isActive === HAND_STATUS.RAISED) {
      style = {
        background: theme.info.background,
        color: theme.info.main,
        ':hover': {
          background: theme.info.hover,
        },
      };
    }
    else if (isActive === HAND_STATUS.UNRAISED) {
      style = {
        background: theme.background._2,
        color: theme.text._1,
      };
    }

    if (isDisabled) {
      style = {
        background:
          theme.themeName === LIGHT_THEME_NAME ? theme.background._4 : theme.background._2,
        color: theme.themeName === LIGHT_THEME_NAME ? theme.text._7 : theme.text._8,
        boxShadow: 'none',
        cursor: 'default',
        ':hover': {
          background:
            theme.themeName === LIGHT_THEME_NAME ? theme.background._4 : theme.background._2,
          color: theme.themeName === LIGHT_THEME_NAME ? theme.text._7 : theme.text._8,
        },
      };
    }

    return {
      boxShadow: `${theme.shadows.plus1.boxShadow} !important`,
      background: isActive ? theme.background._2 : theme.danger.main,
      color: isActive ? theme.text._1 : theme.text._11,

      ':hover': {
        background: isActive ? theme.background._3 : theme.colors.danger._400,
        color: isActive ? theme.text._5 : theme.text._11,
      },

      ...theme.focusVisible,
      ...style,
    };
  },
);

const getIcon = ({ id, isActive }) => {
  let icon;

  if (id === CONTROLS_ID.HAND) {
    icon = icons[id].on;
  }
  else if (id === CONTROLS_ID.LEAVE) {
    icon = icons[id].on;
  }
  else {
    const status = isActive ? 'on' : 'off';
    icon = icons[id][status];
  }

  return icon;
};

export const Icon = (props) =>
  styled(getIcon(props))({
    height: '1.5rem',
    width: '1.5rem',
  });

export const IconLarge = (props) =>
  styled(getIcon(props))({
    height: '2rem',
    width: '2rem',
  });

export const TooltipContentContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
`;

export const Content = styled.div`
  text-align: center;
  padding: 0.5rem 0;
`;

export const Title = styled.div(({ theme }) => ({
  ...theme.typo.labels.label,
}));

export const Description = styled.div(({ theme }) => ({
  ...theme.typo.bodyText.tinyText,
  marginTop: '.25rem',
}));

export const Label = styled.span(({ theme, isDisabled }) => ({
  textAlign: 'center',
  cursor: 'default',
  color: isDisabled ? theme.text._7 : theme.text._1,
  ...theme.typo.labels.tinyLabel,
}));
