/*
 * Package Import
 */

/*
 * Local Import
 */
import type { Promotion } from 'src/schemas/Entities/Promotion';
import type { MongoId } from 'src/schemas/Entities/utils';

/*
 * Code
 */
export const getPromotionFromId = ({
  promotions,
  id,
}: {
  promotions: Promotion[] | undefined;
  id: MongoId;
}): Promotion | undefined => promotions?.find((promo) => promo.id === id);
