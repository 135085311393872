/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilEllipsisV,
  UilPen,
  UilTrashAlt,
  UilCopy,
  UilCommentQuestion,
} from '@iconscout/react-unicons';
import { MenuButton as MenuButtonImported, Menu as MenuInner } from '@szhsin/react-menu';
import { menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';

/**
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/*
 * Icons
 */
const iconStyle = (theme) => ({
  color: theme.text._1,
  height: '1.25rem',
  width: '1.25rem',
});

export const More = styled(UilEllipsisV)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Delete = styled(UilTrashAlt)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Edit = styled(UilPen)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Copy = styled(UilCopy)(({ theme }) => ({
  ...iconStyle(theme),
}));

export const Question = styled(UilCommentQuestion)(({ theme }) => ({
  ...iconStyle(theme),
}));

/*
 * Style
 */
export const Menu = styled(MenuInner)(({ theme }) => ({
  [menuSelector.name]: {
    borderRadius: '1rem',
    padding: '0.2rem 0',
    minWidth: '8.5rem',
    position: 'absolute',
    background: theme.themeName === LIGHT_THEME_NAME ? theme.background._2 : theme.background._3,
    ...theme.shadows.plus2,
  },

  [menuItemSelector.name]: {
    margin: '0.25rem 0.5rem',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.5rem',
  },
  [menuItemSelector.hover]: {
    background: theme.background._4,
  },
}));

export const Icon = styled.div`
  margin-right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div(({ theme }) => ({
  color: theme.text._1,
  ...theme.typo.bodyText.smallBody,
}));

export const Button = styled(MenuButtonImported)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  margin-right: 1px;
  flex: 0 0 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  transition: background 0.2s;
  border-radius: 8px;

  :hover {
    background: ${({ theme }) => theme.background._4};
  }

  :focus-visible {
    border: 2px solid ${({ theme }) => theme.primary._1};
  }
  :-moz-focusring {
    border: 2px solid ${({ theme }) => theme.primary._1};
  },
`;
