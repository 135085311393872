/*
 * Package Import
 */

/*
 * Local Import
 */
import type { Course } from '../Entities/Course';
import type { MongoId } from '../Entities/utils';

/*
 * Code
 */
export const getCoursesChildrenOfPromotion = ({
  courses,
  id,
}: {
  courses: Course[] | undefined;
  id: MongoId;
}): Course[] | undefined => courses?.filter((course) => course.promotion.id === id);

export const getCoursesChildrenOfUser = ({
  courses,
  id,
}: {
  courses: Course[] | undefined;
  id: MongoId;
}): Course[] | undefined =>
  courses?.filter(
    (course) => course.teacher.id === id || course.helpers.some((helper) => helper?.id === id),
  );
