/**
 * Package Import
 */
import * as z from 'zod';

/**
 * Constants · VM • Statuses
 */
export const STATUS_VM = {
  PENDING: 'pending',
  RUNNING: 'running',
  INSTALLING: 'installing',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
  TERMINATED: 'terminated',
  NOT_FOUND: 'not-found',
  SHUTTING_DOWN: 'shutting-down',
  ERROR: 'error',
} as const;
export const STATUS_VM_SCHEMA = z.nativeEnum(STATUS_VM);
export type StatusVMEnum = z.infer<typeof STATUS_VM_SCHEMA>;

/**
 * Constants · VM • Statuses which are not resolved
 */
export const NOT_RESOLVED_VM = [STATUS_VM.NOT_FOUND, STATUS_VM.ERROR, STATUS_VM.PENDING];

/*
 * Constants • VM • Translated status
 */
export const TRANSLATED_STATUS = {
  installing: 'Démarrage en cours…',
  pending: 'En attente',
  running: 'Démarré',
  'shutting-down': 'En extinction',
  terminated: 'Terminé',
  stopping: 'Arrêt en cours…',
  stopped: 'Arrêté',
  'not-found': 'Non trouvé',
};

/**
 * Constants · VM • Actions
 */
export const ACTION_VM = {
  CREATE: 'CREATE',
  REINSTALL: 'REINSTALL',
  DELETE: 'DELETE',
  START: 'START',
  REBOOT: 'REBOOT',
  STOP: 'STOP',
} as const;

/**
 * Constants · VMs
 */
export default Object.freeze({
  /**
   * DELAY
   */
  GET_STATUS_DELAY: 30000,

  /**
   * HOSTNAME
   */
  HOSTNAME: 'cloud',
  PUBLIC_SUFFIX: 'eddi',
});
