/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { getConstraints } from 'src/utils/medias';
import * as S from './style';

/*
 * Component
 */
class Webcam extends React.Component {
  /*
   * State
   */
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  /*
   * Lifecycles
   */
  componentDidMount() {
    if (this.props.status) {
      const constraints = getConstraints();

      // Get user webcam
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.mountVideo)
        .catch((error) => {
          // If error we stop trying displaying webcam and display avatar
          this.props.setVideoOn(false);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.removeEventListener('loadeddata', this.ready);
      this.props.setVideoOn(false);
      if (this.stream) {
        // We cut each stream tracks at unmount
        this.stream.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
          }
        });
      }
      delete this.video;
    }
  }

  /*
   * Actions
   */
  mountVideo = (stream) => {
    if (this.props.forwardRef) {
      this.video = this.props.forwardRef.current;

      if (this.video) {
        // When ready
        this.video.addEventListener('loadeddata', this.ready);

        // Stream in <video>
        this.stream = stream;
        this.video.srcObject = stream;

        // Play
        this.video.play();
        this.video.muted = true;
        this.props.setVideoOn(this.stream.active);
      }
    }
  };

  ready = () => {
    this.setState({ loading: false }, () => {
      if (this.props.onPlay) {
        this.props.onPlay();
      }
    });
  };

  /*
   * Render
   */
  render() {
    return (
      <S.Webcam isLoading={this.state.loading}>
        <S.Video ref={this.props.forwardRef} tabIndex="-1" />
      </S.Webcam>
    );
  }
}

export default React.forwardRef((props, ref) => <Webcam {...props} forwardRef={ref} />);

Webcam.propTypes = {
  setVideoOn: PropTypes.func.isRequired,
  forwardRef: PropTypes.object,
  status: PropTypes.bool.isRequired,
  onPlay: PropTypes.func,
};

Webcam.defaultProps = {
  forwardRef: null,
  onPlay: () => {},
};
