/* eslint-disable max-classes-per-file */

/**
 * Package Import
 */
import { Quill } from 'react-quill';
import { EmojiBlot } from '@o-clock-dev/quill-emoji';

/**
 * Custom Quill formats
 */
const Inline = Quill.import('blots/inline');

/**
 * Bold
 */
class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'strong';
Quill.register('formats/bold', BoldBlot);

/**
 * Italic
 */
class ItalicBlot extends Inline {}
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'em';
Quill.register('formats/italic', ItalicBlot);

/**
 * Emoji
 */
Quill.register('formats/emoji', EmojiBlot);
