/**
 * Package imports
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local import
 */
import TimestampMessage from 'src/components/Chats/Channel/Chat/Message/Timestamp';
import Mugshot from 'src/components/Chats/Channel/Mugshot';

/**
 * Helpers
 */
import { isHelper, isTeacher, isGhost, isStudent } from 'src/utils/roles';

/**
 * Style
 */
import * as S from '../style';

/**
 * Message infos, username, role badge
 */
const Infos = ({ chatAppearance, handleUsername, pinned, time, user, userId }) => (
  <S.Infos chatAppearance={chatAppearance}>
    {/* Mugshot */}
    {chatAppearance === 'spaced' && <Mugshot user={user} size={32} statusSize={0.6} />}
    {/* Flag in compact mode */}
    {chatAppearance === 'compact' && pinned && <S.Pinned size={16} />}
    {/* Name */}
    <S.Name
      chatAppearance={chatAppearance}
      isPinned={pinned}
      onClick={handleUsername}
      role={user?.role}
    >
      {user?.name || userId}
    </S.Name>
    {!isStudent(user?.role) && (
      <S.Badge>
        {isHelper(user?.role) && <S.Helper />}
        {isTeacher(user?.role) && <S.Teacher />}
        {isGhost(user?.role) && <S.Ghost />}
      </S.Badge>
    )}
    {/* Time */}
    {chatAppearance === 'spaced' && <TimestampMessage time={time} />}
  </S.Infos>
);

Infos.propTypes = {
  chatAppearance: PropTypes.string.isRequired,
  handleUsername: PropTypes.func.isRequired,
  pinned: PropTypes.bool.isRequired,
  time: PropTypes.number.isRequired,
  user: PropTypes.object,
  userId: PropTypes.string,
};

Infos.defaultProps = {
  user: null,
  userId: 'Utilisateur inconnu',
};

export default Infos;
