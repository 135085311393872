/*
 * Local Import
 */

// Emoji
import Colon, { patternColon } from './components/Piece/Emoji/colon';
import Smiley, { patternSmiley } from './components/Piece/Emoji/smiley';

/*
 * Export
 */
export default [
  // Emojis
  {
    pattern: patternColon,
    Component: Colon,
  },
  {
    pattern: patternSmiley,
    Component: Smiley,
  },
];
