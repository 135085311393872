import styled from '@emotion/styled';
import {
  UilInfoCircle,
  UilCheck,
  UilClipboardNotes,
  UilTimes,
  UilExclamationCircle,
} from '@iconscout/react-unicons';

export const Info = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}));

export const InfoIcon = styled(UilInfoCircle)(({ theme }) => ({
  color: theme.info.main,
  marginRight: '.3rem',
  width: '1.1rem',
  height: '1.1rem',
}));

export const IconClipboard = styled(UilClipboardNotes)(({ theme }) => ({
  color: theme.text._1,
}));

export const Check = styled('div')(() => ({
  display: 'flex',
  marginLeft: '.5rem',
}));

export const IconCheck = styled(UilCheck)(({ theme }) => ({
  color: theme.text._1,
}));

export const CloseIcon = styled(UilTimes)(() => ({
  height: '.85rem',
  width: '.85rem',
  margin: '.125rem',
}));

export const ErrorIcon = styled(UilExclamationCircle)(({ theme }) => ({
  color: theme.danger.main,
  marginRight: '.3rem',
  width: '1rem',
  height: '1rem',
}));

export const CsvConfirmationInfoCaptionIcon = styled(UilInfoCircle)(({ theme }) => ({
  color: theme.info.main,
  margin: '0 .3rem',
  width: '1.1rem',
  height: '1.1rem',
}));

export const CsvConfirmationErrorCaptionIcon = styled(UilExclamationCircle)(({ theme }) => ({
  color: theme.danger.main,
  margin: '0 .3rem',
  width: '1.1rem',
  height: '1.1rem',
}));
