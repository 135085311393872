import { useEffect, useState } from 'react';
import { useEvent } from './useEvent';

export const useActivityFlag = (initial) => {
  // Check if at least one event flag is true
  const [activityFlag, setActivityFlag] = useState(initial);
  // Events watched
  const [clickFlag, resetClickFlag] = useEvent('click', initial);
  const [keyFlag, resetKeyFlag] = useEvent('keydown', initial);
  const [mouseFlag, resetMouseFlag] = useEvent('mousemove', initial);

  useEffect(() => {
    setActivityFlag(clickFlag || keyFlag || mouseFlag);
  }, [clickFlag, keyFlag, mouseFlag]);

  const resetFlags = () => {
    resetClickFlag();
    resetKeyFlag();
    resetMouseFlag();
  };

  return [activityFlag, resetFlags];
};
