/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  getPromoOptions,
  getPromoOptionsTooltipsInfos,
} from 'src/components/Admin/Modals/FormElements/utils';
import { Option } from 'src/components/Admin/Modals/FormElements/type';

/*
 * Code
 */
export const multipleUsersAddFormData = {
  upload: {
    header: 'Importer un csv',
    subheading: {
      label: 'Importer des utilisateurs via le',
      linkText: 'modèle de fichier CSV',
    },
    inputs: {
      selectPromotions: {
        title: 'promotion',
        display: 'Promotion',
        isMulti: false,
        getPromoOptions,
        getPromoOptionsTooltipsInfos,
      },
      filePond: {
        title: 'csvFile',
        display: 'Fichier CSV',
      },
    },
  },
  confirmation: {
    header: 'Confirmation des informations',
    getSubheading: (promotion: Option | null): string => {
      if (!promotion) {
        return 'Ajouter des utilisateurs sans promotion.';
      }
      return `Ajouter des utilisateurs pour la promotion ${promotion.label}.`;
    },
    caption: {
      informations: {
        firstPart: 'Le survol de',
        secondPart: "permet de visualiser le détail des informations de l'utilisateur.",
      },
      anomalies: {
        firstPart: 'Le survol de',
        secondPart: 'permet de visualiser les anomalies relevées.',
      },
      checkbox: 'Vous pouvez décocher un utilisateur et il ne sera pas enregistré.',
      checkboxAnomaly:
        "En cas d'anomalie relevée, la case ne pourra pas être cochée et l'utilisateur ne sera pas enregistré.",
      edit: 'Pour corriger les anomalies, vous pouvez modifier ou revenir en arrière et charger un fichier corrigé.',
    },
    formErrors: {
      noData: {
        type: 'noData',
        message: 'Vous devez sélectionner au moins un utilisateur',
      },
    },
  },
};
