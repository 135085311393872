/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';

/*
 * Local Import
 */

/*
 * Style
 */

export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter !important',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: 'auto',
  '.MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2.5rem',
    position: 'relative',
    minWidth: '49rem',
    minHeight: 'unset',
    maxWidth: 'unset',
    background: theme.background._2,
    border: `2px solid ${theme.text._7}`,
    borderRadius: '16px',
    ...theme.shadows.plus3,
  },
}));

export const Header = styled.h1(({ theme }) => ({
  ...theme.typo.headlines.H2,
  marginBottom: '2.5rem',
  width: '100%',
  textAlign: 'center',
  color: theme.text._1,
}));

export const Table = styled.table(({ theme }) => ({
  width: '100%',
  maxHeight: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2.5rem',
  color: theme.text._1,

  'thead, tbody': {
    display: 'flex',
    width: '100%',
    borderRadius: '0.25rem',
  },

  thead: {
    backgroundColor: theme.background._8,
  },

  tbody: {
    flexDirection: 'column',
    overflow: 'auto',
    maxHeight: '38rem',

    '::-webkit-scrollbar': {
      width: '0.25rem',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.background._8,
      borderRadius: '0.37rem',
    },

    // Firefox
    scrollbarColor: `transparent ${theme.background._8}`,
    scrollbarWidth: 'thin',
  },

  tr: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  'th, td': {
    padding: '1.5rem 1rem',
    maxHeight: '3rem',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.background._7}`,
  },
}));

export const HasSigned = styled.div(({ theme, hasSigned }) => ({
  color: !hasSigned ? theme.warning.main : theme.text._1,
}));
