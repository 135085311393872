/*
 * Package Import
 */
import { useEffect, useState, useMemo } from 'react';
import { statusStrings } from 'src/constants/status';

/**
 * Hooks ~ useStatusString
 * Takes connected and away booleans and return status string corresponding
 * @param  {Boolean} connected
 * @param  {Boolean} away
 */
export const useStatusString = ({ connected, away }) => {
  const statusStringMemo = useMemo(() => {
    if (connected && away) {
      return statusStrings.AWAY;
    }
    if (connected) {
      return statusStrings.PRESENT;
    }
    return statusStrings.DISCONNECTED;
  });
  const [statusString, setStatusString] = useState(statusStringMemo);

  useEffect(() => {
    setStatusString(statusStringMemo);
  }, [connected, away]);

  return statusString;
};
