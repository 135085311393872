/**
 * Package Import
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import Chat from 'src/components/Chats/Channel/Chat';
import Unread from 'src/components/Chats/Channel/Unread';
import Filters from 'src/components/Chats/Channel/Filters/container';
// import ChannelSearch from 'src/components/Chats/Channel/ChannelSearch'
import ChannelList from 'src/components/Chats/Channel/ChannelList';
import Mugshot from 'src/components/Chats/Channel/Mugshot';
import Tooltip from 'src/components/Tooltip/container';

/**
 * Hooks
 */
import { useScroll } from 'src/hooks';

// Helpers
import CHATS from 'src/constants/chats';
import { setFocus } from 'src/utils';
import { isEnter, isSpace, isLeft, isRight } from 'src/utils/events';

// Style
import ScrollShadowContainer from 'src/components/ScrollShadowContainer';
import * as S from './style';

/**
 * Code
 */
const { CHAT_GENERAL, CHAT_PRIVATE, CHAT_HOME_PRIVATE } = CHATS;

/**
 * Component
 */
const ChannelHeader = ({
  actions,
  activeId,
  currentView,
  generalUnread,
  privatesUnread,
  ...userProps
}) => {
  const { handleScroll, isTop } = useScroll();
  /**
   * Props
   */
  const generalActive = currentView === CHAT_GENERAL;
  const privateActive = currentView === CHAT_PRIVATE;
  const homePrivateActive = currentView === CHAT_HOME_PRIVATE;

  const { user, isMe, isHelper, isTeacher, name } = userProps;

  /**
   * Set the new current view by adding the selected view
   * @param {String} view
   * @return {void}
   */
  const handleView = (view) => () => {
    // If we change the view to `CHAT_GENERAL`,
    // We're updating the ID of the active channel
    if (view === CHAT_GENERAL) {
      actions.switchToGeneral();
    }

    // If we change the view to `CHAT_HOME_PRIVATE`
    // We're updating the ID of the active channel at `null`
    if (view === CHAT_HOME_PRIVATE) {
      actions.switchToHome();

      if (activeId) {
        // Small hack otherwise does not work
        setTimeout(() => {
          setFocus(`preview-chat-${activeId}`);
        }, 0);
      }
    }

    // Otherwise, just update the current view
    actions.changeChannelView(view);
  };

  /**
   * Set the new current view /w keyboard
   * @param  {String} view
   * @param  {Event} evt
   */
  const onKeyDown = (view) => (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      handleView(view)();
    }
    else if (isRight(evt) || isLeft(evt)) {
      if (evt.currentTarget.id === 'tabChat') {
        setFocus('tabChatPrivate');
      }
      else {
        setFocus('tabChat');
      }
    }
  };

  /**
   * Close the current channel
   * @return {void}
   */
  const handleClose = () => {
    actions.closeChannel(activeId);
    setFocus('tabChatPrivate');
  };

  useEffect(() => {
    if (privateActive) {
      setFocus('userNameTabHead');
    }
  }, [name]);

  /**
   * Render
   */
  return (
    <div aria-labelledby="chat" role="tabpanel">
      {/* Tabs */}
      <ScrollShadowContainer isTop={isTop} style={{ position: 'relative' }}>
        <S.Tabs role="tablist">
          <S.Tab
            id="tabChat"
            isActive={generalActive}
            onClick={handleView(CHATS.CHAT_GENERAL)}
            onKeyDown={onKeyDown(CHATS.CHAT_GENERAL)}
            // a11y
            role="tab"
            aria-selected={generalActive}
            aria-controls="Chats"
            tabIndex="0"
          >
            <S.MinorTitleSrOnly>La classe</S.MinorTitleSrOnly>
            <S.ClassIcon aria-hidden />
            <S.Label isActive={generalActive}>La classe</S.Label>

            {/* Unread */}
            {!generalActive && !!generalUnread && <Unread unread={generalUnread} />}
          </S.Tab>
          <S.Tab
            id="tabChatPrivate"
            isActive={homePrivateActive || privateActive}
            onClick={handleView(CHAT_HOME_PRIVATE)}
            onKeyDown={onKeyDown(CHAT_HOME_PRIVATE)}
            // a11y
            role="tab"
            aria-selected={homePrivateActive || privateActive}
            aria-controls="messages"
            tabIndex="0"
          >
            <S.MinorTitleSrOnly>Messages privés</S.MinorTitleSrOnly>
            <S.PrivateIcon aria-hidden />
            <S.Label isActive={homePrivateActive || privateActive}>Messages privés</S.Label>

            {/* Unread */}
            {!(homePrivateActive || privateActive) && !!privatesUnread && (
              <Unread unread={privatesUnread} />
            )}
          </S.Tab>
        </S.Tabs>
      </ScrollShadowContainer>

      {/* 1st possible view : General channel */}
      {generalActive && (
        <>
          <Filters chatId={activeId} />
          <Chat chatId={activeId} />
        </>
      )}

      {/* 2nd possible view : Privates channels  · Home */}
      {homePrivateActive && (
        // <ChannelSearch />
        <ChannelList handleScroll={handleScroll} />
      )}

      {/* 3rd possible view : Privates channels · Chat */}
      {privateActive && (
        <>
          <S.Buttons>
            <S.Infos>
              <Tooltip content="Retour">
                <S.Button
                  type="button"
                  onClick={handleView(CHAT_HOME_PRIVATE)}
                  onKeyDown={handleView(CHAT_HOME_PRIVATE)}
                  aria-label="Retour à la liste des conversations privées"
                >
                  <S.ReturnArrow aria-hidden />
                </S.Button>
              </Tooltip>
              <Mugshot user={user} size={32} statusSize={0.75} />
              <S.Name>
                <S.User tabIndex="0" id="userNameTabHead">
                  {name}
                </S.User>
                {isHelper && <S.HelperIcon />}
                {isTeacher && <S.TeacherIcon />}
                {isMe && <S.You>&nbsp;(vous)&nbsp;</S.You>}
              </S.Name>
            </S.Infos>
            <Tooltip content="Fermer la conversation">
              <S.Button
                type="button"
                onClick={handleClose}
                aria-label="Fermer la conversation privée"
              >
                <S.IconClose aria-hidden />
              </S.Button>
            </Tooltip>
          </S.Buttons>
          <Chat chatId={activeId} isPrivate />
        </>
      )}
    </div>
  );
};

/**
 * PropTypes
 */
ChannelHeader.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Current channel ID */
  activeId: PropTypes.string,
  /** Current tab view */
  currentView: PropTypes.string,
  /** Display badge if privates channel have unread messages ? */
  privatesUnread: PropTypes.number,
  /** Display badge if general channel has unread messages ? */
  generalUnread: PropTypes.number,
};

ChannelHeader.defaultProps = {
  activeId: null,
  currentView: CHAT_GENERAL,
  privatesUnread: 0,
  generalUnread: 0,
};

/**
 * Export
 */
export default React.memo(ChannelHeader);
