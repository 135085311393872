/*
 * Package Import
 */
import {
  breakpoints as themeBreakpoints,
  betweenWidth,
  maxWidth,
  minWidth,
} from '@o-clock-dev/theme-oclock';

/*
 * Breakpoints
 */
export const breakpoints = {
  ...themeBreakpoints,
};

/*
 * Functions medias
 */
export const medias = {
  betweenWidth,
  maxWidth,
  minWidth,
};
