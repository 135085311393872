/**
 * Package Import
 */
import { useQuery, UseQueryResult } from 'react-query';
import { IntegrationApps } from 'src/constants/integrations';

/**
 * Local Import
 */
import { IntegrationDTO } from 'src/schemas/Entities/Integration';
import { fetchIntegration } from './fetchIntegration';

/**
 * Code
 */
export const useGetIntegration = (
  integrationName: IntegrationApps,
): UseQueryResult<IntegrationDTO | null> => {
  const integrationsQuery = useQuery(
    ['integration', integrationName],
    fetchIntegration(integrationName),
    {
      staleTime: 60000,
    },
  );

  return integrationsQuery;
};
