/**
 * Package Import
 */
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { UilAngleDown, UilAngleUp, UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

// Helpers
import { getStorage, setStorage } from 'src/utils/storage';

const getLabelStyle = (theme: ThemeInterface): Record<symbol, string> => theme.typo.labels.label;
const getTinyLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;

const PaginationContainer = styled.div(({ theme }) => ({
  marginRight: '2rem',
  display: 'flex',
  float: 'right',
  alignItems: 'center',
  overflow: 'visible',
  ...getLabelStyle(theme),
}));

const StyledSelect = styled.button(({ theme }) => ({
  position: 'relative',
  marginRight: '3rem',
  marginLeft: '.5rem',
  border: `1px solid ${theme.text._7}`,
  borderRadius: '8px',
  cursor: 'pointer',
  color: theme.text._4,

  '&:hover': {
    background: theme.background._5,
    border: `2px solid ${theme.text._6}`,
    marginRight: 'calc(3rem - 1px)',
    marginLeft: 'calc(.5rem - 1px)',
  },

  '&:focus': {
    border: `2px solid ${theme.primary._1}`,
    marginRight: 'calc(3rem - 1px)',
    marginLeft: 'calc(.5rem - 1px)',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    color: theme.text._6,
    border: `1px solid ${theme.text._6}`,
    marginRight: '3rem',
    marginLeft: '.5rem',
    background: 'none',
  },
}));

const Label = styled.span(({ theme }) => ({
  ...getTinyLabelStyle(theme),
}));

const Selected = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: '.25rem .25rem .25rem .5rem',
  ...getLabelStyle(theme),
}));

const OptionsContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  borderRadius: '8px',
  transform: 'scaleY(0)',
  transformOrigin: '0 0',
  transition: 'all 300ms ease',
  border: `1px solid ${theme.text._7}`,
  width: '100%',
  padding: '.25rem',
  '&.select-open': {
    top: '2.2rem',
    background: theme.background._3,
    opacity: '1',
    transform: 'scaleY(1)',
    ...theme.shadows.plus2,
  },
}));

const Option = styled.option(({ theme }) => ({
  borderRadius: '4px',
  padding: '.25rem',
  color: theme.text._1,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  ...getTinyLabelStyle(theme),

  '&:hover': {
    color: theme.text._1,
    background: theme.background._5,
  },
}));

const PaginationButtons = styled.div(() => ({
  display: 'flex',
  marginLeft: '3rem',
}));

const PageButton = styled.button(({ theme }) => ({
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.text._4,

  '&.button-left': {
    marginRight: '.5rem',
  },

  ':hover': {
    background: theme.background._5,
  },

  ':disabled': {
    cursor: 'not-allowed',
    color: theme.text._6,
    background: 'none',
  },

  ':focus': {
    border: `2px solid ${theme.primary._1}`,
  },
}));

const PageSizeSelect = (props: PaginationProps): JSX.Element => {
  const { pageSize, setPageSize } = props;
  const [isOpen, setIsOpen] = useState(false);
  const options = [10, 20, 50, 100];
  const oldPageSize = getStorage('pageSize');
  useEffect(() => {
    if (oldPageSize) {
      setPageSize(oldPageSize);
    }
  }, [oldPageSize, setPageSize]);

  const handleOpen = (): void => {
    if (!isOpen) {
      setIsOpen(true);
    }
    else {
      setIsOpen(false);
    }
  };

  return (
    <StyledSelect onClick={handleOpen}>
      <Selected>
        {oldPageSize || pageSize || options[0]}
        {isOpen ? <UilAngleUp /> : <UilAngleDown />}
      </Selected>
      <OptionsContainer className={isOpen ? 'select-open' : 'select-close'}>
        {options.map((option, index) => (
          <Option
            key={index}
            value={option}
            onClick={() => {
              setStorage('pageSize', option);
              setPageSize(option);
              setIsOpen(false);
            }}
          >
            {option}
          </Option>
        ))}
      </OptionsContainer>
    </StyledSelect>
  );
};

interface PaginationProps {
  pageIndex: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  rowsCount: number;
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const {
    rowsCount,
    pageIndex,
    pageSize,
    previousPage,
    nextPage,
    getCanPreviousPage,
    getCanNextPage,
  } = props;
  const getPaginationSentence = useCallback(
    (): string =>
      `${pageIndex * pageSize + 1} - ${Math.min(
        (pageIndex + 1) * pageSize,
        rowsCount,
      )} sur ${rowsCount}`,
    [pageIndex, pageSize, rowsCount],
  );

  const [paginationSentence, setPaginationSentence] = useState(getPaginationSentence());
  useEffect(() => {
    setPaginationSentence(getPaginationSentence());
  }, [pageIndex, pageSize, rowsCount, getPaginationSentence]);

  return (
    <PaginationContainer>
      <Label>Résultats par page</Label>
      <Tooltip content="Choix du nombre d'éléments par page">
        <PageSizeSelect {...props} />
      </Tooltip>
      <span>{paginationSentence}</span>
      <Tooltip content="Accès aux autres pages de la table">
        <PaginationButtons>
          <PageButton
            className="button-left"
            onClick={() => previousPage()}
            disabled={!getCanPreviousPage()}
          >
            <UilAngleLeft />
          </PageButton>
          <PageButton onClick={() => nextPage()} disabled={!getCanNextPage()}>
            <UilAngleRight />
          </PageButton>
        </PaginationButtons>
      </Tooltip>
    </PaginationContainer>
  );
};

export default Pagination;
