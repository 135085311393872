import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import type { Course } from 'src/schemas/Entities/Course';
import type { Promotion } from 'src/schemas/Entities/Promotion';
import {
  findCoursesAndCheckPromotionDates,
  findPromotionAndCheckIfExportDatesCorrespondToPromotionDates,
  PromotionFormDataPartialStatic,
  PromotionActivityExportFormDataPartialStatic,
} from 'src/schemas/Refines/Validations/promotions';

export const promotionFormDatesMustBeOkWithCourses = (
  data: PromotionFormDataPartialStatic,
): boolean => {
  const coursesData: Course[] | undefined = queryClient.getQueryData('courses');
  return findCoursesAndCheckPromotionDates(data, coursesData);
};

export const promotionActivityExportFormDatesMustBeOkWithPromotionDates = (
  data: PromotionActivityExportFormDataPartialStatic,
): boolean => {
  const promotionsData: Promotion[] | undefined = queryClient.getQueryData('promotions');

  return findPromotionAndCheckIfExportDatesCorrespondToPromotionDates(data, promotionsData);
};
