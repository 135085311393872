import { getHoursOrMinutes, Slot } from 'src/components/Admin/Modals/FormElements/Timeslot/utils';
import { MongoId } from 'src/schemas/Entities/utils';

const timeToDate = (time: string, dateString: string): Date => {
  const date = new Date(dateString);

  date.setHours(getHoursOrMinutes(time, 'hours'), getHoursOrMinutes(time, 'minutes'));

  return date;
};

export type SlotWithDates = {
  _id?: MongoId;
  start: Date;
  end: Date;
};

export const transformTimeslotsSimpleStringToDate = (
  timeslots: Slot[],
  courseDate: string,
): SlotWithDates[] =>
  timeslots.map((timeslot) => ({
    start: timeToDate(timeslot.start, courseDate),
    end: timeToDate(timeslot.end, courseDate),
    ...(timeslot._id && { _id: timeslot._id }),
  }));
