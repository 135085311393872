/**
 * Local Import
 */
import { MEDIAS_STREAMS_MAX_LIMIT } from './mediaDevices';
import { CONFERENCE_MAX_STUDENTS } from './conference';

/**
 * Constants • Controls • IDs
 */
export const CONTROLS_ID = {
  HAND: 'control_hand',
  MICROPHONE: 'control_microphone',
  SOUND: 'control_sound',
  WEBCAM: 'control_webcam',
  SCREEN: 'control_screen',
  CONFERENCE: 'control_conference',
  LEAVE: 'control_leave',
};

/**
 * Constants • Controls • Descriptions
 */
export const DESCRIPTIONS = {
  [CONTROLS_ID.HAND]: {
    FEATURE_ON: 'Demander la parole au formateur',
    FEATURE_OFF: 'Rendre la parole au formateur',
    FEATURE_DISABLE: {
      NO_PERMISSIONS:
        "Vous ne pouvez pas prendre la parole car vous n'avez pas autorisé l'accès au micro.",
      MAX_VIDEO_TRACKS: `Vous ne pouvez pas demander la parole car la limite des ${MEDIAS_STREAMS_MAX_LIMIT} caméras a été atteinte.`,
      TEACHER_NOT_CONNECTED:
        'Vous ne pouvez pas prendre la parole car le formateur n’est pas encore arrivé dans la classe.',
      AUDIO_CONFERENCE_ON:
        'Vous ne pouvez pas demander la parole car le mode Discussion est activé.',
    },
  },
  [CONTROLS_ID.WEBCAM]: {
    FEATURE_ON: 'Activer ma webcam',
    FEATURE_OFF: 'Désactiver ma webam',
    FEATURE_DISABLE: {
      NO_PERMISSIONS:
        "Vous ne pouvez pas activer votre webcam car vous n'avez pas autorisé l'accès à la webcam.",
      MAX_VIDEO_TRACKS: `Vous ne pouvez pas afficher votre webcam car la limite des ${MEDIAS_STREAMS_MAX_LIMIT} caméras est atteinte.`,
      VOICE_NOT_GIVEN: 'Vous ne pouvez pas afficher votre webcam car vous n’avez pas la parole.',
    },
  },
  [CONTROLS_ID.MICROPHONE]: {
    FEATURE_ON: 'Activer mon micro',
    FEATURE_OFF: 'Désactiver mon micro',
    FEATURE_DISABLE: {
      NO_PERMISSIONS:
        "Vous ne pouvez pas activer votre micro car vous n'avez pas autorisé l'accès au micro.",
      VOICE_NOT_GIVEN: 'Vous ne pouvez pas activer votre micro car vous n’avez pas la parole.',
    },
  },
  [CONTROLS_ID.SOUND]: {
    FEATURE_ON: 'Activer le son du cours',
    FEATURE_OFF: 'Désactiver le son du cours',
  },
  [CONTROLS_ID.SCREEN]: {
    FEATURE_ON: "Activer le partage d'écran",
    FEATURE_OFF: "Désactiver le partage d'écran",
  },
  [CONTROLS_ID.CONFERENCE]: {
    FEATURE_ON: 'Autoriser tous les apprenants à activer leur microphone',
    FEATURE_OFF: 'Couper les micros de tous les apprenants',
    FEATURE_DISABLE: {
      MAX_STUDENTS: `Cette fonctionnalité est désactivée quand plus de ${
        CONFERENCE_MAX_STUDENTS - 1
      } apprenants sont connectés.`,
      VOICE_GIVEN:
        'Vous ne pouvez pas activer le mode discussion car une prise de parole est en cours',
    },
  },
};

/**
 * Constants • Voice request labels
 */
export const VOICE_REQUEST_LABELS = {
  FEATURE_AWAIT: 'En attente',
  FEATURE_ACCEPTED: 'Accepté',
  FEATURE_ALL_DENIED: 'Tout refuser',
  FEATURE_OFF: 'Couper la parole',
  FEATURE_DISABLED: {
    MAX_VIDEO_TRACKS: `Vous ne pouvez pas accepter la prise de parole car la limite des ${MEDIAS_STREAMS_MAX_LIMIT} caméras a été atteinte`,
    LOADING:
      'Vous ne pouvez pas accepter la prise de parole tant que la précédente n’a pas chargé.',
  },
};
