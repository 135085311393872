/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@oclock/crumble';

/*
 * Local Import
 */

const ResetSignature = ({ actions }) => (
  <Dialog
    title="Réinitialiser l'émargement"
    desc="Souhaitez-vous vraiment remettre à zéro la feuille d'émargement de cette séance ?
    Toutes les signatures seront effacées et les apprenants devront à nouveau signer."
    variant="danger"
    cancelButtonProps={{
      label: 'Annuler',
      onClick: () => actions.confirmResetSignatures({ resetIsOpen: false }),
    }}
    successButtonProps={{
      label: 'Réinitialiser',
      onClick: () => {
        actions.resetSignatures();
        actions.confirmResetSignatures({ resetIsOpen: false });
      },
    }}
  />
);

ResetSignature.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
};

export default ResetSignature;
