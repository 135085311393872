/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const UserCard = styled.button(({ theme }) => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  cursor: 'pointer',
  ':hover': { background: theme.background._3 },
}));

export const Mugshot = styled.div({
  position: 'relative',
});
