/**
 * Package Import
 */
import type { DeltaOperation } from 'quill';

/**
 * Play a sound
 */
export const play = () => {
  const audio = new Audio('/static/media/audios/notification/default.m4a');
  audio.play();
};

/**
 * Check if one of ops in content contain mention & target user
 */
export const isMentionUser = (content: { ops: DeltaOperation[] }, userId: string) =>
  content.ops.some((op) => op.insert?.mention?.id === userId);
