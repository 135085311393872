import Keycloak from 'keycloak-js';

/**
 * Creates new Keycloak instance and returns it
 * @param {string} url
 * @param {string} clientId
 * @param {string} realm
 * @returns Keycloak
 */
const createKeycloakInstance = (url, clientId, realm) =>
  new Keycloak({
    url,
    clientId,
    realm,
  });

export { createKeycloakInstance };
