/*
 * Package import
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@sentry/react';

/*
 * Local import
 */
import Format from 'src/components/Format';
import { ErrorMessage, ErrorNetworkMessage } from 'src/components/Chats/Channel/ErrorMessage';
import TypeContent from 'src/components/Chats/Channel/Chat/Message/Content/Type';

// Helpers
import MESSAGES from 'src/constants/messages';

// Style
import * as S from './style';

/*
 * Code
 */
const { STATUS_RECEIVED, STATUS_SENDING, STATUS_FAILED, STATUS_ERROR } = MESSAGES;

/**
 * Component Content
 */
const Content = ({
  actions,
  content,
  context,
  deletedAt,
  editedAt,
  infos,
  isAnswered,
  isAsked,
  isSpoiler,
  messageId,
  pinned,
  status,
  userId,
}) => {
  /*
   * Refs
   */
  const nodeRef = useRef(null);

  /*
   * Props
   */
  const hasError = status === STATUS_FAILED || status === STATUS_ERROR;

  /*
   * State
   */
  const [retry, setRetry] = useState(
    /** @type {Boolean} · Retry state  */
    false,
  );

  /*
   * LifeCycles
   */
  useEffect(() => () => {
    window.clearTimeout(nodeRef.current);
  });

  /**
   * Try to send the message again
   * @return {void}
   */
  const onRetry = () => {
    actions.sendMessage({
      content,
      context,
      hasError,
      messageId,
    });

    // Shows feedbacks to user
    setRetry(true);
    nodeRef.current = window.setTimeout(() => {
      setRetry(false);
    }, 3000);
  };

  /*
   * Render
   */
  if (!content?.ops) {
    return null;
  }

  if (deletedAt) {
    return <div>Message supprimé</div>;
  }

  return (
    <ErrorBoundary fallback={ErrorMessage}>
      {/* Content */}
      <TypeContent
        hasError={hasError}
        infos={infos}
        isAnswered={isAnswered}
        isAsked={isAsked}
        isPinned={pinned}
        isSpoiler={isSpoiler}
        messageId={messageId}
        userId={userId}
      >
        {content.ops.map((element, index) => (
          <Format
            key={`key-content-element-${index}`}
            content={content}
            element={element}
            index={index}
          />
        ))}

        {/* Edited */}
        {editedAt && <S.Edited>&nbsp;(modifié)&nbsp;</S.Edited>}
      </TypeContent>

      {/* hasError */}
      {hasError && <ErrorNetworkMessage retry={retry} onClick={onRetry} />}
    </ErrorBoundary>
  );
};

/*
 * PropTypes
 */
Content.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Message context */
  context: PropTypes.string.isRequired,
  /** Content */
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** The message has been deleted */
  deletedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  /** The message has been edited */
  editedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  /** Infos component for compact mode */
  infos: PropTypes.node,
  /** The current question is flagged as answered */
  isAnswered: PropTypes.bool,
  /** The message has been flag as a question */
  isAsked: PropTypes.bool,
  /** The message has been flag as a spoiler */
  isSpoiler: PropTypes.bool,
  /** ID message */
  messageId: PropTypes.string.isRequired,
  /** The state of the pin, of the current message */
  pinned: PropTypes.bool,
  /** Status of the message */
  status: PropTypes.oneOf([STATUS_RECEIVED, STATUS_SENDING, STATUS_FAILED, STATUS_ERROR]),
  /** ID of message's creator */
  userId: PropTypes.string.isRequired,
};

Content.defaultProps = {
  content: null,
  deletedAt: null,
  editedAt: null,
  infos: null,
  isAnswered: false,
  isAsked: false,
  isSpoiler: false,
  pinned: false,
  status: STATUS_SENDING,
};

/*
 * Export
 */
export default React.memo(Content);
