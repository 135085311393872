/**
 * awayTime
 * @type {Array}
 */

// value : seconds
export const awayTime = [
  {
    label: 'Sans minuteur',
    value: '0',
  },
  {
    label: '10 minutes',
    value: '600',
  },
  {
    label: '1 heure',
    value: '3600',
  },
];
