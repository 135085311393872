/**
 * Package Import
 */
import styled from '@emotion/styled';
import { UilPlusCircle, UilTimes } from '@iconscout/react-unicons';
import { Menu } from '@szhsin/react-menu';
import { menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';

/**
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/**
 * Style
 */
export const Emotions = styled('div')(({ theme }) => ({
  color: theme.text._1,
  margin: '0 .4rem 0 .5rem',
  ...theme.typo.labels.smallLabel,
  position: 'relative',

  '& #emotion--none': {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  ...theme.focusOutlineVisible,
}));

export const SelectLabel = styled.div(({ theme }) => ({
  alignItems: 'center',
  color: theme.text._4,
  display: 'flex',
  height: '1.5rem',
  width: '100%',
  position: 'relative',
  ...theme.focusOutlineVisible,
}));

export const Label = styled('div')({
  marginLeft: '6px',
  marginRight: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const OptionContainer = styled(Menu)(({ theme }) => ({
  [menuSelector.name]: {
    background: theme.themeName === LIGHT_THEME_NAME ? theme.background._2 : theme.background._3,
    ...theme.shadows.plus2,
  },

  [menuItemSelector.name]: {
    color: theme.text._1,
    ...theme.focusOutlineVisible,
  },
  [menuItemSelector.hover]: {
    background: theme.background._4,
  },
}));

export const EmojiContainer = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const Add = styled(UilPlusCircle)``;

export const Close = styled.button(({ theme }) => ({
  alignItems: 'center',
  background: theme.background._5,
  borderRadius: '50%',
  color: theme.text._5,
  display: 'flex',
  justifyContent: 'center',
  height: '1.25rem',
  width: '1.25rem',
  ...theme.focusOutlineVisible,
}));

export const CloseX = styled(UilTimes)`
  height: 1rem;
  width: 1rem;
`;
