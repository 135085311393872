/* eslint-disable max-len */
/**
 * Package Import
 */
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';

/**
 * Style
 */
const getIconSearchStyle = (props) => ({
  position: 'absolute',
  cursor: props.searchterm ? 'pointer' : '',
  transition: 'all 0.7s ease-in-out',
  background: props.searchterm && props.theme.background._5,
  borderRadius: '100%',
  // eslint-disable-next-line no-nested-ternary
  color: props.searchterm
    ? props.theme.text._5
    : props.focus === 'true'
      ? props.theme.text._1
      : props.theme.text._5,
});

export const StyledUilSearch = styled(UilSearch, {
  shouldForwardProp: (prop) => isPropValid(prop),
})((props) => ({
  right: '10%',
  top: '50%',
  opacity: props.searchterm ? '0%' : '100%',
  transform: `translateY(-50%) ${props.searchterm ? 'rotate(45deg)' : ''}`,
  height: props.searchterm ? '2.5rem' : '1rem',
  ...getIconSearchStyle(props),
}));

export const StyledUilTimes = styled(UilTimes, {
  shouldForwardProp: (prop) => isPropValid(prop),
})((props) => ({
  right: '10%',
  top: '50%',
  height: props.searchterm ? '1.5rem' : '0.5rem',
  opacity: props.searchterm ? '100%' : '0%',
  transform: `translateY(-50%) ${props.searchterm ? 'rotate(180deg)' : ''}`,
  ...getIconSearchStyle(props),
}));

export const SearchContainer = styled('div')({
  padding: '0 1rem',
  display: 'grid',
  placeItems: 'center',
  flexShrink: 0,
  position: 'relative',
  width: '100%',
  marginBottom: '0.5rem',
});

export const SubTitleSrOnly = styled.h3(({ theme }) => ({
  ...theme.srOnly,
}));

export const SearchInput = styled('input')((props) => ({
  ...props.theme.typo.bodyText.body,
  border: `1px solid ${props.theme.text._7}`,
  color: props.theme.text._1,
  backgroundColor: props.theme.background._3,
  width: '100%',
  borderRadius: '0.5rem',
  height: '2.5rem',
  padding: '0% 20% 0 5%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '::placeholder': {
    color: props.theme.text._5,
  },
  '::-webkit-search-cancel-button': {
    display: 'none',
  },
  ':hover': {
    backgroundColor: props.theme.background._3,
  },
  ':focus': {
    backgroundColor: props.theme.background._2,
    outline: `2px solid ${props.theme.primary._1}`,
  },
}));
