/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Transcription from 'src/components/Course/Transcriptions/Transcription';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => ({
  isSameAuthor: ownProps?.previousAuthorId === ownProps?.userId,
  name: state.users.userById[ownProps.userId]?.name,
});

const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Transcription);
