/**
 * Types
 */
interface EmotionsProps {
  id: number;
  emoji: ':sob:' | ':sleeping:';
  feeling: string;
}

/**
 * No emotion
 */
export const NO_EMOTION = 'none';

/**
 * Constants · Emotions
 */
export const emotions: EmotionsProps[] = [
  {
    id: 2,
    emoji: ':sob:',
    feeling: "Besoin d'aide",
  },
  {
    id: 3,
    emoji: ':sleeping:',
    feeling: "Besoin d'une pause",
  },
];
