/*
 * Package Import
 */
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import { UserContext } from 'src/context/User';
import { Client } from 'src/schemas/Entities/User';
import { isAdmin, isSuperAdmin } from 'src/utils/roles';

// Queries
import { fetchUsers } from 'src/serverStateManagers/queries/Users/fetchUsers';
import { fetchPromotions } from 'src/serverStateManagers/queries/Promotions/fetchPromotions';
import { fetchCourses } from 'src/serverStateManagers/queries/Courses/fetchCourses';
import { useGetLimitations } from 'src/serverStateManagers/queries/Limitations/useGetLimitations';

// Helpers
import { useStore } from 'src/localStateManagers/store';

// Style
import { IntegrationApps } from 'src/constants/integrations';
import { fetchIntegration } from 'src/serverStateManagers/queries/Integrations/fetchIntegration';
import * as S from './style';

/*
 * Code
 */
type InitProps = {
  children: React.ReactChild | null;
};
// @TODO PASSER UserContext EN .TS ET TYPER CA EN ENTIER
type UserContextProps = {
  user: Client;
};
const Init = ({ children }: InitProps): JSX.Element => {
  const { user: client }: UserContextProps = useContext(UserContext);

  // We wait for promotionsData first for Users,
  // And for promotionsData and usersData for Courses
  const { isLoading: isLoadingPromotions, data: promotionsData } = useQuery(
    ['promotions'],
    fetchPromotions,
    {
      staleTime: 5000,
    },
  );
  const { isLoading: isLoadingUsers, data: usersData } = useQuery(['users'], fetchUsers, {
    enabled: !!promotionsData,
    staleTime: 5000,
  });
  const { isLoading: isLoadingCourses } = useQuery(['courses'], fetchCourses, {
    enabled: !!promotionsData && !!usersData,
    staleTime: 5000,
  });
  const { isLoading: isLoadingLimitations } = useGetLimitations();

  const { isLoading: isLoadingIntegrationEdusignQuery } = useQuery(
    ['integration', IntegrationApps.EDUSIGN],
    fetchIntegration(IntegrationApps.EDUSIGN),
    {
      enabled: !!client && (isAdmin(client.role) || isSuperAdmin(client.role)),
      staleTime: 5000,
    },
  );

  /*
   * LifeCycle
   */
  useEffect(() => {
    useStore.setState({ client });
  }, [client]);

  /**
   * Render
   */
  if (
    !client
    || isLoadingPromotions
    || isLoadingUsers
    || isLoadingCourses
    || isLoadingLimitations
    || isLoadingIntegrationEdusignQuery
  ) {
    return (
      <S.LoaderContainer>
        <Loader size={96} type="Oval" />
      </S.LoaderContainer>
    );
  }

  return <>{children}</>;
};

/*
 * Export
 */
export default Init;
