/**
 * Package Import
 */
import styled from '@emotion/styled';
import { UilCheck, UilTimes } from '@iconscout/react-unicons';

/**
 * Local Import
 */
import { base } from 'src/theme';

/**
 * Icons
 */
const iconStyle = () => ({
  height: '1.25rem',
  width: '1.25rem',
});

export const IconCheck = styled(UilCheck)(() => ({
  ...iconStyle(),
}));

export const IconClose = styled(UilTimes)(() => ({
  ...iconStyle(),
}));

/**
 * Style
 */
export const Container = styled.div(() => ({
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  padding: '0 1rem',
}));

export const Header = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
}));

export const Title = styled.span(({ theme }) => ({
  ...theme.typo.specials.capitalized,
  color: theme.text._5,
}));

export const UserLine = styled.li(({ isLarge }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.4rem 0',
  ':first-of-type': { paddingTop: '0' },
  ':last-of-type': { paddingBottom: '0' },
  '> div': {
    width: '100%',
    flex: 1,
    maxWidth: isLarge ? '13.5rem' : '11rem',
    ':hover': {
      background: 'none',
    },
  },
}));

export const Buttons = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '2.5rem',
  'button:first-of-type': {
    marginRight: '.25rem',
  },
}));

export const Button = styled.button(({ isCancel, isGreen, isRed, isIcon, theme }) => ({
  ...base.button({ isCancel, isGreen, isRed, isIcon, theme }),
  ...theme.typo.labels.smallLabel,
  padding: '.25rem',
  minHeight: 'unset',
}));

export const Label = styled.span(({ theme, status }) => ({
  ...theme.typo.labels.tinyLabel,
  textAlign: 'center',
  borderRadius: '8px',
  width: 'fit-content',
  padding: '.25rem .5rem',
  background: status === 'voiceGiven' ? theme.success.background : theme.info.background,
  color: status === 'voiceGiven' ? theme.success.main : theme.info.main,
}));

export const Content = styled.ul(({ theme }) => ({
  ':hover': { background: theme.background._3 },
}));
