/*
 * Package Import
 */

/*
 * Local Import
 */
import { makeRequest, VMPayload } from 'src/utils/virtualMachine';
import { User } from 'src/schemas/Entities/User';
import { isVmApiError } from './utils';

/*
 * Code
 */
export const startVm = async (user: User): Promise<number> => {
  try {
    const payload: VMPayload = {
      url: 'start/',
      user,
    };
    const res = await makeRequest(payload);

    return res?.status;
  }
  catch (err) {
    if (isVmApiError(err)) {
      if (err.response.status === 404) {
        return 404;
      }
      throw err;
    }
    throw err;
  }
};
