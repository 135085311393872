/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilSmile, UilPlusCircle } from '@iconscout/react-unicons';

/*
 * Icons
 */
export const Smile = styled(UilSmile)(({ theme }) => ({
  color: theme.text._5,
  height: '1.125rem',
  width: '1.125rem',
  ':hover': {
    color: theme.text._4,
  },
}));

export const IconAdd = styled(UilPlusCircle)(({ theme }) => ({
  height: '25px',
  width: '25px',
  color: theme.text._5,
  ':hover': {
    color: theme.text._4,
  },
}));

/*
 * Style
 */
export const Menu = styled.div(({ theme, popout }) => ({
  fontFamily: 'Inter !important',
  position: 'absolute',
  right: popout ? 'auto' : '-3.75rem',
  left: popout ? '0' : 'auto',
  bottom: '2.25rem',
  borderRadius: '16px',
  background: theme.background._2,
  ...theme.shadows.plus2,
}));

export const ContainerPicker = styled.div(({ theme }) => ({
  fontFamily: 'Inter !important',
  borderRadius: '16px',

  '.emoji-mart': {
    background: theme.background._2,
    color: theme.text._1,
  },

  '.emoji-mart-scroll': {
    borderBottom: `1px solid ${theme.background._4} !important`,

    scrollbarColor: `${theme.text._7} transparent`,
    scrollbarWidth: 'thin',

    '::-webkit-scrollbar': {
      width: '0.25rem',
    },
    '::-webkit-scrollbar-track': {
      background: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.text._7,
      borderRadius: '5px',
    },
  },

  // Header
  '.emoji-mart-anchors svg': {
    fill: theme.text._6,
    ':hover': {
      fill: theme.primary._1,
    },
  },

  '.emoji-mart-anchor-selected': {
    svg: {
      fill: `${theme.primary._2} !important`,
      ':hover': {
        fill: `${theme.primary._1} !important`,
      },
    },
  },

  '.emoji-mart-anchor-bar': {
    background: `${theme.primary._2} !important`,
  },

  // Search
  '.emoji-mart-search': {
    borderTop: `1px solid ${theme.text._7}`,

    input: {
      background: theme.background._3,
      borderRadius: `1px solid ${theme.text._7}`,
      color: theme.text._1,
      '::placeholder': {
        color: theme.text._5,
      },
    },

    '.emoji-mart-search-icon svg': {
      fill: theme.text._5,
      ':hover': {
        fill: theme.text._2,
      },
    },
  },

  // Category
  '.emoji-mart-category-label': {
    background: theme.background._2,
    color: theme.text._1,
  },

  '.emoji-mart-category .emoji-mart-emoji:hover::before': {
    background: theme.background._4,
  },

  // Footer
  '.emoji-mart-title-label': {
    color: theme.text._2,
  },

  '.emoji-mart-preview-shortname, emoji-mart-preview-emoticon': {
    color: theme.text._4,
  },

  '.emoji-mart-no-results-label': {
    color: theme.text._2,
  },

  ...theme.shadows.plus2,
}));
