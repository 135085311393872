/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import Question from 'src/components/Chats/Channel/Chat/Message/Content/Question/container';
import Spoil from 'src/components/Chats/Channel/Chat/Message/Content/Spoil/container';

// Style
import { useSelector } from 'react-redux';
import { getChatAppearance } from 'src/store/selectors/settings';
import * as S from './style';

/**
 * Component
 */
const TypeContent = ({
  children,
  hasError,
  infos,
  isAnswered,
  isAsked,
  isPinned,
  isSpoiler,
  messageId,
  userId,
}) => {
  const chatAppearance = useSelector(getChatAppearance);

  /**
   * Type • Spoiler
   */
  if (isSpoiler) {
    return (
      <>
        {chatAppearance === 'compact' && infos}
        <Spoil>{children}</Spoil>
      </>
    );
  }

  /**
   * Type • Question
   */
  if (isAsked && !isAnswered) {
    return (
      <>
        {chatAppearance === 'compact' && infos}
        <Question messageId={messageId} messageUserId={userId}>
          {children}
        </Question>
      </>
    );
  }

  /**
   * Default Render
   */
  return (
    <S.Text chatAppearance={chatAppearance} hasError={hasError} isPinned={isPinned}>
      {chatAppearance === 'compact' && infos}
      {children}
    </S.Text>
  );
};

/**
 * PropTypes
 */
TypeContent.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool.isRequired,
  infos: PropTypes.node,
  isAnswered: PropTypes.bool.isRequired,
  isAsked: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool.isRequired,
  isSpoiler: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

TypeContent.defaultProps = {
  infos: null,
};

/**
 * Export
 */
export default TypeContent;
