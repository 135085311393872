/*
 * Package Import
 */
import {
  UilPen,
  UilCheck,
  UilTimes,
  UilExclamationCircle,
  UilInfoCircle,
} from '@iconscout/react-unicons';
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

/*
 * Styles
 */
const getBodyTypoStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.bodyText.body;
const getTinyLabelStyle = (theme: ThemeInterface): Record<symbol, string> =>
  theme.typo.labels.tinyLabel;

export const Form = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '14rem',
}));

export const EditIcon = styled(UilPen)(() => ({
  width: '1.25rem',
  height: '1.25rem',
}));

export const CheckIcon = styled(UilCheck)(() => ({
  width: '1.25rem',
  height: '1.25rem',
}));

export const CancelIcon = styled(UilTimes)(() => ({
  width: '1.25rem',
  height: '1.25rem',
}));

export const EditButton = styled('button')(({ theme }) => ({
  color: theme.button.secondary.background._3,
  padding: '.5rem',
  display: 'flex',
  alignItems: 'center',
}));

export const CheckButton = styled('button')(({ theme }) => ({
  color: theme.success.main,
  padding: '.5rem',
  display: 'flex',
  alignItems: 'center',
  borderRight: `2px solid ${theme.text._7}`,
}));

export const CancelButton = styled('button')(({ theme }) => ({
  color: theme.danger.main,
  padding: '.5rem',
  display: 'flex',
  alignItems: 'center',
}));

export const ValidationButtons = styled('div')(() => ({
  display: 'flex',
}));

export const Value = styled('div')(() => ({
  // calculate padding based on input padding + its border size
  // to avoid cell resize
  padding: 'calc(.5rem + 1px) calc(.75rem + 1px)',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const AnomalyValue = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

export const EditInput = styled('input')(({ theme }) => ({
  border: `1px solid ${theme.text._7}`,
  width: '9rem',
  borderRadius: '8px',
  minHeight: '2.5rem',
  padding: '.5rem .75rem',
  fontFamily: 'Inter',
  background: theme.background._2,
  color: theme.text._1,
  ...getBodyTypoStyle(theme),
  ':hover': {
    border: `2px solid ${theme.text._6}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    background: theme.background._8,
  },
  '&.is-error': {
    border: `2px solid ${theme.danger.main}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
  '&.readOnly': {
    background: theme.background._8,
    color: theme.text._6,
  },
  ':focus': {
    border: `2px solid ${theme.primary._1}`,
    padding: 'calc(.5rem - 1px) calc(.75rem - 1px)', // to avoid moving content when hover
    boxShadow: 'none',
  },
  ':disabled': {
    background: theme.background._8,
    color: theme.text._6,
  },
}));

export const ErrorTag = styled.div(({ theme }) => ({
  backgroundColor: theme.danger.background,
  color: theme.danger.main,
  borderRadius: '25px',
  padding: '0.25rem 0.5rem',
  display: 'flex',
  justifyContent: 'center',
  ...getTinyLabelStyle(theme),
}));

export const ErrorTagLabel = styled.div(() => ({
  maxWidth: '8rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '.3rem',
}));

export const CsvErrorIcon = styled(UilExclamationCircle)(({ theme }) => ({
  color: theme.danger.main,
  width: '1.1rem',
  height: '1.1rem',
}));

export const CsvInfoIcon = styled(UilInfoCircle)(({ theme }) => ({
  color: theme.info.main,
  width: '1.1rem',
  height: '1.1rem',
}));

const getH6Style = (theme: ThemeInterface): Record<symbol, string> => theme.typo.specials.lead;
export const InformationsHeader = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '.25rem',
  ...getH6Style(theme),
}));
