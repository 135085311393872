/**
 * Package Import
 */
import * as z from 'zod';

/**
 * Local Import
 */
import { mongoIdSchema } from './utils';

export const integrationDTOSchema = z.object({
  id: mongoIdSchema,
  name: z.string(),
  isAccountLinked: z.boolean(),
  customProperties: z.object({
    signature_by_default: z.boolean(),
  }),
});

export type IntegrationDTO = z.infer<typeof integrationDTOSchema>;
