/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { UserDTO } from 'src/schemas/Entities/User';
import { API_SEND_INVITATION, API_POST } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const sendInvitation = async (email: string): Promise<UserDTO> => {
  try {
    const payload: MutationPayload = {
      method: API_POST,
      url: API_SEND_INVITATION,
      data: { email },
    };
    const { data: userData } = await api(payload);

    return userData;
  }
  catch (error) {
    toast("Dû à une erreur technique, le mail d'invitation n'a pas été renvoyé.", {
      type: 'error',
    });
    throw error;
  }
};
