import type { RootState } from 'src/store';

/**
 * Gets chat display mode (spaced / compact)
 * @param state
 * @returns string
 */
export const getChatAppearance = (state: RootState) => state.settings.layout.chatAppearance;

/**
 * Gets chat size
 * @param state
 * @returns string
 */
export const getChatSize = (state: RootState) => state.settings.layout.chatSize;

/**
 * Gets user list style to display in user panel
 * @param state
 * @returns string
 */
export const getUserListStyle = (state: RootState) => state.settings.layout.userListStyle;
