/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Code
 */
export const promotionsAddFormData = (isChild: boolean) => ({
  header: isChild ? 'Nouvelle promotion fille' : 'Nouvelle promotion',
  inputs: {
    parentId: {
      title: 'parentId',
      display: 'Id',
      type: 'hidden',
    },
    name: {
      title: 'name',
      display: 'Nom *',
      type: 'text',
    },
    start: {
      title: 'start',
      display: 'Date de début *',
      type: 'date',
    },
    end: {
      title: 'end',
      display: 'Date de fin *',
      type: 'date',
    },
  },
});
