/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { UnreadNotification } from './style';

/*
 * Component
 */
const Unread = (props) => {
  const { unread } = props;

  return (
    unread > 0 && <UnreadNotification {...props}>{unread > 9 ? '9+' : unread}</UnreadNotification>
  );
};

/*
 * PropTypes
 */
Unread.propTypes = {
  unread: PropTypes.number,
};

Unread.defaultProps = {
  unread: 0,
};

/*
 * Export
 */
export default React.memo(Unread);
