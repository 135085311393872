/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Container = styled.div(({ theme }) => ({
  border: `2px solid ${theme.text._7}`,
  borderRadius: '16px',
  ...theme.shadows.plus1,
  margin: '1rem .5rem',
}));

export const HeaderContainer = styled.div(({ theme }) => ({
  background: theme.background._8,
  borderBottom: `2px solid ${theme.text._7}`,
  borderRadius: '16px 16px 0px 0px',
  minHeight: '4.5rem',
  padding: '1rem',
  marginBottom: '.5rem',
}));

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Choice = styled.span(({ theme }) => ({
  ...theme.typo.labels.tinyLabel,
  color: theme.text._5,
}));

export const MinorTitle = styled.h4(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  color: theme.text._1,
}));

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
`;
