/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  Promotion,
  PromotionDTO,
  promotionSchema,
  promotionDTOSchema,
} from 'src/schemas/Entities/Promotion';
import { ZodError } from 'zod';

/*
 * Code
 */

interface ZodValidationFailure {
  success: false;
  error: ZodError;
  data: Promotion;
}

interface ZodValidationSuccess {
  success: true;
  data: Promotion;
}

interface ErrorWithData {
  error: ZodError;
  data: Promotion;
}

interface TransformPromotionsDTOToPromotionsResponse {
  promotions: Promotion[];
  errors: { error: ZodError; data: Promotion }[];
}
/*
 * Code
 */
const transformPromotionDTOToPromotion = (promotionDTO: PromotionDTO): Promotion => {
  const startDate = new Date(Date.parse(promotionDTO.start));
  const endDate = new Date(Date.parse(promotionDTO.end));
  // eslint-disable-next-line max-len
  const deactivatedDate = promotionDTO.deactivatedAt && new Date(Date.parse(promotionDTO.deactivatedAt));

  const { id, name, parentId, parent, isChild, displayName } = promotionDTO;

  const promotion: Promotion = {
    id,
    name,
    start: startDate,
    end: endDate,
    isChild: isChild || false,
    displayName: displayName || name,
    ...(parentId && { parentId }),
    ...(parent && { parent }),
    ...(deactivatedDate && { deactivatedAt: deactivatedDate }),
  };

  return promotion;
};

export const validateAndTransformPromotionDTOToPromotion = (
  promotionData: PromotionDTO,
): ZodValidationSuccess | ZodValidationFailure => {
  const promotion = transformPromotionDTOToPromotion(promotionData);
  const promotionDTOParseResult = promotionDTOSchema.safeParse(promotionData);
  const promotionParseResult = promotionSchema.safeParse(promotion);
  if (!promotionDTOParseResult.success) {
    return {
      error: promotionDTOParseResult.error,
      success: promotionDTOParseResult.success,
      data: promotion,
    };
  }
  if (!promotionParseResult.success) {
    return {
      error: promotionParseResult.error,
      success: promotionParseResult.success,
      data: promotion,
    };
  }

  return promotionParseResult;
};

export const transformPromotionsDTOToPromotions = (
  promotionsData: PromotionDTO[],
): TransformPromotionsDTOToPromotionsResponse => {
  const errors = [] as ErrorWithData[];
  const promotions = promotionsData.reduce((acc: Promotion[], promotionData: PromotionDTO) => {
    // for each PromotionDto received, we try to transform it in Promotion after validation
    const zodValidationPromotion = validateAndTransformPromotionDTOToPromotion(promotionData);
    // If response is a validation failure, we push at errors
    if (!zodValidationPromotion.success) {
      errors.push({ error: zodValidationPromotion.error, data: zodValidationPromotion.data });
    }

    // and we push promotion
    acc.push(zodValidationPromotion.data);
    return acc;
  }, []);

  return {
    promotions,
    errors,
  };
};
