/*
 * Package Import
 */
import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

/*
 * Local Import
 */
import Classroom from 'src/components/Classroom/container';
import MediasIntermediateStage from 'src/components/Medias/container';

// Context
import { GlobalStateContext } from 'src/context/GlobalStateMachine';

/*
 * Component
 */
const Launch = () => {
  /**
   * State Machine
   */
  const globalServices = useContext(GlobalStateContext);
  const [state, send] = useActor(globalServices.classroomService);

  /**
   * Show the intermediate stage 📽 🎤 🔊
   */
  if (state.value === 'started') {
    return <MediasIntermediateStage setApplicationState={send} />;
  }

  /**
   * Show the Classroom 👩‍🏫 👨‍🏫
   */
  if (state.value === 'joined' || state.value === 'leaving') {
    return <Classroom />;
  }

  /**
   * Default
   */
  return null;
};

/*
 * Export
 */
export default Launch;
