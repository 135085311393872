/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Controls from 'src/components/Course/Controls';
import {
  sendRaisedHand,
  enableMicrophone,
  disableMicrophone,
  enableWebcam,
  disableWebcam,
  enableSound,
  disableSound,
  enableScreen,
  disableScreen,
  setScreenStatus,
  setConferenceStatus,
} from 'src/store/actions';

// Helpers
import { isAnyoneHasVoiceGiven } from 'src/components/Course/Controls/utils';
import { getTeacher, getStudentsIdsOnline } from 'src/store/selectors/users';
import { isMaxVideoTracksReached } from 'src/utils/webRTCManager/utils';

// Constants
import { CONFERENCE_MAX_STUDENTS, HAND_STATUS } from 'src/constants/conference';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { conferenceStatus, medias, peerById, peers, usersWebcamMuted } = state.course;
  const { streamList, streamListStatus, antmediaLocalStream } = state.webRTC;

  // Users
  const { client, userById } = state.users;
  const connectedStudents = getStudentsIdsOnline(state);
  const teacherId = getTeacher(state);

  // Audio Conference is causing problems when a lot of users are connected
  // Its limited to `CONFERENCE_MAX_STUDENTS`
  const isAudioConferenceButtonDisabled = connectedStudents.length >= CONFERENCE_MAX_STUDENTS
    || isAnyoneHasVoiceGiven({ peers, peerById });

  return {
    client,
    microphoneEnabled: medias.microphoneEnabled,
    webcamEnabled: medias.webcamEnabled,
    soundEnabled: medias.soundEnabled,
    screenEnabled: medias.screenEnabled,
    conferenceStatus,
    handStatus: peerById[client.id]?.hand || HAND_STATUS.UNRAISED,
    clientRole: client.role,
    isTeacherConnected: userById[teacherId]?.connected,
    isAudioConferenceButtonDisabled,
    isMaxTracksReached: isMaxVideoTracksReached({
      camsStreamList: streamList.cams,
      streamListStatus: streamListStatus.cams,
      usersWebcamMuted,
      userById,
    }),
    isLocalStreamReady: antmediaLocalStream,
    connectedStudents,
  };
};

// Actions
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  actions: bindActionCreators(
    {
      enableMicrophone,
      disableMicrophone,
      enableWebcam,
      disableWebcam,
      enableSound,
      disableSound,
      enableScreen,
      disableScreen,
      setConferenceStatus,
      setScreenStatus,
      sendRaisedHand: sendRaisedHand(stateProps.client),
    },
    dispatchProps.dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, null, mergeProps)(Controls);
