/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { Button } from '@oclock/crumble';

/*
 * Local Import
 */
import {
  Promotion,
  promotionFormDataSchema,
  PromotionFormData,
  PromotionFormDataValidated,
  promotionFormDataValidatedSchema,
} from 'src/schemas/Entities/Promotion';
import { useUpdatePromotion } from 'src/serverStateManagers/mutations/update/Promotions/useUpdatePromotion';

import { Modal } from 'src/components/Admin/Modals/Modal';
import * as F from 'src/components/Admin/Modals/FormElements';
import { MongoId } from 'src/schemas/Entities/utils';
import { useGetPromotions } from 'src/serverStateManagers/queries/Promotions/useGetPromotions';
import { getRoute } from 'src/utils/routes';
import { promotionsUpdateFormData } from './config';

/*
 * Code
 */
type UpdatePromotionProps = {
  isOpen: boolean;
  idToUpdate: MongoId | null;
};
const UpdatePromotion = ({ isOpen, idToUpdate }: UpdatePromotionProps): JSX.Element | null => {
  const updatePromotionMutation = useUpdatePromotion();
  const history = useHistory();

  // Config
  const { header, inputs } = promotionsUpdateFormData;
  const { id, name, start, end } = inputs;

  // Entity Data
  const { data: promotionsData } = useGetPromotions();
  const promotionData = promotionsData?.find((promotion: Promotion) => promotion.id === idToUpdate);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
    reset,
  } = useForm({
    resolver: zodResolver(promotionFormDataSchema),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      history.push(getRoute('promotionsTable').getUrl({}));
    }
  }, [isSubmitSuccessful, reset, history]);

  const onSubmit = (data: PromotionFormData): void => {
    const promotionFormDataValidatedResult: PromotionFormDataValidated = promotionFormDataValidatedSchema.parse(data);
    clearErrors();
    if (idToUpdate) {
      updatePromotionMutation.mutate({
        promotionId: idToUpdate,
        promotionFormData: promotionFormDataValidatedResult,
      });
    }
  };

  const handleClose = (evt: React.MouseEvent): void => {
    evt.preventDefault();
    clearErrors();
    history.push(getRoute('promotionsTable').getUrl({}));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose}>
      <F.Form onSubmit={handleSubmit(onSubmit)}>
        <F.Header>{header}</F.Header>
        <F.Field>
          <F.Input ref={register} name={id.title} type={id.type} defaultValue={promotionData?.id} />
        </F.Field>
        <F.Field>
          <F.Label htmlFor={name.title}>{name.display}</F.Label>
          <F.Input
            className={errors.name ? 'is-error' : undefined}
            ref={register}
            name={name.title}
            type={name.type}
            defaultValue={promotionData?.name}
          />
          {errors.name?.message && <F.ErrorMessage>{errors.name.message}</F.ErrorMessage>}
        </F.Field>
        <F.Field>
          <F.Label htmlFor={start.title}>{start.display}</F.Label>
          <F.Input
            className={errors.start ? 'is-error' : undefined}
            ref={register}
            name={start.title}
            type={start.type}
            defaultValue={promotionData ? format(promotionData?.start, 'yyyy-MM-dd') : ''}
          />
          {errors.start?.message && <F.ErrorMessage>{errors.start.message}</F.ErrorMessage>}
        </F.Field>
        <F.Field>
          <F.Label htmlFor={end.title}>{end.display}</F.Label>
          <F.Input
            className={errors.end ? 'is-error' : undefined}
            ref={register}
            name={end.title}
            type={end.type}
            defaultValue={promotionData ? format(promotionData?.end, 'yyyy-MM-dd') : ''}
          />
          {errors.end?.message && <F.ErrorMessage>{errors.end.message}</F.ErrorMessage>}
        </F.Field>

        <F.Buttons>
          <Button type="button" onClick={handleClose} variant="secondary" fullWidth>
            Annuler
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)} variant="primary" fullWidth>
            Enregistrer
          </Button>
        </F.Buttons>
      </F.Form>
    </Modal>
  );
};

/*
 * Export
 */
export default UpdatePromotion;
