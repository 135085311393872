/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { UserDTO } from 'src/schemas/Entities/User';
import { updateUser, UpdateUserProps } from './updateUser';

/*
 * Code
 */
export const useUpdateUser = (): UseMutationResult<UserDTO, unknown, UpdateUserProps, unknown> => {
  const userMutation = useMutation(
    (updateUserProps: UpdateUserProps) => updateUser(updateUserProps),
    {
      onSettled: () => {
        queryClient.invalidateQueries('users');
      },
      onSuccess: (userData) => {
        toast(`Utilisateur ${userData.name} modifié avec succès !`, {
          type: 'success',
        });
      },
    },
  );

  return userMutation;
};
