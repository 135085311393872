/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import MenuHelp from 'src/components/TopBar/Menu/SubMenus/MenuHelp';
import { setModalShortcuts } from 'src/store/actions';

/*
 * Code
 */

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setModalShortcuts }, dispatch),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(MenuHelp);
