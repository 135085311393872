/**
 * Package import
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import has from 'lodash.has';

/**
 * Local import
 */
import CHATS from 'src/constants/chats';
import { SET_X_IS_TYPING } from 'src/store/types';

/**
 * Hook qui intercepte les events `...is typing`
 * Il stocke le timeout associé à une clé `chatId-userId`
 * qu'il clear au bout de TYPING_TIMEOUT_DURATION seconds
 * @param {string} chatId
 * @param {Socket} socket
 */
const useTyping = (activeChatId, socket) => {
  const [usersTyping, setUsersTyping] = useState({});
  // La ref permet aux setTimeout d'accéder à l'état réel du state au moment de son exécution,
  // Plutôt que d'avoir le state dans son état au moment de la création du timeout
  const usersTypingRef = useRef(usersTyping);
  usersTypingRef.current = usersTyping;

  /**
   * Clear & enlève un timeout pour un chatId-userId donné
   */
  const clearTyping = useCallback(
    (isTypingKey) => {
      if (has(usersTypingRef.current, isTypingKey)) {
        clearTimeout(usersTypingRef.current[isTypingKey]);
        setUsersTyping((prevUsers) => {
          const { [isTypingKey]: timeoutToRemove, ...rest } = prevUsers;

          return rest;
        });
      }
    },
    [usersTyping],
  );

  /**
   * Ajoute un timeout pour ce chatId-userId
   */
  const addTyping = useCallback(
    (isTypingKey) => {
      const isTypingTimeout = setTimeout(
        () => clearTyping(isTypingKey),
        CHATS.TYPING_TIMEOUT_DURATION,
      );

      setUsersTyping((prevUsers) => ({
        ...prevUsers,
        [isTypingKey]: isTypingTimeout,
      }));
    },
    [clearTyping, usersTyping],
  );

  /**
   * Quelqu'un écrit, si remove est à true on clear/suppr le timeout directement
   * Sinon, si on a déjà ce combo `chatId-userId`, on clear le timeout
   * Enfin, on crée un nouveau timeout
   */
  const someoneIsTyping = useCallback(
    (data) => {
      const { chatId, remove, userId } = data;
      const isTypingKey = `${chatId}-${userId}`;

      if (chatId !== activeChatId) return;

      if (remove) {
        clearTyping(isTypingKey);

        return;
      }

      if (has(usersTyping, isTypingKey)) {
        clearTimeout(usersTyping[isTypingKey]);
      }

      addTyping(isTypingKey);
    },
    [addTyping, has, clearTyping, usersTyping],
  );

  /**
   * Interception des event SET_X_IS_TYPING venant du websocket
   */
  useEffect(() => {
    socket?.on(SET_X_IS_TYPING, someoneIsTyping);

    return () => {
      socket?.off(SET_X_IS_TYPING, someoneIsTyping);
    };
  }, [socket, someoneIsTyping]);

  return { usersTyping };
};

export default useTyping;
