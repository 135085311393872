/*
 * Code
 */
const stepScale = 0.5;

/**
 * [stepDown description]
 * @param  {[type]} image       [description]
 * @param  {[type]} widthScale  [description]
 * @param  {[type]} heightScale [description]
 * @return {[type]}             [description]
 */
const stepDown = (image, widthScale, heightScale) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = widthScale;
  canvas.height = heightScale;
  ctx.scale(stepScale, stepScale);
  ctx.drawImage(image, 0, 0);
  return canvas;
};

/**
 * [scaleDown description]
 * @param  {[type]} image  [description]
 * @param  {[type]} scale  [description]
 * @param  {[type]} source [description]
 * @return {[type]}        [description]
 */
export const scaleDown = (image, { scale, source }) => {
  // Init
  const { width, height } = source;
  let currentScale = 1;
  let widthScale = width;
  let heightScale = height;
  let newImage = image;

  // Scaling down, step by step
  while (currentScale * stepScale > scale) {
    currentScale *= stepScale;
    widthScale = width * stepScale;
    heightScale = height * stepScale;
    newImage = stepDown(newImage, widthScale, heightScale);
  }

  // image downscaled, with the remainingScale to do.
  return {
    image: newImage,
    remainingScale: scale / currentScale,
  };
};
