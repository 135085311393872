/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import { logUserIn, wsConnect, setInitializeData } from 'src/store/actions';
import ExternalChat from 'src/components/Chats/External';

/*
 * Code
 */
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logUserIn,
      wsConnect,
      setInitializeData,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(null, mapDispatchToProps)(ExternalChat);
