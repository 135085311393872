/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import UsersPanel from 'src/components/Users/UsersPanel';
import { getUsersWithoutGhosts } from 'src/store/selectors/users';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  users: getUsersWithoutGhosts(state),
});

// Actions
const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(UsersPanel);
