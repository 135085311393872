/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { useMessage } from 'src/components/Classroom/hooks';
import Overlay from 'src/components/Overlay';

// Style
import * as S from './style';

/*
 * Component
 */
const ConfirmationDelete = ({
  actions,
  clearDelete,
  deleting,
  chatId,
  messageId,
  messageUserId,
}) => {
  const { deleteMessage } = useMessage();

  /**
   * handleClose
   * @return {void}
   */
  const handleClose = () => {
    clearDelete();
  };

  /**
   * handleDelete
   * @return {void}
   */
  const handleDelete = async () => {
    const payload = {
      chatId,
      messageId,
      messageUserId,
    };

    await actions.deleteMessage(payload);
    deleteMessage(payload);

    handleClose();
  };

  /*
   * Render
   */
  if (!deleting) {
    return null;
  }

  return (
    <Overlay onClose={handleClose}>
      <S.Modal>
        {/* Header */}
        <S.Title>Supprimer le message</S.Title>

        {/* Body */}
        <S.Body>
          Voulez-vous vraiment supprimer ce message ? <br />
          Cette opération est irréversible.
        </S.Body>

        {/* Footer */}
        <S.Footer>
          <S.Button type="button" onClick={handleClose} isCancel>
            Non
          </S.Button>
          <S.Button type="button" onClick={handleDelete} isMain>
            Supprimer
          </S.Button>
        </S.Footer>
      </S.Modal>
    </Overlay>
  );
};

/*
 * PropTypes
 */
ConfirmationDelete.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** Function to exit the delete state */
  clearDelete: PropTypes.func,
  /** If the message is in delete state */
  deleting: PropTypes.bool,
  /** On which channel the message is displayed */
  chatId: PropTypes.string.isRequired,
  /** ID of the current message to delete */
  messageId: PropTypes.string.isRequired,
  /** ID of message's creator */
  messageUserId: PropTypes.string.isRequired,
};

ConfirmationDelete.defaultProps = {
  clearDelete: null,
  deleting: false,
};

/*
 * Export
 */
export default ConfirmationDelete;
