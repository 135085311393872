/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Package Component Import
 */
import { MenuDivider, MenuItem, SubMenu } from '@szhsin/react-menu';

/*
 * Local Import
 */
import ROLES from 'src/constants/roles';

// Style
import * as S from '../style';
import * as P from '../../style';

/*
 * Component
 */
const MenuNotifications = ({
  actions,
  disabled,
  enabled,
  index,
  pinEnabled,
  privateEnabled,
  role,
  surveyEnabled,
  soundEnabled,
}) => {
  /*
   * Handlers
   */

  const handleChange = (name) => (evt) => {
    if (name !== 'enabled' && !enabled) {
      return;
    }
    actions.toggleNotification(name);
    // Keep menu opened when clicked
    evt.keepOpen = true;
    evt.stopPropagation = true;
  };

  /*
   * Render
   */
  return (
    <SubMenu
      label={(
        <P.ContainerLabel>
          <P.IconBell aria-hidden="true" />
          &nbsp; Notifications
        </P.ContainerLabel>
      )}
      index={index}
      disabled={disabled}
    >
      <MenuItem onClick={handleChange('enabled')}>
        <S.Form>
          <S.ContainerSwitch>
            <S.Switch isActive={enabled} name="enabled" />
          </S.ContainerSwitch>
          <S.Label isShort>Activer les notifications</S.Label>
        </S.Form>
      </MenuItem>
      <MenuItem onClick={handleChange('soundEnabled')}>
        <S.Form>
          <S.ContainerSwitch>
            <S.Switch disabled={!enabled} isActive={soundEnabled} name="soundEnabled" />
          </S.ContainerSwitch>
          <S.Label isShort>Activer le son des notifications</S.Label>
        </S.Form>
      </MenuItem>

      <MenuDivider />
      <MenuItem onClick={handleChange('pinEnabled')}>
        <S.Form>
          <S.ContainerSwitch>
            <S.Switch disabled={!enabled} isActive={pinEnabled} name="pinEnabled" />
          </S.ContainerSwitch>
          <S.Label isShort>Messages épinglés</S.Label>
        </S.Form>
      </MenuItem>
      <MenuItem onClick={handleChange('privateEnabled')}>
        <S.Form>
          <S.ContainerSwitch>
            <S.Switch disabled={!enabled} isActive={privateEnabled} name="privateEnabled" />
          </S.ContainerSwitch>
          <S.Label isShort>Messages privés</S.Label>
        </S.Form>
      </MenuItem>
      {role === ROLES.ROLE_STUDENT && (
        <MenuItem onClick={handleChange('surveyEnabled')}>
          <S.Form>
            <S.ContainerSwitch>
              <S.Switch disabled={!enabled} isActive={surveyEnabled} name="surveyEnabled" />
            </S.ContainerSwitch>
            <S.Label isShort>Sondages</S.Label>
          </S.Form>
        </MenuItem>
      )}
    </SubMenu>
  );
};

/*
 * PropTypes
 */
MenuNotifications.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State all notification for state switch */
  enabled: PropTypes.bool.isRequired,
  /** State survey notification for state switch */
  surveyEnabled: PropTypes.bool.isRequired,
  /** State private message notification for state switch */
  privateEnabled: PropTypes.bool.isRequired,
  /** State pin notification for state switch */
  pinEnabled: PropTypes.bool.isRequired,
  /** State sound notification for state switch */
  soundEnabled: PropTypes.bool.isRequired,
  /** User role for display on no options */
  role: PropTypes.string.isRequired,
  /** All 2 next props are necessary for the call of applyStatics */
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

MenuNotifications.defaultProps = {
  disabled: false,
};

/*
 * Export
 */
export default MenuNotifications;
