/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Package Component Import
 */
import { MenuItem, SubMenu } from '@szhsin/react-menu';

/*
 * Local Import
 */
// Style
import * as G from '../../style';
import * as P from '../../../style';

/*
 * Component
 */
const Transcription = (props) => {
  const { actions, enabled } = props;

  /*
   * Handlers
   */
  const handleChange = (evt) => {
    actions.setTranscritionEnabled(!enabled);
    // Keep menu opened when clicked
    evt.keepOpen = true;
    evt.stopPropagation = true;
  };

  /*
   * Render
   */
  return (
    <SubMenu
      label={(
        <G.ContainerCheckBox>
          <G.Checkbox />
          <P.IconTranscription aria-hidden="true" />
          &nbsp; Sous-titres
        </G.ContainerCheckBox>
      )}
      index={props.index}
    >
      <MenuItem onClick={handleChange}>
        <G.Form>
          <G.ContainerSwitch>
            <G.Switch isActive={enabled} name="transcriptionEnabled" />
          </G.ContainerSwitch>
          <G.Label isShort>Activer les sous-titres</G.Label>
        </G.Form>
      </MenuItem>
    </SubMenu>
  );
};

/*
 * PropTypes
 */
Transcription.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  enabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

/*
 * Export
 */
export default Transcription;
