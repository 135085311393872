/*
 * Package Import
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*
 * Local import
 */
import { NOT_FOUND_TITLE } from 'src/constants/titles';
import { trackEvent } from 'src/utils/tracking';

// Style
import * as S from './style';

/**
 * Component
 */
const NotFound = () => {
  /*
   * Hooks
   */
  const { pathname } = useLocation();

  /**
   * LifeCycles
   */
  useEffect(() => {
    document.title = NOT_FOUND_TITLE;

    // Mixpanel tracking event
    trackEvent('Page Viewed', {
      title: NOT_FOUND_TITLE,
      url: pathname,
    });
  }, []);

  /**
   * Render
   */
  return (
    <S.Container>
      <S.Number>404</S.Number>
      <S.Text>Vous vous êtes perdu ?</S.Text>
      <S.Link href="/">Retour à la page d&apos;accueil</S.Link>
    </S.Container>
  );
};

/*
 * Export
 */
export default NotFound;
