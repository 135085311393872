/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  userDisplayBeforeCreateSchema,
  userDTOSchemaWithoutId,
  UserDTOWithoutId,
  UserDisplayBeforeCreate,
} from 'src/schemas/Entities/User';
import { MongoId } from 'src/schemas/Entities/utils';
import { purify, purifyIfExist } from '../utils';

/*
 * Code
 */
export const transformUserDisplayToUserDTOWithoutId = (
  user: UserDisplayBeforeCreate,
): UserDTOWithoutId => {
  const userParseResult = userDisplayBeforeCreateSchema.parse(user);

  const promotionsIds: MongoId[] = userParseResult.promotions.reduce(
    (ids: MongoId[], promotion) => {
      if (promotion) ids.push(promotion.id);
      return ids;
    },
    [],
  );

  // eslint-disable-next-line max-len
  const { firstname, lastname, email, role, githubProfile, discordProfile, town, country, avatar } = userParseResult;

  const userDTO = {
    firstname: purify(firstname),
    lastname: purify(lastname),
    email: purify(email),
    role,
    githubProfile: purifyIfExist(githubProfile),
    discordProfile: purifyIfExist(discordProfile),
    town: purifyIfExist(town),
    country: purifyIfExist(country),
    avatar,
    promosIds: promotionsIds,
  };

  const userDTOParseResult = userDTOSchemaWithoutId.parse(userDTO);

  return userDTOParseResult;
};

export const transformUsersDisplayToUsersDTOWithoutId = (
  users: UserDisplayBeforeCreate[],
): UserDTOWithoutId[] => {
  const usersDTO = users.map((user) => transformUserDisplayToUserDTOWithoutId(user));
  return usersDTO;
};
