/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Dialog } from '@material-ui/core';

/**
 * Local Import
 */
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/*
 * Style
 */

// Modal shortcuts
export const Modal = styled(Dialog)(({ theme }) => ({
  fontFamily: 'Inter !important',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: 'auto',
  overflow: 'hidden',
  '.MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    position: 'relative',
    minWidth: '35%',
    maxWidth: 'unset',
    minHeight: '65%',
    height: '79%',
    background: theme.background._2,
    borderRadius: '8px',
    border: `2px solid ${theme.text._7}`,
    overflow: 'hidden',
    ...theme.shadows.plus3,
  },
}));

// Title modal shortcuts
export const Title = styled.div(({ theme }) => ({
  ...theme.typo.headlines.H4,
  color: theme.colors.coolGrey._50,
  width: '100%',
  backgroundColor: theme.themeName === LIGHT_THEME_NAME ? theme.primary._1 : theme.primary._5,
  padding: '1.5rem',
  position: 'relative',
}));

// Container X close modal
export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  color: ${({ theme }) => theme.background._2};
`;

// Body of modal
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0.75rem 0.5rem;

  scrollbar-color: ${({ theme }) => theme.background._7} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background._7};
    border-radius: 5px;
  }
`;

// Title of category shortcuts
export const SubTitle = styled.div(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  color: theme.text._1,
  width: '100%',
  padding: '.75rem 1.5rem',
}));

// Line contain one shortcut
export const Line = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1.5rem;
`;

// Label action of shortcut
export const Action = styled.div(({ theme }) => ({
  ...theme.typo.bodyText.body,
  color: theme.text._5,
  width: '57%',
  paddingRight: '.5rem',
}));

// Group keys of shortcut
export const Shorcut = styled.div(({ theme }) => ({
  ...theme.typo.bodyText.body,
  color: theme.text._1,
  width: '43%',
  padding: '0 .5em',
}));

// One key of shortcut
export const Key = styled.span(({ theme }) => ({
  ...theme.typo.labels.label,
  color: theme.text._1,
}));

// '+' separate keys of shortcut
export const Separator = styled.span(({ theme }) => ({
  ...theme.typo.bodyText.body,
  color: theme.text._5,
}));
