/*
 * Package Import
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { HAND_STATUS } from 'src/constants/conference';
import { CONTROLS_ID } from 'src/constants/controls';
import { setFocus } from 'src/utils';
import * as S from './style';

/*
 * Code
 */
const status = {
  [HAND_STATUS.RAISED]: 'En attente',
  [HAND_STATUS.VOICE_GIVEN]: 'Vous avez la parole',
};

/*
 * Component
 */
const Modal = ({ actions, clientId, handStatus, onClose }) => {
  /*
   * State
   */
  const [isOpen, setIsOpen] = useState(true);

  /**
   * Unraise hand of current user
   * @return {void}
   */
  const unraiseHand = () => {
    actions.sendUnraisedHand(clientId);
  };

  /**
   * Close the modal
   * @return {void}
   */
  const handleCloseModal = () => {
    onClose();
    setIsOpen(false);
  };

  /**
   * Unraise hand of current user
   * @return {void}
   */
  const stopSpeaking = () => {
    if (handStatus === HAND_STATUS.VOICE_GIVEN) {
      actions.sendTakeVoice(clientId);
    }
  };

  /**
   * Set focus on the "close" button
   * @return {void}
   */
  const handleFocus = () => {
    setFocus('closeButton');
  };

  return (
    <S.ModalContainer
      role="dialog"
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterOpen={handleFocus}
      shouldReturnFocusAfterClose={false}
      ariaLabel="Statut de la prise de parole"
      customParent={`${CONTROLS_ID.HAND}-container`}
      customOverlayCss={{ position: 'unset' }}
    >
      {/* Close Button */}
      <S.Close aria-label="Fermer" id="closeButton" onClick={handleCloseModal} type="button">
        <S.StyledUilClose />
      </S.Close>

      {/* Content */}
      <S.Title>Demande de parole</S.Title>
      <S.Text>Statut de la demande</S.Text>
      <S.Status status={handStatus}>{status[handStatus]}</S.Status>

      {/* Buttons */}
      <S.ButtonContainer>
        {handStatus === HAND_STATUS.RAISED && (
          <S.CancelButton onClick={unraiseHand} isCancel>
            Annuler
          </S.CancelButton>
        )}
        <S.StopButton onClick={stopSpeaking} isMain disabled={handStatus === HAND_STATUS.RAISED}>
          Rendre la parole
        </S.StopButton>
      </S.ButtonContainer>
    </S.ModalContainer>
  );
};

/*
 * PropTypes
 */
Modal.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  clientId: PropTypes.string.isRequired,
  /** Current user hand status */
  handStatus: PropTypes.string.isRequired,
  /**
   * Close modale handler
   * @return {void}
   */
  onClose: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default Modal;
