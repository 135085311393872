/*
 * Package Import
 */
import * as z from 'zod';

/**
 * Constants • Messages • Types
 */
export const MESSAGE_TYPE = {
  TYPE_TEXT: 'message',
  TYPE_SURVEY: 'survey',
} as const;

// Types
export const MESSAGE_TYPE_SCHEMA = z.nativeEnum(MESSAGE_TYPE);
export type MessageTypeEnum = z.infer<typeof MESSAGE_TYPE_SCHEMA>;

/**
 * Constants • Messages • Statuses
 */
export const MESSAGE_STATUS = {
  STATUS_RECEIVED: 'received',
  STATUS_SENDING: 'sending',
  STATUS_FAILED: 'failed',
  STATUS_ERROR: 'error',
} as const;

// Types
export const MESSAGE_STATUS_SCHEMA = z.nativeEnum(MESSAGE_STATUS);
export type MessageStatusEnum = z.infer<typeof MESSAGE_STATUS_SCHEMA>;

/**
 * Constants • Messages • Filters
 */
export const MESSAGE_FILTER = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_PINNED: 'SHOW_PINNED',
  SHOW_MENTIONED: 'SHOW_MENTIONED',
  SHOW_QUESTION: 'SHOW_QUESTION',
} as const;

// Types
export const MESSAGE_FILTER_SCHEMA = z.nativeEnum(MESSAGE_FILTER);

/**
 * Constants · Messages
 */
export default Object.freeze({
  ...MESSAGE_TYPE,
  ...MESSAGE_STATUS,
  ...MESSAGE_FILTER,

  /**
   * Limit
   */
  MAX_LIMIT_LENGTH: 200,
  START_OFFSET: 100_000_000,
  STEP_MESSAGES: 25,
});
