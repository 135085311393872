import * as z from 'zod';
import { dateStringToNumberValue } from 'src/utils/time';
import * as refines from 'src/schemas/Refines/promotions';
import { dateParams, datetimeParams, mongoIdSchema } from './utils';

const promotionDTOSchemaBase = z.object({
  id: mongoIdSchema,
  parentId: mongoIdSchema.optional(),
  isChild: z.boolean().optional(),
  // TODO: Éviter de réécrire le schéma
  parent: z
    .object({
      id: mongoIdSchema,
      name: z.string(),
      start: z.string(),
      end: z.string(),
      deactivatedAt: z.string().optional(),
    })
    .optional(),
  name: z.string(),
  displayName: z.string().optional(),
  start: z.string(),
  end: z.string(),
  deactivatedAt: z.string().optional(),
});

export const promotionDTOSchema = promotionDTOSchemaBase.refine(
  (data) => dateStringToNumberValue(data.start) <= dateStringToNumberValue(data.end),
  {
    message: 'La date de fin de la promotion doit être supérieure à la date de départ',
    path: ['end'],
  },
);

export const promotionDTOSchemaWithoutId = promotionDTOSchemaBase
  .omit({
    id: true,
  })
  .refine((data) => dateStringToNumberValue(data.start) <= dateStringToNumberValue(data.end), {
    message: 'La date de fin de la promotion doit être supérieure à la date de départ',
    path: ['end'],
  });

export const promotionSchema = z
  .object({
    id: mongoIdSchema,
    isChild: z.boolean(),
    parentId: mongoIdSchema.optional(),
    parent: z
      .object({
        id: mongoIdSchema,
        name: z.string(),
        start: z.string(),
        end: z.string(),
        deactivatedAt: z.string().optional(),
      })
      .optional(),
    name: z.string(),
    displayName: z.string().optional(),
    start: z.date(),
    end: z.date(),
    deactivatedAt: z.date().optional(),
  })
  .refine((data) => data.start <= data.end, {
    message: 'La date de fin de la promotion doit être supérieure à la date de départ',
    path: ['end'],
  });

export const promotionFormDataSchema = z
  .object({
    id: mongoIdSchema.optional(),
    parentId: mongoIdSchema.optional(),
    name: z
      .string()
      .nonempty('Le nom doit être rempli')
      .refine((data) => data.trim().length !== 0, {
        message: 'Le nom doit être rempli',
      }),
    start: z
      .string()
      .nonempty('La date de début doit être remplie')
      .regex(...dateParams),
    end: z
      .string()
      .nonempty('La date de fin doit être remplie')
      .regex(...dateParams),
  })
  .refine((data) => dateStringToNumberValue(data.start) <= dateStringToNumberValue(data.end), {
    message: 'La date de fin de la promotion doit être postérieure à la date de départ',
    path: ['end'],
  })
  .refine(refines.promotionFormDatesMustBeOkWithCourses, {
    message: 'Les dates de la promotion ne correspondent pas aux dates des cours déjà créés',
    path: ['end'],
  });

export const promotionFormDataValidatedSchema = z.object({
  id: mongoIdSchema.optional(),
  parentId: mongoIdSchema.optional(),
  name: z.string().nonempty('Le nom doit être rempli'),
  start: z
    .string()
    .nonempty('La date de début doit être remplie')
    .regex(...dateParams),
  end: z
    .string()
    .nonempty('La date de fin doit être remplie')
    .regex(...dateParams),
});

export const promotionActivityExportFormDataSchema = z
  .object({
    promotionId: mongoIdSchema,
    start: z
      .string()
      .nonempty('La date de début doit être remplie')
      .regex(...datetimeParams),
    end: z
      .string()
      .nonempty('La date de fin doit être remplie')
      .regex(...datetimeParams),
  })
  .refine((data) => dateStringToNumberValue(data.start) <= dateStringToNumberValue(data.end), {
    message: 'La date de fin de la promotion doit être postérieure à la date de départ',
    path: ['end'],
  })
  .refine(refines.promotionActivityExportFormDatesMustBeOkWithPromotionDates, {
    message: "La période d'activité ne correspond pas aux dates de la promotion",
    path: ['end'],
  });

export const promotionActivityExportFormDataValidatedSchema = z.object({
  promotionId: mongoIdSchema.optional(),
  start: z
    .string()
    .nonempty('La date de début doit être remplie')
    .regex(...dateParams),
  end: z
    .string()
    .nonempty('La date de fin doit être remplie')
    .regex(...dateParams),
});

export type PromotionDTO = z.infer<typeof promotionDTOSchema>;
export type Promotion = z.infer<typeof promotionSchema>;
export type PromotionDTOWithoutId = z.infer<typeof promotionDTOSchemaWithoutId>;
export type PromotionFormData = z.infer<typeof promotionFormDataSchema>;
export type PromotionFormDataValidated = z.infer<typeof promotionFormDataValidatedSchema>;
export type PromotionActivityExportFormData = z.infer<typeof promotionActivityExportFormDataSchema>;
export type PromotionActivityExportFormDataValidated = z.infer<
  typeof promotionActivityExportFormDataValidatedSchema
>;
