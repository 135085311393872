/**
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/**
 * Local Import
 */
import { getAvatar } from 'src/utils/avatar';

/**
 * Style
 */
const Image = styled.img(() => ({
  borderRadius: '7px',
  maxWidth: '64px',
}));

/**
 * Types
 */
interface AvatarProps {
  avatar: string;
  firstname: string;
  lastname: string;
}

/**
 * Component
 */
export const Avatar = ({ avatar, firstname, lastname }: AvatarProps): JSX.Element => {
  const name = `${firstname} ${lastname}`;
  const userAvatar = getAvatar({ avatar, name });

  return (
    <Image
      alt={`avatar de ${name}`}
      height="64"
      src={userAvatar}
      onError={(event) => {
        if (event.currentTarget.src !== userAvatar) {
          event.currentTarget.src = getAvatar({ name });
        }
      }}
    />
  );
};
