/*
 * Package Import
 */
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

/*
 * Local Import
 */
import Send from 'src/components/Chats/Channel/Chat/Input/Send';
import Composer from 'src/components/Chats/Channel/Chat/Input/Composer/container';
import QuillInput from 'src/components/Chats/Channel/Chat/Input/QuillInput/container';
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';
import { inputChange, resetInput } from 'src/store/actions';
import { getInputContent } from 'src/store/selectors/inputs';

// Helpers
import { commands, isCommand } from '../commands';
import { insertEmoji, isMessageEmpty } from '../utils';

// Style
import * as S from './style';

/*
 * Component
 */
const InputMessage = ({ actions, context, chatId, ...props }) => {
  const reactQuillRef = useRef(null);

  const { addMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  const dispatch = useDispatch();
  const savedInputState = useSelector((state) => getInputContent(state, chatId));

  /**
   * Clears Quill input
   */
  const clearInput = useCallback(() => {
    reactQuillRef.current?.editor.setContents({ ops: [] });
    dispatch(resetInput(chatId));
  }, [chatId]);

  const clearTyping = () => {
    actions.isTyping(true);
  };

  /**
   * Detect and execute command
   * @param {RegExpExecArray} regExpResult
   */
  const execCommandOrSend = useCallback(
    (value) => {
      const { insert } = value.ops[0];

      // get command name from insert
      const commandName = isCommand(insert);

      // get command from name
      const command = commands[commandName];

      if (command) {
        // Execute command
        if (command({ actions, addMessage, chatId, commandName, context, value, ...props })) {
          // Reset input
          clearInput();
        }
      }
      else {
        // Send message
        const payload = {
          content: value,
          context,
          chatId,
          userId: user.id,
          name: user.name,
          messageUuid: uuid(),
        };

        actions.sendMessage(payload);
        addMessage(payload);

        clearInput();
      }
    },
    [addMessage, clearInput],
  );

  /**
   * Handle sending "chat.i_am_typing" to websocket.
   * Event is throttled to avoid dispatching too many actions at once.
   */
  const handleChange = useCallback(
    throttle((value, source) => {
      // Save current input state
      dispatch(inputChange(chatId)(value));

      if (source && source !== 'api') {
        if (isMessageEmpty(value)) {
          clearTyping();
        }
        else {
          actions.isTyping(false);
        }
      }
    }, 250),
    [],
  );

  const handleKeyboard = useCallback((shortcut) => {
    const currentFormats = reactQuillRef.current?.editor.getFormat();

    switch (shortcut) {
      case 'code-block': {
        const isCodeBlock = currentFormats['code-block'];
        reactQuillRef.current?.editor.format('code-block', !isCodeBlock, 'user');
        break;
      }
      default:
        break;
    }
  }, []);

  /**
   * Send the message with options
   * @param {Object} · opts
   * @return {void}
   */
  const sendMessageWithOptions = useCallback(
    (value, opts = {}) => {
      const payload = {
        content: value,
        context,
        chatId,
        isSpoiler: opts.isSpoiler,
        isAsked: opts.isAsked,
        userId: user.id,
        name: user.name,
        messageUuid: uuid(),
      };

      actions.sendMessage(payload);
      addMessage(payload);

      clearInput();
    },
    [addMessage, clearInput],
  );

  /**
   * Submit the message with options OR after executing / commands and clear the `isTyping` event.
   * @param {FormEvent|MouseEvent} · event
   * @return {void}
   */
  const handleSubmit = useCallback(
    ({ event = null, options = {} } = {}) => {
      if (event) event.preventDefault();

      const value = reactQuillRef.current?.editor.getContents();

      if (isMessageEmpty(value)) return;

      // Send with options ?
      const isWithSomeOptions = Object.keys(options).some((option) => options[option]);

      if (isWithSomeOptions) {
        sendMessageWithOptions(value, options);
      }
      else {
        execCommandOrSend(value);
      }

      clearTyping();
    },
    [execCommandOrSend, sendMessageWithOptions],
  );

  /**
   * Select an emoji
   * @param  {Object} emoji
   */
  const onEmojiSelected = useCallback((emoji) => {
    if (reactQuillRef.current) {
      reactQuillRef.current.focus();
      insertEmoji(reactQuillRef.current.editor, emoji.colons);
    }
  }, []);

  /**
   * Load saved input state on chat change &
   * put cursor at the end, if needed
   */
  useEffect(() => {
    if (reactQuillRef.current && savedInputState) {
      reactQuillRef.current.editor.setContents(savedInputState);
      reactQuillRef.current.editor.setSelection(reactQuillRef.current.editor.getLength(), 0);
    }
  }, []);

  return (
    <S.Message>
      <S.Form onSubmit={handleSubmit}>
        <S.SubTitleSrOnly>Écrire un message</S.SubTitleSrOnly>
        {/* Input */}
        <QuillInput
          // React
          myRef={reactQuillRef}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleKeyboard={handleKeyboard}
          // QuillJS
          toolbarSelector="#toolbar"
          // HTML
          placeholder="Message"
          aria-describedby="messageNoDisplay"
          aria-label="Saisissez un message"
        />

        {/* a11y */}
        <S.ParagraphNoDisplay id="messageNoDisplay">
          Des contrôles d’insertion de contenus particuliers et mises en forme sont disponibles
          après ce champ.
        </S.ParagraphNoDisplay>

        {/* Action buttons */}
        <S.Buttons>
          <Send sendMessage={handleSubmit} />
        </S.Buttons>
      </S.Form>

      {/* Composer */}
      <Composer id="toolbar" onEmojiSelected={onEmojiSelected} />
    </S.Message>
  );
};

InputMessage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  context: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
};

export default InputMessage;
