/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const TranscriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const TranscriptionBackground = styled.div`
  position: relative;
  width: 100%;
  max-width: 1600px;
  height: 102px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 12px;
  padding-top: 8px;
  margin-bottom: 8px;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
`;
export const Transcription = styled.div`
  position: absolute;
  bottom: 0;
`;
