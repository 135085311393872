/**
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, Icon, InputField, Logo } from '@oclock/crumble';
import { toast } from 'react-toastify';

/**
 * Local Import
 */
import Modal from 'src/components/Modal';

// Helpers
import { api } from 'src/utils/api';
import { IntegrationApps } from 'src/constants/integrations';

// Assets
import Screen_Aide_API_Edusign from 'assets/images/customs/Screen_Aide_API_Edusign.jpg';

// Style
import * as P from '../style';
import * as S from './style';

/**
 * Code
 */
const INTEGRATION_EDUSIGN_VIEWS = {
  LOADING: 'loading',
  WELCOME: 'welcome',
  CONNEXION: 'connexion',
  AUTHORIZATION: 'authorization',
  AUTHORIZATION_OPTS: 'authorization_opts',
  SUCCESSED: 'successed',
  FAILED: 'failed',
};

/**
 * Types
 */
type IValueState = {
  access_token: string;
  signature_by_default?: boolean;
};

/**
 * Component
 */
const Edusign = () => {
  /**
   * State
   */
  const [view, setView] = useState(INTEGRATION_EDUSIGN_VIEWS.WELCOME);

  const [value, setValue] = useState<IValueState>({
    access_token: '',
    signature_by_default: undefined,
  });

  const [modal, setModal] = useState({
    showHelpModal: false,
    showConfirmationModal: false,
    showConfirmationDeleteModal: false,
  });

  /**
   * Create or update the data of Edusign integration.
   * If the Edusign integration is on, we can create SigninSheets.
   */
  const setIntegrationData = () =>
    api({
      method: 'POST',
      url: '/integrations-apps/create',
      data: {
        name: IntegrationApps.EDUSIGN,
        access_token: value.access_token,
        customProperties: {
          signature_by_default:
            typeof value.signature_by_default === 'boolean' ? value.signature_by_default : true,
        },
      },
    });

  /**
   * Update the access token value,
   * for the Edusign API integration.
   */
  const onAccessTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prevState) => ({
      ...prevState,
      access_token: event.target.value,
    }));
  };

  /**
   * Update the `signature default` value
   */
  const onDefaultSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const defaultSignatureMapper: { [key: string]: boolean } = {
      on: true,
      off: false,
    };
    const hasDefaultSignature = defaultSignatureMapper[event.target.value] || false;

    setValue((prevState) => ({
      ...prevState,
      signature_by_default: hasDefaultSignature,
    }));
  };

  /**
   * Submit the first part of data, for the Edusign API integration.
   */
  const onAuthorizationSubmit = () =>
    setIntegrationData()
      .then(() => {
        setView(INTEGRATION_EDUSIGN_VIEWS.AUTHORIZATION_OPTS);
      })
      .catch((error) => {
        if (error) {
          if (error.response?.data?.error === 'Connection failed') {
            toast(
              "La connexion n'a pas pu se faire. Veuillez réessayer ou contacter l'équipe Slippers.",
              {
                type: 'error',
              },
            );
          }
          else {
            toast("Une erreur est survenue. Veuillez réessayer ou contacter l'équipe Slippers.", {
              type: 'error',
            });
          }
        }
      });

  /**
   * Submit the second part of data, for the Edusign API integration.
   */
  const onAuthorizationOptsSubmit = () =>
    setIntegrationData().then(() => {
      setView(INTEGRATION_EDUSIGN_VIEWS.SUCCESSED);
      setModal((prevState) => ({ ...prevState, showConfirmationModal: true }));
    });

  /**
   * Reset all state
   */
  const onResetIntegration = () => {
    api({
      method: 'PUT',
      url: `/integrations-apps/update/${IntegrationApps.EDUSIGN}`,
      data: {
        name: IntegrationApps.EDUSIGN,
        access_token: '',
      },
    })
      .then(() => {
        setView(INTEGRATION_EDUSIGN_VIEWS.WELCOME);
        setValue({ access_token: '', signature_by_default: undefined });
        setModal((prevState) => ({ ...prevState, showConfirmationDeleteModal: false }));
      })
      .catch(() => {
        toast("Une erreur est survenue. Veuillez réessayer ou contacter l'équipe de Slippers.", {
          type: 'error',
        });
      });
  };

  /**
   *
   */
  const onCloseModal = (key: string): void => {
    setModal((prevState) => ({ ...prevState, [key]: false }));
  };

  /**
   *
   */
  const onDisplayModal = (key: string): void => {
    setModal((prevState) => ({ ...prevState, [key]: true }));
  };

  /**
   * LifeCycles
   */
  useEffect(() => {
    // Fetch the initial data
    api({
      method: 'GET',
      url: `/integrations-apps/find/${IntegrationApps.EDUSIGN}`,
    })
      .then(({ data }) => {
        if (data?.isAccountLinked) {
          setView(INTEGRATION_EDUSIGN_VIEWS.SUCCESSED);
        }
      })
      .catch(() => {
        toast("Une erreur est survenue. Veuillez réessayer ou contacter l'équipe de Slippers.", {
          type: 'error',
        });
      });
  }, []);

  /**
   * Render
   */
  return (
    <div>
      <P.Title>Edusign</P.Title>
      <P.Description>
        Edusign permet de dématérialiser les feuilles d’émargement.
        <br />
        La connexion avec Slippers automatise la création et la signature des feuilles d’émargement
        très simplement.
        <br />
        Connectez votre compte Edusign à Slippers pour bénéficier de l’intégration&nbsp;!
      </P.Description>

      {/*
        Cards • Step 1
        */}
      {(view === INTEGRATION_EDUSIGN_VIEWS.WELCOME
        || view === INTEGRATION_EDUSIGN_VIEWS.FAILED) && (
        <>
          <P.Card>
            <S.LogoContainer>
              <S.EdusignLogoContainer>
                <S.EdusignLogo />
              </S.EdusignLogoContainer>
            </S.LogoContainer>
            <P.CardTitle>Connexion à Edusign</P.CardTitle>
            <P.Footer>
              <S.EdusignCTA
                type="button"
                onClick={() => setView(INTEGRATION_EDUSIGN_VIEWS.CONNEXION)}
              >
                Relier mon compte à Edusign
              </S.EdusignCTA>
            </P.Footer>
          </P.Card>
          {view === INTEGRATION_EDUSIGN_VIEWS.FAILED && (
            <P.ErrorMessage>
              Vous devez autoriser l’accès pour que Slippers puisse communiquer à Edusign les
              informations nécéssaires.
            </P.ErrorMessage>
          )}
        </>
      )}

      {/*
        Cards • Step 2
        */}
      {view === INTEGRATION_EDUSIGN_VIEWS.CONNEXION && (
        <>
          <P.Card>
            <S.LogoContainer>
              <S.EdusignLogoContainer>
                <S.EdusignLogo />
              </S.EdusignLogoContainer>
            </S.LogoContainer>
            <P.CardTitle>Connexion à Edusign</P.CardTitle>
            <S.ContentContainer>
              <P.Content>
                <InputField
                  type="text"
                  label="Clé API"
                  placeholder="Clé API Edusign"
                  onChange={onAccessTokenChange}
                  value={value.access_token}
                />
                <S.ModalCTA type="button" onClick={() => onDisplayModal('showHelpModal')}>
                  Comment récupérer ma clé API ?
                </S.ModalCTA>
              </P.Content>
            </S.ContentContainer>
            <P.Footer>
              <Button
                type="button"
                onClick={() => setView(INTEGRATION_EDUSIGN_VIEWS.AUTHORIZATION)}
                disabled={value.access_token.length === 0}
              >
                Valider
              </Button>
            </P.Footer>
          </P.Card>

          {/* showConfirmationModal */}
          {modal.showHelpModal && (
            <Modal
              customParent="root"
              isOpen={modal.showHelpModal}
              onRequestClose={() => onCloseModal('showHelpModal')}
              withOverlay
            >
              <S.ModalContent>
                {/* Close Button */}
                <S.Close
                  aria-label="Fermer"
                  id="closeButton"
                  onClick={() => onCloseModal('showHelpModal')}
                  type="button"
                >
                  <S.StyledUilClose />
                </S.Close>
                <S.ModalTitle>Récupérer ma clé API</S.ModalTitle>
                <P.Content>
                  <P.CardDescription>
                    Pour récupérer votre clé API, vous devez aller dans les paramètres de votre
                    compte Edusign, dans l&apos;onglet &quot;API&quot;.
                  </P.CardDescription>
                  <br />
                  <P.CardDescription>
                    Vous pouvez accéder à vos paramètre via ce lien :{' '}
                    <a href="https://edusign.app/school/settings" target="_blank" rel="noreferrer">
                      https://edusign.app/school/settings
                    </a>
                  </P.CardDescription>

                  <S.Img src={Screen_Aide_API_Edusign} alt="Screen aide API Edusign" />
                </P.Content>
              </S.ModalContent>
            </Modal>
          )}
        </>
      )}

      {/*
        Cards • Step 3
      */}
      {view === INTEGRATION_EDUSIGN_VIEWS.AUTHORIZATION && (
        <>
          <P.Card>
            <P.Header>
              <S.LogoContainer>
                <S.EdusignLogoContainer>
                  <S.EdusignLogo />
                </S.EdusignLogoContainer>
                <S.Connexion />
                <S.SlippersLogoContainer>
                  <Logo variant="short" />
                </S.SlippersLogoContainer>
              </S.LogoContainer>
              <P.CardTitle>
                Edusign souhaite se connecter <br /> à votre compte Slippers
              </P.CardTitle>
            </P.Header>
            <P.Content>
              <S.BodyFlag>Edusign aimerait :</S.BodyFlag>
              <S.List>
                <S.Item>Voir les informations de votre profil.</S.Item>
                <S.Item>Accéder aux informations des cours.</S.Item>
                <S.Item>Receuillir les émargements des apprenants.</S.Item>
              </S.List>
            </P.Content>
            <P.Footer>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setView(INTEGRATION_EDUSIGN_VIEWS.FAILED)}
              >
                Refuser l’accès
              </Button>
              <div style={{ padding: '0.5rem' }} />
              <Button type="button" variant="primary" onClick={onAuthorizationSubmit}>
                Autoriser l’accès
              </Button>
            </P.Footer>
          </P.Card>
        </>
      )}

      {/*
        Cards • Step 3.5
      */}
      {view === INTEGRATION_EDUSIGN_VIEWS.AUTHORIZATION_OPTS && (
        <>
          <P.Card>
            <P.Header>
              <S.LogoContainer>
                <S.EdusignLogoContainer>
                  <S.EdusignLogo />
                </S.EdusignLogoContainer>
                <S.Connexion />
                <S.SlippersLogoContainer>
                  <Logo variant="short" />
                </S.SlippersLogoContainer>
              </S.LogoContainer>
              <P.CardTitle>
                Création automatique <br /> de vos feuilles d’émargements
              </P.CardTitle>
            </P.Header>
            <P.Content>
              <S.Input>
                <S.Radio
                  type="radio"
                  id="signature_by_default_enabled"
                  checked={
                    typeof value.signature_by_default === 'boolean' && value.signature_by_default
                  }
                  onChange={onDefaultSignatureChange}
                  value="on"
                />
                <S.Label htmlFor="signature_by_default_enabled">
                  Je souhaite que tous mes apprenants émargent par défaut, je pourrai leur retirer
                  ce droit en modifiant leurs fiches utilisateurs
                </S.Label>
              </S.Input>
              <S.Input>
                <S.Radio
                  type="radio"
                  id="signature_by_default_disabled"
                  checked={
                    typeof value.signature_by_default === 'boolean' && !value.signature_by_default
                  }
                  onChange={onDefaultSignatureChange}
                  value="off"
                />
                <S.Label htmlFor="signature_by_default_disabled">
                  Je ne souhaite pas que mes apprenants émargent par défaut, je pourrai les
                  autoriser à émarger en modifiant leurs fiches utilisateurs
                </S.Label>
              </S.Input>
            </P.Content>
            <P.Footer>
              <Button
                type="button"
                variant="primary"
                disabled={typeof value.signature_by_default === 'undefined'}
                onClick={onAuthorizationOptsSubmit}
              >
                Valider
              </Button>
            </P.Footer>
          </P.Card>
        </>
      )}

      {/*
        Cards • Step 4
      */}
      {view === INTEGRATION_EDUSIGN_VIEWS.SUCCESSED && (
        <>
          <P.Card>
            <S.LogoContainer>
              <S.EdusignLogoContainer>
                <S.EdusignLogo />
              </S.EdusignLogoContainer>
            </S.LogoContainer>
            <P.CardTitle>Votre compte Edusign</P.CardTitle>
            <P.CardDescription>est relié à votre compte Slippers</P.CardDescription>
            <P.Footer>
              <Button
                type="button"
                variant="text"
                icon="TrashAlt"
                onClick={() => onDisplayModal('showConfirmationDeleteModal')}
              >
                Supprimer la connexion
              </Button>
            </P.Footer>
          </P.Card>

          {/* showConfirmationModal */}
          {modal.showConfirmationModal && (
            <Modal
              customParent="root"
              isOpen={modal.showConfirmationModal}
              onRequestClose={() => onCloseModal('showConfirmationModal')}
              withOverlay
            >
              <S.ModalContent isAlignCenter>
                {/* Close Button */}
                <S.Close
                  aria-label="Fermer"
                  id="closeButton"
                  onClick={() => onCloseModal('showConfirmationModal')}
                  type="button"
                >
                  <S.StyledUilClose />
                </S.Close>
                <Icon name="CheckCircle" size="6.25rem" color="#057A55" />
                <S.ModalTitle>
                  Félicitations !{' '}
                  <span role="img" aria-label="Cornet à confettis">
                    🎉
                  </span>
                </S.ModalTitle>
                <P.Content>
                  <P.CardDescription>
                    Votre compte Edusign est maintenant connecté à Slippers&nbsp;! <br />
                    Profitez dès à présent de la création automatique de vos feuilles d’émargement.
                  </P.CardDescription>
                </P.Content>
                <P.Footer>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={() => onCloseModal('showConfirmationModal')}
                  >
                    Terminer
                  </Button>
                </P.Footer>
              </S.ModalContent>
            </Modal>
          )}

          {/* showConfirmationDeleteModal */}
          {modal.showConfirmationDeleteModal && (
            <Dialog
              title="Supprimer la liaison à Edusign"
              desc="Souhaitez-vous délier vos comptes Edusign et Slippers ? Les fonctionnalités
            d’émargement de Slippers ne seront donc plus visibles."
              variant="danger"
              cancelButtonProps={{
                onClick: () => onCloseModal('showConfirmationDeleteModal'),
                label: 'Annuler',
              }}
              successButtonProps={{
                onClick: onResetIntegration,
                label: 'Supprimer',
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

/**
 * Export
 */
export default Edusign;
