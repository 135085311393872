// Alt Shift
export const TOGGLE_DARK_MODE = 'alt_shift_y';
export const TOGGLE_CHAT_DRAWER = 'alt_shift_c';
export const TOGGLE_USERS_DRAWER = 'alt_shift_g';
export const RESET_INTERFACE = 'alt_shift_r';
// export const CHANGE_DEVICE = 'alt_shift_u'; not implemented yet
export const TOGGLE_NOTIFICATIONS = 'alt_shift_v';
export const DISPLAY_CHAT = 'alt_shift_x';
export const DISPLAY_POLL = 'alt_shift_p';

// Ctrl
export const EDIT_ITALIC = 'ctrl_i';
export const EDIT_BOLD = 'ctrl_b';

// Alt
export const ASK_SPEAKING = 'alt_r';
export const TOGGLE_MIC = 'alt_u';
export const TOGGLE_WEBCAM = 'alt_w';
export const DISPLAY_SNIPPETS = 'alt_i';
export const FOCUS_CHAT_INPUT = 'alt_x';
export const CREATE_SURVEY = 'alt_p';
export const OPEN_SHORTCUTS = 'alt_k';
export const OPEN_NOTIFICATIONS = 'alt_v';
export const TAKE_PAUSE = 'alt_z';
export const QUIT_CLASS = 'alt_e';
