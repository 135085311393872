import { getHours, getMinutes, isEqual, startOfDay } from 'date-fns';

/**
 * Adds dates to messages list
 * @param messages
 */
export const addDates = (messages, chatAppearance = 'spaced') => {
  let previousTime = null;
  let previousDate = null;

  const result = [...messages].flatMap((msg) => {
    const currentTime = msg.time;
    const currentDate = startOfDay(msg.time);
    const currentResult = [msg];

    if (
      chatAppearance === 'compact'
      && (getHours(currentTime) !== getHours(previousTime)
        || getMinutes(currentTime) !== getMinutes(previousTime))
    ) {
      currentResult.unshift({
        type: 'message.time',
        time: currentTime,
      });
    }
    previousTime = currentTime;

    if (!isEqual(currentDate, previousDate)) {
      previousDate = currentDate;
      currentResult.unshift({
        type: 'message.date',
        date: currentDate,
        time: null,
      });
    }

    return currentResult;
  });

  return result;
};
