/*
 * Package Import
 */
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { UilTrashAlt, UilCheck } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import Modal from 'src/components/Modal';
import { LIGHT_THEME_NAME } from 'src/constants/theme';

/*
 * Style
 */
export const NotificationModal = styled(Modal)({});

// Supprimer
export const DeleteIcon = styled(UilTrashAlt, {
  shouldForwardProp: (prop) => isPropValid(prop),
})({
  height: '1.5rem',
  width: '1.5rem',
});

export const CheckIcon = styled(UilCheck)(({ theme }) => ({
  color: theme.primary._1,
  height: '4rem',
  width: '4rem',
}));

/*
 * Style
 */

// List All Notifications
export const NotificationsList = styled.div(({ theme }) => ({
  width: '26rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0.5rem',
  borderRadius: '8px',
  background: theme.background._4,
  zIndex: '2',
  '.removed': {
    transition: 'opacity 0.2s ease-out, z-index 0.2s ease-out, margin-top 0.3s ease-out',
    opacity: '0',
    marginTop: '-3.85rem',
    zIndex: '0',
  },

  ...theme.shadows.plus3,
}));

export const List = styled.ul(({ theme, blur }) => ({
  position: 'relative',
  maxHeight: '80vh',
  overflowY: 'scroll',

  scrollbarColor: `${theme.background._7} transparent`,
  scrollbarWidth: 'thin',

  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },

  '> *': {
    filter: blur ? 'blur(.45rem)' : 'none',
    transition: 'filter 0.2s ease-out',
  },
}));

export const Header = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0.125rem 0.25rem 0.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

export const Title = styled.h2(({ theme }) => ({
  cursor: 'default',
  color: theme.text._1,
  ...theme.typo.labels.label,
}));

export const Actions = styled.ul`
  display: flex;
  align-items: center;
`;

export const Action = styled.button(
  ({ theme }) => ({
    alignItems: 'center',
    border: '1px solid transparent',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    height: '2rem',
    width: '2rem',
    ...theme.focusVisible,
  }),
  ({ disabled, theme }) => {
    if (disabled) {
      return {
        color: theme.text._6,
        cursor: 'default',
      };
    }

    return {
      color: theme.text._4,
      cursor: 'pointer',
      ':hover': {
        background: theme.background._4,
        color: theme.text._1,
      },
    };
  },
);

export const Confim = styled.div(({ open }) => ({
  display: open ? 'flex' : 'none',
  flexDirection: 'column',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  filter: 'none !important',
  zIndex: '5',
}));

export const Text = styled.span(({ theme }) => ({
  filter: 'none',
  margin: '1rem auto',
  color: theme.text._1,
  ...theme.typo.labels.bigLabel,
}));

export const Buttons = styled.div`
  display: flex;
`;

export const Button = styled.button(({ theme }) => ({
  ...theme.typo.labels.label,
  color: theme.primary._1,
  margin: '0 1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '.25rem .5rem',
  width: 'fit-content',
  filter: 'none',
  ':hover': {
    color: theme.text._1,
  },
}));

export const Empty = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2.5rem',
  color: theme.text._1,
  ...theme.typo.labels.bigLabel,
}));

export const CheckContainer = styled.div(({ theme }) => ({
  height: '6.5rem',
  width: '6.5rem',
  background: theme.themeName === LIGHT_THEME_NAME ? theme.primary._3 : theme.primary._5,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '.75rem',
}));
