/*
 * Package Import
 */
import React from 'react';

/*
 * Local Import
 */
import * as S from './style';

/*
 * Code
 */
const ToastContainerCustom = (props: Record<string, string>): JSX.Element => (
  <S.StyledToastContainer {...props} />
);

/*
 * Export
 */

export default ToastContainerCustom;
