/*
 * Package Import
 */
import { useCallback, useEffect, useRef, useState } from 'react';

/*
 * Local Import
 */
import { api } from 'src/utils/api';

/*
 * Hooks
 */
export const useFetch = (url, options = {}, dependencies = []) => {
  /**
   * Refs
   */
  const abortControllerRef = useRef();

  /**
   * State
   */
  const isMounted = useRef(
    /** @type {Boolean} */
    false,
  );

  const [loading, setLoading] = useState(
    /** @type {Boolean} */
    false,
  );

  const data = useRef(
    /** @type {Object} */
    null,
  );

  const error = useRef(
    /** @type {Object} */
    null,
  );

  /**
   * Make the request
   */
  const makeRequest = useCallback(() => {
    const doRequest = async () => {
      //
      abortControllerRef.current = new AbortController();
      abortControllerRef.current.signal.onabort = () => {};

      // 1. Initialize request
      setLoading(true);
      error.current = undefined;

      // 2. Request
      try {
        const res = await api({ url, ...options });
        data.current = res.data;
      }
      catch (err) {
        error.current = err;
      }
      finally {
        // Remove the abort controller
        abortControllerRef.current = undefined;
      }

      // 3. Clear request
      setLoading(false);
    };

    return doRequest();
  }, [url]);

  /**
   * LifeCycles
   */
  useEffect(() => {
    isMounted.current = true;

    // Init
    if (isMounted.current) {
      makeRequest();
    }

    return () => {
      isMounted.current = false;
    };
  }, dependencies);

  // Cancel requests when unmounting
  useEffect(() => () => abortControllerRef.current && abortControllerRef.current.abort(), []);

  /**
   * Return
   */
  return {
    loading,
    data: data.current,
    error: error.current,
  };
};
