import { useState, useEffect } from 'react';
import Fuse from 'fuse.js';

const useSearch = ({
  array,
  options: { initialSearchTerm = '', keys, threshold = 0.5, ...otherOptions },
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [arrayFiltered, setArrayFiltered] = useState(array);

  const fuseOptions = {
    keys,
    threshold,
    ...otherOptions,
  };

  // Give a searchTerm, an array and some options and get an results array
  const filterUsersWithSearch = (searchedTerm, arrayToSearch, options) => {
    const fuse = new Fuse(arrayToSearch, options);
    return fuse.search(searchedTerm).map((result) => result.item);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      const newArrayFiltered = [...filterUsersWithSearch(searchTerm, array, fuseOptions)];
      setArrayFiltered(newArrayFiltered);
    }
    else setArrayFiltered(array);
  }, [searchTerm]);

  return [arrayFiltered, searchTerm, setSearchTerm];
};

export default useSearch;
