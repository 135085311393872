/**
 * Package Import
 */
import styled from '@emotion/styled';

/**
 * Local Import
 */

/**
 * Style
 */
export const SearchResultContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  paddingTop: '0.5rem',
  paddingRight: '0',
  color: theme.text._1,
  transition: 'all 1s ease-out',
  overflowY: 'auto',

  scrollbarColor: `${theme.background._7} transparent`,
  scrollbarWidth: 'thin',

  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '4px',
  },
}));

export const SectionHeader = styled('div')((props) => ({
  padding: '0 1rem',
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'spaceEvenly',
  backgroundColor: props.theme.background._2,
}));
