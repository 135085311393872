import { getCoursesChildrenOfPromotion } from 'src/schemas/Repositories/courses';
import type { Promotion } from 'src/schemas/Entities/Promotion';
import { dateToNumberValue, dateStringToNumberValue } from 'src/utils/time';
import type { MongoId } from 'src/schemas/Entities/utils';
import type { Course } from 'src/schemas/Entities/Course';

// Can't use directly schema FormData type b/c cyclic dependency
export interface PromotionFormDataPartialStatic {
  id?: MongoId;
  end: string;
  start: string;
}
export const findCoursesAndCheckPromotionDates = (
  data: PromotionFormDataPartialStatic,
  courses: Course[] | undefined,
): boolean => {
  if (data.id) {
    if (courses) {
      const promotionCourses = getCoursesChildrenOfPromotion({
        courses,
        id: data.id,
      });
      if (!promotionCourses) {
        return true;
      }

      return promotionCourses.every(
        (course) =>
          dateToNumberValue(course.date) <= dateStringToNumberValue(data.end)
          && dateToNumberValue(course.date) >= dateStringToNumberValue(data.start),
      );
    }
  }
  return true;
};

export interface PromotionActivityExportFormDataPartialStatic {
  promotionId: MongoId;
  start: string;
  end: string;
}
export const findPromotionAndCheckIfExportDatesCorrespondToPromotionDates = (
  data: PromotionActivityExportFormDataPartialStatic,
  promotions: Promotion[] | undefined,
): boolean => {
  if (promotions) {
    const currentPromotion = promotions.find((promotion) => promotion.id === data.promotionId);

    if (!currentPromotion) {
      return true;
    }

    return (
      dateStringToNumberValue(data.start) >= dateToNumberValue(currentPromotion.start)
      && dateStringToNumberValue(data.end) <= dateToNumberValue(currentPromotion.end)
    );
  }
  return true;
};
