import { titles, icons } from 'src/constants/notifications';

// Si on garde par defaut info => supprimer tous les switch qui retourne info
export const setIconName = (title) => {
  switch (title) {
    case titles.PRIVATE_MESSAGE:
      return icons.ICON_MESSAGE;
    case titles.REQUEST_TALK:
      return icons.ICON_HAND;
    case titles.REPORT_SOUND:
      return icons.ICON_INFO;
    case titles.REPORT_VIDEO:
      return icons.ICON_INFO;
    case titles.NEW_DOCUMENT:
      return icons.ICON_DOCUMENT;
    case titles.NEW_MENTION:
      return icons.ICON_INFO;
    case titles.NEW_QUESTION:
      return icons.ICON_INFO;
    case titles.NEW_PIN:
      return icons.ICON_INFO;
    case titles.NEW_SURVEY:
      return icons.ICON_SURVEY;
    case titles.EDUSIGN_SIGNATURE_NEEDED:
      return icons.ICON_EDUSIGN;
    default:
      return icons.ICON_INFO;
  }
};
