/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Content from 'src/components/Chats/Channel/Chat/Message/Content';
import { sendMessage } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state, ownProps) => {
  const { kind } = state.chats.byId[ownProps.chatId];

  return {
    context: kind,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendMessage }, dispatch),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Content);
