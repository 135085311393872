import styled from '@emotion/styled';
import { ThemeInterface } from 'src/theme/slippersTheme/themes';

const getLabelStyle = (theme: ThemeInterface): Record<symbol, string> => theme.typo.labels.label;
const TableHead = styled.thead(({ theme }) => ({
  backgroundColor: theme.background._3,
  '& > tr': {
    ...getLabelStyle(theme),
    color: theme.text._4,
  },
}));

export default TableHead;
