/*
 * Package Import
 */
import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query/types';
import { useHistory, useRouteMatch } from 'react-router-dom';

/*
 * Local Import
 */
import Add from 'src/components/Admin/Modals/Add/Course';
import Update from 'src/components/Admin/Modals/Update/Course';
import Deactivate from 'src/components/Admin/Modals/Deactivate/Course';
import Reactivate from 'src/components/Admin/Modals/Reactivate/Course';
import { Course } from 'src/schemas/Entities/Course';
import { useGetCourses } from 'src/serverStateManagers/queries/Courses/useGetCourses';
import { menuOptions } from 'src/components/Admin/TableElements/ActionsMenuOptions';
import { MongoId } from 'src/schemas/Entities/utils';
import { ParamsProps, actions, IdToAction } from 'src/constants/routes';
import * as T from 'src/components/Admin/TableElements';
import { isProperUrl, getRoute } from 'src/utils/routes';
import { ongoing, finished } from 'src/constants/status';
import * as S from './style';
import useTable from './hooks/useCourseTable';
import { getStatus } from './hooks/utils';

/*
 * Code
 */
const CourseTable = (): JSX.Element => {
  const { isError, data }: UseQueryResult<Course[] | Error> = useGetCourses();
  const [idToAction, setIdToAction] = useState({
    update: null,
    deactivate: null,
    reactivate: null,
  } as IdToAction);

  const match = useRouteMatch();
  const params = match.params as ParamsProps;
  const history = useHistory();

  const handleAddModal = (): void => {
    history.push(getRoute('coursesAdd').getUrl({}));
  };
  const handleDeactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('coursesDeactivate').getUrl({ id }));
    }
  };
  const handleReactivateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('coursesReactivate').getUrl({ id }));
    }
  };
  const handleUpdateModal = (id: MongoId | null): void => {
    if (id) {
      history.push(getRoute('coursesUpdate').getUrl({ id }));
    }
  };

  useEffect(() => {
    if (!isProperUrl('courses', data, params)) {
      history.push('/404');
    }

    if (params.action && params.id) {
      const { action, id } = params;
      setIdToAction((prevState) => ({
        ...prevState,
        [action]: id,
      }));
    }
  }, [params, data, history]);

  const { updateOption, deactivateOption, reactivateOption } = menuOptions;

  /**
   * Update handler
   */
  const updateHandle = (id: MongoId | null): void => {
    handleUpdateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      update: id,
    }));
  };

  const displayUpdateHandler = (rowId: MongoId | null): boolean => {
    const currentCourse = data?.find((course) => course.id === rowId);
    if (!currentCourse) {
      return false;
    }

    // Dont display the `update` button, if course is ongoing or ended
    const status = getStatus(currentCourse);
    return ![ongoing, finished].includes(status);
  };
  const isUpdateDisplayed = (rowId: MongoId | null) => displayUpdateHandler(rowId);
  const configuredUpdateOption = updateOption(updateHandle, isUpdateDisplayed);

  /**
   * Deactivate handler
   */
  const deactivateHandle = (id: MongoId | null): void => {
    handleDeactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      deactivate: id,
    }));
  };
  const isDeactive = (rowId: MongoId | null): boolean => {
    const currentCourse = data?.find((course) => course.id === rowId);
    if (!currentCourse) {
      return false;
    }
    return !!currentCourse.deactivatedAt;
  };
  const isDeactivateDisplayed = (rowId: MongoId | null) => !isDeactive(rowId);
  const configuredDeactivateOption = deactivateOption(deactivateHandle, isDeactivateDisplayed);

  /**
   * Reactivate handler
   */
  const reactivateHandle = (id: MongoId | null): void => {
    handleReactivateModal(id);
    setIdToAction((prevState) => ({
      ...prevState,
      reactivate: id,
    }));
  };
  const configuredReactivateOption = reactivateOption(reactivateHandle, isDeactive);

  /**
   * Hooks
   */
  const [tableInstance, Table] = useTable(data);

  /**
   * Render
   */
  return (
    <S.Container>
      {!isError && (
        <>
          <Table
            title="Cours"
            tableInstance={tableInstance}
            openAddModal={handleAddModal}
            menuOptions={[
              configuredUpdateOption,
              configuredDeactivateOption,
              configuredReactivateOption,
            ]}
            customInterface={{ topLeft: <T.ArchiveFilter tableInterface={tableInstance} /> }}
          />
          <Add isOpen={!!params.action && params.action === actions.add} />
          <Update
            isOpen={!!params.action && !!params.id && params.action === actions.update}
            idToUpdate={idToAction.update}
          />
          <Deactivate
            isOpen={!!params.action && !!params.id && params.action === actions.deactivate}
            idToDeactivate={idToAction.deactivate}
          />
          <Reactivate
            isOpen={!!params.action && !!params.id && params.action === actions.reactivate}
            idToReactivate={idToAction.reactivate}
          />
        </>
      )}
    </S.Container>
  );
};

/*
 * Export
 */

export default CourseTable;
