/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import AvatarTooltip from 'src/components/Tooltip/AvatarTooltip';
import ClassEmotions from 'src/components/Users/UsersPanel/Emotions/ClassEmotions';
import Badge from 'src/components/Users/UsersPanel/Class/Section/Badge';
import Avatar from 'src/components/Users/UsersPanel/List/UserCard/Avatar';
import AudioLevel from 'src/components/Course/AudioLevel/container';

// Constants
import CHATS from 'src/constants/chats';
import ROLES from 'src/constants/roles';
import { triggerLocations } from 'src/constants/tracking';

// Style
import * as S from './style';

/*
 * Component
 */
const ProfileCard = ({ name, mugshot, connected, away, role, emotion, id, actions }) => {
  /*
   * Handlers
   */
  const handleOpenChat = () => {
    actions.changeMainChatView(CHATS.TAB_CHAT);
    actions.openChat(id, triggerLocations.USER_LIST);
  };

  /*
   * Render
   */
  return (
    <AvatarTooltip name={name} connected={connected} away={away}>
      <S.Container id={`user-${id}`}>
        <S.ProfileCard onClick={handleOpenChat}>
          <Avatar mugshot={mugshot} name={name} connected={connected} away={away} showConnected />
        </S.ProfileCard>
        {role === ROLES.ROLE_STUDENT && <ClassEmotions emotion={emotion} connected={connected} />}
        {role !== ROLES.ROLE_STUDENT && <Badge role={role} />}
        <AudioLevel userId={id} />
      </S.Container>
    </AvatarTooltip>
  );
};

/*
 * PropTypes
 */
ProfileCard.propTypes = {
  mugshot: PropTypes.string,
  connected: PropTypes.bool.isRequired,
  away: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  emotion: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

ProfileCard.defaultProps = {
  mugshot: null,
};

/*
 * Export
 */
export default ProfileCard;
