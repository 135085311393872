import styled from '@emotion/styled';

export const Form = styled('form')(({ theme }) => ({
  color: theme.text._3,
  background: theme.background._2,
}));

export const Container = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  maxHeight: '100vh',
  overflow: 'auto',
  borderRadius: '20px',
  scrollbarColor: `${theme.text._7} transparent`,
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar': {
    width: '0.25rem',
  },
  '::-webkit-scrollbar-track': {
    background: 'none',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.background._7,
    borderRadius: '5px',
  },
}));
