/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { deactivateCourse } from './deactivateCourse';

/*
 * Code
 */
export const useDeactivateCourse = (): UseMutationResult<void, unknown, string, unknown> => {
  const CourseMutation = useMutation((courseId: string) => deactivateCourse(courseId), {
    onSettled: () => {
      queryClient.invalidateQueries('courses');
    },
    onSuccess: () => {
      toast('Cours archivé avec succès !', { type: 'success' });
    },
  });

  return CourseMutation;
};
