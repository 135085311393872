/* eslint-disable react/jsx-key */
/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import * as T from 'src/components/Admin/TableElements';
import { flexRender } from '@tanstack/react-table';
import * as S from './style';

/*
 * Code
 */
const CsvImportTable = ({ tableInstance, rowIsDisabled }) => {
  const {
    getHeaderGroups,
    getVisibleFlatColumns,
    setGlobalFilter,
    getCoreRowModel,
    getState,
    getRowModel,
  } = tableInstance;

  const { globalFilter } = getState();
  const visibleColumns = getVisibleFlatColumns();
  const headerGroups = getHeaderGroups();
  const { rows } = getRowModel();

  return (
    <>
      {getCoreRowModel().rows && (
        <T.ModalSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      )}
      <S.TableContainer>
        <S.TableScrollManager>
          <S.TableRoot>
            <colgroup>
              <col style={{ width: '8%' }} />
              <col style={{ width: '8%' }} />
              <col style={{ width: '8%' }} />
              {visibleColumns.map((column) => (
                <col key={column.id} style={{ width: `${76 / (visibleColumns.length - 3)}%` }} />
              ))}
            </colgroup>
            <T.TableHead>
              {headerGroups.map((headerGroup) => (
                <T.TableRowWithoutHover key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <T.TableCellHeader key={header.id}>
                      <T.HeaderContainer key={header.id}>
                        <T.Header>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </T.Header>
                      </T.HeaderContainer>
                    </T.TableCellHeader>
                  ))}
                </T.TableRowWithoutHover>
              ))}
            </T.TableHead>
            <S.TableBody>
              {!rows.length && (
                <S.TableRow>
                  <S.TableCell colSpan={visibleColumns.length} style={{ textAlign: 'center' }}>
                    <div>Aucun résultat trouvé</div>
                  </S.TableCell>
                </S.TableRow>
              )}
              {rows.map((row) => (
                <S.TableRow
                  key={row.id}
                  disabled={rowIsDisabled ? rowIsDisabled(row.original) : false}
                >
                  {row.getVisibleCells().map((cell) => (
                    <S.TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </S.TableCell>
                  ))}
                </S.TableRow>
              ))}
            </S.TableBody>
          </S.TableRoot>
        </S.TableScrollManager>
      </S.TableContainer>
    </>
  );
};

CsvImportTable.propTypes = {
  tableInstance: PropTypes.object.isRequired,
  rowIsDisabled: PropTypes.func.isRequired,
};

/*
 * Export
 */
export default CsvImportTable;
