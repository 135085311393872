/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { reactivateUser } from './reactivateUser';

/*
 * Code
 */
export const useReactivateUser = (): UseMutationResult<void, unknown, string, unknown> => {
  const UserMutation = useMutation((userId: string) => reactivateUser(userId), {
    onSettled: () => {
      queryClient.invalidateQueries('users');
    },
    onSuccess: () => {
      toast('Utilisateur désarchivé avec succès !', { type: 'success' });
    },
  });

  return UserMutation;
};
