/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilExternalLinkAlt } from '@iconscout/react-unicons';

/*
 * Code
 */
export const IconExternalWindow = styled(UilExternalLinkAlt)(({ theme }) => ({
  color: theme.text._6,
  height: '1rem',
  width: '1rem',
}));

export const ButtonExt = styled.button(({ theme }) => ({
  width: '2rem',
  height: '2rem',
  background: theme.colors.coolGrey._200,
  opacity: '0',
  borderRadius: '4px',
  position: 'absolute',
  bottom: '0',
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
    opacity: '1',
  },
  ':active': {
    background: theme.background._7,
    border: 'none',
    opacity: '1',
  },
  ':focus-visible': {
    border: `2px solid ${theme.primary._1}`,
    opacity: '1',
  },
  ':-moz-focusring': {
    background: 'none',
    border: `2px solid ${theme.primary._1}`,
    opacity: '1',
  },
}));

export const Button = styled.button(({ theme }) => ({
  borderRadius: '25px',
  background: theme.colors.primary._600,
  padding: '.5rem 1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.monochrome.white,
  ':hover': {
    opacity: '1',
  },
  ':active': {
    background: theme.background._7,
    border: 'none',
    opacity: '1',
  },
  ':focus-visible': {
    background: 'none',
    border: `2px solid ${theme.primary._1}`,
    opacity: '1',
  },
  ':-moz-focusring': {
    background: 'none',
    border: `2px solid ${theme.primary._1}`,
    opacity: '1',
  },
}));

export const Screen = styled.div(({ theme, isVideoActive, popoutIsOpen }) => ({
  background: isVideoActive ? theme.colors.monochrome.black : theme.colors.coolGrey._400,
  borderRadius: '0.5rem',
  position: 'relative',
  margin: 'auto',
  overflow: 'hidden',
  aspectRatio: '16/9',
  height: 'auto',
  width: '100%',
  containerType: 'size',
  containerName: 'screen-container',

  ':hover': {
    [ButtonExt]: {
      display: popoutIsOpen || !isVideoActive ? 'none' : 'block',
      opacity: 1,
    },
  },
}));

export const Overlay = styled.div(({ popoutIsOpen }) => ({
  position: 'relative',
  zIndex: 0,
  height: '100%',
  background: popoutIsOpen ? 'rgba(0,0,0, 1)' : 'transparent',
  display: popoutIsOpen ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const InfoText = styled.span(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  color: theme.colors.monochrome.white,
  marginBottom: '2rem',
}));

export const Video = styled.video`
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 180px;
  aspect-ratio: 16/9;
`;

export const TranscriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TranscriptionBackground = styled.div`
  position: absolute;
  bottom: 0;
  max-width: 696px;
  width: 90%;
  height: 102px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  margin: auto;
  background: rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 12px;
  padding-top: 8px;
  margin-bottom: 8px;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
`;

export const Transcription = styled.div`
  position: absolute;
  bottom: 0;
`;

export const User = styled.div`
  display: contents;
  line-height: 150%;
  font-size: 22px;
  font-weight: bold;
`;

export const Text = styled.div`
  overflow: hidden;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.4px;
  text-align: left;
  line-height: 150%;
  display: contents;
`;

export const IframeContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: black;
`;
