/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { User } from 'src/schemas/Entities/User';
import { api, Payload } from 'src/utils/api';
import { transformUsersDTOToUsers } from 'src/schemas/Adapters/Users/transformUserDTOToUser';
import { API_FIND_USERS, API_GET } from 'src/constants/apiRoutes';
import { getRoute } from 'src/utils/routes';

/*
 * Code
 */
export const fetchUsers = async (): Promise<User[]> => {
  try {
    const payload: Payload = {
      method: API_GET,
      url: API_FIND_USERS,
    };
    const { data: usersData } = await api(payload);
    const { users, errors } = transformUsersDTOToUsers(usersData);

    if (errors.length) {
      const errorsMessages = errors.map(
        (errorWithData) =>
          `User with name ${errorWithData.data.firstname} ${
            errorWithData.data.lastname
          } validation problem : ${errorWithData.error.message}.
          Click here to correct : ${window.location.origin}${getRoute('usersUpdate').getUrl({
  id: errorWithData.data.id,
})}`,
      );
      // eslint-disable-next-line no-console
      console.error(`${errorsMessages.join('\n')}\n Please contact support`);
      toast(
        'Nous avons détecté des erreurs de validation sur les données reçues, le détail se trouve dans la console',
        { type: 'warning', toastId: 'query' },
      );
    }

    return users;
  }
  catch (error) {
    toast(`Erreur pendant le chargement des données : ${error}`, {
      type: 'error',
      toastId: 'fetch',
    });
    throw error;
  }
};
