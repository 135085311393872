/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import { getAvatar } from 'src/utils/avatar';
import * as S from './style';

/*
 * Component
 */
const Avatar = React.forwardRef(
  ({ avatar, away, connected, mugshot, name, size, showConnected, statusSize, ...props }, ref) => (
    <S.Avatar
      ref={ref}
      away={away}
      connected={connected}
      size={size}
      statusSize={statusSize}
      showConnected={showConnected}
    >
      <S.Img src={mugshot || getAvatar({ name, avatar })} alt="" {...props} />
    </S.Avatar>
  ),
);

/*
 * PropTypes
 */
Avatar.propTypes = {
  avatar: PropTypes.string,
  away: PropTypes.bool,
  connected: PropTypes.bool,
  mugshot: PropTypes.string,
  name: PropTypes.string.isRequired,
  showConnected: PropTypes.bool,
  size: PropTypes.number,
  statusSize: PropTypes.number,
};

Avatar.defaultProps = {
  avatar: null,
  away: false,
  connected: false,
  mugshot: null,
  showConnected: false,
  size: null,
  statusSize: null,
};

/*
 * Export
 */
export default Avatar;
