/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import NotificationReader from 'src/components/NotificationsReader';
import { setModalNotificationReader, deleteNotifications } from 'src/store/actions';
import { titles } from 'src/constants/notifications';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { readerIsOpen, currentNotification } = state.notifications;
  const { feedbacks } = state.feedback;

  let data;

  switch (currentNotification.title) {
    case titles.REPORT_SOUND:
    case titles.REPORT_VIDEO: {
      const { id } = currentNotification;
      data = feedbacks.find((feed) => feed.id === id) || null;
      break;
    }
    default:
      data = currentNotification;
  }

  return {
    readerIsOpen,
    data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setModalNotificationReader,
      deleteNotifications,
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(NotificationReader);
