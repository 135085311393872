import { useCallback, useState } from 'react';
import throttle from 'lodash.throttle';

/**
 * Hook qui vérifie au `onScroll` que le scroll soit en haut ou non
 */
export const useScroll = () => {
  const [isTop, setIsTop] = useState(true);

  const handleScroll = useCallback(
    throttle((event) => {
      setIsTop(event.target.scrollTop === 0);
    }, 200),
    [],
  );

  return { handleScroll, isTop };
};
