/*
 * Package Import
 */
import { keyframes } from '@emotion/react';
/*
 * Local Import
 */
import { weights } from './weights';
import { animations } from './animations';
import { colors, colorsRGB } from './colors';
import { ThemeInterface, defaultTheme } from './slippersTheme/themes';

/**
 * Types
 */
interface ButtonProp {
  isMain: boolean;
  isCancel: boolean;
  isBlue: boolean;
  isShort: boolean;
  isSelect: boolean;
  isActive: boolean;
  isIcon: boolean;
  isGreen: boolean;
  isRed: boolean;
  theme: ThemeInterface;
}

/*
 * Keyframes
 */
const spin = keyframes(animations.spin);
/*
 * Components
 */
const cta = (): Record<string, unknown> => ({
  borderRadius: '.5em',
  display: 'inline-block',
  fontSize: '1.75rem',
  fontWeight: weights.bold,
  letterSpacing: '.12em',
  padding: '1em 1.5em',
  textTransform: 'uppercase',
  background: colors.greenWater,
  borderBottom: `.3em solid ${colors.greenWaterDark}`,
  color: colors.cream,
  textShadow: `0 0 0.25em rgba(${colorsRGB.black},.1)`,
  minWidth: '10em',
  cursor: 'pointer',
  // Hover ~ Focus
  '&:hover, &:focus': {
    background: `rgba(${colorsRGB.greenWater}, .9)`,
  },
  // Active
  ':active': {
    background: colors.greenWater,
    borderWidth: '.15em',
    marginBottom: '.15em',
    transform: 'translateY(.15em)',
  },
});

const button = (prop: ButtonProp): Record<string, unknown> => {
  // Props
  let style = {};
  const {
    isMain,
    isCancel,
    isBlue,
    isShort,
    isSelect,
    isActive,
    isIcon,
    isGreen,
    isRed,
    theme = defaultTheme,
  } = prop;

  // isMain
  if (isMain) {
    style = {
      color: theme.colors.monochrome.white,
      background: theme.button.main.background._1,
      borderRadius: '8px',
      border: 'none',
      minWidth: '6.187rem',
      minHeight: '2.5rem',

      // Hover
      '&:hover': {
        background: theme.button.main.background._2,
        border: 'none',
      },
      // Focus
      '&:focus': {
        background: theme.button.main.background._2,
        border: `2px solid ${theme.primary._2}`,
      },
      // Active
      '&:active': {
        background: theme.button.main.background._3,
        border: 'none',
      },
      // Active
      '&:disabled': {
        background: theme.button.main.background._4,
        border: 'none',
        color: theme.button.main.text._1,
        cursor: 'default',
      },
    };
  }

  // isCancel
  if (isCancel) {
    style = {
      ...style,
      color: theme.text._4,
      background: theme.button.cancel.background._2,
      boxShadow: 'none',
      minWidth: '6.187rem',
      minHeight: '2.5rem',

      // Hover
      '&:hover': {
        color: theme.text._3,
        background: theme.button.cancel.background._1,
        border: 'none',
      },
      // Focus
      '&:focus': {
        color: theme.text._4,
        background: theme.button.cancel.background._2,
        border: `2px solid ${theme.primary._1}`,
      },
      // Active
      '&:active': {
        color: theme.text._1,
        background: theme.button.cancel.background._1,
        border: 'none',
      },
      // Active
      '&:disabled': {
        color: theme.text._7,
        background: theme.button.cancel.background._2,
        border: 'none',
        cursor: 'default',
      },
    };
  }
  // isBlue (light blue)
  if (isBlue) {
    style = {
      ...style,
      color: theme.colors.monochrome.white,
      background: theme.button.secondary.background._3,
      minWidth: '6.187rem',
      minHeight: '2.5rem',

      '&:hover': {
        background: theme.button.secondary.background._3,
        boxShadow: 'none',
      },
      '&:focus': {
        background: theme.button.secondary.background._3,
        border: `2px solid ${theme.primary._1}`,
      },
      '&:active': {
        background: theme.button.secondary.background._2,
        border: 'none',
      },
    };
  }

  // isShort
  if (isShort) {
    style = {
      ...style,
      minWidth: '5rem',
      minHeight: '2.312rem',
    };
  }

  if (isSelect) {
    style = {
      boxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      minHeight: '2.5rem',
      color: isActive ? theme.text._3 : theme.text._5,
      border: isActive ? `1px solid ${theme.primary._1}` : 'none',
      backgroundColor: isActive ? theme.primary._3 : theme.button.select.background._1,

      // Hover
      ':hover': {
        backgroundColor: isActive ? theme.primary._4 : theme.button.select.background._2,
      },
      ':focus': {
        backgroundColor: isActive ? theme.primary._5 : theme.button.select.background._1,
        border: `2px solid ${theme.primary._1}`,
      },
      ':focus-visible': {
        backgroundColor: isActive ? theme.primary._5 : theme.button.select.background._1,
        border: `2px solid ${theme.primary._1}`,
      },
      ':-moz-focusring': {
        backgroundColor: isActive ? theme.primary._5 : theme.button.select.background._1,
        border: `2px solid ${theme.primary._1}`,
      },
    };
  }

  // isIcon
  if (isIcon) {
    style = {
      ...style,
      width: '2rem',
      height: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }

  if (isGreen || isRed) {
    const colorContext = isGreen ? theme.success : theme.danger;
    style = {
      ...style,
      color: colorContext.main,
      background: colorContext.background,
      '&:hover': {
        background: colorContext.hover,
        boxShadow: 'none',
        border: 'none',
      },
      '&:focus': {
        background: colorContext.background,
        border: `2px solid ${theme.primary._1}`,
        boxShadow: 'none',
      },
      '&:active': {
        background: colorContext.hover,
        border: 'none',
        ...theme.shadows.minus1,
      },
      '&:disabled': {
        background: theme.button.main.background._4,
        border: 'none',
        color: theme.button.main.text._1,
        cursor: 'default',
      },
    };
  }

  return {
    borderRadius: '8px',
    color: theme.text._1,
    background: theme.button.simple.background._2,
    cursor: 'pointer',
    display: 'inline-block',
    padding: '.5rem 1rem',
    ...theme.typo.labels.label,

    // Hover
    '&:hover': {
      background: theme.button.simple.background._3,
      ...theme.shadows.plus2,
    },
    // Focus
    '&:focus': {
      background: theme.button.simple.background._3,
      border: `2px solid ${theme.primary._2}`,
      ...theme.shadows.plus2,
    },
    // Active
    '&:active': {
      background: theme.button.simple.background._3,
      border: 'none',
      ...theme.shadows.minus1,
    },
    // Style override
    ...style,
  };
};
const loader = {
  content: '""',
  position: 'absolute',
  top: '50%',
  left: '50%',
  margin: '-.75em 0 0 -.75em',
  width: '1.5em',
  height: '1.5em',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: `${colors.blue} transparent ${colors.blue}`,
  borderRadius: '100%',
  animation: `2s ${spin} linear infinite`,
};
/*
 * Export
 */
export const base = {
  cta,
  button,
  loader,
};
