/*
 * Package Import
 */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { UilSearch } from '@iconscout/react-unicons';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';
import {
  FilterModalButton,
  FilterModal,
  Close,
} from 'src/components/Admin/TableElements/Filters/Modal';

/*
 * Styles
 */
const FilterContainer = styled.div(({ theme, isValue }) => ({
  display: 'flex',
  maxWidth: '15rem',
  position: 'relative',
  flexDirection: 'row',
  padding: '0.3rem',
  borderRadius: '8px',
  border: `1px solid ${theme.text._7}`,
  boxShadow: isValue ? `0 0 0 2px ${theme.primary._1}` : '',
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${theme.primary._1}`,
  },
}));

const StyledSearchIcon = styled(UilSearch)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '1rem',
  color: theme.text._4,
}));

const ButtonSearchIcon = styled(UilSearch)(() => ({
  width: '1.25rem',
}));

const Input = styled.input(({ theme }) => ({
  ...theme.typo.bodyText.body,
  background: 'transparent',
  lineHeight: '1.5rem',
  marginLeft: '1.2rem',
  width: '100%',
  textOverflow: 'ellipsis',
  color: theme.text._1,
}));

/*
 * Components
 */
const FilterTextInput = ({ filterValue, setFilter }) => {
  const [value, setValue] = useState(filterValue);
  const onChange = (inputValue) => {
    setFilter(inputValue || undefined); // Set undefined to remove the filter entirely
  };

  useEffect(() => {
    document.getElementById('search-filter').focus();
  }, []);

  return (
    <FilterContainer isValue={!!value}>
      <StyledSearchIcon />
      <Input
        value={value || ''}
        onChange={(event) => {
          onChange(event.target.value);
          setValue(event.target.value);
        }}
        placeholder="Recherche"
        id="search-filter"
      />
    </FilterContainer>
  );
};

export const FilterText = ({ filterValue, setFilter, header }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Tooltip content={`Recherche dans la colonne ${header}`}>
      <>
        <FilterModalButton
          onClick={isOpen ? handleClose : handleOpen}
          isOpen={isOpen}
          isFilterValue={filterValue}
        >
          <ButtonSearchIcon />
        </FilterModalButton>
        {isOpen && (
          <FilterModal isOpen={isOpen}>
            <FilterTextInput filterValue={filterValue} setFilter={setFilter} />
          </FilterModal>
        )}
        {isOpen && <Close onClick={handleClose} />}
      </>
    </Tooltip>
  );
};

FilterTextInput.propTypes = {
  filterValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
};
FilterTextInput.defaultProps = {
  filterValue: '',
};

FilterText.propTypes = {
  filterValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};
FilterText.defaultProps = {
  filterValue: '',
};
