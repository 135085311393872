/**
 * Package Import
 */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Local Import
 */
import ProfileCard from 'src/components/Users/UsersPanel/Class/Section/ProfileCard/container';
import UserCard from 'src/components/Users/UsersPanel/List/UserCard/container';
import { getUserListStyle } from 'src/store/selectors/settings';
import ParticipantsCount from '../ParticipantsCount';

// Style
import * as S from './style';
import * as P from '../../style';

/**
 * Component
 */
const ListDisplay = ({ handleScroll, users }) => {
  const viewChosen = useSelector(getUserListStyle);

  const usersPresent = useMemo(() => users.filter((user) => user.connected && !user.away), [users]);
  const usersAway = useMemo(() => users.filter((user) => user.connected && user.away), [users]);
  const usersOffline = useMemo(() => users.filter((user) => !user.connected), [users]);

  const sortedUsers = [...usersPresent, ...usersAway, ...usersOffline];

  return (
    <S.ListDisplayContainer onScroll={handleScroll} viewChosen={viewChosen}>
      <ParticipantsCount users={users} />
      <P.StudentList>
        {sortedUsers.map((user) =>
          (viewChosen === 'list' ? (
            <P.StudentItem key={user.id} fullWidth listItem>
              <UserCard user={user} />
            </P.StudentItem>
          ) : (
            <P.StudentItem key={user.id}>
              <ProfileCard {...user} />
            </P.StudentItem>
          )),
        )}
      </P.StudentList>
    </S.ListDisplayContainer>
  );
};

/**
 * PropTypes
 */
ListDisplay.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

/**
 * Export
 */
export default memo(ListDisplay);
