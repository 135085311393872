/*
 * Package Import
 */
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import type { AnyAction } from 'redux';

/*
 * Local import
 */
import CHATS, { ChannelPanelEnum, ChatTabsEnum } from 'src/constants/chats';
import * as types from 'src/store/types';
import { titles, descriptions } from 'src/constants/notifications';
import { CONFERENCE_STATUS } from 'src/constants/conference';
import { isTeacher, isStudent } from 'src/utils/roles';
import { getStorage } from 'src/utils/storage';
import { CourseRoleEnum, COURSE_ROLE } from 'src/constants/roles';
import { play, isMentionUser } from './utils';

/**
 * Types
 */
export interface NotificationProps {
  id: string;
  title: string;
  description: string;
  userIdRequest?: string;
  read?: boolean;
}

export interface NotificationsState {
  clientId: string;
  clientRole: CourseRoleEnum;
  active?: string;
  isOpen: boolean;
  allNotifications: NotificationProps[];
  readerIsOpen: boolean;
  currentNotification: NotificationProps;
  snackIsOpen: boolean;
  snackMessageTitle: string;
  snackMessageInfo: string;
  severity?: 'danger' | 'warning' | 'info' | 'success';
  currentMainChatView: ChatTabsEnum;
  currentView: ChannelPanelEnum;
  sound: 'default';
  courseEndSoon: boolean;
}

/**
 * Code
 */
const settings = getStorage('middleware_settings_notifications');

/*
 * Initial state
 */
const initialState: NotificationsState = {
  clientId: '',
  clientRole: COURSE_ROLE.ROLE_STUDENT,
  isOpen: false,
  allNotifications: [],
  readerIsOpen: false,
  currentNotification: {
    id: '1',
    title: '',
    description: '',
  },
  snackIsOpen: false,
  snackMessageTitle: '',
  snackMessageInfo: '',
  severity: undefined,
  currentMainChatView: CHATS.TAB_CHAT,
  currentView: CHATS.CHAT_GENERAL,
  sound: 'default',
  courseEndSoon: false,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      /*
       * Data User
       */
      case types.LOG_USER_IN: {
        draft.clientId = action.user.id;
        break;
      }

      case types.INITIALIZE_DATA: {
        draft.clientRole = action.data.users.byId[state.clientId].role;
        break;
      }

      /**
       * Notifications
       */
      case types.ADD_NOTIFICATION:
        draft.allNotifications.push({
          id: action.id || uuid(),
          title: action.title,
          description: action.description,
        });

        if (!settings || settings?.soundEnabled) {
          play();
        }
        break;

        /*
       * Data Chat
       */

      /**
       * Create a new channel
       * Update active & current view
       * @param {String} chatId
       * @param {String} view
       */
      case types.CHAT_CREATED: {
        const { chatId, startedBy, userId, view } = action;
        if (startedBy === userId) {
          draft.active = chatId;
          draft.currentView = view;
        }
        break;
      }

      /**
       * Close a channel
       * Update active & current view
       */
      case types.CLOSE_CHAT: {
        // Reset the active chat
        draft.active = '';
        // Set the current view to the default view
        draft.currentView = CHATS.CHAT_HOME_PRIVATE;
        break;
      }

      /**
       * Update current view.
       * @param {String} view {CHAT_GENERAL, CHAT_HOME_PRIVATE, CHAT_PRIVATE}
       */
      case types.CHANGE_ACTIVE_VIEW: {
        draft.currentView = action.view;
        break;
      }

      /*
       * Data Chat + Notif as view
       */

      /**
       * Open a channel
       * Update active & current view
       * @param {String} chatId -
       * @param {String} view
       */
      case types.OPEN_CHAT: {
        const { chatId, view } = action;
        // Update current channel
        draft.active = chatId;
        // Update current view.
        draft.currentView = view;
        break;
      }

      /**
       * Change Chat (Private)
       * Set notif as read
       * @param {String} chatId
       */
      case types.CHANGE_CHAT: {
        const { chatId, view } = action;
        draft.active = chatId;
        draft.currentView = view;
        break;
      }

      /**
       * Change main chat view (Chat / Poll / Document)
       * Set notif as read
       * @param {String} view -
       */
      case types.CHANGE_MAIN_CHAT_VIEW: {
        draft.currentMainChatView = action.view;
        break;
      }

      /*
       * Notifications
       */

      // SOUND NOTIFICATIONS
      case types.CHANGE_NOTIFICATIONS_SOUND: {
        draft.sound = action.sound;
        break;
      }

      // MENU NOTIFICATIONS
      case types.SET_MENU_NOTIFICATIONS: {
        draft.isOpen = action.isOpen;
        if (action.isOpen) {
          draft.readerIsOpen = !action.isOpen;
        }
        break;
      }

      case types.DELETE_NOTIFICATIONS: {
        if (!action.id) {
          draft.allNotifications = [];
          draft.isOpen = false;
        }
        else {
          draft.allNotifications = state.allNotifications.filter((notif) => notif.id !== action.id);
        }
        break;
      }

      /*
       * Notification Feedback
       */
      // Notification Snack
      case types.FEEDBACK_SENT: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Signaler un problème';
        draft.snackMessageInfo = 'Message envoyé avec succès';
        draft.severity = 'success';
        break;
      }

      case types.FEEDBACK_SEND_ERROR: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Signaler un problème';
        draft.snackMessageInfo = 'Message non envoyé';
        draft.severity = 'danger';
        break;
      }

      // Notification in menu
      case types.RECEIVED_FEEDBACK: {
        if (settings?.enabled || !settings) {
          const { id, title, description } = action.data;
          const newNotification = {
            id,
            title,
            description,
          };

          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      // PRIVATE MESSAGE
      case types.RECEIVED_MESSAGE: {
        const { id, emiter, chatId, receiverId, content } = action;

        // Check if is mention me
        if (isMentionUser(content, state.clientId)) {
          const newNotification = {
            id,
            title: titles.NEW_MENTION,
            description: `${emiter} ${descriptions.NEW_MENTION}`,
            chatId,
            read: false,
          };
          // If same notification exist, remove and actualise
          draft.allNotifications.forEach((notif) => {
            if (notif.description === newNotification.description) {
              draft.allNotifications = draft.allNotifications.filter(
                (notification) => notification.id !== notif.id,
              );
            }
          });
          draft.allNotifications.push(newNotification);
          if (!settings || settings.soundEnabled) {
            play();
          }
          break;
        }

        if (!receiverId || receiverId !== state.clientId) {
          break;
        }

        // Check if conversation is the active view
        if (
          state.currentMainChatView === CHATS.TAB_CHAT
          && state.currentView === CHATS.CHAT_PRIVATE
          && state.active === chatId
        ) {
          break;
        }

        if (!settings || (settings?.enabled && settings?.privateEnabled)) {
          const newNotification = {
            id,
            title: titles.PRIVATE_MESSAGE,
            description: `${emiter} ${descriptions.PRIVATE_MESSAGE}`,
            chatId,
          };
          // If same notification exist, remove and actualise
          draft.allNotifications.forEach((notif) => {
            if (notif.description === newNotification.description) {
              draft.allNotifications = draft.allNotifications.filter(
                (notification) => notification.id !== notif.id,
              );
            }
          });
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      /*
       * Notification Poll
       */
      // Notification Snack
      // NOTE: Voir quels seront les messages des snack notifs
      case types.ERROR_SEND_SURVEY: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Sondage non envoyé';
        draft.snackMessageInfo = action.error.name;
        draft.severity = 'danger';
        break;
      }

      // Notification Snack & in menu
      case types.RECEIVED_SURVEY: {
        if (action.snack) {
          draft.snackIsOpen = true;
          draft.snackMessageTitle = 'Nouveau sondage';
          draft.snackMessageInfo = 'Sondage envoyé avec succès';
          draft.severity = 'success';
          break;
        }
        if (!action.notification || isTeacher(state.clientRole)) {
          break;
        }
        if (!settings || (settings?.enabled && settings?.surveyEnabled !== false)) {
          const { id } = action;
          if (state.currentMainChatView === CHATS.TAB_SURVEY) {
            break;
          }
          const newNotification = {
            id,
            title: titles.NEW_SURVEY,
            description: descriptions.NEW_SURVEY,
          };

          // If same notification exist, remove and actualise
          state.allNotifications.forEach((notif) => {
            if (notif.title === newNotification.title) {
              draft.allNotifications = draft.allNotifications.filter(
                (notification) => notification.id !== notif.id,
              );
            }
          });
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      /*
       * Notification Pin
       */
      case types.ADD_PIN: {
        // Check if is own action
        if (!action.notification) {
          break;
        }
        // Check notifications settings
        if (!settings || (settings?.enabled && settings?.pinEnabled !== false)) {
          const { id, userIdMessage } = action;
          const newNotification = {
            id,
            title: titles.NEW_PIN,
            description: descriptions.NEW_PIN,
            userIdMessage,
          };
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      /*
       * Notification Speak
       */
      // ASK SPEAK
      case types.RECEIVE_HAND_RAISED: {
        if (isTeacher(state.clientRole)) {
          const { id: userId, lastname, firstname } = action.user;
          const newNotification = {
            id: action.notifId,
            userIdRequest: userId,
            title: titles.REQUEST_TALK,
            description: `${firstname} ${lastname} ${descriptions.REQUEST_TALK}`,
          };
          // If same notification exist, remove and actualise
          state.allNotifications.forEach((notif) => {
            if (notif.title === titles.REQUEST_TALK) {
              draft.allNotifications = state.allNotifications.filter(
                (notification) => notification.userIdRequest !== userId,
              );
            }
          });
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      // Unraised hand
      case types.RECEIVE_HAND_UNRAISED: {
        draft.allNotifications = state.allNotifications.filter(
          (notif) => notif.title === titles.REQUEST_TALK && notif.userIdRequest !== action.userId,
        );
        break;
      }

      /*
       * Notification Reader
       * modale display message feedback & ask speak
       */
      case types.SET_CURRENT_NOTIFICATION: {
        const notification = state.allNotifications.find((notif) => notif.id === action.id);

        if (notification) {
          draft.currentNotification = notification;
        }

        break;
      }

      case types.SET_MODAL_NOTIFICATION_READER: {
        draft.isOpen = false;
        draft.readerIsOpen = action.readerIsOpen;
        break;
      }

      /*
       * Notification Message
       */
      // Notification Snack copy
      case types.CONFIRM_COPY: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Message copié';
        draft.snackMessageInfo = `Message de ${action.username} copié`;
        draft.severity = 'success';
        break;
      }

      /*
       * Notification Snack close
       */
      case types.CLOSE_SNACK_NOTIFICATIONS: {
        draft.snackIsOpen = false;
        draft.snackMessageInfo = '';
        draft.severity = undefined;
        break;
      }

      /*
       * Notification Audio Conference
       */
      case types.SET_AUDIO_CONFERENCE_STATUS: {
        if (isStudent(state.clientRole)) {
          if (action.conferenceStatus === CONFERENCE_STATUS.RUNNING) {
            const notifId = uuid();
            const newNotification = {
              id: notifId,
              title: titles.AUDIO_CONFERENCE_ACTIVE,
              description: descriptions.AUDIO_CONFERENCE_ACTIVE,
            };
            // If same notification exist, remove and actualise
            state.allNotifications.forEach((notif) => {
              if (notif.title === titles.AUDIO_CONFERENCE_ACTIVE) {
                draft.allNotifications = state.allNotifications.filter(
                  (notification) => notification.id !== notifId,
                );
              }
            });
            draft.allNotifications.push(newNotification);
            if (!settings || settings?.soundEnabled) {
              play();
            }
          }

          if (action.conferenceStatus === CONFERENCE_STATUS.STOPPED) {
            const notifId = uuid();
            const newNotification = {
              id: notifId,
              title: titles.AUDIO_CONFERENCE_INACTIVE,
              description: descriptions.AUDIO_CONFERENCE_INACTIVE,
            };
            // If same notification exist, remove and actualise
            state.allNotifications.forEach((notif) => {
              if (notif.title === titles.AUDIO_CONFERENCE_INACTIVE) {
                draft.allNotifications = state.allNotifications.filter(
                  (notification) => notification.id !== notifId,
                );
              }
            });
            draft.allNotifications.push(newNotification);
            if (!settings || settings?.soundEnabled) {
              play();
            }
          }
        }
        break;
      }

      /**
       * Notification Max Tracks Reached
       */
      case types.DISABLE_WEBCAM: {
        if (action.isMaxTracksReached) {
          const notifId = uuid();
          const newNotification = {
            id: notifId,
            title: titles.MAX_TRACKS_REACHED,
            description: descriptions.MAX_TRACKS_REACHED,
          };
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }
        }
        break;
      }

      case types.COURSE_END_SOON: {
        const notifId = uuid();
        const newNotification = {
          id: notifId,
          title: titles.COURSE_END_SOON,
          description: descriptions.COURSE_END_SOON.replace('XX', action.minutesLeft),
        };

        // If course end soon notification is not already sent, add it
        if (!draft.courseEndSoon) {
          draft.allNotifications.push(newNotification);
          if (!settings || settings?.soundEnabled) {
            play();
          }

          draft.courseEndSoon = true;
        }
        // Else, edit the existing one with the new minutes left (if exist)
        else {
          draft.allNotifications = state.allNotifications.map((notif) =>
            (notif.title === titles.COURSE_END_SOON ? newNotification : notif),
          );
        }
        break;
      }

      case types.EDUSIGN_STUDENT_SIGNATURE_NEEDED: {
        const notifId = uuid();
        const newNotification = {
          id: notifId,
          title: titles.EDUSIGN_SIGNATURE_NEEDED,
          description: descriptions.EDUSIGN_STUDENT_SIGNATURE_NEEDED,
        };
        // If same notification exist, remove and actualise
        state.allNotifications.forEach((notif) => {
          if (notif.title === titles.EDUSIGN_SIGNATURE_NEEDED) {
            draft.allNotifications = state.allNotifications.filter(
              (notification) => notification.id !== notifId,
            );
          }
        });
        draft.allNotifications.push(newNotification);
        if (!settings || settings?.soundEnabled) {
          play();
        }
        break;
      }

      case types.EDUSIGN_TEACHER_SIGNATURE_NEEDED: {
        const notifId = uuid();
        const newNotification = {
          id: notifId,
          title: titles.EDUSIGN_SIGNATURE_NEEDED,
          description: descriptions.EDUSIGN_TEACHER_SIGNATURE_NEEDED,
        };
        // If same notification exist, remove and actualise
        state.allNotifications.forEach((notif) => {
          if (notif.title === titles.EDUSIGN_SIGNATURE_NEEDED) {
            draft.allNotifications = state.allNotifications.filter(
              (notification) => notification.id !== notifId,
            );
          }
        });
        draft.allNotifications.push(newNotification);
        if (!settings || settings?.soundEnabled) {
          play();
        }
        break;
      }

      case types.SIGNATURE_DONE: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Succès !';
        draft.snackMessageInfo = `La feuille d'émargement à bien été ${
          action.isTeacherType ? 'envoyée' : 'signée'
        }.`;
        draft.severity = 'success';
        break;
      }

      case types.RESEND_SIGNATURE_NOTIFICATIONS_SUCCESS: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Succès !';
        draft.snackMessageInfo = "Les apprenants ont été relancés à signer la feuille d'émargement.";
        draft.severity = 'success';
        break;
      }

      case types.SIGNATURE_FAIL: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Erreur !';
        draft.snackMessageInfo = `La feuille d'émargement n'a pas pu être ${
          action.isTeacherType ? 'envoyée' : 'signée'
        }. Veuillez réessayer.`;
        draft.severity = 'danger';
        break;
      }

      case types.RESET_SIGNATURES_FAIL: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Erreur !';
        draft.snackMessageInfo = "L'émargement n'a pas pu être réinitialisé. Veuillez réessayer.";
        draft.severity = 'danger';
        break;
      }

      case types.SIGNATURE_START_ERROR: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Erreur !';
        draft.snackMessageInfo = 'La feuille d’émargement n’a pas pu être envoyée. Veuillez réessayer.';
        draft.severity = 'danger';
        break;
      }

      case types.SIGNATURE_RESEND_ERROR: {
        draft.snackIsOpen = true;
        draft.snackMessageTitle = 'Erreur !';
        draft.snackMessageInfo = 'Une erreur est survenue, la relance a échoué. Veuillez réessayer.';
        draft.severity = 'danger';
        break;
      }

      default:
        return state;
    }
  });
