/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Modal from 'src/components/Course/Controls/Modal';
import { sendUnraisedHand, sendTakeVoice } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  clientId: state.users.client.id,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ sendTakeVoice, sendUnraisedHand }, dispatch),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
