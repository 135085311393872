/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';
import type { StatusVMEnum } from 'src/constants/virtualDesktop';

/**
 * Types
 */
export interface VirtualDesktopState {
  status?: StatusVMEnum;
}

/*
 * Initial State
 */
const initialState = {
  status: null,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_VIRTUAL_DESKTOP_STATUS:
        draft.status = action.status;
        break;

      default:
        return state;
    }
  });
