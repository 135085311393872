/*
 * Package Import
 */

/*
 * Local Import
 */
import * as types from 'src/store/types';
import { setPeopleIncrement, setPeopleProperties, trackEvent } from 'src/utils/tracking';
import { awayTime } from 'src/components/TopBar/Menu/AwayChoice/data';
import { marks } from 'src/data/textSize';
import { emotions } from 'src/constants/emotions';
import chatsTypes from 'src/constants/chats';
import { eventNames } from 'src/constants/tracking';
import { LIGHT_THEME_NAME } from 'src/constants/theme';
import { isTeacher } from 'src/utils/roles';
import { initialLayout } from 'src/store/reducers/settings/layout';
import { CONFERENCE_STATUS } from 'src/constants/conference';
import MESSAGES from 'src/constants/messages';

/*
 * Init
 */

/*
 * Middleware
 */
export default (store) => (next) => (action) => {
  switch (action.type) {
    case types.INITIALIZE_DATA: {
      const { users, settings } = store.getState();
      const { client } = users;
      const { course } = action.data;
      // eslint-disable-next-line max-len
      const {
        chatAppearance,
        chatSize,
        textSize,
        transcription,
        displayChat,
        webcams,
        tooltips,
        displayUsers,
        userListStyle,
      } = settings.layout;

      const data = {
        courseId: course.id,
        courseName: course.title,
        promotionId: course.promoId,
        promotionName: course.promoName,
        userStatus: action.data.users.byId[client.id]?.role,
      };

      trackEvent(eventNames.CLASS_JOINED, data);
      setPeopleIncrement('coursesJoinedCount');

      // Set profile properties
      const layoutSettings = {
        messageSize: marks.find((mark) => mark.value === textSize).label,
        subtitlesUse: transcription,
        classroomDarkMode: document.body.dataset.themeMode,
        usersDisplay: displayUsers,
        chatDisplay: displayChat,
        webcamsDisplay: webcams,
        tooltipDisplay: tooltips,
        userListStyle,
        chatAppearance,
        chatSize,
      };
      setPeopleProperties(layoutSettings);
      break;
    }

    case types.COURSE_LEFT: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        method: action.method,
      };

      trackEvent(eventNames.CLASS_LEFT, data);
      break;
    }

    case types.CHANGE_AWAY: {
      const { users, breaktime, course } = store.getState();
      const { client } = users;
      const timeValue = breaktime?.timeValue;
      const { courseId, courseTitle, promoId, promoName } = course;
      const optionAwayTime = awayTime.find((data) => data.value === timeValue.toString());
      const labelAwayTime = optionAwayTime?.label || timeValue;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        timer: labelAwayTime,
      };

      trackEvent(action.away ? eventNames.BREAK_TAKEN : eventNames.BREAK_ENDED, data);
      break;
    }

    case types.SEND_RAISE_HAND: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
      };

      trackEvent(eventNames.SPEECH_ASKED, data);
      break;
    }

    case types.SEND_UNRAISE_HAND: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
      };

      trackEvent(eventNames.SPEECH_CANCELLED, data);
      break;
    }

    case types.SEND_GIVE_VOICE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
      };

      trackEvent(eventNames.SPEECH_GIVEN, data);
      break;
    }

    case types.SEND_TAKE_VOICE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
      };

      trackEvent(eventNames.SPEECH_GIVEN_BACK, data);
      break;
    }

    case types.SEND_MESSAGE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const { message, type } = action.payload;

      const baseData = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
      };

      const messageSentdata = {
        ...baseData,
        // Message infos
        spoiler: message.isSpoiler,
        destination: type === 'general' ? 'Class' : 'Private',
        codeMessage: message.content.ops.some((op) => op.attributes?.['code-block']),
        italicMessage: message.content.ops.some((op) => op.attributes?.italic),
        boldMessage: message.content.ops.some((op) => op.attributes?.bold),
        emojiMessage: message.content.ops.some((op) => op.insert?.emoji),
      };

      trackEvent(eventNames.MESSAGE_SENT, messageSentdata);

      if (message.pinned) {
        const messagePinnedData = {
          ...baseData,
          shortcut: message.isCommand,
          pinnedMessage: true,
        };

        trackEvent(eventNames.MESSAGE_PINNED, messagePinnedData);
      }

      if (message.isAsked) {
        const data = {
          ...baseData,
          userMessageStatus: client.role,
          shortcut: message.isCommand,
          tagAsQuestion: false,
        };

        trackEvent(eventNames.QUESTION_ASKED, data);
      }
      break;
    }

    case types.EDIT_MESSAGE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        userMessageStatus: users.userById[action.messageUserId].role,
      };

      trackEvent(eventNames.MESSAGE_MODIFIED, data);
      break;
    }

    case types.DELETE_MESSAGE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        userMessageStatus: users.userById[action.messageUserId].role,
      };

      trackEvent(eventNames.MESSAGE_DELETED, data);
      break;
    }

    case types.ASK_SURVEY: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const { content } = action.payload.message;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,

        shortcut: content.shortcut,
        answersNumber: content.answers.length,
        multipleChoices: content.multiple,
        resultsShared: content.shareResult,
      };

      trackEvent(eventNames.POLL_CREATED, data);
      break;
    }

    case types.VOTE_SURVEY: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      if (users.client.id === action.userId) {
        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          creationTime: new Date(action.time).toString(),
        };

        trackEvent(eventNames.POLL_ANSWERED, data);
      }
      break;
    }

    case types.REMOVE_PIN:
    case types.ADD_PIN: {
      // Check if its own action
      if (action.ws) {
        const { course, users } = store.getState();
        const { courseId, courseTitle, promoId, promoName } = course;
        const { client } = users;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          userMessageStatus: users.userById[action.messageUserId].role,
          shortcut: false,
          pinnedMessage: action.type === types.ADD_PIN,
        };

        trackEvent(eventNames.MESSAGE_PINNED, data);
      }
      break;
    }

    case types.FLAG_MESSAGE_AS_QUESTION: {
      // Check if it's own action
      if (action.ws) {
        const { course, users } = store.getState();
        const { courseId, courseTitle, promoId, promoName } = course;
        const { client } = users;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          userMessageStatus: users.userById[action.messageUserId].role,
          shortcut: false,
          tagAsQuestion: true,
        };

        trackEvent(eventNames.QUESTION_ASKED, data);
      }
      break;
    }

    case types.SET_QUESTION_MESSAGE_ANSWERED_STATUS: {
      // Check if its own action
      if (action.ws) {
        const { course, users } = store.getState();
        const { courseId, courseTitle, promoId, promoName } = course;
        const { client } = users;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          userMessageStatus: users.userById[action.messageUserId].role,
        };

        if (action.answered) {
          trackEvent(eventNames.QUESTION_RESOLVED, data);
        }
        else {
          trackEvent(eventNames.QUESTION_ASKED, { ...data, shortcut: false, tagAsQuestion: true });
        }
      }
      break;
    }

    case types.TEXTSIZE_CHANGE:
    case types.SET_CHAT_SIZE:
    case types.SET_USERLIST_STYLE:
    case types.SET_TRANSCRIPTION_ENABLED:
    case types.CHANGE_THEME:
    case types.TOGGLE_LAYOUT: {
      const { course, users, settings } = store.getState();
      const { promoId, promoName } = course;
      // eslint-disable-next-line max-len
      const {
        chatAppearance,
        chatSize,
        textSize,
        transcription,
        displayChat,
        webcams,
        tooltips,
        displayUsers,
        userListStyle,
      } = settings.layout;
      const { client } = users;

      // Set storeName by action.type or action.name
      let storeName;
      switch (action.type) {
        case types.TEXTSIZE_CHANGE:
          storeName = 'textSize';
          break;

        case types.CHANGE_THEME:
          storeName = 'classroomDarkMode';
          break;

        case types.SET_CHAT_APPEARANCE:
          storeName = 'chatAppearance';
          break;

        case types.SET_CHAT_SIZE:
          storeName = 'chatSize';
          break;

        case types.SET_TRANSCRIPTION_ENABLED:
          storeName = 'transcription';
          break;

        case types.SET_USERLIST_STYLE:
          storeName = 'userListStyle';
          break;

        default:
          storeName = action.name;
          break;
      }

      /**
       * People properties
       */
      const getDefaultActivationStatus = (newValue) => (newValue ? 'Enabled' : 'Disabled');
      // Create an array to store mixpanel property name with the current value
      const layoutSettings = [
        {
          storeName: 'textSize',
          mixpanelName: 'messageSize',
          value: textSize,
          activation: () => "Don't apply",
        },
        {
          storeName: 'transcription',
          mixpanelName: 'subtitlesUse',
          value: transcription,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'classroomDarkMode',
          mixpanelName: 'classroomDarkMode',
          // Not in redux store
          value: document.body.dataset.themeMode,
          activation: (newValue) => (newValue === 'dark' ? 'Enabled' : 'Disabled'),
        },
        {
          storeName: 'displayChat',
          mixpanelName: 'chatDisplay',
          value: displayChat,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'displayUsers',
          mixpanelName: 'usersDisplay',
          value: displayUsers,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'webcams',
          mixpanelName: 'webcamsDisplay',
          value: webcams,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'tooltips',
          mixpanelName: 'tooltipDisplay',
          value: tooltips,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'userListStyle',
          mixpanelName: 'userListStyle',
          value: userListStyle,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'chatAppearance',
          mixpanelName: 'chatAppearance',
          value: chatAppearance,
          activation: getDefaultActivationStatus,
        },
        {
          storeName: 'chatSize',
          mixpanelName: 'chatSize',
          value: chatSize,
          activation: getDefaultActivationStatus,
        },
      ];

      // Create object who want to send to mixpanel
      const properties = {};
      const activations = {};

      // For each setting, check if current value change and add that in properties object
      layoutSettings.forEach((setting) => {
        let value;
        if (setting.storeName === storeName) {
          value = action.name ? !setting.value : action.value;
        }
        else {
          value = setting.value;
        }

        // For text size, change int value by the good string value
        if (setting.storeName === 'textSize') {
          value = marks.find((mark) => mark.value === value).label;
        }

        activations[setting.mixpanelName] = setting.activation(value);
        properties[setting.mixpanelName] = value;
      });

      // Send properties object to mixpanel
      setPeopleProperties(properties);

      const settingUpdated = layoutSettings.find((setting) => setting.storeName === storeName);
      const activation = settingUpdated
        ? activations[settingUpdated.mixpanelName]
        : 'Setting updated not found : error';

      /**
       * Event
       */
      const data = {
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        location: storeName,
        activation,
      };

      trackEvent(eventNames.INTERFACE_UPDATED, data);
      break;
    }

    case types.RESET_LAYOUT: {
      const { course, users } = store.getState();
      const { promoId, promoName } = course;
      const { client } = users;
      // eslint-disable-next-line max-len
      const {
        chatAppearance,
        chatSize,
        textSize,
        transcription,
        displayChat,
        webcams,
        tooltips,
        displayUsers,
        userListStyle,
      } = initialLayout;

      const layoutSettings = {
        messageSize: marks.find((mark) => mark.value === textSize).label,
        subtitlesUse: transcription,
        classroomDarkMode: LIGHT_THEME_NAME,
        usersDisplay: displayUsers,
        chatDisplay: displayChat,
        webcamsDisplay: webcams,
        tooltipDisplay: tooltips,
        userListStyle,
        chatAppearance,
        chatSize,
      };
      setPeopleProperties(layoutSettings);

      /**
       * Event
       */
      const data = {
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        location: 'Reset',
        activation: "Don't apply",
      };

      trackEvent(eventNames.INTERFACE_UPDATED, data);
      break;
    }

    case types.DISABLE_MICROPHONE:
    case types.ENABLE_MICROPHONE: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        device: 'Micro',
        activation: action.type === types.ENABLE_MICROPHONE ? 'Enabled' : 'Disabled',
      };

      trackEvent(eventNames.DEVICE_MANAGED, data);
      break;
    }

    case types.DISABLE_SOUND:
    case types.ENABLE_SOUND: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        device: 'Sound',
        activation: action.type === types.ENABLE_SOUND ? 'Enabled' : 'Disabled',
      };

      trackEvent(eventNames.DEVICE_MANAGED, data);
      break;
    }

    case types.DISABLE_WEBCAM:
    case types.ENABLE_WEBCAM: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        device: 'Webcam',
        activation: action.type === types.ENABLE_WEBCAM ? 'Enabled' : 'Disabled',
      };

      trackEvent(eventNames.DEVICE_MANAGED, data);
      break;
    }

    case types.DISABLE_SCREEN:
    case types.ENABLE_SCREEN: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      if (isTeacher(client.role)) {
        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          screenSharing: action.type === types.ENABLE_SCREEN ? 'Enabled' : 'Disabled',
        };

        trackEvent(eventNames.SCREEN_SHARED, data);
      }
      break;
    }

    case types.TOGGLE_NOTIFICATION: {
      const { course, users, settings } = store.getState();
      const { promoId, promoName } = course;
      const { client } = users;

      let name;
      let value;
      switch (action.name) {
        case 'enabled':
          name = 'Global';
          value = !settings.notifications.enabled;
          break;

        case 'soundEnabled':
          name = 'Sound';
          value = !settings.notifications.soundEnabled;
          break;

        case 'privateEnabled':
          name = 'Private message';
          value = !settings.notifications.privateEnabled;
          break;

        case 'pinEnabled':
          name = 'Pinned message';
          value = !settings.notifications.pinEnabled;
          break;

        default:
          break;
      }

      const data = {
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        notification: name,
        activation: value,
      };

      trackEvent(eventNames.NOTIFICATIONS_MANAGED, data);
      break;
    }

    case types.SET_MODAL_SHORTCUTS: {
      if (action.isOpen) {
        const { course, users } = store.getState();
        const { courseId, courseTitle, promoId, promoName } = course;
        const { client } = users;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
          method: action.method,
        };

        trackEvent(eventNames.SHORTCUTS_DISPLAYED, data);
      }
      break;
    }

    case types.CHANGE_EMOTION: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      if (users.client.id === action.user) {
        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          userStatus: client.role,
        };

        if (action.emotion !== 'none') {
          const emotionLabel = emotions.find((item) => item.emoji === action.emotion).feeling;
          data.emotionType = emotionLabel;

          trackEvent(eventNames.EMOTION_UPDATED, data);
        }
        else {
          const previousEmotion = users.userById[users.client.id].emotion;
          const emotionLabel = emotions.find((item) => item.emoji === previousEmotion).feeling;
          data.emotionType = emotionLabel;

          trackEvent(eventNames.EMOTION_DELETED, data);
        }
      }
      break;
    }

    case types.CHANGE_ACTIVE_VIEW: {
      if (action.view === chatsTypes.CHAT_GENERAL) {
        const { course } = store.getState();
        const { courseId, courseTitle, promoId, promoName } = course;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
        };

        trackEvent(eventNames.GLOBAL_CHAT_OPENED, data);
      }
      break;
    }

    case types.OPEN_CHAT:
    case types.CHANGE_CHAT:
    case types.CREATE_NEW_CHAT: {
      const { course, chats } = store.getState();
      if (
        (action.chatId && action.chatId !== chats.general)
        || action.type === types.CREATE_NEW_CHAT
      ) {
        const { courseId, courseTitle, promoId, promoName } = course;

        const data = {
          courseId,
          courseName: courseTitle,
          promotionId: promoId,
          promotionName: promoName,
          location: action.methodLocation,
        };

        trackEvent(eventNames.PRIVATE_CHAT_OPENED, data);
      }
      break;
    }

    case types.SET_VISIBILITY_FILTER: {
      const { course, users } = store.getState();
      const { courseId, courseTitle, promoId, promoName } = course;
      const { client } = users;

      let filter;
      if (action.filter === MESSAGES.SHOW_QUESTION) {
        filter = 'Questions';
      }
      else if (action.filter === MESSAGES.SHOW_PINNED) {
        filter = 'Pinned';
      }

      const data = {
        courseId,
        courseName: courseTitle,
        promotionId: promoId,
        promotionName: promoName,
        userStatus: client.role,
        filter: filter ?? action.filter,
        activation: action.filter !== MESSAGES.SHOW_ALL,
      };

      trackEvent(eventNames.MESSAGES_FILTERED, data);
      break;
    }

    case types.SET_AUDIO_CONFERENCE_STATUS: {
      const { course, users } = store.getState();
      const { promoId, promoName, courseId, courseTitle } = course;
      const { client } = users;

      const data = {
        promotionId: promoId,
        promotionName: promoName,
        courseId,
        courseName: courseTitle,
        userStatus: client.role,
      };

      if (action.conferenceStatus === CONFERENCE_STATUS.RUNNING) {
        trackEvent(eventNames.DISCUSSION_MODE_ENABLED, data);
      }

      if (action.conferenceStatus === CONFERENCE_STATUS.STOPPED) {
        trackEvent(eventNames.DISCUSSION_MODE_DISABLED, data);
      }
      break;
    }

    default:
      break;
  }

  return next(action);
};
