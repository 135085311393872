import styled from '@emotion/styled';

export const SectionHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '0 1rem',
  marginBottom: '1rem',

  svg: {
    marginRight: '0.5rem',
  },

  h4: {
    flexGrow: 0,
    lineHeight: '2rem',
    marginRight: '0.5rem',
  },
});
