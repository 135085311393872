/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */

export const Input = styled.div`
  display: flex;
  padding: 0.625rem 0.5rem;
  height: fit-content;
  font-size: ${({ textSize }) => `${textSize}rem !important`};
`;

export const Inner = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
`;
