import React from 'react';
import styled from '@emotion/styled';
import {
  UilPen,
  UilTimesCircle,
  UilCheckCircle,
  UilDownloadAlt,
  UilCloudDataConnection,
  UilUsersAlt,
  UilRedo,
  UilTrashAlt,
  UilPlus,
} from '@iconscout/react-unicons';
import { MongoId } from 'src/schemas/Entities/utils';

const iconStyle = {
  width: '1.5rem',
  height: '1.5rem',
};

const UpdateIcon = styled(UilPen)(() => iconStyle);

const DeactivateIcon = styled(UilTimesCircle)(() => iconStyle);

const ReactivateIcon = styled(UilCheckCircle)(() => iconStyle);

const ExportIcon = styled(UilDownloadAlt)(() => iconStyle);

const VMIcon = styled(UilCloudDataConnection)(() => iconStyle);

const UsersIcon = styled(UilUsersAlt)(() => iconStyle);

const SendInvitationIcon = styled(UilRedo)(() => iconStyle);

const DeleteIcon = styled(UilTrashAlt)(() => iconStyle);

const PlusIcon = styled(UilPlus)(() => iconStyle);

export type MenuOption = {
  key: string;
  label: string;
  icon: JSX.Element;
  getHandle: (id: MongoId | null) => React.MouseEventHandler<HTMLButtonElement>;
  getTooltipContent?: (rowId: MongoId | null) => string;
  isDisplayed?: (rowId: MongoId | null) => boolean;
  isDisabled?: (rowId: MongoId | null) => boolean;
};

export type MenuOptions = {
  updateOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed?: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
  deactivateOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
  reactivateOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
  activityOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (id: MongoId | null) => boolean,
  ) => MenuOption;
  vmOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (id: MongoId | null) => boolean,
  ) => MenuOption;
  promoUsersOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
  sendInvitationOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
    isDisabled: (rowId: MongoId | null) => boolean,
    getTooltipContent: (rowId: MongoId | null) => string,
  ) => MenuOption;
  deleteOption: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
  createChildPromo: (
    handle: (id: MongoId | null) => void,
    isDisplayed: (rowId: MongoId | null) => boolean,
  ) => MenuOption;
};

export const menuOptions: MenuOptions = {
  updateOption: (handle, isDisplayed) => ({
    key: 'update',
    label: 'Modifier',
    icon: <UpdateIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  deactivateOption: (handle, isDisplayed) => ({
    key: 'deactivate',
    label: 'Archiver',
    icon: <DeactivateIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  reactivateOption: (handle, isDisplayed) => ({
    key: 'reactivate',
    label: 'Désarchiver',
    icon: <ReactivateIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  activityOption: (handle, isDisplayed) => ({
    key: 'activity',
    label: 'Exporter l’activité',
    icon: <ExportIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  vmOption: (handle, isDisplayed) => ({
    key: 'vmURL',
    label: 'Accéder à la VM',
    icon: <VMIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  promoUsersOption: (handle, isDisplayed) => ({
    key: 'promoUsers',
    label: 'Gérer les participants',
    icon: <UsersIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  sendInvitationOption: (handle, isDisplayed, isDisabled, getTooltipContent) => ({
    key: 'sendInvitation',
    label: "Renvoyer l'invitation",
    icon: <SendInvitationIcon />,
    getTooltipContent,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
    isDisabled,
  }),
  deleteOption: (handle, isDisplayed) => ({
    key: 'delete',
    label: 'Supprimer',
    icon: <DeleteIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
  createChildPromo: (handle, isDisplayed) => ({
    key: 'createChildPromo',
    label: 'Créer une promotion fille',
    icon: <PlusIcon />,
    getHandle:
      (rowId): React.MouseEventHandler<HTMLButtonElement> =>
        () =>
          handle(rowId),
    isDisplayed,
  }),
};
