/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  UserDTOWithoutId,
  userDTOSchemaWithoutId,
  UserFormDataValidated,
  userFormDataValidatedSchema,
  UserDTO,
  userDTOSchema,
} from 'src/schemas/Entities/User';
import { MongoId } from 'src/schemas/Entities/utils';
import { purify, purifyIfExist } from '../utils';

/*
 * Code
 */

export const transformUserFormDataToUserDTO = (userFormData: UserFormDataValidated): UserDTO => {
  const userFormDataParseResult = userFormDataValidatedSchema.parse(userFormData);

  if (!userFormDataParseResult.id) {
    throw new Error("transformUserFormDataToUserDTO : l'id du user est manquant");
  }

  const {
    id,
    firstname,
    lastname,
    email,
    role,
    githubProfile,
    discordProfile,
    town,
    country,
    avatar,
    promotions,
    integrations,
    canCreateVM,
  } = userFormDataParseResult;

  const userDTO: UserDTO = {
    id,
    firstname: purify(firstname),
    lastname: purify(lastname),
    email: purify(email),
    role: role.value,
    githubProfile: purifyIfExist(githubProfile),
    discordProfile: purifyIfExist(discordProfile),
    town: purifyIfExist(town),
    country: purifyIfExist(country),
    avatar: purifyIfExist(avatar),
    integrations,
    promosIds: promotions.reduce((ids: MongoId[], promotion) => {
      if (promotion) ids.push(promotion.value);
      return ids;
    }, []),
    canCreateVM,
  };

  return userDTOSchema.parse(userDTO);
};

export const transformUserFormDataToUserDTOWithoutId = (
  userFormData: UserFormDataValidated,
): UserDTOWithoutId => {
  const userFormDataParseResult = userFormDataValidatedSchema.parse(userFormData);

  const {
    firstname,
    lastname,
    email,
    role,
    githubProfile,
    discordProfile,
    town,
    country,
    avatar,
    promotions,
    integrations,
  } = userFormDataParseResult;

  const userDTO: UserDTOWithoutId = {
    firstname: purify(firstname),
    lastname: purify(lastname),
    email: purify(email),
    role: role.value,
    githubProfile: purifyIfExist(githubProfile),
    discordProfile: purifyIfExist(discordProfile),
    town: purifyIfExist(town),
    country: purifyIfExist(country),
    avatar: purifyIfExist(avatar),
    integrations,
    promosIds: promotions.reduce((ids: MongoId[], promotion) => {
      if (promotion) ids.push(promotion.value);
      return ids;
    }, []),
  };

  return userDTOSchemaWithoutId.parse(userDTO);
};

export const transformUsersFormDataToUsersDTO = (usersData: UserFormDataValidated[]): UserDTO[] =>
  usersData.map((user) => transformUserFormDataToUserDTO(user));
