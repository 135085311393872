/*
 * Package Import
 */
import React from 'react';
import { Column } from '@tanstack/react-table';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip';

// Helpers
import { ZodValidationFailure, ZodValidationUser } from 'src/schemas/Adapters/Users/utils';
import { UserDisplayBeforeCreate } from 'src/schemas/Entities/User';
import { getAnomaliesDetails } from './utils';

// Style
import * as S from './style';

/*
 * Code
 */
type AnomalyTagProps = {
  zodResult: ZodValidationFailure;
  column: Column<ZodValidationUser, unknown>;
  allColumns: Column<ZodValidationUser, unknown>[];
};
export const AnomalyTag = ({ zodResult, column, allColumns }: AnomalyTagProps): JSX.Element => {
  const { error: zodError } = zodResult;
  const anomaliesDetails = getAnomaliesDetails(zodError, allColumns);
  const cellAnomaly = anomaliesDetails.find((anomalyDetails) => anomalyDetails.id === column.id);

  const getLabel = (): string => {
    const dataValue = zodResult.data[column.id as keyof UserDisplayBeforeCreate];

    if (typeof dataValue === 'string') {
      return dataValue;
    }

    return '';
  };

  return (
    <Tooltip content={cellAnomaly?.message || 'Erreur'}>
      <S.ErrorTag>
        <S.CsvErrorIcon />
        <S.ErrorTagLabel>{getLabel() || 'Information requise'}</S.ErrorTagLabel>
      </S.ErrorTag>
    </Tooltip>
  );
};
