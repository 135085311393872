/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import VoiceRequest from 'src/components/Users/UsersPanel/VoiceRequest';
import { HAND_STATUS } from 'src/constants/conference';
import { isTeacher, isStudent, isGhost } from 'src/utils/roles';
import {
  simpleSendTakeVoice,
  sendGiveVoice,
  refuseGiveVoice,
  deleteNotifications,
} from 'src/store/actions';
import { titles } from 'src/constants/notifications';
import { isMaxVideoTracksReached } from 'src/utils/webRTCManager/utils';

/*
 * Code
 */
const mapStateToProps = (state) => {
  const { course, users, webRTC } = state;
  const { peers, peerById, usersWebcamMuted } = course;
  const { client, userById } = users;
  const { role: myRole } = client;
  const { streamList, streamListStatus } = webRTC;

  const students = peers
    .filter(
      (user) =>
        (isStudent(peerById[user].role) || isGhost(peerById[user].role))
        && peerById[user]?.hand !== HAND_STATUS.UNRAISED,
    )
    .map((user) => users.userById[user]);

  return {
    peerById,
    students,
    isClientTeacher: isTeacher(myRole),
    notifications: state.notifications.allNotifications.filter(
      (notif) => notif.title === titles.REQUEST_TALK,
    ),
    isMaxTracksReached: isMaxVideoTracksReached({
      camsStreamList: streamList.cams,
      streamListStatus: streamListStatus.cams,
      usersWebcamMuted,
      userById,
    }),
    streamListStatus: streamListStatus.cams,
  };
};

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      simpleSendTakeVoice,
      sendGiveVoice,
      refuseGiveVoice,
      deleteNotifications,
    },
    dispatch,
  ),
});

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(VoiceRequest);
