/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Container = styled.div({
  position: 'relative',
});

export const ProfileCard = styled.picture(({ theme }) => ({
  borderRadius: '8px',
  minWidth: '3rem',
  height: '3rem',
  width: '3rem',
  backgroundColor: theme.background._7,
  cursor: 'pointer',
}));

export const Img = styled.img`
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
