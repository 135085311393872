/*
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';

/*
 * Local Import
 */
import { ThemeInterface } from 'src/theme/slippersTheme/themes/index';
import Tooltip from 'src/components/Tooltip';
import { getIsRegisteredStrings } from 'src/constants/status';

/*
 * Styles
 */
const getBodyText = (theme: ThemeInterface): Record<symbol, string> => theme.typo.bodyText.body;
const RegistrationStatusLabel = styled.span(({ theme }) => ({
  ...getBodyText(theme),
  '&.registration-ok': {
    color: theme.success.main,
  },
  '&.registration-waiting': {
    color: theme.danger.main,
  },
}));

const Container = styled.div(() => ({
  width: 'fit-content',
}));

/*
 * Types
 */
type RegistrationStatusProps = {
  status: boolean;
};

/*
 * Components
 */
export const RegistrationStatus = ({ status }: RegistrationStatusProps): JSX.Element => {
  const { statusString, tooltipString } = getIsRegisteredStrings(status);
  return (
    <Container>
      <Tooltip content={tooltipString}>
        <RegistrationStatusLabel className={status ? 'registration-ok' : 'registration-waiting'}>
          {statusString}
        </RegistrationStatusLabel>
      </Tooltip>
    </Container>
  );
};
