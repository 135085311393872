/**
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Local Import
 */
import { useAction } from 'src/components/Classroom/hooks';
import * as S from './style';

/**
 * Component
 */
const Pin = ({ actions, canPin, pinned, messageId }) => {
  const { setMessagePinned, setMessageUnpinned } = useAction();
  /**
   * Pin the message
   * @return {void}
   */
  const handlePin = () => {
    actions.pinMessage();
    setMessagePinned({ messageId });
  };

  /**
   * UnPin the message
   * @return {void}
   */
  const handleUnPin = () => {
    actions.unpinMessage();
    setMessageUnpinned({ messageId });
  };

  /**
   * Render
   */
  if (!canPin) {
    return null;
  }

  return pinned ? (
    <S.Button role="button" aria-label="Épingler le message" onClick={handleUnPin}>
      <S.Pinned />
    </S.Button>
  ) : (
    <S.Button role="button" aria-label="Désépingler le message" onClick={handlePin}>
      <S.Unpin />
    </S.Button>
  );
};

/**
 * PropTypese
 */
Pin.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** The user has permission {Educator} to pin the current message */
  canPin: PropTypes.bool,
  /** State of the message, it is pinned ? */
  pinned: PropTypes.bool,
  /** Message id */
  messageId: PropTypes.string.isRequired,
};

Pin.defaultProps = {
  canPin: false,
  pinned: false,
};

/**
 * Export
 */
export default Pin;
