/*
 * Package Import
 */

/*
 * Local Import
 */
import {
  getPromoOptions,
  getPromoOptionsTooltipsInfos,
  getRolesOptionsToDisable,
} from 'src/components/Admin/Modals/FormElements/utils';
import { roles, Role } from 'src/constants/roles';
import { Option } from 'src/components/Admin/Modals/FormElements/type';

/*
 * Code
 */
export const usersAddFormData = {
  header: 'Nouvel utilisateur',
  inputs: {
    firstname: {
      title: 'firstname',
      display: 'Prénom *',
      type: 'text',
    },
    lastname: {
      title: 'lastname',
      display: 'Nom *',
      type: 'text',
    },
    email: {
      title: 'email',
      display: 'Email *',
      type: 'email',
    },
    selectRole: {
      title: 'role',
      display: 'Rôle *',
      isMulti: false,
      rolesOptions: roles.map(
        (role: Role): Option => ({
          label: role.display,
          value: role.name,
        }),
      ),
      getRolesOptionsToDisable,
    },
    selectPromotions: {
      title: 'promotions',
      display: 'Promotions',
      isMulti: true,
      getPromoOptions,
      getPromoOptionsTooltipsInfos,
    },
    mustSign: {
      title: 'integrations.edusign.mustSign',
      display: "Activer l'émargement",
      type: 'checkbox',
      tooltip:
        "Activer l'émargement permet à l'utilisateur de signer sa feuille d'émargement à chaque cours.",
    },
    canCreateVM: {
      title: 'canCreateVM',
      display: 'Autoriser la création de bureau virtuel',
      type: 'checkbox',
    },
  },
};
