/*
 * Package Import
 */
import ReduxThunk, { ThunkAction } from 'redux-thunk';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import type { Action, PreloadedState } from 'redux';

/*
 * Local Import
 */
import reducers from './reducers';

// Middlewares
import localStorage from './middlewares/localStorage';
import webSocket from './middlewares/websocket';
import transcription from './middlewares/transcription';
import webRTC from './middlewares/webRTC';
import tracking from './middlewares/tracking';
import course from './middlewares/course';

// Enhancers
import sentryReduxEnhancer from './enhancers/sentry';

/*
 * Code
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Setup Store
export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  createStore(
    reducers,
    preloadedState,
    composeEnhancers(
      applyMiddleware(localStorage, transcription, tracking, webSocket, course, webRTC, ReduxThunk),
      sentryReduxEnhancer,
    ),
  );

const store = setupStore({});

/*
 * Export
 */
export default store;

/**
 * Types
 */
export type RootState = ReturnType<typeof reducers>;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, null, Action<string>>;
