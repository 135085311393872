/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { UserDTO, UserFormDataValidated } from 'src/schemas/Entities/User';
import { transformUserFormDataToUserDTOWithoutId } from 'src/schemas/Adapters/Users/transformUserFormDataToUserDTO';
import { API_CREATE_USER, API_POST } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const createUser = async (userFormData: UserFormDataValidated): Promise<UserDTO> => {
  try {
    const userDTO = transformUserFormDataToUserDTOWithoutId(userFormData);

    const payload: MutationPayload = {
      method: API_POST,
      url: API_CREATE_USER,
      data: userDTO,
    };
    const { data: userData } = await api(payload);

    return userData;
  }
  catch (error) {
    toast(`Erreur pendant le chargement : ${error}`, { type: 'error' });
    throw error;
  }
};
