/*
 * Package Import
 */
import React from 'react';
import PropTypes from 'prop-types';
import { UilTimes } from '@iconscout/react-unicons';

/*
 * Local Import
 */
// Component
import Sections from './Section';

// Style
import * as S from './style';

/*
 * Component
 */
const Shortcuts = ({ actions, isOpen }) => {
  /*
   * Handlers
   */
  const handleClose = () => {
    actions.setModalShortcuts({ isOpen: false });
  };

  /*
   * Render
   */
  return (
    <S.Modal open={isOpen} onClose={handleClose} aria-labelledby="shortcuts-modal">
      <S.Title>
        Raccourcis clavier
        <S.Close onClick={handleClose}>
          <UilTimes />
        </S.Close>
      </S.Title>
      <S.Container>
        <Sections />
      </S.Container>
    </S.Modal>
  );
};

/*
 * PropTypes
 */
Shortcuts.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  /** State modal shortcut for display */
  isOpen: PropTypes.bool.isRequired,
};

Shortcuts.defaultProps = {};

/*
 * Export
 */
export default Shortcuts;
