/*
 * Package Import
 */
import styled from '@emotion/styled';
import {
  UilCheckCircle,
  UilInfoCircle,
  UilExclamationCircle,
  UilExclamationTriangle,
} from '@iconscout/react-unicons';

import { Snackbar as SnackbarMaterial } from '@material-ui/core';

/*
 * Style
 */

const iconStyles = (theme, severity) => ({
  color: theme[severity]?.main,
  marginLeft: '0.625rem',
  height: '1.625rem',
  width: '1.625rem',
});

export const Snackbar = styled(SnackbarMaterial)(({ theme }) => ({
  ...theme.shadows.plus4,
  position: 'absolute !important',
  top: '1rem !important',
  right: '1rem !important',
}));

export const Alert = styled.div(({ theme, severity }) => ({
  color: theme.text._1,
  background: theme.background._2,
  border: `1px solid ${theme[severity]?.main}`,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  opacity: '1',
  transform: 'none',
  transition:
    'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}));

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 21.25rem;
  margin: 0.75rem 1.125rem;
`;

export const Title = styled.label(({ theme }) => ({
  color: theme.text._1,
  ...theme.typo.labels.bigLabel,
}));

export const Description = styled.div(({ theme }) => ({
  color: theme.text._5,
  ...theme.typo.bodyText.smallBody,
}));

export const Close = styled.span(({ theme }) => ({
  cursor: 'pointer',
  color: theme.text._6,
  marginRight: '1rem',
  ':hover': {
    color: theme.primary._1,
  },
  ...theme.typo.bodyText.smallBody,
}));

export const IconSuccess = styled(UilCheckCircle)(({ theme, severity }) => ({
  ...iconStyles(theme, severity),
}));

export const IconInfo = styled(UilInfoCircle)(({ theme, severity }) => ({
  ...iconStyles(theme, severity),
}));

export const IconWarning = styled(UilExclamationCircle)(({ theme, severity }) => ({
  ...iconStyles(theme, severity),
}));

export const IconDanger = styled(UilExclamationTriangle)(({ theme, severity }) => ({
  ...iconStyles(theme, severity),
}));
