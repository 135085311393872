/*
 * Package Import
 */
import styled from '@emotion/styled';
import { UilInfoCircle, UilTimes } from '@iconscout/react-unicons';
import { ReactComponent as Hand } from 'assets/images/icons/hand.svg';
import { ReactComponent as Edusign } from 'assets/images/icons/edusign.svg';

/*
 * Local Import
 */
import { base } from 'src/theme';
/*
 * Style
 */

export const Modal = styled.div(({ theme, rightOpened }) => ({
  position: 'absolute',
  top: '1rem',
  right: rightOpened ? '1rem' : '-19.5rem',
  zIndex: '5',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 2rem',
  background: theme.background._2,
  border: `1px solid ${theme.text._7}`,
  borderRadius: '16px',
  ...theme.shadows.plus3,
}));

export const Title = styled.h2(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '.5rem',
  color: theme.text._1,
  ...theme.typo.headlines.H2,
}));

export const Description = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: '.5rem',
  color: theme.text._1,
  ...theme.typo.bodyText.body,
}));

export const Comment = styled.div(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: '.5rem',
  color: theme.text._1,
  ...theme.typo.bodyText.body,
}));

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;

const iconStyle = {
  height: '3.3125rem',
  width: '3.3125rem',
  margin: 'auto',
  marginBottom: '0.8125rem',
};

export const IconInfoCircle = styled(UilInfoCircle)(({ theme }) => ({
  color: theme.warning.main,
  ...iconStyle,
}));

export const IconHand = styled(Hand)(({ theme }) => ({
  color: theme.info.main,
  ...iconStyle,
}));

export const IconEdusign = styled(Edusign)(() => ({
  color: '#FEC007',
  ...iconStyle,
}));

export const IconClose = styled(UilTimes)(({ theme }) => ({
  color: theme.text._5,
}));

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5rem;
`;

export const Button = styled.button(({ isMain, isCancel, theme }) => ({
  ...base.button({ isMain, isCancel, theme }),
  margin: 'auto',
}));
