/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Local Import
 */

/*
 * Style
 */
export const Select = styled.select(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '2.5rem',
  border: '1px solid',
  borderColor: theme.text._6,
  background: theme.background._2,
  color: theme.text._1,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: '0.5rem 0.75rem',
  margin: '0 auto 1.5rem',
  width: '100%',

  option: {
    background: theme.background._2,
    color: theme.text._1,
    padding: '8px 0px 0px 12px',
  },
  ':focus-visible': { border: `1px solid ${theme.primary._1}` },
  ':-moz-focusring': { border: `1px solid ${theme.primary._1}` },
}));

export const MessageError = styled.span(({ theme }) => ({
  marginBottom: '24px',
  color: theme.colors.danger._500,
  ...theme.typo.bodyText.smallBody,
}));
