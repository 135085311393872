/*
 * Package Import
 */
import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import { Emoji } from 'src/components/Format/Formatizer';
import { useReaction } from 'src/components/Classroom/hooks';
import { UserContext } from 'src/context/User';

// Helper
import { getReactionTip } from './utils';

// Style
import * as S from '../style';

/*
 * Component
 */
const Reaction = ({ messageId, name, namedUsers, userId, users }) => {
  const { user } = useContext(UserContext);
  const { handleReaction } = useReaction();

  const count = users.length;

  /**
   * If current user has reacted with this reaction
   */
  const reacted = useMemo(() => users.some((id) => id === user.id), [users, user.id]);

  /**
   * Add or remove a reaction
   */
  const onReaction = useCallback(() => {
    handleReaction({ messageId, userId, reaction: name });
  }, [handleReaction]);

  /*
   * Render
   */
  return (
    <Tooltip content={getReactionTip({ name, users: namedUsers })} options={{ delay: 300 }}>
      <S.Button
        type="button"
        onClick={onReaction}
        // a11y
        aria-label={`${count} réactions, réagir avec l'émoji ${name}`}
        aria-pressed={reacted}
        // style
        reacted={reacted}
      >
        {/* Emoji */}
        <S.Emoji>
          <Emoji size={16}>{`:${name}:`}</Emoji>
        </S.Emoji>

        {/* Count */}
        {Boolean(count) && <S.Count reacted={reacted}>{count}</S.Count>}
      </S.Button>
    </Tooltip>
  );
};

/*
 * PropTypes
 */
Reaction.propTypes = {
  /** Message's id */
  messageId: PropTypes.string.isRequired,
  /** Reaction name */
  name: PropTypes.string.isRequired,
  /** List of reaction's users names */
  namedUsers: PropTypes.array.isRequired,
  /** People who reacted to the message */
  userId: PropTypes.string.isRequired,
  /** List of reaction's users ids */
  users: PropTypes.array.isRequired,
};

/*
 * Export
 */
export default React.memo(Reaction);
