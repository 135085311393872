/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';

/*
 * Local Import
 */
import * as types from 'src/store/types';

// Constants
import ROLES, { CourseRoleEnum } from 'src/constants/roles';
import {
  HAND_STATUS,
  CONFERENCE_STATUS,
  HandStatusEnum,
  ConferenceStatusEnum,
} from 'src/constants/conference';

// Helpers
import { getSave } from 'src/store/middlewares/localStorage';
import { isStudent, isGhost } from 'src/utils/roles';

/**
 * Types
 */
export interface CourseMediasBooleanProps {
  microphoneEnabled: boolean;
  webcamEnabled: boolean;
  soundEnabled: boolean;
  screenEnabled: boolean;
}

export interface CourseMediasStringProps {
  cameraId: string;
  microphoneId: string;
  speakerId: string;
}

export type CourseMediasProps = CourseMediasBooleanProps & CourseMediasStringProps;

export interface PeerProps {
  id: string;
  role: CourseRoleEnum;
  hand: HandStatusEnum;
}

export interface CourseState {
  antServer: string;
  courseId: string;
  courseTitle: string;
  promoId: string;
  promoName: string;
  clientId: string;
  medias: CourseMediasProps;
  usersWebcamMuted: string[];
  hasScreen: boolean;
  teacherId?: string;
  helpersId?: string[];
  peers: string[];
  audioPeers: string[];
  peerById: Record<string, PeerProps>;
  conferenceStatus: ConferenceStatusEnum;
}

/**
 * Code
 */
const localMediasState: Partial<CourseMediasProps> = getSave('course', 'medias') || {};

/*
 * Initial State
 */
const initialState: CourseState = {
  antServer: 'ant5',
  courseId: '',
  courseTitle: '',
  promoId: '',
  promoName: '',
  clientId: '',

  medias: {
    // Controls
    microphoneEnabled: true,
    webcamEnabled: true,
    soundEnabled: true,
    screenEnabled: false,

    // Devices management
    cameraId: '',
    microphoneId: '',
    speakerId: '',
    ...localMediasState,
  },

  // Conference Peers
  peers: [],
  peerById: {},
  usersWebcamMuted: [],

  // Audio Peers
  audioPeers: [],

  // Replay
  hasScreen: false,

  // Conference
  conferenceStatus: CONFERENCE_STATUS.STOPPED,
};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      /*
       * Data
       */
      case types.INITIALIZE_DATA:
        {
          const { antServer, course, users } = action.data;
          const { role } = users.byId[state.clientId];

          // Initialize course data
          draft.antServer = antServer;
          draft.courseId = course.id;
          draft.courseTitle = course.title;
          draft.promoId = course.promoId;
          draft.promoName = course.promoName;
          draft.hasScreen = course.hasScreen;
          draft.conferenceStatus = course.conferenceStatus || CONFERENCE_STATUS.STOPPED;

          // Initialize conference data
          draft.peers = [...new Set([course.teacherId, ...course.helpersIds, state.clientId])];
          draft.peerById = {
            // Teacher
            [course.teacherId]: {
              id: course.teacherId,
              role: ROLES.ROLE_TEACHER,
              hand: HAND_STATUS.VOICE_GIVEN,
            },

            // Helpers
            ...course.helpersIds.reduce((acc: CourseState['peerById'], item: string) => {
              acc[item] = {
                id: item,
                role: ROLES.ROLE_HELPER,
                hand: HAND_STATUS.UNRAISED,
              };
              return acc;
            }, {}),

            // All others peoples
            ...((isStudent(role) || isGhost(role)) && {
              [state.clientId]: {
                id: state.clientId,
                role,
                hand: HAND_STATUS.UNRAISED,
              },
            }),
          };
        }
        break;

      case types.LOG_USER_IN:
        {
          const { id } = action.user;
          draft.clientId = id;
        }
        break;

      case types.HAS_LEFT:
        {
          const { peers, peerById } = state;
          const isPeer = peers.includes(action.user);

          // If student was in peers list, delete it
          if (isPeer) {
            const { role } = peerById[action.user];
            if (isStudent(role) || isGhost(role)) {
              draft.peers = peers.filter((peer) => peer !== action.user);
              delete draft.peerById[action.user];
            }
          }
        }
        break;

      case types.RECEIVE_AUDIO_STREAMS:
        {
          const newPeers: string[] = [];
          let newPeerById = {};
          action.studentStreams.forEach((studentId: string) => {
            const isPeer = state.peers.includes(action.user);
            if (!isPeer) {
              newPeerById = {
                ...newPeerById,
                [studentId]: {
                  id: studentId,
                  role: ROLES.ROLE_STUDENT,
                  hand: HAND_STATUS.VOICE_GIVEN,
                },
              };
              newPeers.push(studentId);
            }
          });

          if (newPeers.length) {
            draft.peerById = { ...state.peerById, ...newPeerById };
            draft.peers = [...state.peers, ...newPeers];
          }
        }
        break;

      /*
       * Speaking
       */
      case types.SEND_RAISE_HAND:
      case types.RECEIVE_HAND_RAISED:
        {
          const newWebcam = {
            id: action.user.id,
            role: ROLES.ROLE_STUDENT,
            hand: HAND_STATUS.RAISED,
          };

          if (!state.peers.includes(action.user.id)) {
            draft.peers = [...state.peers, action.user.id];
          }

          draft.peerById = {
            ...state.peerById,
            [action.user.id]: newWebcam,
          };
        }
        break;

      case types.SEND_UNRAISE_HAND:
      case types.RECEIVE_HAND_UNRAISED:
      case types.SEND_TAKE_VOICE:
      case types.RECEIVE_VOICE_TAKEN:
      case types.RECEIVE_REFUSE_GIVE_VOICE:
        {
          const { userId } = action;
          const { peers, peerById, clientId } = state;

          if (clientId === userId) {
            const newWebcam = {
              id: userId,
              role: ROLES.ROLE_STUDENT,
              hand: HAND_STATUS.UNRAISED,
            };

            draft.peerById = {
              ...peerById,
              [userId]: newWebcam,
            };
          }
          else if (peers.includes(userId)) {
            draft.peerById = peerById;
            draft.peers = peers.filter((id) => id !== userId);
          }
        }
        break;

      case types.SEND_GIVE_VOICE:
      case types.RECEIVE_VOICE_GIVEN:
        {
          const newWebcam = {
            id: action.userId,
            role: ROLES.ROLE_STUDENT,
            hand: HAND_STATUS.VOICE_GIVEN,
          };

          draft.peerById = {
            ...state.peerById,
            [action.userId]: newWebcam,
          };

          if (!state.peers.includes(action.userId)) {
            draft.peers = [...state.peers, action.userId];
          }
        }
        break;

      /**
       * Medias controls
       */
      case types.SET_MICROPHONE_STATUS:
        draft.medias.microphoneEnabled = action.isEnabled;
        break;

      case types.ENABLE_SOUND:
        draft.medias.soundEnabled = true;
        break;

      case types.DISABLE_SOUND:
        draft.medias.soundEnabled = false;
        break;

      case types.SET_SCREEN_STATUS:
        draft.medias.screenEnabled = action.isEnabled;
        break;

      /**
       * Medias devices management
       */
      case types.UPDATE_CURRENT_DEVICE: {
        // Course medias, devices controls
        if (typeof action.value === 'boolean') {
          const mediaName: keyof CourseMediasBooleanProps = action.name;
          draft.medias[mediaName] = action.value;
        }

        // Course medias, devices management
        if (typeof action.value === 'string') {
          const mediaName: keyof CourseMediasStringProps = action.name;
          draft.medias[mediaName] = action.value;
        }
        break;
      }

      /**
       * Peers
       */
      case types.SET_USER_WEBCAM_MUTED: {
        if (action.usersWebcamMuted) {
          draft.usersWebcamMuted = action.usersWebcamMuted;
        }
        if (action.isMuted !== undefined) {
          draft.medias.webcamEnabled = !action.isMuted;
        }
        break;
      }

      case types.SEND_HAS_SCREEN:
        draft.hasScreen = true;
        break;

      /**
       * Conference Audio
       */
      case types.SET_AUDIO_CONFERENCE_PEERS:
        draft.audioPeers = action.conferencePeers;
        break;

      case types.SET_AUDIO_CONFERENCE_STATUS:
        draft.conferenceStatus = action.conferenceStatus;
        break;

      default:
        return state;
    }
  });
