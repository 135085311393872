/*
 * Package Import
 */
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Style
 */
export const PageError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const Container = styled.div`
  max-width: 615px;
`;

export const Title = styled.h1`
  color: #1a202e;
  font-size: 4rem;
  font-weight: 700;
  line-height: 115%;
  letter-spacing: -1.43px;
`;

export const Subtitle = styled.h2`
  color: #1a202e;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 125%;
  letter-spacing: -0.47px;
  padding-top: 1rem;
`;

export const Paragraph = styled.p`
  color: #475569;
  line-height: 150%;
  letter-spacing: -0.18px;
  padding: 2rem 0;
`;

export const Link = styled(RouterLink)`
  border: none;
  border-radius: 8px;
  color: #ffffff;
  background: #1a202e;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.18px;
  min-width: 6.187rem;
  min-height: 2.5rem;
`;
