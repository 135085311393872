/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import Profile from 'src/components/Users/Profile';
import { getUserProps } from 'src/store/selectors/users';

/*
 * Code
 */
const mapStateToProps = (state) =>
  // We send to Users component the current user object
  ({
    user: getUserProps(state, state.users.client.id),
    webcamEnabled: state.course.medias.webcamEnabled,
  });

// Actions
const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
