/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Pin from 'src/components/Chats/Channel/Chat/Message/Actions/Pin';

// Helpers
import { isTeacher, isHelper } from 'src/utils/roles';
import { pinMessage, unpinMessage } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => {
  // Props
  const { role } = state.users.client;

  // Does the user has permission to access at these actions ?
  const canPin = isTeacher(role) || isHelper(role);

  return {
    canPin,
  };
};

// Actions
const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: bindActionCreators(
    {
      pinMessage: pinMessage(ownProps),
      unpinMessage: unpinMessage(ownProps),
    },
    dispatch,
  ),
});

/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Pin);
