/**
 * Package Import
 */
import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@oclock/crumble';

/**
 * Local Import
 */

const Container = styled.div({
  marginRight: '1.5rem',
});

type UsersImportButtonProps = {
  openMultipleAddModal: () => void;
};
export const UsersImportButton = ({
  openMultipleAddModal,
}: UsersImportButtonProps): JSX.Element => (
  <Container>
    <Button type="button" variant="outlined" onClick={openMultipleAddModal} icon="FilePlusAlt">
      Importer CSV
    </Button>
  </Container>
);
