/**
 * Package Import
 */
import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

/**
 * Local Import
 */
import Loader from 'src/components/Loader';
import Router from 'src/components/Router';

/**
 * Context
 */
import { createKeycloakInstance } from 'src/context/Keycloak/Keycloak';
import KeycloakProvider from 'src/context/Keycloak/KeycloakProvider';
import { MessageProvider } from 'src/context/Message';
import { SocketProvider } from 'src/context/SocketProvider';

/**
 * Helpers
 */
import { fetchOrganizations } from 'src/store/actions';
import { getOrganization } from 'src/store/selectors/organization';

/**
 * Component
 */
const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { keycloak_client_id, keycloak_realm, keycloak_url } = useSelector(getOrganization);

  const keycloakInstance = useMemo(() => {
    if (keycloak_url && keycloak_client_id && keycloak_realm) {
      return createKeycloakInstance(keycloak_url, keycloak_client_id, keycloak_realm);
    }

    return {};
  }, [keycloak_url, keycloak_client_id, keycloak_realm]);

  useEffect(() => {
    dispatch(fetchOrganizations())
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        toast('Une erreur est survenue lors du chargement des données', { type: 'error' });
        throw error;
      });
  }, []);

  if (isLoading) return <Loader type="Oval" overlay size={48} />;

  return (
    <KeycloakProvider keycloakInstance={keycloakInstance}>
      <SocketProvider>
        <MessageProvider>
          <Router />
        </MessageProvider>
      </SocketProvider>
    </KeycloakProvider>
  );
};

/**
 * Export
 */
export default App;
