import { useCallback, useContext, useEffect } from 'react';

import { MessageContext } from 'src/context/Message';
import { CREATED_MESSAGE, DELETE_MESSAGE, EDIT_MESSAGE, RECEIVED_MESSAGE } from 'src/store/types';

/**
 * Hook that handles deleting, editing, creating & receiving messages.
 * If given a socket, it will add listeners for those actions.
 */
export const useMessage = (socket) => {
  // eslint-disable-next-line max-len
  const { idsByChat, messagesById, onMessageReceived, setIdsByChat, updateMessage } = useContext(MessageContext);

  /**
   * Soft delete message
   */
  const deleteMessage = useCallback(
    ({ chatId, messageId }) => {
      const messageIndex = idsByChat[chatId].findIndex((msgId) => msgId === messageId);

      if (messageIndex !== -1) {
        setIdsByChat((prevIdsByChat) => {
          const newByChatId = [...prevIdsByChat[chatId]];

          newByChatId.splice(messageIndex, 1);

          return {
            ...prevIdsByChat,
            [chatId]: newByChatId,
          };
        });
      }

      // Remove content + add deletedAt prop
      if (messagesById[messageId]) {
        updateMessage(messageId, {
          content: { ops: [] },
          deletedAt: new Date(),
        });
      }
    },
    [idsByChat, messagesById, updateMessage],
  );

  /**
   * Edit message content
   */
  const editMessage = useCallback(
    ({ messageId, newMessage }) => {
      updateMessage(messageId, {
        content: newMessage,
        editedAt: new Date(),
      });
    },
    [updateMessage],
  );

  useEffect(() => {
    socket?.on(CREATED_MESSAGE, onMessageReceived);
    socket?.on(DELETE_MESSAGE, deleteMessage);
    socket?.on(EDIT_MESSAGE, editMessage);
    socket?.on(RECEIVED_MESSAGE, onMessageReceived);

    return () => {
      socket?.off(CREATED_MESSAGE, onMessageReceived);
      socket?.off(DELETE_MESSAGE, deleteMessage);
      socket?.off(EDIT_MESSAGE, editMessage);
      socket?.off(RECEIVED_MESSAGE, onMessageReceived);
    };
  }, [deleteMessage, editMessage, onMessageReceived, socket]);

  return {
    deleteMessage,
    editMessage,
  };
};
