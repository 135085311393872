/*
 * Package Import
 */
import { useEffect, useState, useLayoutEffect } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

/*
 * Hooks
 */
export const useIntersectionObserver = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref?.current]);

  return [isIntersecting];
};

// Hook returning bounding rectangle of message element
export const useBoundingRectObserver = (ref, isToObserve) => {
  // We can avoid one useless observation with second param to false
  if (!isToObserve) return [undefined];

  // 2 states to store actual and last bounding rect of element observed
  const [lastBoundingRect, setLastBoundingRect] = useState(undefined);
  const [boundingRect, setBoundingRect] = useState(undefined);

  // After first paint, we get the bounding rect of element
  useLayoutEffect(() => {
    setBoundingRect(ref.current.getBoundingClientRect());
  }, [ref]);

  // Where the magic happens, only one observer used for many observables => best performance
  // At each resize, we put the actual boundingrect state as last state and we actualize the current
  useResizeObserver(ref, (entry) => {
    setLastBoundingRect(boundingRect);
    setBoundingRect(entry.target.getBoundingClientRect());
  });
  return [boundingRect, lastBoundingRect];
};
