/**
 * Package Import
 */
import mixpanel from 'mixpanel-browser';

/*
 *  Local import
 */
import { getStorage } from './storage';

/*
 * IDs
 */
const HUBSPOT_ID = '19841030';
const STONLY_WID = 'e0136149-8599-11eb-accc-0ad3430e55ea';

/*
 * Constants
 */
const { orga: organization } = getStorage('organization');

/**
 * Initialize the 3rd party widgets like Stonly, HubSpot, etc…
 */
const init = () => {
  // If we're online, load SDK
  const { host } = window.location;
  const online = host.includes('slippers.live') || host.includes('eddi.cloud');

  // Mixpanel
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.init(process.env.MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV === 'development',
      ignore_dnt: true,
      loaded: () => {
        // Set "Super" prop instanceName
        mixpanel.register({ instanceName: organization });
      },
    });
  }

  if (online) {
    /* eslint-disable */
    try {
      // Stonly widget
      (function (s, t, o, n, l, y, w, g) {
        s.StonlyWidget ||
          (((w = s.StonlyWidget =
            function () {
              w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
            }).queue = []),
          ((y = t.createElement(o)).async = !0),
          (g = new XMLHttpRequest()).open('GET', `${n}version?v=${Date.now()}`, !0),
          (g.onreadystatechange = function () {
            g.readyState === 4 &&
              ((y.src = `${n}stonly-widget.js?v=${g.status === 200 ? g.responseText : Date.now()}`),
              (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
          }),
          g.send());
        window.STONLY_WID = STONLY_WID;
      })(window, document, 'script', 'https://stonly.com/js/widget/v2/');

      // Hubspot conversation widget
      (function (h, u, b, s) {
        var t = u.getElementsByTagName(b)[0],
          e = u.createElement(b);
        e.async = true;
        e.id = 'hs-script-loader';
        e.src = s;
        t.parentNode.insertBefore(e, t);
        h.hsConversationsSettings = { loadImmediately: false };
      })(window, document, 'script', '//js-na1.hs-scripts.com/' + HUBSPOT_ID + '.js');
    } catch (e) {}
    /* eslint-enable */
  }
};

/*
 * Export
 */
export default init;

/**
 *
 */
export const identify = (userId) => {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.identify(userId);
  }
};

export const trackEvent = (event, data = {}) => {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.track(event, data);
  }
};

export const trackLinks = (selector, event, data = {}) => {
  if (process.env.MIXPANEL_TOKEN && document.querySelector(selector)) {
    mixpanel.track_links(selector, event, data);
  }
};

export const setSuperProp = (data) => {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.register(data);
  }
};

export const setPeopleIncrement = (name, number = 1) => {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.people.increment(name, number);
  }
};

export const setPeopleProperties = (data) => {
  if (process.env.MIXPANEL_TOKEN) {
    mixpanel.people.set(data);
  }
};
