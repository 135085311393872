/*
 * Package Import
 */

/*
 * Local Import
 */

/*
 * Custom z-Index
 */

/*
 * Export
 */
export const zIndex = {
  /*
   * Custom z-Index
   */
  courseOverlay: 20,
  chatOverlay: 2,
  filterModalOpen: 2,
};
