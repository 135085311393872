/*
 * Package Import
 */
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import { User } from 'src/schemas/Entities/User';

/*
 * Local Import
 */
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { deleteUser } from './deleteUser';

/*
 * Code
 */
export const useDeleteUser = (): UseMutationResult<void, unknown, string, unknown> => {
  const UserMutation = useMutation((userId: string) => deleteUser(userId), {
    // If error (normally not possible with fresh data),
    // we reload all users to be sure to have fresh data
    onError: () => {
      queryClient.invalidateQueries('users');
      toast('Une erreur est survenue, veuillez réessayer.', {
        type: 'error',
      });
    },
    onSuccess: (_data, userId) => {
      // in the cache to avoid useless request
      const updateFonction = (oldData: User[] | undefined): User[] => {
        if (userId) {
          if (!oldData) {
            return [];
          }
          const newData = oldData.filter((userObject) => userObject.id !== userId);
          return newData;
        }
        return [];
      };
      queryClient.setQueryData(['users'], updateFonction);

      toast(
        "L'invitation de cet utilisateur est supprimée. Il ne pourra plus s'inscrire sur Slippers.",
        {
          type: 'success',
        },
      );
    },
  });

  return UserMutation;
};
