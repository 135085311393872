/*
 * Package Import
 */
import produce from 'immer';
import type { AnyAction } from 'redux';
import type { DeltaOperation } from 'quill';

/*
 * Local import
 */
import * as types from 'src/store/types';
import MESSAGES, { MessageTypeEnum } from 'src/constants/messages';

/**
 * Types
 */
interface InputsState {
  [key: string]: {
    kind: MessageTypeEnum;
    input: {
      ops: DeltaOperation[];
    };
  };
}

/*
 * Initial State
 */
const initialState: InputsState = {};

/*
 * Reducer
 */

/* eslint-disable-next-line @typescript-eslint/default-param-last */
export default (state = initialState, action: AnyAction) =>
  /* eslint-disable-next-line consistent-return */
  produce(state, (draft) => {
    switch (action.type) {
      /**
       * Initialize the application w/ data
       */
      case types.INITIALIZE_DATA:
        action.data.chats.ids.forEach((item: string) => {
          draft[item] = {
            kind: MESSAGES.TYPE_TEXT,
            input: { ops: [] },
          };
        });
        break;

      /**
       * Add a new item on created chat
       */
      case types.CHAT_CREATED:
        draft[action.chatId] = {
          kind: MESSAGES.TYPE_TEXT,
          input: { ops: [] },
        };
        break;

      /**
       * Update the message input
       */
      case types.MESSAGE_INPUT_CHANGE:
        draft[action.chatId].input = action.value;
        break;

      /**
       * Reset the input message
       */
      case types.RESET_INPUT:
        draft[action.chatId].input = { ops: [] };
        break;

      default:
        return state;
    }
  });
