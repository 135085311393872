/* eslint-disable max-len */
/*
 * Package Import
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Local Import
 */
import Mugshot from 'src/components/Users/Profile/Image/Video/Mugshot/container';
// Helpers
import { getAvatar } from 'src/utils/avatar';
// Constants
import mugshot from 'src/constants/mugshot';
// Style
import * as S from './style';

/*
 * Properties
 */

const snapshot = {
  interval: mugshot.INTERVAL_SNAPSHOT_AVATAR,
  height: mugshot.HEIGHT_SNAPSHOT_AVATAR,
  width: mugshot.WIDTH_SNAPSHOT_AVATAR,
};

/*
 * Component
 */
const Image = React.forwardRef(({ user, webcamEnabled }, ref) => {
  /*
   * State
   */
  const { connected, away } = user;
  // We determine status of user with connected and away and we keep it updated
  const [status, setStatus] = useState(connected && !away);
  useEffect(() => {
    setStatus(connected && !away);
  }, [connected, away]);

  const [videoOn, setVideoOn] = useState(true);

  useEffect(() => {
    // If user is exiting of away, we relaunch video
    if (webcamEnabled && !away) {
      setVideoOn(true);
    }
    else {
      setVideoOn(false);
    }
  }, [away, webcamEnabled]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  // If video on and status ok, we use Mugshot/Webcam, else we use Avatar
  return (
    <S.Image connected={connected} away={away} ref={ref}>
      {!videoOn || !status ? (
        <S.Avatar src={getAvatar(user)} />
      ) : (
        <Mugshot {...snapshot} status={status} setVideoOn={setVideoOn} />
      )}
    </S.Image>
  );
});

/*
 * PropTypes
 */
Image.propTypes = {
  user: PropTypes.object.isRequired,
  webcamEnabled: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default Image;
