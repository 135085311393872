/*
 * Package import
 */
import { useEffect } from 'react';
import Proptypes from 'prop-types';
import { api } from 'src/utils/api';
import { useTimer } from './hooks/useTimer';
import { useActivityFlag } from './hooks/useActivityFlag';

/*
 * Component
 */
const StudentTracking = ({ userId }) => {
  /*
   * State
   */
  const [activityFlag, resetFlags] = useActivityFlag(false);

  const [timer, ready, resetTimer] = useTimer({
    initialTimer: 0,
    initialReady: true,
    target: 30000,
  });

  useEffect(() => {
    if (ready && activityFlag) {
      const payload = {
        userId,
        time: new Date(),
        message: 'online',
      };
      api({
        method: 'POST',
        url: 'activities/create',
        data: payload,
      });
      resetFlags();
      resetTimer();
    }
  }, [timer]);

  return null;
};

StudentTracking.propTypes = {
  userId: Proptypes.string.isRequired,
};

/*
 * Export
 */
export default StudentTracking;
