/*
 * Package Import
 */
import { connect } from 'react-redux';

/*
 * Local Import
 */
import CoursePanel from 'src/components/Course/Panel';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  transcriptionEnabled: state.settings.layout.transcription && state.transcription.initialized,
  popoutScreen: state.settings.popout.popoutScreen,
});

const mapDispatchToProps = {};

/*
 * Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(CoursePanel);
