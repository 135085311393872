/*
 * Package Import
 */
import styled from '@emotion/styled';

/*
 * Style
 */
export const Result = styled.div`
  padding: 0.5rem 1rem;
`;

export const Total = styled.strong(({ theme }) => ({
  ...theme.typo.labels.bigLabel,
  color: theme.text._1,
}));

export const ContainerAnswer = styled.div`
  padding: 0.5rem 0;
`;

export const Answer = styled.div(
  ({ hasCursor, percent, theme }) => ({
    alignItems: 'center',
    border: `1px solid ${theme.text._6}`,
    borderRadius: '8px',
    color: theme.text._5,
    cursor: hasCursor ? 'pointer' : 'default',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: '.5rem .5rem .5rem .75rem',
    position: 'relative',
    ...theme.typo.bodyText.body,
    fontWeight: 500,

    /* Percent */
    '::before': {
      content: '""',
      background: theme.text._9,
      width: `${percent}%`,
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
    },
  }),
  ({ hasVotedFor, theme }) =>
    hasVotedFor && {
      border: `1px solid ${theme.primary._1}`,
      color: theme.primary._1,

      '::before': {
        background: theme.primary._4,
      },
    },
);

export const StatsAction = styled.div(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  position: 'relative',
  color: theme.primary._2,
  cursor: 'pointer',
  marginTop: '.25rem',
  marginBottom: '.5rem',
  outline: 'none',

  '> span': {
    marginLeft: '.25rem',
  },
  ...theme.focusVisible,
  borderRadius: '8px',
}));

export const Stats = styled.div(({ theme }) => ({
  borderLeft: `3px solid ${theme.background._7}`,
  marginTop: '.5rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const Participants = styled.strong(({ theme }) => ({
  ...theme.typo.labels.label,
  color: theme.text._1,
}));

export const ParticipantsContent = styled.span`
  font-size: 14px;
  line-height: 1.5rem;
  z-index: 1;
`;

export const Analytics = styled.div`
  z-index: 1;
  flex-shrink: 0;
  margin-left: 0.5rem;
`;

export const Percent = styled.span`
  font-size: 14px;
`;

export const InNumber = styled.span`
  font-size: 10px;
`;

export const Content = styled.span`
  font-size: 14px;
  line-height: 1.5rem;
  z-index: 1;
  overflow: hidden;
`;
