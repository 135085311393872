/*
 * Package Import
 */

/*
 * Local Import
 */
import { User } from 'src/schemas/Entities/User';
import { Promotion } from 'src/schemas/Entities/Promotion';
import { getUserFromId } from 'src/schemas/Repositories/users';
import { getPromotionFromId } from 'src/schemas/Repositories/promotions';
import { queryClient } from 'src/serverStateManagers/queries/queryClient';
import { Course, CourseDTO, courseDTOSchema, courseSchema } from 'src/schemas/Entities/Course';
import { ZodError } from 'zod';

interface ZodValidationFailure {
  success: false;
  error: ZodError;
  data: Course;
}

interface ZodValidationSuccess {
  success: true;
  data: Course;
}

interface ErrorWithData {
  error: ZodError;
  data: Course;
}

interface TransformCoursesDTOToCoursesResponse {
  courses: Course[];
  errors: { error: ZodError; data: Course }[];
}

/*
 * Code
 */
const transformCourseDTOToCourse = (courseDTO: CourseDTO): Course => {
  const promotionsData: Promotion[] | undefined = queryClient.getQueryData('promotions');
  const usersData: User[] | undefined = queryClient.getQueryData('users');

  const date = new Date(Date.parse(courseDTO.date));
  const deactivatedDate = courseDTO.deactivatedAt && new Date(Date.parse(courseDTO.deactivatedAt));

  const coursePromo = getPromotionFromId({ promotions: promotionsData, id: courseDTO.promoId });
  const promotion = {
    id: courseDTO.promoId,
    name: coursePromo ? coursePromo.name : '',
    start: coursePromo ? coursePromo.start : new Date(),
    end: coursePromo ? coursePromo.end : new Date(),
    displayName: coursePromo ? coursePromo.displayName : '',
    ...(coursePromo?.deactivatedAt && { deactivatedAt: coursePromo.deactivatedAt }),
  };

  const teacherPromo = getUserFromId({ users: usersData, id: courseDTO.teacherId });
  const teacher = {
    id: courseDTO.teacherId,
    firstname: teacherPromo ? teacherPromo.firstname : 'Utilisateur',
    lastname: teacherPromo ? teacherPromo.lastname : 'non connu',
    ...(teacherPromo?.deactivatedAt && { deactivatedAt: teacherPromo.deactivatedAt }),
  };

  const helpers = courseDTO.helpersIds.map((helperId) => {
    if (!helperId) return null;
    const helper = getUserFromId({ users: usersData, id: helperId });
    return {
      id: helperId,
      firstname: helper ? helper.firstname : 'Utilisateur',
      lastname: helper ? helper.lastname : 'non connu',
      ...(helper?.deactivatedAt && { deactivatedAt: helper.deactivatedAt }),
    };
  });
  const { id, title } = courseDTO;
  const timeslots = courseDTO.timeslots.map(({ start, end, _id }) => ({
    start: new Date(Date.parse(start)),
    end: new Date(Date.parse(end)),
    _id,
  }));

  const course: Course = {
    id,
    title,
    promotion,
    teacher,
    date,
    helpers,
    timeslots,
    ...(deactivatedDate && { deactivatedAt: deactivatedDate }),
  };

  return course;
};

export const validateAndTransformCourseDTOToCourse = (
  courseData: CourseDTO,
): ZodValidationSuccess | ZodValidationFailure => {
  const course = transformCourseDTOToCourse(courseData);
  const courseDTOParseResult = courseDTOSchema.safeParse(courseData);
  const courseParseResult = courseSchema.safeParse(course);
  if (!courseDTOParseResult.success) {
    return {
      error: courseDTOParseResult.error,
      success: courseDTOParseResult.success,
      data: course,
    };
  }
  if (!courseParseResult.success) {
    return {
      error: courseParseResult.error,
      success: courseParseResult.success,
      data: course,
    };
  }

  return courseParseResult;
};

export const transformCoursesDTOToCourses = (
  coursesData: CourseDTO[],
): TransformCoursesDTOToCoursesResponse => {
  const errors = [] as ErrorWithData[];
  const courses = coursesData.reduce((acc: Course[], courseData: CourseDTO) => {
    // for each CourseDto received, we try to transform it in Course after validation
    const zodValidationCourse = validateAndTransformCourseDTOToCourse(courseData);
    // If response is a validation failure, we push at errors
    if (!zodValidationCourse.success) {
      errors.push({ error: zodValidationCourse.error, data: zodValidationCourse.data });
    }
    // and we push course
    acc.push(zodValidationCourse.data);
    return acc;
  }, []);

  return {
    courses,
    errors,
  };
};
