/*
 * Package Import
 */
import { toast } from 'react-toastify';

/*
 * Local Import
 */
import { api, MutationPayload } from 'src/utils/api';
import { CourseDTO, CourseFormDataValidated } from 'src/schemas/Entities/Course';
import { transformCourseFormDataToCourseDTO } from 'src/schemas/Adapters/Courses/transformCourseFormDataToCourseDTO';
import { API_CREATE_COURSE, API_POST } from 'src/constants/apiRoutes';

/*
 * Code
 */
export const createCourse = async (courseFormData: CourseFormDataValidated): Promise<CourseDTO> => {
  try {
    const courseDTO = transformCourseFormDataToCourseDTO(courseFormData);

    const payload: MutationPayload = {
      method: API_POST,
      url: API_CREATE_COURSE,
      data: courseDTO,
    };
    const { data: courseData } = await api(payload);

    return courseData;
  }
  catch (error) {
    toast("Le cours n'a pas pu être créé, veuillez réessayer", { type: 'error' });
    throw error;
  }
};
