/*
 * Package Import
 */
import React, { useState } from 'react';
import { UilEye } from '@iconscout/react-unicons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/*
* Local Import
*/
import { getChatAppearance } from 'src/store/selectors/settings';
import * as S from './style';

/*
 * Component
 */
const Spoil = ({ children, openedByDefault }) => {
  const chatAppearance = useSelector(getChatAppearance);

  /*
   * State
   */
  const [opened, setOpened] = useState(
    /** @type {Boolean} */
    openedByDefault,
  );

  /**
   * Display the spoiler
   * @return {void}
   */
  const onDisplay = () => {
    setOpened(true);
  };

  /**
   * Close the spoiler
   * @return {void}
   */
  const onClose = () => {
    setOpened(false);
  };

  /**
   * Handle the spoiler
   * @param {MouseEvent} • evt
   * @return {void}
   */
  const onClick = () => {
    if (opened) {
      onClose();
    }
    else {
      onDisplay();
    }
  };

  return (
    <S.Container chatAppearance={chatAppearance}>
      {/* Header */}
      <S.Header onClick={onClick} opened={opened}>
        <S.HeaderTitle>
          <S.Icon>
            <UilEye />
          </S.Icon>
          <S.Title>Spoiler</S.Title>
          {opened ? <ExpandLess /> : <ExpandMore />}
        </S.HeaderTitle>
      </S.Header>
      {/* Content */}
      <S.Content displayed={opened}>{children}</S.Content>
    </S.Container>
  );
};

/*
 * PropTypes
 */
Spoil.propTypes = {
  children: PropTypes.node.isRequired,
  openedByDefault: PropTypes.bool.isRequired,
};

/*
 * Export
 */
export default Spoil;
