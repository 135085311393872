/*
 * Package Import
 */

/*
 * Local Import
 */
import * as types from 'src/store/types';

/*
 * Middleware
 */
export default (store) => (next) => (action) => {
  const { organization } = store.getState();
  const { classroom_url: CLASSROOM_URL } = organization;

  switch (action.type) {
    case types.COURSE_ENDED: {
      window.location = CLASSROOM_URL;
      break;
    }

    default:
  }

  return next(action);
};
